import { Router, RouterLink } from '@angular/router';
import { IndicadoresPerformanceService } from './../../../../services/indicadores/indicadores-performance.service';
import { Component, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { CommonModule } from '@angular/common';
import { IndicadorDePerformance } from '../../../../model/indicadores/indicadoresDePerformance/indicadorDePerformance';
import moment from 'moment';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-indicadores-performance-lista',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, PaginatorComponent],
  templateUrl: './indicadores-performance-lista.component.html',
  styleUrl: './indicadores-performance-lista.component.scss'
})
export class IndicadoresPerformanceListaComponent implements OnInit {
  titulo = "<i class='fa-solid fa-chart-simple'></i> INDICADOR DE PERFORMANCE";
  nomeFormulario = "Indicadores de performance";
  listaDeIndicadores:IndicadorDePerformance[]=[]
  anoAtual:string = moment().format("yyyy");
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  token: any;

  constructor(
    private indicadoresPerformanceService:IndicadoresPerformanceService,
    private router:Router,
    private loginService: LoginService
  ){

  }

  ngOnInit(): void {
     this.decodificarToken();
  }

  private decodificarToken(){
      this.token = this.loginService.decodificarToken();
      this.consultaIndicadores();
  }

  public consultaIndicadores(){
    this.indicadoresPerformanceService.consultaIndicadorDePerformancePorAno(this.paginaAtual,this.totalPorPagina,this.anoAtual).subscribe({
      next:(res:any)=>{
        this.listaDeIndicadores = res.content;
        this.totalTamanho = res.page.totalElements
      },error:(error)=>{
        
      },complete:()=>{
        
      }
    });
  }

  public gerenciarIndicador(idIndicador:number){
    this.router.navigate([`indicadores/indicador-performance-resultado/${idIndicador}/${this.anoAtual}`])
  }

  public editarIndicador(idIndicador:number){
    this.router.navigate([`indicadores/performance-cadastro/${idIndicador}`])
  }

  public mudancaNaPagina(evento:any){
    this.paginaAtual = evento.pageIndex;
    this.totalPorPagina = evento.pageSize;
    this.consultaIndicadores();
  }



}
