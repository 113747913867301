<div class="row">
    <div class="col-12 col-lg-4">
        <label for="" class="form-label">Equipamento / Serviço</label>
        <!-- <select class="form-select form-select-sm" [(ngModel)]="osCadastro.idEquipamento">
            <option value="" selected></option>
            @for (e of equipamentos; track $index) {
                <option value="{{e.id}}">{{e.descricao}}</option>
            }
        </select> -->
        <app-select-component [listaConteudo]="equipamentos" (conteudoRetorno)="receberConteudoSelecionado($event)"></app-select-component>
    </div>
    <div class="col-12 col-lg-4">
        <label for="" class="form-label ">Prioridade</label>
        <select class="form-select form-select-sm" [(ngModel)]="osCadastro.prioridade">
            <option value="" selected></option>
            <option value="Alta">Alta</option>
            <option value="Normal">Normal</option>
            <option value="Baixa">Baixa</option>
            <option value="Sem prioridade">Sem prioridade</option>
        </select>
    </div>
    <div class="col-12 col-lg-4">
        <label for="" class="form-label">Parada de produção?</label>
        <select class="form-select form-select-sm" [(ngModel)]="osCadastro.stopProd">
            <option value="" selected></option>
            <option value="Sim">Sim</option>
            <option value="Não">Não</option>
        </select>
    </div>
    <div class="col-12 mt-2">
        <label for="" class="form-label">Solicitação / Problema / Situação</label>
        <textarea class="form-control form-control-sm" maxlength="498" rows="4" [(ngModel)]="osCadastro.solicitacao"></textarea>
    </div>
    <div class="col-12 mt-3">
        <button class="btn btn-sm" (click)="cadastrar()">Cadastrar</button>
    </div>
</div>
