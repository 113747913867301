import { LoginService } from './../../../services/login/login.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SnackBarService } from '../../../utils/snack-bar.service';

@Component({
  selector: 'app-menu-acoes',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, RouterOutlet, MatSidenavModule],
  templateUrl: './menu-acoes.component.html',
  styleUrl: './menu-acoes.component.scss'
})
export class MenuAcoesComponent {

  open: boolean = true;
  mostrarMenu = false;
  menuFixo = true;
  menusVisiveis: { [key: string]: boolean } = {};
  @ViewChild('drawer') drawer!: MatDrawer;
  isMobile = window.innerWidth < 1000;
  cdr = inject(ChangeDetectorRef);
  snackBarService = inject(SnackBarService)
  loginService = inject(LoginService);

  constructor() {

  }


  ngAfterViewInit(): void {
    this.restaurarEstadoMenu();
    this.verificarSeEhMobile();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.restaurarEstadoMenu();
  }


  private restaurarEstadoMenu() {
    this.menuFixo = JSON.parse(localStorage.getItem("menuFixado")! || 'false');
    if (this.drawer) {
      if (this.menuFixo) {
        this.drawer.open();
        this.open = false;
      }
    }

  }

  public verificarSeEhMobile() {
    this.isMobile = window.innerWidth <= 1000;
    if (this.isMobile) {
      localStorage.setItem("menuFixado", "false");
      this.open = true;
      this.menuFixo = false;
    }
  }


  public getMenuItensInformacaoDocumentada() {
    let menuLista = [
      { label: 'Consultar', link: ['informacao-documentada'] },
    ];
    if(this.temPermissao()){
      menuLista.push(
        { label: 'Cadastrar', link: ['informacao-documentada-registrar'] },
        { label: 'Gerenciar', link: ['informacao-documentada-gerenciar'] }
      )
    }
    return menuLista;
  }


  public getMenuItensPlanoDeRisco() {
    let menuLista = [
      { label: 'Consultar', link: ['plano-de-risco'] },
      // { label: 'GERENCIAR', link: ['plano-de-risco-gerenciar'] },
    ];
    if(this.temPermissao()){
      menuLista.push({ label: 'Cadastrar', link: ['plano-de-risco-registrar'] },)
    }
    return menuLista;
  }
  public getMenuItensPlanejamentoComunicacao() {
    let menuLista = [
      { label: 'Consultar', link: ['planejamento-comunicacao'] },
      // { label: 'GERENCIAR', link: ['planejamento-comunicacao-gerenciar'] },
    ];
    if(this.temPermissao()){
      menuLista.push({ label: 'Cadastrar', link: ['planejamento-comunicacao-registrar'] },)
    }
    return menuLista;
  }

  public getMenuItensPartesInteressadas() {
    let menuLista = [
      { label: 'Consultar', link: ['partes-interessadas'] },
    ];
    if(this.temPermissao()){
      menuLista.push(
        { label: 'Cadastrar', link: ['partes-interessadas-registrar'] },
        // { label: 'Gerenciar', link: ['partes-interessadas-gerenciar'] }
      )
    }
    return menuLista;
  }
  public getMenuItensAuditoriaInterna() {
    let menuLista = [
      { label: 'Consultar', link: ['auditoria-interna-lista'] },
    ];
    if(this.temPermissao()){
      menuLista.push(
        { label: 'Cadastrar', link: ['auditoria-interna-registrar'] },
        // { label: 'Gerenciar', link: ['partes-interessadas-gerenciar'] }
      )
    }
    return menuLista;
  }
  public getMenuItensAnaliseCritica() {
    let menuLista = [
      { label: 'Consultar', link: ['analise-critica'] },
    ];
    if(this.temPermissao()){
      menuLista.push(
        { label: 'Cadastrar', link: ['analise-critica-registrar'] },
        // { label: 'Gerenciar', link: ['partes-interessadas-gerenciar'] }
      )
    }
    return menuLista;
  }

  public mudarIcone() {
    if (!this.menuFixo) {
      this.open = !this.open;
    }
  }

  abrirMenu(menu: string) {
    // Alterna o estado do menu clicado
    if (this.menusVisiveis[menu]) {
      this.menusVisiveis[menu] = false;
    } else {
      // Fecha todos os menus
      Object.keys(this.menusVisiveis).forEach(key => {
        this.menusVisiveis[key] = false;
      });
      // Abre o menu clicado
      this.menusVisiveis[menu] = true;
    }
    // this.menusVisiveis[menu] = !this.menusVisiveis[menu];
  }

  fixarMenu(): void {
    this.menuFixo = !this.menuFixo; // Alterna o estado do menu fixo
    localStorage.setItem("menuFixado", this.menuFixo.toString());
  }

  clicarMenu(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    }
  }

  clicarFechar(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    } else {
      this.snackBarService.openSnackBar("Para recolher o menu você precisa clicar no icone desfixar menu", "my-snack-bar-warning")
    }
  }

  
  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_ACAO_EDIT']);
  }

  public temPermissaoExcluir() {
    return this.loginService.temPermissao(['ROLE_ACAO_ADMIN']);
  }


}


