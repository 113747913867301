import { FormsDataService } from './../../../../utils/forms-data.service';
import { GerenciadorDeArquivosService } from './../../../../utils/gerenciador-de-arquivos.service';
import { SnackBarService } from './../../../../utils/snack-bar.service';
import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { AuditoriaInternaService } from './../../../../services/acoes/auditoria-interna/auditoria-interna.service';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { BotaoComponent } from "../../../../componentes/botao/botao.component";
import { FormControl, FormGroup, ReactiveFormsModule, FormBuilder, FormsModule, Validators } from '@angular/forms';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { CommonModule } from '@angular/common';
import { AuditoriaInternaConsultaDto } from '../../../../model/acoes/auditoria-Interna/AuditoriaInternaConsultaDto';
import { ActivatedRoute } from '@angular/router';
import { InputImagensComponent } from "../../../../componentes/input-imagens/input-imagens.component";
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-auditoria-interna-registrar',
  standalone: true,
  imports: [ContainerComponentComponent, MensagemErrorsComponent, BotaoComponent, ReactiveFormsModule, CommonModule, InputImagensComponent, FormsModule],
  templateUrl: './auditoria-interna-registrar.component.html',
  styleUrl: './auditoria-interna-registrar.component.scss'
})
export class AuditoriaInternaRegistrarComponent implements OnInit {

  formularioCadastro!: FormGroup;
  idAuditoria: number = 0;
  arquivosSelecionados: any[] = [];
  descricaoArquivo: string = "";
  auditoria: AuditoriaInternaConsultaDto = new AuditoriaInternaConsultaDto();
  private formBuilder = inject(FormBuilder);
  private formValidacaoService = inject(FormValidacaoService);
  private auditoriaInternaService = inject(AuditoriaInternaService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private gerenciadorDeArquivosService = inject(GerenciadorDeArquivosService);
  private snackBarService = inject(SnackBarService);
  private activatedRoute = inject(ActivatedRoute);
  private formsDataService = inject(FormsDataService);
  private dialog = inject(MatDialog);

  ngOnInit(): void {
    this.verificarRota();
  }

  public verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idAuditoria = res.id;
        if (this.idAuditoria != 0) {
          this.consultaAponetamentos();
        }
      }
    });
  }

  public iniciarFormulario() {
    this.formularioCadastro = this.formBuilder.group({
      id: [],
      normaAuditada: [null,Validators.required],
      identificacao: [null,Validators.required],
      auditorLider: [null,Validators.required],
      equipeAuditora: [null,Validators.required],
      exclusaoEscopo: [null,Validators.required],
      justificativa: [null,Validators.required]
    })
  }

  public cadastrarAuditoria() {
    if (this.formularioCadastro.valid) {
      this.emisorEventoGeralService.carregando.emit(true);
      this.formularioCadastro.removeControl("id");
      this.auditoriaInternaService.cadastrarAuditoriaInterna(this.formularioCadastro.value).subscribe({
        next: (res) => {

        }, error: (error) => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.tratarErro(error, "Erro ao tentar cadastrar auditoria interna");
        }, complete: () => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.iniciarFormulario();
          this.snackBarService.openSnackBar("Auditoria interna cadastrada com sucesso !", "my-snack-bar-sucess");
        }
      });
    } else {
      this.formularioCadastro.markAllAsTouched();
    }
  }
  public editarAuditoria() {
    if (this.formularioCadastro.valid) {
      this.emisorEventoGeralService.carregando.emit(true);
      this.auditoriaInternaService.editarAuditoriaInterna(this.formularioCadastro.value).subscribe({
        next: (res) => {

        }, error: (error) => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.tratarErro(error, "Erro ao tentar editar auditoria interna");
        }, complete: () => {
          this.consultaAponetamentos();
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.openSnackBar("Auditoria interna editar com sucesso !", "my-snack-bar-sucess");
        }
      });
    }
  }

  public consultaAponetamentos() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.auditoriaInternaService.consultarAuditoriaPorId(this.idAuditoria).subscribe({
      next: (res) => {
        this.auditoria = res;
        this.formularioCadastro.patchValue(this.auditoria);
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public excluirArquivo(arquivo: any) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: arquivo.descricao, texto: "Deseja apagar esse arquivo" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.deletarArquivo(arquivo.id);
        };
      }
    });
  }

  private deletarArquivo(idImagem:number){
    this.emisorEventoGeralService.carregando.emit(true);
    this.auditoriaInternaService.deletarArquivoAuditoriaInterna(this.idAuditoria,idImagem).subscribe({
      next: (res) => {
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.consultaAponetamentos();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public cadastrarNovoArquivo() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.auditoriaInternaService.cadastrarArquivoAuditoriaInterna(this.criarFormData(), this.idAuditoria).subscribe({
      next: (res) => {
        this.auditoria = res;
        this.formularioCadastro.patchValue(this.auditoria);
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.consultaAponetamentos();
        this.emisorEventoGeralService.emitirEventoDeLimpezaDoInputDeArquivos();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public criarFormData() {
    return this.formsDataService.criarFormData("dto", this.arquivosSelecionados, { descricao: this.descricaoArquivo });
  }


  public verificarFormato(imagem: any): string {
    return this.gerenciadorDeArquivosService.getIconeArquivo(imagem);
  }


  public receberListaDeArquivos(arquivosSelecionados: any[]) {
    console.log(arquivosSelecionados)
    if(arquivosSelecionados.length == 0){
      this.arquivosSelecionados = [];
    }
    this.arquivosSelecionados = arquivosSelecionados;
  }

  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formularioCadastro, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formularioCadastro, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formularioCadastro, nome);
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }


}
