import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { EngDeterminacaoLiquida } from '../../../model/engenharia/EngDeterminacaoLiquida';



@Injectable({
  providedIn: 'root'
})
export class DeterminacaoProcessoLiquidaService {

  private url = `${environment.url}/engenharia/eng-determinacao-liquida`;
  constructor(
    private http: HttpClient
  ) { 
    
  }


  public salvarDeterminacaoProcesso(engDeterminacaoProcesso: EngDeterminacaoLiquida): Observable<EngDeterminacaoLiquida> {
    return this.http.post<EngDeterminacaoLiquida>(`${this.url}`, engDeterminacaoProcesso);
  }
  public editarDeterminacaoProcesso(engDeterminacaoProcesso: EngDeterminacaoLiquida): Observable<EngDeterminacaoLiquida> {
    return this.http.put<EngDeterminacaoLiquida>(`${this.url}`, engDeterminacaoProcesso);
  }

  public consultarDeterminacaoProcessoPorCdProduto(cdProduto:number): Observable<EngDeterminacaoLiquida> {
    return this.http.get<EngDeterminacaoLiquida>(`${this.url}/${cdProduto}`);
  }

  public deletarDeterminacaoProcesso(id:number): Observable<EngDeterminacaoLiquida> {
    return this.http.delete<EngDeterminacaoLiquida>(`${this.url}/${id}`);
  }

}
