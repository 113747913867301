import { EventEmitter, Injectable } from '@angular/core';
import { Produto } from '../../model/engenharia/produto-model';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  static emissorProduto = new EventEmitter<Produto>();

  dadosProcesso = {processo1 : '', numeroProcesso1: 0, processo2: '', numeroProcesso2: 0};
  processoDoProduto: string[] = []

  constructor() { }

  /**
   * Realiza a separação dos processos do produto em strings distintas
   * @param processo 
   * @returns 
   */
  public verificarProcesso(processo: any): {} {
    this.dadosProcesso = {processo1 : '', numeroProcesso1: 0, processo2: '', numeroProcesso2: 0};
    this.processoDoProduto = processo!.split("+");
    if (this.processoDoProduto.length > 0) {
      this.processoDoProduto.forEach(e => {
        switch (e.trim().toUpperCase()) {  // Adicionei trim e toUpperCase para garantir a consistência
          case "KTL":
            this.dadosProcesso.processo1 = "KTL"
            this.dadosProcesso.numeroProcesso1 = 2;
            break;
          case "PÓ":
          case "PO": // Adicionado para garantir que ambas as variantes sejam tratadas
          this.dadosProcesso.processo2 = "PÓ"
          this.dadosProcesso.numeroProcesso2 = 3; 
            break;
          case "FOSFATO":
            this.dadosProcesso.processo1 = "FOSFATO"
            this.dadosProcesso.numeroProcesso1 = 1; 
            break;
          case "LÍQUIDA":
          case "LIQUIDA": // Adicionado para garantir que ambas as variantes sejam tratadas
          this.dadosProcesso.processo2 = "LÍQUIDA"
          this.dadosProcesso.numeroProcesso2 = 4;   
            break;
          default:
            console.log(`Processo desconhecido: ${e}`);
            break;
        }
      });
    }
    return this.dadosProcesso;
  }

}
