import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { EngPadraoIsencaoService } from '../../../../services/engenharia/processo/eng-padrao-isencao.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { FormsDataService } from '../../../../utils/forms-data.service';
import { ActivatedRoute } from '@angular/router';
// import { RotaProcessosService } from '../../../../services/rotas-services/rota-processos.service';
import { CommonModule } from '@angular/common';
import { CdkDropList, CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { GerenciadorDeArquivosService } from '../../../../utils/gerenciador-de-arquivos.service';
import { EngProcessoUpdateDto } from '../../../../model/engenharia/engProcessoUpdateDto';
import { forkJoin, map, of } from 'rxjs';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { Pagination } from 'swiper/modules';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { LoadingComponent } from "../../../../componentes/loading/loading.component";

@Component({
  selector: 'app-eng-padrao-isencao',
  standalone: true,
  imports: [CommonModule, BotaoComponent, ReactiveFormsModule, MatTooltipModule, MatCardModule, FormsModule, CdkDropList, CdkDrag, ContainerComponentComponent, LoadingComponent],
  templateUrl: './eng-padrao-isencao.component.html',
  styleUrl: './eng-padrao-isencao.component.scss'
})
export class EngPadraoIsencaoComponent implements OnInit, OnChanges {

  formulario!: FormGroup;
  urlImagem = environment.url;
  listaDocumentoSelecionadoRederizadosIsencao: any[] = [];
  lista: string[] = [];
  idProcesso: string = '';
  cdProduto: number = 0;
  listaDePadraoDeIsencao: any[] = []
  listaDeDados: any[] = [];
  contactFormPadraoIsencao!: FormGroup;
  produto: any = {}
  engProcessoUpdateDto: EngProcessoUpdateDto[] = [];
  nomeDeterminacao: any;
  @Input() dados: any;
  @Input() fazerConsulta: boolean = false;
  ativarVisualizarSequencia: boolean = false;
  @ViewChild('swiperProduto', { static: false }) swiperContainer!: ElementRef;
  public swiper?: Swiper;
  editando: boolean = false;
  imagemSelecionada: any;
  descricaoImagem: string = "";
  carregando: boolean = false;


  constructor(
    private engPadraoIsencaoService: EngPadraoIsencaoService,
    private gerenciadorDeArquivosService: GerenciadorDeArquivosService,
    private snackBarService: SnackBarService,
    private formDataService: FormsDataService,
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    // private rotaProcessoService: RotaProcessosService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef,
    private formValidacaoService: FormValidacaoService
  ) {

  }
  ngOnInit(): void {
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = parseInt(params.cdProduto);
      this.produto = { cdProduto: this.cdProduto }
      // RotaProcessosService.emitirProcesso.emit(this.produto);
      this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
      if (this.cdProduto && this.idProcesso) {
        this.limparDados();
        this.consultarTodosOsDocumentos();
      }
    });

    this.contactFormPadraoIsencao = this.formBuilder.group({
      contactsPadraoIsencao: this.formBuilder.array([this.createContact()])
    });
    this.iniciarFormulario();

  }

  ngOnChanges(changes: SimpleChanges) {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    if (changes['fazerConsulta'] && changes['fazerConsulta'].currentValue) {
      this.limparDados();
      this.consultarTodosOsDocumentos();
    }
  }

  public iniciarFormulario() {
    this.formulario = this.formBuilder.group({
      id: [''],
      descricao: [''],
    });
  }

  public consultarTodosOsDocumentos() {
    this.listaDePadraoDeIsencao = [];
    this.engPadraoIsencaoService
      .consultarPadraoDeIsencao(this.cdProduto, parseInt(this.idProcesso))
      .subscribe({
        next: (res) => {
          this.listaDePadraoDeIsencao = res;
        },
        error: (erro) => {

        },
        complete: () => {
          this.montarImagens();
        },
      });
  }


  createContact(): FormGroup {
    return this.formBuilder.group({
      descricao: ['', Validators.required],
    });
  }

  addContact(): void {
    const contactsPadraoIsencao = this.contactsPadraoIsencao;
    contactsPadraoIsencao.push(this.createContact());
  }

  removeContact(index: number): void {
    const contactsPadraoIsencao = this.contactFormPadraoIsencao.get('contactsPadraoIsencao') as FormArray;
    contactsPadraoIsencao.removeAt(index);
    this.listaDocumentoSelecionadoRederizadosIsencao.splice(index, 1);
  }

  get contactsPadraoIsencao() {
    return this.contactFormPadraoIsencao.get('contactsPadraoIsencao') as FormArray;
  }



  onSubmit() {
    const formData = new FormData();
    this.listaDeDados = [];
    this.contactFormPadraoIsencao.value['contactsPadraoIsencao'].forEach((elemento: any, index: number) => {
      const file = this.listaDocumentoSelecionadoRederizadosIsencao[index];
      if (!file || !file.name) {
        this.snackBarService.openSnackBar('Sem imagem selecionada', "my-snack-bar-erro")
      } else {
        formData.append('file', file, file.name);
        this.listaDeDados.push({
          "descricao": elemento.descricao,
          "cdProduto": this.cdProduto,
          "cdProcesso": this.idProcesso,
          "sequencia": index
        });
      }

    });
    formData.append('engPadraoIsencao', JSON.stringify(this.listaDeDados)), this.formDataService.criarFormData("engPadraoIsencao", this.listaDocumentoSelecionadoRederizadosIsencao, this.listaDeDados)
    this.salvarPadraoIsencao(formData);
  }

  public salvarPadraoIsencao(formData: FormData) {
    this.carregando = true;
    this.engPadraoIsencaoService.salvarPadraoDeIsencao(formData).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar salvar padrão de isenção")
        this.carregando = false;
      }, complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos();
        this.limparFormulario();
      }
    })
  }




  public pegarPadraoIsencao(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let itemJaExiste = this.listaDocumentoSelecionadoRederizadosIsencao.some(
        (arquivo) => arquivo.name === file.name
      );
      if (!itemJaExiste) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          file['preview'] = e.target.result;
          const previsualizacao = e.target.result;
          this.listaDocumentoSelecionadoRederizadosIsencao.push(file);
        }
        reader.readAsDataURL(file);
      }
    }
  }


  drop(event: CdkDragDrop<{ title: string; poster: string }[]>) {
    moveItemInArray(this.listaDePadraoDeIsencao, event.previousIndex, event.currentIndex);
  }


  public deletarPadraoDeIsencao(nomeArquivo: string, id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: nomeArquivo, textoConteudo: "padrão de isenção" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.deletar(id);
        };
      }
    });
  }
  private deletar(id: number) {
    this.carregando = true;
    this.engPadraoIsencaoService.deletarPadraoDeIsencao(id).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar(res.mensagem, "my-snack-bar-sucess")
      }, error: (error) => {
        this.carregando = false;
        this.snackBarService.tratarErro(error, "Erro ao tentar deletar padrão de isenção")
      }, complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos();
      }
    });
  }


  public salvarNovaSequencia() {
    const atualizarSequencias$ = of(this.listaDePadraoDeIsencao).pipe(map(lista => {
      let contador = 1;
      lista.forEach(e => {
        e.sequencia = contador++;
        e.cdProcesso = this.idProcesso;
        e.cdProduto = this.cdProduto;
      });
      return lista;
    }));

    forkJoin([atualizarSequencias$]).subscribe(() => {
      this.transformaEmObjetoDeEnvio();
      this.salvarSequenciaNova();
    });
  }


  public salvarSequenciaNova() {
    this.carregando = true;
    this.engPadraoIsencaoService.salvarNovoPadraoDeIsencao(this.engProcessoUpdateDto).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Sequencia redefinada", "my-snack-bar-sucess")
      }, error: (error) => {
        this.carregando = false;
        this.snackBarService.tratarErro(error, "Erro ao tentar salvar nova sequência de padrão de isenção")
      }, complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos()
      }
    });
  }



  public transformaEmObjetoDeEnvio() {
    this.engProcessoUpdateDto = [];
    this.listaDePadraoDeIsencao.forEach(e => {
      let objTemp: EngProcessoUpdateDto = {
        id: e.id,
        nomeArquivo: e.nomeArquivo,
        cdProcesso: e.cdProcesso,
        cdProduto: e.cdProduto,
        descricao: e.descricao,
        path: e.path,
        dataCadastro: e.dataCadastro,
        sequencia: e.sequencia
      }
      this.engProcessoUpdateDto.push(objTemp);
    });
  }

  public limparFormulario() {
    this.listaDePadraoDeIsencao = [];
    this.listaDeDados = [];
    this.listaDocumentoSelecionadoRederizadosIsencao = [];
    this.contactFormPadraoIsencao.reset();
    this.formulario.reset();
    this.contactsPadraoIsencao.reset();
    this.contactsPadraoIsencao.clear();
  }

  public editarDescricaoImagem(imagemSelecionada: number, descricao: string) {
    this.editando = true;
    this.imagemSelecionada = imagemSelecionada;
    this.descricaoImagem = descricao;
  }

  public cancelarEdicao() {
    this.editando = false;
    this.descricaoImagem = '';
    this.imagemSelecionada = 0;
  }

  public concluirEdicaoDeDescricao() {
    this.carregando = true;
    this.engPadraoIsencaoService.editarDescricaoPadraoDeIsencao(this.imagemSelecionada, this.descricaoImagem).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar('Descrição editada com sucesso', 'my-snack-bar-sucess');
      }, error: (e) => {
        this.carregando = false;
        this.snackBarService.tratarErro(e, "Erroa ao tentar editar descrição");
      }, complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos();
        this.cancelarEdicao();
      }
    })
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }




  public limparDados() {
    this.listaDePadraoDeIsencao = [];
    this.listaDeDados = [];
    this.listaDocumentoSelecionadoRederizadosIsencao = [];
  }





  public montarImagens() {
    this.cdr.detectChanges();
    const options: SwiperOptions = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff; background:#09638E" class="' + className + '">' + (index + 1) + '</span>';
        }
      },
      allowTouchMove: true, // Garante suporte ao touch
      observer: true,       // Observa alterações no Swiper
      observeParents: true, // Observa mudanças nos elementos pais
      modules: [Pagination],
      threshold: 30,
    };
    this.swiper = new Swiper(this.swiperContainer.nativeElement, options);
  }
}
