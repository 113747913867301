
import { Routes } from "@angular/router";
import { HomeQualidadeComponent } from "../paginas/qualidade/home-qualidade/home-qualidade.component";
import { HomeAlertaComponent } from "../paginas/qualidade/alerta/home-alerta/home-alerta.component";



export const routesQualidade: Routes = [
    { path: "home", component: HomeQualidadeComponent },
    //ALERTA
    { path: "alerta", component: HomeAlertaComponent },
    { path: "alerta/:valorPesquisado", component: HomeAlertaComponent },
    { path: "alerta-registrar", loadComponent: () => import("../paginas/qualidade/alerta/alerta-registrar/alerta-registrar.component").then(a => a.AlertaRegistrarComponent) },
    { path: "alerta-lista", loadComponent: () => import("../paginas/qualidade/alerta/alerta-lista/alerta-lista.component").then(a => a.AlertaListaComponent) },
    { path: "alerta-editar/:id", loadComponent: () => import("../paginas/qualidade/alerta/editarAlerta/editarAlerta.component").then(a => a.EditarAlertaComponent) },

    //NAO CONFORMIDADE
    { path: "nao-conformidade", loadComponent: () => import("../paginas/qualidade/nao-conformidade/nao-conformidade-registrar/nao-conformidade-registrar.component").then(n => n.NaoConformidadeRegistrarComponent) },
    { path: "nao-conformidade-lista", loadComponent: () => import("../paginas/qualidade/nao-conformidade/nao-conformidade-lista/nao-conformidade-lista.component").then(n => n.NaoConformidadeListaComponent) },
    { path: "nao-conformidade-edita/:id", loadComponent: () => import("../paginas/qualidade/nao-conformidade/editar-nao-conformidade/editar-nao-conformidade.component").then(n => n.EditarNaoConformidadeComponent) },

    //NAO CONFORMIDADE
    { path: "reclamacao", loadComponent: () => import("../paginas/qualidade/reclamacao-rrc/registrar-rrc-reclamacao/registrar-rrc-reclamacao.component").then(r => r.RegistrarRrcReclamacaoComponent) },
    { path: "listar-reclamacoes", loadComponent: () => import("../paginas/qualidade/reclamacao-rrc/listar-rrc-reclamacoes/listar-rrc-reclamacoes.component").then(r => r.ListarRrcReclamacoesComponent) },
    { path: "reclamacao-edita/:id", loadComponent: () => import("../paginas/qualidade/reclamacao-rrc/edita-reclamacao-rrc/edita-reclamacao-rrc.component").then(r => r.EditaReclamacaoRrcComponent) },

    //PLANO DE AÇÃO
    { path: "registrar-plano-acao", loadComponent: () => import("../paginas/qualidade/plano-de-acao/registrar-plano-de-acao/registrar-plano-de-acao.component").then(r => r.RegistrarPlanoDeAcaoComponent) },
    { path: "plano-acao-lista", loadComponent: () => import("../paginas/qualidade/plano-de-acao/listar-plano-de-acao/listar-plano-de-acao.component").then(r => r.ListarPlanoDeAcaoComponent) },
    { path: "editar-plano-acao/:id", loadComponent: () => import("../paginas/qualidade/plano-de-acao/editar-plano-de-acao/editar-plano-de-acao.component").then(r => r.EditarPlanoDeAcaoComponent) },
    //CUSTO QUALIDADE
    { path: "custo-qualidade", loadComponent: () => import("../paginas/qualidade/custo-qualidade/custo-da-qualidade-home/custo-da-qualidade-home.component").then(c => c.CustoDaQualidadeHomeComponent) },
    { path: "custo-qualidade-registrar", loadComponent: () => import("../paginas/qualidade/custo-qualidade/custo-da-qualidade-registrar/custo-da-qualidade-registrar.component").then(c => c.CustoDaQualidadeRegistrarComponent) },
    { path: "custo-qualidade-registrar/:id", loadComponent: () => import("../paginas/qualidade/custo-qualidade/custo-da-qualidade-registrar/custo-da-qualidade-registrar.component").then(c => c.CustoDaQualidadeRegistrarComponent) },
    //CUSTO PRODUTO NÃO CONFORME
    { path: "pnc", loadComponent: () => import("../paginas/qualidade/pnc-produto-nao-conforme/produto-nao-conforme/produto-nao-conforme.component").then(r => r.ProdutoNaoConformeComponent) },
    { path: "pnc-registrar", loadComponent: () => import("../paginas/qualidade/pnc-produto-nao-conforme/produto-nao-conforme-registra/produto-nao-conforme-registra.component").then(r => r.ProdutoNaoConformeRegistraComponent) },
    { path: "pnc-registrar/:id", loadComponent: () => import("../paginas/qualidade/pnc-produto-nao-conforme/produto-nao-conforme-registra/produto-nao-conforme-registra.component").then(r => r.ProdutoNaoConformeRegistraComponent) },
    { path: "pnc-apontamento/:id", loadComponent: () => import("../paginas/qualidade/pnc-produto-nao-conforme/produto-nao-conforme-apontamento/produto-nao-conforme-apontamento.component").then(r => r.ProdutoNaoConformeApontamentoComponent) },
    { path: "pnc-visualizar/:id", loadComponent: () => import("../paginas/qualidade/pnc-produto-nao-conforme/produto-nao-conforme-visualizar/produto-nao-conforme-visualizar.component").then(r => r.ProdutoNaoConformeVisualizarComponent) },
    { path: "pnc-relatorio", loadComponent: () => import("../paginas/qualidade/pnc-produto-nao-conforme/produto-nao-conforme-relatorios/relatorios-pnc/relatorios-pnc.component").then(r => r.RelatoriosPncComponent) },
]