import { FornecedorService } from './../../../../services/qualidade/pnc-produto-nao-conforme/fornecedor.service';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { CommonModule } from '@angular/common';
import { GestaoDeFornecedoresService } from '../../../../services/gestao/gestao-de-fornecedor/gestao-de-fornecedores.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-gestao-de-fornecedores-lista',
  standalone: true,
  imports: [ContainerComponentComponent, CommonModule],
  templateUrl: './gestao-de-fornecedores-lista.component.html',
  styleUrl: './gestao-de-fornecedores-lista.component.scss'
})
export class GestaoDeFornecedoresListaComponent implements OnInit {

  listaDeGestaoFornecedor: any[] = [];
  listaDeGestaoFornecedorInativos: any[] = [];
  gestaoDeFornecedoresService = inject(GestaoDeFornecedoresService);
  router = inject(Router);
  loginService = inject(LoginService);
  paginaAtual = 0;
  quantidadePorPagina: any[] = [10, 20, 40, 80];
  totalPorPagina: number = this.quantidadePorPagina[0];
  tamanhoTotal: number = 0

  ngOnInit(): void {
    this.consultarListaDeFornecedor();
  }

  public consultarListaDeFornecedor() {
    this.gestaoDeFornecedoresService.consultarGestaoDeFornecedorPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.listaDeGestaoFornecedor = res.content;
        this.processarElementosPagina(res.page);

      }, error: (error) => {

      }, complete: () => {
        this.tratarFornecedoresInativos();
      }
    });
  }

  public visualizarItem(id: number) {
    this.router.navigate([`gestao/gestao-fornecedor-view/${id}`]);
  }

  public editarItem(id: number) {
    this.router.navigate([`gestao/gestao-fornecedor-editar/${id}`]);
  }


  private processarElementosPagina(page: any) {
    this.paginaAtual = page.number;
    this.tamanhoTotal = page.totalElements;
  }
  private tratarFornecedoresInativos() {
    this.listaDeGestaoFornecedor.forEach(f => {
      f.status == "Ativo" ? '' : this.listaDeGestaoFornecedorInativos.push(f);
    });
  }

  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_GEST_EDIT']);
  }

  public temPermissaoExcluir() {
    return this.loginService.temPermissao(['ROLE_GEST_ADMIN']);
  }

}
