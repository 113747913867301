<app-container-component [tituloPage]="titulo" [subtituloPage]="subTitulo">
        <div class="row g-0 d-flex align-items-center justify-content-center">
            <div class="col-md-4 col-md-2 d-flex justify-content-center justify-content-start pt-1 pb-1">
                <img src="assets/grafico-crescente.png" class="img-fluid rounded-start" alt="">
            </div>
            <div class="col-md-8">
                <div class="card-body">
                    <h5 class="card-title"><b>INDICADORES DE PROCESSO</b></h5>
                    <p class="card-text">Os indicadores de processo são definidos no nível de cada processo, de modo
                        que
                        se tenha uma forma eficaz de medir cada uma das atividades executadas pela empresa. Em geral
                        os
                        Objetivos do sistema de gestão derivam desses indicadores.</p>
                    <p class="card-text"><small class="text-body-secondary">Mais informações logo abaixo</small></p>
                </div>
            </div>
        </div>
        <div class="mt-4 conteudo">
            <p>Essa área é reservada a definição e divulgação dos indicadores de performance de processo, trata-se de
                uma forma de divulgação ampla dos resultados de exercício de processo. </p>
            <p>Os indicadores de processo são pontos de controle que visam demonstrar a saúde do processo com base nos
                seguintes critérios:</p>
            <p>
                <b>Atingimento da Meta:</b> Percentual com que o indicador foi atingido, ou seja, se a meta foi
                alcançada, se
                foi superada ou não foi atingida. Serve para se tomar ações diretas para o atingimento do resultado.
            </p>
            <p>
                <b> Dispersão dos resultados:</b> Variação dos resultados de um mês para o outro, pode indicar
                descontrole do
                processo, ou ações tomadas sem planejamento prévio.
            </p>
            <p>
                <b>Tendência do Processo:</b> Análise linear dos resultados do processo de modo a se concluir as
                probabilidades
                do próximo resultado, via de regra, três pontos acima da média indicam que o próximo ponto também será
                acima da média, se nada for mudado no processo.
            </p>
        </div>
</app-container-component>