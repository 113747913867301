import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngMedicaoCamadaService {

  private url = environment.url + '/engenharia';
  constructor(
    private http: HttpClient
  ) {

  }
  public salvarMedicaoDeCamada(formdata: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-medicao-camada`, formdata);
  }
  public editarDescricaoMedicaoDeCamada(id: number, descricao: string): Observable<any> {
    return this.http.put<any>(`${this.url}/eng-medicao-camada/${id}/${descricao}`, null);
  }

  // public consultaImagemPorNome(nomeArquivo:string): Observable<Blob> {
  //   return this.http.get(`${this.url}/eng-medicao-camada/download/${nomeArquivo}`,{responseType:'blob'});
  // }

  public consultarMedicaoDeCamada(cdProduto: number, cdProcesso: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-medicao-camada/${cdProduto}/${cdProcesso}`);
  }

  public deletarMedicaoDeCamada(id: number): Observable<any> {
    return this.http.delete<any>(`${this.url}/eng-medicao-camada/${id}`);
  }

  public salvarNovoMedicaoDeCamada(novaSequencia: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-medicao-camada/sequencia`, novaSequencia)
  }
}
