<table class=" table table-sm table-striped">
    <tbody>
        <tr>
            <td><strong>Qtd de peças na travessa:</strong></td>
            <td>{{processoDoPo!.qtdePecasTravessa }}</td>
        </tr>
        <tr>
            <td><strong>Qtd de travessas por quadro:</strong></td>
            <td>{{processoDoPo!.qtdePecasTravessa }}</td>
        </tr>
        <tr>
            <td><strong>Qtd de peças por quadro:</strong></td>
            <td>{{processoDoPo!.qtdePecasQuadro }}</td>
        </tr>
        <tr>
            <td><strong>Tensão:</strong></td>
            <td>{{processoDoPo!.tensao }}</td>
        </tr>
        <tr>
            <td><strong>Corrente:</strong></td>
            <td>{{processoDoPo!.corrente}}</td>
        </tr>
        <tr>
            <td><strong>Vazão da pistola retrátil:</strong></td>
            <td>{{processoDoPo!.vazaoPistolaRet }}</td>
        </tr>
    </tbody>
</table>