import { Component } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { LuximetroServiceService } from '../../../../services/inspecao-equipamento/luximetroService.service';
import { PageEvent } from '@angular/material/paginator';
import { Luximetro } from '../../../../model/inspecao-equipamento/luximetro';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";

@Component({
  selector: 'app-inspecao-luximetro',
  standalone: true,
  imports: [ContainerComponentComponent, CommonModule, FormsModule, PaginatorComponent],
  templateUrl: './inspecao-luximetro.component.html',
  styleUrl: './inspecao-luximetro.component.scss'
})
export class InspecaoLuximetroComponent {

  
  listaDeLuximetro: Luximetro[] = [];
  valorPesquisado = "";
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: number[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];

  constructor(
    private luximetroService: LuximetroServiceService,
    private router: Router,
    private activatedRoute:ActivatedRoute
  ) {

  }
  ngOnInit(): void {
    this.verificarSeExisteValorDeBusca();
  }

  public consultaTodasLuximetroPaginada() {
    this.luximetroService.buscarTodasLuximetroPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.listaDeLuximetro = res.content;
        this.totalTamanho = res.page.totalElements
      }, error: (error) => {

      }, complete: () => {
      }
    });
  }


  public editarLuximetro(id: number) {
    this.router.navigate([`inspecao/luximetro-registrar/${id}`])
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.buscar(event);
    }
  }


  buscar(event?: Event) {
    if (event instanceof KeyboardEvent && event.key !== 'Enter') {
      return;
    }
    if (this.valorPesquisado.trim() !== '' && this.valorPesquisado.length >= 2) {
      this.luximetroService.buscarTodasLuximetroPaginadaMerge(this.valorPesquisado, this.paginaAtual, this.totalPorPagina).subscribe({
        next: (res) => {
          this.listaDeLuximetro = res.content;
          this.totalTamanho = res.page.totalElements
        }, error: (error) => {

        }, complete: () => {
        }
      });
    } else {
      this.paginaAtual = 0;
      this.totalPorPagina = 10;
      this.consultaTodasLuximetroPaginada();
    }
  }


  public verificarSeExisteValorDeBusca(){
    this.activatedRoute.params.subscribe((res:any)=>{
      this.valorPesquisado = res.valorBusca;
      if(this.valorPesquisado){
        this.buscar();
      }else{
        this.consultaTodasLuximetroPaginada();
      }
    });
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultaTodasLuximetroPaginada();
  }

  public visualizar(id:number){
    this.router.navigate([`inspecao/luximetro/view/${id}`])
  }
}
