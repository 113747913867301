<div class="separador mt-2 mb-2" #dispositivoIsencao>
    <strong>DISPOSITIVO DE ISENÇÃO</strong>
</div>
@defer (on viewport(dispositivoIsencao)) {
@if(tabAtiva != ''){
<!-- Nav Tabs -->
<ul class="nav nav-tabs" id="tabDisisencao" role="tablist">
    @if (dados.processo1 != '') {
    <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="{'active': tabAtiva === 'processo1'}" id="dispIsencao1-tab" data-bs-toggle="tab"
            href="#dispIsencao1" (click)="this.consultarPadraoIsencaoProcesso1Iniciar(dados.numeroProcesso1)" role="tab"
            aria-controls="processo1" aria-selected="true">{{dados.processo1}}</a>
    </li>
    }
    @if (dados.processo2 != '') {
    <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="{'active': tabAtiva === 'processo2'}" id="dispIsencao2-tab" data-bs-toggle="tab"
            href="#dispIsencao2" role="tab" (click)="consultarPadraoIsencaoProcesso2Iniciar(dados.numeroProcesso2)"
            aria-controls="processo2" aria-selected="false">{{dados.processo2}}</a>
    </li>
    }
</ul>
<div class="tab-content mt-3" id="myTabContent">
    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo1'}" id="dispIsencao1" role="tabpanel"
        aria-labelledby="dispIsencao1-tab">
        <div class="position-img">
            <div class="col-12 ">
                @if(imagens1 == undefined){
                <div class="card-load-box">
                    <div class="card_load_extreme_descripion"></div>
                </div>
                }@else if (imagemNaoCadastrada1) { <div class="col-12 text-center text-danger">
                    <h3>Imagem não cadastrada!</h3>
            </div>
            }
            <app-swiperComponent [listaDeDados]="imagens1"></app-swiperComponent>
        </div>
    </div>
</div>
<div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo2'}" id="dispIsencao2" role="tabpanel"
    aria-labelledby="dispIsencao2-tab">
    <div class="position-img">
        <div class="col-12 ">
            @if(imagens2 == undefined){
            <div class="card-load-box">
                <div class="card_load_extreme_descripion"></div>
            </div>
            }@else if (imagemNaoCadastrada2) { <div class="col-12 text-center text-danger">
                <h3>Imagem não cadastrada!</h3>
        </div>
        }
        <app-swiperComponent [listaDeDados]="imagens2"></app-swiperComponent>
    </div>
</div>
</div>
</div>
}@else {
<div class="text-center mt-3">
    <p class="alert alert-danger">Dispositivo de inseção não cadastrado!</p>
</div>
}
}@loading {
<div class="card-load-box">
    <div class="card_load_extreme_descripion"></div>
</div>
}