import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { Router } from '@angular/router';
import { GestaoDeMelhoriaService } from '../../../../services/gestao/gestao-de-melhoria/gestao-de-melhoria.service';
import { CommonModule } from '@angular/common';
import Swiper from 'swiper';
import { Pagination, Controller, EffectFade } from 'swiper/modules';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-gestao-de-melhorias-lista',
  standalone: true,
  imports: [CommonModule,ContainerComponentComponent],
  templateUrl: './gestao-de-melhorias-lista.component.html',
  styleUrl: './gestao-de-melhorias-lista.component.scss',
  changeDetection:ChangeDetectionStrategy.OnPush
  
})
export class GestaoDeMelhoriasListaComponent implements OnInit {

  expanded: boolean = false;
  router = inject(Router);
  cdr = inject(ChangeDetectorRef);
  gestaoDeMelhoriaService = inject(GestaoDeMelhoriaService);
  loginService = inject(LoginService);
  gestaoMelhorias:any[]=[];
  idHover:number | null = 0;
  

  ngOnInit(): void {
    this.consultarGestaoDeMelhoria();
  }

  toggleExpand(state: boolean,id:number) {  
    this.idHover = state ? id : null;
  }

  public consultarGestaoDeMelhoria(){
    this.gestaoDeMelhoriaService.consultarGestaoDeMelhoriaPaginada(0,40).subscribe({
      next:(res)=>{
        this.gestaoMelhorias = res.content;
      },error:(error)=>{

      },complete:()=>{
   
        this.montarImagens();
      }
    });
  }

  public editarGestaoMelhoria(id:number){
    this.router.navigate([`gestao/gestao-melhoria-registrar/${id}`]);
  }

  public visualizar(id:number){
    this.router.navigate([`gestao/gestao-melhoria-view/${id}`]);
  }

  public montarImagens() {
    this.cdr.detectChanges();
    this.gestaoMelhorias.forEach(e => {
      const swiper = new Swiper(`.mySwiper-${e.id}`, {
        fadeEffect: {
          crossFade: true
        },
        slidesPerView: 1,
        spaceBetween: 50,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff; background:#086586" class="' + className + '">' + (index + 1) + "</span>";
          },
        },
        modules: [Pagination, Controller, EffectFade]
      });
    });
  }

  public temPermissao(){
    return this.loginService.temPermissao(['ROLE_GEST_EDIT']);
  }

  public temPermissaoExcluir(){
    return this.loginService.temPermissao(['ROLE_GEST_ADMIN']);
  }
}
