import { NotificacoesSistemaService } from './../../services/infra/notificacoes-sistema.service';
import { CommonModule } from '@angular/common';
import { Component, inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EmisorEventoGeralService } from '../../utils/emisorEventoGeral.service';
import { StatusInfoDoc } from '../../enum/status-informacao-documentada';
import { ManuaisClientesService } from '../../services/controles/manuais-clientes/manuais-clientes.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnChanges {
  url: any;
  quantidadeTotalDeNotificacao: number = 0;
  quantidadeDeInformacaoDocumentadaPendente: number = 0;
  tipo = environment.tipo;
  linkServe = `${environment.url}/home`;

  private notificacoesInterval: any = null; // Variável para controlar o intervalo
  private subscription: Subscription | null = null;

  //Injeções de dependencia
  router = inject(Router);
  bottomSheet = inject(MatBottomSheet);
  notificacoesSistemaService = inject(NotificacoesSistemaService);
  manualDoClienteService = inject(ManuaisClientesService);
  emisorEventoGeral = inject(EmisorEventoGeralService);

  constructor(

  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.receberQuantidadeDeNotificacao();
  }

  ngOnInit(): void {
    this.consultarAprovacaoInformacaoDocumentada();
    this.receberQuantidadeDeNotificacao();
    if (!this.notificacoesInterval) {
      this.startNotificacoesAutoUpdate();
    }
  }

  openBottomSheet(): void {
    this.router.navigate(['/notificacoes-sistema'])
  }

  public receberQuantidadeDeNotificacao() {
    this.emisorEventoGeral.obterMeuEvento().subscribe(res => {
      if (res) {
        this.consultarAprovacaoInformacaoDocumentada();
      }
    });
    EmisorEventoGeralService.quantidadeNotificacao.subscribe(res => {
      this.quantidadeDeInformacaoDocumentadaPendente = res;
    });
  }

  public consultarAprovacaoInformacaoDocumentada() {
    this.quantidadeTotalDeNotificacao = 0;
    this.subscription = this.notificacoesSistemaService.consultarNotificacoesDoUsuario("Não lida").subscribe({
      next: (res) => {
        this.quantidadeTotalDeNotificacao = res.length;
      }, error: (error) => {

      }, complete: () => {
        this.finalizarNofificacoes();
        this.totalDeNotificacoes();
      }
    });
  }

  // public consultarAprovacaoManualDoCliente(){
  //   this.manualDoClienteService.consultarAprovacoesPendentes().subscribe({
  //     next:(res)=>{
  //       this.quantidadeTotalDeNotificacao+=(res.length+this.quantidadeDeInformacaoDocumentadaPendente);
  //       console.log(res.length);
  //     },
  //     error:(e)=>{

  //     },
  //     complete:()=>{

  //     }
  //   });
  // }

  private finalizarNofificacoes() {
    if (this.quantidadeTotalDeNotificacao == 0) {
      // this.stopNotificacoesAutoUpdate();
    }
  }

  public logOffSistema() {
    localStorage.clear();
    window.location.reload();
  }

  public totalDeNotificacoes() {
    EmisorEventoGeralService.quantidadeNotificacao.emit(this.quantidadeTotalDeNotificacao);
  }

  private startNotificacoesAutoUpdate(): void {
    // Verifica se o intervalo já está rodando para evitar duplicações
    this.notificacoesInterval = setInterval(() => {
      this.consultarAprovacaoInformacaoDocumentada();
    }, 10000); // Atualiza a cada 60 segundos
  }

  public stopNotificacoesAutoUpdate(): void {
    if (this.notificacoesInterval) {
      clearInterval(this.notificacoesInterval);
      this.notificacoesInterval = null;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
