<!-- PADRÃO DE MONTAGEM -->
<div class="separador mt-2 mb-2" #padraoIsencao>
    <strong>PADRÃO DE ISENÇÃO</strong>
</div>
@defer (on viewport(padraoIsencao)) {
@if(tabAtiva != ''){
<!-- Nav Tabs -->
<ul class="nav nav-tabs" id="tabPadIsencao" role="tablist">
    @if(dados.processo1 != ''){
    <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="{'active': tabAtiva === 'processo1'}" id="padisencao1-tab" data-bs-toggle="tab"
            href="#padisencao1" (click)="consultarPadraoIsencaoProcesso1(dados.numeroProcesso1)" role="tab"
            aria-controls="processo1" aria-selected="true">{{dados.processo1}}
        </a>
    </li>
    }
    @if (dados.processo2 != '') {
    <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="{'active': tabAtiva === 'processo2'}" id="padisencao2-tab" data-bs-toggle="tab"
            href="#padisencao2" role="tab" (click)="consultarPadraoIsencaoProcesso2(dados.numeroProcesso2)"
            aria-controls="processo2" aria-selected="false">{{dados.processo2}}
        </a>
    </li>
    }
</ul>

<!-- Tab Content -->
<div class="tab-content mt-3" id="myTabContent">
    <!-- Conteúdo da Tab Processo 1 -->
    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo1'}" id="padisencao1" role="tabpanel"
        aria-labelledby="padisencao1-tab">
        <div class="position-img">
            <div class="col-12 ">
                @if(padraoIsencaoProcesso1 == undefined && !imagemNaoCadastrada1){
                <div class="card-load-box">
                    <div class="card_load_extreme_descripion"></div>
                </div>
                }
                @else if (imagemNaoCadastrada1) {
                <div class="text-center text-danger mt-3">
                    <p class="alert alert-danger">Imagem não cadastrada!</p>
                </div>
                }
                <app-swiperComponent [listaDeDados]="padraoIsencaoProcesso1"></app-swiperComponent>
            </div>
        </div>
    </div>

    <!-- Conteúdo da Tab Processo 2 -->
    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo2'}" id="padisencao2" role="tabpanel"
        aria-labelledby="padisencao2-tab">
        <div class="position-img">
            <div class="col-12 ">
                @if(padraoIsencaoProcesso2 == undefined && !imagemNaoCadastrada2){
                <div class="card-load-box">
                    <div class="card_load_extreme_descripion"></div>
                </div>
                }@else if (imagemNaoCadastrada2) {
                <div class="text-center text-danger mt-3">
                    <p class="alert alert-danger">Imagem não cadastrada!</p>
                </div>
                }
                <app-swiperComponent [listaDeDados]="padraoIsencaoProcesso2"></app-swiperComponent>
            </div>
        </div>
    </div>
</div>
}@else {
<div class="text-center text-danger mt-3">
    <p class="alert alert-danger">Padrão de defeitos não cadastrado!</p>
</div>
}
}@loading {
<div class="card-load-box">
    <div class="card_load_extreme_descripion"></div>
</div>
}