<app-container-component tituloPage="Gerenciar Auditoria" subtituloPage="Cadastro e Gestão de Auditoria" >
  <!-- Formulário para Registrar Dados da Auditoria -->
  @if(auditoria.relatorios.length > 0){
  <div class="auditorias-lista mb-2">
    <h3>Auditorias Cadastradas</h3>
    @for (relatorio of auditoria.relatorios; track $index) {
    <div class="auditoria-card">
      <div class="auditoria-header">
        <h5><b>Norma - {{ relatorio.norma }}</b> </h5>
        <h5><b>Processo - {{ relatorio.processo }} </b></h5>
        <p><strong>Auditor:</strong> {{ relatorio.auditor }}</p>
        <p><strong>Data:</strong> {{ relatorio.dataCadastro | date: 'dd/MM/yyyy' }}</p>
      </div>
      <div class="auditoria-body">
        <p><strong>Observações:</strong> {{ relatorio.obs }}</p>
      </div>
      <div class="auditoria-footer">
        <button class="btn editar" (click)="mostrarFormsAcoes(relatorio)">Editar</button>
        <button class="btn excluir" (click)="excluirAuditoria(relatorio)">Excluir</button>
      </div>
    </div>
    }
  </div>
  }
  <!-- <div class="form-container">
    <form class="auditoria-form" [formGroup]="auditoriaForm" (ngSubmit)="registrarAuditoria()">
      <div class="form-group">
          <label for="auditor">Auditor:</label>
          <input type="text" id="auditor" formControlName="auditor" placeholder="Digite o nome do auditor" required />
        </div>
  
        <div class="form-group">
          <label for="data">Data:</label>
          <input type="date" id="data" formControlName="data" required />
        </div>
  
        <div class="form-group">
          <label for="norma">Norma:</label>
          <input type="text" id="norma" formControlName="norma" placeholder="Digite a norma auditada" required />
        </div>
      <div class="form-group">
        <label for="processo">Processo:</label>
        <input type="text" id="processo" formControlName="processo" placeholder="Digite o processo auditado" required />
      </div>

      <div class="form-group">
        <label for="auditados">Auditados:</label>
        <input type="text" id="auditados" formControlName="auditado" placeholder="Digite os auditados" required />
      </div>

      <div class="form-group">
        <label for="observacao">Observação:</label>
        <textarea id="observacao" formControlName="obs" placeholder="Observações sobre a auditoria" required></textarea>
      </div>
      <div class="form-group">
        <label for="campoExtra">Observação:</label>
        <textarea id="campoExtra" formControlName="campoExtra" placeholder="campoExtra" required></textarea>
      </div>
      <button type="submit" class="btn salvar">Registrar Auditoria</button>
    </form>
  </div> -->



  <!-- Mensagem quando não há auditorias -->
  <!-- <div class="no-auditorias" *ngIf="auditorias.length === 0">
      <span>Não há auditorias cadastradas</span>
    </div> -->
    <div class="botao-container">
      <div class="botao" (click)="openClose()">
          @if(!mostrarBolas){
          <i class="fa-solid fa-plus"></i>
          }@else {
          <i class="fa-solid fa-minus"></i>
          }
      </div>
      <div class="bola-adicionar" [class.show]="mostrarBolas" (click)="mostrarFormsAcoes()">
          <span>Registrar relatorio Auditoria</span>
      </div>
  </div>
</app-container-component>