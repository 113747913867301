import { Routes } from '@angular/router';
import { authGuard } from './auth/auth-guard.guard';
import { verificarPermissoesGuard } from './auth/verificar-permissoes.guard';
import { routesDispositivos } from './routes/routes-view/app-dispositivo.routes';
import { routesEngenhariaView } from './routes/routes-view/app.engenharia.routes';
import { routesEngenhariaEdit } from './routes/routes-edit/app-engenharia-private.routes';
import { routesQualidadeView } from './routes/routes-view/app.qualidade.routes';
import { routesQualidadeEdit } from './routes/routes-edit/app.qualidade-private.routes';
import { routesIndicadoresView } from './routes/routes-view/app.indicadores.routes';
import { routesIndicadoresEdit } from './routes/routes-edit/app.indicadores-private.routes';
import { routesGestaoView } from './routes/routes-view/app-gestao.routes';
import { routesGestaoEdit } from './routes/routes-edit/app.gestao-private.routes';
import { routesAcoesView } from './routes/routes-view/app.acoes.routes';
import { routesAcoesEdit } from './routes/routes-edit/app.acoes-private.routes';

import { routesProducao } from './routes/app.producao.routes';
import { MenuIndicadoresComponent } from './paginas/indicadores/menu-indicadores/menu-indicadores.component';
import { MenuComponent } from './paginas/engenharia/menu-eng/menu/menu.component';
import { LoginComponent } from './paginas/login/login.component';
import { HomeComponent } from './paginas/home/home.component';
import { SatisfacaoClienteResponderComponent } from './paginas/controles/satisfacao-cliente/satisfacao-cliente-responder/satisfacao-cliente-responder.component';
import { GestaoAVistaComponent } from './paginas/qualidade/gestao-a-vista/gestao-a-vista.component';
import { MenuQualidadeComponent } from './paginas/qualidade/menu-qualidade/menu-qualidade.component';
import { MenuControlesComponent } from './paginas/controles/menu-controles/menu-controles.component';
import { BookProducaoVisualizarComponent } from './paginas/producao/book-producao-visualizar/book-producao-visualizar.component';
import { routesInspecaoEquipamentos } from './routes/routes-view/app.inspecao-equipamento.routes';
import { RecuperarSenhaComponent } from './paginas/recuperar-senha/recuperar-senha.component';
import { MenuAcoesComponent } from './paginas/acoes/menu-acoes/menu-acoes.component';

import { MenuManutencaoComponent } from './paginas/manutencao/menu-manutencao/menu-manutencao.component';
import { PcpMenuComponent } from './paginas/pcp/pcp-menu/pcp-menu.component';
import { routesPcp } from './routes/routes-view/app.pcp.routes';
import { routesControleView } from './routes/routes-view/app.controle.routes';
import { rotaManutencao } from './routes/app-menutencao.routes';
import { MenuDispositivoComponent } from './paginas/dispositivos/menu-dispositivo/menu-dispositivo.component';
import { MenuInspecaoComponent } from './paginas/inspecao-equipamentos/menu-inspecao/menu-inspecao.component';
import { UsuarioConfigComponent } from './paginas/usuario/usuario-config/usuario-config.component';
import { routesNotificacoesSistema } from './routes/app.notificacoes.routes';
import { ConsultaComponent } from './paginas/infra/notificacoes-sistema/consulta/consulta.component';
import { MenuAdministracaoComponent } from './paginas/administrador/menu-administracao/menu-administracao.component';
import { MenuGestaoComponent } from './paginas/gestao/menu-gestao/menu-gestao.component';
import { routesControlePrivada } from './routes/routes-edit/app.controle-privada.routes';
import { routesDispositivosEdit } from './routes/routes-edit/app.dispositivos-private.routes';
import { routesAdministrador } from './routes/routes-edit/app.admin.routes';
import { routesInspecaoEquipamentosEdit } from './routes/routes-edit/app.inspecao-equipamento-private.routes';


export const routes: Routes = [
    //ROTA PUBLICA
    { path: "", redirectTo: "home", pathMatch: 'full' },
    { path: "home", component: HomeComponent, canActivate: [authGuard] },
    { path: "usuario-config", component: UsuarioConfigComponent, canActivate: [authGuard] },
    { path: "login", component: LoginComponent},
    { path: "login/:mail", component: LoginComponent },
    { path: "recuperar-senha/:email/:token", component: RecuperarSenhaComponent,},
    { path: "usuario-config", component: UsuarioConfigComponent },
    { path: "resposta/satisfacao-cliente/:id/:token", component: SatisfacaoClienteResponderComponent },
    //Notificações do sistema
    {
        path: "notificacoes-sistema", component: ConsultaComponent,
        children: [...routesNotificacoesSistema],
        canActivate: [authGuard]
    },


    // ROTAS RESPONSAVEIS POR ENGENHARIA
    {
        path: "engenharia", component: MenuComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesEngenhariaView.map(route => ({
                ...route,
                data: { permissao: ['ROLE_ENG_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesEngenhariaEdit.map(route => ({
                ...route,
                data: { permissao: ['ROLE_ENG_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_ENG_VIS', 'ROLE_ADMIN'] }
    },

    // ROTAS RESPONSAVEIS POR QUALIDADE
    {
        path: "qualidade", component: MenuQualidadeComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesQualidadeView.map(route => ({
                ...route,
                data: { permissao: ['ROLE_QUA_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesQualidadeEdit.map(route => ({
                ...route,
                data: { permissao: ['ROLE_QUA_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_QUA_VIS', 'ROLE_ADMIN'] }
    },

    // ROTA PCP
    {
        path: "pcp", component: PcpMenuComponent,
        children: [...routesPcp],
        canActivate: [authGuard, verificarPermissoesGuard],
        data: { permissao: ['ROLE_PCP_VIS', 'ROLE_ADMIN'] }
    },

    // ROTA GESTÃO
    {
        path: "gestao", component: MenuGestaoComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesGestaoView.map(routeView => ({
                ...routeView,
                data: { permissao: ['ROLE_GEST_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesGestaoEdit.map(routeEdit => ({
                ...routeEdit,
                data: { permissao: ['ROLE_GEST_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_GEST_VIS', 'ROLE_ADMIN'] }
    },

    // ROTA INDICADORES
    {
        path: "indicadores", component: MenuIndicadoresComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesIndicadoresView.map(routeView => ({
                ...routeView,
                data: { permissao: ['ROLE_IND_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesIndicadoresEdit.map(routeEdit => ({
                ...routeEdit,
                data: { permissao: ['ROLE_IND_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_IND_VIS', 'ROLE_ADMIN'] }
    },

    // ROTAS RESPONSAVEIS POR CONTROLES
    {
        path: "controles", component: MenuControlesComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesControleView.map(route => ({
                ...route,
                data: { permissao: ['ROLE_CONT_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesControlePrivada.map(route => ({
                ...route,
                data: { permissao: ['ROLE_CONT_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_CONT_VIS', 'ROLE_ADMIN'] }
    },

    // ACÕES
    {
        path: "acoes", component: MenuAcoesComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesAcoesView.map(route => ({
                ...route,
                data: { permissao: ['ROLE_ACAO_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesAcoesEdit.map(route => ({
                ...route,
                data: { permissao: ['ROLE_ACAO_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_ACAO_VIS', 'ROLE_ADMIN'] }
    },

    // ROTAS RESPONSAVEIS POR DISPOSITIVOS
    {
        path: "dispositivo", component: MenuDispositivoComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesDispositivos.map(route => ({
                ...route,
                data: { permissao: ['ROLE_DISP_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesDispositivosEdit.map(route => ({
                ...route,
                data: { permissao: ['ROLE_DISP_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_DISP_VIS', 'ROLE_ADMIN'] }
    },

    // ROTA INSPECAO
    {
        path: "inspecao", component: MenuInspecaoComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesInspecaoEquipamentos.map(route => ({
                ...route,
                data: { permissao: ['ROLE_INSP_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesInspecaoEquipamentosEdit.map(route => ({
                ...route,
                data: { permissao: ['ROLE_INSP_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_INSP_VIS', 'ROLE_ADMIN'] }
    },

    // ROTA Manuntenção
    {
        path: "manutencao", component: MenuManutencaoComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...rotaManutencao.map(route => ({
                ...route,
                data: { permissao: ['ROLE_MANUT_VIS', 'ROLE_ADMIN'] }
            })),
            ...rotaManutencao.map(route => ({
                ...route,
                data: { permissao: ['ROLE_MANUT_EDIT', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_MANUT_VIS', 'ROLE_ADMIN'] }
    },

    //ROTA PUBLICA
    {
        path: "gestao-a-vista", component: GestaoAVistaComponent
    },

    //PRODUÇÃO
    {
        path: "producao/book", component: BookProducaoVisualizarComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesProducao.map(route => ({
                ...route,
                data: { permissao: ['ROLE_PROD_VIS', 'ROLE_ADMIN'] }
            })),
            ...routesProducao.map(route => ({
                ...route,
                data: { permissao: ['ROLE_PROD_VIS', 'ROLE_ADMIN'] }
            }))
        ],
        data: { permissao: ['ROLE_PROD_VIS', 'ROLE_ADMIN'] }

    },

    // //ROTA ADMINISTRADOR
    {
        path: "administrador", component: MenuAdministracaoComponent,
        canActivate: [authGuard, verificarPermissoesGuard],
        canActivateChild: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesAdministrador.map(route => ({
                ...route,
                data: { permissao: ['ROLE_ADMIN'] }
            })),
        ],
        data: { permissao: ['ROLE_ADMIN'] }
    },
    //ROTA COMPONENTE TESTE
    {
        path: "teste", loadComponent: () => import("./paginas/teste/teste.component").then(t => t.TesteComponent), children: [
            { path: "teste-home", loadComponent: () => import("./paginas/teste/componente-exibicao-teste/componente-exibicao-teste.component").then(a => a.ComponenteExibicaoTesteComponent) },
        ]
    },
];

