import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpj',
  standalone: true
})
export class CnpjPipe implements PipeTransform {

  transform(value: string | number): String {
    let cnpj = value.toString();

    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/\D/g, '');

    // Aplica a máscara se o CNPJ tiver 14 dígitos
    if (cnpj.length === 14) {
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,'$1.$2.$3/$4-$5');
    }

    return cnpj; // Retorna o valor original se não tiver 14 dígitos
  }

}
