import { Component, inject, OnInit } from '@angular/core';
import { AnaliseCritica } from '../../../../model/acoes/analise-critica/AnaliseCritica';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { AnaliseCriticaService } from '../../../../services/acoes/analise-critica/analise-critica.service';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-analise-critica-lista',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, PaginatorComponent, RouterLink, FormsModule],
  templateUrl: './analise-critica-lista.component.html',
  styleUrl: './analise-critica-lista.component.scss'
})
export class AnaliseCriticaListaComponent implements OnInit {

  listaAnaliseCritica: AnaliseCritica[] = [];
  valorPesquisado: string = "";
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];

  private analiseCriticaService = inject(AnaliseCriticaService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);


  ngOnInit(): void {
    this.consultaPaginada();
  }

  public consultaPaginada(event?: any) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.analiseCriticaService.consultaAnaliseCriticaPaginada(this.paginaAtual, this.totalPorPagina,this.valorPesquisado).subscribe({
      next: (res) => {
        this.listaAnaliseCritica = res.content;
        this.processarElementosPagina(res.page);
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }


  private processarElementosPagina(page: any) {
    this.paginaAtual = page.number;
    this.totalTamanho = page.totalElements;
  }


  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.consultaPaginada(event); // Chama o método buscar se a tecla Enter for pressionada
    }
  }


  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultaPaginada();
  }

  public visualizarAnaliseCritica(analiseCritica:number){

  }


}
