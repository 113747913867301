<mat-drawer-container class="example-container h-100">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <div class="row mb-1 mt-1 ms-1">
            <div class="d-none d-lg-flex col-1">
                @if(menuFixo){
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Desfixar menu"></i></div>
                }@else {
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack-slash" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Fixar menu"></i></div>
                }
            </div>
            <div class="col-10  col-md-11 d-flex justify-content-start justify-content-lg-center align-items-center">
                <a class="logo d-flex justify-content-center align-items-center gap-2">INDICADORES</a>
            </div>
            <div class="col-2  z-1 d-flex align-items-center  justify-content-end" (click)="clicarMenu(drawer)"
                (click)="mudarIcone()">
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div class="menu-content">
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                    (click)="abrirMenu('home')" routerLink="/indicadores/home">
                    <span class="font-titulo"><i class="fa-solid fa-house"></i> <span class="ms-2">Home
                        </span></span>
                </div>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['indicador-performance']}"
                    (click)="abrirMenu('indicador-performance')">
                    <span class="font-titulo"><i class="fa-solid fa-chart-simple"></i><span class="ms-2">Indicador
                            performance SGQ</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['indicador-performance']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['indicador-performance']}">
                    @for (link of getMenuIndicadoresPerformance(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }

                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['indicadores-gerenciais']}"
                    (click)="abrirMenu('indicadores-gerenciais')">
                    <span class="font-titulo"><i class="fa-solid fa-bars-progress"></i> <span class="ms-2">Indicadores
                            Gerenciais</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['indicadores-gerenciais']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['indicadores-gerenciais']}">
                    @for (link of getMenuIndicadoresGerenciais(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" routerLinkActive="active-solo" (click)="clicarMenu(drawer)"
                            (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['indicador-monitoramento']}"
                    (click)="abrirMenu('indicador-monitoramento')">
                    <span class="font-titulo"><i class="fa-solid fa-chart-pie"></i> <span class="ms-2">Indicador
                            monitoramento</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['indicador-monitoramento']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['indicador-monitoramento']}">
                    @for (link of getMenuIndicadoresMonitoramento(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" routerLinkActive="active-solo" (click)="clicarMenu(drawer)"
                            (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['indicador-iqf-cliente']}"
                    (click)="abrirMenu('indicador-iqf-cliente')">
                    <span class="font-titulo"><i class="fa-solid fa-chart-area"></i> <span class="ms-2">IQF
                            clientes</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['indicador-iqf-cliente']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['indicador-iqf-cliente']}">
                    @for (link of getMenuIndicadoresClientes(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" routerLinkActive="active-solo" (click)="clicarMenu(drawer)"
                            (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['indicador-iqf-fornecedores']}"
                    (click)="abrirMenu('indicador-iqf-fornecedores')">
                    <span class="font-titulo"><i class="fa-solid fa-magnifying-glass-chart"></i> <span
                            class="ms-2">IQF Fornecedores</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['indicador-iqf-fornecedores']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['indicador-iqf-fornecedores']}">
                    @for (link of getMenuIndicadoresForneceres(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" routerLinkActive="active-solo" (click)="clicarMenu(drawer)"
                            (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['indicador-obj-qualidade']}"
                    (click)="abrirMenu('indicador-obj-qualidade')">
                    <span class="font-titulo"><i class="fa-solid fa-ranking-star"></i><span class="ms-2">Objetivo
                            qualidade</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['indicador-obj-qualidade']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['indicador-obj-qualidade']}">
                    @for (link of getMenuIndicadoresObjQualidade(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" routerLinkActive="active-solo" (click)="clicarMenu(drawer)"
                            (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
        </div>
    </mat-drawer>
    <div class="example-sidenav-content flex-column justify-content-start align-items-start ">
        <a class="btn-menu" (click)="clicarFechar(drawer)" (click)="mudarIcone()">
            <i *ngIf="!open" class="fa-solid fa-angles-left"></i>
            <i *ngIf="open" class="fa-solid fa-angles-right"></i>
        </a>
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>