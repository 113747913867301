
import { CanActivateFn, Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login/login.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
    const loginService = inject(LoginService);
    const router = inject(Router);
    const activatedRoute = inject(ActivatedRoute);
    let id = 0;
    let token = "";
    activatedRoute.params.subscribe((res:any)=>{
      id = parseInt(res.id)
      token = res.token;
    });
    
    if(loginService.estaLogado()){
      return true
    }else{
      router.navigate(['/login']);
      return false;
    }
};
