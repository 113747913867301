<app-container-component tituloPage="AÇÕES" subtituloPage="Ações visualizar" [header]="ativarHeader">
    <div class="actions-container">
        @if(planejamentoComunicacaoRecebido && planejamentoComunicacaoRecebido.itens && planejamentoComunicacaoRecebido.itens.length > 0){
            @for(acao of planejamentoComunicacaoRecebido.itens; track $index){
                <div class="action-card">
                    <div class="action-header">
                        <strong>{{acao.parteInteressada}}</strong>
                    </div>
                    <div class="action-content">
                        <p><strong>O que comunica:</strong> {{acao.oqueComunica}}</p>
                        <p><strong>Quando comunica:</strong> {{acao.quandoComunica}}</p>
                        <p><strong>Público alvo:</strong> {{acao.publicoAlvo}}</p>
                        <p><strong>Canal de comunicação:</strong> {{acao.canalComunicacao}}</p>
                        <p><strong>Responsável pela comunicação:</strong> {{acao.respComunicacao}}</p>
                    </div>
                    @if(!ativarHeader){
                        <div class="action-footer">
                            @if(temPermissao()){
                                <button class="btn edit" (click)="editarAcaoPlanejamentoComunicacaoSelecionada(acao)">
                                    <i class="fas fa-edit"></i>
                                </button>
                                @if(temPermissaoExcluir()){
                                    <button class="btn delete" (click)="deletarAcaoPlanejamentoComunicacaoSelecionada(acao)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                }
                            }
                        </div>
                    }
                </div>
            }
        }@else {
            <span class="no-actions">Não há ações cadastradas</span>
        }
    </div>
</app-container-component>