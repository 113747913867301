import { Routes } from "@angular/router";


export const routesDispositivos: Routes = [
    { path: "dispositivo", loadComponent: () => import("../paginas/dispositivos/dispositivo/dispositivos.component").then(d => d.DispositivosComponent) },
    { path: "dispositivo/:id", loadComponent: () => import("../paginas/dispositivos/dispositivo/dispositivos.component").then(d => d.DispositivosComponent) },
    { path: "dispositivo-lista", loadComponent: () => import("../paginas/dispositivos/dispositivos-lista/dispositivos-lista.component").then(d => d.DispositivosListaComponent) },
    { path: "dispositivo-isencao", loadComponent: () => import("../paginas/dispositivos/dispositivo-isencao/dispositivo-isencao.component").then(d => d.DispositivoIsencaoComponent) },
    { path: "dispositivo-isencao/:id", loadComponent: () => import("../paginas/dispositivos/dispositivo-isencao/dispositivo-isencao.component").then(d => d.DispositivoIsencaoComponent) },
    { path: "dispositivo-isencao-lista", loadComponent: () => import("../paginas/dispositivos/dispositivos-isencao-lista/dispositivos-isencao-lista.component").then(d => d.DispositivosIsencaoListaComponent) },
]