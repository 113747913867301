<app-container-component tituloPage="Registrar Auditoria" subtituloPage="Dados da Auditoria">
    <form class="form-group row d-flex justify-content-start formulario-produto col-12"
        [formGroup]="formularioCadastro">
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Identificação da Auditoria:</label>
            <div class="position-relative">
                <input type="text" formControlName="identificacao" class="form-control form-control-sm"
                    [ngClass]="{'errorAnimacao':tocado('identificacao'),'validoAnimacao':validar('identificacao')}">
                <span class="input-icon"
                    [ngClass]="{'valid-icon': validar('identificacao'), 'invalid-icon': tocado('identificacao')}">
                    <i class="fas fa-check-circle" *ngIf="validar('identificacao')"></i>
                    <i class="fas fa-exclamation-circle" *ngIf="tocado('identificacao')"></i>
                </span>
            </div>
            <app-mensagem-errors [control]="obterValidacao('normaAuditada')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Norma Auditada:</label>
            <div class="position-relative">
                <input type="text" formControlName="normaAuditada" class="form-control form-control-sm"
                    [ngClass]="{'errorAnimacao':tocado('normaAuditada'),'validoAnimacao':validar('normaAuditada')}">
                <span class="input-icon"
                    [ngClass]="{'valid-icon': validar('normaAuditada'), 'invalid-icon': tocado('normaAuditada')}">
                    <i class="fas fa-check-circle" *ngIf="validar('normaAuditada')"></i>
                    <i class="fas fa-exclamation-circle" *ngIf="tocado('normaAuditada')"></i>
                </span>
            </div>
            <app-mensagem-errors [control]="obterValidacao('auditorLider')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Auditor Lider:</label>
            <div class="position-relative">
                <input type="text" formControlName="auditorLider" class="form-control form-control-sm"
                    [ngClass]="{'errorAnimacao':tocado('auditorLider'),'validoAnimacao':validar('auditorLider')}">
                <span class="input-icon"
                    [ngClass]="{'valid-icon': validar('auditorLider'), 'invalid-icon': tocado('auditorLider')}">
                    <i class="fas fa-check-circle" *ngIf="validar('auditorLider')"></i>
                    <i class="fas fa-exclamation-circle" *ngIf="tocado('auditorLider')"></i>
                </span>
            </div>
            <app-mensagem-errors [control]="obterValidacao('exclusaoEscopo')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Equipe auditora:</label>
            <div class="position-relative">
                <input type="text" formControlName="equipeAuditora" class="form-control form-control-sm"
                    [ngClass]="{'errorAnimacao':tocado('equipeAuditora'),'validoAnimacao':validar('equipeAuditora')}">
                <span class="input-icon"
                    [ngClass]="{'valid-icon': validar('equipeAuditora'), 'invalid-icon': tocado('equipeAuditora')}">
                    <i class="fas fa-check-circle" *ngIf="validar('equipeAuditora')"></i>
                    <i class="fas fa-exclamation-circle" *ngIf="tocado('equipeAuditora')"></i>
                </span>
            </div>
            <app-mensagem-errors [control]="obterValidacao('equipeAuditora')"></app-mensagem-errors>
        </div>

        <div class="mb-3 col-12">
            <label class="form-label">Exclusão de Escopo:</label>
            <div class="position-relative">
                <textarea type="text" formControlName="exclusaoEscopo" class="form-control form-control-sm"
                    [ngClass]="{'errorAnimacao':tocado('exclusaoEscopo'),'validoAnimacao':validar('exclusaoEscopo')}"></textarea>
                <span class="input-icon"
                    [ngClass]="{'valid-icon': validar('exclusaoEscopo'), 'invalid-icon': tocado('exclusaoEscopo')}">
                    <i class="fas fa-check-circle" *ngIf="validar('exclusaoEscopo')"></i>
                    <i class="fas fa-exclamation-circle" *ngIf="tocado('exclusaoEscopo')"></i>
                </span>
            </div>
            <app-mensagem-errors [control]="obterValidacao('justificativa')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12">
            <label class="form-label">Justificativa:</label>
            <div class="position-relative">
                <textarea type="text" formControlName="justificativa" class="form-control form-control-sm"
                    [ngClass]="{'errorAnimacao':tocado('justificativa'),'validoAnimacao':validar('justificativa')}"></textarea>
                <span class="input-icon"
                    [ngClass]="{'valid-icon': validar('justificativa'), 'invalid-icon': tocado('justificativa')}">
                    <i class="fas fa-check-circle" *ngIf="validar('justificativa')"></i>
                    <i class="fas fa-exclamation-circle" *ngIf="tocado('justificativa')"></i>
                </span>
            </div>
            <app-mensagem-errors [control]="obterValidacao('justificativa')"></app-mensagem-errors>
        </div>
        <div class="row">
            @if(auditoria.id){

            <app-botao label="Editar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-lápis.png"
                status="btn-salvar" (click)="editarAuditoria()"></app-botao>

            }@else {
            <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="cadastrarAuditoria()"></app-botao>
            }
        </div>


    </form>
</app-container-component>
@if(auditoria.id){
<app-container-component [header]="false" subtituloPage="Arquivos">
    <div class="arquivos-lista row gap-3 justify-content-center">
        @for (arquivo of auditoria.arquivos; track $index) {
        <div class="arquivo-card col-12 col-sm-8 col-lg-4 col-xl-3">
            <!-- <div class="arquivo-header">
                    <span class="arquivo-nome">{{ arquivo.arquivo }}</span>
                </div> -->
            <div class="arquivo-body">
                <p><strong>Tipo de Arquivo:</strong> {{ arquivo.tipo }}</p>
                <img [src]="verificarFormato(arquivo.arquivo)" width="48px" height="48px">
                <div class="d-block">
                    <p><strong>Descrição:</strong> {{ arquivo.descricao }}</p>
                </div>
            </div>
            <div class="arquivo-footer">
                <a [href]="arquivo.path" target="_blank" rel="noopener noreferrer">
                    <button class="btn acessar">acessar/Download</button>
                </a>
                <button class="btn excluir" (click)="excluirArquivo(arquivo)">Excluir</button>
            </div>
        </div>
        }
    </div>
    <app-input-imagens (emissaoLista)="receberListaDeArquivos($event)" [multipleImagem]="false"
        [quantidadePermitida]="1"></app-input-imagens>
    @if(arquivosSelecionados.length > 0){
    <div class="mb-3 col-12">
        <label class="form-label">Descricao:</label>
        <div class="position-relative">
            <input type="text" [(ngModel)]="descricaoArquivo" class="form-control form-control-sm">
        </div>
    </div>
    <div class="row">
        <app-botao label="Salvar novo Arquivo" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
            status="btn-salvar" (click)="cadastrarNovoArquivo()"></app-botao>
    </div>
    }
</app-container-component>
}