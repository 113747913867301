<app-container-component [tituloPage]="titulo" [header]="comTopo" [subtituloPage]="nomeFormulario">
    <table class="table table-sm table-striped">
        <thead>
            <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Local</th>
                <th>Status</th>
                <th>Prox. Calibração</th>
                @if(temPermissao()){
                <th class="text-center">Editar</th>
                }
                @if(temPermissao()){
                <th class="text-center">Gerenciar</th>
                }
            </tr>
        </thead>
        <tbody>
            @for (medicao of listaDeMedicaoMonitoramento; track $index) {
            <tr>
                <td>{{medicao.codRecurso}}</td>
                <td>{{medicao.descricaoRecurso}}</td>
                <td>{{medicao.armarzenagemRecurso}}</td>
                <td>{{medicao.status}}</td>
                <td>{{medicao.proximaValidacao ? (medicao.proximaValidacao | date : 'dd/MM/yyyy') : '-'}}</td>
                @if(temPermissao()){
                <td (click)="editarMedicaoMonitoramento(medicao.id)" class="text-center"><i
                        class="fa-solid fa-pencil"></i></td>
                }
                @if(temPermissao()){
                <td (click)="gerenciarMedicaoMonitoramento(medicao.id)" class="text-center"><i
                        class="fa-solid fa-gear"></i></td>
                }

            </tr>
            }
        </tbody>
    </table>
    <app-paginator (paginaMudou)="mudancaNaPagina($event)" [paginaAtual]="paginaAtual"
        [quantidadesPorPagina]="quantidadesPorPagina" [totalElementos]="totalTamanho" [totalPorPagina]="totalPorPagina">
    </app-paginator>
</app-container-component>