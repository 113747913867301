<div class="separador mt-2 mb-2" #idTeste>
    <strong>DADOS DO PRODUTO</strong>
</div>

@defer (on viewport(idTeste)) {
    <div class="p-2">
        <table class="table table-sm table-striped">
            <tbody>
                <tr>
                    <td><strong>CLIENTE: </strong></td>
                    <td>{{this.produto.nomeCliente}}</td>
                </tr>
                <tr>
                    <td><strong>CÓDIGO: </strong></td>
                    <td>{{produto.cdProduto}}</td>
                </tr>
                <tr>
                    <td><strong>DESCRIÇÃO: </strong></td>
                    <td>{{produto.nomeProduto}}</td>
                </tr>
                <tr>
                    <td><strong>BENEFICIAMENTO: </strong></td>
                    <td>{{produto.nomeBeneficiamento}}</td>
                </tr>
                <tr>
                    <td><strong>PESO: </strong></td>
                    <td>{{produto.peso | number : '1.0-3'}}</td>
                </tr>
                <tr>
                    <td><strong>ÁREA: </strong></td>
                    <td>{{produto.area | number : '1.0-3' }}</td>
                </tr>
                <tr>
                    <td><strong>ESPESSURA: </strong></td>
                    <td>{{produto.espessura | number : '1.0-3'}}</td>
                </tr>
                <!-- <tr>
                    <td><strong>MATERIAL: </strong></td>
                    <td>{{produto.material}}</td>
                </tr> -->
                <!-- <tr>
                    <td><strong>ITEM DE SEGURANÇA: </strong></td>
                    <td>{{produto.itemSeguranca === false ? 'NÃO' : 'SIM'}}</td>
                </tr>
                <tr>
                    <td><strong>ITEM DE APARENCIA: </strong></td>
                    <td>{{produto.itemAparencia === false ? 'NÃO' : 'SIM'}}</td>
                </tr>
                <tr>
                    <td><strong>ITEM ZINCADO: </strong></td>
                    <td>{{produto.zincado === false ? 'NÃO' : 'SIM'}}</td>
                </tr> -->
            </tbody>
        </table>
    </div>
}@loading {
    <div class="card-load-box">
        <div class="card_load_extreme_descripion"></div>
      </div>
}
