<app-container-component tituloPage="Home MSA" subtituloPage="Lista de msa">
    <!-- <div class="col-12 d-flex flex-column justify-content-end align-items-end">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mt-2 me-2">
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="valorPesquisado"
                    (keypress)="this.onKeyPress($event)" placeholder="N° , Cliente , Item, Status, RNC, RRC">
                <button class="btn btn-sm" type="button"><i class="fa-solid fa-magnifying-glass"
                        (click)="this.buscar($event)"></i></button>
            </div>
        </div>
    </div> -->
    <div class="table-responsive">
        <table class="table table-hover table-sm pointer table-striped align-middle">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">MSA</th>
                    <th scope="col">Identificação</th>
                    <th scope="col">Instrumento</th>
                    <th scope="col" class="text-center">Análise</th>
                    <th scope="col" class="text-center">Data</th>
                </tr>
            </thead>
            <tbody>
                @if(listaDeMsa.length > 0) {
                @for (msa of listaDeMsa; track $index) {
                <tr title="Clique para visualizar" class="table-row" (click)="editarMsa(msa.id)">
                    <td data-label="MSA">{{ msa.id }}</td>
                    <td data-label="Identificação">{{ msa.identificacao }}</td>
                    <td data-label="Instrumento">{{ msa.instrumento }}</td>
                    <td data-label="Análise" class="text-center">
                        <span class="badge" [ngClass]="{
                          'aprovado': msa.anSistema == 'Aprovado',
                          'reprovado': msa.anSistema == 'Reprovado',
                      }">
                            {{ msa.anSistema }}
                        </span>
                    </td>
                    <td data-label="Data" class="text-center">{{ msa.dataCadastro | date: 'dd/MM/yy' }}</td>
                </tr>
                }
                }
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center col-12">
        <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
            [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
            (paginaMudou)="onPageChange($event)"></app-paginator>
    </div>
</app-container-component>