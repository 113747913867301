import { ValidatorsService } from './../../../../../utils/validators.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { Processo } from '../../../../../enum/Processo';
import { BotaoComponent } from '../../../../../componentes/botao/botao.component';
import { DeterminacaoProcessoPoService } from '../../../../../services/engenharia/processo/eng-determinacao-processo-po.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../../../../../utils/snack-bar.service';
import { FormValidacaoService } from '../../../../../utils/formValidacao.service';
import { LoginService } from '../../../../../services/login/login.service';
import { Usuario } from '../../../../../model/usuario/usuario';
import moment from 'moment';
import { MensagemErrorsComponent } from "../../../../../componentes/mensagem-errors/mensagem-errors.component";
import { ContainerComponentComponent } from "../../../../../componentes/container-component/container-component.component";

@Component({
  selector: 'app-eng-determinacao-processo-po',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BotaoComponent, MensagemErrorsComponent, ContainerComponentComponent],
  templateUrl: './eng-determinacao-processo-po.component.html',
  styleUrl: './eng-determinacao-processo-po.component.scss'
})
export class EngDeterminacaoProcessoPoComponent implements OnInit, OnChanges {

  @Input() dadosFormulario: any;
  formularioDeterminacaoProcessoPo!: FormGroup;
  processo = Processo;
  cdProduto: any;
  idProcesso: any;
  usuario: Usuario = new Usuario();
  @Output() processoSalvo: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private determinacaoProcessoPoService: DeterminacaoProcessoPoService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private formValidacoesServices: FormValidacaoService,
    private loginService: LoginService,
    private validatorsService: ValidatorsService,
  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
    if (changes['dadosFormulario'] && !changes['dadosFormulario'].firstChange) {
      this.formularioDeterminacaoProcessoPo.patchValue(changes['dadosFormulario'].currentValue);
    }
  }
  ngOnInit(): void {
    this.pegarDadosUsuario();
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
    this.iniciarFormulario();
    this.formularioDeterminacaoProcessoPo.patchValue(this.dadosFormulario);
  }


  public iniciarFormulario() {
    this.formularioDeterminacaoProcessoPo = this.formBuilder.group({
      id: [],
      nome: [],
      cdProduto: [this.cdProduto],
      cdProcesso: [this.processo.PO],
      equipament1: [],
      equipament2: [],
      equipament3: [],
      qtdePecasTravessa: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdeTravessasQuadro: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdePecasQuadro: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      velocMonoviaMax: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      velocMonoviaMin: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      distanciaReciprocador: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      velocReciprocador: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      vazaoReciprocador: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      alturaReciprocadorMax: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      alturaReciprocadorMin: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tensao: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      corrente: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      vazaoPistolaRet: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdePassadaPistolaRet: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      formatoPintura: [],
      parametroCura: [],
      amostraInspecao: [],
      tempoSetupEquipamento: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoSetupDispositivo: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoMontagem: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoIsencaoAnterior: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoMaquina: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoResfriamento: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoDesmontagem: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoInspecao: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoIsencaoPosterior: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoEmbalagem: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      leadTime: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdePecasHoraMaquina: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      camadaMinima: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      camadaMaxima: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      epi: ["Óculos, sapatos de segurança, luvas e protetor auricular"],
      observacao: [],
      dataCadastro: [moment().format("yyyy-MM-DD")],
      usuarioCadastro: [this.usuario.username],
      usuarioAlteracao: [this.usuario.username],
      dataAlteracao: [moment().format("yyyy-MM-DD")]
    });
  };


  public pegarDadosUsuario() {
    this.usuario = this.loginService.decodificarToken();
  }

  public salvarProcesso() {
    if (this.formularioDeterminacaoProcessoPo.valid) {
      this.determinacaoProcessoPoService.salvarDeterminacaoProcesso(this.formularioDeterminacaoProcessoPo.value).subscribe({
        next: (res) => {
          this.snackBarService.openSnackBar("Derminação do processo PÓ criada", "my-snack-bar-sucess");
        }, error: (error) => {
          this.snackBarService.openSnackBar("Derminação do processo PÓ criada", "my-snack-bar-erro");
        }, complete: () => {
          this.processoSalvo.emit(true);
        },
      });
    } else {
      this.snackBarService.openSnackBar("Formulario com campos inválidos", "my-snack-bar-erro");
      this.formularioDeterminacaoProcessoPo.markAllAsTouched();
    }

  }
  public editarProcesso() {
    if (this.formularioDeterminacaoProcessoPo.valid) {
      this.determinacaoProcessoPoService.editarDeterminacaoProcesso(this.formularioDeterminacaoProcessoPo.value).subscribe({
        next: (res) => {
          this.snackBarService.openSnackBar("Derminação do processo PÓ criada", "my-snack-bar-sucess");
        }, error: (error) => {
          this.snackBarService.openSnackBar("Derminação do processo PÓ criada", "my-snack-bar-erro");
        }, complete: () => {
          this.processoSalvo.emit(true);
        },
      });
    } else {
      this.snackBarService.openSnackBar("Formulario com campos inválidos", "my-snack-bar-erro");
      this.formularioDeterminacaoProcessoPo.markAllAsTouched();
    }
  }

  public calcularQuantidadePorRack() {
    this.formularioDeterminacaoProcessoPo.get('qtdePecasQuadro')?.reset();
    let valor = this.formValidacoesServices.calcularQuantidade(this.formularioDeterminacaoProcessoPo.get('qtdePecasTravessa')?.value, this.formularioDeterminacaoProcessoPo.get('qtdeTravessasQuadro')?.value)
    // Converta os valores para inteiros, atribuindo 0 se não for um número válido
    this.formularioDeterminacaoProcessoPo.get('qtdePecasQuadro')?.setValue(valor);
  }


  public limparFormulario() {
    this.formularioDeterminacaoProcessoPo.reset();
    this.iniciarFormulario();
  }

  public autoGrow(event: any) {
    this.formValidacoesServices.autoAjuste(event)
  }

  public obterValidacao(nome: string) {
    return this.formValidacoesServices.obterValidacao(this.formularioDeterminacaoProcessoPo, nome)
  }


}
