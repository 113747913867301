import { ChangeDetectorRef, Component, Inject, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ProdutoDetalheService } from '../../../services/engenharia/produto/produto-detalhe.service';
import { ProdutoDetalheImagemService } from '../../../services/engenharia/produto/produto-detalhe-imagem.service';
import { Produto } from '../../../model/engenharia/produto-model';
import { PaginatorComponent } from "../../../componentes/paginator/paginator.component";
import { LoadCardComponentComponent } from "../../../componentes/loadCardComponent/loadCardComponent.component";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-modal-consulta',
  standalone: true,
  imports: [
      PaginatorComponent, 
      LoadCardComponentComponent, 
      MatDialogModule,
      CommonModule,
      FormsModule
    ],
  templateUrl: './modal-consulta-engenharia.component.html',
  styleUrl: './modal-consulta-engenharia.component.scss'
})
export class ModalConsultaEngenhariaComponent implements OnInit{

    produtos: any[] = [];
    paginaAtual: number = 0;
    totalTamanho: number = 0;
    quantidadesPorPagina: any[] = [3, 8, 12, 16];
    totalPorPagina: number = this.quantidadesPorPagina[0];
    tooltipPosition = { x: 0, y: 0 }; // Armazena a posição do tooltip
    nomeProduto: string = '';
    exibeAlerta: boolean = false;
    baseUrl = window.location.origin;
    router = inject(Router);
    loginService = inject(LoginService);
    url: string = "";
    urlTemp: string = "";
    situacao: string = "Todos";
    situacoes:string[]=['Amostra',"Produção","Desenvolvimento N1","Desenvolvimento N2","Aguardando aprovação","Inativa"];
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private produtoService: ProdutoDetalheService,
      private dialogRef: MatDialogRef<ModalConsultaEngenhariaComponent>,
      private imagemService: ProdutoDetalheImagemService,
      private cdr: ChangeDetectorRef
    ) {
  
    } 
  
    ngOnInit(): void {
      console.log(this.data);
      if (this.data) {
        this.exibeAlerta = false;
        this.nomeProduto = this.data.nomeProduto;
        //(/\/\d+$/) é uma expressão regular que procura um / seguido de um ou mais números no final da URL.
        this.urlTemp = this.router.url.replace(/\/\d+$/, '');
        this.url = `${this.baseUrl}/#${this.urlTemp}`;
        this.consultarProdutos();
      }
  
    }
  
    public consultarProdutos() {
      this.exibeAlerta = false;
      this.produtoService.consultaPorNomeProdutoEhSituacao(this.paginaAtual, this.totalPorPagina, this.nomeProduto, this.situacao).subscribe({
        next: (res) => {
          this.totalPorPagina = res.page.size;
          this.totalTamanho = res.page.totalElements;
          this.preencherDadosDoProduto(res.content);
          this.consultarImagem();
        }, error: (e) => {
          console.log(e)
        }
      });
    }
  
    public preencherDadosDoProduto(produtos: any) {
      this.produtos = produtos;
    }
  
    public consultarImagem() {
      this.produtos.forEach((p: any) => {
        p.imagemCarregada = false;
        this.imagemService.consultarTodasImagemPorIdProduto(p.cdProduto).subscribe({
          next: (res) => {
            p.imagem = res[0] ? res[0].path : './assets/Produto-sem-imagem.png';
            this.cdr.detectChanges();
          }, error: (error) => {
            p.imagem = './assets/Produto-sem-imagem.png';
            this.cdr.detectChanges();
          }
        });
        p.imagemCarregada = false;
      });
    }
  
    loadingImagem(produto: any) {
      produto.imagemCarregada = true;
      this.cdr.detectChanges();
    }
  
    onChange() {
      this.paginaAtual = 0;
    }
  
    onKeyPress(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        if (this.nomeProduto == "") {
          this.situacao = "Todos";
          this.nomeProduto = " "
          this.consultarProdutos();
        } else {
          this.consultarProdutos();
        }
      }
    }
  
    pegarIcone(situacao: string): string {
      const iconMap: { [key: string]: string } = {
        'Amostra': 'fa-swatchbook',
        'Produção': 'fa-people-carry-box',
        'Desenvolvimento N1': 'fa-gear',
        'Aguardando aprovação': 'fa-clock',
        'Desenvolvimento N2': 'fa-gears', 
        'Inativa': 'fa-triangle-exclamation'
      };
      return iconMap[situacao];
    }
  
    pegarCor(situacao: string): string {
      const iconMap: { [key: string]: string } = {
        'Amostra': 'amostra',
        'Produção': 'producao',
        'Desenvolvimento N1': 'emAndamento',
        'Aguardando aprovação': 'aguardando',
        'Desenvolvimento N2': 'finalizado', 
        'Inativa': 'inativo'
      };
      return iconMap[situacao];
    }
  
    public mudancaNaPagina(evento: any) {
      this.paginaAtual = evento.pageIndex;
      this.totalPorPagina = evento.pageSize;
      this.consultarProdutos();
    }
  
    public closeModal() {
      this.dialogRef.close();
    }
  
    public selecionarProduto(produto: Produto) {
      this.dialogRef.close(produto);
    }
  
    public consultaProdutoSuperSmart() {
      this.produtoService.consultarProdutoPaginado(0, 30, this.nomeProduto).subscribe({
        next: (res) => {
          console.log(res);
        }
      });
    }

}
