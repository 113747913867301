import { Routes } from "@angular/router";
import { ControlesHomeComponent } from "../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-home/controles-home.component";

export const routesControle: Routes = [
    
    { path: "home", component: ControlesHomeComponent },
    { path: "cadastrar-medicao-monitoramento", loadComponent: () => import("../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-cadastro/controles-cadastro.component").then(c => c.ControlesCadastroComponent) },
    { path: "cadastrar-medicao-monitoramento/:id", loadComponent: () => import("../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-cadastro/controles-cadastro.component").then(c => c.ControlesCadastroComponent) },
    { path: "lista-medicao-monitoramento", loadComponent: () => import("../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-lista/controles-medicao-monitoramento-lista.component").then(c => c.ControlesMedicaoMonitoramentoListaComponent) },
    { path: "resultado-medicao-monitoramento/:id", loadComponent: () => import("../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-resultado/controles-medicao-monitoramento-resultado.component").then(c => c.ControlesMedicaoMonitoramentoResultadoComponent) },

    //SATISFAÇÃO CLIENTE
    { path: "home-satisfacao-cliente", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-home/satisfacao-cliente-home.component").then(s => s.SatisfacaoClienteHomeComponent) },
    { path: "cadastrar-satisfacao-cliente", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-cadastrar/satisfacao-cliente-cadastrar.component").then(s => s.SatisfacaoClienteCadastrarComponent) },
    { path: "cadastrar-satisfacao-cliente/:id", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-cadastrar/satisfacao-cliente-cadastrar.component").then(s => s.SatisfacaoClienteCadastrarComponent) },
    { path: "lista-satisfacao-cliente", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-lista/satisfacao-cliente-lista.component").then(s => s.SatisfacaoClienteListaComponent) },
    { path: "editar-satisfacao-cliente/:id", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-editar/satisfacao-cliente-editar.component").then(s => s.SatisfacaoClienteEditarComponent) },
    { path: "resultados-satisfacao-cliente/:id", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-resultado/satisfacao-cliente-resultado.component").then(s => s.SatisfacaoClienteResultadoComponent) },
    { path: "autoridade-pesquisa/:id", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-autoridade/satisfacao-cliente-autoridade.component").then(s => s.SatisfacaoClienteAutoridadeComponent) },

    //NORMA PINTURA
    { path: "home-norma-pintura", loadComponent: () => import("../paginas/controles/norma-pintura/norma-pintura-home/norma-pintura-home.component").then(n => n.NormaPinturaHomeComponent) },
    { path: "cadastrar-norma-pintura", loadComponent: () => import("../paginas/controles/norma-pintura/norma-pintura-cadastrar/norma-pintura-cadastrar.component").then(n => n.NormaPinturaCadastrarComponent) },
    { path: "consulta-norma-pintura", loadComponent: () => import("../paginas/controles/norma-pintura/norma-pintura-consultar/norma-pintura-consultar.component").then(n => n.NormaPinturaConsultarComponent) },
    { path: "cadastrar-norma-pintura/:id", loadComponent: () => import("../paginas/controles/norma-pintura/norma-pintura-cadastrar/norma-pintura-cadastrar.component").then(n => n.NormaPinturaCadastrarComponent) },
    { path: "historico-norma-pintura/:id", loadComponent: () => import("../paginas/controles/norma-pintura/norma-pintura-historico/norma-pintura-historico.component").then(n => n.NormaPinturaHistoricoComponent) },
    { path: "requisito-norma-pintura/:idNorma/:idRequisito", loadComponent: () => import("../paginas/controles/norma-pintura/norma-pintura-alteracao/norma-pintura-alteracao.component").then(r=> r.NormaPinturaAlteracaoComponent) },

    //MANUAIS DE CLIENTES
    { path: "manuais-cliente-consulta", loadComponent: () => import("../paginas/controles/manuais-cliente/manual-cliente-consulta/manual-cliente-consulta.component").then(m=> m.ManualClienteConsultaComponent) },
    { path: "manuais-cliente-cadastro", loadComponent: () => import("../paginas/controles/manuais-cliente/manual-cliente-cadastro/manual-cliente-cadastro.component").then(m=> m.ManualClienteCadastroComponent) },
    { path: "manuais-cliente-edicao/:id", loadComponent: () => import("../paginas/controles/manuais-cliente/manual-cliente-edicao/manual-cliente-edicao.component").then(m=> m.ManualClienteEdicaoComponent) },
    { path: "manuais-cliente-gerenciar/:id", loadComponent: () => import("../paginas/controles/manuais-cliente/manual-cliente-gerencia/manual-cliente-gerencia.component").then(m=>m.ManualClienteGerenciaComponent)},
    { path: "manuais-cliente-requisito/:idManual", loadComponent: () => import("../paginas/controles/manuais-cliente/requisito-cadastro/requisito-cadastro.component").then(m=> m.RequisitoCadastroComponent)},
    { path: "manuais-cliente-requisito/:idManual/:idRequisito", loadComponent: () => import("../paginas/controles/manuais-cliente/requisito-edicao/requisito-edicao.component").then(m=> m.RequisitoEdicaoComponent)},   
    { path: "manuais-cliente-aprovacao", loadComponent: () => import("../paginas/controles/manuais-cliente/manual-cliente-aprovacao/manual-cliente-aprovacao.component").then(m=> m.ManualClienteAprovacaoComponent)},   
];
