import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import moment from 'moment';
import { StatusDesenvolvimento } from '../../../../model/statusDensevolvimento';
import { StatusDesenvolvimentoService } from '../../../../services/engenharia/produto/status-desenvolvimento.service';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { MensagemErrorsComponent } from '../../../../componentes/mensagem-errors/mensagem-errors.component';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { ValidatorsService } from '../../../../utils/validators.service';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-status-desenvolvimento',
  standalone: true,
  imports: [ReactiveFormsModule,CommonModule,BotaoComponent,MensagemErrorsComponent],
  templateUrl: './status-desenvolvimento.component.html',
  styleUrl: './status-desenvolvimento.component.scss'
})
export class StatusDesenvolvimentoComponent {



  statusDesenvolvimento: StatusDesenvolvimento[] = [];
  atualizacao:boolean = false;
  formularioStatusDesenvolvimento!:FormGroup

  constructor(
    private statusDesenvolvimentoService: StatusDesenvolvimentoService,
    private formBuild:FormBuilder,
    private snackBarService: SnackBarService,
    private validatorsService:ValidatorsService,
    private dialog:MatDialog,
  ) {

  }


  ngOnInit(): void {
    this.listarTipos();
    this.formularioStatusDesenvolvimento = this.formBuild.group({
      id:[],
      descricao:['',[Validators.required,this.validatorsService.semEspaçoEmBranco()]],
      data:[moment().format("DD-MM-yyyy")]
    });
  }

  public listarTipos() {
    this.statusDesenvolvimentoService.consultarStatusDesenvolvimento().subscribe({
      next: (tipos) => {
        this.statusDesenvolvimento = tipos;
      },
    });
  }

  public criarTipo(){
    if(this.formularioStatusDesenvolvimento.valid){
      this.statusDesenvolvimentoService.criarStatusDesenvolvimento(this.formularioStatusDesenvolvimento.value).subscribe({
        next:(res)=>{
          
        },error:(error)=>{
  
        },complete:()=>{
          this.listarTipos();
        }
      });
    }
  }

  buscarPorId(id:number){
    this.statusDesenvolvimentoService.buscarPorId(id).subscribe({
      next:(res)=>{
        this.formularioStatusDesenvolvimento.patchValue(res);
      },error:(erro)=>{

      },complete:()=>{

      }
    })
  }




  public limparFormulario(){
    this.formularioStatusDesenvolvimento.reset();
  }
  

  public editarStatusDesenvolvimento(){
    this.criarTipo();
  }


  public deletarItem(nomeArquivo:string,id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: {nomeArquivo:nomeArquivo,textoConteudo:"status desenvolvimento"},
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next:(res)=>{
        if (res) {
        this.deletar(id);
        };
      }
    });
  }

  private deletar(id:number){
    this.statusDesenvolvimentoService.deletarStatusDesenvolvimento(id).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Item deletado com sucesso", "my-snack-bar-sucess")
      }, complete: () => {
        this.listarTipos();
      }
    });
  }



  public obterValidacao(nome: string): FormControl {
    const control = this.formularioStatusDesenvolvimento.get(nome);
    if (!control) {
      throw new Error('Controle de formulario não encontrado' + nome);
    }
    return control as FormControl;
  }

  public tocado(nome: string): boolean {
    const control = this.formularioStatusDesenvolvimento.get(nome);
    if (!control?.value) {
      return control!.touched;
    }
    return false;
  }

  public validar(nome: string): boolean {
    const control = this.formularioStatusDesenvolvimento.get(nome);
    return control!.touched && control!.valid;
  }

  public closeModal(){
    this.dialog.closeAll()
  }

}
