import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { CdkDropList, CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { environment } from '../../../../../environments/environment';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { FormsDataService } from '../../../../utils/forms-data.service';
import { ActivatedRoute } from '@angular/router';
// import { RotaProcessosService } from '../../../../services/rotas-services/rota-processos.service';
import { EngPadraoEmbalagemService } from '../../../../services/engenharia/processo/eng-padrao-embalagem.service';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EngProcessoUpdateDto } from '../../../../model/engenharia/engProcessoUpdateDto';
import { GerenciadorDeArquivosService } from '../../../../utils/gerenciador-de-arquivos.service';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { LoadingComponent } from "../../../../componentes/loading/loading.component";
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-eng-padrao-embalagem',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BotaoComponent, MatCardModule, MatTooltipModule, FormsModule, CdkDropList, CdkDrag, CdkDropList, CdkDrag, ContainerComponentComponent, LoadingComponent],
  templateUrl: './eng-padrao-embalagem.component.html',
  styleUrl: './eng-padrao-embalagem.component.scss'
})
export class EngPadraoEmbalagemComponent implements OnChanges {

  formulario!: FormGroup;
  nomeDeterminacao: string = ''
  urlImagem = environment.url;
  listaDocumentoSelecionadoPadraoEmbalagemRederizados: any[] = [];
  lista: string[] = [];
  controle: boolean = false;
  idProcesso: string = '';
  cdProduto: number = 0;
  cdDeterminacao: string = '';
  listaDeCriteriosDePadraoEmbalagem: any[] = []
  listaDeDados: any[] = [];
  contactFormPadraoEmbalagem!: FormGroup;
  produto: any = {}
  engProcessoUpdateDto: EngProcessoUpdateDto[] = [];
  @Input() dados: any;
  @Input() fazerConsulta: boolean = false;
  ativarVisualizarSequencia: boolean = false;
  @ViewChild('swiperProduto', { static: false }) swiperContainer!: ElementRef;
  public swiper?: Swiper;
  editando: boolean = false;
  imagemSelecionada: any;
  descricaoImagem: string = "";
  carregando: boolean = false;
  private loginService = inject(LoginService);


  constructor(
    private engPadraoEmbalagemService: EngPadraoEmbalagemService,
    private gerenciadorDeArquivosService: GerenciadorDeArquivosService,
    private snackBarService: SnackBarService,
    private formDataService: FormsDataService,
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private formValidacaoService: FormValidacaoService,
    private emisorEventoGeralService: EmisorEventoGeralService,
  ) {

  }
  ngOnInit(): void {
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = parseInt(params.cdProduto);
      this.produto = { cdProduto: this.cdProduto }
      this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    });
    this.iniciarFormulario();
    this.contactFormPadraoEmbalagem = this.formBuilder.group({
      contactsPadraoEmbalagem: this.formBuilder.array([this.createContact()])
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    if (changes['fazerConsulta'] && changes['fazerConsulta'].currentValue) {
      this.limparFormulario();
      this.consultarTodosOsDocumentos();
    }
  }


  public iniciarFormulario() {
    this.formulario = this.formBuilder.group({
      id: [''],
      descricao: [''],
    });
  }

  public consultarTodosOsDocumentos() {
    this.listaDeCriteriosDePadraoEmbalagem = [];
    this.engPadraoEmbalagemService
      .consultarPadraoEmbalagem(this.cdProduto, parseInt(this.idProcesso))
      .subscribe({
        next: (res) => {
          this.listaDeCriteriosDePadraoEmbalagem = res;

        },
        error: (erro) => { },
        complete: () => {
          this.montarImagens();
        },
      });
  }
  ;

  createContact(): FormGroup {
    return this.formBuilder.group({
      descricao: ['', Validators.required],
    });
  }

  addContact(): void {
    const contacts = this.contactsPadraoEmbalagem;
    contacts.push(this.createContact());
  }

  removeContact(index: number): void {
    const contacts = this.contactFormPadraoEmbalagem.get('contactsPadraoEmbalagem') as FormArray;
    contacts.removeAt(index);
    this.listaDocumentoSelecionadoPadraoEmbalagemRederizados.splice(index, 1);
  }

  get contactsPadraoEmbalagem() {
    return this.contactFormPadraoEmbalagem.get('contactsPadraoEmbalagem') as FormArray;
  }



  onSubmit() {
    const formData = new FormData();
    this.contactFormPadraoEmbalagem.value['contactsPadraoEmbalagem'].forEach((elemento: any, index: number) => {
      const file = this.listaDocumentoSelecionadoPadraoEmbalagemRederizados[index];
      if (!file || !file.name) {
        this.snackBarService.openSnackBar('Sem imagem selecionada', "my-snack-bar-erro")
      } else {
        formData.append('file', file, file.name);
        this.listaDeDados.push({
          "descricao": elemento.descricao,
          "cdProduto": this.cdProduto,
          "cdProcesso": this.idProcesso,
          "sequencia": index
        });
      }

    });
    formData.append('engPadraoEmbalagem', JSON.stringify(this.listaDeDados)), this.formDataService.criarFormData("engPadraoEmbalagem", this.listaDocumentoSelecionadoPadraoEmbalagemRederizados, this.listaDeDados)
    this.salvarPadraoEmbalagem(formData);
  }

  public salvarPadraoEmbalagem(formData: FormData) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.engPadraoEmbalagemService.salvarPadraoEmbalagem(formData).subscribe({
      next: (res) => {
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar salvar padrão de embalagem")
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.consultarTodosOsDocumentos();
        this.limparFormulario();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    })
  }

  public pegarImagemPadraoEmbalagem(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let itemJaExiste = this.listaDocumentoSelecionadoPadraoEmbalagemRederizados.some(
        (arquivo) => arquivo.name === file.name
      );
      if (!itemJaExiste) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          file['preview'] = e.target.result;
          const previsualizacao = e.target.result;
          this.listaDocumentoSelecionadoPadraoEmbalagemRederizados.push(file);
        }
        reader.readAsDataURL(file);
      }
    }
  }


  drop(event: CdkDragDrop<{ title: string; poster: string }[]>) {
    moveItemInArray(this.listaDeCriteriosDePadraoEmbalagem, event.previousIndex, event.currentIndex);
  }


  public deletarPadraoEmbalagem(nomeArquivo: string, id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: nomeArquivo, textoConteudo: "padrão de embalagem" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.deletar(id);
        };
      }
    });
  }

  public deletar(id: number) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.engPadraoEmbalagemService.deletarPadraoEmbalagem(id).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar(res.mensagem, "my-snack-bar-sucess")
      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar excluir padrão de embalagem");
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.consultarTodosOsDocumentos();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }


  public salvarNovaSequencia() {
    let contador = 1;
    this.listaDeCriteriosDePadraoEmbalagem.forEach(e => {
      e.sequencia = 0;
      e.cdProcesso = this.idProcesso;
      e.cdProduto = this.cdProduto;
    });
    this.listaDeCriteriosDePadraoEmbalagem.forEach(e => {
      e.sequencia = contador;
      e.cdProcesso = this.idProcesso;;
      e.cdProduto = this.cdProduto;;
      contador++
    });
    this.transformaEmObjetoDeEnvio();
    this.salvarSequenciaNova();
  }


  public salvarSequenciaNova() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.engPadraoEmbalagemService.salvarNovoPadraoEmbalagem(this.engProcessoUpdateDto).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Sequencia redefinada", "my-snack-bar-sucess")
      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar atualizar sequência de padrão de embalagem")
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.consultarTodosOsDocumentos()
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public editarDescricaoImagem(imagemSelecionada: number, descricao: string) {
    this.editando = true;
    this.imagemSelecionada = imagemSelecionada;
    this.descricaoImagem = descricao;
  }

  public cancelarEdicao() {
    this.editando = false;
    this.descricaoImagem = '';
    this.imagemSelecionada = 0;
  }

  public concluirEdicaoDeDescricao() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.engPadraoEmbalagemService.editarDescricaoPadraoEmbalagem(this.imagemSelecionada, this.descricaoImagem).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar('Descrição editada com sucesso', 'my-snack-bar-sucess');
      }, error: (e) => {
        this.snackBarService.tratarErro(e, "Erroa ao tentar editar descrição");
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.consultarTodosOsDocumentos();
        this.cancelarEdicao();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    })
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }


  public transformaEmObjetoDeEnvio() {
    this.listaDeCriteriosDePadraoEmbalagem.forEach(e => {
      let objTemp: EngProcessoUpdateDto = new EngProcessoUpdateDto();
      objTemp.id = e.id;
      objTemp.nomeArquivo = e.nomeArquivo;
      objTemp.cdProcesso = e.cdProcesso;
      objTemp.cdProduto = e.cdProduto;
      objTemp.descricao = e.descricao;
      objTemp.path = e.path;
      objTemp.dataCadastro = e.dataCadastro;
      objTemp.sequencia = e.sequencia;
      this.engProcessoUpdateDto.push(objTemp);
    });
  }


  public limparFormulario() {
    this.listaDeDados = [];
    this.listaDeCriteriosDePadraoEmbalagem = [];
    this.listaDocumentoSelecionadoPadraoEmbalagemRederizados = [];
    this.contactFormPadraoEmbalagem.reset();
    this.formulario.reset();
    this.contactsPadraoEmbalagem.reset();
    this.contactsPadraoEmbalagem.clear();
  }

  public limparDados() {
    this.listaDeDados = [];
    this.listaDeCriteriosDePadraoEmbalagem = [];
    this.listaDocumentoSelecionadoPadraoEmbalagemRederizados = [];
  }





  public montarImagens() {
    this.cdr.detectChanges()
    const options: SwiperOptions = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff; background:#09638E" class="' + className + '">' + (index + 1) + '</span>';
        }
      },
      allowTouchMove: true, // Garante suporte ao touch
      observer: true,       // Observa alterações no Swiper
      observeParents: true, // Observa mudanças nos elementos pais
      threshold: 30,
      modules: [Pagination],
    };
    this.swiper = new Swiper(this.swiperContainer.nativeElement, options);
  }


  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_ENG_EDIT']);
  }

  public temPermissaoExcluir() {
    return this.loginService.temPermissao(['ROLE_ENG_ADMIN']);
  }
}
