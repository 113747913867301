import { Component } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { DurezaLapisService } from '../../../../services/inspecao-equipamento/durezaLapis.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DurezaLapis } from '../../../../model/inspecao-equipamento/durezaLapis';
import { CommonModule } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";

@Component({
  selector: 'app-inspecao-dureza-lapis',
  standalone: true,
  imports: [ContainerComponentComponent, CommonModule, FormsModule, PaginatorComponent],
  templateUrl: './inspecao-dureza-lapis.component.html',
  styleUrl: './inspecao-dureza-lapis.component.scss'
})
export class InspecaoDurezaLapisComponent {

  listaDeDurezaDeLapis:DurezaLapis[] = [];
  valorPesquisado = "";
  buscarPorValidade = "";
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: number[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];

  constructor(
    private durezaLapisService:DurezaLapisService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
  ){

  }
  ngOnInit(): void {
    this.verificarSeExisteValorDeBusca();
  }

  public consultaTodasDurezaDeLapis(){
    this.durezaLapisService.buscarTodasDurezaLapisPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next:(res)=>{
        this.listaDeDurezaDeLapis = res.content;
        this.totalTamanho = res.page.totalElements;
      },error:(error)=>{

      },complete:()=>{

      }
    });
  }
  public consultaTodasDurezaDeLapisPorValidade(){
    this.durezaLapisService.buscarTodasDurezaLapisPaginadaPorValidade(this.buscarPorValidade,this.paginaAtual, this.totalPorPagina).subscribe({
      next:(res)=>{
        this.listaDeDurezaDeLapis = res.content;
        this.totalTamanho = res.page.totalElements;
      },error:(error)=>{

      },complete:()=>{

      }
    });
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.router.navigate([`/inspecao/dureza-lapis/${this.valorPesquisado}`])
      this.buscar(event);
    }
  }


  buscar(event?: Event) {
    if (event instanceof KeyboardEvent && event.key !== 'Enter') {
      return;
    }
    this.router.navigate([`/inspecao/dureza-lapis/${this.valorPesquisado}`])
    if (this.valorPesquisado.trim() !== '' && this.valorPesquisado.length >= 2) {
      this.durezaLapisService.buscarTodasDurezaLapisPaginadaMerge(this.valorPesquisado, this.paginaAtual, this.totalPorPagina).subscribe({
        next: (res) => {
          this.listaDeDurezaDeLapis = res.content;
          this.totalTamanho = res.page.totalElements
        }, error: (error) => {

        }, complete: () => {
        }
      });
    } else {
      this.paginaAtual = 0;
      this.totalPorPagina = 10;
      this.consultaTodasDurezaDeLapis();
    }
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.verificarValores(this.valorPesquisado);
  }

  public verificarSeExisteValorDeBusca(){
    this.activatedRoute.params.subscribe((res:any)=>{
      this.buscarPorValidade = res.valorBusca;
      this.verificarValores(res.valorBusca);
    });
  }

  public verificarValores(valorDeBusca:string =""){
    if(this.buscarPorValidade == "validade"){
      this.consultaTodasDurezaDeLapisPorValidade();
    }else if(valorDeBusca != "validade" && valorDeBusca.trim() != ""){
      this.valorPesquisado = valorDeBusca;
      this.buscar();
    }
    else{
      this.consultaTodasDurezaDeLapis();
    }
  }



  public editarDurezaLapis(id:number){
    this.router.navigate([`inspecao/dureza-lapis-registrar/${id}`])
  }

  public visualizar(id:number){
    this.router.navigate([`inspecao/dureza-lapis/view/${id}`])
  }
}
