<app-container-component tituloPage="Planejamento de comunicação" subtituloPage="Planejamentos cadastrados">
    <div class="col-12 d-flex flex-column justify-content-end align-items-end">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 m-0 mt-md-2 me-md-2">
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="valorPesquisado"
                    (keypress)="this.onKeyPress($event)" placeholder="Planejamentos">
                <button class="btn btn-sm" type="button"><i class="fa-solid fa-magnifying-glass"
                        (click)="this.buscarPlanejamento($event)"></i></button>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-hover pointer table-striped align-middle">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">Plano</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Atualização</th>
                    <th scope="col" class="text-center">Ver plano</th>
                </tr>
            </thead>
            <tbody>
                @if(listaDePlanejamentoComunicacao.length > 0) {
                @for (planejamento of listaDePlanejamentoComunicacao; track $index) {
                <tr title="Clique para editar" class="table-row " >
                    <td data-label="planejamento">
                        <a [routerLink]="['/acoes/planejamento-comunicacao-registrar', planejamento.id]">
                            {{ planejamento.id }}
                        </a>
                    </td>
                    <td data-label="planejamento">
                        <a [routerLink]="['/acoes/planejamento-comunicacao-registrar', planejamento.id]">
                            {{ planejamento.descricao }}
                        </a>
                    </td>
                    <td data-label="Atualização">
                        <a [routerLink]="['/acoes/planejamento-comunicacao-registrar', planejamento.id]">
                            {{ planejamento.dataAlteracao | date: "dd/MM/yyyy" }}
                        </a>
                    </td>
                    <td data-label="Visualizar" class="text-center" title="Clique para visualizar" (click)="visualizarPlanejamentoComunicacao(planejamento.id)">
                        <a [routerLink]="['/acoes/planejamento-comunicacao-visualizar', planejamento.id]">
                            <i class="fa-solid fa-eye"></i>
                        </a>
                    </td>
                </tr>
                }
                }
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center col-12">
        <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
            [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
            (paginaMudou)="onPageChange($event)"></app-paginator>
    </div>
</app-container-component>