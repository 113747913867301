import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { PlanejamentoComunicacaoService } from './../../../../services/acoes/planejamento-comunicacao/planejamento-comunicacao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BotaoComponent } from "../../../../componentes/botao/botao.component";
import { PlanejamentoComunicacao } from '../../../../model/acoes/planejamento-comunicacao/PlanejamentoComunicacao';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { CommonModule } from '@angular/common';
import AcoesPlanejamentoComunicacao from '../../../../model/acoes/planejamento-comunicacao/AcoesPlanejamentoComunicacao';
import { PlanejamentoComunicacaoRegistrarAcoesComponent } from "../planejamento-comunicacao-registrar-acoes/planejamento-comunicacao-registrar-acoes.component";
import { PlanejamentoComunicacaoAcoesComponent } from "../planejamento-comunicacao-acoes/planejamento-comunicacao-acoes.component";

@Component({
  selector: 'app-planejamento-comunicacao-registrar',
  standalone: true,
  imports: [ContainerComponentComponent, MensagemErrorsComponent, BotaoComponent, ReactiveFormsModule, CommonModule, PlanejamentoComunicacaoRegistrarAcoesComponent, PlanejamentoComunicacaoAcoesComponent],
  templateUrl: './planejamento-comunicacao-registrar.component.html',
  styleUrl: './planejamento-comunicacao-registrar.component.scss'
})
export class PlanejamentoComunicacaoRegistrarComponent implements OnInit {

  planejamento: PlanejamentoComunicacao = new PlanejamentoComunicacao();
  formularioCadastro!: FormGroup;
  idPlanejamentoComunicacao: number = 0;
  mostrarBolas: boolean = false;
  mostrarFormularioAcoes: boolean = false;
  acoesParteInteressada: AcoesPlanejamentoComunicacao = new AcoesPlanejamentoComunicacao();

  private formBuilder = inject(FormBuilder);
  private formValidacaoService = inject(FormValidacaoService);
  private planejamentoComunicacaoService = inject(PlanejamentoComunicacaoService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);


  ngOnInit(): void {
    this.verificarRota();
  }


  public verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idPlanejamentoComunicacao = res.id;
        if (this.idPlanejamentoComunicacao) {
          this.consultarPorId();
        }
      }
    })
  }

  private iniciarFormulario() {
    this.formularioCadastro = this.formBuilder.group({
      id: [null,],
      descricao: [null, Validators.required],
      status: ['true'],
    });
  }

  public consultarPorId() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.planejamentoComunicacaoService.consultarPorId(this.idPlanejamentoComunicacao).subscribe({
      next: (res) => {
        this.planejamento = res;
        this.formularioCadastro.patchValue(this.planejamento);
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public cadastrarPlanejamentoComunicacao() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.formularioCadastro.removeControl("id");
    this.planejamentoComunicacaoService.cadastrarPlanejamentoComunicacao(this.formularioCadastro.value).subscribe({
      next: (res) => {
        this.idPlanejamentoComunicacao = res.id;
      }, error: (error) => {
        console.log(error)
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.router.navigate([`/acoes/planejamento-comunicacao-registrar/${this.idPlanejamentoComunicacao}`])
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public editarPlanejamentoComunicacao() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.planejamentoComunicacaoService.editarPlanejamentoComunicacao(this.formularioCadastro.value).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.consultarPorId();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public receberNovaConsulta(event: any) {
    if (event) {
      this.verificarRota();
      this.acoesParteInteressada = new AcoesPlanejamentoComunicacao();
    }
  }

  public emitirEdicao(event: any) {
    if (event) {
      if (this.mostrarFormularioAcoes) {
        this.acoesParteInteressada = event;
      } else {
        this.acoesParteInteressada = event;
        this.mostrarFormularioAcoes = !this.mostrarFormularioAcoes
      }
    }
  }



  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formularioCadastro, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formularioCadastro, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formularioCadastro, nome);
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }


  public openClose() {
    this.mostrarBolas = !this.mostrarBolas;
  }

  public mostrarFormsAcoes() {
    this.mostrarFormularioAcoes = !this.mostrarFormularioAcoes
    if (!this.mostrarFormularioAcoes) {
      this.acoesParteInteressada = new AcoesPlanejamentoComunicacao();
    }
  }
}
