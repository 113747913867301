import { CdkDropList, CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { EngPadraoDefeitoService } from '../../../../services/engenharia/processo/eng-padrao-defeito.service';
// import { RotaProcessosService } from '../../../../services/rotas-services/rota-processos.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { FormsDataService } from '../../../../utils/forms-data.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EngProcessoUpdateDto } from '../../../../model/engenharia/engProcessoUpdateDto';
import { forkJoin, map, of } from 'rxjs';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingComponent } from "../../../../componentes/loading/loading.component";

@Component({
  selector: 'app-eng-padrao-defeitos',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BotaoComponent, MatCardModule, FormsModule, MatTooltipModule, CdkDropList, CdkDrag, ContainerComponentComponent, LoadingComponent],
  templateUrl: './eng-padrao-defeitos.component.html',
  styleUrl: './eng-padrao-defeitos.component.scss'
})
export class EngPadraoDefeitosComponent implements OnChanges {
  formulario!: FormGroup;
  urlImagem = environment.url;
  listaDocumentoSelecionadoPadraoDefeitoRederizados: any[] = [];
  lista: string[] = [];
  idProcesso: string = '';
  cdProduto: number = 0;
  listaDeCriteriosDePadraoDefeito: any[] = []
  listaDeDados: any[] = [];
  contactFormPadraoDefeito!: FormGroup;
  produto: any = {};
  engProcessoUpdateDto: EngProcessoUpdateDto[] = [];
  @Input() dados: any;
  @Input() fazerConsulta: boolean = false;
  ativarVisualizarSequencia:boolean = false;
  @ViewChild('swiperProduto', { static: false }) swiperContainer!: ElementRef;
  public swiper?: Swiper;
  editando: boolean = false;
  imagemSelecionada: any;
  descricaoImagem: string = "";
  carregando:boolean = false;

  constructor(
    private engPadraoDefeitoService: EngPadraoDefeitoService,
    // private gerenciadorDeArquivosService: GerenciadorDeArquivosService,
    private snackBarService: SnackBarService,
    private formDataService: FormsDataService,
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    // private rotaProcessoService: RotaProcessosService,
    public dialog: MatDialog,
    private cdr:ChangeDetectorRef,
    private formValidacaoService:FormValidacaoService
  ) {

  }
  ngOnInit(): void {
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = parseInt(params.cdProduto);
      this.produto = { cdProduto: this.cdProduto }
      // RotaProcessosService.emitirProcesso.emit(this.produto);
      this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
      if (this.cdProduto && this.idProcesso) {
        this.limparDados();
        this.consultarTodosOsDocumentos();
      }
    });
    this.iniciarFormulario();
    this.contactFormPadraoDefeito = this.formBuilder.group({
      contactsPadraoDefeito: this.formBuilder.array([this.createContact()])
    });
  }



  ngOnChanges(changes: SimpleChanges) {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    if (changes['fazerConsulta'] && changes['fazerConsulta'].currentValue) {
      this.limparDados();
      this.consultarTodosOsDocumentos();
    }
  }

  public iniciarFormulario() {
    this.formulario = this.formBuilder.group({
      id: [''],
      descricao: [''],
    });
  }

  public consultarTodosOsDocumentos() {
    this.listaDeCriteriosDePadraoDefeito = [];
    this.engPadraoDefeitoService
      .consultarPadraoDefeito(this.cdProduto, parseInt(this.idProcesso))
      .subscribe({
        next: (res) => {
          this.listaDeCriteriosDePadraoDefeito = res;

        },
        error: (erro) => { },
        complete: () => {

          this.montarImagens();
        },
      });
  }

  createContact(): FormGroup {
    return this.formBuilder.group({
      descricao: ['', Validators.required],
    });
  }

  addContact(): void {
    const contacts = this.contactsPadraoDefeito;
    contacts.push(this.createContact());
  }

  removeContact(index: number): void {
    const contacts = this.contactFormPadraoDefeito.get('contactsPadraoDefeito') as FormArray;
    contacts.removeAt(index);
    this.listaDocumentoSelecionadoPadraoDefeitoRederizados.splice(index, 1);
  }

  get contactsPadraoDefeito() {
    return this.contactFormPadraoDefeito.get('contactsPadraoDefeito') as FormArray;
  }



  onSubmit() {
    const formData = new FormData();
    this.listaDeDados = [];
    this.contactFormPadraoDefeito.value['contactsPadraoDefeito'].forEach((elemento: any, index: number) => {
      const file = this.listaDocumentoSelecionadoPadraoDefeitoRederizados[index];
      if (!file || !file.name) {
        this.snackBarService.openSnackBar('Sem imagem selecionada', "my-snack-bar-erro")
      } else {
        formData.append('file', file, file.name);
        this.listaDeDados.push({
          "descricao": elemento.descricao,
          "cdProduto": this.cdProduto,
          "cdProcesso": this.idProcesso,
          "sequencia": index
        });
      }

    });
    formData.append('engPadraoDefeito', JSON.stringify(this.listaDeDados)), this.formDataService.criarFormData("engPadraoDefeito", this.listaDocumentoSelecionadoPadraoDefeitoRederizados, this.listaDeDados)
    this.salvarPadraoIsencao(formData);
  }

  public salvarPadraoIsencao(formData: FormData) {
    this.carregando = true;
    this.engPadraoDefeitoService.salvarPadraoDefeito(formData).subscribe({
      next: (res) => {
      
      }, error: (error) => {
        this.carregando = false;
      }, complete: () => {
        this.carregando = false;
        this.limparFormulario();
        this.consultarTodosOsDocumentos();
      }
    })
  }

  public pegarImagemPadraoDefeito(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let itemJaExiste = this.listaDocumentoSelecionadoPadraoDefeitoRederizados.some(
        (arquivo) => arquivo.name === file.name
      );
      if (!itemJaExiste) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          file['preview'] = e.target.result;
          const previsualizacao = e.target.result;
          this.listaDocumentoSelecionadoPadraoDefeitoRederizados.push(file);
        }
        reader.readAsDataURL(file);
      }
    }
  }


  drop(event: CdkDragDrop<{ title: string; poster: string }[]>) {
    moveItemInArray(this.listaDeCriteriosDePadraoDefeito, event.previousIndex, event.currentIndex);
  }


  public deletarPadraoDefeito(nomeArquivo: string, id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: nomeArquivo, textoConteudo: "padrão de embalagem" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.deletar(id);
        };
      }
    });
  }
  private deletar(id: number) {
    this.carregando = true;
    this.engPadraoDefeitoService.deletarPadraoDefeito(id).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar(res.mensagem, "my-snack-bar-sucess")
      }, error: (error) => {
        this.carregando = false;
        this.snackBarService.tratarErro(error,"Erro ao tenta excluir imagem")
      }, complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos();
      }
    });
  }


  public salvarNovaSequencia() {

    const atualizarSequencias$ = of(this.listaDeCriteriosDePadraoDefeito).pipe(map(lista => {
      let contador = 1;
      lista.forEach(e => {
        e.sequencia = contador++;
        e.cdProcesso = this.idProcesso;
        e.cdProduto = this.cdProduto;
      });
      return lista;
    }));

    forkJoin([atualizarSequencias$]).subscribe(() => {
      this.transformaEmObjetoDeEnvio();
      this.salvarSequenciaNova();
    });
  }


  public salvarSequenciaNova() {
    this.carregando = true;
    this.engPadraoDefeitoService.salvarNovoPadraoDefeito(this.engProcessoUpdateDto).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Sequencia redefinada", "my-snack-bar-sucess")
      }, error: (error) => {
        this.carregando = false;
        this.snackBarService.tratarErro(error,"Erro ao tentar salvar sequencia")
        console.log(error)
      }, complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos()
      }
    });
  }


  public editarDescricaoImagem(imagemSelecionada: number, descricao: string) {
    this.editando = true;
    this.imagemSelecionada = imagemSelecionada;
    this.descricaoImagem = descricao;
  }

  public cancelarEdicao() {
    this.editando = false;
    this.descricaoImagem = '';
    this.imagemSelecionada = 0;
  }

  public concluirEdicaoDeDescricao() {
    this.engPadraoDefeitoService.editarDescricaoPadraoDefeito(this.imagemSelecionada, this.descricaoImagem).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar('Descrição editada com sucesso', 'my-snack-bar-sucess');
      }, error: (e) => {
        this.snackBarService.tratarErro(e,"Erroa ao tentar editar descrição");
      }, complete: () => {
        this.consultarTodosOsDocumentos();
        this.cancelarEdicao();
      }
    })
  }


  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }



  public transformaEmObjetoDeEnvio() {
    this.engProcessoUpdateDto = [];
    this.listaDeCriteriosDePadraoDefeito.forEach(e => {
      let objTemp: EngProcessoUpdateDto = {
        id: e.id,
        nomeArquivo: e.nomeArquivo,
        cdProcesso: e.cdProcesso,
        cdProduto: e.cdProduto,
        descricao: e.descricao,
        path: e.path,
        dataCadastro: e.dataCadastro,
        sequencia: e.sequencia
      }
      this.engProcessoUpdateDto.push(objTemp);
    });
  }

  public limparFormulario() {
    this.listaDeDados = [];
    this.listaDeCriteriosDePadraoDefeito = [];
    this.listaDocumentoSelecionadoPadraoDefeitoRederizados = [];
    this.formulario.reset();
    this.contactsPadraoDefeito.reset();
    this.contactFormPadraoDefeito.reset();
    this.contactsPadraoDefeito.clear();

  }

  public limparDados() {
    this.listaDeDados = [];
    this.listaDeCriteriosDePadraoDefeito = [];
    this.listaDocumentoSelecionadoPadraoDefeitoRederizados = [];
  }


  public montarImagens() {
    this.cdr.detectChanges();
    const options: SwiperOptions = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff; background:#09638E" class="' + className + '">' + (index + 1) + '</span>';
        }
      },
      allowTouchMove: true, // Garante suporte ao touch
      observer: true,       // Observa alterações no Swiper
      observeParents: true, // Observa mudanças nos elementos pais
      threshold: 30,
      modules: [Pagination],
    };
    this.swiper = new Swiper(this.swiperContainer.nativeElement, options);
  }

}
