<app-container-component tituloPage="Gestão de mudanças" [iconeVolta]="false" subtituloPage="Lista">
    <div class="row p-2">
        <table class="table table-responsive table-striped ">
            <thead>
                <tr>
                    <th>Plano</th>
                    <th>Descrição</th>
                    <th>Participantes</th>
                    <th>Atualização</th>
                    <th class="text-center">Ver plano</th>
                </tr>
            </thead>
            <tbody>

                @for (gestao of listaDeGestaoDeMudanca; track $index) {
                <tr>
                    <td>{{gestao.id}}</td>
                    <td>{{gestao.descricao}}</td>
                    <td>{{gestao.participantes}}</td>
                    <td>{{gestao.dataAlteracao | date:'dd/MM/yy'}}</td>
                    <td (click)="criarItens(gestao.id)" class="text-center">
                        <i class="fa-solid fa-plus"></i>
                    </td>
                </tr>
                }

            </tbody>
        </table>
        <app-paginator [paginaAtual]="paginaAtual" (paginaMudou)="mudancaDePagina($event)"
            [quantidadesPorPagina]="quantidadePorPagina" [totalElementos]="tamanhoTotal"></app-paginator>
    </div>
</app-container-component>