<div class="card">
    <div class="card-header">
        <div class="h-100 d-flex justify-content-start align-items-center flex-grow-1">
            <h6>CADASTRO SITUAÇÃO</h6>
        </div>
        <div class="h-100 d-flex justify-content-center align-items-center" (click)="closeModal()">
            <i class="fa-solid fa-xmark x-close"></i>
        </div>
    </div>
    <div class="modal-sessao sessao-box-flutuando">
        <form class="form-group" [formGroup]="formularioSituacao">
            <div>
                <div class="mb-3">
                    <h6>NOVA SITUAÇÃO</h6>
                </div>
                <div class="col-12">
                    <label for="text" class="form-label">Descricao:</label>
                    <div class="input-group d-flex flex-column mb-3">
                        <input type="text"  formControlName="descricao"
                            (keyup)="verificarEstado(this.formularioSituacao.get('descricao')!.value)"
                            class="form-control form-control-sm col-12"  [ngClass]="{'errorAnimacao':tocado('descricao'),'validoAnimacao':validar('descricao')}">
                            <i class="fa-solid fa-xmark x-formulario" (click)="limparFormulario()"></i>
                            <app-mensagem-errors [control]="obterValidacao('descricao')"></app-mensagem-errors>
                    </div>
                  
                </div>

                <div class="row justify-content-start gap-2">
                    @if(this.formularioSituacao.get('id')!.value !== null){
                    <app-botao label="Editar" class="col-2" status="btn-salvar" icon="icons8-lápis.png"
                        (click)="editarTipoPlanoAcao()"></app-botao>
                    }@else {
                    <app-botao label="Salvar" class="col-2" status="btn-salvar" icon="icons8-disquete.png"
                        (click)="criarTipoPlanoAcao()"></app-botao>
                    }
                    @if(this.formularioSituacao.get('id')!.value !== null){
                    <app-botao label="Cancelar" class="col-2" status="btn-cancelar" icon="icons8-x.png"
                        (click)="voltar()"></app-botao>
                    }
                </div>

            </div>
        </form>
        <div class="col-12">
            <div class="card-header mt-3">
                <h6>LISTA SITUAÇÃO</h6>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Descricao</th>
                        <th class="text-center">Editar</th>
                        <th class="text-center">Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    @for (item of situacao; track item.id) {
                    <tr>
                        <td>{{item.descricao}}</td>
                        <td class="text-center" (click)="buscarPorId(item)"><i class="fa-solid fa-pen-to-square"></i>
                        </td>
                        <td class="text-center" (click)="deletarTipoPlanoAcao(item.descricao,item.id)"><i
                                class="fa-solid fa-trash-can"></i>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>

        </div>
    </div>
</div>