import { AreaFormatterPipe } from './../../../pipe/AreaFormatter.pipe';
import { EmisorEventoGeralService } from './../../../utils/emisorEventoGeral.service';
import { ProcessoService } from '../../../services/engenharia/processo/processo.service';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { TipoDenvolvimentoComponent } from './tipo-denvolvimento/tipo-denvolvimento.component';
import { StatusDesenvolvimentoComponent } from './status-desenvolvimento/status-desenvolvimento.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusBook } from '../../../model/statusBook';
import { StatusDesenvolvimento } from '../../../model/statusDensevolvimento';
import { TipoDesenvolvimento } from '../../../model/TipoDesenvolvimento';
import { ProdutoDetalheService } from '../../../services/engenharia/produto/produto-detalhe.service';
import { StatusBookService } from '../../../services/engenharia/produto/status-book.service';
import { StatusDesenvolvimentoService } from '../../../services/engenharia/produto/status-desenvolvimento.service';
import { TipoDesenvolvimentoService } from '../../../services/engenharia/produto/tipo-desenvolvimento.service';
import { SnackBarService } from '../../../utils/snack-bar.service';
import { StatusBookComponent } from './status-book/status-book.component';
import { ProcessoComponent } from '../processo/processo/processo.component';
import { SubstratoComponent } from '../../substrato/substrato.component';
import { Processo } from '../../../model/engenharia/processo';
import { Substrato } from '../../../model/substrato';
import { SubstratoService } from '../../../services/substrato.service';
import { ProdutoDetalheImagemService } from '../../../services/engenharia/produto/produto-detalhe-imagem.service';
import { FormsDataService } from '../../../utils/forms-data.service';
import { MensagemErrorsComponent } from '../../../componentes/mensagem-errors/mensagem-errors.component';
import moment from 'moment';
import { ValidatorsService } from '../../../utils/validators.service';
import { Subscription } from 'rxjs';
import { FormValidacaoService } from '../../../utils/formValidacao.service';
import Swiper from 'swiper';
import { SituacaoModalComponent } from './situacao-modal/situacao-modal.component';
import { SituacaoService } from '../../../services/engenharia/situacao/situacao.service';
import { Situacao } from '../../../model/engenharia/situacao';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";
import { Pagination } from 'swiper/modules';
import { SelectComponentComponent } from "../../../componentes/select-component/select-component.component";
import { LoadingComponent } from "../../../componentes/loading/loading.component";

@Component({
  selector: 'app-produto-detalhe',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MensagemErrorsComponent,
    FormsModule,
    ContainerComponentComponent,
    SelectComponentComponent,
    LoadingComponent
  ],
  templateUrl: './produto-detalhe.component.html',
  styleUrl: './produto-detalhe.component.scss',
})
export class ProdutoDetalheComponent implements OnInit, OnDestroy, AfterViewInit {
  formularioProduto!: FormGroup;
  cdProduto: any;
  tipoDesenvolvimento: TipoDesenvolvimento[] = [];
  statusDesenvolvimento: StatusDesenvolvimento[] = [];
  processos: Processo[] = [];
  substratos: Substrato[] = [];
  statusBook: StatusBook[] = [];
  situacoes: Situacao[] = [];
  imagemBlob: Blob = new Blob();
  mostrarValorSupersmart: boolean = false;
  urlImagem!: SafeUrl;
  listaDeImagens: any[] = [];
  eventoDeNovaConsulta!: Subscription;
  imagemProduto: Blob;
  descricaoTamanhoPermitido: number = 2000;
  @ViewChild('textareaRef') textareaRef!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('swiperContainer') swiperProduto!: ElementRef;
  swiper?: Swiper;
  consultaSupersmart: boolean = true;
  codigoSupersmart: string = "";
  private carregandoSubscription!: Subscription;
  statusBook$: any;
  carregando: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private produtoDetalheService: ProdutoDetalheService,
    private snackBarService: SnackBarService,
    private statusBookService: StatusBookService,
    private statusDesenvolvimentoService: StatusDesenvolvimentoService,
    private tipoDesenvolvimentoService: TipoDesenvolvimentoService,
    private situacaoService: SituacaoService,
    private imagemService: ProdutoDetalheImagemService,
    private processoService: ProcessoService,
    private substratoService: SubstratoService,
    private formDataService: FormsDataService,
    private dialog: MatDialog,
    private santize: DomSanitizer,
    private activeRouter: ActivatedRoute,
    private validatorsService: ValidatorsService,
    private emisorEventoGeralService: EmisorEventoGeralService,
    private formValidacaoService: FormValidacaoService,
    private cdf: ChangeDetectorRef,
    private areaFormatterPipe: AreaFormatterPipe,
    private router: Router
  ) {

    this.imagemProduto = new Blob();
  }
  ngAfterViewInit(): void {
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = params.cdProduto;
      if (this.cdProduto != undefined) {
        this.consultarPorIdClick(this.cdProduto);
      }
    });
  }
  ngOnDestroy(): void {
    if (this.carregandoSubscription) {
      this.carregandoSubscription.unsubscribe();
    }
    this.destruirSwiper();
  }


  ngOnInit(): void {
    this.iniciarFormulario();
    this.consultaProcesso();
    this.consultaStatusBook();
    this.consultaStatusDesenvolvimento();
    this.consultarSituacao();
    this.consultarTipoDesenvolvimento()
    this.consultaSubstrato();
  }


  public verificarSeFoiChamadaConsulta() {
    this.carregandoSubscription = this.emisorEventoGeralService.obterMeuEvento().subscribe({
      next: (res) => {
        if (res) {
          this.consultarPorIdClick(this.cdProduto);
        }
      }, complete: () => {

      }
    });
  }

  public iniciarFormulario() {
    this.formularioProduto = this.formBuilder.group({
      id: [null],
      cdProduto: [null, [Validators.required]],
      nomeProduto: [null, this.validatorsService.semEspaçoEmBranco()],
      cdBeneficiamento: [],
      nomeBeneficiamento: [null, this.validatorsService.semEspaçoEmBranco()],
      cdCliente: [],
      nomeCliente: [, this.validatorsService.semEspaçoEmBranco()],
      peso: [null, [Validators.required, this.validatorsService.somentaNumeros()]],
      area: [null, [Validators.required, this.validatorsService.somentaNumeros()]],
      espessura: [null, [Validators.required, this.validatorsService.somentaNumeros()]],
      representante: [],
      cargoRepresentante: [],
      numeroPedido: [],
      aplicacaoPrincipal: [],
      norma: [],
      situacao: [, [Validators.required]],
      processo: [, [Validators.required]],
      imds: [],
      material: [],
      percentualSucata: [null,],
      familiaMateriais: [null,],
      corteLaser: [false],
      chapaPreta: [false],
      jateamento: [false],
      zincado: [false],
      decapIterna: [false],
      decapExterna: [false],
      itemSeguranca: [false],
      itemAparencia: [false],
      relatAprovAparencia: [false],
      retemAmostraPadrao: [false],
      substanciaPerigosa: [false],
      pecaPolimera: [false],
      prazoEntrega: [],
      montadora: [],
      previsaoDemanda: [],
      statusBook: [],
      condiArmazenagem: [],
      tipoDesenvolvimento: [],
      statusDesenvolvimento: [],
      loteSignificativo: [],
      inicioDesenvolvimento: [moment().format("YYYY-MM-DD"), Validators.required],
      fimDesenvolvimento: [],
      codigoDesenho: [],
      nomePecaDesenho: [],
      revisaoDesenho: [],
      dataDesenho: [],
      observacao: [null, Validators.maxLength(2000)],
    });
  }


  public receberConteudoProcesso(valorSelecionado: any) {
    this.formularioProduto.controls['processo'].setValue(valorSelecionado.descricao);
  }

  public salvarProduto() {
    if (this.formularioProduto.valid) {
      this.carregando = true;
      this.produtoDetalheService
        .criarProduto(this.formularioProduto.value)
        .subscribe({
          next: (res) => {
            this.snackBarService.openSnackBar('Produto criado no book com sucesso', 'my-snack-bar-sucess');
          },
          error: (erro) => {
            console.log(erro)
            this.snackBarService.openSnackBar(erro.error.mensagem, 'my-snack-bar-erro');
            this.carregando = false;
          }, complete: () => {
            this.salvarImagemComProduto();
            this.snackBarService.openSnackBar("Produto editado com sucesso", "my-snack-bar-sucess");
            this.carregando = false;
            // this.realizarConsulta(this.cdProduto);
            // this.consultarPorId(this.cdProduto)
          }
        });
    } else {
      this.snackBarService.openSnackBar(
        "Formulario invalido",
        'my-snack-bar-erro'
      );
      this.validatorsService.marcaCamposComErro(this.formularioProduto);
    }
  }

  public editarProduto() {
    if (this.formularioProduto.valid) {
      this.carregando = true;
      this.produtoDetalheService
        .criarProduto(this.formularioProduto.value)
        .subscribe({
          next: (res) => {
            this.snackBarService.openSnackBar("Produto editado com sucesso", "my-snack-bar-sucess");
          },
          error: (erro) => {
            console.log(erro)
            this.carregando = false;
            this.snackBarService.openSnackBar(erro.error.mensagem, 'my-snack-bar-erro');
          }, complete: () => {
            this.carregando = false;
            this.consultarPorIdClick(this.cdProduto)
          }
        });
    } else {
      this.snackBarService.openSnackBar(
        "Formulario invalido",
        'my-snack-bar-erro'
      );
      this.validatorsService.marcaCamposComErro(this.formularioProduto);
    }
  }


  public consultarPorId(codProduto: any, event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.realizarConsulta(codProduto);
    }
  }

  public consultarPorIdClick(codProduto: any) {
    this.realizarConsulta(codProduto);
  }

  public realizarConsulta(codProduto: any) {
    this.cdProduto = codProduto
    this.carregando = true;
    this.produtoDetalheService.consultarProduto(codProduto).subscribe({
      next: (res) => {
        this.iniciarFormulario()
        this.formularioProduto.patchValue(res);
        console.log(this.formularioProduto.value)

        setTimeout(() => {
          const textareas = document.querySelectorAll('textarea'); // Seleciona todos os textareas
          textareas.forEach((textarea: any) => {
            this.autoAjuste({ target: textarea } as Event); // Ajusta a altura de cada um
          });
        }, 0);

      },
      error: (erro) => {
        this.carregando = false;
        this.limparFormulario()
      }, complete: () => {
        this.carregando = false;
        this.verificarSeExisteId();
        this.ajustarCampoPipes()

      }
    });
  }
  public ajustarCampoPipes() {
    const areaFormatada = this.areaFormatterPipe.transformNumber(this.formularioProduto.controls['area'].value);
    const pesoFormatado = this.areaFormatterPipe.transformNumber(this.formularioProduto.controls['peso'].value)
    this.formularioProduto.patchValue({
      area: areaFormatada,
      peso: pesoFormatado
    });
  }

  public limparFormulario() {
    this.listaDeImagens = []
    this.urlImagem = "";
    this.mostrarValorSupersmart = false;
    this.formularioProduto.reset();
    this.iniciarFormulario();
    this.router.navigate(['engenharia/produto-detalhe']);
  }


  public consultaStatusBook() {
    this.statusBookService.consultarStatusBook().subscribe({
      next: (res) => {
        this.statusBook = res;
      },
    });
  }

  public consultaStatusDesenvolvimento() {
    this.statusDesenvolvimentoService
      .consultarStatusDesenvolvimento()
      .subscribe({
        next: (res) => {
          this.statusDesenvolvimento = res;
        },
      });
  }

  public consultarTipoDesenvolvimento() {
    this.tipoDesenvolvimentoService.consultarTiposDensolvimento().subscribe({
      next: (res) => {
        this.tipoDesenvolvimento = res;
      },
    });
  }

  public consultaProcesso() {
    this.processoService.consultarProcessos().subscribe({
      next: (res) => {
        this.processos = res;

      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public consultaSubstrato() {
    this.substratoService.consultarSubstratos().subscribe({
      next: (res) => {
        this.substratos = res;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public consultarSituacao() {
    this.situacaoService.consultarTodasSituacao().subscribe({
      next: (res) => {
        this.situacoes = res;
      }, error: (error) => {

      }
    });
  }


  public gerenciarTipoDesenvolvimento() {
    let dialogTemp = this.dialog.open(TipoDenvolvimentoComponent, {
      panelClass: 'modal-class'

    });
    dialogTemp.afterClosed().subscribe({
      next: (res) => {
        this.consultarTipoDesenvolvimento()
      }
    });
  }


  public gerenciarSituacao() {
    let dialogTemp = this.dialog.open(SituacaoModalComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "800px",
      maxHeight: "650px",

    });
    dialogTemp.afterClosed().subscribe({
      next: (res) => {
        this.consultarSituacao()
      }
    })
  }


  public gerenciarProcesso() {
    let dialogTemp = this.dialog.open(ProcessoComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "800px",
      maxHeight: "650px",
      // position: { top: '30px' }
    });
    dialogTemp.afterClosed().subscribe({
      next: (res) => {
        this.consultaProcesso();
      }
    })
  }
  public gerenciarSubstrato() {
    let dialogTemp = this.dialog.open(SubstratoComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "800px",
      maxHeight: "650px",
    });
    dialogTemp.afterClosed().subscribe({
      next: (res) => {
        this.consultaSubstrato();
      }
    })
  }

  public gerenciarStatusDesenvolvimento() {
    let dialogTemp = this.dialog.open(StatusDesenvolvimentoComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "800px",
      maxHeight: "650px",

    });
    dialogTemp.afterClosed().subscribe({
      next: (res) => {
        this.consultaStatusDesenvolvimento();
      }
    })
  }

  public gerenciarStatusBook() {
    let dialogTemp = this.dialog.open(StatusBookComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "800px",
      maxHeight: "650px",

    });
    dialogTemp.afterClosed().subscribe({
      next: (res) => {
        this.consultaStatusBook();
      }
    })
  }

  public verificarSeExisteId() {
    if (this.formularioProduto.controls['id'].value != undefined) {
      this.snackBarService.openSnackBar(`produto já cadastrado no book`, 'my-snack-bar-sucess');
      this.emitirCodigoProdutoExistente()
      this.consultarImagemCadastrada();

    } else {
      this.snackBarService.openSnackBar(`Buscando dados do produto`, 'my-snack-bar-sucess');
      this.verificarBeneficiamento();
      this.mostrarValorSupersmart = true
      this.produtoDetalheService.consultarImagemProdutoSemId(this.cdProduto)
        .subscribe({
          next: (res) => {
            this.imagemBlob = res;
            this.urlImagem = this.santize.bypassSecurityTrustUrl(
              URL.createObjectURL(this.imagemBlob)
            );

          },
          error: (erro) => { },
        });
    }
  }

  public emitirCodigoProdutoExistente() {
    EmisorEventoGeralService.produtoSupersmart.emit(this.cdProduto);
    this.consultaSupersmart = false;
    this.router.navigate([`/engenharia/produto-detalhe/${this.cdProduto}`])
  }


  public pegarImagem(): File[] {
    const nomeDoArquivo = "imagemTemp.jpg"; // Nome que você deseja dar ao arquivo
    const tipoMime = "image/jpeg"; // Tipo MIME adequado para a imagem
    let arquivo: File[] = [];
    let listImagem: File = new File([this.imagemBlob], nomeDoArquivo, { type: tipoMime });
    arquivo.push(listImagem);
    return arquivo
  }

  public montarFormData(): FormData {
    return this.formDataService.criarFormData("engImgProduto", this.pegarImagem(), { "cdProduto": this.formularioProduto.get('cdProduto')!.value });
  }

  public salvarImagemComProduto() {
    this.carregando = true;
    this.imagemService.salvarImagemProduto(this.montarFormData()).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.carregando = false;
      }, complete: () => {
        this.carregando = false;
        this.emitirCodigoProdutoExistente();
      }
    })
  }

  public consultarImagemCadastrada() {
    this.listaDeImagens = [];
    this.urlImagem = [];
    if (this.cdProduto) {
      this.imagemService.consultarTodasImagemPorIdProduto(this.cdProduto).subscribe({
        next: (res) => {
          this.listaDeImagens = res;
        }, error: (erro) => {
          console.log(erro)
        }, complete: () => {
          this.montarImagens();
        }
      });
    }
  }

  public verificarBeneficiamento() {
    const beneficiamento = this.removerAcentos(this.formularioProduto.get('nomeBeneficiamento')!.value.toString().trim()).toUpperCase();
    let beneficiamentoSeparados = beneficiamento.toString().split('+');
    let resultados = beneficiamentoSeparados.flatMap(b =>
      ["KTL", "PO", "FOSFATO", "LIQUIDA"].filter(keyword => b.trim().toUpperCase().includes(keyword))
    );
    let processoEncontrado:string;
    if (resultados.length >= 2) {
      processoEncontrado = `${resultados[0]} + ${resultados[1]}`;
    }else if(resultados){
      processoEncontrado = resultados[0];
    }
    this.processos.forEach(p=>{
      let valorDeComparacao= this.removerAcentos(p.descricao)
      if(valorDeComparacao == processoEncontrado){
        this.formularioProduto.controls['processo'].setValue(p.descricao)
      }
    })
  }

  public removerAcentos(conteudo: string): string {
    return conteudo.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
  }

  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formularioProduto, nome);
  }
  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formularioProduto, nome);
  }
  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formularioProduto, nome);
  }
  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }
  public verificarQuantidadeCaracteres(nome: string) {
    return this.formValidacaoService.obterValidacaoTamanho(this.formularioProduto, nome, this.descricaoTamanhoPermitido);
  }

  public montarImagens() {
    this.cdf.detectChanges();
    this.destruirSwiper();
    const options: SwiperOptions = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff; background:#09638E" class="' + className + '">' + (index + 1) + '</span>';
        }
      },
      allowTouchMove: true, // Garante suporte ao touch
      observer: true,       // Observa alterações no Swiper
      observeParents: true, // Observa mudanças nos elementos pais
      threshold: 30,
      modules: [Pagination],
    };
    this.swiper = new Swiper(this.swiperProduto.nativeElement, options);
  }

  public destruirSwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }


  onEnter(event: Event): void {
    event.preventDefault(); // Impede o envio do formulário
  }



}
