<app-container-component tituloPage="Notificações do sistema" subtituloPage="Notificações">

    <div class="row justify-content-center">
        <div class="col-12">
            <span>
                <strong>Digite a mensagem para enviar</strong>
            </span>
        </div>
        <div class="col-12">
            <div class="assunto">
                <div class="header-notificaca">
                    <h4 class="mt-2">{{mensagem.assunto ? mensagem.assunto : 'Assunto'}}</h4>
                    <label><strong>Remetente: </strong>TODOS</label>
                </div>
                <div class="body-notificacao">
                    <div class="msg mt-2 d-flex">
                        <div class="d-block">Mensagem conteudo do modal </div>
                    </div>
                    <div class="mt-5">
                        <a [href]="mensagem.url" target="_blank">Clique aqui para visualizar</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-12 mt-2">
            <div class="alert alert-primary " role="alert">
                Conteúdo da notificação
            </div>
            <div class="col-6 mb-2">
                <input type="text" class="form-control form-control-sm" placeholder="Titulo"
                    [(ngModel)]="mensagem.assunto">
            </div>
            <div class="col-6 mb-2">
                <input type="text" class="form-control form-control-sm" placeholder="Link para encaminhar os usuários"
                    [(ngModel)]="mensagem.url">
            </div>
            <app-formulario-editor-jodit  (conteudoHtml)="receberConteudo($event)"></app-formulario-editor-jodit>
            <div class="mt-2">
                <button class="btn btn-sm ms-1" (click)="enviarNotificacao()"> <i class="fa-solid fa-envelope-circle-check"></i> Enviar notificação para todos usúarios </button>
            </div>
        </div>
    </div>
</app-container-component>