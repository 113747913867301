<app-container-component [tituloPage]="tituloPagina" [subtituloPage]="subTitulo">
    <section class="row p-2">

        <div class="col-12 col-lg-6 mt-3 mb-3">
            <button class="btn btn-sm" (click)="cadastrarNovoUsuario()">Novo +</button>
        </div>
        <div class="col-12 col-lg-6 mb-3 mt-3">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Digite o nome" aria-label="Digite o nome"
                    aria-describedby="button-addon2" [(ngModel)]="email" (keyup.enter)="consultaPorNomeEmail()">
                <button class="btn " type="button" id="button-addon2" (click)="consultaPorNomeEmail()">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </button>
            </div>
        </div>

        <div class="col-12">
            <div class="table">
                <table class="table  table-striped table-hover">
                    <thead class="table-secondary">
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (usuario of usuarios; track $index) {
                        <tr>
                            <td>{{usuario.id}}</td>
                            <td>{{usuario.nome}}</td>
                            <td>{{usuario.username}}</td>
                            @if (usuario.contaAtiva) {
                            <td class="text-center" (click)="confirmacaoBloqueio(usuario.username)"><i
                                    class="fa-solid fa-lock-open text-success"></i></td>
                            }@else {
                            <td class="text-center" (click)="confirmacaoDesbloqueio(usuario.username)"><i
                                    class="fa-solid fa-lock text-danger"></i></td>
                            }
                            <td class="text-center" (click)="editarrUsuario(usuario.id)">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        <app-paginator (paginaMudou)="mudancaNaPagina($event)" [paginaAtual]="pagina"
            [quantidadesPorPagina]="quantidadesPorPagina" [totalElementos]="totalElementos"
            [totalPorPagina]="totalPorPagina">
        </app-paginator>
    </section>

    <section class="p-2">
       
    </section>
</app-container-component>

<!-- <app-container-component [header]="false" [subtituloPage]="'Certificado e-cnpj A1'">
    <app-certificado></app-certificado>
</app-container-component> -->

<!-- <app-container-component [header]="false" [subtituloPage]="'Permissões do sistema'">
    <app-permissoes></app-permissoes>
</app-container-component> -->