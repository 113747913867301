<app-container-component tituloPage="ADERENCIA" subtituloPage="ADERENCIA LISTA">
    <div class="col-12 d-flex flex-column justify-content-end align-items-end">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mt-2 me-2">
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="valorPesquisado"
                    (keypress)="this.onKeyPress($event)" placeholder="Produto, Item , Cliente, Data, Status">
                <button class="btn btn-sm" type="button"><i class="fa-solid fa-magnifying-glass"
                        (click)="this.buscar($event)"></i></button>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-hover pointer table-striped align-middle">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">Produto</th>
                    <th scope="col">Item</th>
                    <th scope="col">Cliente</th>
                    <th scope="col">Inspetor</th>
                    <th scope="col" class="text-center">Data teste</th>
                    <th scope="col" class="text-center">Status</th>
                    <th scope="col" class="text-center">Vizualizar</th>
                </tr>
            </thead>
            <tbody>
                @if(listaDeAderencia.length > 0) {
                @for (aderencia of listaDeAderencia; track $index) {
                <tr title="Clique para visualizar" class="table-row">
                    <td data-label="Produto"  (click)="editarAderencia(aderencia.id!)">{{ aderencia.cdProduto }}</td>
                    <td data-label="Item"  (click)="editarAderencia(aderencia.id!)">{{ aderencia.item }}</td>
                    <td data-label="Cliente" (click)="editarAderencia(aderencia.id!)">{{ aderencia.cliente }}</td>
                    <td data-label="Inspetor" (click)="editarAderencia(aderencia.id!)">{{ aderencia.usuario }}</td>
                    <td data-label="Data teste" class="text-center"  (click)="editarAderencia(aderencia.id!)">{{aderencia.dataTeste | date:'dd/MM/yyyy'}}</td>
                    <td data-label="Status" class="text-center"  (click)="editarAderencia(aderencia.id!)">
                        <span class="badge bg" [ngClass]="{
                  'aprovado': aderencia.status == 'Aprovado',
                  'reprovado': aderencia.status == 'Reprovado',
                }">
                            {{ aderencia.status }}
                        </span>
                    </td>
                    <td class="text-center"  (click)="visualizar(aderencia.id!)"><i class="fa-solid fa-eye"></i></td>
                </tr>
                }
                }
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center col-12">
        <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
            [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
            (paginaMudou)="onPageChange($event)"></app-paginator>
    </div>
</app-container-component>