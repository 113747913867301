<div class="separador mt-2 mb-2" #imagensDoProduto>
  <strong>IMAGENS DO PRODUTO</strong>
</div>
@defer (on viewport(imagensDoProduto)) {
<div class="position-img">
  <div class="col-12 ">
    <app-swiperComponent [listaDeDados]="imagensProduto"></app-swiperComponent>
  </div>
</div>
}@loading {
<div class="card-load-box">
  <div class="card_load_extreme_descripion"></div>
</div>
}