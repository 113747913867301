export class Certificado {
    id:number = 0;
    nome:string = '';
    empresa: string = ''
    cnpj: string = '';
    dataCadastro:string = '';
    dataExpiracao:string = ''
    path:string = '';

}
