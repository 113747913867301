import { Component } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { CommonModule } from '@angular/common';
import { NormaPinturaConsultarComponent } from "../norma-pintura-consultar/norma-pintura-consultar.component";

@Component({
  selector: 'app-norma-pintura-home',
  standalone: true,
  imports: [ContainerComponentComponent, CommonModule, NormaPinturaConsultarComponent],
  templateUrl: './norma-pintura-home.component.html',
  styleUrl: './norma-pintura-home.component.scss'
})
export class NormaPinturaHomeComponent {

  
}
