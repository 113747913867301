import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { TipoDesenvolvimento } from '../../../../model/TipoDesenvolvimento';
import { TipoDesenvolvimentoService } from '../../../../services/engenharia/produto/tipo-desenvolvimento.service';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { MensagemErrorsComponent } from '../../../../componentes/mensagem-errors/mensagem-errors.component';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { ValidatorsService } from '../../../../utils/validators.service';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-tipo-denvolvimento',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BotaoComponent,MensagemErrorsComponent],
  templateUrl: './tipo-denvolvimento.component.html',
  styleUrl: './tipo-denvolvimento.component.scss',
})
export class TipoDenvolvimentoComponent implements OnInit {

  tipoDesenvolvimento: TipoDesenvolvimento[] = [];
  atualizacao: boolean = false;
  formularioTipoDesenvolvimento!: FormGroup

  constructor(
    private tipoDesenvolvimentoService: TipoDesenvolvimentoService,
    private formBuild: FormBuilder,
    private snackBarService: SnackBarService,
    private validatorsService:ValidatorsService,
    private dialog:MatDialog,
  ) {

  }


  ngOnInit(): void {
    this.listarTipos();
    this.formularioTipoDesenvolvimento = this.formBuild.group({
      id: [],
      descricao: ['',[Validators.required,this.validatorsService.semEspaçoEmBranco()]],
      data: [moment().format("DD-MM-yyyy")]
    });
  }

  public listarTipos() {
    this.tipoDesenvolvimentoService.consultarTiposDensolvimento().subscribe({
      next: (tipos) => {
        this.tipoDesenvolvimento = tipos;
      },
    });
  }

  public criarTipo() {
    if(this.formularioTipoDesenvolvimento.valid){
      this.tipoDesenvolvimentoService.criarTipoDesenvolvimento(this.formularioTipoDesenvolvimento.value).subscribe({
        next: (res) => {
  
        }, error: (error) => {
  
        }, complete: () => {
          this.listarTipos();
          this.limparFormulario();
        }
      });
    }
  }

  buscarPorId(id: number) {
    this.tipoDesenvolvimentoService.buscarPorId(id).subscribe({
      next: (res) => {
        this.formularioTipoDesenvolvimento.patchValue(res);
      }, error: (erro) => {

      }, complete: () => {
        this.listarTipos();
      }
    })
  }



  public editarTipo() {
    this.criarTipo();
  }

  public voltar() {
    this.formularioTipoDesenvolvimento.reset();
  }


  public deletarItem(nomeArquivo:string,id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: {nomeArquivo:nomeArquivo,textoConteudo:"Processo"},
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next:(res)=>{
        if (res) {
        this.deletar(id);
        };
      }
    });
  }

  private deletar(id:number){
    this.tipoDesenvolvimentoService.deletarTipoDesenvolvimento(id).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Item deletado com sucesso", "my-snack-bar-sucess")
      }, complete: () => {
        this.listarTipos();
      }
    });
  }

  public limparFormulario(){
    this.formularioTipoDesenvolvimento.reset();
  }
  
  
  public obterValidacao(nome: string): FormControl {
    const control = this.formularioTipoDesenvolvimento.get(nome);
    if (!control) {
      throw new Error('Controle de formulario não encontrado' + nome);
    }
    return control as FormControl;
  }

  public tocado(nome: string): boolean {
    const control = this.formularioTipoDesenvolvimento.get(nome);
    if (!control?.value) {
      return control!.touched;
    }
    return false;
  }

  public validar(nome: string): boolean {
    const control = this.formularioTipoDesenvolvimento.get(nome);
    return control!.touched && control!.valid;
  }

  public closeModal(){
    this.dialog.closeAll()
  }

}
