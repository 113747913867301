<header>
  <nav class="navbar bg-body-tertiary">
    <div class="container-fluid">
      <div class="d-flex container-menu">
        <div routerLink="home">
          <a class="navbar-brand bg" routerLink="/home">
            <i class="fa-solid fa-house"></i>
          </a>
        </div>
        @if(tipo === 'homologacao'){
        <div class="homologacao">
          AMBIENTE DE HOMOLOGAÇÃO
        </div>
        }
        @if(tipo === 'desenvolvimento'){
        <div class="homologacao">
          DESENVOLVIMENTO
        </div>
        }
        <!-- SISTEMA DE NOTIFICAÇÕES PARA APROVAÇÃO DE DOCUMENTOS -->
        <div class="d-flex gap-4 align-items-center justify-content-center">
          @if(quantidadeDeInformacaoDocumentadaPendente != 0){
          <div class="botao-notificacao position-relative" (click)="openBottomSheet()">
            <i class="fa-solid fa-bell"></i>
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {{quantidadeDeInformacaoDocumentadaPendente}}
              <span class="visually-hidden">unread messages</span>
            </span>
          </div>
          }
          <a class="navbar-brand bg" routerLink="/home">
            <img class="imagem-logo-header" src="assets/logo-croma.png" alt="">
          </a>
          <!-- MENU DE OPÇÕES -->
          <div class="menu-container">
            <i class="fa-solid fa-ellipsis-vertical"></i>
            <div class="menu">
              <ul>
                <li routerLink="notificacoes-sistema">
                  <i class="fa-solid fa-bell"></i>
                  Notificações
                </li>
                <li routerLink="usuario-config">
                  <i class="fa-solid fa-key me-2"></i>
                  Alterar senha
                </li>
                <li routerLink="usuario-config">
                  <i class="fa-solid fa-file-pen me-2"></i>
                  Atualizar dados
                </li>
                <li (click)="logOffSistema()">
                  <i class="fa-solid fa-power-off me-2"></i>
                  Sair
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>