<app-container-component [tituloPage]="'Recursos de Medição e Monitoramento'" [subtituloPage]="'Recursos de Medição e Monitoramento'">
    <div class="row m-auto gap-3">
        <div
            class="col-12 col-md-12  sessao-card d-flex justify-content-center align-items-center col-lg-3 col-xl-3 col-xxl-2 card-contador gap-1">
            <p class="text-center m-0">Recursos</p>
            <label class="text-center"><strong>{{recursos}}</strong></label>
        </div>
        <div
            class="col-12 col-md-12  sessao-card d-flex justify-content-center align-items-center col-lg-3 col-xl-3 col-xxl-2 card-contador-descalibrado gap-1">
            <p class="text-center m-0">Descalibrados</p>
            <label class="text-center"><strong>{{descalibrados}}</strong></label>
        </div>
    </div>
</app-container-component>
<div>
    <app-controles-medicao-monitoramento-lista [comTopo]="false"></app-controles-medicao-monitoramento-lista>
</div>