import { Component, inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import PartesInteressadasConsultaDto from '../../../../model/acoes/partes-interessadas/ParteInteressadaConsultaDto';
import AcoesPartesInteressadas from '../../../../model/acoes/partes-interessadas/AcoesPartesInteressadas';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { PartesInteressadasService } from '../../../../services/acoes/partes-interessadas/partes-interessadas.service';
import { CommonModule } from '@angular/common';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-partes-interessada-listar',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent],
  templateUrl: './partes-interessada-listar.component.html',
  styleUrl: './partes-interessada-listar.component.scss'
})
export class PartesInteressadaListarComponent implements OnInit {
  idMapeamento: number = 0;
  mostrarFormularioAcoes: boolean = false;
  acoesParteInteressada!: AcoesPartesInteressadas;
  @Input() mapeamentoRecebido!: PartesInteressadasConsultaDto;
  @Input() ativarHeader: boolean = true;
  @Output() emitirEdicao: EventEmitter<AcoesPartesInteressadas> = new EventEmitter()

  private activatedRoute = inject(ActivatedRoute);
  private snackBarService = inject(SnackBarService);
  private partesInteressadaService = inject(PartesInteressadasService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private dialog = inject(MatDialog);
  private loginService = inject(LoginService);


  ngOnInit(): void {
    this.verificarRota();
  }


  public verificarRota() {
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idMapeamento = res.id;
        if (this.idMapeamento != 0) {
          this.consultaPorId();
        }
      }
    })
  }

  public consultaPorId() {
    this.partesInteressadaService.consultarPorId(this.idMapeamento).subscribe({
      next: (res) => {
        this.mapeamentoRecebido = res;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }




  public editarParteInteressadaSelecionada(acaoSelecionada: AcoesPartesInteressadas) {
    this.emitirEdicao.emit(acaoSelecionada);
    this.partesInteressadaService.emitirEdicao.emit(acaoSelecionada);
  }


  public deletarParteInteressadaSelecionada(acao: AcoesPartesInteressadas) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: acao.parteInteressada, texto: "Deseja apagar essa parte interessada" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.deletar(acao.id);
        };
      }
    });

  }
  private deletar(id: number) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.partesInteressadaService.deletarAcoesPartesInteressadas(id).subscribe({
      next: (res) => {
        this.mapeamentoRecebido = res;
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar deletar ação")
      }, complete: () => {
        this.consultaPorId();
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.openSnackBar("Ação deletada com sucesso", "my-snack-bar-sucess");
      }
    });
  }
  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_ENG_EDIT']);
  }

  public temPermissaoExcluir() {
    return this.loginService.temPermissao(['ROLE_ENG_ADMIN']);
  }



}
