import { CommonModule } from '@angular/common';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuditoriaInternaConsultaDto } from '../../../../model/acoes/auditoria-Interna/AuditoriaInternaConsultaDto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { BotaoComponent } from "../../../../componentes/botao/botao.component";
import { PartesInteressadasService } from '../../../../services/acoes/partes-interessadas/partes-interessadas.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { AuditoriaInternaService } from '../../../../services/acoes/auditoria-interna/auditoria-interna.service';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';

@Component({
  selector: 'app-auditoria-interna-modal',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, ReactiveFormsModule, BotaoComponent],
  templateUrl: './auditoria-interna-modal.component.html',
  styleUrl: './auditoria-interna-modal.component.scss'
})
export class AuditoriaInternaModalComponent implements OnInit {
  auditoriaRelatorioForm!: FormGroup;
  idAuditoria: number = 0;
  auditoria: AuditoriaInternaConsultaDto = new AuditoriaInternaConsultaDto();
  private auditoriaInternaService = inject(AuditoriaInternaService);
  private snackBarService = inject(SnackBarService);
  private formValidacaoService = inject(FormValidacaoService);

  constructor(
    private dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  private activatedRoute = inject(ActivatedRoute);
  private formBuilder = inject(FormBuilder);

  public enviarDados() {

  }


  ngOnInit(): void {
    this.verificarRota();
    this.auditoria = this.data.auditoria;
    this.auditoriaRelatorioForm.patchValue(this.data.relatorio);
  }

  public registrarAuditoria() {

  }


  public verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idAuditoria = res.id;
        // this.consultaAponetamentos();
      }
    });
  }

  public iniciarFormulario() {
    this.auditoriaRelatorioForm = this.formBuilder.group({
      id: [],
      processo: [null,Validators.required],
      auditado: [null,Validators.required],
      obs: [null,Validators.required],
    })
  }

  public cadastrarRelatorio() {
    this.auditoriaRelatorioForm.removeControl("id");
    this.auditoriaInternaService.cadastrarRelatorio(this.auditoria.id, this.auditoriaRelatorioForm.value).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar cadastrar relátorio")
      }, complete: () => {
        this.dialogRef.close(true)
        this.snackBarService.openSnackBar("Relátorio cadastrado com sucesso", "my-snack-bar-sucess");
      }
    });

  }
  public editarRelatorio() {
    this.auditoriaInternaService.editarRelatorio(this.auditoria.id, this.auditoriaRelatorioForm.value).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar editar relátorio")
      }, complete: () => {
        this.dialogRef.close(true)
        this.snackBarService.openSnackBar("Relátorio editado com sucesso", "my-snack-bar-sucess");
      }
    });
  }

  public fecharModal(){
    this.dialogRef.close()
  }

    public obterValidacao(nome: string): FormControl {
      return this.formValidacaoService.obterValidacao(this.auditoriaRelatorioForm, nome);
    }
  
    public tocado(nome: string): boolean {
      return this.formValidacaoService.tocado(this.auditoriaRelatorioForm, nome);
    }
  
    public validar(nome: string): boolean {
      return this.formValidacaoService.validar(this.auditoriaRelatorioForm, nome);
    }
  
    public autoAjuste(evento: Event) {
      return this.formValidacaoService.autoAjuste(evento);
    }

}
