<header>
    <div class="content-header">
        <strong>REDEFINIÇÃO DE SENHA</strong>
    </div>
</header>

<section>
    <div class="p-4" >
        <label for="" class="form-label">Senha:</label>
        <input type="password" class="form-control" [(ngModel)]="senha">
        <label for="" class="form-label">Confirmação de senha:</label>
        <input type="password" class="form-control" [(ngModel)]="confirmaSenha">
        <div class="invalid-feedback" [ngClass]="{'d-block': erro === true}">
            {{mensagem}}
        </div>
        <button class="btn btt mt-3" (click)="redefinirSenha()">Salvar</button>
        <button class="btn btn-danger ms-2 mt-3" (click)="fechar()">Cancelar</button>
    </div>
</section>