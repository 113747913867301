<app-container-component tituloPage="ORDEM DE SERVIÇO" [desativarSubTitulo]="false">
    <div class="separador mt-2 mb-2" #idTeste>
        <strong>ORDENS DE SERVIÇO</strong>
    </div>
    <div class="row d-flex justify-content-end mt-2">
        <div class="col-4 col-lg-6 mt-2 text-end">
            <button class="btn btn-sm" (click)="ativarCastro()">{{textoBotao}}</button>
        </div>
        <div class="col-8 col-lg-6 mt-2">
            <div class="input-group mb-3">
                <select name="" id="" class="form-select form-select-sm " [(ngModel)]="status" (change)="consultarOs()">
                    <option value="Aberta">Aberta</option>
                    <option value="Aprovada">Aprovada</option>
                    <option value="Em atendimento">Em atendimento</option>
                    <option value="Reprovada">Reprovada</option>
                    <option value="Finalizado">Finalizado</option>
                </select>
                <button class="btn btn-sm" type="button" id="button-addon2">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-hover table-striped align-middle">
                    <thead class="table-secondary">
                        <tr>
                            <th class="text-center">OS</th>
                            <th>Solicitante</th>
                            <th>Equipamento</th>
                            <th>Prioridade</th>
                            <th>Status</th>
                            <th class="text-center">Excluir</th>
                        </tr>
                    </thead>
                    <tbody>
                        @if(osConsulta.length > 0) {
                        @for (os of osConsulta; track $index) {
                        <tr class="c-pointer">
                            <td data-label="OS" class="text-center" (click)="visualizarOs()">{{os.id}}</td>
                            <td data-label="Solicitante" class="" (click)="visualizarOs()">{{os.solicitante}}</td>
                            <td data-label="Equipamento" class="" (click)="visualizarOs()">{{os.equipamento.descricao}}
                            </td>
                            <td data-label="Prioridade" class="" (click)="visualizarOs()">{{os.prioridade}}</td>
                            <td data-label="Status" class="" (click)="visualizarOs()">{{os.status}}</td>
                            @if (os.status === 'ABERTA') {
                            <td data-label="Excluir" class="text-danger text-center c-pointer"
                                (click)="excluirOS(os.id, os.equipamento.descricao)">
                                <i class="fa-solid fa-trash"></i>
                            </td>
                            }@else {
                            <td class="text-danger text-center c-pointer"></td>
                            }
                        </tr>
                        }
                        }@else {
                        <td [colSpan]="6" class="text-center">
                            <div class="alert alert-danger d-flex justify-content-center" role="alert">
                                Não há Os {{status}}
                            </div>
                        </td>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        <app-paginator [quantidadesPorPagina]="itensPorPagina" [paginaAtual]="paginaAtual"
            [totalPorPagina]="totalPorPagina" [totalElementos]="totalElementos" (paginaMudou)="onPageChange($event)">
        </app-paginator>
    </div>

    @if(habilitaCadastro){
    <div class="separador mt-2 mb-2" #idTeste>
        <strong>CADASTRO DE ORDEM DE SERVIÇO</strong>
    </div>
    <div class="row">
        <div class="col-12">
            <app-ordem-servico-cadastro></app-ordem-servico-cadastro>
        </div>
    </div>
    }

</app-container-component>