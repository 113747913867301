import { Component } from '@angular/core';
import { PlanejamentoComunicacaoListarComponent } from "../planejamento-comunicacao-listar/planejamento-comunicacao-listar.component";
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";

@Component({
  selector: 'app-planejamento-comunicacao-home',
  standalone: true,
  imports: [PlanejamentoComunicacaoListarComponent],
  templateUrl: './planejamento-comunicacao-home.component.html',
  styleUrl: './planejamento-comunicacao-home.component.scss'
})
export class PlanejamentoComunicacaoHomeComponent {

}
