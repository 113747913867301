<app-container-component [tituloPage]="'MANUAIS DE CLIENTES'" [subtituloPage]="'Manuais cadastrados'">
    <div class="row">
        <div class="col-12">
            <div class="table ">
                <table class="table table-striped table-hover table-sm table-responsive">
                    <thead class="table-secondary">
                        <tr>
                            <th>Cliente</th>
                            <th>Descricao</th>
                            <th>Responsável</th>
                            <th>Atualização</th>
                            <th>Atualizado por</th>
                            <th>Versão</th>
                            <th>Status</th>
                            <th colspan="2" class="text-center">Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (manual of manuais; track $index) {
                        <tr class="c-pointer">
                            <td>{{manual.cliente}}</td>
                            <td>{{manual.descricao}}</td>
                            <td>{{manual.usuarioCadastro}}</td>
                            <td>{{manual.dataAlteracao | date : 'dd/MM/yyyy'}}</td>
                            <td>{{manual.usuarioAlteracao}}</td>
                            <td>{{manual.versao}}</td>
                            <td>{{manual.status}}</td>
                            <td class="text-center">
                                @if(temPermissao()){
                                <i class="fa-solid fa-pen-to-square text-success me-3"
                                    (click)="editarManual(manual.id)"></i>
                                }
                                <i class="fa-solid fa-eye" (click)="gerenciarManual(manual.id)"></i>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <app-paginator [quantidadesPorPagina]="itensPorPgina" [totalPorPagina]="totalItens" [paginaAtual]="pagina"
                [totalElementos]="totalElementos" (paginaMudou)="onPageChange($event)"></app-paginator>
        </div>
    </div>
</app-container-component>