<div class="separador mt-2 mb-2" #padraoDeRetrabalho>
    <strong>PADRÃO DE RETRABALHO</strong>
</div>
@defer (on viewport(padraoDeRetrabalho)) {
@if(padraoRetrabalho.length > 0){
<ul class="nav nav-tabs" id="tabRetrabalho" role="tablist">
    <li class="nav-item" role="presentation">
        <a class="nav-link active" id="retrabalho1-tab" data-bs-toggle="tab" href="#retrabalho1"
            (click)="consultarPadraoRetrabalho(dados.numeroProcesso1)" role="tab" aria-controls="processo1"
            aria-selected="true">PADRÃO</a>
    </li>
</ul>

<div class="tab-content mt-3" id="myTabContent">
    <!-- Conteúdo da Tab Processo 1 -->
    <div class="tab-pane fade show active" id="retrabalho1" role="tabpanel" aria-labelledby="retrabalho1-tab">
        <div class="position-img">
            <div class="col-12 ">
                @if(padraoRetrabalho.length == 0){
                <div class="card-load-box">
                    <div class="card_load_extreme_descripion"></div>
                </div>
                }
                @if (imagemNaoCadastrada) {
                <div class="col-12 text-center text-danger">
                    <h3>Imagem não cadastrada!</h3>
                </div>
                }
                <app-swiperComponent [listaDeDados]="padraoRetrabalho"></app-swiperComponent>
            </div>
        </div>
    </div>
</div>
}@else {
<div class="text-center text-danger mt-3">
    <p class="alert alert-danger">Padrão de retrabalho não cadastrado!</p>
</div>
}
}@loading {
<div class="card-load-box">
    <div class="card_load_extreme_descripion"></div>
</div>
}