import { Routes } from "@angular/router";

export const routesQualidadeEdit: Routes = [

    //ALERTA
    {
        path: "alerta-editar/:id",
        loadComponent: () => import("../../paginas/qualidade/alerta/editarAlerta/editarAlerta.component")
            .then(a => a.EditarAlertaComponent),
    },

    //CUSTO QUALIDADE
    {
        path: "custo-qualidade-registrar",
        loadComponent: () => import("../../paginas/qualidade/custo-qualidade/custo-da-qualidade-registrar/custo-da-qualidade-registrar.component")
            .then(c => c.CustoDaQualidadeRegistrarComponent),
    },

    //CUSTO PRODUTO NÃO CONFORME
    {
        path: "pnc-registrar/:id",
        loadComponent: () => import("../../paginas/qualidade/pnc-produto-nao-conforme/produto-nao-conforme-registra/produto-nao-conforme-registra.component")
            .then(r => r.ProdutoNaoConformeRegistraComponent),
    },

    {
        path: "pnc-apontamento/:id",
        loadComponent: () => import("../../paginas/qualidade/pnc-produto-nao-conforme/produto-nao-conforme-apontamento/produto-nao-conforme-apontamento.component")
            .then(r => r.ProdutoNaoConformeApontamentoComponent),
    },
]