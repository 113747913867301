import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngPadraoDefeitoService {

  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }
  public salvarPadraoDefeito(formdata: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-padrao-defeito`, formdata);
  }
  public editarDescricaoPadraoDefeito(id:number,descricao:string): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-padrao-defeito/${id}/${descricao}`, null);
  }


  public consultarPadraoDefeito(cdProduto:number,idProcesso:number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-padrao-defeito/${cdProduto}/${idProcesso}`);
  }

  public consultaImagemPorNome(nomeArquivo:string): Observable<Blob> {
    return this.http.get(`${this.url}/eng-padrao-defeito/download/${nomeArquivo}`,{responseType:'blob'});
  }

  public deletarPadraoDefeito(id:number): Observable<any> {
    return this.http.delete<any>(`${this.url}/eng-padrao-defeito/${id}`);
  }

  public salvarNovoPadraoDefeito(novaSequencia:any):Observable<any>{
    return this.http.post<any>(`${this.url}/eng-padrao-defeito/sequencia`,novaSequencia)
  }
}