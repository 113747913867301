
import { AfterViewInit, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PaginatorComponent } from '../../../componentes/paginator/paginator.component';
import { PageEvent } from '@angular/material/paginator';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../componentes/modal/modal.component';
import { Router } from '@angular/router';
import Swiper from 'swiper';
import { Controller, EffectFade, Pagination } from 'swiper/modules';
import { LoginService } from '../../../services/login/login.service';

@Component({
  selector: 'app-listar-dispositivos',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PaginatorComponent],
  templateUrl: './listar-dispositivos.component.html',
  styleUrl: './listar-dispositivos.component.scss'
})
export class ListarDispositivosComponent implements OnInit, AfterViewInit {
  @Input() dispositivos: Array<any> = [];
  @Input() caminho: string = 'dispositivo';
  rotaEdicao: string = '';
  url = environment.url;
  @Input() paginaAtual: number = 0;
  @Input() totalPorPagina: number = 0;
  @Input() totalTamanho: number = 0;
  @Input() quantidadesPorPagina: any[] = [];
  @Input() exibirPaginacao: boolean = true;
  @Output() paginaMudou = new EventEmitter<PageEvent>();
  @Output() excluscao = new EventEmitter<any>();
  private loginService = inject(LoginService);

  time: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,

  ) {

  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.montarImagens()
    }, 300)
  }

  ngOnInit(): void {

  }

  public onPageChange(event: PageEvent) {
    this.paginaMudou.emit(event)
    setTimeout(() => {
      this.montarImagens()
    }, 300)
  }

  public exclusao(dados: any) {
    this.excluscao.emit(dados);
  }

  public semImagem(event: any) {
    event!.target.src = "assets/Produto-sem-imagem.png"
    let imagem = event.target as HTMLElement;
    imagem.style.transform = "scale(1)";
    imagem.style.cursor = "default";
  }

  public deletarImagem(dispositivo: any) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: dispositivo,
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exclusao(dispositivo);
      }
    });
  }


  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_DISP_ADMIN', 'ROLE_DISP_EDIT'])
  }

  // public darZoom(evento: MouseEvent) {
  //   let imagem: HTMLElement = evento.target as HTMLElement;
  //   const rect = imagem.getBoundingClientRect();
  //   const x = evento.clientX - rect.left;
  //   const y = evento.clientY - rect.top;
  //   imagem.style.transformOrigin = `${x}px ${y}px`;
  //   imagem.style.transform = "scale(1.6)";
  //   if (this.time) {
  //     clearTimeout(this.time);
  //   }
  // }

  // public resetarZoom(evento: MouseEvent) {
  //   let imagem: HTMLElement = evento.target as HTMLElement;
  //   this.time = setTimeout(() => {
  //     imagem.style.transformOrigin = "center center";
  //     imagem.style.transform = "scale(1)";
  //   }, 800)
  // }

  // public resetarZoomNahora(evento: MouseEvent) {
  //   let card: HTMLElement = evento.currentTarget as HTMLElement;
  //   let imagem: HTMLElement = card.querySelector('img.imagem-card') as HTMLElement;
  //   if (imagem) {
  //     imagem.style.transformOrigin = "center center";
  //     imagem.style.transform = "scale(1)";
  //   }
  //   if (this.time) {
  //     clearTimeout(this.time);
  //   }
  // }


  public editarDispositivo(dispositivo: any) {
    this.rotaEdicao = this.trabalharUrl();
    this.router.navigate([`dispositivo/${this.rotaEdicao}/${dispositivo.id}`])
  }


  public trabalharUrl() {
    this.caminho = this.caminho.replace("eng-", "")
    // console.log(this.caminho)
    return this.caminho;
  }


  public calDelay(contador: number) {
    let delay = contador * 0.15;
    return `${delay.toString()}s`
  }


  public montarImagens() {
    this.dispositivos.forEach(e => {
      const swiper = new Swiper(`.mySwiper-${e.id}`, {
        fadeEffect: {
          crossFade: true
        },
        slidesPerView: 1,
        spaceBetween: 50,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff; background:#09638E" class="' + className + '">' + (index + 1) + "</span>";
          },
        },
        modules: [Pagination, Controller, EffectFade]
      });
    })

  }
}

