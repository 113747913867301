import { Component, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { CommonModule } from '@angular/common';
import { MedicaoMonitoramentoService } from '../../../../services/controles/medicao-monitoramento/medicao-monitoramento.service';
import { ControlesMedicaoMonitoramentoListaComponent } from "../controles-medicao-monitoramento-lista/controles-medicao-monitoramento-lista.component";

@Component({
  selector: 'app-controles-home',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, ControlesMedicaoMonitoramentoListaComponent],
  templateUrl: './controles-home.component.html',
  styleUrl: './controles-home.component.scss'
})
export class ControlesHomeComponent implements OnInit{

  recursos:number = 0;
  descalibrados:number = 0;

  constructor(
    private medicaoMonitoramentoService:MedicaoMonitoramentoService
  ){

  }
  
  ngOnInit(

  ): void {
    this.consultaTodosDisponiveis();
    this.consultaTodosDescalibrados();
  }


  public consultaTodosDisponiveis(){
    this.medicaoMonitoramentoService.consultaRecursosDisponiveis().subscribe({
      next:(res:any)=>{
        this.recursos = res.recursos
      },error:(error)=>{

      },complete:()=>{

      }
    });
  }
  
  public consultaTodosDescalibrados(){
    this.medicaoMonitoramentoService.consultaRecursosDescalibrados().subscribe({
      next:(res:any)=>{
        this.descalibrados = res.recursos
      },error:(error)=>{

      },complete:()=>{

      }
    });
  }

  public aplicarClasse(evento:MouseEvent){
    let cards = document.querySelectorAll('.sessao-card');
    cards.forEach(card=>{
      if(card != evento.target){
        card.classList.add('blur')
      }
    });
  }

  public removerBlur(evento:MouseEvent){
    let cards = document.querySelectorAll('.sessao-card');
    cards.forEach(card=>{
        card.classList.remove('blur')
    });
  }

}
