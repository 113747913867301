import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";
import { FormsModule } from '@angular/forms';
import { NotificacaoSistemaConsulta } from '../../../model/infra/notificacoes/notificacao-consulta';
import { SafeHtml } from '@angular/platform-browser';
import { FormularioEditorJoditComponent } from "../../../componentes/jodit-editor/formulario-editor-jodit/formulario-editor-jodit.component";
import { NotificacoesSistemaService } from '../../../services/infra/notificacoes-sistema.service';
import { NotificacaoColetivaCadastroDTO } from '../../../dto/notificacao/NotificacaoColetivaCadastroDTO';

@Component({
  selector: 'app-notificacoes',
  standalone: true,
  imports: [ContainerComponentComponent, FormsModule, FormularioEditorJoditComponent],
  templateUrl: './notificacoes.component.html',
  styleUrl: './notificacoes.component.scss'
})
export class NotificacoesComponent implements OnInit, AfterViewInit {


  mensagem: NotificacaoSistemaConsulta = new NotificacaoSistemaConsulta();
  @ViewChild("editor", { static: false }) editorRef: ElementRef | undefined;
  editorContent: SafeHtml = '';
  notificacaoService = inject(NotificacoesSistemaService);
  notificacaoColetivaCadastroDTO:NotificacaoColetivaCadastroDTO = new NotificacaoColetivaCadastroDTO();

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
   
  }

  public receberConteudo(event:any){
    this.editorContent = event.conteudo;
  }

  public enviarNotificacao(){
    this.preencherDadosNotificacao();
    this.notificacaoService.notificarTodosUsuarios(this.notificacaoColetivaCadastroDTO).subscribe({
      next:(res)=>{

      },error:(error)=>{

      },complete:()=>{

      }
    })
  }

  private preencherDadosNotificacao(){
    this.notificacaoColetivaCadastroDTO = new NotificacaoColetivaCadastroDTO();
    this.notificacaoColetivaCadastroDTO.assunto = this.mensagem.assunto;
    this.notificacaoColetivaCadastroDTO.mensagem =  this.editorContent.toString();
    this.notificacaoColetivaCadastroDTO.url = this.mensagem.url;
    return this.notificacaoColetivaCadastroDTO;
  }

}
