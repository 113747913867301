<app-container-component [tituloPage]="titulo" [header]="comTopo" [subtituloPage]="nomeFormulario">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 p-2">
      <label for="text" class="form-label">Selecione status:</label>
      <div class="input-group mb-3">
        <select class="form-select form-select-sm" [(ngModel)]="statusSelecionado">
          <option>Ativo</option>
          <option>Inativo</option>
        </select>
        <a class="btn btn-sm" (click)="pesquisarPorStatus()"><i class="fa-solid fa-magnifying-glass"></i></a>
      </div>
    </div>
  </div>
  <div class="row g-4">
    @for(satisfacao of listaDeSatisfacaoCliente;track $index){
    <div class="col-12 col-lg-6">
      <div class="card h-100  shadow">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h6 class="mb-0">{{ satisfacao.nomePesquisa }}</h6>
          <i class="fa-solid fa-chart-bar fa-lg"></i>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 d-flex justify-content-between">
              <p class="mb-2"><strong>Criada por:</strong></p>
              <span> {{ satisfacao.usuarioCadastro }}</span>
            </div>
            <div class="col-12 d-flex justify-content-between">
              <strong>Versão:</strong>
              <span> {{ satisfacao.versao }}</span>
            </div>
            <div class="col-12 d-flex justify-content-between">
              <p class="mb-2"><strong>Data:</strong></p>
              <span> {{ satisfacao.dataCadastro | date:'dd/MM/yyyy' }}</span>
            </div>

            <div class="col-12 d-flex justify-content-between">
              <p class="mb-2"><strong>Status:</strong></p>
              <p>
                {{satisfacao.status}}
                @if(temPermissao()){
                <mat-slide-toggle class="ms-2" [checked]="satisfacao.status === 'Ativo'"
                  (change)="confirmarTrocaDeStatus(satisfacao, $event.checked)">
                </mat-slide-toggle>
                }
              </p>
            </div>

            @if(temPermissao()){
            <div class="col-12 d-flex justify-content-between">
              <p class="mb-2"><strong>Enviar pesquisa:</strong></p>
              <a (click)="enviarPesquisa(satisfacao.id)" class="enviarBtn"><b><i class="fa-solid fa-envelope"></i></b>
                Enviar</a>
            </div>
            }
          </div>
        </div>
        <div class="card-footer bg-light d-flex justify-content-center align-items-center flex-wrap">
          <div class="btn-group w-100 d-flex gap-1 flex-wrap justify-content-evenly">
            @if(temPermissao()){
            <button (click)="editarSatisfacaoCliente(satisfacao.id)" class="btn btn-sm ">
              <i class="fa-solid fa-pencil"></i>
              <p>Editar</p>
            </button>
            <button (click)="criarPerguntaSatisfacaoCliente(satisfacao.id)" class="btn btn-sm ">
              <i class="fa-solid fa-circle-question"></i>
              <p>Perguntas</p>
            </button>
            <button (click)="criarAutoridade(satisfacao.id)" class="btn btn-sm ">
              <i class="fa-solid fa-user-lock"></i>
              <p>Autorizados</p>
            </button>
            }
            <button (click)="verResultados(satisfacao.id)" class="btn btn-sm ">
              <i class="fas fa-poll"></i>
              <p>Resultados</p>
            </button>
          </div>
        </div>
      </div>
    </div>
    }
  </div>
  @if(totalTamanho > this.quantidadesPorPagina[0]){
  <app-paginator (paginaMudou)="mudancaNaPagina($event)" [paginaAtual]="paginaAtual"
    [quantidadesPorPagina]="quantidadesPorPagina" [totalElementos]="totalTamanho" [totalPorPagina]="totalPorPagina">
  </app-paginator>
  }

</app-container-component>