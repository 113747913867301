<app-container-component [tituloPage]="titulo" [subtituloPage]="nomeFormulario">
    <table class="table table-sm table-striped">
        <thead>
            <tr>
                <th>Objetivo</th>
                <th>Cálculo</th>
                <th>Tipo</th>
                <th>Descrição</th>
                <th>Status</th>
                <th class="text-center" *ngIf="temPermissao('ROLE_IND_EDIT')">Editar</th>
                <th *ngIf="temPermissao('ROLE_IND_EDIT')" class="text-center">Gerenciar</th>
            </tr>
        </thead>
        <tbody>
            @for (indicador of listaDeIndicadores; track $index) {
            <tr >
                <td>{{indicador.objetivo}}</td>
                <td>{{indicador.calculo}}</td>
                <td>{{indicador.tipo}}</td>
                <td>{{indicador.desc_obj}}</td>
                <td>{{indicador.status}}</td>
                <td *ngIf="temPermissao('ROLE_IND_EDIT')" (click)="editarIndicador(indicador.id)" class="text-center"><i class="fa-solid fa-pencil"></i></td>
                <td *ngIf="temPermissao('ROLE_IND_EDIT')" (click)="gerenciarIndicador(indicador.id)" class="text-center"><i class="fa-solid fa-gear"></i></td>
            </tr>
            }
        </tbody>
    </table>
    <!-- <app-paginator 
    (paginaMudou)="mudancaNaPagina($event)"
    [paginaAtual]="paginaAtual"
    [quantidadesPorPagina]="quantidadesPorPagina"
    [totalElementos]="totalTamanho"
    [totalPorPagina]="totalPorPagina">

    </app-paginator> -->
</app-container-component>