<app-container-component [tituloPage]="titulo" [header]="comTopo" [subtituloPage]="nomeFormulario">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 p-2">
      <label for="text" class="form-label">Buscar:</label>
      <div class="input-group mb-3" (keyup)="enterPress($event)">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="valorPesquisado" placeholder="Identificação ou equipe">
        <a class="btn btn-sm" (click)="pesquisar()"><i class="fa-solid fa-magnifying-glass"></i></a>
      </div>
    </div>
  </div>
  <div class="row g-4">
      <div class="table">
        <table class="table table-sm  table-hover">
            <thead class="table-secondary">
              <th class="text-center">Plano</th>
              <th>Identificação</th>
              <th>Cadastro</th>
              <th>Alteração</th>
              <th>Responsável</th>
              <th class="text-center">Opções</th>
            </thead>
            <tbody>
             @for (plano of listaPlanoDeRisco; track $index) {
              <tr>
                <td class="text-center">{{plano.id}}</td>
                <td>{{plano.identificacao}}</td>
                <td>{{plano.dataCadastro | date : 'dd/MM/yyyy'}}</td>
                <td>{{plano.dataAlteracao | date : 'dd/MM/yyyy'}}</td>
                <td>{{plano.responsavel}}</td>
                <td class="text-center">
                  @if(temPermissao()){
                    <i class="fa-solid fa-pencil text-success me-4"  matTooltipPosition="above" matTooltipHideDelay="50" matTooltip="Editar plano" (click)="editarPlanoDeRisco(plano.id)"></i>
                  }
                  <i class="fa-solid cor fa-eye" matTooltipPosition="above" matTooltipHideDelay="50" matTooltip="Visualizar plano" (click)="visualizarAcoesPlano(plano.id)"></i>
                </td>
              </tr>
             }
            </tbody>
        </table>
      </div>
  </div>
  @if(listaPlanoDeRisco.length === 0){
    <div class="alert alert-danger mt-5" role="alert">
      Nenhum resultado encontrado para esta consulta!
    </div>
  }
  @if(this.totalElementos > this.totalPorPagina){
  <app-paginator (paginaMudou)="onPageChange($event)" [paginaAtual]="paginaAtual"
    [quantidadesPorPagina]="itensPorPagina" [totalElementos]="totalElementos" [totalPorPagina]="totalPorPagina">
  </app-paginator>
  }

</app-container-component>