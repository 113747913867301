import { Component, OnInit } from '@angular/core';
import { ContainerComponentComponent } from '../../componentes/container-component/container-component.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { SnackBarService } from '../../utils/snack-bar.service';

@Component({
  selector: 'app-recuperar-senha',
  standalone: true,
  imports: [
    ContainerComponentComponent,
    CommonModule,
    FormsModule
  ],
  templateUrl: './recuperar-senha.component.html',
  styleUrl: './recuperar-senha.component.scss'
})
export class RecuperarSenhaComponent implements OnInit{

  senha: string = '';
  confirmaSenha : string = '';
  erro: boolean = false;
  sucesso:boolean = false;
  token: string = '';
  email: string = '';
  mensagem: string = '';
  autorizacao:boolean = false;

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private snackbarService: SnackBarService,
  ){
    
  }

  ngOnInit(): void {
      this.activeRouter.params.subscribe((res: any)=>{
        this.email = res.email;
        this.token = res.token;
        this.verificaPermissaoDeRecuperacao();
      });
  }

  voltar(){
    this.router.navigate(['/']);
  }

  verificaPermissaoDeRecuperacao(){
    this.loginService.verificaPermissaoDeTrocaDeSenha(this.email, this.token).subscribe({
        next:(res)=>{
          this.autorizacao = res.autorizacao;
        },
        error:(e)=>{

        }
    });
  }

  redefinirSenha(){
    if(this.senha.trim() === '' || this.confirmaSenha.trim() === ''){
      this.mensagem = 'As senhas não podem ser vazias!'
      this.sucesso = false;
      this.erro = true;
    }else if(this.senha.length < 6){
      this.mensagem = 'A senha precisa ter 6 ou mais caracteres!'
      this.sucesso = false;
      this.erro = true;
    }else if(this.senha !== this.confirmaSenha){
      this.mensagem = 'As senhas não são iguais!'
      this.sucesso = false;
      this.erro = true;
    }else if(this.senha === this.confirmaSenha){
      this.alterarSenha();
      this.erro = false;
      this.sucesso = true;
    }
  }


  private redirecionarUsuario(){
    setTimeout(()=>{
      this.router.navigate([`/login/${this.email}`]);
    }, 3000);
  }

  private alterarSenha(){
      this.loginService.cadastrarNovaSenha(this.email, this.token, this.senha).subscribe({
        next:(res)=>{
          this.redirecionarUsuario();
        },
        error:(e)=>{
          this.snackbarService.tratarErro(e, "Falha ao tentar alterar senha!");
        }
      });
  }


}
