<div class="separador mt-2 mb-2" #determinacaoDeProcesso>
    <strong>DETERMINAÇÃO DE PROCESSO</strong>
</div>

@defer (on viewport(determinacaoDeProcesso)) {
@if(tabAtiva != ''){
<!-- Nav Tabs -->
<ul class="nav nav-tabs" id="tabDeterminacao" role="tablist">
    @if(dados.processo1 != ''){
    <li class="nav-item" role="presentation">
        <a class="nav-link active" id="tabDeterminacao1-tab" data-bs-toggle="tab" href="#tabDeterminacao1"
            (click)="realizarNovaConsulta(dados.numeroProcesso1)" role="tab" aria-controls="processo1"
            aria-selected="true">{{dados.processo1}}</a>
    </li>
    }
    @if(dados.processo2 != ''){
    <li class="nav-item" role="presentation">
        <a class="nav-link" id="tabDeterminacao2-tab" data-bs-toggle="tab" href="#tabDeterminacao2" role="tab"
            (click)="realizarNovaConsulta(dados.numeroProcesso2)" aria-controls="processo2"
            aria-selected="false">{{dados.processo2}}</a>
    </li>
    }
</ul>



<!-- Tab Content -->
<div class="tab-content mt-3" id="myTabContent">
    <!-- TAB 1 PARA O PROCESSO DE FOSFATO -->
    @if(dados.processo1 === 'FOSFATO'){
    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo1'}" id="tabDeterminacao1"
        role="tabpanel" aria-labelledby="embalagem1-tab">
        <div class="position-img">
            <div class="col-12 limite">
                @if (imagemNaoCadastrada) {
                <div class="col-12 text-center text-danger">
                    <h3>Infomações não encontradas!</h3>
                </div>
                }@else {
                <table class=" table table-sm table-striped">
                    <tbody>
                        <tr>
                            <td><strong>PEÇAS POR GANCHEIRA:</strong></td>
                            <td>{{determinacaoProcesso1?.qtdePecasGancheira}}</td>
                        </tr>
                        <tr>
                            <td><strong>GANCHEIRAS POR RACK:</strong></td>
                            <td>{{determinacaoProcesso1?.qtdeGancheirasRack}}</td>
                        </tr>
                        <tr>
                            <td><strong>PEÇAS POR RACK:</strong></td>
                            <td>{{determinacaoProcesso1?.qtdePecasRack}}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE MÁQUINA:</strong></td>
                            <td>{{determinacaoProcesso1?.tempoDeMaquina}}</td>
                        </tr>
                        <tr>
                            <td><strong>LEADTIME:</strong></td>
                            <td>{{determinacaoProcesso1?.leadTime}}</td>
                        </tr>
                        <tr>
                            <td><strong>PEÇAS POR HORA:</strong></td>
                            <td>{{determinacaoProcesso1?.qtdePecasHora}}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE MONTAGEM:</strong></td>
                            <td>{{determinacaoProcesso1?.tempoMontagem}}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE EMBALAGEM:</strong></td>
                            <td>{{determinacaoProcesso1?.tempoEmbalagem}}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE RESFRIAMENTO:</strong></td>
                            <td>{{determinacaoProcesso1?.tempoResfriamento}}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <strong> <label for="" class="form-label">PARÂMETROS ESTUFA:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso1?.parametrosEstufa}}</textarea>
                </div>
                <div>
                    <strong> <label for="" class="form-label">OBSERVAÇÕES:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso1?.observacoes}}</textarea>
                </div>
                <div>
                    <strong> <label for="" class="form-label">EPI:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso1?.equipamentoDeProtecao}}</textarea>
                </div>
                }
            </div>
        </div>
    </div>
    }

    <!-- TAB 1 PARA O PROCESSO DE PINTURA KTL -->
    @if(dados.processo1 === 'KTL'){
    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo1'}" id="tabDeterminacao1"
        role="tabpanel" aria-labelledby="embalagem1-tab">
        <div class="position-img">
            <div class="col-12 limite">
                @if (imagemNaoCadastrada) {
                <div class="col-12 text-center text-danger">
                    <h3>Infomações não encontradas!</h3>
                </div>
                }@else {
                <table class=" table table-sm table-striped">
                    <tbody>
                        <tr>
                            <td><strong>PEÇAS POR GANCHEIRA:</strong></td>
                            <td>{{determinacaoProcesso1?.qtdePecasGancheira}}</td>
                        </tr>
                        <tr>
                            <td><strong>GANCHEIRAS POR RACK:</strong></td>
                            <td>{{determinacaoProcesso1?.qtdeGancheirasRack }}</td>
                        </tr>
                        <tr>
                            <td><strong>PEÇAS POR RACK:</strong></td>
                            <td>{{determinacaoProcesso1?.qtdePecasRack }}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE MÁQUINA:</strong></td>
                            <td>{{determinacaoProcesso1?.tempoMaquina }}</td>
                        </tr>
                        <tr>
                            <td><strong>LEADTIME:</strong></td>
                            <td>{{determinacaoProcesso1?.leadTime }}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE MONTAGEM:</strong></td>
                            <td>{{determinacaoProcesso1?.tempoMontagem }}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE EMBALAGEM:</strong></td>
                            <td>{{determinacaoProcesso1?.tempoEmbalagem }}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE RESFRIAMENTO:</strong></td>
                            <td>{{determinacaoProcesso1?.tempoResfriamento }}</td>
                        </tr>
                        <tr>
                            <td><strong>CAMADA:</strong></td>
                            <td>MIN {{determinacaoProcesso1?.camadaMinima }} µm - MÁX
                                {{determinacaoProcesso1?.camadaMaxima }} µm</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <strong> <label for="" class="form-label">PARÂMETROS ESTUFA:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso1?.parametroEstufa }}</textarea>
                </div>
                <div>
                    <strong> <label for="" class="form-label">OBSERVAÇÕES:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso1?.observacao }}</textarea>
                </div>
                <div>
                    <strong> <label for="" class="form-label">EPI:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso1?.epi }}</textarea>
                </div>
                }
            </div>
        </div>
    </div>
    }

    <!-- TAB 2 PARA O PROCESSO DE PINTURA PÓ -->
    @if(dados.processo2 === 'PÓ' || dados.processo2 == 'PO'){
    <div class="tab-pane fade" id="tabDeterminacao2" role="tabpanel" aria-labelledby="embalagem2-tab">
        <div class="position-img">
            <div class="col-12 limite">
                @if (imagemNaoCadastrada) {
                <div class="col-12 text-center text-danger">
                    <h3>Infomações não encontradas!</h3>
                </div>
                }@else {
                <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> @switch (determinacaoProcesso2.equipament1) {
                                @case ('MÁQUINA 1') {
                                <strong>MÁQUINA 1</strong>
                                }
                                @case ('MÁQUINA 2') {
                                <strong>MÁQUINA 2</strong>
                                }
                                @case ('ESTACIONÁRIA') {
                                <strong>ESTACIONÁRIA</strong>
                                }
                                @default {
                                <strong> Não há maquiná cadastrada</strong>
                                }
                                } </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        @switch (determinacaoProcesso2.equipament1) {
                        @case ('MÁQUINA 1') {
                        <app-form-maquina-1 [processoDoPo]="determinacaoProcesso2"></app-form-maquina-1>
                        }
                        @case ('MÁQUINA 2') {
                        <app-form-maquina-2 [processoDoPo]="determinacaoProcesso2"></app-form-maquina-2>
                        }
                        @case ('ESTACIONÁRIA') {
                        <app-form-estacionaria [processoDoPo]="determinacaoProcesso2"></app-form-estacionaria>
                        }

                        }
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                @switch (determinacaoProcesso2.equipament2) {
                                @case ('MÁQUINA 1') {
                                <strong> MÁQUINA 1</strong>
                                }
                                @case ('MÁQUINA 2') {
                                <strong>MÁQUINA 2</strong>
                                }
                                @case ('ESTACIONÁRIA') {
                                <strong>ESTACIONÁRIA</strong>
                                }
                                @default {
                                <strong> Não há maquiná cadastrada</strong>
                                }
                                }
                            </mat-panel-title>
                            <mat-panel-description>
                                <!-- <p>ICON</p> -->
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        @switch (determinacaoProcesso2.equipament2) {
                        @case ('MÁQUINA 1') {
                        <app-form-maquina-1 [processoDoPo]="determinacaoProcesso2"></app-form-maquina-1>
                        }
                        @case ('MÁQUINA 2') {
                        <app-form-maquina-2 [processoDoPo]="determinacaoProcesso2"></app-form-maquina-2>
                        }
                        @case ('ESTACIONÁRIA') {
                        <app-form-estacionaria [processoDoPo]="determinacaoProcesso2"></app-form-estacionaria>
                        }

                        }
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                @switch (determinacaoProcesso2.equipament3) {
                                @case ('MÁQUINA 1') {
                                <strong>MÁQUINA 1</strong>
                                }
                                @case ('MÁQUINA 2') {
                                <strong>MÁQUINA 2</strong>
                                }
                                @case ('ESTACIONÁRIA') {
                                <strong>ESTACIONÁRIA</strong>
                                }
                                @default {
                                <strong> Não há maquiná cadastrada</strong>
                                }
                                }
                            </mat-panel-title>
                            <mat-panel-description>
                                <!-- <p>ICON</p> -->
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        @switch (determinacaoProcesso2.equipament3) {
                        @case ('MÁQUINA 1') {
                        <app-form-maquina-1 [processoDoPo]="determinacaoProcesso2"></app-form-maquina-1>
                        }
                        @case ('MÁQUINA 2') {
                        <app-form-maquina-2 [processoDoPo]="determinacaoProcesso2"></app-form-maquina-2>
                        }
                        @case ('ESTACIONÁRIA') {
                        <app-form-estacionaria [processoDoPo]="determinacaoProcesso2"></app-form-estacionaria>

                        }
                        }
                    </mat-expansion-panel>
                </mat-accordion>
                <table class=" table table-sm table-striped">
                    <tbody>
                        <tr>
                            <td><strong>Tempo de setup do equipamento:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoSetupEquipamento }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de setup do dispositivo:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoSetupDispositivo }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de montagem:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoMontagem }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de isenção (anterior):</strong></td>
                            <td>{{determinacaoProcesso2?.tempoIsencaoAnterior }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de máquina:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoMaquina }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de resfriamento:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoResfriamento }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de desmontagem:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoDesmontagem }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de inspeção:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoInspecao }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de isenção (posterior):</strong></td>
                            <td>{{determinacaoProcesso2?.tempoIsencaoPosterior }}</td>
                        </tr>
                        <tr>
                            <td><strong>Tempo de embalagem:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoEmbalagem }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="mt-2">
                    <strong> <label for="" class="form-label">OBSERVAÇÕES:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso2?.observacao }}</textarea>
                </div>
                <div>
                    <strong> <label for="" class="form-label">EPI:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso2?.epi }}</textarea>
                </div>
                }
            </div>
        </div>
    </div>
    }

    <!-- TAB 2 PARA O PROCESSO DE PINTURA LÍQUIDA -->
    @if(dados.processo2 == 'LÍQUIDA' || dados.processo2 == 'LIQUIDA'){
    <div class="tab-pane fade" id="tabDeterminacao2" role="tabpanel" aria-labelledby="embalagem2-tab">
        <div class="position-img">
            <div class="col-12 limite">
                @if (imagemNaoCadastrada) {
                <div class="col-12 text-center text-danger">
                    <h3>Infomações não encontradas!</h3>
                </div>
                }@else {
                <table class=" table table-sm table-striped">
                    <tbody>
                        <tr>
                            <td><strong>PEÇAS POR QUADRO:</strong></td>
                            <td>{{determinacaoProcesso2?.qtdePecasQuadro }}</td>
                        </tr>
                        <tr>
                            <td><strong>PEÇAS POR TRAVESSA:</strong></td>
                            <td>{{determinacaoProcesso2?.qtdePecasTravessa}}</td>
                        </tr>
                        <tr>
                            <td><strong>TRAVESSAS POR QUADRO:</strong></td>
                            <td>{{determinacaoProcesso2?.qtdeTravessaQuadro }}</td>
                        </tr>
                        <tr>
                            <td><strong>CAMADA:</strong></td>
                            <td>MIN {{determinacaoProcesso2?.camadaMinima }} µm - MÁX
                                {{determinacaoProcesso2?.camadaMaxima }} µm</td>
                        </tr>
                        <tr>
                            <td><strong>PRESSÃO DO AR GERAL</strong></td>
                            <td>{{determinacaoProcesso2?.pressaoArGeral }}</td>
                        </tr>
                        <tr>
                            <td><strong>PRESSÃO DO AR TAMBOR:</strong></td>
                            <td>{{determinacaoProcesso2?.pressaoArTambor }}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPERATURA CURA:</strong></td>
                            <td>{{determinacaoProcesso2?.temperatuaCura }}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO DE CURA:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoCura }}</td>
                        </tr>
                        <tr>
                            <td><strong>TEMPO FLASHOFF:</strong></td>
                            <td>{{determinacaoProcesso2?.tempoFlashOff }}</td>
                        </tr>
                        <tr>
                            <td><strong>VISCOSIDADE MISTURA:</strong></td>
                            <td>{{determinacaoProcesso2?.viscosidadeMistura }}</td>
                        </tr>
                        <tr>
                            <td><strong>VOLUME CATALISADOR:</strong></td>
                            <td>{{determinacaoProcesso2?.volumeCatalisador }}</td>
                        </tr>
                        <tr>
                            <td><strong>VOLUME SOLVENTE:</strong></td>
                            <td>{{determinacaoProcesso2?.volumeSolvente }}</td>
                        </tr>
                        <tr>
                            <td><strong>VOLUME DE TINTA:</strong></td>
                            <td>{{determinacaoProcesso2?.volumeSolvente }}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <strong> <label for="" class="form-label">PADRÃO DE PINTURA:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso2?.padraoPintura }}</textarea>
                </div>
                <div>
                    <strong> <label for="" class="form-label">OBSERVAÇÕES:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso2?.observacao }}</textarea>
                </div>
                <div>
                    <strong> <label for="" class="form-label">EPI:</label></strong>
                    <textarea class="form-control">{{determinacaoProcesso2?.epi }}</textarea>
                </div>
                }
            </div>
        </div>
    </div>
    }
</div>
}@else {
<div class="text-center text-danger mt-3">
    <p class="alert alert-danger">Determinação de processo não cadastrada!</p>
</div>
}
}@loading {
<div class="card-load-box">
    <div class="card_load_extreme_descripion"></div>
</div>
}