import { Msa } from "../model/engenharia/msa";

export class MsaContinuoAlteracaoDTO {

  id: number;
  identificacao: string;
  analista: string;
  instrumento: string;
  procOperacional: string;
  imgGrafGageRr: string;
  imgTwayAnova: string;
  anTolerancia: string;
  imgEstTolerancia: string;
  ndc: string;
  imgGageLb: string;
  anLinearidade: string;
  anSistema: string;
  obs: string;
  arquivoMpj: string;

  constructor(init?: Partial<MsaContinuoAlteracaoDTO>) {
    this.id = 0;
    this.identificacao = '';
    this.analista = '';
    this.instrumento = '';
    this.procOperacional = '';
    this.imgGrafGageRr = '';
    this.imgTwayAnova = '';
    this.anTolerancia = '';
    this.imgEstTolerancia = '';
    this.ndc = '';
    this.imgGageLb = '';
    this.anLinearidade = '';
    this.anSistema = '';
    this.obs = '';
    this.arquivoMpj = '';

    Object.assign(this, init);
  }
}


