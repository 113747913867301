<div class="col-12 col-md-12 col-lg-12 col-xl-12">
    <div class="row justify-content-start   align-items-center p-3  gap-2">
        <div class="col-12 col-md-12  col-lg-4 col-xl-3 card-contador ">
            <label class="text-center"> <b>Cadastradas</b></label>
            <p class="text-center">{{this.quantidadeInformacaoDocumentadaCriada}}</p>
        </div>
        <div class="col-12 col-md-12  col-lg-4 col-xl-3 card-contador ">
            <label class="text-center"> <b>Pendentes</b></label>
            <p class="text-center">{{this.listaInformacaoDocumentadaPendentes.length}}</p>
        </div>
        <div class="col-12 col-md-12  col-lg-4 col-xl-3 card-contador ">
            <label class="text-center"> <b>Aprovadas</b></label>
            <p class="text-center">{{this.listaInformacaoDocumentadaAprovadas.length}}</p>
        </div>
        <div class="col-12 col-md-12  col-lg-4 col-xl-3 card-contador ">
            <label class="text-center"> <b>Reprovadas</b></label>
            <p class="text-center">{{this.listaInformacaoDocumentadaReprovadas.length}}</p>
        </div>
</div>