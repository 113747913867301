import { Routes } from "@angular/router";
import { authGuard } from "../auth/auth-guard.guard";
import { verificarPermissoesGuard } from "../auth/verificar-permissoes.guard";
import { BookProducaoVisualizarComponent } from "../paginas/producao/book-producao-visualizar/book-producao-visualizar.component";

export const routesProducao: Routes = [
    {
        path: "producao/book/:id", component: BookProducaoVisualizarComponent,
        canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
]
