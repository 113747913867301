import { Component } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { IndicadoresMonitoramentoService } from '../../../../services/indicadores/indicadores-monitoramento.service';
import moment from 'moment';
import { IndicadorMonitoramento } from '../../../../model/indicadores/indicadoresDeMonitoramento/indicadorMonitoramento';
import { Router } from '@angular/router';
import { LoginService } from '../../../../services/login/login.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';

@Component({
  selector: 'app-indicadores-monitoramento-lista',
  standalone: true,
  imports: [ContainerComponentComponent, PaginatorComponent],
  templateUrl: './indicadores-monitoramento-lista.component.html',
  styleUrl: './indicadores-monitoramento-lista.component.scss'
})
export class IndicadoresMonitoramentoListaComponent {

  titulo = "LISTA INDICADORES MONITORAMENTO";
  nomeFormulario = "INDICADORES";
  listaDeIndicadores:IndicadorMonitoramento[]=[];
  token: any;

  paginaAtual: number = 1;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  anoAtual = moment().format("yyyy");
  constructor(
    private indicadoresGerenciaisService:IndicadoresMonitoramentoService,
    private snackbarService: SnackBarService,
    private router:Router,
    private loginService: LoginService
  ){

  }

  ngOnInit(): void {
    this.decodificarToken();
  }

  private decodificarToken(){
    this.token = this.loginService.decodificarToken();
    this.consultaIndicadores();
  }

  public consultaIndicadores(){
    this.indicadoresGerenciaisService.consultaIndicadorMonitoramentoPorAno(this.paginaAtual,this.totalPorPagina,this.anoAtual).subscribe({
      next:(res:any)=>{
        this.listaDeIndicadores = res.content;
        this.totalTamanho = res.page.totalElements;
      },error:(error)=>{
        
      },complete:()=>{

      }
    });
  }

  public gerenciarIndicador(idIndicador:number, status: string){
    if(status.toUpperCase() == 'ATIVO'){
      this.router.navigate([`indicadores/indicador-monitoramento-resultado/${idIndicador}/${this.anoAtual}`]);
    }else{
      this.snackbarService.openSnackBar('Ative o indicador para poder gerencia-lo!', 'my-snack-bar-erro');
    }
  }

  public editarIndicador(idIndicador:number){
    this.router.navigate([`indicadores/monitoramento-cadastro/${idIndicador}`]);
  }

  public mudancaNaPagina(evento:any){
    this.paginaAtual = evento.pageIndex;
    this.totalPorPagina = evento.pageSize;
    this.consultaIndicadores();
  }
}
