import { FormsDataService } from './../../../../utils/forms-data.service';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { InputImagensComponent } from "../../../../componentes/input-imagens/input-imagens.component";
import { BotaoComponent } from "../../../../componentes/botao/botao.component";
import { MsaService } from '../../../../services/engenharia/msa/msa.service';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { ActivatedRoute } from '@angular/router';
import { Msa } from '../../../../model/engenharia/msa';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { MsaContinuoAlteracaoDTO } from '../../../../dto/MsaContinuoAlteracaoDTO';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { LoadingComponent } from "../../../../componentes/loading/loading.component";

@Component({
  selector: 'app-msa-registrar',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, ReactiveFormsModule, MensagemErrorsComponent, InputImagensComponent, BotaoComponent],
  templateUrl: './msa-registrar.component.html',
  styleUrl: './msa-registrar.component.scss'
})
export class MsaRegistrarComponent implements OnInit {

  idMsa: number = 0;
  arquivosSelecionados: Array<any> = [];
  listaDeImagensGageStudy: File | undefined;
  listaDeImagensTwoWay: File | undefined;
  listaDeImagensTolerancia: File | undefined;
  listaDeImagenslinearidade: File | undefined;
  listaDeImagensMpj: File | undefined;
  formularioMsa!: FormGroup;
  formBuilder = inject(FormBuilder);
  formValidacaoService = inject(FormValidacaoService)
  formControler = inject(FormsDataService);
  msaService = inject(MsaService);
  emisorEventoGeralService = inject(EmisorEventoGeralService);
  activatedRoute = inject(ActivatedRoute);
  snackbarService = inject(SnackBarService);
  dialog = inject(MatDialog);
  msaModel: Msa = new Msa();
  msaDto: MsaContinuoAlteracaoDTO = new MsaContinuoAlteracaoDTO();

  ngOnInit(): void {
    this.iniciarFormulario();
    this.verificarRotas()
  }

  public iniciarFormulario() {
    this.formularioMsa = this.formBuilder.group({
      identificacao: [],
      analista: [],
      instrumento: [],
      procOperacional: [],
      anTolerancia: [],
      imgTwayAnova: [],
      imgGrafGageRr: [],
      anInteracao: [],
      anSemInteracao: [],
      percTolerancia: [],
      imgEstTolerancia: [],
      imgGageLb: [],
      ndc: [],
      anVies: [],
      anLinearidade: [],
      anSistema: [],
      obs: [],
      arquivoMpj: []
    });
  }

  public verificarRotas() {
    this.activatedRoute.params.subscribe((res: any) => {
      if (res) {
        this.idMsa = res.id;
        this.consultaPorId();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public consultaPorId() {
    this.msaService.consultaPorId(this.idMsa).subscribe({
      next: (res) => {
        this.formularioMsa.patchValue(res);
        this.msaModel = res;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public salvar() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.prepararImagens();

    this.msaService.salvarMsa(this.criarFormDataMsa()).subscribe({
      next: (res) => {
        this.snackbarService.openSnackBar('MSA cadastrado com sucesso!', 'my-snack-bar-sucess');
      }, error: (error) => {
        this.snackbarService.tratarErro(error, "Falha ao tentar salvar MSA!");
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.emisorEventoGeralService.emitirEventoDeLimpezaDoInputDeArquivos();
        this.limparVariaveisDeImagens();
        this.iniciarFormulario();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public editar() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.prepararImagens();
    this.msaService.alterarMsa(this.criarFormDataMsaEditar()).subscribe({
      next: (res) => {
        this.snackbarService.openSnackBar('MSA alterado com sucesso!', 'my-snack-bar-sucess');
      }, error: (error) => {
        this.snackbarService.tratarErro(error, "Falha ao tentar salvar MSA!");
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.snackbarService.openSnackBar("MSA atualizado com sucesso", "my-snack-bar-sucess");
        this.limparVariaveisDeImagens();
        this.emisorEventoGeralService.emitirEventoDeLimpezaDoInputDeArquivos();
        this.consultaPorId();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public prepararImagens() {
    this.arquivosSelecionados = [
      { nome: "imgGrafGageRr", arquivo: this.listaDeImagensGageStudy },
      { nome: "imgTwayAnova", arquivo: this.listaDeImagensTwoWay },
      { nome: "imgEstTolerancia", arquivo: this.listaDeImagensTolerancia },
      { nome: "imgGageLb", arquivo: this.listaDeImagenslinearidade },
      { nome: "arquivoMpj", arquivo: this.listaDeImagensMpj },
    ];
  }

  public deletarArquivo(nomeArquivo: string, arquivo: string) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: nomeArquivo, texto: "Tem certeza de que deseja excluir este arquivo ?" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.validarQualExcluir(arquivo);
        }
      }
    });
  }


  public validarQualExcluir(arquivo: string) {
    switch (arquivo) {
      case 'GRAFICOGAGERR':
        this.deletarImagemGrafGageRR();
        break
      case 'TWOWAYANOVA':
        this.deletarImagemWayAnova();
        break
      case 'TOLERANCIA':
        this.deletarImagemEstudoTolerancia();
        break
      case 'LINEARIDADE':
        this.deletarImagemGraficoGageLb();
        break
      case 'MJP':
        this.deletarImagemMpj();
        break
    }
  }

  public deletarImagemGraficoGageLb() {
    this.msaService.deletarImagemGageLb(this.idMsa).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackbarService.tratarErro(error, "Erro ao deletar gráfico do gage study rr");
      }, complete: () => {
        this.snackbarService.openSnackBar("Arquivo gráfico do gage study rr deletado com sucesso !", "my-snack-bar-sucess");
        this.consultaPorId();
      }
    });
  }

  public deletarImagemWayAnova() {
    this.msaService.deletarImagemTwayAnova(this.idMsa).subscribe({
      next: (res) => {


      }, error: (error) => {
        this.snackbarService.tratarErro(error, "Erro ao deletar Two Way Anova");
      }, complete: () => {
        this.snackbarService.openSnackBar("Arquivo Two Way Anova deletado com sucesso !", "my-snack-bar-sucess");
        this.consultaPorId();
      }
    });
  }

  public deletarImagemEstudoTolerancia() {
    this.msaService.deletarImagemEsTolerancia(this.idMsa).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackbarService.tratarErro(error, "Erro ao deletar o estudo de tolerância");
      }, complete: () => {
        this.snackbarService.openSnackBar("Arquivo estudo de tolerância deletado com sucesso !", "my-snack-bar-sucess");
        this.consultaPorId();
      }
    });
  }

  public deletarImagemGrafGageRR() {
    this.msaService.deletarImagemGrafGageRR(this.idMsa).subscribe({
      next: (res) => {
      }, error: (error) => {
        this.snackbarService.tratarErro(error, "Erro ao deletar o arquivo MPJ");
      }, complete: () => {
        this.snackbarService.openSnackBar("Arquivo MPJ deletado com sucesso !", "my-snack-bar-sucess");
        this.consultaPorId();
      }
    });
  }

  public deletarImagemMpj() {
    this.msaService.deletarImagemMpj(this.idMsa).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackbarService.tratarErro(error, "Erro ao deletar o arquivo MPJ");
      }, complete: () => {
        this.snackbarService.openSnackBar("Arquivo MPJ deletado com sucesso !", "my-snack-bar-sucess");
        this.consultaPorId();
      }
    });
  }


  public receberImagemGageStudy(event: any) {
    this.listaDeImagensGageStudy = event[0];
  }
  public receberImagemWayAnova(event: any) {
    this.listaDeImagensTwoWay = event[0];
  }
  public receberImagemEstudoTolerancia(event: any) {
    this.listaDeImagensTolerancia = event[0];
  }
  public receberImagemLinearidadeVies(event: any) {
    this.listaDeImagenslinearidade = event[0];
  }
  public receberImagemMpj(event: any) {
    this.listaDeImagensMpj = event[0];
  }

  public limparVariaveisDeImagens() {
    this.arquivosSelecionados = [];
    this.listaDeImagensGageStudy = undefined;
    this.listaDeImagensTwoWay = undefined;
    this.listaDeImagensTolerancia = undefined;
    this.listaDeImagenslinearidade = undefined;
    this.listaDeImagensMpj = undefined;
  }

  public criarFormDataMsa() {
    this.msaModel = this.formularioMsa.value;
    return this.formControler.criarFormDataMsa("msa", this.arquivosSelecionados, this.msaModel);
  }
  public criarFormDataMsaEditar() {
    this.msaDto = new MsaContinuoAlteracaoDTO(this.formularioMsa.value);
    this.msaDto.id = this.idMsa;
    return this.formControler.criarFormDataMsa("msa", this.arquivosSelecionados, this.msaDto);
  }

  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formularioMsa, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formularioMsa, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formularioMsa, nome);
  }

  public sujoInvalido(nome: string): boolean {
    return this.formValidacaoService.sujoInvalido(this.formularioMsa, nome);
  }
  public autoAjuste(event: any) {
    return this.formValidacaoService.autoAjuste(event);
  }

}
