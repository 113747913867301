export class EngProcessoUpdateDto {
    id!: number;
    nomeArquivo!: string;
    cdProcesso!:number;
    cdProduto!: number;
    descricao!: string;
    path!: string;
    dataCadastro!:string;
    sequencia!: number;

}