import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { Usuario } from '../../../model/usuario/usuario';
import { PaginatorComponent } from '../../../componentes/paginator/paginator.component';
import { ContainerComponentComponent } from '../../../componentes/container-component/container-component.component';
import { NotificacaoComponent } from '../../../componentes/notificacao/notificacao.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../../../componentes/modal/modal.component';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CertificadoComponent } from '../certificado/certificado.component';
import { PermissoesComponent } from "../permissoes/permissoes.component";

@Component({
  selector: 'app-usuario',
  standalone: true,
  imports: [
    CommonModule,
    PaginatorComponent,
    ContainerComponentComponent,
    FormsModule,
    CertificadoComponent,
    PermissoesComponent
],
  templateUrl: './usuario.component.html',
  styleUrl: './usuario.component.scss'
})
export class UsuarioComponent implements OnInit {

  pagina: number = 0;
  totalElementos = 0;
  totalPorPagina = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100];
  size: number = this.quantidadesPorPagina[0];
  tituloPagina = "CONSULTA DE USUÁRIOS";
  subTitulo = 'USUÁRIOS CADASTRADOS';
  email: string = '';
  usuarios: Usuario[] = [];

  constructor(
    private usuarioService: UsuarioService,
    public dialog: MatDialog,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.consultarUsuariosCadastrados();
    console.log(this.email);
  }

  public consultarUsuariosCadastrados() {
    this.usuarioService.consultarPaginada(this.pagina, this.size).subscribe({
      next: (res) => {
        this.usuarios = [];
        this.totalElementos = res.page.totalElements
        this.usuarios = res.content;
      }
    });
  }

  public cadastrarNovoUsuario(){
    this.router.navigate([`usuario-cadastro/${0}`]);
  }

  public editarrUsuario(id: number){
    this.router.navigate([`usuario-cadastro/${id}`]);
  }

  public bloquearUsuario(email: string) {
    this.usuarioService.bloquearUsuarioEmail(email).subscribe({
      next: (res) => {
        console.log(res);
      },
      complete: () => {
        this.consultarUsuariosCadastrados();
      }
    });
  }

  public consultaPorNomeEmail() {
    if (this.email !== '') {
      this.usuarioService.consultaUsuarioPorEmailOuNome(this.pagina, this.size, this.email).subscribe({
        next: (res) => {
          this.usuarios = [];
          this.usuarios = res.content;
        }
      });
    }else{
      this.consultarUsuariosCadastrados();
    }
  }

  public desbloquearUsuario(email: string) {
    this.usuarioService.desbloquearUsuarioEmail(email).subscribe({
      next: (res) => {
        console.log(res);
      },
      complete: () => {
        this.consultarUsuariosCadastrados();
      }
    });
  }

  public mudancaNaPagina(evento: any) {
    this.pagina = evento.pageIndex; // Incrementado por 1 para alinhar com a paginação de backend
    this.size = evento.pageSize;
    this.consultarUsuariosCadastrados();
  }

  public confirmacaoBloqueio(email: string) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: email, textoConteudo: "Bloquear usuário", texto: 'Deseja realmente bloquear este usuário?' },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.bloquearUsuario(email);
        };
      }
    });
  }

  public confirmacaoDesbloqueio(email: string) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: email, textoConteudo: "Bloquear usuário", texto: 'Deseja realmente desbloquear este usuário?' },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.desbloquearUsuario(email);
        };
      }
    });
  }


}
