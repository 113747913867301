import { SnackBarService } from './../../../../utils/snack-bar.service';
import { Component } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import moment from 'moment';
import { CommonModule } from '@angular/common';
import { StatusBookService } from '../../../../services/engenharia/produto/status-book.service';
import { StatusBook } from '../../../../model/statusBook';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { MensagemErrorsComponent } from '../../../../componentes/mensagem-errors/mensagem-errors.component';
import { ValidatorsService } from '../../../../utils/validators.service';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-status-book',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BotaoComponent, MensagemErrorsComponent],
  templateUrl: './status-book.component.html',
  styleUrl: './status-book.component.scss'
})
export class StatusBookComponent {

  statusBook: StatusBook[] = [];
  atualizacao: boolean = false;
  formularioStatusBook!: FormGroup

  constructor(
    private statusBookService: StatusBookService,
    private formBuild: FormBuilder,
    private snackBarService: SnackBarService,
    private validatorsService:ValidatorsService,
    private dialog:MatDialog,
  ) {

  }


  ngOnInit(): void {
    this.listarTipos();
    this.formularioStatusBook = this.formBuild.group({
      id: [],
      descricao: ['', [Validators.required,this.validatorsService.semEspaçoEmBranco()]],
      data: [moment().format("DD-MM-yyyy")]
    });
  }

  public listarTipos() {
    this.statusBookService.consultarStatusBook().subscribe({
      next: (tipos) => {
        this.statusBook = tipos;
      },
    });
  }

  public criarStatusBook() {
    if (this.formularioStatusBook.valid) {
      this.statusBookService.criarStatusBook(this.formularioStatusBook.value).subscribe({
        next: (res) => {

        }, error: (error) => {

        }, complete: () => {
          this.listarTipos();
          this.limparFormulario();
        }
      });
    }
  }

  buscarPorId(id: number) {
    this.statusBookService.buscarPorId(id).subscribe({
      next: (res) => {
        this.formularioStatusBook.patchValue(res);
        console.log(this.formularioStatusBook.value)
      }, error: (erro) => {

      }, complete: () => {
        this.listarTipos();
      }
    })
  }



  public voltar() {
    this.formularioStatusBook.reset();
  }

  public editarStatusBook() {
    this.criarStatusBook()
  }

  public deletarItem(nomeArquivo:string,id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: {nomeArquivo:nomeArquivo,textoConteudo:"status book"},
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next:(res)=>{
        if (res) {
        this.deletar(id);
        };
      }
    });
  }

  private deletar(id:number){
    this.statusBookService.deletarStatusBook(id).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Item deletado com sucesso", "my-snack-bar-sucess")
      }, complete: () => {
        this.listarTipos();
      }
    });
  }

  public limparFormulario(){
    this.formularioStatusBook.reset();
  }
  


  public obterValidacao(nome: string): FormControl {
    const control = this.formularioStatusBook.get(nome);
    if (!control) {
      throw new Error('Controle de formulario não encontrado' + nome);
    }
    return control as FormControl;
  }

  public tocado(nome: string): boolean {
    const control = this.formularioStatusBook.get(nome);
    if (!control?.value) {
      return control!.touched;
    }
    return false;
  }

  public validar(nome: string): boolean {
    const control = this.formularioStatusBook.get(nome);
    return control!.touched && control!.valid;
  }
  public closeModal(){
    this.dialog.closeAll()
  }

}
