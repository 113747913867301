import { EmisorEventoGeralService } from './../../utils/emisorEventoGeral.service';
import { InformacaoDocumentadaService } from './../../services/acoes/informacao-documentada/informacao-documentada.service';
import { Component, inject, OnInit } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import {
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { CommonModule } from '@angular/common';
import { InformacaoDocumentadaCadastro } from '../../model/informacao-documentada/informacao-documentada-cadastro';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notificacoes-component',
  standalone: true,
  imports: [MatButtonModule, MatBottomSheetModule, CommonModule],
  templateUrl: './notificacoes-component.component.html',
  styleUrl: './notificacoes-component.component.scss'
})
export class NotificacoesComponentComponent implements OnInit {
  private bottomSheetRef = inject<MatBottomSheetRef<NotificacoesComponentComponent>>(MatBottomSheetRef);

  quantidadeDeInformacaoDocumentadaPendente: number = 0;

  icone = "fa-up-right-and-down-left-from-center";
  status = "PENDENTE"
  constructor(
    private informacaoDocumentadaService: InformacaoDocumentadaService,
    private emisorEventoGeralService: EmisorEventoGeralService,
    private router: Router
  ) {

  }
  ngOnInit(): void {
    this.emitirQuantidadeTotalDeNotificacao();
    this.consultarNotificacoes();

  }

  openLink(rota: any): void {
    this.router.navigate([`acoes/informacao-documentada-aprovacao/${rota}`])
    this.bottomSheetRef.dismiss();
  }


  public consultarNotificacoes() {
    this.informacaoDocumentadaService.consultaAprovacaoPendentesDoUsuario(this.status).subscribe({
      next: (res) => {
        this.quantidadeDeInformacaoDocumentadaPendente = res;
      }, complete: () => {

      }
    });
  }

  public emitirQuantidadeTotalDeNotificacao() {
    let totalDeNotificacao = this.quantidadeDeInformacaoDocumentadaPendente;
    EmisorEventoGeralService.quantidadeNotificacao.subscribe(res => {
      this.quantidadeDeInformacaoDocumentadaPendente = res;
    });
  }
}
