import { ActivatedRoute } from '@angular/router';
import { Component, inject, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { BotaoComponent } from "../../../../componentes/botao/botao.component";
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { PlanejamentoComunicacaoService } from '../../../../services/acoes/planejamento-comunicacao/planejamento-comunicacao.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { CommonModule } from '@angular/common';
import { PlanejamentoComunicacao } from '../../../../model/acoes/planejamento-comunicacao/PlanejamentoComunicacao';
import AcoesPlanejamentoComunicacao from '../../../../model/acoes/planejamento-comunicacao/AcoesPlanejamentoComunicacao';

@Component({
  selector: 'app-planejamento-comunicacao-registrar-acoes',
  standalone: true,
  imports: [ContainerComponentComponent, MensagemErrorsComponent, BotaoComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './planejamento-comunicacao-registrar-acoes.component.html',
  styleUrl: './planejamento-comunicacao-registrar-acoes.component.scss'
})
export class PlanejamentoComunicacaoRegistrarAcoesComponent implements OnInit {

  formularioCadastroAcoes!: FormGroup;
  idAcaoPlanoComunicao: number = 0;

  @Input() planejamentoComunicacao!: PlanejamentoComunicacao;
  @Input() acaoPlanejamentoComunicacao!: AcoesPlanejamentoComunicacao;
  @Output() emitirNovaConsulta: EventEmitter<boolean> = new EventEmitter;
  @ViewChild("formularioEditarAcoes") formularioEditarAcoes!: ElementRef;

  private formValidacaoService = inject(FormValidacaoService);
  private formBuilder = inject(FormBuilder);
  private activatedRoute = inject(ActivatedRoute);
  private planejamentoComunicacaoService = inject(PlanejamentoComunicacaoService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private snackBarService = inject(SnackBarService);

  ngOnInit(): void {
    this.verificarEditar();
  }

  ngAfterViewInit(): void {
    this.moverParaFormulario();
  }


  public verificarEditar() {
    this.planejamentoComunicacaoService.emitirEdicao.subscribe(res => {
      if (res.id) {
        this.moverParaFormulario();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.verificarRota();
    if (changes['acaoPlanejamentoComunicacao'] && changes['acaoPlanejamentoComunicacao'].currentValue && this.formularioCadastroAcoes) {
      this.formularioCadastroAcoes.patchValue(this.acaoPlanejamentoComunicacao);
    }
  }


  private verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idAcaoPlanoComunicao = res.id;
      }
    });
  }





  public iniciarFormulario() {
    this.formularioCadastroAcoes = this.formBuilder.group({
      id: [],
      oqueComunica: [],
      quandoComunica: [],
      publicoAlvo: [],
      canalComunicacao: [],
      respComunicacao: [],
    });
  }


  public cadastrarAcoesParteInteressada() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.formularioCadastroAcoes.removeControl("id");
    this.planejamentoComunicacaoService.cadastrarAcoesPlanejamentoComunicacao(this.idAcaoPlanoComunicao, this.formularioCadastroAcoes.value).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar salvar ação");
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.emitirNovaConsulta.emit(true);
        this.iniciarFormulario();
        this.snackBarService.openSnackBar("Ação criada com sucesso", "my-snack-bar-sucess");
      }
    });
  }
  public editarAcoesParteInteressada() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.planejamentoComunicacaoService.editarAcoesPlanejamentoComunicacao(this.idAcaoPlanoComunicao, this.formularioCadastroAcoes.value).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar salvar ação");
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.emitirNovaConsulta.emit(true);
        this.formularioCadastroAcoes.reset();
        this.snackBarService.openSnackBar("Ação criada com sucesso", "my-snack-bar-sucess");
      }
    })
  }


  public limparFormulario() {
    this.formularioCadastroAcoes.reset();
    this.acaoPlanejamentoComunicacao = new AcoesPlanejamentoComunicacao();
  }


  public moverParaFormulario() {
    if (this.formularioEditarAcoes) {
      this.formularioEditarAcoes.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formularioCadastroAcoes, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formularioCadastroAcoes, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formularioCadastroAcoes, nome);
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }


}
