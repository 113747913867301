import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { AnaliseCritica } from '../../../model/acoes/analise-critica/AnaliseCritica';
import { RegistroPaginado } from '../../../interface/registroPaginado';

@Injectable({
  providedIn: 'root'
})
export class AnaliseCriticaService {

  urlApi = environment.url + "/acoes/analise-critica"

  constructor(
    private http: HttpClient
  ) { }


  public cadastrarAnaliseCritica(analiseCritica: AnaliseCritica): Observable<AnaliseCritica> {
    return this.http.post<AnaliseCritica>(`${this.urlApi}`, analiseCritica);
  }
  public editarAnaliseCritica(analiseCritica: AnaliseCritica): Observable<AnaliseCritica> {
    return this.http.put<AnaliseCritica>(`${this.urlApi}`, analiseCritica);
  }
  public consultarAnaliseCriticaPorId(idAnaliseCritica: number): Observable<AnaliseCritica> {
    return this.http.get<AnaliseCritica>(`${this.urlApi}/${idAnaliseCritica}`);
  }
  public deletarAnaliseCritica(idAnaliseCritica: number): Observable<AnaliseCritica> {
    return this.http.delete<AnaliseCritica>(`${this.urlApi}/${idAnaliseCritica}`);
  }
  public consultaAnaliseCriticaPaginada(paginaAtual: number, quantidadePorPagina: number,valorBusca?:string): Observable<RegistroPaginado> {
    let url =  valorBusca != "" ? `${this.urlApi}/${paginaAtual}/${quantidadePorPagina}/${valorBusca}` : `${this.urlApi}/${paginaAtual}/${quantidadePorPagina}`
    return this.http.get<RegistroPaginado>(url);
  }


}