import { Component } from '@angular/core';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";
import { FormsModule } from '@angular/forms';
import { NotificacaoSistemaConsulta } from '../../../model/infra/notificacoes/notificacao-consulta';

@Component({
  selector: 'app-notificacoes',
  standalone: true,
  imports: [ContainerComponentComponent,FormsModule],
  templateUrl: './notificacoes.component.html',
  styleUrl: './notificacoes.component.scss'
})
export class NotificacoesComponent {

  mensagem:NotificacaoSistemaConsulta = new NotificacaoSistemaConsulta();


  // aplicarFormatacao(tag: string) {
  //   const textArea = this.textArea.nativeElement; // Acessa o textarea
  //   const inicioSelecao = textArea.selectionStart; // Índice inicial da seleção
  //   const fimSelecao = textArea.selectionEnd; // Índice final da seleção
  //   const textoSelecionado = this.mensagem.substring(inicioSelecao, fimSelecao); // Texto selecionado

  //   if (!textoSelecionado) return; // Se não houver texto selecionado, não faz nada

  //   let textoFormatado = '';

  //   // Aplica a tag correspondente
  //   switch (tag) {
  //     case 'strong':
  //       textoFormatado = `<strong>${textoSelecionado}</strong>`;
  //       break;
  //     case 'em':
  //       textoFormatado = `<em>${textoSelecionado}</em>`;
  //       break;
  //     case 'h2':
  //       textoFormatado = `<h2>${textoSelecionado}</h2>`;
  //       break;
  //     default:
  //       textoFormatado = textoSelecionado;
  //   }

  //   // Substitui o texto selecionado pelo texto formatado
  //     // this.mensagem.substring(0, inicioSelecao) +
  //     // textoFormatado +
  //     // this.mensagem.substring(fimSelecao);

  //   // Atualiza o valor do textarea
  //   textArea.value = this.mensagem;

  //   // Restaura a seleção do cursor
  //   textArea.setSelectionRange(inicioSelecao, inicioSelecao + textoFormatado.length);
  // }
}
