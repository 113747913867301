import { Routes } from "@angular/router";
import { ControlesHomeComponent } from "../../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-home/controles-home.component";
import { ControlesMedicaoMonitoramentoListaComponent } from "../../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-lista/controles-medicao-monitoramento-lista.component";
import { SatisfacaoClienteHomeComponent } from "../../paginas/controles/satisfacao-cliente/satisfacao-cliente-home/satisfacao-cliente-home.component";
import { SatisfacaoClienteListaComponent } from "../../paginas/controles/satisfacao-cliente/satisfacao-cliente-lista/satisfacao-cliente-lista.component";
import { NormaPinturaHomeComponent } from "../../paginas/controles/norma-pintura/norma-pintura-home/norma-pintura-home.component";
import { NormaPinturaConsultarComponent } from "../../paginas/controles/norma-pintura/norma-pintura-consultar/norma-pintura-consultar.component";
import { ManualClienteConsultaComponent } from "../../paginas/controles/manuais-cliente/manual-cliente-consulta/manual-cliente-consulta.component";
import { ManualClienteCadastroComponent } from "../../paginas/controles/manuais-cliente/manual-cliente-cadastro/manual-cliente-cadastro.component";


export const routesControleView: Routes = [

    //MEDIÇÃO E MONITORAMENTO
    { path: "home", component: ControlesHomeComponent },
    {
        path: "lista-medicao-monitoramento",
        component:ControlesMedicaoMonitoramentoListaComponent
    },

    //SATISFAÇÃO CLIENTE
    {
        path: "home-satisfacao-cliente",
        component:SatisfacaoClienteHomeComponent,
    },
    {
        path: "lista-satisfacao-cliente",
        component:SatisfacaoClienteListaComponent
    },
    {
        path: "resultados-satisfacao-cliente/:id", loadComponent: () => import("../../paginas/controles/satisfacao-cliente/satisfacao-cliente-resultado/satisfacao-cliente-resultado.component")
            .then(s => s.SatisfacaoClienteResultadoComponent)
    },

    //NORMA PINTURA
    {
        path: "home-norma-pintura",
        component:NormaPinturaHomeComponent
    },
    {
        path: "consulta-norma-pintura",
        component:NormaPinturaConsultarComponent
    },

    //MANUAIS DE CLIENTES
    {
        path: "manuais-cliente-consulta",
        component:ManualClienteConsultaComponent
    },
    {
        path: "manuais-cliente-cadastro",
        component:ManualClienteCadastroComponent
    },
    {
        path: "manuais-cliente-gerenciar/:id",
        loadComponent: () => import("../../paginas/controles/manuais-cliente/manual-cliente-gerencia/manual-cliente-gerencia.component")
            .then(m => m.ManualClienteGerenciaComponent)
    },
    {
        path: "manuais-cliente-requisito/:idManual/:idRequisito",
        loadComponent: () => import("../../paginas/controles/manuais-cliente/requisito-edicao/requisito-edicao.component")
            .then(m => m.RequisitoEdicaoComponent)
    },
    {
        path: "manuais-cliente-aprovacao",
        loadComponent: () => import("../../paginas/controles/manuais-cliente/manual-cliente-aprovacao/manual-cliente-aprovacao.component")
            .then(m => m.ManualClienteAprovacaoComponent)
    },
];
