import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, throwError } from "rxjs";


export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem('token_sgq');
  const router = inject(Router);

  const publicRoutes = [
    '/login',
    '/responder-satisfacao-cliente'
  ]

  if (publicRoutes.some(route => req.url.includes(route))) {
    return next(req)
  }


  let authReq = req;
  if (token) {
    const auth = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next(auth);
  } else {
    return next(authReq).pipe(
      catchError(error => {
        console.log('Request error:', error);
        // if (error) {
        //   console.log('Redirecionado para o login');
        //   localStorage.removeItem('token');
        //   router.navigate(['/login']);
        // }
        return throwError(() => error);
      })
    );
  }
};


export const jwtConfig = {
  tokenGetter: tokenGetter,
}


export function tokenGetter() {
  return localStorage.getItem('token_sgq');
}