import { Routes } from "@angular/router";


export const routesControlePrivada: Routes = [

    //MEDIÇÃO E MONITORAMENTO
    {
        path: "cadastrar-medicao-monitoramento",
        loadComponent: () => import("../../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-cadastro/controles-cadastro.component")
            .then(c => c.ControlesCadastroComponent),

    },
    {
        path: "cadastrar-medicao-monitoramento/:id",
        loadComponent: () => import("../../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-cadastro/controles-cadastro.component")
            .then(c => c.ControlesCadastroComponent),
            
    },
    {
        path: "cadastrar-medicao-monitoramento/:id",
        loadComponent: () => import("../../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-cadastro/controles-cadastro.component")
            .then(c => c.ControlesCadastroComponent),
    },
    {
        path: "cadastrar-medicao-monitoramento/:id",
        loadComponent: () => import("../../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-cadastro/controles-cadastro.component")
            .then(c => c.ControlesCadastroComponent)
    },
    {
        path: "resultado-medicao-monitoramento/:id",
        loadComponent: () => import("../../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-resultado/controles-medicao-monitoramento-resultado.component")
            .then(c => c.ControlesMedicaoMonitoramentoResultadoComponent),
    },

    //SATISFAÇÃO CLIENTE
    {
        path: "cadastrar-satisfacao-cliente",
        loadComponent: () => import("../../paginas/controles/satisfacao-cliente/satisfacao-cliente-cadastrar/satisfacao-cliente-cadastrar.component")
            .then(s => s.SatisfacaoClienteCadastrarComponent),
    },
    {
        path: "cadastrar-satisfacao-cliente/:id",
        loadComponent: () => import("../../paginas/controles/satisfacao-cliente/satisfacao-cliente-cadastrar/satisfacao-cliente-cadastrar.component")
            .then(s => s.SatisfacaoClienteCadastrarComponent),
    },
    {
        path: "autoridade-pesquisa/:id",
        loadComponent: () => import("../../paginas/controles/satisfacao-cliente/satisfacao-cliente-autoridade/satisfacao-cliente-autoridade.component")
            .then(s => s.SatisfacaoClienteAutoridadeComponent),
    },
    {
        path: "editar-satisfacao-cliente/:id",
        loadComponent: () => import("../../paginas/controles/satisfacao-cliente/satisfacao-cliente-editar/satisfacao-cliente-editar.component")
            .then(s => s.SatisfacaoClienteEditarComponent),
    },

    //NORMA DE PINTURA
    {
        path: "cadastrar-norma-pintura",
        loadComponent: () => import("../../paginas/controles/norma-pintura/norma-pintura-cadastrar/norma-pintura-cadastrar.component")
            .then(n => n.NormaPinturaCadastrarComponent),
    },
    {
        path: "cadastrar-norma-pintura/:id",
        loadComponent: () => import("../../paginas/controles/norma-pintura/norma-pintura-cadastrar/norma-pintura-cadastrar.component")
            .then(n => n.NormaPinturaCadastrarComponent),
    },

    {
        path: "historico-norma-pintura/:id",
        loadComponent: () => import("../../paginas/controles/norma-pintura/norma-pintura-historico/norma-pintura-historico.component")
            .then(n => n.NormaPinturaHistoricoComponent),
    },
    {
        path: "requisito-norma-pintura/:idNorma/:idRequisito",
        loadComponent: () => import("../../paginas/controles/norma-pintura/norma-pintura-alteracao/norma-pintura-alteracao.component")
            .then(r => r.NormaPinturaAlteracaoComponent),
    },


    //MANUAIS DE CLIENTE
    {
        path: "manuais-cliente-edicao/:id",
        loadComponent: () => import("../../paginas/controles/manuais-cliente/manual-cliente-edicao/manual-cliente-edicao.component")
            .then(m => m.ManualClienteEdicaoComponent),
    },
    {
        path: "manuais-cliente-requisito/:idManual",
        loadComponent: () => import("../../paginas/controles/manuais-cliente/requisito-cadastro/requisito-cadastro.component")
            .then(m => m.RequisitoCadastroComponent),
    },

]