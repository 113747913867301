import { Component, OnInit } from '@angular/core';
import { OrdemDeServicoConsulta } from '../../../../../model/acoes/ordem-servico/ordem-servico-consulta';
import { OrdemServicoService } from '../../../../../services/acoes/manutencao/ordem-servico.service';
import { SnackBarService } from '../../../../../utils/snack-bar.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContainerComponentComponent } from '../../../../../componentes/container-component/container-component.component';
import { PageEvent } from '@angular/material/paginator';
import { PaginatorComponent } from '../../../../../componentes/paginator/paginator.component';
import { OrdemServicoCadastroComponent } from '../ordem-servico-cadastro/ordem-servico-cadastro.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../../../componentes/modal/modal.component';
import { ModalConsultaComponent } from '../modal-consulta/modal-consulta.component';

@Component({
  selector: 'app-ordem-servico-consulta',
  standalone: true,
  imports: [
    ContainerComponentComponent,
    FormsModule,
    CommonModule,
    PaginatorComponent,
    OrdemServicoCadastroComponent,
  ],
  templateUrl: './ordem-servico-consulta.component.html',
  styleUrl: './ordem-servico-consulta.component.scss'
})
export class OrdemServicoConsultaComponent implements OnInit {
  osConsulta: OrdemDeServicoConsulta[] = [];
  osConsultaPaginada: OrdemDeServicoConsulta[] = [];
  clickTabAprovacao: boolean = false;
  clickReprovar: boolean = false;
  os: OrdemDeServicoConsulta;
  paginaAtual: number = 0;
  totalElementos: number = 0;
  itensPorPagina: number[] = [5, 10, 15, 20, 25];
  totalPorPagina: number = this.itensPorPagina[0];
  status: string = 'Aberta'
  habilitaCadastro: boolean = false;
  textoBotao = 'Nova Os';

  constructor(
    private osService: OrdemServicoService,
    private snackBarService: SnackBarService,
    private matDialog: MatDialog,
  ) {
    this.os = new OrdemDeServicoConsulta();
  }

  ngOnInit(): void {
    this.consultarOs();
    this.receberNovosCadastros();
  }

  public receberNovosCadastros(){
    OrdemServicoService.emitirCadastro.subscribe((res)=>{
      if(res == true){
        this.consultarOs();
        this.habilitaCadastro = false;
        this.textoBotao = 'Nova OS';
      }
    });
  }

  public consultarOs() {
    this.osService.consultaOrdensDeServicoDoUsuaioPoStatus(this.paginaAtual, this.status,this.totalPorPagina).subscribe({
      next: (res) => {
        this.totalElementos = res.page.totalElements;
        this.osConsulta = res.content;
      },
      error:(e)=>{}
    });
  }

  public visualizarOs(){
    this.matDialog.open(ModalConsultaComponent,{
      width: '80%',
      data: this.osConsulta
    });
  }

  public excluirOS(id: number, equipamento: string){
    let dialog = this.matDialog.open(ModalComponent,{
      data: {texto: 'Você deseja realmente excluir esta ordem de serviço?', nomeArquivo: equipamento }
    });
    dialog.afterClosed().subscribe(res=>{
      if(res){
        this.excluir(id);
      }
    });
  }

  private excluir(id: number){
    this.osService.excluirOs(id).subscribe({
      next:(res)=>{
        this.snackBarService.openSnackBar('Ordem de serviço removida com sucesso!', 'my-snack-bar-sucess');
      },
      error:(e)=>{
        this.snackBarService.tratarErro(e, "Falha ao tentar excluir os");
      },
      complete:()=>{
        this.consultarOs();
      }
    });
  }

  public receberItensPaginado(itensPaginado: any[]) {
    this.osConsultaPaginada = itensPaginado;
  }

  public aprovarOs(os: OrdemDeServicoConsulta) {
    this.clickTabAprovacao = true;
    this.os = os;
  }

  public reprovar() {
    if (!this.clickReprovar) {
      this.clickReprovar = true;
      this.snackBarService.openSnackBar('Digite o motivo da reprova!', 'my-snack-bar-warning', 10000);
    }
  }

  public ativarCastro(){
    this.habilitaCadastro = !this.habilitaCadastro;
    if(this.habilitaCadastro){
      this.textoBotao = 'Fechar cadastro';
    }else{
      this.textoBotao = 'Nova OS';
    }
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultarOs();
  }

}
