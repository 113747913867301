import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, tap, throwError } from "rxjs";
import { EmisorEventoGeralService } from "../utils/emisorEventoGeral.service";
import { LoginService } from "../services/login/login.service";


export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const loginService = inject(LoginService);
  const router = inject(Router);
  const emisorEventoGeralService = inject(EmisorEventoGeralService);
  const publicRoutes = [
    '/login',
    'api/recuperar-senha',
    // '/usuario-config',
    '/resposta/satisfacao-cliente',
  ];
  

  if (publicRoutes.some(route => req.url.includes(route))) {
    return next(req).pipe(
      tap(response => response),
      catchError(error => handleHttpError(error, router, emisorEventoGeralService))
    );
  }

  // ✅ Verifica se o token está expirado antes de enviar a requisição
  if (!loginService.estaLogado()) {
    emisorEventoGeralService.controleHeader.emit(false);
    setTimeout(() => router.navigate(['/login']), 0);
    return throwError(() => new Error('Falha ao verificar se o usuário esta autenticado!'));
  }

  const token = loginService.consultarLocalstorageTokenSgq();
  const authReq = token ? req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }) : req;

  return next(authReq).pipe(
    tap(response => response),
    catchError(error => handleHttpError(error, router, emisorEventoGeralService))
  );
};

// ✅ Apenas trata erros genéricos sem remover o token no erro 403
function handleHttpError(error: any, router: Router, emisorEventoGeralService: EmisorEventoGeralService) {
  if (error.status === 401) {
    emisorEventoGeralService.controleHeader.emit(false);
    setTimeout(() => router.navigate(['/login']), 0);
  }
  return throwError(() => error);
}
