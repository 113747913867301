import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertaService } from '../../../../services/qualidade/alerta/alerta.service';
import { Alerta } from '../../../../model/qualidade/alerta/alerta';
import { AlertaListaComponent } from "../alerta-lista/alerta-lista.component";
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-alerta',
  standalone:true,
  templateUrl: './home-alerta.component.html',
  styleUrls: ['./home-alerta.component.scss'],
  imports: [AlertaListaComponent, ContainerComponentComponent]
})
export class HomeAlertaComponent implements OnInit {

  alertasEmAndamento: Alerta[] = [];
  alertasEmProcesso: Alerta[] = [];
  alertasFinalizado: Alerta[] = [];
  alertasRetirar: Alerta[] = [];
  alertasSemRetorno: Alerta[] = [];

  constructor(
    private alertaService: AlertaService,
    private router:Router
  ) { }

  ngOnInit() {
    this.consultaPorStatusSemRetorno();
    this.consultaPorStatusEmAndamento();
    this.consultaPorStatusEmProcesso();
    this.consultaPorStatusRetirar();
    this.consultaPorStatusFinalizado();
  }


  public consultaPorStatusSemRetorno() {
    this.alertaService.consultaAlertaPorStatus('Sem retorno').subscribe({
      next: (res) => {
        this.alertasSemRetorno = res;
        console.log(this.alertasSemRetorno)
      }, error: (error) => {
        this.alertasSemRetorno = [];
      }
    });
  }

  public consultaPorStatusEmAndamento() {
    this.alertaService.consultaAlertaPorStatus('Em andamento').subscribe({
      next: (res) => {
        this.alertasEmAndamento = res;
      }, error: (error) => {
        this.alertasEmAndamento = [];
      }
    });
  }

  public consultaPorStatusEmProcesso() {
    this.alertaService.consultaAlertaPorStatus('Em processo').subscribe({
      next: (res) => {
        this.alertasEmProcesso = res;
      }, error: (error) => {
        this.alertasEmProcesso = [];
      }
    });
  }
  public consultaPorStatusRetirar() {
    this.alertaService.consultaAlertaPorStatus('Retirar').subscribe({
      next: (res) => {
        this.alertasRetirar = res;
      }, error: (error) => {
        this.alertasRetirar = [];
      }
    });

  }
  public consultaPorStatusFinalizado() {
    this.alertaService.consultaAlertaPorStatus('Finalizado').subscribe({
      next: (res) => {
        this.alertasFinalizado = res;
      }, error: (error) => {
        this.alertasFinalizado = [];
      }
    });
  }


  public navegarPorStatusSemRetorno(valor:string) {
    this.router.navigate([`qualidade/alerta/${valor}`])
  }

  public navegarPorStatusEmAndamento(valor:string) {
    this.router.navigate([`qualidade/alerta/${valor}`])
  }

  public navegarPorStatusEmProcesso(valor:string) {
    this.router.navigate([`qualidade/alerta/${valor}`])
  }
  public navegarPorStatusRetirar(valor:string) {
    this.router.navigate([`qualidade/alerta/${valor}`])

  }
  public navegarPorStatusFinalizado(valor:string) {
    this.router.navigate([`qualidade/alerta/${valor}`])
  }
}
