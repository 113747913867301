import { Processo } from "./processo";

export class EngDispositivoPintura {
    id!: number;
    nomeDispositivo!: string;
    cdProcesso!:number;
    cdDispositivo!: number;
    cdDeterminacao!: number;
    cdProduto!: number;

}