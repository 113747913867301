import { PartesInteressadaRegistrarComponent } from './../../paginas/acoes/partes-interessadas/partes-interessada-registrar/partes-interessada-registrar.component';
import { Routes } from "@angular/router";
import { InformacaoDocumentadaListaComponent } from "../../paginas/acoes/informacao-documentada/informacao-documentada-lista/informacao-documentada-lista.component";
import { PlanoDeRiscoListarComponent } from "../../paginas/acoes/plano-de-risco/plano-de-risco-listar/plano-de-risco-listar.component";
import { PartesInteressadasComponent } from '../../paginas/acoes/partes-interessadas/partes-interessadas/partes-interessadas.component';
import { PartesInteressadaListarComponent } from '../../paginas/acoes/partes-interessadas/partes-interessada-visualizar/partes-interessada-listar.component';
import { AnaliseCriticaRegistrarComponent } from '../../paginas/acoes/analise-critica/analise-critica-registrar/analise-critica-registrar.component';
import { AnaliseCriticaListaComponent } from '../../paginas/acoes/analise-critica/analise-critica-lista/analise-critica-lista.component';
import { AnaliseCriticaVisualizarComponent } from '../../paginas/acoes/analise-critica/analise-critica-visualizar/analise-critica-visualizar.component';
import { PlanejamentoComunicacaoRegistrarComponent } from '../../paginas/acoes/planejamento-comunicacao/planejamento-comunicacao-registrar/planejamento-comunicacao-registrar.component';
import { PlanejamentoComunicacaoHomeComponent } from '../../paginas/acoes/planejamento-comunicacao/planejamento-comunicacao-home/planejamento-comunicacao-home.component';
import { AuditoriaInternaRegistrarComponent } from '../../paginas/acoes/auditoria-interna/auditoria-interna-registrar/auditoria-interna-registrar.component';
import { AuditoriaInternaListaComponent } from '../../paginas/acoes/auditoria-interna/auditoria-interna-lista/auditoria-interna-lista.component';
import { AuditoriaInternaApontamentosComponent } from '../../paginas/acoes/auditoria-interna/auditoria-interna-apontamentos/auditoria-interna-apontamentos.component';
import { AuditoriaInternaGerenciarComponent } from '../../paginas/acoes/auditoria-interna/auditoria-interna-gerenciar/auditoria-interna-gerenciar.component';


export const routesAcoesView: Routes = [
    { path: "informacao-documentada", component: InformacaoDocumentadaListaComponent },
    { path: "informacao-documentada-historico/:id", loadComponent: () => import('../../paginas/acoes/informacao-documentada/informacao-documentada-historico/informacao-documentada-historico.component').then(h => h.InformacaoDocumentadaHistoricoComponent) },
    { path: "informacao-documentada-aprovacao/:valor", loadComponent: () => import('../../paginas/acoes/informacao-documentada/informacao-documentada-aprovacao/informacao-documentada-aprovacao.component').then(a => a.InformacaoDocumentadaAprovacaoComponent) },

    { path: "plano-de-risco", component: PlanoDeRiscoListarComponent },
    { path: "plano-de-risco-acoes/:id", loadComponent: () => import('../../paginas/acoes/plano-de-risco/plano-de-risco-acoes-lista/plano-de-risco-acoes-lista.component').then(i => i.PlanoDeRiscoAcoesListaComponent) },
    { path: "plano-de-risco-gerenciar/:idPlano", loadComponent: () => import('../../paginas/acoes/plano-de-risco/plano-de-risco-editar/plano-de-risco-editar.component').then(i => i.PlanoDeRiscoEditarComponent) },
    { path: "plano-de-risco-gerenciar/:idPlano/:idAcao", loadComponent: () => import('../../paginas/acoes/plano-de-risco/plano-de-risco-editar/plano-de-risco-editar.component').then(i => i.PlanoDeRiscoEditarComponent) },
    { path: "plano-de-risco-historico/:id", loadComponent: () => import('../../paginas/acoes/plano-de-risco/plano-de-risco-historico/plano-de-risco-historico.component').then(h => h.PlanoDeRiscoHistoricoComponent) },
    { path: "plano-de-risco-registrar", loadComponent: () => import('../../paginas/acoes/plano-de-risco/plano-de-risco-registrar/plano-de-risco-registrar.component').then(r => r.PlanoDeRiscoRegistrarComponent) },
    { path: "plano-de-risco-registrar/:id", loadComponent: () => import('../../paginas/acoes/plano-de-risco/plano-de-risco-registrar/plano-de-risco-registrar.component').then(r => r.PlanoDeRiscoRegistrarComponent) },



    { path: "partes-interessadas", component: PartesInteressadasComponent },
    { path: "partes-interessadas-registrar", component: PartesInteressadaRegistrarComponent },
    { path: "partes-interessadas-registrar/:id", component: PartesInteressadaRegistrarComponent },
    { path: "partes-interessadas-visualizar/:id", component: PartesInteressadaListarComponent },



    { path: "planejamento-comunicacao", component: PlanejamentoComunicacaoHomeComponent },
    { path: "planejamento-comunicacao-registrar", component: PlanejamentoComunicacaoRegistrarComponent },
    { path: "planejamento-comunicacao-registrar/:id", component: PlanejamentoComunicacaoRegistrarComponent },
    { path: "planejamento-comunicacao-visualizar/:id", component: PartesInteressadaListarComponent },

    { path: "auditoria-interna-lista", component: AuditoriaInternaListaComponent },
    { path: "auditoria-interna-registrar", component: AuditoriaInternaRegistrarComponent },
    { path: "auditoria-interna-registrar/:id", component: AuditoriaInternaRegistrarComponent },
    { path: "auditoria-interna-apontamentos/:id", component: AuditoriaInternaApontamentosComponent },
    { path: "auditoria-interna-gerenciar/:id", component: AuditoriaInternaGerenciarComponent },

    { path: "analise-critica", component: AnaliseCriticaListaComponent },
    { path: "analise-critica-registrar", component: AnaliseCriticaRegistrarComponent },
    { path: "analise-critica-registrar/:id", component: AnaliseCriticaRegistrarComponent },
    { path: "analise-critica-visualizar/:id", component: AnaliseCriticaVisualizarComponent },


]
