<app-container-component [tituloPage]="'SATISFAÇÃO DE CLIENTE'" [subtituloPage]="'Pesquisas'">
    <div class="row m-auto gap-3 mb-3">
        <div
            class="col-12 col-md-12  sessao-card d-flex justify-content-center align-items-center col-lg-3 col-xl-3 col-xxl-2 card-contador gap-1">
            <p class="text-center m-0">Cadastradas</p>
            <label class="text-center"><strong>{{lista!.pesquisasCriadas}}</strong></label>
        </div>
        <div
            class="col-12 col-md-12  sessao-card d-flex justify-content-center align-items-center col-lg-3 col-xl-3 col-xxl-2 card-contador-descalibrado gap-1">
            <p class="text-center m-0">Em Andamento</p>
            <label class="text-center"><strong>{{lista.pesquisasAtivas}}</strong></label>
        </div>
        <div
            class="col-12 col-md-12  sessao-card d-flex justify-content-center align-items-center col-lg-3 col-xl-3 col-xxl-2 card-contador-finalizada gap-1">
            <p class="text-center m-0">Finalizadas</p>
            <label class="text-center"><strong>{{lista.pesquisasInativas}}</strong></label>
        </div>
    </div>
</app-container-component>
<app-satisfacao-cliente-lista [comTopo]="false"></app-satisfacao-cliente-lista>