<app-container-component tituloPage="Normas de Pintura" subtituloPage="Normas">
    <div class="row m-auto gap-3 mb-3">
        <div
            class="col-12 col-md-12  sessao-card d-flex justify-content-center align-items-center col-lg-3 col-xl-3 col-xxl-2 card-contador gap-1">
            <p class="text-center m-0">Registrados</p>
            <label class="text-center"><strong></strong></label>
        </div>
        <div
            class="col-12 col-md-12  sessao-card d-flex justify-content-center align-items-center col-lg-3 col-xl-3 col-xxl-2 card-contador-descalibrado gap-1">
            <p class="text-center m-0">Gerenciados</p>
            <label class="text-center"><strong></strong></label>
        </div>
    </div>
</app-container-component>
<app-norma-pintura-consultar [desativarHeader]="false"></app-norma-pintura-consultar>