<mat-drawer-container class="example-container h-100">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <div class="row mb-1 mt-1 ms-1">
            <div class="d-none d-lg-flex col-1">
                @if(menuFixo){
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Desfixar menu"></i></div>
                }@else {
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack-slash" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Fixar menu"></i></div>
                }
            </div>
            <div class="col-10  col-md-11 d-flex justify-content-start  align-items-center">
                <a class="logo d-flex justify-content-center align-items-center gap-2"> <i
                        class="fa-solid fa-ranking-star"></i>
                    Qualidade</a>
            </div>
            <div class="col-2  z-1 d-flex align-items-center  justify-content-end" (click)="clicarMenu(drawer)"
                (click)="mudarIcone()">
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div class="menu-content">
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                    (click)="abrirMenu('home')" routerLink="/qualidade/home">
                    <span class="font-titulo"><i class="fa-solid fa-house"></i> <span class="ms-2">Home
                        </span></span>
                </div>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['nao-conformidade']}" (click)="abrirMenu('nao-conformidade')">
                    <span class="font-titulo"><i class="fa-solid fa-file-prescription"></i> <span class="ms-2">Não
                            conformidade</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['nao-conformidade']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['nao-conformidade']}">
                    @for (link of getMenuItensNaoConformidade(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }

                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['alerta']}" (click)="abrirMenu('alerta')">
                    <span class="font-titulo"><i class="fa-solid fa-bell"></i> <span class="ms-2">Alerta
                        </span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['alerta']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['alerta']}">
                    @for (link of getMenuItensAlerta(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['produtoNaoConforme']}"
                    (click)="abrirMenu('produtoNaoConforme')">
                    <span class="font-titulo"><i class="fa-solid fa-thumbs-down"></i> <span class="ms-2">Produto não
                            conforme
                        </span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['produtoNaoConforme']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['produtoNaoConforme']}">
                    @for (link of getMenuItensProdutoNaoConforme(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['planoAcao']}" (click)="abrirMenu('planoAcao')">
                    <span class="font-titulo"><i class="fa-solid fa-code-fork"></i> <span class="ms-2">Plano de ação
                        </span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['planoAcao']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['planoAcao']}">
                    @for (link of getMenuPlanoDeAcao(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['reclamacao']}" (click)="abrirMenu('reclamacao')">
                    <span class="font-titulo"><i class="fa-solid fa-face-frown"></i> <span class="ms-2">Reclamação
                        </span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['reclamacao']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['reclamacao']}">
                    @for (link of getMenuItensReclamacoes(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['custoQualidade']}" (click)="abrirMenu('custoQualidade')">
                    <span class="font-titulo"><i class="fa-solid fa-dollar-sign"></i> <span class="ms-2">Custo Qualidade
                        </span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['custoQualidade']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['custoQualidade']}">
                    @for (link of getMenuItensCustoQualidade(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <a routerLink="/gestao-a-vista">
                    <div class="d-flex justify-content-between sessao-header-menu"
                        [ngClass]="{'active':menusVisiveis['gestaoAhVista']}" (click)="abrirMenu('gestaoAhVista')">

                        <span class="font-titulo"><i class="fa-solid fa-dollar-sign"></i> <span class="ms-2">Gestão a
                                vista
                            </span></span>
                    </div>
                </a>
            </ul>
        </div>
    </mat-drawer>
    <div class="example-sidenav-content flex-column justify-content-start align-items-start ">
        <a class="btn-menu" (click)="clicarFechar(drawer)" (click)="mudarIcone()">
            <i *ngIf="!open" class="fa-solid fa-angles-left"></i>
            <i *ngIf="open" class="fa-solid fa-angles-right"></i>
        </a>
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>