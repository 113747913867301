import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ProdutoDetalheService } from '../../../services/engenharia/produto/produto-detalhe.service';
import { Produto } from '../../../model/engenharia/produto-model';
import { PaginatorComponent } from '../../../componentes/paginator/paginator.component';
import { ProdutoDetalheImagemService } from '../../../services/engenharia/produto/produto-detalhe-imagem.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadCardComponentComponent } from "../../../componentes/loadCardComponent/loadCardComponent.component";

@Component({
  selector: 'app-modal-consulta',
  standalone: true,
  imports: [
    MatDialogModule,
    PaginatorComponent,
    CommonModule,
    FormsModule,
    LoadCardComponentComponent
],
  templateUrl: './modal-consulta.component.html',
  styleUrl: './modal-consulta.component.scss'
})
export class ModalConsultaComponent implements OnInit {

  produtos: any[] = [];
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [3, 8, 12, 16];
  totalPorPagina: number = this.quantidadesPorPagina[0];
  tooltipPosition = { x: 0, y: 0 }; // Armazena a posição do tooltip
  nomeProduto: string = '';
  exibeAlerta: boolean = false;
  


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private produtoService: ProdutoDetalheService,
    private dialogRef: MatDialogRef<ModalConsultaComponent>,
    private imagemService: ProdutoDetalheImagemService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    if (this.data) {
      this.exibeAlerta = false;
      this.nomeProduto = this.data.nomeProduto;
      this.consultarProdutos();
  
    }
  }

  public consultarProdutos() {
    this.exibeAlerta = false;
    this.produtoService.consultaPorNomeProduto(this.paginaAtual, this.totalPorPagina, this.nomeProduto).subscribe({
      next: (res) => {
        this.totalPorPagina = res.page.size;
        this.totalTamanho = res.page.totalElements;
        this.preencherDadosDoProduto(res.content);
        this.consultarImagem();
      }, error: (e) => {

      }
    });
  }

  public preencherDadosDoProduto(produtos: any){
    this.produtos = produtos;
  }

  public consultarImagem(){
    this.produtos.forEach((p: any) => {
      p.imagemCarregada = false;
      this.imagemService.consultarTodasImagemPorIdProduto(p.cdProduto).subscribe({
        next: (res) => {
          p.imagem = res[0] ? res[0].path : './assets/Produto-sem-imagem.png';
          this.cdr.detectChanges();
        }, error: (error) => {
          p.imagem = './assets/Produto-sem-imagem.png';
          this.cdr.detectChanges();
        }
      })
      p.imagemCarregada = false;
    });
  }

  loadingImagem(produto: any) {
    produto.imagemCarregada = true;
    this.cdr.detectChanges();
  }

  onChange() {
    this.paginaAtual = 0;
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.consultarProdutos();
    }
  }

  public mudancaNaPagina(evento: any) {
    this.paginaAtual = evento.pageIndex;
    this.totalPorPagina = evento.pageSize;
    this.consultarProdutos();
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public selecionarProduto(produto: Produto) {
    this.dialogRef.close(produto);
  }

  public consultaProdutoSuperSmart() {
    this.produtoService.consultarProdutoPaginado(0, 30, this.nomeProduto).subscribe({
      next: (res) => {
        console.log(res);
      }
    });
  }

}
