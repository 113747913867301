<section class="sessao-login">
    <aside class="sessao-lateral-login" #myDiv>
        <div class="sessao-login-card" >
            <div class="sessao-logo">
                <img class="imagen-logo" src="assets/logo-croma.png" alt="">
                <div class="label-logo">
                    <h5><strong>Faça login na sua conta</strong></h5>
                </div>
            </div>
            <div class="sessao-input-group">
                <div class="sessao-input d-flex flex-column">
                    <label class="mb-2" >Email</label>
                    <input type="mail" [(ngModel)]="email" placeholder="exemplo@email.com">
                    <span class="icon-group">
                        <i class="fa-regular fa-envelope"></i>
                    </span>
                </div>
                <div class="sessao-input d-flex flex-column">
                    <label class="mb-2">Senha</label>
                    <input type="password" (input)="verificarValor()" #inputSenha [(ngModel)]="senha" (keyup.enter)="login()"  placeholder="Digite sua senha">
                    <span class="icon-group" (click)="verSenha()">
                        <i [ngClass]="icone"></i>
                    </span>
                </div>
                <label class="sessao-esqueceu-senha" (click)="abrirEsqueceuSenha()">
                    <a ><i class="fa-solid fa-lock"></i> Esqueceu a senha ?</a>
                </label>
            </div>
            <div class="sessao-botao-group">
                <button class="botao-login" (click)="login()">Entrar <i class="fa-solid fa-right-to-bracket"></i></button>
            </div>
        </div>
        <section class="sessao-esqueceuSenha-card" #myDiv2>
            <div class="sessao-logo">
                <img class="imagen-logo-cadeado" src="assets/cadeado.png" alt="">
                <div class="label-logo">
                    <h5><strong>RECUPERAR SENHA </strong></h5>
                </div>
            </div>
            <div class="sessao-input-group">
                <div class="sessao-input d-flex flex-column">
                    <label class="mb-2">Email</label>
                    <input type="text" [(ngModel)]="emailRecuperacao" placeholder="Digite o email para recuperar a senha">
                    <span class="icon-group">
                        <i class="fa-regular fa-envelope"></i>
                    </span>
                </div>
            </div>
            <div class="sessao-botao-group">
                <button class="botao-login-recuperação" (click)="enviarRecuperacaoDeSenha()">Enviar código <i class="fa-solid fa-paper-plane"></i></button>
                <button class="botao-login-recuperação" (click)="abrirEsqueceuSenha()">Fazer login</button>
            </div>
            <div class="alert alert-success" [ngClass]="{'d-block' : mensagem, 'd-none': !mensagem}" role="alert">
                Siga as instruções enviadas por email!
            </div>
        </section>
     
    </aside>
    <div class="sessao-main">
        <div class="sessao-imagem">
            <!-- <h3>You should, MoveIt!</h3>
            <img src="assets/Delivery.png" alt=""> -->
            <!-- <div class="blur"></div>
            <video src="assets/video/video-croma-sgq.mp4"  autoplay></video> -->
            <img src="assets/standard.svg" alt="">
        </div>
    </div>
</section>
