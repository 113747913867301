import { Routes } from "@angular/router";
import { authGuard } from "../auth/auth-guard.guard";
import { verificarPermissoesGuard } from "../auth/verificar-permissoes.guard";
import { UsuarioComponent } from "../paginas/administrador/usuario-consulta/usuario.component";
import { UsuarioCadastroComponent } from "../paginas/administrador/usuario-cadastro/usuario-cadastro.component";


export const routesAdministrador: Routes = [
     { path: "usuario-cadastro/:id", component:UsuarioCadastroComponent},
     { path: "permissao-alteracao/:id", loadComponent: () => import("../paginas/administrador/permissoes-alteracao/permissoes-alteracao.component").then(d => d.PermissoesAlteracaoComponent) }
]
