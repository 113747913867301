<app-container-component tituloPage="Gestão de melhoria" subtituloPage="Melhorias" [iconeVolta]="false">
    <div class="row gap-2 justify-content-around">
        @for (gestao of gestaoMelhorias; track $index) {
        <div class="col-12  col-sm-5  col-md-5 col-lg-3">
            <div class="card">
                <div class="swiper mySwiper-{{gestao.id}}">
                    <div class="swiper-wrapper">
                        @for (imagem of [{imagemAntes:gestao.pathImgAntes},{imagemDepois:gestao.pathImgDepois}]; track imagem) {
                        <div class="swiper-slide">
                            <div class="text-center header-imagem">{{imagem.imagemAntes ? 'Imagem Antes' : "Imagem Depois"}}</div>
                            <figure class="image-container">
                                <a [href]="imagem.imagemAntes || imagem.imagemDepois" target="_blank" rel="noopener noreferrer">
                                    <img [src]="imagem.imagemAntes || imagem.imagemDepois" class="card-img-top" alt="...">
                                </a>
                            </figure>
                        </div>
                        }
                    </div>
                    <div class="swiper-pagination d-flex justify-content-center my"></div>
                </div>
          
                <div class="card-body" (mouseenter)="toggleExpand(true,gestao.id)"
                    (mouseleave)="toggleExpand(false,gestao.id)">
                    <h5 class="card-title">Registro: {{gestao.id}}</h5>
                    <p class="card-text mb-0"><strong>Descrição</strong></p>
                    <p class="card-text" [class.expanded]="expanded">{{gestao.descMelhoria}}</p>
                    @if(idHover === gestao.id){
                    <button class="read-more" (click)="visualizar(gestao.id)"><strong> <i class="fa-regular fa-eye"></i> Ver mais</strong></button>
                    }
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between"><strong>Data: </strong>
                        <span>{{gestao.dataCadastro | date: 'dd/MM/yyyy'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between"><strong>Investimento: </strong>
                        <span>{{gestao.investimento }}</span>
                    </li>
                </ul>
                <div class="card-body d-flex">
                    @if(temPermissao()){
                        <a class="card-link" (click)="editarGestaoMelhoria(gestao.id)"><i
                            class="fa-solid fa-pen-to-square"></i>
                        Editar</a>
                    }
                    <a class="card-link" (click)="visualizar(gestao.id)"><i class="fa-regular fa-eye"></i> Ver mais</a>
                </div>
            </div>
        </div>
        }
    </div>

</app-container-component>