<app-container-component tituloPage="ANÁLISE CRÍTICA - VISUALIZAÇÃO" [desativarSubTitulo]="false">
    <div class="container container-custom">
        <div class="text-center mb-4">
            <h3 class="fw-bold">ANÁLISE CRÍTICA 2024</h3>
            <h5 class="text-muted">Detalhes da Análise</h5>
        </div>

        <div class="card p-4">
            <ul class="list-group list-group-flush">
                <li class="list-group-item"><strong>Identificação: </strong> Análise Crítica - 2024</li>
                <li class="list-group-item"><strong>Participantes: </strong> Roberto Bertoli, Rudinei Andrioli, Erivaldo Santos, Álvaro Banzzato, Sílvia Koga, Éricles Egídio, Márcio Oliveira, Rogério Galdino.</li>
                <li class="list-group-item"><strong>Período em análise: </strong> Ano de 2023</li>
                <li class="list-group-item"><strong>Data de realização: </strong> 09/02/2024</li>
            </ul>
        </div>
        
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="card p-4">
                    <h5>Satisfação do Cliente</h5>
                    <p><strong>IQF: </strong> 95%</p>
                    <p><strong>Pesquisa de Satisfação: </strong> 92,82%</p>
                    <p><strong>NPS: </strong> 95,38%</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card p-4">
                    <h5>Objetivos de Qualidade</h5>
                    <p><strong>Satisfação Clientes: </strong> Meta: 90% | Resultado: 95%</p>
                    <p><strong>Capacitação de Pessoas: </strong> Meta: 30h/ano | Resultado: 35,4h</p>
                    <p><strong>Produto Não Conforme: </strong> Meta: 0,53% | Resultado: 0,626%</p>
                </div>
            </div>
        </div>
        
        <div class="card p-4 mt-3">
            <h5>Auditorias</h5>
            <ul class="list-group list-group-flush">
                <li class="list-group-item"><strong>Auditoria Interna ISO 9001:</strong> 05 e 06/06/2023 - 4 não conformidades</li>
                <li class="list-group-item"><strong>Auditoria Externa:</strong> 11 a 14/07/2023 - 1 não conformidade</li>
                <li class="list-group-item"><strong>Auditorias de Clientes:</strong> 8 auditorias realizadas com resultado satisfatório</li>
            </ul>
        </div>
        
        <div class="card p-4 mt-3">
            <h5>Desempenho de Processos</h5>
            <p><strong>Indicadores de Performance: </strong> 81% de atendimento</p>
            <p><strong>Reclamações: </strong> 19 registradas | Índice: 0,02% (meta: 0,04%)</p>
        </div>

        <div class="card p-4 mt-3">
            <h5>Conclusões e Melhorias</h5>
            <p><strong>Política da Qualidade:</strong> Adequada conforme revisão 03</p>
            <p><strong>Necessidade de Recursos:</strong> Relacionadas no plano de ação</p>
        </div>
    </div>
</app-container-component>