import { InformacaoDocumentadaService } from './../../../../services/acoes/informacao-documentada/informacao-documentada.service';
import { Component, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { InformacaoDocumentadaConsulta } from '../../../../model/informacao-documentada/informacao-documentada-consulta';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { StatusInfoDoc } from '../../../../enum/status-informacao-documentada';
import { InformacaoDocumentadaHomeComponent } from '../informacao-documentada-home/informacao-documentada-home.component';

@Component({
  selector: 'app-informacao-documentada-lista',
  standalone: true,
  imports: [ContainerComponentComponent, FormsModule, CommonModule, PaginatorComponent, InformacaoDocumentadaHomeComponent],
  templateUrl: './informacao-documentada-lista.component.html',
  styleUrl: './informacao-documentada-lista.component.scss'
})
export class InformacaoDocumentadaListaComponent implements OnInit {
  listaDeInformacaoDocumentada:InformacaoDocumentadaConsulta[]=[];
  valorPesquisado = "";
  paginaAtual: number = 0;
  totalElementos: number = 0;
  itensPorPagina: number[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.itensPorPagina[0];
  StatusInfoDoc = StatusInfoDoc;

  constructor(
    private informacaoDocumentadaService:InformacaoDocumentadaService,
    private router:Router,
  ){
    
  }
  ngOnInit(): void {
    this.consultaTodosInformacaoDocumentada();
  }

  public consultaPorIdentificacao(){
    this.informacaoDocumentadaService.consultarPorIdentificacao(this.valorPesquisado).subscribe({
      next:(res:any)=>{
        this.listaDeInformacaoDocumentada = res;
      },error:(error)=>{},complete:()=>{}
    });
  }

  public onKeyEnterPress(event: KeyboardEvent){
    if(event.key === 'Enter'){
      if(this.valorPesquisado != ''){
        this.consultaPorIdentificacao();
      }else{
        this.consultaTodosInformacaoDocumentada();
      }
    }
  }

  public consultaTodosInformacaoDocumentada(){
    this.informacaoDocumentadaService.consultaPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next:(res:any)=>{
        this.totalElementos = res.page.totalElements;
        this.listaDeInformacaoDocumentada = res.content;
      },error:(error)=>{},complete:()=>{}
    });
  }

  public verHistorioDeInformacaoDocumentada(id:number){
    this.router.navigate([`acoes/informacao-documentada-historico/${id}`]);
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultaTodosInformacaoDocumentada();
  }
}
