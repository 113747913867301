import { EmisorEventoGeralService } from './utils/emisorEventoGeral.service';
import { LoginService } from './services/login/login.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './template/header/header.component';
import { FooterComponent } from './template/footer/footer.component';
import { AreaFormatterPipe } from './pipe/AreaFormatter.pipe';
import { PesoFormatterPipe } from './pipe/pesoFormatter.pipe';
import { LoadingComponent } from "./componentes/loading/loading.component";
import { ViewImagensModalComponent } from "./componentes/view-imagens-modal/view-imagens-modal.component";
import { ImagemRenderizada } from './interface/imagenRenderizada';
import 'moment/locale/pt-br';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    ViewImagensModalComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [AreaFormatterPipe, PesoFormatterPipe]
})
export class AppComponent implements OnInit {
  title = 'sgq-croma';
  carregando: boolean = false;
  constrole: boolean = false;
  show = false;
  linkImagem!: ImagemRenderizada;
  private carregandoSubscription!: Subscription;

  constructor(
    private loginService: LoginService,
    private emisorEventoGeralService: EmisorEventoGeralService,
    private cdr: ChangeDetectorRef
  ) {

  }

  OnDestroy() {
    if (this.carregandoSubscription) {
      this.carregandoSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    moment.locale("pt-br");
    this.escutarEventoLoading();
    this.observarMudanca();
    this.receberLink();
    this.receberClose();
    this.verificarSeExisteLogin();

   
  }

  public verificarSeExisteLogin() {
    this.constrole = this.loginService.estaLogado();

  }

  public observarMudanca() {
    this.carregandoSubscription = this.emisorEventoGeralService.controleHeader.subscribe(res => {
      this.constrole = res;
      this.cdr.detectChanges();
    });
  }

  public escutarEventoLoading() {
    this.carregandoSubscription = this.emisorEventoGeralService.carregando.subscribe(res => {
      this.carregando = res;
      this.cdr.detectChanges();
  
    });
  }

  receberLink() {
    this.carregandoSubscription = this.emisorEventoGeralService.abrirImagem.subscribe({
      next: (res: ImagemRenderizada) => {
        this.linkImagem = res;
        this.show = true;
      }
    });
  }

  receberClose() {
    this.carregandoSubscription = this.emisorEventoGeralService.fecharModal.subscribe(res => {
      if (res) {
        this.show = false;
      }
    });
  }
}
