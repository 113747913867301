import { AfterViewInit } from '@angular/core';
//Modulos angular
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
//Modulos material/Angular
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
//model/interface/enum
//Componentes
import { EngDeterminacaoProcessoKtlComponent } from './eng-determinacao-processo-ktl/eng-determinacao-processo-ktl.component';
import { EngDeterminacaoProcessoFosfatoComponent } from './eng-determinacao-processo-fosfato/eng-determinacao-processo-fosfato.component';
import { EngDeterminacaoProcessoLiquidaComponent } from './eng-determinacao-processo-liquida/eng-determinacao-processo-liquida.component';
import { EngAnaliseCapabilidadeComponent } from '../eng-analise-capabilidade/eng-analise-capabilidade.component';
import { EngCriterioAutoInspecaoComponent } from '../eng-criterio-auto-inspecao/eng-criterio-auto-inspecao.component';
import { EngDispositivoIsencaoComponent } from '../eng-dispositivo-isencao/eng-dispositivo-isencao.component';
import { EngDispositivoPinturaComponent } from '../eng-dispositivo-pintura/eng-dispositivo-pintura.component';
import { EngMedicaoCamadaComponent } from '../eng-medicao-camada/eng-medicao-camada.component';
import { EngPadraoDefeitosComponent } from '../eng-padrao-defeitos/eng-padrao-defeitos.component';
import { EngPadraoEmbalagemComponent } from '../eng-padrao-embalagem/eng-padrao-embalagem.component';
import { EngPadraoIsencaoComponent } from '../eng-padrao-isencao/eng-padrao-isencao.component';
import { EngPadraoMontagemComponent } from '../eng-padrao-montagem/eng-padrao-montagem.component';
import { EngDeterminacaoProcessoPoComponent } from './eng-determinacao-processo-po/eng-determinacao-processo-po.component';
//Serviços
import { DeterminacaoProcessoFosfatoService } from '../../../../services/engenharia/processo/eng-determinacao-processo-fosfato.service';
import { DeterminacaoProcessoKtlService } from '../../../../services/engenharia/processo/eng-determinacao-processo-ktl.service';
import { DeterminacaoProcessoLiquidaService } from '../../../../services/engenharia/processo/eng-determinacao-processo-liquida.service';
import { DeterminacaoProcessoPoService } from '../../../../services/engenharia/processo/eng-determinacao-processo-po.service';
import { ProdutoDetalheService } from '../../../../services/engenharia/produto/produto-detalhe.service';
// import { RotaProcessosService } from '../../../../services/rotas-services/rota-processos.service';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { MatTabsModule } from '@angular/material/tabs';


@Component({
  selector: 'app-eng-determinacao-registrar',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    EngDispositivoPinturaComponent,
    EngPadraoIsencaoComponent,
    EngPadraoMontagemComponent,
    EngDispositivoIsencaoComponent,
    EngCriterioAutoInspecaoComponent,
    EngMedicaoCamadaComponent,
    EngPadraoDefeitosComponent,
    EngPadraoEmbalagemComponent,
    EngAnaliseCapabilidadeComponent,
    EngDeterminacaoProcessoFosfatoComponent,
    EngDeterminacaoProcessoKtlComponent,
    EngDeterminacaoProcessoPoComponent,
    EngDeterminacaoProcessoLiquidaComponent,
    ContainerComponentComponent,
    MatTabsModule
  ],
  templateUrl: './eng-determinacao-registrar.component.html',
  styleUrl: './eng-determinacao-registrar.component.scss'
})
export class EngDeterminacaoRegistrarComponent implements OnInit {
  cdProduto: string = '';
  idProcesso: string = '';
  produtoConsutado: any;
  processoSelecionado: any;
  listaDeProcessoForm: any[] = [];
  listaDeProcesso: string[] = [];
  produto: any = {}
  panelOpenState: any;
  steps: { [key: number]: number } = {};
  consultaDispositivoFlag: boolean = false;
  consultaPadraoMontagemFlag: boolean = false;
  consultaDispositivoIsencaoFlag: boolean = false;
  consultaPadraoIsencaoFlag: boolean = false;
  consultaCriteriosDeAutoInspecaoFlag: boolean = false;
  consultaMedicaoCamadaFlag: boolean = false;
  consultaPadraoEmbalagemFlag: boolean = false;
  consultaPadraoDefeitosFlag: boolean = false;
  consultaPadraoAnaliseDeCapabilidadeFlag: boolean = false;
  listaDeDeterminacaoFosfatoDoProduto: any[] = [];
  listaDeDeterminacaoKtlDoProduto: any[] = [];
  listaDeDeterminacaoPoDoProduto: any[] = [];
  listaDeDeterminacaoLiquidaDoProduto: any[] = [];
  @ViewChild('tabList') tabList!: ElementRef;

  constructor(
    private engProdutoDetalheService: ProdutoDetalheService,
    private determinacaoProcessoFosfatoService: DeterminacaoProcessoFosfatoService,
    private determinacaoProcessoKtlService: DeterminacaoProcessoKtlService,
    private determinacaoProcessoPoService: DeterminacaoProcessoPoService,
    private determinacaoProcessoLiquidaService: DeterminacaoProcessoLiquidaService,
    private snackBarService: SnackBarService,
    private activeRouter: ActivatedRoute,
    private routerLink: Router,
    private cdr: ChangeDetectorRef

  ) {



  }

  



  ngOnInit(): void {
    sessionStorage.setItem('processo', '0');
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = params.cdProduto;
      this.produto = { cdProduto: this.cdProduto, idProcesso: this.idProcesso }
      // RotaProcessosService.emitirProcesso.emit(this.produto);
      this.consultaProduto();
    });
  }

  public consultaProduto() {
    this.limparDados();
    this.resetarConsultas();
    this.engProdutoDetalheService.consultarProduto(parseInt(this.cdProduto)).subscribe({
      next: (res) => {
        this.produtoConsutado = res;
        console.log(res)
      }, error: (error) => {

      }, complete: () => {
        this.separarProcesso();
      }
    });
  }

  public limparDados() {
    this.listaDeProcesso = [];
    this.listaDeProcessoForm = [];
    this.listaDeDeterminacaoFosfatoDoProduto = [];
    this.listaDeDeterminacaoKtlDoProduto = [];
    this.listaDeDeterminacaoPoDoProduto = [];
    this.listaDeDeterminacaoLiquidaDoProduto = [];
  }

  public consultarDerminacaoDoProduto() {
    this.listaDeProcessoForm.forEach(e => {
      switch (e.processo.toUpperCase()) {
        case 'KTL':
          this.consultarDeterminacaoKtlProcesso();
          break
        case 'FOSFATO':
          this.consultarDeterminacaoFosfatoProcesso();
          break
        case 'PÓ':
          this.consultarDeterminacaoPoProcesso();
          break
        case 'LIQUIDA':
        case 'LÍQUIDA':
        case 'LIQUIDA ALTA TEMPERATURA':
          this.consultarDeterminacaoLiquidaProcesso();
          break
      }
    });
  }

  public receberProcessoSalvoPo(event: any) {
    if (event) {
      this.consultarDeterminacaoPoProcesso();
    }
  }

  public receberProcessoSalvoKtl(event: any) {
    if (event) {
      this.consultarDeterminacaoKtlProcesso();
    }
  }

  public receberProcessoSalvoLiquida(event: any) {
    if (event) {
      this.consultarDeterminacaoLiquidaProcesso();
    }
  }

  public receberProcessoSalvoFosfato(event: any) {
    if (event) {
      this.consultarDeterminacaoFosfatoProcesso();
    }
  }

  public montarListaParaForm() {
    let contador = 1;
    this.listaDeProcesso.forEach(p => {
      let obTemp: any = [];
      obTemp = { id: contador, processo: p.trim(), cdProduto: parseInt(this.cdProduto) },
        contador++
      this.listaDeProcessoForm.push(obTemp);
    });
    this.consultarDerminacaoDoProduto();
  }

  public separarProcesso() {
    this.resetarConsultas();
    this.listaDeProcesso = this.produtoConsutado.processo?.split('+')!;
    this.montarListaParaForm();
  }


  public verificarSeExiste(): boolean {
    return this.listaDeDeterminacaoFosfatoDoProduto.length > 0 || this.listaDeDeterminacaoPoDoProduto.length > 0 || this.listaDeDeterminacaoKtlDoProduto.length > 0 ||
      this.listaDeDeterminacaoLiquidaDoProduto.length > 0;
  }

  verificarSeNaoTem(item: any): boolean {
    switch (item.processo.toUpperCase()) {
      case 'KTL':
        return this.listaDeDeterminacaoKtlDoProduto.length <= 0;
      case 'PÓ':
        return this.listaDeDeterminacaoPoDoProduto.length <= 0;
      case 'PO':
        return this.listaDeDeterminacaoPoDoProduto.length <= 0;
      case 'LIQUIDA ALTA TEMPERATURA':
        return this.listaDeDeterminacaoLiquidaDoProduto.length <= 0;
      case 'LIQUIDA':
        return this.listaDeDeterminacaoLiquidaDoProduto.length <= 0;
      case 'LIQUÍDA':
        return this.listaDeDeterminacaoLiquidaDoProduto.length <= 0;
      case 'FOSFATO':
        return this.listaDeDeterminacaoFosfatoDoProduto.length <= 0;
      default:
        return false;
    }
  }


  public consultarDeterminacaoFosfatoProcesso() {
    if (this.cdProduto) {
      this.determinacaoProcessoFosfatoService.consultarDeterminacaoProcessoPorCdProduto(parseInt(this.cdProduto)).subscribe({
        next: (res: any) => {
          this.listaDeDeterminacaoFosfatoDoProduto = res;
        }, error: (error) => {
        }, complete: () => {
        }
      });
    }
  }

  public consultarDeterminacaoKtlProcesso() {
    if (this.cdProduto) {
      this.determinacaoProcessoKtlService.consultarDeterminacaoProcessoPorCdProduto(parseInt(this.cdProduto)).subscribe({
        next: (res: any) => {
          this.listaDeDeterminacaoKtlDoProduto = res;
        }, error: (error) => {
        }, complete: () => {

        }
      });
    }
  }

  public consultarDeterminacaoPoProcesso() {
    if (this.cdProduto) {
      this.determinacaoProcessoPoService.consultarDeterminacaoProcessoPorCdProduto(parseInt(this.cdProduto)).subscribe({
        next: (res: any) => {
          this.listaDeDeterminacaoPoDoProduto = res;

        }, error: (error) => {

        }, complete: () => {

        }
      });
    }
  }

  public consultarDeterminacaoLiquidaProcesso() {
    if (this.cdProduto) {
      this.determinacaoProcessoLiquidaService.consultarDeterminacaoProcessoPorCdProduto(parseInt(this.cdProduto)).subscribe({
        next: (res: any) => {
          this.listaDeDeterminacaoLiquidaDoProduto = res;
        }, error: (error) => {

        }, complete: () => {

        }
      });
    }
  }

  public colocarIdProcessoStorage(idProcesso: number, processo: string) {
    this.resetarConsultas();
    let processoTemp = sessionStorage.getItem('processo');
    if (processoTemp) {
      sessionStorage.setItem('processo', JSON.stringify(0));
    }
    sessionStorage.setItem('tipo', JSON.stringify(processo))
    sessionStorage.setItem('processo', JSON.stringify(idProcesso));
    this.verificarProcesso()

  }
  public verificarProcesso() {
    this.processoSelecionado = JSON.parse(sessionStorage.getItem('tipo')!.toString());
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
    this.consultarDerminacaoDoProduto();
  }


  trackById(index: number, item: any): any {
    return item.id;
  }



  setPanelOpenState(id: number | null): void {
    this.panelOpenState = id;
  }
  setStep(id: number, step: number): void {
    this.steps[id] = step;
  }

  // Métodos prevStep, nextStep e outros conforme necessário
  prevStep(id: number) {
    if (this.steps[id] > 0) {
      this.steps[id]--;
    }
  }

  nextStep(id: number) {
    this.steps[id]++;
  }


  emitirConsulta(event: any) {
    // Mapeamento de ações para atualizar as flags
    const mapeamentoFlags: Record<string, () => void> = {
      'DISPOSITIVO DE PINTURA': () => this.consultaDispositivoFlag = true,
      'PADRÃO DE MONTAGEM': () => this.consultaPadraoMontagemFlag = true,
      'DISPOSITIVO DE ISENÇÃO': () => this.consultaDispositivoIsencaoFlag = true,
      'PADRÃO DE ISENÇÃO': () => this.consultaPadraoIsencaoFlag = true,
      'CRITÉRIOS DE AUTOINSPEÇÃO': () => this.consultaCriteriosDeAutoInspecaoFlag = true,
      'MEDIÇÃO DE CAMADA': () => this.consultaMedicaoCamadaFlag = true,
      'PADRÃO DE EMBALAGEM': () => this.consultaPadraoEmbalagemFlag = true,
      'PADRÃO DE DEFEITOS': () => this.consultaPadraoDefeitosFlag = true,
      'ANÁLISE DE CAPABILIDADE': () => this.consultaPadraoAnaliseDeCapabilidadeFlag = true,
      // Adicione outros casos conforme necessário
    };

    // Reseta todas as flags antes de configurar a nova
    this.resetarConsultas();

    // Obtém a função correspondente no mapeamento
    const atualizarFlag = mapeamentoFlags[event.tab.textLabel];

    // Se existir uma função correspondente, executa
    if (atualizarFlag) {
      atualizarFlag();
    }
    // switch (event.tab.textLabel) {
    //   case 'DISPOSITIVO DE PINTURA':
    //     this.consultaDispositivoFlag = true;
    //     break
    //   case 'PADRÃO DE MONTAGEM':
    //     this.consultaPadraoMontagemFlag = true;
    //     break
    //   case 'DISPOSITIVO DE ISENÇÃO':
    //     this.consultaDispositivoIsencaoFlag = true;
    //     break
    //   case 'PADRÃO DE ISENÇÃO':
    //     this.consultaPadraoIsencaoFlag = true;
    //     break
    //   case 'CRITÉRIOS DE AUTOINSPEÇÃO':
    //     this.consultaCriteriosDeAutoInspecaoFlag = true;
    //     break
    //   case 'MEDIÇÃO DE CAMADA':
    //     this.consultaMedicaoCamadaFlag = true;
    //     break
    //   case 'PADRÃO DE EMBALAGEM':
    //     this.consultaPadraoEmbalagemFlag = true
    //     break
    //   case 'PADRÃO DE DEFEITOS':
    //     this.consultaPadraoDefeitosFlag = true;
    //     break
    //   case 'ANÁLISE DE CAPABILIDADE':
    //     this.consultaPadraoAnaliseDeCapabilidadeFlag = true;
    //     break
    //   case 'DADOS':
    //     this.resetarConsultas()
    //     break
    //   default:
    //     this.resetarConsultas()
    // }
  }

  public resetarConsultas() {
    this.consultaDispositivoFlag = false;
    this.consultaPadraoMontagemFlag = false;
    this.consultaDispositivoIsencaoFlag = false;
    this.consultaPadraoIsencaoFlag = false;
    this.consultaCriteriosDeAutoInspecaoFlag = false;
    this.consultaMedicaoCamadaFlag = false;
    this.consultaPadraoEmbalagemFlag = false;
    this.consultaPadraoDefeitosFlag = false;
    this.consultaPadraoAnaliseDeCapabilidadeFlag = false;
  }




}
