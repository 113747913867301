import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ContainerComponentComponent } from '../../../../componentes/container-component/container-component.component';
import { NormaPinturaService } from '../../../../services/controles/norma-pintura/norma-pintura.service';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { Router, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NormaPintura } from '../../../../model/controles/norma-pintura/norma-pintura';
import { NormaPinturaConsultaDTO } from '../../../../dto/norma-pintura/normaPinturaConsultaDTO';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-norma-pintura-consultar',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent,MatTooltipModule ,PaginatorComponent, RouterLink, FormsModule],
  templateUrl: './norma-pintura-consultar.component.html',
  styleUrl: './norma-pintura-consultar.component.scss'
})
export class NormaPinturaConsultarComponent implements OnInit {

  @Input() desativarHeader: boolean = true;
  valorPesquisado: string = '';
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100];
  totalPorPagina: number = this.quantidadesPorPagina[0];
  listaDeNormasPintura: Array<NormaPinturaConsultaDTO> = [];

  normaPinturaService = inject(NormaPinturaService);
  router = inject(Router);
  private loginService = inject(LoginService);

  ngOnInit(): void {
    this.consultarNormaPintura();
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.consultaPorValorPesquisado(event); // Chama o método buscar se a tecla Enter for pressionada
      if(this.valorPesquisado.trim() == ""){
        this.consultarNormaPintura();
      }
    }else{
      if(this.valorPesquisado.trim() == ""){
        this.consultarNormaPintura();
      }
    }
  }

  public editarNormaPintura(idNormaPinura:number){
    this.router.navigate([`/controles/cadastrar-norma-pintura/${idNormaPinura}`])
  }

  public consultaPorValorPesquisado(event:any){
    this.normaPinturaService.consultarPorMergeNormaPintura(this.valorPesquisado).subscribe({
      next: (res) => {
        this.listaDeNormasPintura = res;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public consultarNormaPintura() {
    this.normaPinturaService.consultarNormaPinturaPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res:any) => {
        this.listaDeNormasPintura = res.content;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public onPageChange(event: any) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultarNormaPintura();
  }
  
  public temPermissao(){
    return this.loginService.temPermissao(['ROLE_CONT_EDIT']);
  }

  public temPermissaoExcluir(){
    return this.loginService.temPermissao(['ROLE_CONT_ADMIN']);
  }

}
