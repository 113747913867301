import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SnackBarService } from '../../../utils/snack-bar.service';

@Component({
  selector: 'app-menu-administracao',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, MatSidenavModule, RouterOutlet, MatTooltipModule],
  templateUrl: './menu-administracao.component.html',
  styleUrl: './menu-administracao.component.scss'
})
export class MenuAdministracaoComponent {
  open: boolean = true;
  mostrarMenu = false;
  menuFixo = true;
  menusVisiveis: { [key: string]: boolean } = {};
  @ViewChild('drawer') drawer!: MatDrawer;
  isMobile = window.innerWidth < 1000;
  cdr = inject(ChangeDetectorRef);
  snackBarService = inject(SnackBarService);





  ngAfterViewInit(): void {
    this.restaurarEstadoMenu();
    this.verificarSeEhMobile();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.restaurarEstadoMenu();
  }


  private restaurarEstadoMenu() {
    this.menuFixo = JSON.parse(localStorage.getItem("menuFixado")! || 'false');
    if (this.drawer) {
      if (this.menuFixo) {
        this.drawer.open();
        this.open = false;
      }
    }
  }

  public verificarSeEhMobile() {
    this.isMobile = window.innerWidth <= 1000;
    if (this.isMobile) {
      localStorage.setItem("menuFixado", "false");
      this.open = true;
      this.menuFixo = false;
    }
  }


  public getMenuItensAdministracao() {
    return [
      { label: 'Parâmetros  de controle', link: ['/administrador/administrador'] },
      { label: 'Certificado', link: ['/administrador/certificado'] },
    ];
  }

  public getMenuItensUsuarios() {
    return [
      { label: 'Usuários cadastrados', link: ['/administrador/usuario-cadastrados'] },
      { label: 'Cadastrar usuário', link: ['/administrador/usuario-cadastro'] },
    ];
  }

  public getMenuItensPermissoes() {
    return [
      { label: 'Permissões', link: ['/administrador/permissoes'] },
    ];
  }

  public getMenuItensNotificacoes() {
    return [
      { label: 'Cadastrar Notificações', link: ['/administrador/notificacoes'] },
      // { label: 'CADASTRAR', link: ['recebimento-registrar'] },
    ];
  }

  public mudarIcone() {
    if (!this.menuFixo) {
      this.open = !this.open
    }
  }

  abrirMenu(menu: string) {
    this.menusVisiveis[menu] = !this.menusVisiveis[menu];
  }

  fixarMenu(): void {
    this.menuFixo = !this.menuFixo; // Alterna o estado do menu fixo
    localStorage.setItem("menuFixado", this.menuFixo.toString());
  }

  clicarMenu(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    }
  }

  clicarFechar(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    } else {
      this.snackBarService.openSnackBar("Para recolher o menu você precisa clicar no icone desfixar menu", "my-snack-bar-warning")
    }
  }

}
