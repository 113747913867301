<div class="row gap-2">
    <div class="row col-12 sessao-header justify-content-center align-items-center">
        <div class="col-1" routerLink="/qualidade/home" (click)="voltar()">
            <i class="fa-solid fa-circle-left"></i>
        </div>
        <div class=" col-11 p-0">
            <h2 class="mb-0 text-white text-center">Gestão a Vista</h2>
        </div>
    </div>
    <div #swiperContainer class="swiper mySwiper mb-3">
        <div class="swiper-wrapper">
            @for(grafico of listaDeGraficos;track grafico.id){
            @if(grafico.indicador != "Custo da Não Qualidade"){
            <div class="swiper-slide position-relative">
                <div class="row col-12 sombra p-2">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 text-start text-lg-start">
                        <h2><span>{{grafico.indicador}}</span>
                        </h2>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-4 col-xxl-4 text-start text-lg-center">
                        <h3><span>Setor - {{grafico.processo}}</span>
                        </h3>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-4 col-xxl-2 text-start text-lg-center">
                        <h3><span>Meta - {{grafico.resultados[grafico.resultados.length - 1].metaAno}}</span>
                        </h3>
                    </div>
                    <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2 text-start text-lg-end">
                        <h3><span>Ano - {{grafico.resultados[grafico.resultados.length - 1].ano}}</span></h3>
                    </div>
                    <div class="col-12">
                        @if(grafico.tipo == "Negativo"){
                            @if(grafico.tipo == "Positivo"){
                            <img class="icon" [src]="grafico.resultados[grafico.resultados.length - 1].media >= grafico.resultados[grafico.resultados.length - 1].metaAno 
                                    ? 'assets/icons/64x64/analise.png' 
                                    : 'assets/icons/64x64/negativo.png'" alt="">
                            }@if(grafico.tipo == "Negativo"){
                            <img class="icon" [src]="grafico.resultados[grafico.resultados.length - 1].media <= grafico.resultados[grafico.resultados.length - 1].metaAno 
                                        ? 'assets/icons/64x64/analise.png' 
                                        : 'assets/icons/64x64/negativo.png'" alt="">
                            }
                            }
                    </div>
                </div>
                <div class="sessao-graficos">
                    <div class="chart grafico-height sombra">
                        <canvas class="p-3" [id]="'myChart' + grafico.id"></canvas>
                    </div>
                </div>
            </div>
            }
            }
        </div>
        <div class="autoplay-progress">
            <svg viewBox="0 0 48 48">
                <circle cx="24" cy="24" r="20"></circle>
            </svg>
            <span></span>
        </div>
        <!-- <div class="swiper-pagination mt-5 d-flex justify-content-center"></div> -->
    </div>
</div>