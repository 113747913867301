import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AzureTokenService {

  private url = `${environment.url}/azure-token-container`;
  constructor(
    private http: HttpClient
  ) {

  }

  public consultarTokens(): Observable<any>{
    return this.http.get(`${this.url}`);
  }
}
