<app-container-component tituloPage="Usuario dados" subtituloPage="Dados">
    <div class="row">
        <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
            <label for="text" class="form-label m-0 col-form-label-sm">Nome Usuario:</label>
            <input type="text" [disabled]="controleEdicao" class="form-control form-control-sm"
                [value]="this.usuario.nome">
        </div>
        <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3 ">
            <label for="text" class="form-label m-0 col-form-label-sm">Email:</label>
            <input type="text" [disabled]="controleEdicao" class="form-control form-control-sm"
                [value]="this.usuario.sub">
        </div>
        <div class="form-check form-switch col-12 col-md-6 col-lg-6 col-xxl-3 d-flex align-items-center ms-2">
            <input class="form-check-input me-2" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                (change)="salvarStatuMenu($event)" [checked]="this.menuFixo">
            <label class="form-check-label" for="flexSwitchCheckChecked">Fixar menu</label>
        </div>
    </div>
</app-container-component>