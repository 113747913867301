import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { EngDeterminacaoKtl } from '../../../model/engenharia/EngDeterminacaoKtl';


@Injectable({
  providedIn: 'root'
})
export class DeterminacaoProcessoKtlService {

  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }


  public salvarDeterminacaoProcesso(engDeterminacaoProcesso: EngDeterminacaoKtl): Observable<EngDeterminacaoKtl> {
    return this.http.post<EngDeterminacaoKtl>(`${this.url}/eng-determinacao-ktl`, engDeterminacaoProcesso);
  }
  public editarDeterminacaoProcesso(engDeterminacaoProcesso: EngDeterminacaoKtl): Observable<EngDeterminacaoKtl> {
    return this.http.put<EngDeterminacaoKtl>(`${this.url}/eng-determinacao-ktl`, engDeterminacaoProcesso);
  }

  public consultarDeterminacaoProcessoPorCdProduto(cdProduto:number): Observable<EngDeterminacaoKtl[]> {
    return this.http.get<EngDeterminacaoKtl[]>(`${this.url}/eng-determinacao-ktl/${cdProduto}`);
  }

  public consultarDeterminacaoProcessoPorCdProduto2(cdProduto:number): Observable<EngDeterminacaoKtl> {
    return this.http.get<EngDeterminacaoKtl>(`${this.url}/eng-determinacao-ktl/${cdProduto}`);
  }

  public deletarDeterminacaoProcesso(id:number): Observable<EngDeterminacaoKtl> {
    return this.http.delete<EngDeterminacaoKtl>(`${this.url}/eng-determinacao-ktl/${id}`);
  }

}
