import { Msa } from "../model/engenharia/msa";

export class MsaContinuoAlteracaoDTO {
    id: number = 0;
    identificacao: string = "";
    analista: string = "";
    instrumento: string = "";
    procOperacional: string = "";
    grafGageRr: string = "";
    imgTwayAnova: string = "";
    anTolerancia: string = "";
    imgEstTolerancia: string = "";
    ndc: string = "";
    imgGageLb: string = "";
    anLinearidade: string = "";
    anVies: string = "";
    anSistema: string = "";
    obs: string = "";
    arquivoMpj: string = "";

    constructor(msa?: Msa) {
        if (msa) {
            this.id = msa.id;
            this.identificacao = msa.identificacao;
            this.analista = msa.analista;
            this.instrumento = msa.instrumento;
            this.procOperacional = msa.procOperacional;
            this.grafGageRr = msa.grafGageRr;
            this.imgTwayAnova = msa.imgTwayAnova;
            this.anTolerancia = msa.anTolerancia;
            this.imgEstTolerancia = msa.imgEstTolerancia;
            this.ndc = msa.ndc;
            this.imgGageLb = msa.imgGageLb;
            this.anLinearidade = msa.anLinearidade;
            this.anVies = msa.anVies;
            this.anSistema = msa.anSistema;
            this.obs = msa.obs;
            this.arquivoMpj = msa.arquivoMpj;
        }
    }
}


