import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { EngDeterminacaoPo } from '../../../model/engenharia/EngDeterminacaoPo';



@Injectable({
  providedIn: 'root'
})
export class DeterminacaoProcessoPoService {

  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }


  public salvarDeterminacaoProcesso(engDeterminacaoProcesso: EngDeterminacaoPo): Observable<EngDeterminacaoPo> {
    return this.http.post<EngDeterminacaoPo>(`${this.url}/eng-determinacao-po`, engDeterminacaoProcesso);
  }
  public editarDeterminacaoProcesso(engDeterminacaoProcesso: EngDeterminacaoPo): Observable<EngDeterminacaoPo> {
    return this.http.put<EngDeterminacaoPo>(`${this.url}/eng-determinacao-po`, engDeterminacaoProcesso);
  }

  public consultarDeterminacaoProcessoPorCdProduto(cdProduto:number): Observable<EngDeterminacaoPo> {
    return this.http.get<EngDeterminacaoPo>(`${this.url}/eng-determinacao-po/${cdProduto}`);
  }

  public deletarDeterminacaoProcesso(id:number): Observable<EngDeterminacaoPo> {
    return this.http.delete<EngDeterminacaoPo>(`${this.url}/eng-determinacao-po/${id}`);
  }

}
