
import { CommonModule } from '@angular/common';
import { Component, inject, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';

import { EngAnaliseCapabilidadeService } from '../../../../services/engenharia/processo/eng-analise-capabilidade.service';
import { environment } from '../../../../../environments/environment';
import { EngAnaliseCapabalidade } from '../../../../model/engenharia/EngAnaliseCapabalidade';
import { GerenciadorDeArquivosService } from '../../../../utils/gerenciador-de-arquivos.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { FormsDataService } from '../../../../utils/forms-data.service';
import { ActivatedRoute } from '@angular/router';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { LoginService } from '../../../../services/login/login.service';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';

@Component({
  selector: 'app-eng-analise-capabilidade',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BotaoComponent, MatCardModule, ContainerComponentComponent],
  templateUrl: './eng-analise-capabilidade.component.html',
  styleUrl: './eng-analise-capabilidade.component.scss'
})
export class EngAnaliseCapabilidadeComponent {

  formulario!: FormGroup;
  formularioEditar!: FormGroup;
  listaDocumentoSelecionado: any[] = [];
  documentoEDesenhos: EngAnaliseCapabalidade[] = [];
  valoresStringList: any[] = [];
  lista: string[] = [];
  controle: boolean = false;
  idProcesso: string = '';
  cdProduto: number = 0;
  listaDeDados: any[] = [];
  urlImagem = environment.url;
  contactFormAnaliseCapabilidade!: FormGroup;
  produto: any = {}
  @Input() dados: any;
  @Input() fazerConsulta: boolean = false;
  private loginService = inject(LoginService);


  constructor(
    private engAnaliseCapabilidadeService: EngAnaliseCapabilidadeService,
    private gerenciadorDeArquivosService: GerenciadorDeArquivosService,
    private snackBarService: SnackBarService,
    private formDataService: FormsDataService,
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    private emisorEventoGeralService: EmisorEventoGeralService,
    public dialog: MatDialog,


  ) {

  }
  ngOnInit(): void {
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = parseInt(params.cdProduto);
      this.produto = { cdProduto: this.cdProduto }
      this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
    });

    this.contactFormAnaliseCapabilidade = this.formBuilder.group({
      contactsAnaliseCapabilidade: this.formBuilder.array([this.createContact()])
    });
    this.iniciarFormulario();
    this.formularioEditar = this.formBuilder.group({
      id: [''],
      estudo: [''],
      analise: [''],
      resultado: [''],
    });

  }


  ngOnChanges(changes: SimpleChanges) {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    if (changes['fazerConsulta'] && changes['fazerConsulta'].currentValue) {
      this.limparDados();
      this.consultarTodosOsDocumentos();
    }
  }

  public iniciarFormulario() {
    this.formulario = this.formBuilder.group({
      id: [''],
      estudo: [''],
      analise: [''],
      resultado: [''],
    });
  }

  public pegarDocumentoEDesenho(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let itemJaExiste = this.listaDocumentoSelecionado.some(
        (arquivo) => arquivo.name === file.name
      );
      if (!itemJaExiste) {
        this.listaDocumentoSelecionado.push(file);
      }
    }
  }

  public editarDesenho() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.engAnaliseCapabilidadeService.editarAnaliseCapacidade(this.formularioEditar.value).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar(
          'Atualizado com sucesso',
          'my-snack-bar-sucess'
        );
      },
      error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar editar");
      },
      complete: () => {
        this.formulario.reset();
        this.controle = false;
        this.consultarTodosOsDocumentos();
        this.emisorEventoGeralService.carregando.emit(false);
      },
    });
  }

  public salvar(formData: FormData) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.engAnaliseCapabilidadeService.salvarAnaliseCapacidade(formData).subscribe({
      next: (res) => {

      },
      error: (err) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.openSnackBar(err, 'my-snack-bar-error');
      },
      complete: () => {
        this.consultarTodosOsDocumentos();
        this.limparFormulario()
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.openSnackBar('Analise de capabilidade criada com sucesso', 'my-snack-bar-sucess');
      },
    });

  }



  public consultarTodosOsDocumentos() {
    this.documentoEDesenhos = [];
    this.engAnaliseCapabilidadeService
      .consultarAnaliseCapacidade(this.cdProduto, parseInt(this.idProcesso))
      .subscribe({
        next: (res) => {
          this.documentoEDesenhos = res;
        },
        error: (erro) => {
          console.log(erro);
        },
        complete: () => {

        },
      });
  }


  public verificarComoAbrir(fileType: string, objectUrl: string, nomeArquivo: string) {
    if (fileType === 'application/pdf' || fileType.startsWith('image/')) {
      this.gerenciadorDeArquivosService.abrirArquivo(objectUrl, fileType);
    } else {
      this.gerenciadorDeArquivosService.baixarArquivo(objectUrl, nomeArquivo);
    }
  }
  public editarDocumentoOuDesenho(arquivo: any) {
    this.controle = true;
    this.formularioEditar.patchValue(arquivo)

  }

  public deletarDocumentoOuDesenho(nomeArquivo: string, id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: nomeArquivo, textoConteudo: "padrão de embalagem" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.deletar(id);
        };
      }
    });
  }

  private deletar(id: number) {
    this.engAnaliseCapabilidadeService
      .deletarAnaliseCapacidade(id)
      .subscribe({
        next: (res) => {
          this.snackBarService.openSnackBar(
            res.mensagem,
            'my-snack-bar-sucess'
          );
        },
        error: (erro) => {
          this.snackBarService.openSnackBar(erro, 'Erro ao tentar deletar');
          this.emisorEventoGeralService.carregando.emit(false);
        },
        complete: () => {
          this.consultarTodosOsDocumentos();
          this.emisorEventoGeralService.carregando.emit(false);
        },
      });

  }

  public cancelarEdicao() {
    this.controle = false;
    this.formularioEditar.reset();
  }

  createContact(): FormGroup {
    return this.formBuilder.group({
      estudo: [''],
      analise: [''],
      resultado: [''],
    });
  }

  addContact(): void {
    const contactsAnaliseCapabilidade = this.contactsAnaliseCapabilidade;
    contactsAnaliseCapabilidade.push(this.createContact());
  }

  removeContact(index: number): void {
    const contactsAnaliseCapabilidade = this.contactFormAnaliseCapabilidade.get('contactsAnaliseCapabilidade') as FormArray;
    contactsAnaliseCapabilidade.removeAt(index);
    this.listaDocumentoSelecionado.splice(index, 1);
  }

  get contactsAnaliseCapabilidade() {
    return this.contactFormAnaliseCapabilidade.get('contactsAnaliseCapabilidade') as FormArray;
  }



  onSubmit() {
    const formData = new FormData();
    this.contactFormAnaliseCapabilidade.value['contactsAnaliseCapabilidade'].forEach((elemento: any, index: number) => {
      const file = this.listaDocumentoSelecionado[index];
      formData.append('file', file, file.name);
      if (!file || !file.name) {
        this.snackBarService.openSnackBar('Sem imagem selecionada', "my-snack-bar-erro")
      } else {
        this.listaDeDados.push({
          "estudo": elemento.estudo,
          "analise": elemento.analise,
          "resultado": elemento.resultado,
          "cdProduto": this.cdProduto,
          "cdProcesso": this.idProcesso,
        });
      }

    });
    formData.append('engAnaliseCapabilidade', JSON.stringify(this.listaDeDados[0])),
      this.formDataService.criarFormData("engAnaliseCapabilidade", this.listaDocumentoSelecionado, this.listaDeDados)
    this.salvar(formData);
  }




  public limparFormulario() {
    this.listaDocumentoSelecionado = [];
    this.listaDeDados = [];
    this.contactFormAnaliseCapabilidade.reset();
    this.formulario.reset();
    this.contactsAnaliseCapabilidade.reset();
    this.contactsAnaliseCapabilidade.clear();

  }

  public limparDados() {
    this.listaDocumentoSelecionado = [];
    this.listaDeDados = [];
  }

  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_ENG_EDIT']);
  }

  public temPermissaoExcluir() {
    return this.loginService.temPermissao(['ROLE_ENG_ADMIN']);
  }
}
