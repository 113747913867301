import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { BookService } from '../../../../services/producao/book.service';
import { Produto } from '../../../../model/engenharia/produto-model';
import { EngPadraoDefeitoService } from '../../../../services/engenharia/processo/eng-padrao-defeito.service';
import { CommonModule } from '@angular/common';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";

@Component({
  selector: 'app-padrao-defeitos',
  standalone: true,
  imports: [
    CommonModule,
    SwiperComponentComponent
],
  templateUrl: './padrao-defeitos.component.html',
  styleUrl: './padrao-defeitos.component.scss'
})
export class PadraoDefeitosComponent implements OnInit {
  padraoDefeitoProcesso1: any[] = []
  padraoDefeitoProcesso2: any[] = []
  imagemNaoCadastrada1 = false;
  imagemNaoCadastrada2 = false;
  produto: Produto = new Produto();
  dados: any = {};
  tabAtiva: string = '';
  @ViewChild("swiperPadraoDefeito1") swiperPadraoDefeito1!: ElementRef;
  @ViewChild("swiperPadraoDefeito2") swiperPadraoDefeito2!: ElementRef;
  swiper?: Swiper

  constructor(
    private padraoDefeitoService: EngPadraoDefeitoService,
    private bookService: BookService,
    private cdr: ChangeDetectorRef,
  ) {

  }


  ngOnInit(): void {
    this.receberDadosDoProduto();
  }

  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.padraoDefeitoProcesso1 = [];
        this.padraoDefeitoProcesso2 = [];
        this.produto = res;
        this.tabAtiva = '';
        if (this.produto.nomeProduto) {
          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          this.consultarPadraoEmbalagemProcesso1Inicial(this.dados.numeroProcesso1);
 
        }
      },
    });
  }


  consultarPadraoEmbalagemProcesso1Inicial(processo: any) {
    this.imagemNaoCadastrada1 = false;
    this.padraoDefeitoProcesso1 = [];
    this.padraoDefeitoService.consultarPadraoDefeito(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoDefeitoProcesso1 = res;
      },
      complete: () => {
        this.definirTabAtiva()
        this.iniciarImagemCarregada();
        this.consultarPadraoEmbalagemProcesso2Inicial(this.dados.numeroProcesso2);
      },
      error: (e) => {
        this.consultarPadraoEmbalagemProcesso2Inicial(this.dados.numeroProcesso2);
        this.padraoDefeitoProcesso1 = [];
        this.imagemNaoCadastrada1 = true;
      }
    });
  }

  consultarPadraoEmbalagemProcesso2Inicial(processo: any) {
    this.imagemNaoCadastrada2 = false;
    this.padraoDefeitoProcesso2 = [];
    this.padraoDefeitoService.consultarPadraoDefeito(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoDefeitoProcesso2 = res;
      },
      error: (e) => {
        this.padraoDefeitoProcesso2 = [];
        this.imagemNaoCadastrada2 = true;
      },
      complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2();
      }
    });
  }
  consultarPadraoEmbalagemProcesso1Click(processo: any) {
    this.imagemNaoCadastrada1 = false;
    this.padraoDefeitoProcesso1 = []
    this.padraoDefeitoService.consultarPadraoDefeito(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoDefeitoProcesso1 = res;
      },error: (e) => {
        this.padraoDefeitoProcesso1 = [];
        this.imagemNaoCadastrada1 = true;
      },complete: () => {
        this.definirTabAtiva()
        this.iniciarImagemCarregada();
      }
    });
  }

  consultarPadraoEmbalagemProcesso2Click(processo: any) {
    this.imagemNaoCadastrada2 = false;
    this.padraoDefeitoProcesso2 = [];
    this.padraoDefeitoService.consultarPadraoDefeito(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoDefeitoProcesso2 = res;
      },
      error: (e) => {
        this.padraoDefeitoProcesso2 = [];
        this.imagemNaoCadastrada2 = true;
      },
      complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2();
      }
    });
  }

  private definirTabAtiva() {
    if (this.padraoDefeitoProcesso1.length > 0) {
      this.tabAtiva = 'processo1';
    } else if (this.padraoDefeitoProcesso2.length > 0) {
      this.tabAtiva = 'processo2';
    }
  }

  private iniciarImagemCarregada() {
    this.iniciarImagemCarregadaParaArray(this.padraoDefeitoProcesso1);
  }

  private iniciarImagemCarregada2() {
    this.iniciarImagemCarregadaParaArray(this.padraoDefeitoProcesso2);
  }

  private iniciarImagemCarregadaParaArray(array: any[]) {
    array.forEach((e: any) => {
      if (!e.imagemCarregada) {
        e.imagemCarregada = false;
      }
    });
  }
}
