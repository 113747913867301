import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { RetrabalhoService } from '../../../../services/engenharia/retrabalho/retrabalho.service';
import { Produto } from '../../../../model/engenharia/produto-model';
import { BookService } from '../../../../services/producao/book.service';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { CommonModule } from '@angular/common';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";


@Component({
  selector: 'app-padrao-retrabalho',
  standalone: true,
  imports: [CommonModule, SwiperComponentComponent],
  templateUrl: './padrao-retrabalho.component.html',
  styleUrl: './padrao-retrabalho.component.scss'
})
export class PadraoRetrabalhoComponent {
  padraoRetrabalho: any = [];
  imagemNaoCadastrada = false;
  produto: Produto = new Produto();
  dados: any = {};
  @ViewChild("swiperPadraoRetrabalho") swiperPadraoRetrabalho!: ElementRef
  swiper?: Swiper

  constructor(
    private retrabalhoService: RetrabalhoService,
    private bookService: BookService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }

  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.padraoRetrabalho = [];
        this.produto = res;
        if (this.produto.nomeProduto) {
          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          this.consultarPadraoRetrabalho(this.dados.numeroProcesso1);
        }
      },
    });
  }

  consultarPadraoRetrabalho(processo: any) {
    this.padraoRetrabalho = [];
    this.imagemNaoCadastrada = false;
    this.padraoRetrabalho = [];
    this.retrabalhoService.consultarRetrabalho(this.produto.cdProduto).subscribe({
      next: (res) => {
        this.padraoRetrabalho = res;
      },
      complete: () => {
        this.iniciarImagemCarregada();
      },
      error: (e) => {
        this.imagemNaoCadastrada = true;
      }
    });
  }

  public iniciarImagemCarregada() {
    this.padraoRetrabalho.forEach((e: any) => {
      if (e.imagemCarregada) {
        return e.imagemCarregada
      } else {
        e.imagemCarregada = false
      }
    });
  }
}
