<app-container-component tituloPage="ALERTA DA QUALIDADE" [desativarSubTitulo]="false" [removerElevacao]="true" [removerPadding]="true">
  <div
    class="row justify-content-center justify-content-lg-evenly justify-content-xl-center  align-items-center p-3  gap-2">
    <div class="col-12 col-md-12  col-lg-3 col-xl-2 card-contador sessao-produto" id="semRetorno" (click)="navegarPorStatusSemRetorno('Sem retorno')">
      <label class="text-center">ALERTAS SEM <br> RETORNO</label>
      <p class="text-center">{{this.alertasSemRetorno.length}}</p>
    </div>
    <div class="col-12 col-md-12  col-lg-3 col-xl-2 card-contador sessao-produto" id="emAndamento" (click)="navegarPorStatusEmAndamento('Em andamento')">
      <label class="text-center"> ALERTAS EM <br> ANDAMENTO</label>
      <p class="text-center">{{this.alertasEmAndamento.length}}</p>
    </div>
    <div class="col-12 col-md-12  col-lg-3 col-xl-2 card-contador sessao-produto" id="emProcesso" (click)="navegarPorStatusEmProcesso('Em Processo')">
      <label class="text-center"> ALERTAS EM <br> PROCESSO</label>
      <p class="text-center">{{this.alertasEmProcesso.length}}</p>
    </div>
    <div class="col-12 col-md-12  col-lg-3 col-xl-2 card-contador sessao-produto" id="emRetirada" (click)="navegarPorStatusRetirar('Retirar')">
      <label class="text-center"> ALERTAS <br> RETIRADA</label>
      <p class="text-center">{{this.alertasRetirar.length}}</p>
    </div>
    <div class="col-12 col-md-12  col-lg-3 col-xl-2 card-contador sessao-produto" id="finalizado" (click)="navegarPorStatusFinalizado('Finalizado')">
      <label class="text-center"> ALERTAS <br> FINALIZADO</label>
      <p class="text-center">{{this.alertasFinalizado.length}}</p>
    </div>
  </div>
</app-container-component>
  <app-alerta-lista ></app-alerta-lista>