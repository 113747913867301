<app-container-component [tituloPage]="tituloPagina" [subtituloPage]="subtituloPagina">
  <div class="row d-flex flex-row-reverse">
    <div class="col-12 col-lg-4 mb-2">
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="nomeProduto" placeholder="Digite o código do produto"
          (input)="salvarValor()" aria-label="Consultar produto" aria-describedby="button-addon2"
          (keyup.enter)="consultaProdutoPorNome()"> <!-- Aqui está o evento Enter -->
        <button class="btn btn-color" type="button" id="button-addon2" (click)="consultaProdutoPorNome()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
  </div>

  @if (prodNaoEncontrado) {
  <div class="alert alert-danger" role="alert">
    PRODUTO NÃO CADASTRADO NO BOOK!
  </div>
  }

  <div class="row">

    <app-dados-produto class="col-12 col-lg-6"></app-dados-produto>

    <app-determinacao-processo class="col-12 col-lg-6"></app-determinacao-processo>

    <app-imagens-produto class="col-12 col-lg-6"></app-imagens-produto>
    
    <app-dispositivo-de-pintura class="col-12 col-lg-6"></app-dispositivo-de-pintura>

    <app-padrao-montagem class="col-12 col-lg-6"></app-padrao-montagem>

    <app-criterio-de-inspecao class="col-12 col-lg-6"></app-criterio-de-inspecao>

    <app-dispositivo-isencao class="col-12 col-lg-6"></app-dispositivo-isencao>

    <app-padrao-isencao class="col-12 col-lg-6"></app-padrao-isencao>

    <app-medicao-camada class="col-12 col-lg-6"></app-medicao-camada>

    <app-padrao-defeitos class="col-12 col-lg-6"></app-padrao-defeitos>

    <app-padrao-embalagem class="col-12 col-lg-6"></app-padrao-embalagem>

    <app-padrao-retrabalho class="col-12 col-lg-6"></app-padrao-retrabalho>
  </div>
</app-container-component>

<div class="mb-5 mt-5">

</div>