import { Routes } from "@angular/router";
import { UsuarioCadastroComponent } from "../paginas/administrador/usuario-cadastro/usuario-cadastro.component";
import { UsuarioComponent } from "../paginas/administrador/usuario-consulta/usuario.component";
import { CertificadoComponent } from "../paginas/administrador/certificado/certificado.component";
import { PermissoesComponent } from "../paginas/administrador/permissoes/permissoes.component";
import { NotificacoesComponent } from "../paginas/administrador/notificacoes/notificacoes.component";


export const routesAdministrador: Routes = [
     { path: "usuario-cadastro", component:UsuarioCadastroComponent},
     { path: "usuario-cadastrados", component:UsuarioComponent},
     { path: "certificado", component:CertificadoComponent},
     { path: "permissoes", component:PermissoesComponent},
     { path: "notificacoes", component:NotificacoesComponent},
     { path: "usuario-cadastrados", component:UsuarioComponent},
     { path: "usuario-cadastro/:id", component:UsuarioCadastroComponent},
     { path: "permissao-alteracao/:id", loadComponent: () => import("../paginas/administrador/permissoes-alteracao/permissoes-alteracao.component").then(d => d.PermissoesAlteracaoComponent) }
]
