import { Component, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { CommonModule } from '@angular/common';
import { SatisfacaoClienteService } from '../../../../services/controles/satisfacao-cliente/satisfacao-cliente.service';
import { SatisfacaoClientePesquisa } from '../../../../interface/controles/satisfacao-clientes/satisfacao-cliente-pesquisa';
import { SatisfacaoClienteListaComponent } from "../satisfacao-cliente-lista/satisfacao-cliente-lista.component";

@Component({
  selector: 'app-satisfacao-cliente-home',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, SatisfacaoClienteListaComponent],
  templateUrl: './satisfacao-cliente-home.component.html',
  styleUrl: './satisfacao-cliente-home.component.scss'
})
export class SatisfacaoClienteHomeComponent implements OnInit {

  lista: SatisfacaoClientePesquisa = { pesquisasCriadas: 0, pesquisasAtivas: 0, pesquisasInativas: 0 };


  constructor(
    private satisfacaoClienteService: SatisfacaoClienteService
  ) {

  }
  ngOnInit(): void {
    this.consultaTodosDisponiveis();
  }


  public consultaTodosDisponiveis() {
    this.satisfacaoClienteService.consultaStatusPesquisas().subscribe({
      next: (res) => {
        console.log(res);
        this.lista = res
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }



}
