import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { Router } from '@angular/router';
import { MsaService } from '../../../../services/engenharia/msa/msa.service';

@Component({
  selector: 'app-msa',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, PaginatorComponent],
  templateUrl: './msa.component.html',
  styleUrl: './msa.component.scss'
})
export class MsaComponent implements OnInit {

  listaDeMsa: Array<any> = []
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];

  router = inject(Router);
  msaService = inject(MsaService);


  ngOnInit(): void {
    this.consultaTodosMsaPaginados();
  }

  public consultaTodosMsaPaginados() {
    this.msaService.consultaPaginadaMsa(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.listaDeMsa = res.content;
        this.processarElementosPagina(res.page);
      }
    })
  }


  private processarElementosPagina(page: any) {
    this.totalPorPagina = page.number;
    this.paginaAtual = page.number;
    this.totalTamanho = page.totalElements;
  }

  public editarMsa(idMsa: number) {
    this.router.navigate([`/engenharia/msa-registrar/${idMsa}`])
  }

  public onPageChange(event: any) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultaTodosMsaPaginados();
  }

}
