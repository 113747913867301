import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import AuditoriaInterna from '../../../model/acoes/auditoria-Interna/AuditoriaInterna';
import { AuditoriaInternaConsultaDto } from '../../../model/acoes/auditoria-Interna/AuditoriaInternaConsultaDto';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaInternaService {

  urlApi = environment.url + "/acoes/auditoria-interna"

  constructor(
    private http: HttpClient
  ) { }

  public cadastrarAuditoriaInterna(auditoriaInterna: AuditoriaInterna): Observable<any> {
    return this.http.post<any>(this.urlApi, auditoriaInterna);
  }
  public editarAuditoriaInterna(auditoriaInterna: AuditoriaInterna): Observable<any> {
    return this.http.put<any>(`${this.urlApi}`, auditoriaInterna);
  }

  public cadastrarArquivoAuditoriaInterna(formData:FormData,idAuditoria:number): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/arquivos/${idAuditoria}`, formData);
  }
  public deletarArquivoAuditoriaInterna(idAuditoria:number,idArquivo:number): Observable<any> {
    return this.http.delete<any>(`${this.urlApi}/arquivos/${idAuditoria}/${idArquivo}`);
  }
  public cadastrarApontamentoAuditoria(auditoriaInterna: AuditoriaInterna, idAuditoria: number): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/apontamentos/${idAuditoria}`, auditoriaInterna);
  }

  public cadastrarRelatorio(idAuditoria:number,relatorio:any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/relatorios/${idAuditoria}`,relatorio);
  }


  public editarRelatorio(idRelatorio:number,relatorio:any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/relatorios/${idRelatorio}`,relatorio);
  }


  public deletarRelatorioAuditoriaInterna(idRelatorio:number): Observable<any> {
    return this.http.delete<any>(`${this.urlApi}/relatorios/${idRelatorio}`);
  }


  public consultarAuditoriaInternaPaginada(quantidadePorPagina: number, totalPorPagina: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${quantidadePorPagina}/${totalPorPagina}`);
  }
  public consultarAuditoriaPorId(idAuditoria: number): Observable<AuditoriaInternaConsultaDto> {
    return this.http.get<AuditoriaInternaConsultaDto>(`${this.urlApi}/${idAuditoria}`);
  }
  public consultarApontamentosAuditoriaPorId(idAuditoria: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/apontamentos/${idAuditoria}`);
  }



}