@if(verificarSeExiste()){
<app-container-component tituloPage="DETERMINAÇÃO DE PROCESSO" [desativarSubTitulo]="false" [removerElevacao]="true"
    [removerPadding]="true" [iconeVolta]="false">
    <mat-accordion class="d-flex flex-column gap-1">
        @if(listaDeDeterminacaoFosfatoDoProduto.length > 0){
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header  (click)="colocarIdProcessoStorage(listaDeDeterminacaoFosfatoDoProduto[0].cdProcesso,'fosfato')">
                <mat-panel-title> Processo Fosfato </mat-panel-title>
                <!-- <mat-panel-description>
                    Currently I am {{panelOpenState() ? 'open' : 'closed'}}
                </mat-panel-description> -->
            </mat-expansion-panel-header>
            <mat-tab-group mat-stretch-tabs="true" #tabList1 mat-align-tabs="center" mat-stretch-tabs
                animationDuration="200ms" dynamicHeight (selectedTabChange)="emitirConsulta($event)">
                <mat-tab label="DADOS">
                    <app-eng-determinacao-processo-fosfato
                        [formularioFosfato]="listaDeDeterminacaoFosfatoDoProduto[0]"></app-eng-determinacao-processo-fosfato>
                </mat-tab>
                @if(processoSelecionado == 'fosfato'){
                <mat-tab label="DISPOSITIVO DE PINTURA">
                    <app-eng-dispositivo-pintura
                        [fazerConsulta]="consultaDispositivoFlag"></app-eng-dispositivo-pintura>
                </mat-tab>
                <mat-tab label="PADRÃO DE MONTAGEM">

                    <app-eng-padrao-montagem [fazerConsulta]="consultaPadraoMontagemFlag"></app-eng-padrao-montagem>

                </mat-tab>
                <mat-tab label="DISPOSITIVO DE ISENÇÃO">

                    <app-eng-dispositivo-isencao
                        [fazerConsulta]="consultaDispositivoIsencaoFlag"></app-eng-dispositivo-isencao>

                </mat-tab>
                <mat-tab label="PADRÃO DE ISENÇÃO">

                    <app-eng-padrao-isencao [fazerConsulta]="consultaPadraoIsencaoFlag"></app-eng-padrao-isencao>

                </mat-tab>
                <mat-tab label="CRITÉRIOS DE AUTOINSPEÇÃO">

                    <app-eng-criterio-auto-inspecao
                        [fazerConsulta]="consultaCriteriosDeAutoInspecaoFlag"></app-eng-criterio-auto-inspecao>

                </mat-tab>
                <mat-tab label="MEDIÇÃO DE CAMADA">

                    <app-eng-medicao-camada [fazerConsulta]="consultaMedicaoCamadaFlag">
                    </app-eng-medicao-camada>

                </mat-tab>
                <mat-tab label="PADRÃO DE EMBALAGEM">

                    <app-eng-padrao-embalagem [fazerConsulta]="consultaPadraoEmbalagemFlag">
                    </app-eng-padrao-embalagem>

                </mat-tab>
                <mat-tab label="PADRÃO DE DEFEITOS">

                    <app-eng-padrao-defeitos [fazerConsulta]="consultaPadraoDefeitosFlag">
                    </app-eng-padrao-defeitos>

                </mat-tab>
                <mat-tab label="ANÁLISE DE CAPABILIDADE">

                    <app-eng-analise-capabilidade
                        [fazerConsulta]="consultaPadraoAnaliseDeCapabilidadeFlag"></app-eng-analise-capabilidade>
                </mat-tab>
                }
            </mat-tab-group>
        </mat-expansion-panel>
        }
        @if(listaDeDeterminacaoPoDoProduto.length > 0){
        <mat-expansion-panel>
            <mat-expansion-panel-header  (click)="colocarIdProcessoStorage(listaDeDeterminacaoPoDoProduto[0].cdProcesso,'po')">
                <mat-panel-title> Processo PÓ </mat-panel-title>
                <!-- <mat-panel-description>
                    Currently I am {{panelOpenState() ? 'open' : 'closed'}}
                </mat-panel-description> -->
            </mat-expansion-panel-header>
            <mat-tab-group #tabList2 mat-align-tabs="center" animationDuration="200ms" dynamicHeight
                (selectedTabChange)="emitirConsulta($event)">
                <mat-tab label="DADOS">
                    <app-eng-determinacao-processo-po
                        [dadosFormulario]="listaDeDeterminacaoPoDoProduto[0]"></app-eng-determinacao-processo-po>
                </mat-tab>
                @if(processoSelecionado == 'po'){
                <mat-tab label="DISPOSITIVO DE PINTURA">
                    <app-eng-dispositivo-pintura
                        [fazerConsulta]="consultaDispositivoFlag"></app-eng-dispositivo-pintura>
                </mat-tab>
                <mat-tab label="PADRÃO DE MONTAGEM">

                    <app-eng-padrao-montagem [fazerConsulta]="consultaPadraoMontagemFlag"></app-eng-padrao-montagem>

                </mat-tab>
                <mat-tab label="DISPOSITIVO DE ISENÇÃO">
                    <app-eng-dispositivo-isencao
                        [fazerConsulta]="consultaDispositivoIsencaoFlag"></app-eng-dispositivo-isencao>
                </mat-tab>
                <mat-tab label="PADRÃO DE ISENÇÃO">
                    <app-eng-padrao-isencao [fazerConsulta]="consultaPadraoIsencaoFlag"></app-eng-padrao-isencao>
                </mat-tab>
                <mat-tab label="CRITÉRIOS DE AUTOINSPEÇÃO">
                    <app-eng-criterio-auto-inspecao
                        [fazerConsulta]="consultaCriteriosDeAutoInspecaoFlag"></app-eng-criterio-auto-inspecao>
                </mat-tab>
                <mat-tab label="MEDIÇÃO DE CAMADA">
                    <app-eng-medicao-camada [fazerConsulta]="consultaMedicaoCamadaFlag"> </app-eng-medicao-camada>
                </mat-tab>
                <mat-tab label="PADRÃO DE EMBALAGEM">
                    <app-eng-padrao-embalagem [fazerConsulta]="consultaPadraoEmbalagemFlag">
                    </app-eng-padrao-embalagem>
                </mat-tab>
                <mat-tab label="PADRÃO DE DEFEITOS">

                    <app-eng-padrao-defeitos [fazerConsulta]="consultaPadraoDefeitosFlag">
                    </app-eng-padrao-defeitos>
                </mat-tab>
                <mat-tab label="ANÁLISE DE CAPABILIDADE">
                    <app-eng-analise-capabilidade
                        [fazerConsulta]="consultaPadraoAnaliseDeCapabilidadeFlag"></app-eng-analise-capabilidade>
                </mat-tab>
                }
            </mat-tab-group>

        </mat-expansion-panel>
        }
        @if(listaDeDeterminacaoKtlDoProduto.length > 0){
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header   (click)="colocarIdProcessoStorage(listaDeDeterminacaoKtlDoProduto[0].cdProcesso,'ktl')">
                <mat-panel-title> Processo KTL </mat-panel-title>
                <!-- <mat-panel-description>
                                Currently I am {{panelOpenState() ? 'open' : 'closed'}}
                            </mat-panel-description> -->
            </mat-expansion-panel-header>
            <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" mat-stretch-tabs animationDuration="200ms"
                dynamicHeight (selectedTabChange)="emitirConsulta($event)">

                <mat-tab label="DADOS">
                    <app-eng-determinacao-processo-ktl
                        [dadosFormulario]="listaDeDeterminacaoKtlDoProduto[0]"></app-eng-determinacao-processo-ktl>
                </mat-tab>
                @if(processoSelecionado == 'ktl'){
                <mat-tab label="DISPOSITIVO DE PINTURA">

                    <app-eng-dispositivo-pintura
                        [fazerConsulta]="consultaDispositivoFlag"></app-eng-dispositivo-pintura>

                </mat-tab>
                <mat-tab label="PADRÃO DE MONTAGEM">

                    <app-eng-padrao-montagem [fazerConsulta]="consultaPadraoMontagemFlag"></app-eng-padrao-montagem>

                </mat-tab>
                <mat-tab label="DISPOSITIVO DE ISENÇÃO">

                    <app-eng-dispositivo-isencao
                        [fazerConsulta]="consultaDispositivoIsencaoFlag"></app-eng-dispositivo-isencao>

                </mat-tab>
                <mat-tab label="PADRÃO DE ISENÇÃO">

                    <app-eng-padrao-isencao [fazerConsulta]="consultaPadraoIsencaoFlag"></app-eng-padrao-isencao>

                </mat-tab>
                <mat-tab label="CRITÉRIOS DE AUTOINSPEÇÃO">

                    <app-eng-criterio-auto-inspecao
                        [fazerConsulta]="consultaCriteriosDeAutoInspecaoFlag"></app-eng-criterio-auto-inspecao>

                </mat-tab>
                <mat-tab label="MEDIÇÃO DE CAMADA">

                    <app-eng-medicao-camada [fazerConsulta]="consultaMedicaoCamadaFlag">
                    </app-eng-medicao-camada>

                </mat-tab>
                <mat-tab label="PADRÃO DE EMBALAGEM">

                    <app-eng-padrao-embalagem [fazerConsulta]="consultaPadraoEmbalagemFlag">
                    </app-eng-padrao-embalagem>

                </mat-tab>
                <mat-tab label="PADRÃO DE DEFEITOS">

                    <app-eng-padrao-defeitos [fazerConsulta]="consultaPadraoDefeitosFlag">
                    </app-eng-padrao-defeitos>

                </mat-tab>
                <mat-tab label="ANÁLISE DE CAPABILIDADE">

                    <app-eng-analise-capabilidade
                        [fazerConsulta]="consultaPadraoAnaliseDeCapabilidadeFlag"></app-eng-analise-capabilidade>

                </mat-tab>
                }
            </mat-tab-group>
        </mat-expansion-panel>
        }
        @if(listaDeDeterminacaoLiquidaDoProduto.length > 0){
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)" >
            <mat-expansion-panel-header (click)="colocarIdProcessoStorage(listaDeDeterminacaoLiquidaDoProduto[0].cdProcesso,'liquida')">
                <mat-panel-title> PROCESSO LIQUÍDA </mat-panel-title>
                <!-- <mat-panel-description>
                            Currently I am {{panelOpenState() ? 'open' : 'closed'}}
                        </mat-panel-description> -->
            </mat-expansion-panel-header>
            <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" mat-stretch-tabs animationDuration="200ms"
                dynamicHeight (selectedTabChange)="emitirConsulta($event)">
                <mat-tab label="DADOS">
                    <app-eng-determinacao-processo-liquida
                        [formularioLiquida]="listaDeDeterminacaoLiquidaDoProduto[0]"></app-eng-determinacao-processo-liquida>
                </mat-tab>
                @if(processoSelecionado == 'liquida'){
                <mat-tab label="DISPOSITIVO DE PINTURA">

                    <app-eng-dispositivo-pintura
                        [fazerConsulta]="consultaDispositivoFlag"></app-eng-dispositivo-pintura>

                </mat-tab>
                <mat-tab label="PADRÃO DE MONTAGEM">

                    <app-eng-padrao-montagem [fazerConsulta]="consultaPadraoMontagemFlag"></app-eng-padrao-montagem>

                </mat-tab>
                <mat-tab label="DISPOSITIVO DE ISENÇÃO">

                    <app-eng-dispositivo-isencao
                        [fazerConsulta]="consultaDispositivoIsencaoFlag"></app-eng-dispositivo-isencao>

                </mat-tab>
                <mat-tab label="PADRÃO DE ISENÇÃO">

                    <app-eng-padrao-isencao [fazerConsulta]="consultaPadraoIsencaoFlag"></app-eng-padrao-isencao>

                </mat-tab>
                <mat-tab label="CRITÉRIOS DE AUTOINSPEÇÃO">

                    <app-eng-criterio-auto-inspecao
                        [fazerConsulta]="consultaCriteriosDeAutoInspecaoFlag"></app-eng-criterio-auto-inspecao>

                </mat-tab>
                <mat-tab label="MEDIÇÃO DE CAMADA">

                    <app-eng-medicao-camada [fazerConsulta]="consultaMedicaoCamadaFlag">
                    </app-eng-medicao-camada>

                </mat-tab>
                <mat-tab label="PADRÃO DE EMBALAGEM">

                    <app-eng-padrao-embalagem [fazerConsulta]="consultaPadraoEmbalagemFlag">
                    </app-eng-padrao-embalagem>

                </mat-tab>
                <mat-tab label="PADRÃO DE DEFEITOS">

                    <app-eng-padrao-defeitos [fazerConsulta]="consultaPadraoDefeitosFlag">
                    </app-eng-padrao-defeitos>

                </mat-tab>
                <mat-tab label="ANÁLISE DE CAPABILIDADE">

                    <app-eng-analise-capabilidade
                        [fazerConsulta]="consultaPadraoAnaliseDeCapabilidadeFlag"></app-eng-analise-capabilidade>

                </mat-tab>
                }
            </mat-tab-group>
        </mat-expansion-panel>
        }
    </mat-accordion>
</app-container-component>
}
@if(!verificarSeExiste()){
@if(listaDeProcessoForm.length > 0){
<app-container-component tituloPage="DETERMINAÇÃO DE PROCESSO REGISTRAR" subtituloPage="Processos" [iconeVolta]="false">
    <mat-accordion class="d-flex flex-column gap-1" multi="false">
        @for (item of listaDeProcessoForm; track item.id) {
        @if(verificarSeNaoTem(item)){
        @switch (true) {
        @case(item.processo.includes('KTL')){
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
                <mat-panel-title> {{item.processo}} </mat-panel-title>
            </mat-expansion-panel-header>
            <app-eng-determinacao-processo-ktl
                (processoSalvo)="receberProcessoSalvoKtl($event)"></app-eng-determinacao-processo-ktl>
        </mat-expansion-panel>
        }
        @case (item.processo.includes('PÓ')){
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
                <mat-panel-title> {{item.processo}} </mat-panel-title>
            </mat-expansion-panel-header>
            <app-eng-determinacao-processo-po
                (processoSalvo)="receberProcessoSalvoPo($event)"></app-eng-determinacao-processo-po>
        </mat-expansion-panel>
        }
        @case(item.processo.includes('LÍQUIDA')){
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
                <mat-panel-title> {{item.processo}} </mat-panel-title>
            </mat-expansion-panel-header>
            <app-eng-determinacao-processo-liquida (processoSalvo)="receberProcessoSalvoLiquida($event)">
            </app-eng-determinacao-processo-liquida>
        </mat-expansion-panel>
        }
        @case(item.processo.includes('LÍQUIDA ALTA TEMPERATURA')){
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
                <mat-panel-title> {{item.processo}} </mat-panel-title>
            </mat-expansion-panel-header>
            <app-eng-determinacao-processo-liquida
                (processoSalvo)="receberProcessoSalvoLiquida($event)"></app-eng-determinacao-processo-liquida>
        </mat-expansion-panel>
        }
        @case(item.processo.includes('FOSFATO')){
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
                <mat-panel-title> {{item.processo}} </mat-panel-title>
            </mat-expansion-panel-header>
            <app-eng-determinacao-processo-fosfato
                (processoSalvo)="receberProcessoSalvoFosfato($event)"></app-eng-determinacao-processo-fosfato>
        </mat-expansion-panel>
        }
        }
        }
        }
    </mat-accordion>
</app-container-component>
}
}