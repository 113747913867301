<app-container-component tituloPage="Novo Apontamento" subtituloPage="Adicionar apontamento à auditoria">
    <div class="apontamento-container">
        @if(auditoria.apontamentos.length > 0){
        <div class="apontamentos-lista">
            <h3>Apontamentos Cadastrados</h3>
            @for (apontamento of itensPaginados; track $index) {
            <div class="apontamento-card">
                <div class="apontamento-header">
                    <span class="apontamento-tipo">
                        @if(apontamento.apontamento === 'Oportunidade de Melhoria'){
                        <i class="fas fa-lightbulb"></i>
                        }
                        @if(apontamento.apontamento === 'Nao Conformidade'){
                        <i class="fas fa-exclamation-triangle"></i>
                        }
                        @if(apontamento.apontamento === 'Observações'){
                        <i class="fas fa-sticky-note"></i>
                        }
                        {{ apontamento.apontamento }}
                    </span>
                </div>
                <div class="apontamento-body">
                    <p><strong>Requisito Normativo:</strong> {{ apontamento.requisitoNormativo }}</p>
                    <p><strong>Evidência Objetiva:</strong> {{ apontamento.evidenciaObjetiva }}</p>
                </div>
            </div>
            }
            <app-paginator-sistemico
            [itemsPorPagina]="3" [itens]="auditoria.apontamentos" 
            (itensPaginados)="receberItensPaginados($event)">
            </app-paginator-sistemico>
        </div>
        } <!-- Formulário -->
        <form class="apontamento-form" [formGroup]="apontamentoForm" (ngSubmit)="salvarApontamento()">
            <label for="tipoApontamento">Tipo de Apontamento</label>
            <select id="tipoApontamento" formControlName="apontamento" required>
                <option value="Oportunidade de Melhoria">Oportunidade de Melhoria</option>
                <option value="Nao Conformidade">Não Conformidade</option>
                <option value="Observações">Observações</option>
            </select>

            <label for="requisitoNormativo">Requisito Normativo</label>
            <textarea id="requisitoNormativo" formControlName="requisitoNormativo" required></textarea>

            <label for="evidenciaObjetiva">Evidência Objetiva</label>
            <textarea id="evidenciaObjetiva" formControlName="evidenciaObjetiva" required></textarea>

            <button type="submit" class="btn salvar" (click)="salvarApontamento()">Salvar Apontamento</button>
        </form>
    </div>
</app-container-component>