import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../../utils/snack-bar.service';

@Component({
  selector: 'app-modal-clone-produto',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './modal-clone-produto.component.html',
  styleUrl: './modal-clone-produto.component.scss'
})
export class ModalCloneProdutoComponent {

  cdProdutoDestino!: number;
  inativarProduto:boolean = true;

  constructor(
    private dialogRef: MatDialogRef<ModalCloneProdutoComponent>,
    private snackbarService: SnackBarService,
  ){

  }

  public clonarProduto(){
    if(this.cdProdutoDestino == undefined){
      this.snackbarService.openSnackBar('Digite o código do produto!', 'my-snack-bar-erro');
    }else if(this.cdProdutoDestino < 1){
      this.snackbarService.openSnackBar('O código do produto deve ser um numero positivo!', 'my-snack-bar-erro');
    }else{
      this.dialogRef.close({'cdProdutoDestino': this.cdProdutoDestino, 'inativarProduto': this.inativarProduto});
    }
  }

  public closeModal(){
    this.dialogRef.close();
  }
  

}
