<app-container-component [header]="false" subtituloPage="Alterar senha">
    <div class="row">
        <div class="col-12 col-lg-4">
            <label class="form-label">Senha atual:</label>
            <div class="input-group">
                <input [type]="showPassword ? 'text': 'password'" class="form-control form-control-sm" [(ngModel)]="senhaAtual">
                <button class="btn btn-outline-secondary btn-sm" type="button" (click)="visualizarSenha()">
                    <i [class]="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                </button>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <label class="form-label">Nova senha:</label>
            <div class="input-group">
                <input [type]="showPassword ? 'text': 'password'" class="form-control form-control-sm" [(ngModel)]="novaSenha">
                <button class="btn btn-outline-secondary btn-sm" type="button" (click)="visualizarSenha()">
                    <i [class]="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                </button>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <label class="form-label">Confirmação de senha:</label>
            <div class="input-group">
                <input [type]="showPassword ? 'text': 'password'" class="form-control form-control-sm" [(ngModel)]="confirmaSenha" (keyup)="habilitarBotao()">
                <button class="btn btn-outline-secondary btn-sm" type="button" (click)="visualizarSenha()">
                    <i [class]="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                </button>
            </div>
        </div>
        <div class="col-12 col-lg-4 mt-4">
            <button class="btn btn-sm btn-primary" [disabled]="btnDisable" (click)="validarSenha()" >Alterar</button>
        </div>
    </div>
</app-container-component>