import { Component, inject, Input, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SnackBarService } from '../../../utils/snack-bar.service';
import { Usuario } from '../../../model/usuario/usuario';

@Component({
  selector: 'app-redefinir-senha',
  standalone: true,
  imports: [
    ContainerComponentComponent,
    FormsModule,
    CommonModule
  ],
  templateUrl: './redefinir-senha.component.html',
  styleUrl: './redefinir-senha.component.scss'
})
export class RedefinirSenhaComponent implements OnInit{

  @Input() usuarioRecebido:any;
  private usuarioService = inject(UsuarioService);
  senhaAtual: string = '';
  novaSenha: string = '';
  confirmaSenha: string= '';
  showPassword: boolean = false;
  btnDisable: boolean = true;
  snackbarService = inject(SnackBarService);
  usuario: Usuario;

  constructor() {
    this.usuario = new Usuario();
  }

  ngOnInit(): void {
    this.consultarUsuarioPorEmailOuMatricula();
  }

  public consultarUsuarioPorEmailOuMatricula(){
    let valor = this.getIdentificadorUsuario();
    if(valor){
      this.usuarioService.consultarUsuarioPorEmailOuMatricula(valor).subscribe({
        next:(res)=>{
          this.usuario = res;
        },
        error:(e)=>{
          this.snackbarService.tratarErro(e, 'Falha ao consultar usuário!');
        } 
      });
    }
  }

  private getIdentificadorUsuario(): string | undefined {
    if (this.usuarioRecebido?.sub && this.usuarioRecebido?.matricula) {
      console.warn('Usuário possui ambos email e matricula. Usando email como padrão.');
    }
    return this.usuarioRecebido.sub || this.usuarioRecebido.matricula;
  }

  visualizarSenha() {
    this.showPassword = !this.showPassword;
  }

  public habilitarBotao(){
    if(this.senhaAtual !== '' && this.novaSenha !== '' && this.confirmaSenha !== ''){
      this.btnDisable = false;
    }else{
      this.btnDisable = true;
    }
  }

  public validarSenha(){
    if(this.novaSenha !== this.confirmaSenha){
      this.snackbarService.openSnackBar('As senhas não são iguais!', 'my-snack-bar-erro');
    }else{
      this.alterarSenha()
    }
  }

  private alterarSenha() {
    this.usuarioService.alterarSenha(this.usuario.id, this.senhaAtual, this.novaSenha).subscribe({
      next:(res)=>{
        this.snackbarService.openSnackBar('Senha alterada com sucesso!', 'my-snack-bar-sucess');
      },
      error:(e)=>{
        this.snackbarService.tratarErro(e, 'Falha ao tentar alterar senha!');
      },
      complete:()=>{
        this.senhaAtual = '';
        this.novaSenha = '';
        this.confirmaSenha = '';
        this.btnDisable = true;
      }
    });
  }

}
