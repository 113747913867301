import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EngDeterminacaoPo } from '../../../../../../model/engenharia/EngDeterminacaoPo';

@Component({
  selector: 'app-form-estacionaria',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-estacionaria.component.html',
  styleUrl: './form-estacionaria.component.scss'
})
export class FormEstacionariaComponent {
  @Input() processoDoPo:EngDeterminacaoPo = new EngDeterminacaoPo;

}
