<mat-drawer-container class="example-container h-100">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <div class="row mb-1 mt-1 ms-1">
            <div class="d-none d-lg-flex col-1">
                @if(menuFixo){
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Desfixar menu"></i></div>
                }@else {
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack-slash" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Fixar menu"></i></div>
                }
            </div>
            <div class="col-10  col-md-11 d-flex justify-content-start align-items-center">
                <a class="logo d-flex justify-content-center align-items-center gap-2">DESENVOLVIMENTO</a>
            </div>
            <div class="col-2  z-1 d-flex align-items-center  justify-content-end" (click)="clicarMenu(drawer)"
                (click)="mudarIcone()">
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div class="group">
            <svg viewBox="0 0 24 24" aria-hidden="true" class="search-icon">
                <g>
                    <path
                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z">
                    </path>
                </g>
            </svg>
            <input class="input" type="text" placeholder="codigo peça..." #valor (keyup)="consultarProduto($event)"
                [(ngModel)]="produto" />
        </div>
        <div class="menu-content">
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['dadosProduto']}" (click)="abrirMenu('dadosProduto')">

                    <span class="font-titulo"><i class="fa-solid fa-ruler-combined"></i> <span class="ms-2">Dados do
                            produto</span></span>
                    @if(this.mostrarMenu){
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['dadosProduto']}"></i></span>
                    }
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['dadosProduto']}">
                    @for (link of linksEngenharia; track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>

            @if(this.mostrarMenu){
            <ul class="item-menu">
                <a *ngFor="let link of listaProcessos" [routerLink]="link.link">
                    <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                        (click)="clicarMenu(drawer)" (click)="mudarIcone()">
                        <span class="font-titulo"><i class="fa-solid fa-shapes"></i> <span class="ms-2">Determinação do
                                processo</span> </span>
                    </div>
                </a>
            </ul>
            <ul class="item-menu">
                <a *ngFor="let link of listaOpcaoExtra" [routerLink]="link.link">
                    <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                        (click)="clicarMenu(drawer)" (click)="mudarIcone()">
                        <span class="font-titulo"><i class="fa-regular fa-square-plus"></i> <span class="ms-2">Operações
                                extras</span></span>
                    </div>
                </a>
            </ul>
            <ul class="item-menu">
                <a *ngFor="let link of listaCriterioAceita" [routerLink]="link.link">
                    <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                        (click)="clicarMenu(drawer)" (click)="mudarIcone()">
                        <span class="font-titulo"> <span><i class="fa-solid fa-thumbs-up"></i><span
                                    class="ms-2">Critério de
                                    aceitação</span> </span></span>
                    </div>
                </a>
            </ul>
            <ul class="item-menu">
                <a *ngFor="let link of listaNorma" [routerLink]="link.link">
                    <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                        (click)="clicarMenu(drawer)" (click)="mudarIcone()">
                        <span class="font-titulo"><i class="fa-solid fa-file-circle-check"></i> <span
                                class="ms-2">Norma</span></span>
                    </div>
                </a>
            </ul>
            <ul class="item-menu">
                <a *ngFor="let link of listaCliente" [routerLink]="link.link">
                    <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                        (click)="clicarMenu(drawer)" (click)="mudarIcone()">
                        <span class="font-titulo"><i class="fa-solid fa-user-check"></i> <span class="ms-2">Critério
                                cliente</span></span>
                    </div>
                </a>
            </ul>
            <ul class="item-menu">
                <a *ngFor="let link of listaLicaoAprendida" [routerLink]="link.link">
                    <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                        (click)="clicarMenu(drawer)" (click)="mudarIcone()">
                        <span class="font-titulo"><i class="fa-solid fa-book-bookmark"></i><span class="ms-2">Lições
                                aprendidas</span></span>
                    </div>
                </a>
            </ul>
            <ul class="item-menu">
                <a *ngFor="let link of listaRetrabalho" [routerLink]="link.link">
                    <div class="d-flex justify-content-between sessao-header-menu" routerLinkActive="active-solo"
                        (click)="clicarMenu(drawer)" (click)="mudarIcone()">
                        <span class="font-titulo"><i class="fa-solid fa-screwdriver-wrench"></i> <span
                                class="ms-2">Retrabalho</span></span>
                    </div>
                </a>
            </ul>
            }
            <div class="d-flex justify-content-between sessao-header-menu" [ngClass]="{'active':menusVisiveis['msa']}"
                (click)="abrirMenu('msa')">

                <span class="font-titulo"><i class="fa-solid fa-ruler-combined"></i> <span
                        class="ms-2">MSA</span></span>
                <span><i class="fa-solid fa-chevron-down" [ngClass]="{'rotate': menusVisiveis['msa']}"></i></span>
            </div>
            <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['msa']}">
                <a *ngFor="let link of listaMsa" [routerLink]="link.link">
                    <li class="item" (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                        <a class="font-subMenu">{{ link.label }}</a>
                    </li>
                </a>
            </ul>
        </div>
    </mat-drawer>
    <div class="example-sidenav-content flex-column justify-content-start align-items-start ">
        <a class="btn-menu" (click)="clicarFechar(drawer)" (click)="mudarIcone()">
            <i *ngIf="!open" class="fa-solid fa-angles-left"></i>
            <i *ngIf="open" class="fa-solid fa-angles-right"></i>
        </a>
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>