<mat-drawer-container class="example-container h-100">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <div class="row mb-1 mt-1 ms-1">
            <div class="d-none d-lg-flex col-1">
                @if(menuFixo){
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Desfixar menu"></i></div>
                }@else {
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack-slash" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Fixar menu"></i></div>
                }
            </div>
            <div class="col-10  col-md-11 d-flex justify-content-start align-items-center">
                <a class="logo d-flex justify-content-center align-items-center gap-2"> <i class="fa-solid fa-user-gear"></i> Administração</a>
            </div>
            <div class="col-2  z-1 d-flex align-items-center  justify-content-end" (click)="clicarMenu(drawer)"
                (click)="mudarIcone()">
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div class="menu-content">
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['admin']}" (click)="abrirMenu('admin')">
                    <span class="font-titulo"><i class="fa-solid fa-file-prescription"></i> <span class="ms-2">
                            Admin</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['admin']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['admin']}">
                    @for (link of getMenuItensAdministracao(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }

                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['usuarios']}" (click)="abrirMenu('usuarios')">
                    <span class="font-titulo"><i class="fa-solid fa-file-prescription"></i> <span class="ms-2">
                            Usuarios</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['usuarios']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['usuarios']}">
                    @for (link of getMenuItensUsuarios(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }

                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['permissao']}" (click)="abrirMenu('permissao')">
                    <span class="font-titulo"><i class="fa-solid fa-file-prescription"></i> <span class="ms-2">
                            Permissão do sistema</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['permissao']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['permissao']}">
                    @for (link of getMenuItensPermissoes(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }

                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['notificacao']}" (click)="abrirMenu('notificacao')">
                    <span class="font-titulo"><i class="fa-solid fa-file-prescription"></i> <span class="ms-2">
                            Notificação</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['notificacao']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['notificacao']}">
                    @for (link of getMenuItensNotificacoes(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }

                </ul>
            </ul>
        </div>
    </mat-drawer>
    <div class="example-sidenav-content flex-column justify-content-start align-items-start ">
        <a class="btn-menu" (click)="clicarFechar(drawer)" (click)="mudarIcone()">
            <i *ngIf="!open" class="fa-solid fa-angles-left"></i>
            <i *ngIf="open" class="fa-solid fa-angles-right"></i>
        </a>
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>