<app-container-component [header]="false" subtituloPage="Dispositivos cadastrados:">
    @if(carregando){
    <app-loading></app-loading>
    }
    <div class="col-12">
        <table class="table table-striped table-responsive">
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Dispositivo</th>
                    <th>Cód. Produto</th>
                    <th class="text-center">Visualizar</th>
                    @if(temPermissao() || temPermissaoExcluir() ){
                    <th class="text-center">Remover</th>
                    }
                </tr>
            </thead>
            <tbody>
                @if(listaDeDispostivosIsencao.length > 0){
                @for (item of listaDeDispostivosIsencao; track item.id) {
                <tr>
                    <td data-label="Código">{{item.id}}</td>
                    <td data-label="Dispositivo">{{item.nomeDispositivo}}</td>
                    <td data-label="Cód. Produto">{{item.cdProduto}}</td>
                    @if(dispositivoExibir){
                    <td data-label="cancelar edição" class="text-center" (click)="limparConsulta()"><i
                            class="fa-solid fa-xmark"></i></td>
                    }@else {
                    <td data-label="Visualizar" class="text-center"
                        (click)="consultarDispositivoPorId(item.cdDispositivoIsencao)"><i class="fa-solid fa-eye"></i>
                    </td>
                    }
                    @if(temPermissao() || temPermissaoExcluir() ){
                    <td data-label="Remover" class="text-center"
                        (click)="deletarDispositivoPintura(item.nomeDispositivo,item.id)"><i
                            class="fa-solid fa-trash-can"></i></td>
                    }
                </tr>
                }}@else{
                <tr>
                    <td data-label="Não há dispositivos isenção cadastrados!" colspan="5"><span
                            class="d-none d-lg-block">Não há dispositivos cadastrados!</span></td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    @if(dispositivoConsultadoParaVisualizacao.imagens.length > 0){

    <div #swiperDispositivo class="swiper mySwiper">
        <div class="swiper-wrapper mb-2">
            @for (imagem of dispositivoConsultadoParaVisualizacao.imagens; track imagem.id) {
            <div class="swiper-slide">
                <div class="mb-2">
                    <label>{{dispositivoConsultadoParaVisualizacao.observacao}}</label>
                </div>
                <a [href]="imagem.path" target="_blank" rel="">
                    <img [src]="imagem.path" alt="" loading="lazy">
                </a>
            </div>
            }

        </div>
        <div class="swiper-pagination d-flex justify-content-center my"></div>
    </div>
    }@if(dispositivoConsultadoParaVisualizacao.id != 0 && dispositivoConsultadoParaVisualizacao.imagens.length <= 0) {
        <div class="d-flex justify-content-center">
        Não há imagens neste dispositivo de isenção{{dispositivoConsultadoParaVisualizacao.id}}
        </div>
        }
</app-container-component>
@if(temPermissao() || temPermissaoExcluir() ){
<app-container-component [header]="false" subtituloPage="Dispositivos cadastrados:">
    @if(carregando){
    <app-loading></app-loading>
    }
    <div class="col-12 card-box mt-3">
        <div class="col-12 col-md-6 col-lg-4 col-xxl-4">
            <label for="text" class="form-label"><strong>Dispositivos disponíveis:</strong></label>
            <div class="input-group mb-3">
                <app-select-component class="col-12" [listaConteudo]="this.listaDispositivoIsencaoPintura"
                    (conteudoRetorno)="receberValor($event)"></app-select-component>
            </div>
        </div>

        <div class="row  col-12 col-6 justify-content-start gap-2 mt-3 mb-3">
            <app-botao label="Salvar" class="col-12 col-lg-2" icon="icons8-disquete.png" status="btn-salvar"
                (click)="salvarDispositivoDeIsencaoPintura()"></app-botao>

        </div>
    </div>
</app-container-component>
}