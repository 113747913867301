import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EngDispositivoIsencaoPintura } from '../../../model/engenharia/engDispositivoIsencaoPintura';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngDispositivoIsencaoPinturaService {

  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }

  public salvarEngDispositivoIsencaoPintura(engDeterminacaoProcesso: EngDispositivoIsencaoPintura): Observable<EngDispositivoIsencaoPintura> {
    return this.http.post<EngDispositivoIsencaoPintura>(`${this.url}/eng-dispositivo-isencao-processo`, engDeterminacaoProcesso);
  }

  public consultarEngDispositivoIsencaoPintura(cdProduto:number): Observable<EngDispositivoIsencaoPintura[]> {
    return this.http.get<EngDispositivoIsencaoPintura[]>(`${this.url}/eng-dispositivo-isencao-processo/${cdProduto}`);
  }

  public consultarEngDispositivoIsencaoPinturaPorIdProcesso(cdProduto:number,cdProcesso:number): Observable<EngDispositivoIsencaoPintura[]> {
    return this.http.get<EngDispositivoIsencaoPintura[]>(`${this.url}/eng-dispositivo-isencao-processo/${cdProduto}/${cdProcesso}`);
  }

  public deletarEngDispositivoIsencaoPintura(id:number): Observable<EngDispositivoIsencaoPintura> {
    return this.http.delete<EngDispositivoIsencaoPintura>(`${this.url}/eng-dispositivo-isencao-processo/${id}`);
  }



}
