<div class="card">
    <div class="card-header">
        <strong>CLONAR PRODUTO</strong>
        <i class="fa-solid fa-xmark close-icon" (click)="closeModal()"></i>
    </div>
    <div class="card-body">
       <div class="row">
            <div class="col-12">
                <label for="cdProd" class="form-label">Digite o código do novo produto:</label>
                <input id="cdProd" type="number" class="form-control" [(ngModel)]="cdProdutoDestino">
            </div>
            <div class="col-12 mt-2">
                <label for="inativar" class="form-label">Inativar produto clonado?</label>
                <select id="inativar" class="form-select" [(ngModel)]="inativarProduto">
                    <option value="true" selected>Sim</option>
                    <option value="false" >Não</option>
                </select>
            </div>
            <div class="col-12 mt-3">
                <button class="btn btn-sm btn-save" (click)="clonarProduto()">Clonar</button>
            </div>
       </div>
    </div>
</div>