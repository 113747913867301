<app-container-component [tituloPage]="titulo" [desativarSubTitulo]="false" alinharAocentro="align-items-center">
    
    @if(listaDeIndicadores.length > 0){
    <div class="row">
        @for (item of this.listaDeIndicadores; track $index) {
        <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-5">
            <div class="mb-3 titulo-card">
                <h6>
                    <p class="p-0 m-0">{{item.objetivo}}</p>
                </h6>
            </div>
            <div class="col-12  d-flex gap-2 justify-content-end">
                <i *ngIf="temPermissao(['ROLE_IND_EDIT', 'ROLE_ADMIN'])" class="fa-solid fa-pencil" (click)="editarIndicador(item.id)">
                    <span>Editar</span>
                </i>
                <i *ngIf="temPermissao(['ROLE_IND_EDIT', 'ROLE_ADMIN'])" class="fa-solid fa-gear" (click)="gerenciarIndicador(item.id)">
                    <span>Gerenciar</span>
                </i>
            </div>
            <div class="d-flex align-items-center justify-content-between col-12">
                <div class="col-1 col-lg-2 d-flex flex-column justify-content-center align-items-center">
                    @if(item.tipo == "Negativo"){
                    <i class="fa-solid fa-circle-arrow-down"></i>
                    <p class="text">{{item.tipo}}</p>
                    }@else {
                    <i class="fa-solid fa-circle-arrow-up"></i>
                    <p class="text">{{item.tipo}}</p>
                    }
                </div>
                <div class="col-10">
                    <div class="grafico">
                        <div class="descricaoChart">
                            <div class="d-flex align-items-center gap-2">
                                <div class="chart-description-a"></div><b>Meta {{this.anoAtual}}.</b>
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <div class="chart-description-g"></div><b>Resultado {{this.anoAtual}}.</b>
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <div class="chart-description-ac"></div><b>Resultado {{this.anoPassado}}.</b>
                            </div>
                        </div>
                        <div class="chart">
                            <canvas [id]="'myChart' + item.id"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
    @if(this.setorSelecionado == ''){
    <app-paginator (paginaMudou)="receberMudanca($event)" [paginaAtual]="paginaAtual"
        [quantidadesPorPagina]="quantidadesPorPagina" [totalElementos]="totalTamanho" [totalPorPagina]="totalPorPagina">
    </app-paginator>
    }
    }@else {
    <div class="text-center">
        Não há graficos cadastrados neste ano {{anoAtual}}
    </div>
    }
</app-container-component>