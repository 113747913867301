import { Routes } from "@angular/router";
import { ProdutoDetalheComponent } from "../paginas/engenharia/produto-detalhe/produto-detalhe.component";
import { EngDeterminacaoRegistrarComponent } from "../paginas/engenharia/processo/eng-determinacao-registrar/eng-determinacao-registrar.component";
import { MsaComponent } from "../paginas/engenharia/msa/msa/msa.component";
import { MsaRegistrarComponent } from "../paginas/engenharia/msa/msa-registrar/msa-registrar.component";


export const routesEngenharia: Routes = [
    //ROTAS RESPONSAVEIS POR BOOK
    { path: "produto-detalhe", component: ProdutoDetalheComponent },
    { path: "produto-detalhe/:cdProduto", component: ProdutoDetalheComponent },
    { path: "produto-detalhe-imagens/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/imagens/imagens.component").then(p => p.ImagensComponent) },
    // { path: "produto-detalhe-imagens/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/imagens-cadastro/imagens-cadastro.component").then(p => p.ImagensCadastroComponent) },
    { path: "produto-detalhe-desenho/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/desenho-documentos-produto/desenho-documentos-produto.component").then(d => d.DesenhoDocumentosProdutoComponent) },
    { path: "produto-detalhe-aplicacao/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/aplicacao-produto/aplicacao-produto.component").then(p => p.AplicacaoProdutoComponent) },
    { path: "produto-detalhe-codicao-recebimento/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/condicao-recebimento/condicao-recebimento.component").then(p => p.CodicaoRecebimentoComponent) },
    { path: "produto-detalhe-criterios-aparencia/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/criterios-aparencia/criterios-aparencia.component").then(p => p.CriteriosAparenciaComponent) },
    { path: "produto-detalhe-pecas-similares/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/pecas-similares/pecas-similares.component").then(p => p.PecasSimilaresComponent) },
    { path: "produto-detalhe-relatorio-ensaio/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/relatorio-ensaio/relatorio-ensaio.component").then(p => p.RelatorioEnsaioComponent) },
    { path: "produto-detalhe-registro-alteracao-projeto/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/registro-alteracao-projeto/registro-alteracao-projeto.component").then(p => p.RelagistoAlteracaoProjetoComponent) },
    { path: "produto-detalhe-acompanhamento-desenvolvimento/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/acompanhamento-desenvolvimento/acompanhamento-desenvolvimento.component").then(p => p.AcompanhamentoDesenvolvimentoComponent) },
    { path: "produto-detalhe-plano-de-controle/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/plano-controle/plano-controle.component").then(p => p.PlanoControleComponent) },
    { path: "produto-detalhe-aprovacoes/:cdProduto", loadComponent: () => import("../paginas/engenharia/produto-detalhe/aprovacoes/aprovacoes.component").then(p => p.AprovacoesComponent) },
    //ROTAS RESPONSAVEIS POR PROCESSO
    { path: "eng-produto-registrar-determinacao/:cdProduto", component: EngDeterminacaoRegistrarComponent },
    //ROTAS RESPONSAVEIS POR OPCAO EXTRA
    { path: "opcao-extra/:cdProduto", loadComponent: () => import("../paginas/engenharia/opcoes-extras/opcao-extra/opcao-extra.component").then(o => o.OpcaoExtraComponent) },

    //ROTAS RESPONSAVEIS POR CRITERIO ACEITACAO
    { path: "criterio-aceitacao/:cdProduto", loadComponent: () => import("../paginas/engenharia/criterioAceitacao/criterio-aceita.component").then(c => c.CriterioAceitaComponent) },

    //ROTAS RESPONSAVEIS POR NORMA
    { path: "norma/:cdProduto", loadComponent: () => import("../paginas/engenharia/norma/norma/norma.component").then(n => n.NormaComponent) },

    //ROTAS RESPONSAVEIS POR CLIENTE
    { path: "criterio-cliente/:cdProduto", loadComponent: () => import("../paginas/engenharia/criterioCliente/criterio-cliente/criterio-cliente.component").then(p => p.CriterioClienteComponent) },
    //ROTAS RESPONSAVEIS POR LIÇÃO APRENDIDA
    { path: "licao-aprendida/:cdProduto", loadComponent: () => import("../paginas/engenharia/licao-aprendida/licao-aprendida.component").then(p => p.LicaoAprendidaComponent) },

    //ROTAS RESPONSAVEIS POR 
    { path: "retrabalho/:cdProduto", loadComponent: () => import("../paginas/engenharia/retrabalho/retrabalho.component").then(p => p.RetrabalhoComponent) },


    //ROTAS MSA
    { path: "msa", component: MsaComponent},
    { path: "msa-registrar", component: MsaRegistrarComponent},
    { path: "msa-registrar/:id", component: MsaRegistrarComponent},
    // { path: "retrabalho/:cdProduto", loadComponent: () => import("../paginas/engenharia/retrabalho/retrabalho.component").then(p => p.RetrabalhoComponent) },

]
