<app-container-component tituloPage="PARTES INTERESSADAS"
    [subtituloPage]="this.mapeamento ? 'Editar Mapeamento':'Novo Mapeamento'">
    <form class="form-group row d-flex justify-content-start formulario-produto col-12"
        [formGroup]="formularioCadastro">
        <div class="mb-3 col-12 col-lg-12">
            <label class="form-label">Nome do mapeamento:</label>
            <input type="text" formControlName="mapeamento" class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('mapeamento')"></app-mensagem-errors>
        </div>
        <div class="row justify-content-start gap-2 ms-2 mt-3 mb-3">
            @if(this.mapeamento){
            <app-botao label="Editar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-lápis.png"
                status="btn-salvar" (click)="editarParteInteressada()"></app-botao>
            }@else{
            <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="cadastrarParteInteressada()"></app-botao>
            }
        </div>
    </form>
</app-container-component>

@if(mapeamento){
<app-partes-interessada-listar [mapeamentoRecebido]="this.mapeamento" [ativarHeader]="false"
    (emitirEdicao)="emitirEdicao($event)"></app-partes-interessada-listar>
    
@if(mostrarFormularioAcoes){
<app-partes-interessada-registrar-acoes [mapeamentoRecebido]="this.mapeamento"
    [acaoParteInteressada]="this.acoesParteInteressada"
    (emitirNovaConsulta)="receberNovaConsulta($event)"></app-partes-interessada-registrar-acoes>
}
<div class="botao-container">
    <div class="botao" (click)="openClose()">
        @if(!mostrarBolas){
        <i class="fa-solid fa-plus"></i>
        }@else {
        <i class="fa-solid fa-minus"></i>
        }
    </div>
    <div class="bola-adicionar" [class.show]="mostrarBolas" (click)="mostrarFormsAcoes()">
        <span>Adicionar Novas Ações </span>
    </div>
</div>
}