import { Apontamento } from "./Apontamento";

export class AuditoriaInternaConsultaDto {
    id: number = 0
    normaAuditada: string | undefined;
    identificacao: string | undefined;
    auditorLider: string | undefined;
    equipeAuditora: string | undefined;
    exclusaoEscopo: string | undefined;
    justificativa: string | undefined;
    apontamentos: Apontamento[] = []
    arquivos: any[] = [];
    relatorios: any[] = [];
}
