import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SatisfacaoCliente } from '../../../model/controles/satisfacao-cliente/satisfacao-cliente';
import { Observable } from 'rxjs';
import { AutoridadePesquisa } from '../../../model/controles/satisfacao-cliente/AutoridadePesquisa';
import { SatisfacaoClienteRespostas } from '../../../interface/controles/satisfacao-clientes/satisfacaoClienteRespostas';

@Injectable({
  providedIn: 'root'
})
export class SatisfacaoClienteRespostaService {

  private httpClient = inject(HttpClient);

  constructor() { }

  private url = `${environment.url}/resposta/satisfacao-cliente`

  public consultaPorIdPesquisa(idPesquisa: number): Observable<SatisfacaoCliente> {
    return this.httpClient.get<SatisfacaoCliente>(`${this.url}/${idPesquisa}`);
  }

  public consultaAutorizacoesPorToken(token: string): Observable<AutoridadePesquisa> {
    return this.httpClient.get<AutoridadePesquisa>(`${this.url}/autorizacao/${token}`);
  }

  public cadastrarRespostas(perguntas: SatisfacaoClienteRespostas[], idPesquisa: number, token: string): Observable<SatisfacaoClienteRespostas[]> {
    return this.httpClient.post<SatisfacaoClienteRespostas[]>(`${this.url}/${idPesquisa}/${token}`, perguntas);
  }

}
