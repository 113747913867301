import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngAnaliseCapabilidadeService {

  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }
  public salvarAnaliseCapacidade(formdata: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-analise-capabilidade`, formdata);
  }

  public consultarAnaliseCapacidade(cdProduto:number,cdProcesso:number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-analise-capabilidade/${cdProduto}/${cdProcesso}`);
  }

  public editarAnaliseCapacidade(formdata: any): Observable<any> {
    return this.http.put<any>(`${this.url}/eng-analise-capabilidade`, formdata);
  }
  
  public consultarOuDocumento(nomeArquivo: string): Observable<Blob> {
    return this.http.get(`${this.url}/eng-analise-capabilidade/download/${nomeArquivo}`, {responseType: 'blob'});
  }

  public deletarAnaliseCapacidade(id:number): Observable<any> {
    return this.http.delete<any>(`${this.url}/eng-analise-capabilidade/${id}`);
  }

  public salvarNovoAnaliseCapacidade(novaSequencia:any):Observable<any>{
    return this.http.post<any>(`${this.url}/eng-analise-capabilidade/sequencia`,novaSequencia)
  }
}
