import { EmisorEventoGeralService } from './../../../../../utils/emisorEventoGeral.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Processo } from '../../../../../enum/Processo';
import { BotaoComponent } from '../../../../../componentes/botao/botao.component';
import { DeterminacaoProcessoKtlService } from '../../../../../services/engenharia/processo/eng-determinacao-processo-ktl.service';
import { SnackBarService } from '../../../../../utils/snack-bar.service';
import moment from 'moment';
import { EngDeterminacaoKtl } from '../../../../../model/engenharia/EngDeterminacaoKtl';
import { ActivatedRoute } from '@angular/router';
import { FormValidacaoService } from '../../../../../utils/formValidacao.service';
import { LoginService } from '../../../../../services/login/login.service';
import { Usuario } from '../../../../../model/usuario/usuario';
import { ValidatorsService } from '../../../../../utils/validators.service';
import { MensagemErrorsComponent } from "../../../../../componentes/mensagem-errors/mensagem-errors.component";
import { ContainerComponentComponent } from "../../../../../componentes/container-component/container-component.component";

@Component({
  selector: 'app-eng-determinacao-processo-ktl',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BotaoComponent, MensagemErrorsComponent, ContainerComponentComponent],
  templateUrl: './eng-determinacao-processo-ktl.component.html',
  styleUrl: './eng-determinacao-processo-ktl.component.scss'
})
export class EngDeterminacaoProcessoKtlComponent implements OnInit, OnChanges {

  @Input() dadosFormulario: any;
  formularioDeterminacaoProcessoKtl!: FormGroup;
  processo = Processo;
  idProcesso: any;
  cdProduto: any;
  usuario: Usuario = new Usuario();
  @Output() processoSalvo: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private determinacaoKtlService: DeterminacaoProcessoKtlService,
    private snackBarService: SnackBarService,
    private activeRouter: ActivatedRoute,
    private formValidacoesServices: FormValidacaoService,
    private loginService: LoginService,
    private emisorEventoGeralService: EmisorEventoGeralService,
    private validatorsService: ValidatorsService,
  ) {

  }

  ngOnInit(): void {
    this.pegarDadosUsuario();
    this.activeRouter.params.subscribe({
      next: (res) => {
        this.cdProduto = res['cdProduto'];
        this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
        this.iniciarFormulario();
        console.log(this.dadosFormulario,this.idProcesso)
        this.formularioDeterminacaoProcessoKtl.patchValue(this.dadosFormulario);
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
    if (changes['dadosFormulario'] && !changes['dadosFormulario'].firstChange) {
      this.formularioDeterminacaoProcessoKtl.patchValue(changes['dadosFormulario'].currentValue);
    }
  }



  public iniciarFormulario() {
    this.formularioDeterminacaoProcessoKtl = this.formBuilder.group({
      id: [],
      cdProcesso: [this.processo.KTL],
      nome: [],
      cdProduto: [this.cdProduto],
      qtdePecasGancheira: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdeGancheirasRack: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdePecasRack: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      receita: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      parametroEstufa: [],
      amostraInspecao: [],
      tempoSetupDispositivo: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoMontagem: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoIsencaoAnterior: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoMaquina: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoResfriamento: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoDesmontagem: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoInspecao: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoIsencaoPosterior: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoEmbalagem: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      leadTime: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdePecasHoraMaquina: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      camadaMinima: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      camadaMaxima: [, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      epi: ["Óculos, sapatos de segurança, luvas e protetor auricular"],
      observacao: [],
      dataCadastro: [moment().format("yyyy-MM-DD")],
      usuarioCadastro: [this.usuario.username],
      usuarioAlteracao: [this.usuario.username],
      dataAlteracao: [moment().format("yyyy-MM-DD")]
    });
  };


  public pegarDadosUsuario() {
    this.usuario = this.loginService.decodificarToken();
  }

  public salvarDeterminacaoKtl() {
    if (this.formularioDeterminacaoProcessoKtl.valid) {
      this.emisorEventoGeralService.carregando.emit(true);
      let engDeterminacaoKtl: EngDeterminacaoKtl = new EngDeterminacaoKtl();
      engDeterminacaoKtl = this.formularioDeterminacaoProcessoKtl.value;
      this.determinacaoKtlService.salvarDeterminacaoProcesso(engDeterminacaoKtl).subscribe({
        next: (res) => {
          this.snackBarService.openSnackBar("Derminação do processo KTL criada", "my-snack-bar-sucess");
        }, error: (error) => {
          console.log(error)
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.openSnackBar("Erro ao tentar criar Determinação KTL", "my-snack-bar-erro");
        }, complete: () => {
          this.processoSalvo.emit(true);
          this.emisorEventoGeralService.carregando.emit(false);
        }
      });
    } else {
      this.snackBarService.openSnackBar("Há campos inválidos no formulario", "my-snack-bar-erro");
      this.formularioDeterminacaoProcessoKtl.markAllAsTouched()
    }
  }


  public editarDeterminacaoKtl() {
    if (this.formularioDeterminacaoProcessoKtl.valid) {
      this.emisorEventoGeralService.carregando.emit(true);
      let engDeterminacaoKtl: EngDeterminacaoKtl = new EngDeterminacaoKtl();
      engDeterminacaoKtl = this.formularioDeterminacaoProcessoKtl.value;
      this.determinacaoKtlService.editarDeterminacaoProcesso(engDeterminacaoKtl).subscribe({
        next: (res) => {

          this.snackBarService.openSnackBar("Derminação do processo KTL editada com sucesso", "my-snack-bar-sucess");
        }, error: (error) => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.openSnackBar("Erro ao tentar editar Determinação KTL", "my-snack-bar-erro");
        }, complete: () => {
          this.emisorEventoGeralService.carregando.emit(false);
        }
      });
    } else {
      this.snackBarService.openSnackBar("Há campos inválidos no formulario", "my-snack-bar-erro");
      this.formularioDeterminacaoProcessoKtl.markAllAsTouched()
    }
  }


  public calcularQuantidadePorRack() {
    this.formularioDeterminacaoProcessoKtl.get('qtdePecasRack')?.reset();
    let valor = this.formValidacoesServices.calcularQuantidade(this.formularioDeterminacaoProcessoKtl.get('qtdePecasGancheira')?.value, this.formularioDeterminacaoProcessoKtl.get('qtdeGancheirasRack')?.value)
    // Converta os valores para inteiros, atribuindo 0 se não for um número válido
    this.formularioDeterminacaoProcessoKtl.get('qtdePecasRack')?.setValue(valor);
  }


  public obterValidacao(nome: string) {
    return this.formValidacoesServices.obterValidacao(this.formularioDeterminacaoProcessoKtl, nome);
  }

  public limparFormulario() {
    this.formularioDeterminacaoProcessoKtl.reset();
    this.iniciarFormulario();
  }

  public autoGrow(event: any) {
    this.formValidacoesServices.autoAjuste(event)
  }


  public temPermissao(){
    return this.loginService.temPermissao(['ROLE_ENG_EDIT']);
  }

  public temPermissaoExcluir(){
    return this.loginService.temPermissao(['ROLE_ENG_ADMIN']);
  }

}
