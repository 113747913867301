import { Component } from '@angular/core';
import { ContainerComponentComponent } from '../../../../componentes/container-component/container-component.component';

@Component({
  selector: 'app-analise-critica-visualizar',
  standalone: true,
  imports: [ContainerComponentComponent],
  templateUrl: './analise-critica-visualizar.component.html',
  styleUrl: './analise-critica-visualizar.component.scss'
})
export class AnaliseCriticaVisualizarComponent {

}
