import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngPadraoIsencaoService {

  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }
  public salvarPadraoDeIsencao(formdata: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-padrao-isencao`, formdata);
  }
  public editarDescricaoPadraoDeIsencao(id:number,descricao:string): Observable<any> {
    return this.http.put<any>(`${this.url}/eng-padrao-isencao/${id}/${descricao}`, null);
  }

  public consultarPadraoDeIsencao(cdProduto:number,cdProcesso:number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-padrao-isencao/${cdProduto}/${cdProcesso}`);
  }

  public consultaImagemPorNome(nomeArquivo:string): Observable<Blob> {
    return this.http.get(`${this.url}/eng-padrao-isencao/download/${nomeArquivo}`,{responseType:'blob'});
  }

  public deletarPadraoDeIsencao(id:number): Observable<any> {
    return this.http.delete<any>(`${this.url}/eng-padrao-isencao/${id}`);
  }

  public salvarNovoPadraoDeIsencao(novaSequencia:any):Observable<any>{
    return this.http.post<any>(`${this.url}/eng-padrao-isencao/sequencia`,novaSequencia)
  }


  // private handleError(error: HttpErrorResponse): Observable<never> {
  //   // Aqui você pode tratar o erro conforme necessário
  //   console.error('Error ao consultar');

  //   // Retorne um observable com um erro amigável
  //   return throwError(() => new Error('Algo deu errado; por favor, tente novamente mais tarde.'));
  // }
}
