import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ManutencaoOsCadastroDTO } from '../../../model/acoes/ordem-servico/ordem-servico-cadastro';

@Injectable({
  providedIn: 'root'
})
export class OrdemServicoService {

  urlApi: string = environment.url + '/ordem-servico';
  public static emitirCadastro = new EventEmitter<any>();

  constructor(
    private http: HttpClient
  ) { }

  public consultaOrdensDeServico(page: number, size: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${page}/${size}`);
  }

  public consultaOrdensDeServicoDoUsuaioPoStatus(page: number, status: string, size: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${page}/${size}/${status}`);
  }

  public cadastrarOrdemDeServico(m: ManutencaoOsCadastroDTO): Observable<any> {
    return this.http.post<ManutencaoOsCadastroDTO>(this.urlApi, m);
  }

  public alterar(id: number, m: ManutencaoOsCadastroDTO): Observable<any> {
    return this.http.put<ManutencaoOsCadastroDTO>(`${this.urlApi}/${id}`, m);
  }

  public excluirOs(id: number): Observable<any> {
    return this.http.delete<ManutencaoOsCadastroDTO>(`${this.urlApi}/${id}`);
  }

}
