export class EngDeterminacaoPo {
    id!: number
    nome!: string
    cdProduto!: number
    equipament1!: string
    equipament2!: string
    equipament3!: string
    qtdePecasTravessa!: number
    qtdeTravessasQuadro!: number
    qtdePecasQuadro!: number
    velocMonoviaMax!: number
    velocMonoviaMin!: number
    distanciaReciprocador!: number
    velocReciprocador!: number
    vazaoReciprocador!: number
    alturaReciprocadorMax!: number
    alturaReciprocadorMin!: number
    tensao!: number
    corrente!: number
    vazaoPistolaRet!: number
    qtdePassadaPistolaRet!: number
    formatoPintura!: string
    parametroCura!: string
    amostraInspecao!: string
    tempoSetupEquipamento!: number
    tempoSetupDispositivo!: number
    tempoMontagem!: number
    tempoIsencaoAnterior!: number
    tempoMaquina!: number
    tempoResfriamento!: number
    tempoDesmontagem!: number
    tempoInspecao!: number
    tempoIsencaoPosterior!: number
    tempoEmbalagem!: number
    leadTime!: number
    qtdePecasHoraMaquina!: number
    camadaMinima!: number
    camadaMaxima!: number
    epi!: string
    observacao!: string
    dataCadastro!: string
    usuarioCadastro!: string
    usuarioAlteracao!: string
    dataAlteracao!: string
    cdProcesso!: number
  }
