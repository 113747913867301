export class Msa {
    id: number  = 0;
    identificacao: string  = "";
    analista: string  = "";
    instrumento: string  = "";
    procOperacional: string  = "";
    anTolerancia: string  = "";
    ndc: string  = "";
    anLinearidade: string  = "";
    anSistema: string  = "";
    obs: string  = "";
    dataCadastro:  string  = "";
    usuarioCadastro: string  = "";
    dataAlteracao:  string  = "";
    usuarioAlteracao: string  = "";
    imgGageLb: string  = "";
    imgGrafGageRr: string  = "";
    pathImgGageLb: string  = "";
    imgEstTolerancia: string  = "";
    pathImgEstTolerancia: string  = "";
    pathImgGrafGageRr: string  = "";
    imgTwayAnova: string  = "";
    pathImgTwayAnova: string  = "";
    arquivoMpj: string  = "";
    pathArquivoMpj: string  = "";

}
