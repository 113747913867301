import { EngProcessoUpdateDto } from '../../../../model/engenharia/engProcessoUpdateDto';
import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormsDataService } from '../../../../utils/forms-data.service';
import { GerenciadorDeArquivosService } from '../../../../utils/gerenciador-de-arquivos.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { CommonModule } from '@angular/common';

import { PadraoMontagemService } from '../../../../services/engenharia/processo/padrao-montagem.service';
// import { RotaProcessosService } from '../../../../services/rotas-services/rota-processos.service';
import { MatCardModule } from '@angular/material/card';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ImagemRenderizada } from '../../../../interface/imagenRenderizada';
import { forkJoin, map, of } from 'rxjs';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { Pagination } from 'swiper/modules';
import { CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { LoadingComponent } from "../../../../componentes/loading/loading.component";

@Component({
  selector: 'app-eng-padrao-montagem',
  standalone: true,
  imports: [CommonModule, CdkDropList, CdkDrag, BotaoComponent, ReactiveFormsModule, MatTooltipModule, MatCardModule, ContainerComponentComponent, FormsModule, LoadingComponent],
  templateUrl: './eng-padrao-montagem.component.html',
  styleUrl: './eng-padrao-montagem.component.scss'
})
export class EngPadraoMontagemComponent implements OnChanges {

  formulario!: FormGroup;
  editarMontagem!: FormGroup;
  nomeDeterminacao: string = ''
  listaDocumentoSelecionadoRederizados: any[] = [];
  listaDocumentoSelecionadoRederizadoEditar: any[] = [];
  controle: boolean = false;
  idProcesso: string = '';
  cdProduto: number = 0;
  itemEditando: any;
  listaDePadraoMontagemDeterminacao: any[] = []
  listaDeDados: any[] = [];
  contactForm!: FormGroup;
  produto: any = {}
  urlRenderImagem: ImagemRenderizada = { nomeArquivo: '', path: '' };
  engPadraoMontagemDto: EngProcessoUpdateDto[] = []
  @Input() dados: any;
  @Input() fazerConsulta: boolean = false;
  @ViewChild('swiperProduto', { static: false }) swiperContainer!: ElementRef;
  public swiper?: Swiper;
  ativarVisualizarSequencia: boolean = false;
  editando: boolean = false;
  imagemSelecionada: any;
  descricaoImagem: string = "";
  carregando:boolean = false;

  constructor(
    private padraoMontagemService: PadraoMontagemService,
    private gerenciadorDeArquivosService: GerenciadorDeArquivosService,
    private snackBarService: SnackBarService,
    private formDataService: FormsDataService,
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef,
    private formValidacaoService:FormValidacaoService
  ) {

  }
  ngOnInit(): void {
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = parseInt(params.cdProduto);
      this.produto = { cdProduto: this.cdProduto }
      // RotaProcessosService.emitirProcesso.emit(this.produto);
      this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
      if (this.cdProduto && this.idProcesso) {
        this.limparDados();
        this.consultarTodosOsDocumentos();
      }
    });
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    this.contactForm = this.formBuilder.group({
      contacts: this.formBuilder.array([this.createContact()])
    });
    this.iniciarFormulario();
  }


  ngOnChanges(changes: SimpleChanges) {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    if (changes['fazerConsulta'] && changes['fazerConsulta'].currentValue === true) {
      this.limparDados();
      this.consultarTodosOsDocumentos();
    }
  }

  public verificarMudanca() {
    if (this.swiper) {
      this.swiper.update();
    }
  }


  public iniciarFormulario() {
    this.formulario = this.formBuilder.group({
      id: [''],
      descricao: [''],
      file: File
    });
  }

  public editarDescricaoImagem(imagemSelecionada: number, descricao: string) {
    this.editando = true;
    this.imagemSelecionada = imagemSelecionada;
    this.descricaoImagem = descricao;
  }

  public cancelarEdicao() {
    this.editando = false;
    this.descricaoImagem = '';
    this.imagemSelecionada = 0;
  }

  public concluirEdicaoDeDescricao() {
    this.carregando = true;
    this.padraoMontagemService.editarDescricaoPadraoMontagem(this.imagemSelecionada, this.descricaoImagem).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar('Descrição editada com sucesso', 'my-snack-bar-sucess');
      }, error: (e) => {
        this.carregando = false;
        this.snackBarService.tratarErro(e,"Erroa ao tentar editar descrição");
      }, complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos();
        this.cancelarEdicao();
      }
    })
  }


  public pegarDocumentoEDesenho(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let itemJaExiste = this.listaDocumentoSelecionadoRederizados.some(
        (arquivo) => arquivo.name === file.name
      );
      if (!itemJaExiste) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          file['preview'] = e.target.result;
          const previsualizacao = e.target.result;
          this.listaDocumentoSelecionadoRederizados.push(file);
        }
        reader.readAsDataURL(file);
      }
    }
  }



  public salvar(formData: FormData) {
    this.carregando = true;
    this.padraoMontagemService.salvarPadraoMontagem(formData).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar('Imagem salva com sucesso', 'my-snack-bar-sucess');
      },
      error: (err) => {
        this.carregando = false;
        this.snackBarService.tratarErro(err,"Erro ao tentar salvar padrão de montagem")
      },
      complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos();
        this.limparFormulario();
      },
    });
  }
  public consultarTodosOsDocumentos() {
    this.listaDePadraoMontagemDeterminacao =[];
    this.padraoMontagemService
      .consultarPadraoMontagem(this.cdProduto, parseInt(this.idProcesso))
      .subscribe({
        next: (res) => {
          this.listaDePadraoMontagemDeterminacao = res;
          console.log(res);

        },
        error: (erro) => {

        },
        complete: () => {
     
          this.montarImagens();
        },
      });
  }




  public limparFormulario() {
    this.listaDePadraoMontagemDeterminacao = [];
    this.listaDocumentoSelecionadoRederizados = [];
    this.listaDeDados = [];
    this.contactForm.reset();
    this.formulario.reset();
    this.contacts.reset();
    this.contacts.clear();

  }

  public limparDados() {
    this.listaDePadraoMontagemDeterminacao = [];
    this.listaDocumentoSelecionadoRederizados = [];
    this.listaDeDados = [];
  }


  createContact(): FormGroup {
    return this.formBuilder.group({
      descricao: ['', Validators.required],
      file: File,
    });
  }


  addContact(): void {
    if (this.listaDocumentoSelecionadoRederizados.length >= 0) {
      const contacts = this.contacts;
      contacts.push(this.createContact());
    } else {
      let pegarInput = document.querySelectorAll(`#arquivoAlteracao`);
      pegarInput.forEach((e: any) => {
        if (e) {
          e.files = null;
          e.value = "";
        }
      })
    }
  }


  removeContact(index: number): void {
    const contacts = this.contactForm.get('contacts') as FormArray;
    contacts.removeAt(index);
    this.listaDocumentoSelecionadoRederizados.splice(index, 1)

  }

  get contacts() {
    return this.contactForm.get('contacts') as FormArray;
  }



  onSubmit() {
    const formData = new FormData();
    this.listaDeDados = [];
    this.contactForm.value['contacts'].forEach((elemento: any, index: number) => {
      const file = this.listaDocumentoSelecionadoRederizados[index];
      elemento.file = file;
      if (!file || !file.name) {
        this.snackBarService.openSnackBar('Sem imagem selecionada', "my-snack-bar-erro")
      } else {
        formData.append('file', elemento.file, elemento.file.name);
        this.listaDeDados.push({
          "descricao": elemento.descricao,
          "cdProduto": this.cdProduto,
          "cdProcesso": this.idProcesso,
          "sequencia": index
        });
      }

    });
    formData.append('engPadraoMontagem', JSON.stringify(this.listaDeDados)), this.formDataService.criarFormData("engPadraoMontagem", this.listaDocumentoSelecionadoRederizados, this.listaDeDados)
    this.salvar(formData);
  }


  drop(event: CdkDragDrop<{ title: string; poster: string }[]>) {
    moveItemInArray(this.listaDePadraoMontagemDeterminacao, event.previousIndex, event.currentIndex);
  }



  public salvarNovaSequencia() {
    const atualizarSequencias$ = of(this.listaDePadraoMontagemDeterminacao).pipe(map(lista => {
      let contador = 1;
      lista.forEach(e => {
        e.sequencia = contador++;
        e.cdProcesso = this.idProcesso;
        e.cdProduto = this.cdProduto;
      });
      return lista;
    }));

    forkJoin([atualizarSequencias$]).subscribe(() => {
      this.transformaEmObjetoDeEnvio();
      this.salvarSequenciaNova();
    });
  }

  public salvarSequenciaNova() {
    this.carregando = true;
    this.padraoMontagemService.salvarNovoPadraoImagem(this.engPadraoMontagemDto).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Sequencia redefinada", "my-snack-bar-sucess")
      }, error: (error) => {
        this.carregando = false;
        this.snackBarService.tratarErro(error,"Erro ao tentar salvar nova sequência padrão de montagem")
      }, complete: () => {
        this.carregando = false;
        this.consultarTodosOsDocumentos()
      }
    });
  }



  public transformaEmObjetoDeEnvio() {
    this.engPadraoMontagemDto = [];
    this.listaDePadraoMontagemDeterminacao.forEach(e => {
      let objTemp: EngProcessoUpdateDto = {
        id: e.id,
        nomeArquivo: e.nomeArquivo,
        cdProcesso: e.cdProcesso,
        cdProduto: e.cdProduto,
        descricao: e.descricao,
        path: e.path,
        dataCadastro: e.dataCadastro,
        sequencia: e.sequencia
      }
      this.engPadraoMontagemDto.push(objTemp);
    });
  }




  public concluirEdicao() {
    if (this.listaDocumentoSelecionadoRederizadoEditar.length > 0) {
      this.editarMontagem.get('file')?.setValue(this.listaDocumentoSelecionadoRederizadoEditar[0]);
    }

  }
  public cancelar() {
    this.itemEditando = 0;
  }

  public deletarPadraoDeMontagem(nomeArquivo: string, id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: nomeArquivo, textoConteudo: "padrão de montagem" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.deletar(id);
        };
      }
    });
  }

  private deletar(id: number) {
    this.carregando = true;
    this.padraoMontagemService.deletarPadraoMontagem(id).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackBarService.tratarErro(error,"Erro ao tentar deletar padrão de montagem")
        this.carregando = false;
      }, complete: () => {
        this.carregando = false;
        this.listaDePadraoMontagemDeterminacao = [];
        this.consultarTodosOsDocumentos();
      }
    });
  }
  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }




  public montarImagens() {
    this.cdr.detectChanges();
    const options: SwiperOptions = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff; background:#09638E" class="' + className + '">' + (index + 1) + '</span>';
        }
      },
      allowTouchMove: true, // Garante suporte ao touch
      observer: true,       // Observa alterações no Swiper
      observeParents: true, // Observa mudanças nos elementos pais
      threshold: 30,
      modules: [Pagination],
    };
    this.swiper = new Swiper(this.swiperContainer.nativeElement, options);
  }


}
