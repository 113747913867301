import { Routes } from "@angular/router";

export const routesInspecaoEquipamentosEdit: Routes = [
    { path: "aderencia-registrar", loadComponent: () => import("../../paginas/inspecao-equipamentos/aderencia/inspecao-aderencia-registrar/inspecao-aderencia-registrar.component").then(a => a.InspecaoAderenciaRegistrarComponent) },
    { path: "aderencia-registrar/:id", loadComponent: () => import("../../paginas/inspecao-equipamentos/aderencia/inspecao-aderencia-registrar/inspecao-aderencia-registrar.component").then(a => a.InspecaoAderenciaRegistrarComponent) },

    { path: "dureza-lapis-registrar", loadComponent: () => import("../../paginas/inspecao-equipamentos/dureza-lapis/inspecao-dureza-lapis-registrar/inspecao-dureza-lapis-registrar.component").then(d => d.InspecaoDurezaLapisRegistrarComponent) },
    { path: "dureza-lapis-registrar/:id", loadComponent: () => import("../../paginas/inspecao-equipamentos/dureza-lapis/inspecao-dureza-lapis-registrar/inspecao-dureza-lapis-registrar.component").then(d => d.InspecaoDurezaLapisRegistrarComponent) },

    { path: "medicao-camada-registrar", loadComponent: () => import("../../paginas/inspecao-equipamentos/medicao-camada/inspecao-medicao-camada-registrar/inspecao-medicao-camada-registrar.component").then(m => m.InspecaoMedicaoCamadaRegistrarComponent) },
    { path: "medicao-camada-registrar/:id", loadComponent: () => import("../../paginas/inspecao-equipamentos/medicao-camada/inspecao-medicao-camada-registrar/inspecao-medicao-camada-registrar.component").then(m => m.InspecaoMedicaoCamadaRegistrarComponent) },

    { path: "luximetro-registrar", loadComponent: () => import("../../paginas/inspecao-equipamentos/luximetro/inspecao-luximetro-registrar/inspecao-luximetro-registrar.component").then(l => l.InspecaoLuximetroRegistrarComponent) },
    { path: "luximetro-registrar/:id", loadComponent: () => import("../../paginas/inspecao-equipamentos/luximetro/inspecao-luximetro-registrar/inspecao-luximetro-registrar.component").then(l => l.InspecaoLuximetroRegistrarComponent) }

]