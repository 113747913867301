@if(verificarSeExiste()){
<app-container-component tituloPage="DETERMINAÇÃO DE PROCESSO" [desativarSubTitulo]="false" [removerElevacao]="true"
    [removerPadding]="true" [iconeVolta]="false">
    @if(listaDeDeterminacaoFosfatoDoProduto.length > 0){
    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button"
                    [ngClass]="listaDeDeterminacaoFosfatoDoProduto[0].cdProcesso == 1 ? 'show' : ''" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseFosfato" aria-expanded="true"
                    aria-controls="collapseFosfato"
                    (click)="colocarIdProcessoStorage(listaDeDeterminacaoFosfatoDoProduto[0].cdProcesso,'fosfato')">
                    Processo Fosfato
                </button>
            </h2>
            <div id="collapseFosfato" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <mat-tab-group mat-stretch-tabs="true" #tabList   mat-align-tabs="center"mat-stretch-tabs animationDuration="200ms" dynamicHeight
                    (selectedTabChange)="emitirConsulta($event)">
                    <mat-tab label="DADOS">
                        <app-eng-determinacao-processo-fosfato
                            [formularioFosfato]="listaDeDeterminacaoFosfatoDoProduto[0]"></app-eng-determinacao-processo-fosfato>
                    </mat-tab>
                    @if(processoSelecionado == 'fosfato'){
                    <mat-tab label="DISPOSITIVO DE PINTURA">
                        <!-- @if(consultaDispositivoFlag){ -->
                        <app-eng-dispositivo-pintura
                            [fazerConsulta]="consultaDispositivoFlag"></app-eng-dispositivo-pintura>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE MONTAGEM">

                        <app-eng-padrao-montagem [fazerConsulta]="consultaPadraoMontagemFlag"></app-eng-padrao-montagem>

                    </mat-tab>
                    <mat-tab label="DISPOSITIVO DE ISENÇÃO">
                        <!-- @if(consultaDispositivoIsencaoFlag){ -->
                        <app-eng-dispositivo-isencao
                            [fazerConsulta]="consultaDispositivoIsencaoFlag"></app-eng-dispositivo-isencao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE ISENÇÃO">
                        <!-- @if(consultaPadraoIsencaoFlag){ -->
                        <app-eng-padrao-isencao [fazerConsulta]="consultaPadraoIsencaoFlag"></app-eng-padrao-isencao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="CRITÉRIOS DE AUTOINSPEÇÃO">
                        <!-- @if(consultaCriteriosDeAutoInspecaoFlag){ -->
                        <app-eng-criterio-auto-inspecao
                            [fazerConsulta]="consultaCriteriosDeAutoInspecaoFlag"></app-eng-criterio-auto-inspecao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="MEDIÇÃO DE CAMADA">
                        <!-- @if(consultaMedicaoCamadaFlag){ -->
                        <app-eng-medicao-camada [fazerConsulta]="consultaMedicaoCamadaFlag"> </app-eng-medicao-camada>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE EMBALAGEM">
                        <!-- @if(consultaPadraoEmbalagemFlag){ -->
                        <app-eng-padrao-embalagem [fazerConsulta]="consultaPadraoEmbalagemFlag">
                        </app-eng-padrao-embalagem>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE DEFEITOS">
                        <!-- @if(consultaPadraoDefeitosFlag){ -->
                        <app-eng-padrao-defeitos [fazerConsulta]="consultaPadraoDefeitosFlag">
                        </app-eng-padrao-defeitos>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="ANÁLISE DE CAPABILIDADE">
                        <!-- @if(consultaPadraoAnaliseDeCapabilidadeFlag){ -->
                        <app-eng-analise-capabilidade
                            [fazerConsulta]="consultaPadraoAnaliseDeCapabilidadeFlag"></app-eng-analise-capabilidade>
                        <!-- } -->
                    </mat-tab>
                    }
                </mat-tab-group>
            </div>

        </div>
    </div>
    }
    @if(listaDeDeterminacaoPoDoProduto.length > 0){
    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button"
                    (click)="colocarIdProcessoStorage(listaDeDeterminacaoPoDoProduto[0].cdProcesso,'po')" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsePo" aria-expanded="true"
                    [ngClass]="listaDeDeterminacaoPoDoProduto[0].cdProcesso == 3 ? 'show' : ''"
                    aria-controls="collapsePo">
                    Processo po
                </button>
            </h2>
            <div id="collapsePo"  class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <mat-tab-group mat-stretch-tabs="true" #tabList   mat-align-tabs="center"mat-stretch-tabs animationDuration="200ms"  dynamicHeight
                    (selectedTabChange)="emitirConsulta($event)">
                    <mat-tab label="DADOS">
                        <app-eng-determinacao-processo-po
                            [dadosFormulario]="listaDeDeterminacaoPoDoProduto[0]"></app-eng-determinacao-processo-po>
                    </mat-tab>
                    @if(processoSelecionado == 'po'){
                    <mat-tab label="DISPOSITIVO DE PINTURA">
                        <!-- @if(consultaDispositivoFlag){ -->
                        <app-eng-dispositivo-pintura
                            [fazerConsulta]="consultaDispositivoFlag"></app-eng-dispositivo-pintura>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE MONTAGEM">
                        @if(consultaPadraoMontagemFlag){
                        <app-eng-padrao-montagem [fazerConsulta]="consultaPadraoMontagemFlag"></app-eng-padrao-montagem>
                        }
                    </mat-tab>
                    <mat-tab label="DISPOSITIVO DE ISENÇÃO">
                        <!-- @if(consultaDispositivoIsencaoFlag){ -->
                        <app-eng-dispositivo-isencao
                            [fazerConsulta]="consultaDispositivoIsencaoFlag"></app-eng-dispositivo-isencao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE ISENÇÃO">
                        <!-- @if(consultaPadraoIsencaoFlag){ -->
                        <app-eng-padrao-isencao [fazerConsulta]="consultaPadraoIsencaoFlag"></app-eng-padrao-isencao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="CRITÉRIOS DE AUTOINSPEÇÃO">
                        <!-- @if(consultaCriteriosDeAutoInspecaoFlag){ -->
                        <app-eng-criterio-auto-inspecao
                            [fazerConsulta]="consultaCriteriosDeAutoInspecaoFlag"></app-eng-criterio-auto-inspecao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="MEDIÇÃO DE CAMADA">
                        <!-- @if(consultaMedicaoCamadaFlag){ -->
                        <app-eng-medicao-camada [fazerConsulta]="consultaMedicaoCamadaFlag"> </app-eng-medicao-camada>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE EMBALAGEM">
                        <!-- @if(consultaPadraoEmbalagemFlag){ -->
                        <app-eng-padrao-embalagem [fazerConsulta]="consultaPadraoEmbalagemFlag">
                        </app-eng-padrao-embalagem>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE DEFEITOS">
                        <!-- @if(consultaPadraoDefeitosFlag){ -->
                        <app-eng-padrao-defeitos [fazerConsulta]="consultaPadraoDefeitosFlag">
                        </app-eng-padrao-defeitos>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="ANÁLISE DE CAPABILIDADE">
                        <!-- @if(consultaPadraoAnaliseDeCapabilidadeFlag){ -->
                        <app-eng-analise-capabilidade
                            [fazerConsulta]="consultaPadraoAnaliseDeCapabilidadeFlag"></app-eng-analise-capabilidade>
                        <!-- } -->
                    </mat-tab>
                    }
                </mat-tab-group>
            </div>
        </div>
    </div>
    }
    @if(listaDeDeterminacaoKtlDoProduto.length > 0){
    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button"
                    (click)="colocarIdProcessoStorage(listaDeDeterminacaoKtlDoProduto[0].cdProcesso,'ktl')"
                    type="button" data-bs-toggle="collapse" data-bs-target="#collapseKtl" aria-expanded="true"
                    [ngClass]="listaDeDeterminacaoKtlDoProduto[0].cdProcesso == 2 ? 'show' : ''"
                    aria-controls="collapseKtl">
                    Processo Ktl
                </button>
            </h2>
            <div id="collapseKtl" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <mat-tab-group mat-stretch-tabs="true"   mat-align-tabs="center"mat-stretch-tabs animationDuration="200ms"  dynamicHeight
                    (selectedTabChange)="emitirConsulta($event)">
                    <mat-tab label="DADOS">
                        <app-eng-determinacao-processo-ktl
                            [dadosFormulario]="listaDeDeterminacaoKtlDoProduto[0]"></app-eng-determinacao-processo-ktl>
                    </mat-tab>
                    @if(processoSelecionado == 'ktl'){
                    <mat-tab label="DISPOSITIVO DE PINTURA">
                        <!-- @if(consultaDispositivoFlag){ -->
                        <app-eng-dispositivo-pintura
                            [fazerConsulta]="consultaDispositivoFlag"></app-eng-dispositivo-pintura>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE MONTAGEM">
                        <!-- @if(consultaPadraoMontagemFlag){ -->
                        <app-eng-padrao-montagem [fazerConsulta]="consultaPadraoMontagemFlag"></app-eng-padrao-montagem>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="DISPOSITIVO DE ISENÇÃO">
                        <!-- @if(consultaDispositivoIsencaoFlag){ -->
                        <app-eng-dispositivo-isencao
                            [fazerConsulta]="consultaDispositivoIsencaoFlag"></app-eng-dispositivo-isencao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE ISENÇÃO">
                        <!-- @if(consultaPadraoIsencaoFlag){ -->
                        <app-eng-padrao-isencao [fazerConsulta]="consultaPadraoIsencaoFlag"></app-eng-padrao-isencao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="CRITÉRIOS DE AUTOINSPEÇÃO">
                        <!-- @if(consultaCriteriosDeAutoInspecaoFlag){ -->
                        <app-eng-criterio-auto-inspecao
                            [fazerConsulta]="consultaCriteriosDeAutoInspecaoFlag"></app-eng-criterio-auto-inspecao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="MEDIÇÃO DE CAMADA">
                        <!-- @if(consultaMedicaoCamadaFlag){ -->
                        <app-eng-medicao-camada [fazerConsulta]="consultaMedicaoCamadaFlag"> </app-eng-medicao-camada>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE EMBALAGEM">
                        <!-- @if(consultaPadraoEmbalagemFlag){ -->
                        <app-eng-padrao-embalagem [fazerConsulta]="consultaPadraoEmbalagemFlag">
                        </app-eng-padrao-embalagem>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE DEFEITOS">
                        <!-- @if(consultaPadraoDefeitosFlag){ -->
                        <app-eng-padrao-defeitos [fazerConsulta]="consultaPadraoDefeitosFlag">
                        </app-eng-padrao-defeitos>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="ANÁLISE DE CAPABILIDADE">
                        <!-- @if(consultaPadraoAnaliseDeCapabilidadeFlag){ -->
                        <app-eng-analise-capabilidade
                            [fazerConsulta]="consultaPadraoAnaliseDeCapabilidadeFlag"></app-eng-analise-capabilidade>
                        <!-- } -->
                    </mat-tab>
                    }
                </mat-tab-group>
            </div>
        </div>
    </div>
    }
    @if(listaDeDeterminacaoLiquidaDoProduto.length > 0){
    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button"
                    (click)="colocarIdProcessoStorage(listaDeDeterminacaoLiquidaDoProduto[0].cdProcesso,'liquida')"
                    type="button" data-bs-toggle="collapse" data-bs-target="#collapseLiquida"
                    [ngClass]="listaDeDeterminacaoLiquidaDoProduto[0].cdProcesso == 4 ? 'show' : ''"
                    aria-expanded="true" aria-controls="collapseLiquida">
                    Processo Liquída
                </button>
            </h2>
            <div id="collapseLiquida" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <mat-tab-group mat-stretch-tabs="true"   mat-align-tabs="center"mat-stretch-tabs animationDuration="200ms"  dynamicHeight
                    (selectedTabChange)="emitirConsulta($event)">
                    <mat-tab label="DADOS">
                        <app-eng-determinacao-processo-liquida
                            [formularioLiquida]="listaDeDeterminacaoLiquidaDoProduto[0]"></app-eng-determinacao-processo-liquida>
                    </mat-tab>
                    @if(processoSelecionado == 'liquida'){
                    <mat-tab label="DISPOSITIVO DE PINTURA">
                        <!-- @if(consultaDispositivoFlag){ -->
                        <app-eng-dispositivo-pintura
                            [fazerConsulta]="consultaDispositivoFlag"></app-eng-dispositivo-pintura>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE MONTAGEM">
                        @if(consultaPadraoMontagemFlag){
                        <app-eng-padrao-montagem [fazerConsulta]="consultaPadraoMontagemFlag"></app-eng-padrao-montagem>
                        }
                    </mat-tab>
                    <mat-tab label="DISPOSITIVO DE ISENÇÃO">
                        <!-- @if(consultaDispositivoIsencaoFlag){ -->
                        <app-eng-dispositivo-isencao
                            [fazerConsulta]="consultaDispositivoIsencaoFlag"></app-eng-dispositivo-isencao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE ISENÇÃO">
                        <!-- @if(consultaPadraoIsencaoFlag){ -->
                        <app-eng-padrao-isencao [fazerConsulta]="consultaPadraoIsencaoFlag"></app-eng-padrao-isencao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="CRITÉRIOS DE AUTOINSPEÇÃO">
                        <!-- @if(consultaCriteriosDeAutoInspecaoFlag){ -->
                        <app-eng-criterio-auto-inspecao
                            [fazerConsulta]="consultaCriteriosDeAutoInspecaoFlag"></app-eng-criterio-auto-inspecao>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="MEDIÇÃO DE CAMADA">
                        <!-- @if(consultaMedicaoCamadaFlag){ -->
                        <app-eng-medicao-camada [fazerConsulta]="consultaMedicaoCamadaFlag"> </app-eng-medicao-camada>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE EMBALAGEM">
                        <!-- @if(consultaPadraoEmbalagemFlag){ -->
                        <app-eng-padrao-embalagem [fazerConsulta]="consultaPadraoEmbalagemFlag">
                        </app-eng-padrao-embalagem>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="PADRÃO DE DEFEITOS">
                        <!-- @if(consultaPadraoDefeitosFlag){ -->
                        <app-eng-padrao-defeitos [fazerConsulta]="consultaPadraoDefeitosFlag">
                        </app-eng-padrao-defeitos>
                        <!-- } -->
                    </mat-tab>
                    <mat-tab label="ANÁLISE DE CAPABILIDADE">
                        <!-- @if(consultaPadraoAnaliseDeCapabilidadeFlag){ -->
                        <app-eng-analise-capabilidade
                            [fazerConsulta]="consultaPadraoAnaliseDeCapabilidadeFlag"></app-eng-analise-capabilidade>
                        <!-- } -->
                    </mat-tab>
                    }
                </mat-tab-group>
            </div>
        </div>
    </div>
    }
</app-container-component>
}
@if(!verificarSeExiste()){
@if(listaDeProcessoForm.length > 0){
<app-container-component tituloPage="DETERMINAÇÃO DE PROCESSO REGISTRAR" subtituloPage="Processos" [iconeVolta]="false">
    @for (item of listaDeProcessoForm; track item.id) {
    @if(verificarSeNaoTem(item)){
    <div class="accordion" id="accordion{{item.processo}}">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse' + item.processo" aria-expanded="true">
                    {{item.processo}}
                </button>
            </h2>
            @switch (true) {
            @case(item.processo.includes('KTL')){
            <div id="collapse{{item.processo}}" class="accordion-collapse collapse"
                [attr.data-bs-target]="'#collapse' + item.processo">
                <app-eng-determinacao-processo-ktl
                    (processoSalvo)="receberProcessoSalvoKtl($event)"></app-eng-determinacao-processo-ktl>
            </div>
            }
            @case (item.processo.includes('PÓ')){
            <div id="collapse{{item.processo}}" class="accordion-collapse collapse"
                [attr.data-bs-target]="'#collapse' + item.processo">
                <app-eng-determinacao-processo-po
                    (processoSalvo)="receberProcessoSalvoPo($event)"></app-eng-determinacao-processo-po>
            </div>
            }
            @case(item.processo.includes('LÍQUIDA')){
            <div id="collapse{{item.processo}}" class="accordion-collapse collapse"
                [attr.data-bs-target]="'#collapse' + item.processo">
                <app-eng-determinacao-processo-liquida (processoSalvo)="receberProcessoSalvoLiquida($event)">
                </app-eng-determinacao-processo-liquida>
            </div>
            }
            @case(item.processo.includes('LÍQUIDA ALTA TEMPERATURA')){
            <div id="collapse{{item.processo}}" class="accordion-collapse collapse"
                [attr.data-bs-target]="'#collapse' + item.processo">
                <app-eng-determinacao-processo-liquida
                    (processoSalvo)="receberProcessoSalvoLiquida($event)"></app-eng-determinacao-processo-liquida>
            </div>
            }
            @case(item.processo.includes('FOSFATO')){
            <div id="collapse{{item.processo}}" class="accordion-collapse collapse"
                [attr.data-bs-target]="'#collapse' + item.processo">
                <app-eng-determinacao-processo-fosfato
                    (processoSalvo)="receberProcessoSalvoFosfato($event)"></app-eng-determinacao-processo-fosfato>
            </div>
            }
            }
        </div>
    </div>
    }
    }
</app-container-component>
}
}