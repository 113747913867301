import { Component, Input } from '@angular/core';
import { EngDeterminacaoPo } from '../../../../../../model/engenharia/EngDeterminacaoPo';

@Component({
  selector: 'app-form-maquina-2',
  standalone: true,
  imports: [],
  templateUrl: './form-maquina-2.component.html',
  styleUrl: './form-maquina-2.component.scss'
})
export class FormMaquina2Component {
  @Input() processoDoPo: EngDeterminacaoPo = new EngDeterminacaoPo;;
}
