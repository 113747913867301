import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { Produto } from '../../../../model/engenharia/produto-model';
import { DispositivoIsencaoService } from '../../../../services/dispositivos/dispositivo-isencao.service';
import { BookService } from '../../../../services/producao/book.service';
import { EngDispositivoIsencaoPinturaService } from '../../../../services/engenharia/processo/eng-dispositivo-isencao-pintura.service';
import { EngPadraoIsencaoService } from '../../../../services/engenharia/processo/eng-padrao-isencao.service';
import { CommonModule } from '@angular/common';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";

@Component({
  selector: 'app-padrao-isencao',
  standalone: true,
  imports: [
    CommonModule,
    SwiperComponentComponent
],
  templateUrl: './padrao-isencao.component.html',
  styleUrl: './padrao-isencao.component.scss'
})
export class PadraoIsencaoComponent implements OnInit {
  padraoIsencaoProcesso1: any = [];
  padraoIsencaoProcesso2: any = [];
  imagemNaoCadastrada1 = false;
  imagemNaoCadastrada2 = false;
  produto: Produto = new Produto();
  processoDoProduto: string[] = [];
  dados: any;
  dispositivoIsencao: any;
  dispositivo: any;
  tabAtiva: string = '';
  @ViewChild("swiperPadraoIsencao1") swiperPadraoIsencao1!: ElementRef;
  @ViewChild("swiperPadraoIsencao2") swiperPadraoIsencao2!: ElementRef;
  swiper!: Swiper;


  constructor(
    private dispositivoService: DispositivoIsencaoService,
    private bookService: BookService,
    private dispositivoIsencaoPinturaServiece: EngDispositivoIsencaoPinturaService,
    private padraoIsencaoService: EngPadraoIsencaoService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }


  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.produto = new Produto();
        this.padraoIsencaoProcesso1 = undefined;
        this.padraoIsencaoProcesso2 = undefined;
        this.tabAtiva = '';
        this.produto = res;
        if (this.produto.nomeProduto) {
          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          this.consultarPadraoIsencaoProcesso1(this.dados.numeroProcesso1);
          this.consultarPadraoIsencaoProcesso2(this.dados.numeroProcesso2);
        }
      },
    });
  }

  public consultarPadraoIsencaoProcesso1(processo: number) {
    this.imagemNaoCadastrada1 = false;
    this.padraoIsencaoProcesso1 = undefined;
    this.padraoIsencaoService.consultarPadraoDeIsencao(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoIsencaoProcesso1 = res;
      },error: (e) => {
        this.imagemNaoCadastrada1 = true;
      },complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada();
        
      },
    });
  }

  public consultarPadraoIsencaoProcesso2(processo: number) {
    this.imagemNaoCadastrada2 = false;
    this.padraoIsencaoProcesso2 = undefined;
    this.padraoIsencaoService.consultarPadraoDeIsencao(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoIsencaoProcesso2 = res;
      },error: (e) => {
        this.imagemNaoCadastrada2 = true;
      },complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2();
      },
    });
  }

  private definirTabAtiva() {
    if (this.padraoIsencaoProcesso1) {
      this.tabAtiva = 'processo1';
      return;
    } if (this.padraoIsencaoProcesso2) {
      this.tabAtiva = 'processo2';
    }
  }

  private iniciarImagemCarregada() {
    this.iniciarImagemCarregadaParaArray(this.padraoIsencaoProcesso1);
  }

  private iniciarImagemCarregada2() {
    this.iniciarImagemCarregadaParaArray(this.padraoIsencaoProcesso2);
  }

  private iniciarImagemCarregadaParaArray(array: any[]) {
    array.forEach((e: any) => {
      if (!e.imagemCarregada) {
        e.imagemCarregada = false;
      }
    });
  }

}