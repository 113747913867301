<app-container-component [subtituloPage]="'Dispositivos cadastrados'" [tituloPage]="'DISPOSITIVO DE PINTURA'">
    <div class="row">
        <div class="col-12 d-flex flex-column justify-content-end align-items-end">
            <div class="col-12 col-lg-4 p-2">
                <div class="input-group">
                    <input type="text" class="form-control form-control-sm" placeholder="Consultar por nome"
                        [(ngModel)]="nomeDispositivo" (keypress)="this.buscar($event)">
                    <button class="btn btn-sm " type="button" (click)="consultaDispositivoPorNome()"><i
                            class="fa-solid fa-magnifying-glass"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            @if (dispositivos.length > 0) {
            <app-listar-dispositivos [dispositivos]="dispositivos" [caminho]="urlTipo" [paginaAtual]="paginaAtual"
                [totalPorPagina]="totalPorPagina" [totalTamanho]="totalTamanho"
                [quantidadesPorPagina]="quantidadesPorPagina" (paginaMudou)="onPageChange($event)"
                [exibirPaginacao]="paginacao">
            </app-listar-dispositivos>
            }
            @if(naoEncontradado){
            <div class="p-3">
                <div class=" alert alert-danger" role="alert">
                    Nenhum dispositivo encontrado!
                </div>
            </div>
            }
        </div>
    </div>
    @if(this.temPermissao()){
        <div class="botao-container">
            <div class="botao" (click)="openClose()">
                @if(!mostrarBolas){
                <i class="fa-solid fa-plus"></i>
                }@else {
                <i class="fa-solid fa-minus"></i>
                }
            </div>
            <div class="bola-adicionar" [class.show]="mostrarBolas" routerLink="/dispositivo/dispositivo">
                <span>Adicionar Dispositivo Pintura</span>
            </div>
            <div class="bola-adicionar2" [class.show]="mostrarBolas" routerLink="/dispositivo/dispositivo-isencao">
                <span>Adicionar Dispositivo isenção</span>
            </div>
        </div>
        }
</app-container-component>