import { MedicaoCamadaService } from './../../../../services/inspecao-equipamento/medicaoCamada.service';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { ActivatedRoute, Router } from '@angular/router';
import { MedicaoCamada } from '../../../../model/inspecao-equipamento/medicaoCamada';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { PageEvent } from '@angular/material/paginator';


@Component({
  selector: 'app-inspecao-medicao-camada',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ContainerComponentComponent, PaginatorComponent,FormsModule],
  templateUrl: './inspecao-medicao-camada.component.html',
  styleUrl: './inspecao-medicao-camada.component.scss'
})
export class InspecaoMedicaoCamadaComponent {

  listaMedicaoCamada: MedicaoCamada[] = [];
  valorPesquisado = "";
  buscarPorValidade = "";
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: number[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];

  constructor(
    private medicaoCamadaService: MedicaoCamadaService,
    private router: Router,
    private activatedRoute:ActivatedRoute,
  ) {

  }
  ngOnInit(): void {
    this.verificarSeExisteValorDeBusca()
  }

  public consultaTodasMedicaoCamadaPaginada() {
    this.medicaoCamadaService.buscarTodasMedicaoCamadaPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.listaMedicaoCamada = res.content;
        console.log(this.listaMedicaoCamada)
        this.totalTamanho = res.page.totalElements;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }
  public consultaTodasMedicaoCamadaPaginadaPorValidade() {
    this.medicaoCamadaService.buscarTodasMedicaoCamadaPaginadaPorValidade(this.buscarPorValidade,this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.listaMedicaoCamada = res.content;
        this.totalTamanho = res.page.totalElements;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  buscar(event?: Event) {
    if (event instanceof KeyboardEvent && event.key !== 'Enter') {
      return;
    }
    this.router.navigate([`/inspecao/medicao-camada/${this.valorPesquisado}`])
    if (this.valorPesquisado.trim() !== '' && this.valorPesquisado.length >= 2) {
      this.medicaoCamadaService.buscarTodasMedicaoCamadaPaginadaMerge(this.valorPesquisado,this.paginaAtual, this.totalPorPagina).subscribe({
        next: (res) => {
          this.listaMedicaoCamada = res.content;
          this.totalTamanho = res.page.totalElements;
        }, error: (error) => {

        }, complete: () => {

        }
      });
    } else {
      this.paginaAtual = 0;
      this.totalPorPagina = 10;
      this.consultaTodasMedicaoCamadaPaginada();
    }
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.router.navigate([`/inspecao/medicao-camada/${this.valorPesquisado}`])
      this.buscar(event);
    }
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.verificarValores(this.valorPesquisado);
   
  }

  public verificarValores(valorDeBusca:string =""){
    if(this.buscarPorValidade == "validade"){
      this.consultaTodasMedicaoCamadaPaginadaPorValidade();
    }else if(valorDeBusca != "validade" && valorDeBusca.trim() != ""){
      this.valorPesquisado = valorDeBusca;
      this.buscar();
    }
    else{
      this.consultaTodasMedicaoCamadaPaginada();
    }
  }

  public verificarSeExisteValorDeBusca(){
    this.activatedRoute.params.subscribe((res:any)=>{
      this.buscarPorValidade = res.valorBusca;
      this.verificarValores(res.valorBusca);
    });
  }

  public editarMedicaoCamada(id: number) {
    this.router.navigate([`inspecao/medicao-camada-registrar/${id}`])
  }
  
  public visualizar(id:number){
    this.router.navigate([`inspecao/medicao-camada/view/${id}`])
  }
}
