import { ActivatedRoute } from '@angular/router';
import { Component, inject, Input, Output, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { LoginService } from '../../../../services/login/login.service';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import AcoesPartesInteressadas from '../../../../model/acoes/partes-interessadas/AcoesPartesInteressadas';
import { PlanejamentoComunicacaoService } from '../../../../services/acoes/planejamento-comunicacao/planejamento-comunicacao.service';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import AcoesPlanejamentoComunicacao from '../../../../model/acoes/planejamento-comunicacao/AcoesPlanejamentoComunicacao';

@Component({
  selector: 'app-planejamento-comunicacao-acoes',
  standalone: true,
  imports: [ContainerComponentComponent],
  templateUrl: './planejamento-comunicacao-acoes.component.html',
  styleUrl: './planejamento-comunicacao-acoes.component.scss'
})
export class PlanejamentoComunicacaoAcoesComponent implements OnInit {

  idPlanejamentoComunicacao: number = 0
  
  @Input() ativarHeader:boolean = true;
  @Input() planejamentoComunicacaoRecebido: any;
  @Output() emitirEdicao: EventEmitter<AcoesPlanejamentoComunicacao> = new EventEmitter();

  private loginService = inject(LoginService);
  private planejamentoComunicacaoService = inject(PlanejamentoComunicacaoService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private snackBarService = inject(SnackBarService);
  private dialog = inject(MatDialog);
  private activatedRoute = inject(ActivatedRoute);


  ngOnInit(): void {
    this.verificarRota();
  }






  public verificarRota() {
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idPlanejamentoComunicacao = res.id;
        if (this.idPlanejamentoComunicacao != 0) {
          this.consultaPorId();
        }
      }
    });
  }
  


  public consultaPorId() {
    this.planejamentoComunicacaoRecebido = [];
    this.planejamentoComunicacaoService.consultarPorId(this.idPlanejamentoComunicacao).subscribe({
      next: (res) => {
        this.planejamentoComunicacaoRecebido = res;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public editarAcaoPlanejamentoComunicacaoSelecionada(acaoSelecionada: AcoesPlanejamentoComunicacao) {
    this.emitirEdicao.emit(acaoSelecionada);
    this.planejamentoComunicacaoService.emitirEdicao.emit(acaoSelecionada);
  }


  public deletarAcaoPlanejamentoComunicacaoSelecionada(acao: AcoesPlanejamentoComunicacao) {

    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: acao.oqueComunica, texto: "Deseja apagar esta ação do planejamento de comunicação" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.deletar(acao,this.idPlanejamentoComunicacao);
        };
      }
    });

  }
  private deletar(acao:any,id:number) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.planejamentoComunicacaoService.deletarAcoesPlanejamentoComunicacao(acao,id).subscribe({
      next: (res) => {
        this.planejamentoComunicacaoRecebido = res;
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar deletar ação")
      }, complete: () => {
        this.consultaPorId();
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.openSnackBar("Ação deletada com sucesso", "my-snack-bar-sucess");
      }
    });
  }


  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_ENG_EDIT']);
  }

  public temPermissaoExcluir() {
    return this.loginService.temPermissao(['ROLE_ENG_ADMIN']);
  }

}
