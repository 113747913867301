import { ChangeDetectorRef, Component, inject, Inject, OnInit } from '@angular/core';
import { OrdemDeServicoConsulta } from '../../../../../model/acoes/ordem-servico/ordem-servico-consulta';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrdemServicoService } from '../../../../../services/acoes/manutencao/ordem-servico.service';
import { ManutencaoOsCadastroDTO } from '../../../../../model/acoes/ordem-servico/ordem-servico-cadastro';
import { SnackBarService } from '../../../../../utils/snack-bar.service';
import { ManutencaoRegistroService } from '../../../../../services/acoes/manutencao/manutencao-registro.service';
import { ManutencaoRegistroConsulta } from '../../../../../model/acoes/atendimento/manutencao-registro-consulta';
import { PaginatorSistemicoComponent } from '../../../../../componentes/paginator-sistemico/paginator-sistemico/paginator-sistemico.component';

@Component({
  selector: 'app-modal-consulta',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    PaginatorSistemicoComponent,
  ],
  templateUrl: './modal-consulta.component.html',
  styleUrl: './modal-consulta.component.scss'
})
export class ModalConsultaComponent implements OnInit{

  os: OrdemDeServicoConsulta;
  osCadastro: ManutencaoOsCadastroDTO;
  apontamentoService = inject(ManutencaoRegistroService);
  apontamentos: ManutencaoRegistroConsulta[] = [];
  apontamentosPaginado: ManutencaoRegistroConsulta[] = [];
  itensPorPagina: number = 3;

  constructor(
    private matdialogRef: MatDialogRef<ModalConsultaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ordemServicoService: OrdemServicoService,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef
  ){
    this.os = new OrdemDeServicoConsulta();
    this.osCadastro = new ManutencaoOsCadastroDTO();
  }

  ngOnInit(): void {
    if(this.data){
      this.os = this.data[0];
      this.consultarApontamentos();
    }
  }

  public close(){
    this.matdialogRef.close();
  }

  public alterar() {
    this.preecherDados();
    if (this.validar()) {
      this.ordemServicoService.alterar(this.os.id,this.osCadastro).subscribe({
        next: (res) => {
          OrdemServicoService.emitirCadastro.emit(true);
          this.snackBarService.openSnackBar('Ordem de serviço alterada com sucesso!','my-snack-bar-sucess');
        },
        complete:()=>{
          this.osCadastro = new ManutencaoOsCadastroDTO();
          this.close();
        },
        error:(e)=>{
          this.snackBarService.tratarErro(e, 'Falha ao alterar OS!');
        }
      });
    } else {
      this.snackBarService.openSnackBar('Formulario inválido!', 'my-snack-bar-erro');
    }
  }

  public pegarMudanca(evento: any) {
    this.apontamentosPaginado = evento;
    this.cdr.detectChanges();// Força a detecção de mudanças manualmente
  }

  public consultarApontamentos(){
    this.apontamentoService.consultarApontamentosPorIdOs(this.os.id).subscribe({
      next:(res)=>{
        this.apontamentos = res;
      }
    });
  }

  public preecherDados(){
    this.osCadastro.idEquipamento = this.os.equipamento.id;
    this.osCadastro.nomeEquipamento = this.os.equipamento.descricao;
    this.osCadastro.solicitacao = this.os.solicitacao;
    this.osCadastro.prioridade = this.os.prioridade;
    this.osCadastro.stopProd = this.os.stopProd;
  }
  
  public validar(): boolean {
    if (this.osCadastro.idEquipamento === undefined || this.osCadastro.prioridade === undefined || this.osCadastro.prioridade === undefined || this.osCadastro.solicitacao === undefined) {
      return false;
    }
    return true;
  }
 
}
