import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';

export const verificarPermissoesGuard: CanActivateFn = (route, state) => {
  const permissaoDaRota = route.data['permissao'];
  const loginService  = inject(LoginService);
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  let id = 0;
  let token = "";
  activatedRoute.params.subscribe((res:any)=>{
    id = parseInt(res.id)
    token = res.token;
  })
  
  if(loginService.temPermissao(permissaoDaRota)){
    LoginService.naoAutorizado.emit(false);
    return true;
  }else{
    LoginService.naoAutorizado.emit(true);
    return false;
  } 

};
