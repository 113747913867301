<app-container-component [tituloPage]="titulo" [subtituloPage]="nomeFormulario">
  <table class="table table-hover table-sm table-striped">
    <thead>
      <tr>
        <th>Indicador</th>
        <th>Processo</th>
        <th>Tipo</th>
        <th>Responsável</th>
        <th>Status</th>
        <th class="text-center">Editar</th>
        <th class="text-center">Gerenciar</th>
      </tr>
    </thead>
    <tbody>
      @for (indicador of listaDeIndicadores; track $index) {
      <tr>
        <td>{{indicador.indicador}}</td>
        <td>{{indicador.processo}}</td>
        <td>{{indicador.tipo}}</td>
        <td>{{indicador.responsavel}}</td>
        <td class="text-truncate" style="max-width: 200px;">{{indicador.status}}</td>
        @if(indicador.responsavel === token.nome || token.permissoes.includes('ROLE_ADMIN') ||token.permissoes.includes('ROLE_IND_EDIT')){
        <td (click)="editarIndicador(indicador.id)" class="text-center">
          <i class="fa-solid fa-pen-to-square"></i>
        </td>
        <td (click)="gerenciarIndicador(indicador.id, indicador.status)" class="text-center">
          <i class="fa-solid fa-gear"></i>
        </td>
        }@else {
        <td class="text-center text-danger"><i class="fa-solid fa-ban"></i></td>
        <td class="text-center text-danger"><i class="fa-solid fa-ban"></i></td>
        }
      </tr>
      }
    </tbody>
  </table>
  <app-paginator (paginaMudou)="mudancaNaPagina($event)" [paginaAtual]="paginaAtual"
    [quantidadesPorPagina]="quantidadesPorPagina" [totalElementos]="totalTamanho" [totalPorPagina]="totalPorPagina">
  </app-paginator>
</app-container-component>