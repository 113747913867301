<app-container-component [tituloPage]="titulo" [subtituloPage]="nomeFormulario">
    <table class="table table-sm table-striped">
        <thead class="table-secondary">
            <tr>
                <th>Indicador</th>
                <th>Processo</th>
                <th>Tipo</th>
                <th>Responsável</th>
                <th>Status</th>
                <th class="text-center ">Editar</th>
                <th class="text-center ">Gerenciar</th>
            </tr>
        </thead>
        <tbody>
            @for (indicador of listaDeIndicadores; track $index) {
            <tr>
                <td >{{indicador.indicador}}</td>
                <td>{{indicador.processo}}</td>
                <td>{{indicador.tipo}}</td>
                <td>{{indicador.responsavel}}</td>
                <td class="text-truncate" style="max-width: 200px;">{{indicador.status}}</td>
                @if(indicador.responsavel === token.nome || token.permissoes.includes('ROLE_ADMIN') || token.permissoes.includes('ROLE_IND_EDIT')){
                    <td class="text-center c-pointer"  (click)="editarIndicador(indicador.id)"><i class="fa-solid fa-pencil"></i></td>
                   @if(indicador.status == 'Ativo'){
                    <td class="text-center c-pointer" (click)="gerenciarIndicador(indicador.id)"><i class="fa-solid fa-gear"></i></td>
                   }@else{
                    <td class="text-center">-</td>
                   }
                }@else {
                    <td class="text-center text-danger"><i class="fa-solid fa-ban"></i></td>
                    <td class="text-center text-danger"><i class="fa-solid fa-ban"></i></td>
                }
            </tr>
            }
        </tbody>
    </table>
    <app-paginator 
    (paginaMudou)="mudancaNaPagina($event)"
    [paginaAtual]="paginaAtual"
    [quantidadesPorPagina]="quantidadesPorPagina"
    [totalElementos]="totalTamanho"
    [totalPorPagina]="totalPorPagina">

    </app-paginator>
</app-container-component>