<div class="card">
  @if(quantidadeDeInformacaoDocumentadaPendente != 0){
  <div class="card-wrapper" (click)="openLink('PENDENTE')">
    <div class="card-icon">
      <div class="icon-cart-box">
        <span class="card-action">
          <i class="fa-solid " [ngClass]="icone"></i>
        </span>
      </div>
    </div>
    <div class="card-content">
      <div class="card-title-wrapper">
        <span class="card-title">Acões</span>
      </div>
      <div class="card-text">
        Você tem documentos pendentes <span>{{quantidadeDeInformacaoDocumentadaPendente}} <i
            class="fa-solid fa-bell"></i></span>
      </div>
      <button type="button" class="btn-accept">
        Ver pendências <span>{{quantidadeDeInformacaoDocumentadaPendente}} <i
            class="fa-solid fa-bell"></i></span>
      </button>
    </div>
  </div>
  }@else {
    Nenhuma notificação encontrada
  }
</div>