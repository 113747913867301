<!-- PADRÃO DE MONTAGEM -->
<div class="separador mt-2 mb-2" #padraoEmbalagem>
    <strong>PADRÃO DE EMBALAGEM</strong>
</div>
@defer (on viewport(padraoEmbalagem)) {
@if(tabAtiva != ''){
<!-- Nav Tabs -->
<ul class="nav nav-tabs" id="tabEmbalagem" role="tablist">
    @if(dados.processo1 != ''){
    <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="{'active': tabAtiva === 'processo1'}" id="embalagem1-tab" data-bs-toggle="tab"
            href="#embalagem1" (click)="consultarPadraoEmbalagemProcesso1Click(dados.numeroProcesso1)" role="tab"
            aria-controls="processo1" aria-selected="true">{{dados.processo1}}</a>
    </li>
    }
    @if(dados.processo2 != ''){
    <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="{'active': tabAtiva === 'processo2'}" id="embalagem2-tab" data-bs-toggle="tab"
            href="#embalagem2" role="tab" (click)="consultarPadraoEmbalagemProcesso2Click(dados.numeroProcesso2)"
            aria-controls="processo2" aria-selected="false">{{dados.processo2}}</a>
    </li>
    }
</ul>

<!-- Tab Content -->
<div class="tab-content mt-3" id="myTabContent">
    <!-- Conteúdo da Tab Processo 1 -->
    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo1'}" id="embalagem1" role="tabpanel"
        aria-labelledby="embalagem1-tab">
        <div class="position-img">
            <div class="col-12 ">
                @if(padraoEmbalagemProcesso1 == undefined){
                <div class="card-load-box">
                    <div class="card_load_extreme_descripion"></div>
                </div>
                }@else if (imagemNaoCadastrada1) {
                <div class="col-12 text-center text-danger">
                    <h3>Imagem não cadastrada!</h3>
                </div>
                }
                <app-swiperComponent [listaDeDados]="padraoEmbalagemProcesso1"></app-swiperComponent>
            </div>
        </div>
    </div>

    <!-- Conteúdo da Tab Processo 2 -->
    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo2'}" id="embalagem2" role="tabpanel"
        aria-labelledby="embalagem2-tab">
        <div class="position-img">
            <div class="col-12 ">
                @if(padraoEmbalagemProcesso2 == undefined){
                <div class="card-load-box">
                    <div class="card_load_extreme_descripion"></div>
                </div>
                }@else if (imagemNaoCadastrada2) {
                <div class="col-12 text-center text-danger">
                    <h3>Imagem não cadastrada!</h3>
                </div>
                }
                <app-swiperComponent [listaDeDados]="padraoEmbalagemProcesso2"></app-swiperComponent>
            </div>
        </div>
    </div>
</div>
}@else {
<div class="text-center text-danger mt-3">
    <p class="alert alert-danger">Padrão de embalagem não encontrado!</p>
</div>
}
}@loading {
<div class="card-load-box">
    <div class="card_load_extreme_descripion"></div>
</div>
}