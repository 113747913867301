import { Routes } from "@angular/router";
import { HomeIndicadoresComponent } from "../../paginas/indicadores/home-indicadores/home-indicadores.component";
import { IndicadoresPerformanceHomeComponent } from "../../paginas/indicadores/indicadores-performance/indicadores-performance-home/indicadores-performance-home.component";
import { IndicadoresGerenciaisHomeComponent } from "../../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-home/indicadores-gerenciais-home.component";
import { IndicadoresIqfClientesHomeComponent } from "../../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-home/indicadores-iqf-clientes-home.component";
import { IndicadoresIqfFornecedoresHomeComponent } from "../../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-home/indicadores-iqf-fornecedores-home.component";
import { IndicadoresMonitoramentoHomeComponent } from "../../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-home/indicadores-monitoramento-home.component";
import { IndicadorObjQualidadeHomeComponent } from "../../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-home/indicador-obj-qualidade-home.component";
import { IndicadoresGerenciaisListaComponent } from "../../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-lista/indicadores-gerenciais-lista.component";
import { IndicadoresPerformanceListaComponent } from "../../paginas/indicadores/indicadores-performance/indicadores-performance-lista/indicadores-performance-lista.component";
import { IndicadoresMonitoramentoListaComponent } from "../../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-lista/indicadores-monitoramento-lista.component";
import { IndicadoresIqfClientesListComponent } from "../../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-list/indicadores-iqf-clientes-list.component";
import { IndicadoresIqfFornecedoresListComponent } from "../../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-list/indicadores-iqf-fornecedores-list.component";
import { IndicadorObjQualidadeListComponent } from "../../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-list/indicador-obj-qualidade-list.component";

export const routesIndicadoresView: Routes = [

    //Indicadores Performance
    { path: "home", component: HomeIndicadoresComponent },
    { path: "performance", component: IndicadoresPerformanceHomeComponent },
    { path: "performance-lista", component: IndicadoresPerformanceListaComponent },

    ///Indicadores Gerenciais
    { path: "gerenciais", component: IndicadoresGerenciaisHomeComponent },
    { path: "gerenciais-lista", component: IndicadoresGerenciaisListaComponent },

    ///Indicadores Monitoramento
    { path: "monitoramento", component: IndicadoresMonitoramentoHomeComponent },
    { path: "monitoramento-lista", component: IndicadoresMonitoramentoListaComponent },


    ///Indicadores IOF CLIENTES
    { path: "clientes", component: IndicadoresIqfClientesHomeComponent },
    { path: "clientes-lista", component: IndicadoresIqfClientesListComponent },


    ///Indicadores IOF FORNECEDORES
    { path: "fornecedores", component: IndicadoresIqfFornecedoresHomeComponent },
    { path: "fornecedores-lista", component: IndicadoresIqfFornecedoresListComponent },


    ///Indicadores QUALIDADE
    { path: "obj-qualidade", component: IndicadorObjQualidadeHomeComponent },
    { path: "obj-qualidade-lista", component: IndicadorObjQualidadeListComponent },

]