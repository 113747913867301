<app-container-component [header]="false" [caixaFormulario]="true" [subtituloPage]="'DETERMINAÇÃO KTL'">
    <form class="form-group  col-12" [formGroup]="formularioDeterminacaoProcessoKtl">
        <div class="row sessao-produto delay-1">
            <!-- <div class="mb-3 col-12  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Característica:</label>
                <input type="text" formControlName="nome" class="form-control form-control-sm">
            </div> -->
            <div class="mb-3 col-12  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">Quantidade de peças na gancheira:</label>
                <input type="number" formControlName="qtdePecasGancheira" (input)="calcularQuantidadePorRack()"
                    class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasGancheira')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">Quantidade de gancheiras por rack:</label>
                <input type="number" formControlName="qtdeGancheirasRack" (input)="calcularQuantidadePorRack()"
                    class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdeGancheirasRack')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">Quantidade de peças por rack:</label>
                <input type="number" formControlName="qtdePecasRack" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasRack')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="text" class="form-label">Receita:</label>
                <input type="number" formControlName="receita" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('receita')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="text" class="form-label">Parâmetros da estufa:</label>
                <input type="text" formControlName="parametroEstufa" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <label for="text" class="form-label">Amostra para inspeção:</label>
                <textarea type="text" formControlName="amostraInspecao" class="form-control form-control-sm"></textarea>
            </div>
            <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de set-up de dispositivo:</label>
                <input type="number" formControlName="tempoSetupDispositivo" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoSetupDispositivo')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de montagem:</label>
                <input type="number" formControlName="tempoMontagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoMontagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de isenção (anterior):</label>
                <input type="number" formControlName="tempoIsencaoAnterior" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoIsencaoAnterior')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de máquina:</label>
                <input type="number" formControlName="tempoMaquina" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoMaquina')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de resfriamento:</label>
                <input type="number" formControlName="tempoResfriamento" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoResfriamento')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de desmontagem:</label>
                <input type="number" formControlName="tempoDesmontagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoDesmontagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de inspeção:</label>
                <input type="number" formControlName="tempoInspecao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoInspecao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de isenção (posterior):</label>
                <input type="number" formControlName="tempoIsencaoPosterior" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoIsencaoPosterior')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">Tempo de embalagem:</label>
                <input type="number" formControlName="tempoEmbalagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoEmbalagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">LeadTime:</label>
                <input type="number" formControlName="leadTime" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('leadTime')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">Qtde/peças por hora da máquina:</label>
                <input type="number" formControlName="qtdePecasHoraMaquina" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasHoraMaquina')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="text" class="form-label">Camada Minima:</label>
                <input type="number" formControlName="camadaMinima" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('camadaMinima')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="text" class="form-label">Camada Maxima:</label>
                <input type="number" formControlName="camadaMaxima" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('camadaMaxima')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <label for="text" class="form-label">EPI:</label>
                <input type="text" formControlName="epi" class="form-control form-control-sm">
            </div>
            <div class="col-12 mb-3">
                <label for="text" class="form-label"><span>Descrição:</span></label>
                <textarea formControlName="observacao" class="form-control form-control-sm dynamic-textarea"
                    (input)="autoGrow($event)"></textarea>
            </div>
            <div class="row justify-content-start gap-2">
                @if(idProcesso && formularioDeterminacaoProcessoKtl.controls['id'].value){
                <app-botao label="Editar" class="col-12 col-lg-2" icon="icons8-lápis.png" status="btn-salvar"
                    (click)="editarDeterminacaoKtl()"></app-botao>
                }@else {
                <app-botao label="Salvar" class="col-12 col-lg-2" icon="icons8-disquete.png" status="btn-salvar"
                    (click)="salvarDeterminacaoKtl()"></app-botao>
                <app-botao label="Cancelar" class="col-12 col-lg-2" icon="icons8-x.png" status="btn-cancelar"
                    (click)="limparFormulario()"></app-botao>
    
                }
            </div>
        </div>
    </form>
</app-container-component>