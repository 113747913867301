import { NotificacoesSistemaService } from './../../services/infra/notificacoes-sistema.service';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EmisorEventoGeralService } from '../../utils/emisorEventoGeral.service';
import { ManuaisClientesService } from '../../services/controles/manuais-clientes/manuais-clientes.service';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../services/login/login.service';
import { SnackBarService } from '../../utils/snack-bar.service';
import { interval, Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  url: any;
  // Variável que armazena o total de notificações não lidas
  quantidadeTotalDeNotificacao: number = 0;
  tipo = environment.tipo;
  linkServe = `${environment.url}/home`;

  //Injeções de dependencia
  router = inject(Router);
  bottomSheet = inject(MatBottomSheet);
  notificacoesSistemaService = inject(NotificacoesSistemaService);
  manualDoClienteService = inject(ManuaisClientesService);
  emisorEventoGeral = inject(EmisorEventoGeralService);
  loginService = inject(LoginService);
  snackbarService = inject(SnackBarService);
  ambiente: string = '';

  // Subject que será usado para parar o polling corretamente quando o componente for destruído
  private stopPolling$ = new Subject<void>();

  constructor(

  ) {

  }

  ngOnInit(): void {
    this.capturarAmbiente();// Captura o tipo de ambiente
    this.checarNotificacoesNaoLidas();// Faz uma verificação inicial de notificações
    this.startNotificacoesAutoUpdate();// Inicia o polling para checar notificações automaticamente
  }
  openBottomSheet(): void {
    this.router.navigate(['/notificacoes-sistema'])
  }

  /**
   * Método chamado quando o componente é destruído.
   * Ele é essencial para evitar vazamento de memória!
   */
  ngOnDestroy(): void {
    this.stopNotificacoesAutoUpdate();
  }


  private capturarAmbiente() {
    this.ambiente = environment.tipo.toUpperCase();
  }

    /**
   * Método responsável por checar quantas notificações não lidas existem.
   * Ele chama o serviço `NotificacoesSistemaService` e atualiza a variável `quantidadeTotalDeNotificacao`.
   */
  public checarNotificacoesNaoLidas() {
    this.notificacoesSistemaService.consultaQtdeNotificacoesNaoLida().subscribe({
      next: (res) => {
        this.quantidadeTotalDeNotificacao = res;
      },
      error: (e) => {
       console.log(e);
      }
    });
  }

    /**
   * Inicia o processo de atualização automática das notificações a cada 10 segundos.
   * Em vez de `setInterval`, usamos o `interval` do RxJS para melhor controle e evitar vazamento de memória.
   */
  private startNotificacoesAutoUpdate(): void {
    interval(10000) // Executa a cada 10 segundos
      .pipe(takeUntil(this.stopPolling$)) // Para quando o Subject emitir um valor
      .subscribe(() => {
        this.checarNotificacoesNaoLidas();
      });
  }

    /**
   * Método responsável por parar o processo de atualização automática das notificações.
   * Chamado no `ngOnDestroy` para evitar vazamento de memória.
   */
  private stopNotificacoesAutoUpdate(): void {
    this.stopPolling$.next(); // Encerra os eventos
    this.stopPolling$.complete(); // Marca o Subject como finalizado
  }

    /**
   * Método responsável por fazer o logout do sistema.
   * Ele limpa os dados armazenados no `localStorage` e recarrega a página.
   */
    public logOffSistema() {
      localStorage.clear(); // Remove todas as informações armazenadas localmente
      this.router.navigate(['/login']);
      window.location.reload(); // Recarrega a página para garantir uma limpeza completa da sessão
    }

}
