<div class="notify-content">
    <div class="row">
        <div class="col-12 col-lg-3">
            <div class="status-msg d-flex justify-content-between">
                <div class="lida text-center" [ngClass]="{ 'selected-nao-lida': selectedStatus === 'Não lida' }"
                    (click)="selectStatus('Não lida')">
                    <label>Não lida <i class=" ms-2 fa-solid fa-envelope"></i></label>
                </div>
                <div class="nao_lida text-center" [ngClass]="{ 'selected-lida': selectedStatus === 'Lida' }"
                    (click)="selectStatus('Lida')">
                    <label>Lida <i class=" ms-2 fa-solid fa-envelope-open"></i></label>
                </div>
            </div>
            @if(notificacoes.length > 0){
            @if( selectedStatus == "Lida"){
            <div class="row ms-2 gap-1 justify-content-center">
                @if(exclusaoAtiva){
                <button class="btn btn-sm col-5" (click)="trocarOpcao()">Cancelar seleção</button>
                <button class="btn btn-sm col-5 flex-grow-1" (click)="selecionarTudo()">{{selecionadoTudo ? 'Desmarcar tudo ':"Selecionar Tudo"}}</button>
                <button class="btn btn-sm col-12 mb-2" (click)="deleteSelected()">Apagar todas as notificações</button>
                }@else {
                <button class="btn btn-sm col-6" (click)="trocarOpcao()">Ativar seleção</button>
                }
            </div>
            }
            @if(notificacoesPaginadas.length > 0){
            @for (notificacao of notificacoesPaginadas; track $index) {
            <div class="container-mensagem">
                <div class="row notificacao" (click)="visualizarMensagem(notificacao,$event)"
                    [ngClass]="notificacao.id == this.mensagem.id ? 'selected' : ''">
                    <div class="col-10 " (click)="pegarNotificacao(notificacao,$event)">
                        <div class="text-truncate"><strong>{{notificacao.remetente?.nome}}</strong></div>
                        <div class="text-truncate">{{notificacao.assunto}}</div>
                        <div class="data">{{notificacao.dataCadastro | date : 'dd/MM/YYYY'}}</div>
                    </div>
                    <div class="col-2 icone-lixeira  d-flex flex-column justify-content-between align-items-center">
                        @if(notificacao.status == 'Não lida'){
                        <i class="fa-solid fa-envelope"></i>
                        }@else{
                        <i class="fa-solid fa-envelope-open"></i>
                        <i class="fa-solid fa-trash-can text-danger" *ngIf="!exclusaoAtiva"
                            (click)="excluirNotificacao(notificacao.id,notificacao.assunto!)"></i>
                        }
                    </div>
                </div>
                <input type="checkbox" *ngIf="exclusaoAtiva" [(ngModel)]="notificacao.selected"
                    (click)="pegarNotificacao(notificacao,$event)" />
            </div>
            }
            }
            <div class="row">
                <div class="col-9">
                    <app-paginator-sistemico [itens]="notificacoes" (itensPaginados)="receberItensPaginados($event)"
                        [itemsPorPagina]="quantidadePorPagina"
                        [ajustarQuantidadePorPagina]="false"></app-paginator-sistemico>
                </div>
                <div class="col-3">
                    <select class="btn btn-sm col-12" class="form-select form-select-sm" (change)="refazerLista()"
                        [(ngModel)]="quantidadePorPagina">
                        <option>{{quantidadePorPagina}}</option>
                        <option>7</option>
                        <option>14</option>
                        <option>28</option>
                    </select>
                </div>
            </div>

            }@else {
            <div class="text-center"><strong>Não há notificações {{selectedStatus === 'Não lida' ? 'Não lida' :
                    'Lida'}}</strong></div>
            }
        </div>
        <div class="col-12 col-lg-9 d-flex  justify-content-center ">
            <div class="assunto " [@slideUpToBottom]="animationState">
                @if(mensagem.assunto){
                <div class="header-notificaca">
                    <h4 class="mt-2">{{mensagem.assunto}}</h4>
                    <label><strong>Remetente: </strong>{{mensagem.remetente?.nome}}</label>
                </div>

                <div class="body-notificacao">
                    <div class="msg mt-2">
                        <p>{{mensagem.mensagem}}</p>
                    </div>
                    <div class="mt-5">
                        <a href="{{mensagem.url}}" target="_blank">Clique aqui para visualizar</a>
                    </div>
                </div>
                }@else {
                <span class="p-2 text-center">Selecione alguma notificação para ler</span>
                }
            </div>
        </div>
    </div>
</div>