import { Routes } from "@angular/router";

export const  routesGestaoEdit:Routes = [
    // GESTÃO MELHORIA
    { path: "gestao-melhoria-registrar", loadComponent: () => import('../../paginas/gestao/gestao-de-melhorias/gestao-de-melhorias-cadastro/gestao-de-melhorias-cadastro.component').then(g => g.GestaoDeMelhoriasCadastroComponent) },
    { path: "gestao-melhoria-registrar/:id", loadComponent: () => import('../../paginas/gestao/gestao-de-melhorias/gestao-de-melhorias-cadastro/gestao-de-melhorias-cadastro.component').then(g => g.GestaoDeMelhoriasCadastroComponent) },

    // GESTÃO MUDANÇA
    { path: "gestao-mudanca-registrar", loadComponent: () => import('../../paginas/gestao/gestao-de-mudancas/gestao-mudanca-cadastro/gestao-mudanca-cadastro.component').then(g => g.GestaoMudancaCadastroComponent) },

    // GESTÃO FORNECEDOR
    { path: "gestao-fornecedor-registrar", loadComponent: () => import('../../paginas/gestao/gestao-de-fornecedores/gestao-de-fornecedores-cadastro/gestao-de-fornecedores-cadastro.component').then(g => g.GestaoDeFornecedoresCadastroComponent) },
    { path: "gestao-fornecedor-editar/:id", loadComponent: () => import('../../paginas/gestao/gestao-de-fornecedores/gestao-de-fornecedores-editar/gestao-de-fornecedores-editar.component').then(g => g.GestaoDeFornecedoresEditarComponent) },
]