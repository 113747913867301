<app-container-component tituloPage="Gestão de forncedores" subtituloPage="Aquisição">
    <div
        class="row justify-content-center justify-content-lg-evenly justify-content-xl-center  align-items-center p-3  gap-2">
        <div class="col-12 col-md-12  col-lg-3 col-xl-2 card-contador sessao-produto" id="fornecedores">
            <label class="text-center"><strong>Fornecedores</strong> <i
                    class="fa-solid fa-person-circle-check"></i></label>
            <p class="text-center">{{ this.listaDeGestaoFornecedor.length }}</p>
        </div>
        <div class="col-12 col-md-12  col-lg-3 col-xl-2 card-contador sessao-produto" id="inativos">
            <label class="text-center"><strong>Inativos</strong> <i class="fa-solid fa-person-circle-xmark"></i></label>
            <p class="text-center">{{ this.listaDeGestaoFornecedorInativos.length }}</p>
        </div>
    </div>
</app-container-component>

<app-container-component [header]="false" subtituloPage="Dados">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>Projeto</th>
                <th>Identificação</th>
                <th>Criação</th>
                <th>Atualização</th>
                <th>Responsável</th>
                <th>Atualizado por</th>
                <th>Status</th>
                <th class="text-center">Ver</th>
                @if(temPermissao()){
                <th class="text-center">Editar</th>
                }
            </tr>
        </thead>
        <tbody>
            @for (item of listaDeGestaoFornecedor; track $index) {
            <tr>
                <td>{{item.id}}</td>
                <td>{{item.fornecedor}}</td>
                <td>{{item.dataCadastro | date:'dd/MM/yyyy'}}</td>
                <td>{{item.dataAlteracao | date:'dd/MM/yyyy'}}</td>
                <td>{{item.usuarioCadastro}}</td>
                <td>{{item.usuarioAlteracao}}</td>
                <td>{{item.status}}</td>
                <td class="text-center c-pointer" (click)="visualizarItem(item.id)"><i class="fa-solid fa-eye"></i></td>
                @if(temPermissao()){
                <td class="text-center c-pointer" (click)="editarItem(item.id)"><i class="fa-solid fa-pencil"></i></td>
                }

            </tr>
            }

        </tbody>
    </table>
</app-container-component>