<app-container-component [tituloPage]="titulo" [subtituloPage]="subTitulo" [alinharAocentro]="'align-items-center'">
    <!-- <div class="row col-12 p-3">
        <div class="col-12 col-lg-6 ">
            <div class="col-12 d-flex flex-column justify-content-between align-items-center">
                <img class="imagem-qualidade" src="assets/boneco_qualidade.svg" alt="">
                <h5> <b>Os seguintes Objetivos da Qualidade são definidos nos níveis e funções necessárias para o
                        Sistema
                        de
                        Gestão da Qualidade e medidos e divulgados anualmente:</b></h5>
                <ul class="mt-3 gap-2 d-flex flex-column">
                    <li>Satisfação do Cliente
                        Medição do índice de satisfação do cliente através de pesquisa anual realizada através de
                        plataforma
                        on-line e índice do IQF de clientes.
                        % de satisfação dos clientes.
                    </li>
                    <li>Capacitação de Pessoas
                        Medição de horas de treinamento anuais por colaborador.
                        % horas de treinamento por colaborador ativo. (média de horas de treinamento por colaborador)
                    </li>
                    <li>Eficácia dos Processos
                        Medição da performance dos processos do sistema de gestão da qualidade.
                        % anual de indicadores de performance com resultados dentro da meta (quantidade de indicadores
                        com
                        resultado acima de 80%/ total de indicadores de performance)*100
                    </li>
                    <li> Produto Não Conforme
                        Medição do índice de produto não conforme.
                        % (peças não conforme / total de peças produzidas)*100
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="col-12 d-flex justify-content-center mt-5">
                <img src="assets/politica_qualidade-1.webp" class="img-fluid" alt="">
            </div>
        </div>
    </div> -->
    <div class="swiper mySwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <div class="col-12 col-lg-6 ">
                    <div class="col-12 mb-3 d-flex flex-column justify-content-between align-items-center">
                        <img class="imagem-qualidade" src="assets/boneco_qualidade.svg" alt="">
                        <h5> <b>Os seguintes Objetivos da Qualidade são definidos nos níveis e funções necessárias para o
                                Sistema
                                de
                                Gestão da Qualidade e medidos e divulgados anualmente:</b></h5>
                        <ul class="mt-3 gap-2 d-flex flex-column">
                            <li>Satisfação do Cliente
                                Medição do índice de satisfação do cliente através de pesquisa anual realizada através de
                                plataforma
                                on-line e índice do IQF de clientes.
                                % de satisfação dos clientes.
                            </li>
                            <li>Capacitação de Pessoas
                                Medição de horas de treinamento anuais por colaborador.
                                % horas de treinamento por colaborador ativo. (média de horas de treinamento por colaborador)
                            </li>
                            <li>Eficácia dos Processos
                                Medição da performance dos processos do sistema de gestão da qualidade.
                                % anual de indicadores de performance com resultados dentro da meta (quantidade de indicadores
                                com
                                resultado acima de 80%/ total de indicadores de performance)*100
                            </li>
                            <li> Produto Não Conforme
                                Medição do índice de produto não conforme.
                                % (peças não conforme / total de peças produzidas)*100
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="col-12 col-lg-6 mb-3">
                    <div class="col-12 d-flex justify-content-center mt-5">
                        <img src="assets/politica_qualidade-1.webp" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
    

        </div>
        <div class="swiper-pagination d-flex justify-content-center my"></div>
    </div>
</app-container-component>
<app-container-component [header]="false" subtituloPage="Documento Controlado">
    <div class="col-12 col-lg-12 d-flex">
        <div class="col-3 d-flex justify-content-center align-items-center">
            <img src="assets/doc_controlado.webp" class="imagem-doc" alt="">
        </div>
        <div class="col-9 d-flex flex-column justify-content-center ">
            <!-- <h5>Documento Controlado</h5> -->
            <p class="mb-1"><b>Elaborado por:</b> Denise Oliveira</p>
            <p class="mb-1"> <b>Aprovado por:</b> Roberto Bertoli</p>
            <p class="mb-1">Revisão 3 – 24/05/2017</p>
        </div>
    </div>
</app-container-component>