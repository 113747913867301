import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EngDispositivoPintura } from '../../../model/engenharia/engDispositivoPintura';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngDispositivoPinturaService {


  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }

  public salvarEngDispositivoPintura(engDeterminacaoProcesso: EngDispositivoPintura): Observable<EngDispositivoPintura> {
    return this.http.post<EngDispositivoPintura>(`${this.url}/eng-dispositivo-pintura`, engDeterminacaoProcesso);
  }

  public consultarEngDispositivoPintura(cdProduto:number): Observable<EngDispositivoPintura[]> {
    return this.http.get<EngDispositivoPintura[]>(`${this.url}/eng-dispositivo-pintura/${cdProduto}`);
  }

  public consultarEngDispositivoPinturaPorIdProcesso(cdProduto:number,cdProcesso:number): Observable<EngDispositivoPintura[]> {
    return this.http.get<EngDispositivoPintura[]>(`${this.url}/eng-dispositivo-pintura/${cdProcesso}/${cdProduto}`);
  }

  public deletarEngDispositivoPintura(id:number): Observable<EngDispositivoPintura> {
    return this.http.delete<EngDispositivoPintura>(`${this.url}/eng-dispositivo-pintura/${id}`);
  }

}
