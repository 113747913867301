import { Component } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { IndicadorGerenciais } from '../../../../model/indicadores/indicadoresGerenciais/IndicadorGerenciais';
import { IndicadoresGerenciaisService } from '../../../../services/indicadores/indicadores-gerenciais.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import moment from 'moment';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { LoginService } from '../../../../services/login/login.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';

@Component({
  selector: 'app-indicadores-gerenciais-lista',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, PaginatorComponent],
  templateUrl: './indicadores-gerenciais-lista.component.html',
  styleUrl: './indicadores-gerenciais-lista.component.scss'
})
export class IndicadoresGerenciaisListaComponent {
  titulo = "INDICADOR GERENCIAL";
  nomeFormulario = "INDICADORES";
  listaDeIndicadores: IndicadorGerenciais[] = []

  paginaAtual: number = 1;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  anoAtual = moment().format("yyyy");
  token: any;
  constructor(
    private indicadoresGerenciaisService: IndicadoresGerenciaisService,
    private router: Router,
    private loginService: LoginService,
    private snackbarService: SnackBarService
  ) {

  }

  ngOnInit(): void {
    this.decodificarToken();
  }

  private decodificarToken(){
    this.token = this.loginService.decodificarToken();
    this.consultaIndicadores();
  }

  public consultaIndicadores() {
    this.indicadoresGerenciaisService.consultaIndicadorGerenciaisPorAno(this.paginaAtual, this.totalPorPagina, this.anoAtual).subscribe({
      next: (res: any) => {
        console.log(res);
        this.listaDeIndicadores = res.content;
        this.totalTamanho = res.page.totalElements;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public gerenciarIndicador(idIndicador: number, status: string) {
    if(status.toUpperCase() == 'ATIVO'){
      this.router.navigate([`indicadores/indicador-gerenciais-resultado/${idIndicador}/${this.anoAtual}`]);
    }else{
      this.snackbarService.openSnackBar('Ativo o indicador para poder gerencia-lo! ', 'my-snack-bar-erro')
    }
  }

  public editarIndicador(idIndicador: number) {
    this.router.navigate([`indicadores/gerenciais-cadastro/${idIndicador}`]);
  }

  public mudancaNaPagina(evento: any) {
    this.paginaAtual = evento.pageIndex;
    this.totalPorPagina = evento.pageSize;
    this.consultaIndicadores();
  }
}
