import { Routes } from "@angular/router";
import { HomeIndicadoresComponent } from "../paginas/indicadores/home-indicadores/home-indicadores.component";
import { IndicadoresGerenciaisHomeComponent } from "../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-home/indicadores-gerenciais-home.component";
import { IndicadoresMonitoramentoHomeComponent } from "../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-home/indicadores-monitoramento-home.component";
import { IndicadoresIqfClientesHomeComponent } from "../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-home/indicadores-iqf-clientes-home.component";
import { IndicadoresIqfFornecedoresHomeComponent } from "../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-home/indicadores-iqf-fornecedores-home.component";
import { IndicadorObjQualidadeHomeComponent } from "../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-home/indicador-obj-qualidade-home.component";
import { IndicadoresPerformanceHomeComponent } from "../paginas/indicadores/indicadores-performance/indicadores-performance-home/indicadores-performance-home.component";

export const routesIndicadores: Routes = [
    { path: "home", component: HomeIndicadoresComponent },
    { path: "performance", component: IndicadoresPerformanceHomeComponent },
    { path: "performance-cadastro", loadComponent: () => import("../paginas/indicadores/indicadores-performance/indicadores-performance-cadastro/indicadores-performance-cadastro.component").then(i => i.IndicadoresPerformanceCadastroComponent) },
    { path: "performance-cadastro/:id", loadComponent: () => import("../paginas/indicadores/indicadores-performance/indicadores-performance-cadastro/indicadores-performance-cadastro.component").then(i => i.IndicadoresPerformanceCadastroComponent) },
    { path: "performance-lista", loadComponent: () => import("../paginas/indicadores/indicadores-performance/indicadores-performance-lista/indicadores-performance-lista.component").then(i => i.IndicadoresPerformanceListaComponent) },
    { path: "indicador-performance-resultado/:id/:ano", loadComponent: () => import("../paginas/indicadores/indicadores-performance/indicadores-performance-resultado/indicadores-performance-resultado.component").then(i => i.IndicadoresPerformanceResultadoComponent) },

    ///Indicadores Gerenciais
    { path: "gerenciais", component: IndicadoresGerenciaisHomeComponent },
    { path: "gerenciais-cadastro", loadComponent: () => import("../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-cadastrar/indicadores-gerenciais-cadastrar.component").then(i => i.IndicadoresGerenciaisCadastrarComponent) },
    { path: "gerenciais-cadastro/:id", loadComponent: () => import("../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-cadastrar/indicadores-gerenciais-cadastrar.component").then(i => i.IndicadoresGerenciaisCadastrarComponent) },
    { path: "gerenciais-lista", loadComponent: () => import("../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-lista/indicadores-gerenciais-lista.component").then(i => i.IndicadoresGerenciaisListaComponent) },
    { path: "indicador-gerenciais-resultado/:id/:ano", loadComponent: () => import("../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-resultado/indicadores-gerenciais-resultado.component").then(i => i.IndicadoresGerenciaisResultadoComponent) },

    ///Indicadores Monitoramento
    { path: "monitoramento", component: IndicadoresMonitoramentoHomeComponent },
    { path: "monitoramento-cadastro", loadComponent: () => import("../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-cadastrar/indicadores-monitoramento-cadastrar.component").then(i => i.IndicadoresMonitoramentoCadastrarComponent) },
    { path: "monitoramento-cadastro/:id", loadComponent: () => import("../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-cadastrar/indicadores-monitoramento-cadastrar.component").then(i => i.IndicadoresMonitoramentoCadastrarComponent) },
    { path: "monitoramento-lista", loadComponent: () => import("../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-lista/indicadores-monitoramento-lista.component").then(i => i.IndicadoresMonitoramentoListaComponent) },
    { path: "indicador-monitoramento-resultado/:id/:ano", loadComponent: () => import("../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-result/indicadores-monitoramento-result.component").then(i => i.IndicadoresMonitoramentoResultComponent) },

    ///Indicadores IOF CLIENTES
    { path: "clientes", component: IndicadoresIqfClientesHomeComponent },
    { path: "clientes-cadastro", loadComponent: () => import("../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-cadastrar/indicadores-iqf-clientes-cadastrar.component").then(i => i.IndicadoresIqfClienteCadastrarComponent) },
    { path: "clientes-cadastro/:id", loadComponent: () => import("../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-cadastrar/indicadores-iqf-clientes-cadastrar.component").then(i => i.IndicadoresIqfClienteCadastrarComponent) },
    { path: "clientes-lista", loadComponent: () => import("../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-list/indicadores-iqf-clientes-list.component").then(i => i.IndicadoresIqfClientesListComponent) },
    { path: "indicador-clientes-resultado/:id/:ano", loadComponent: () => import("../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-result/indicadores-iqf-clientes-result.component").then(i => i.IndicadoresIqfClienteResultComponent) },

    ///Indicadores IOF FORNECEDORES
    { path: "fornecedores", component: IndicadoresIqfFornecedoresHomeComponent },
    { path: "fornecedores-cadastro", loadComponent: () => import("../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-cadastro/indicadores-iqf-fornecedores.component").then(i => i.IndicadoresIqfFornecedoresComponent) },
    { path: "fornecedores-cadastro/:id", loadComponent: () => import("../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-cadastro/indicadores-iqf-fornecedores.component").then(i => i.IndicadoresIqfFornecedoresComponent) },
    { path: "fornecedores-lista", loadComponent: () => import("../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-list/indicadores-iqf-fornecedores-list.component").then(i => i.IndicadoresIqfFornecedoresListComponent) },
    { path: "indicador-fornecedores-resultado/:id/:ano", loadComponent: () => import("../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-result/indicadores-iqf-fornecedores-result.component").then(i => i.IndicadoresIqfFornecedoresResultComponent) },

    ///Indicadores QUALIDADE
    { path: "obj-qualidade", component: IndicadorObjQualidadeHomeComponent },
    { path: "obj-qualidade-cadastro", loadComponent: () => import("../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-cadastrar/indicador-obj-qualidade-cadastrar.component").then(i => i.IndicadorObjQualidadeCadastrarComponent) },
    { path: "obj-qualidade-cadastro/:id", loadComponent: () => import("../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-cadastrar/indicador-obj-qualidade-cadastrar.component").then(i => i.IndicadorObjQualidadeCadastrarComponent) },
    { path: "obj-qualidade-lista", loadComponent: () => import("../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-list/indicador-obj-qualidade-list.component").then(i => i.IndicadorObjQualidadeListComponent) },
    { path: "indicador-obj-qualidade-resultado/:id", loadComponent: () => import("../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-resultado/indicador-obj-qualidade-resultado.component").then(i => i.IndicadorObjQualidadeResultadoComponent) },
]