import { PermissoesService } from './../../services/usuario/permissoes.service';
import { SnackBarService } from './../../utils/snack-bar.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { EmisorEventoGeralService } from '../../utils/emisorEventoGeral.service';
import { LoginService } from '../../services/login/login.service';
import { Usuario } from '../../model/usuario/usuario';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule,RouterLink,FormsModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  editorContent: any;
  naoAutorizado: boolean = false;
  usuario:Usuario = new Usuario();
  listaPermissao:any[]=[];
  eng:boolean = false;
  qual:boolean = false;
  permissoes:any[] = [];
  roleAdmin:any[] = [];
  roleEng:any[] = [];

  constructor(
    private snackBarService:SnackBarService,
    private emisorEventoGeralService: EmisorEventoGeralService,
    private loginService: LoginService,
    private permissoesService:PermissoesService
  ){
    localStorage.removeItem("clickedElementId");
  }

  ngOnInit(): void {
    this.verificaPermissao();
    this.verificarSeExisteLogin();
    this.pegarDadosUsuario();
  }

  public aviso(){
    this.snackBarService.openSnackBar("Este módulo está em desenvolvimento e estará disponível em breve.","my-snack-bar-warning")
  }

  public verificarSeExisteLogin() {
    this.emisorEventoGeralService.controleHeader.emit(this.loginService.estaLogado());
  }

  //Exibe a mensagem na tela quando o usuário não possui permissao de acesso
  private verificaPermissao(){
    LoginService.naoAutorizado.subscribe(res=>{
      this.naoAutorizado = res;
      if(this.naoAutorizado){
        this.snackBarService.openSnackBar("Usuário não autorizado ! ","my-snack-bar-erro")
      }
    });
  }

  public pegarDadosUsuario(){
   this.usuario =  this.loginService.decodificarToken();
  }

  public isPermitido(permissao: any): boolean{
    return this.usuario.permissoes.some(p=> p === permissao);
  }
}
