<app-container-component tituloPage="MEDIÇÃO DE CAMADA" subtituloPage="MEDIÇÃO DE CAMADA">
    <div class="col-12 d-flex flex-column justify-content-end align-items-end">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mt-2 me-2">
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="valorPesquisado"
                    (keypress)="this.onKeyPress($event)" placeholder="Produto, Item , Cliente, Data, Status">
                <button class="btn btn-sm" type="button"><i class="fa-solid fa-magnifying-glass"
                        (click)="this.buscar($event)"></i></button>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-hover pointer table-striped align-middle">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">Produto</th>
                    <th scope="col">Item</th>
                    <th scope="col">Cliente</th>
                    <th scope="col" class="text-center">Data teste</th>
                    <th scope="col">Inspetor</th>
                    <th scope="col" class="text-center">Status</th>
                    <th scope="col" class="text-center">Visualizar</th>
                </tr>
            </thead>
            <tbody>
                @if(listaMedicaoCamada.length > 0) {
                @for (medicaoCamada of listaMedicaoCamada; track $index) {
                <tr title="Clique para visualizar" class="table-row">
                    <td (click)="editarMedicaoCamada(medicaoCamada.id!)" data-label="Produto">{{ medicaoCamada.cdProduto
                        }}</td>
                    <td (click)="editarMedicaoCamada(medicaoCamada.id!)" data-label="Item">{{ medicaoCamada.item }}</td>
                    <td (click)="editarMedicaoCamada(medicaoCamada.id!)" data-label="Cliente">{{ medicaoCamada.cliente
                        }}</td>
                    <td (click)="editarMedicaoCamada(medicaoCamada.id!)" data-label="Data teste" class="text-center">
                        {{medicaoCamada.dataCreate| date:'dd/MM/yyyy'}}</td>
                    <td (click)="editarMedicaoCamada(medicaoCamada.id!)" data-label="Inspetor">{{ medicaoCamada.usuario
                        }}</td>
                    <td (click)="editarMedicaoCamada(medicaoCamada.id!)" data-label="Status" class="text-center">
                        <span class="badge bg" [ngClass]="{
                  'aprovado': medicaoCamada.status == 'Aprovado',
                  'reprovado': medicaoCamada.status == 'Reprovado',
                }">
                            {{ medicaoCamada.status }}
                        </span>
                    </td>
                    <td class="text-center" (click)="visualizar(medicaoCamada.id!)"><i class="fa-solid fa-eye"></i></td>
                </tr>
                }
                }
            </tbody>
        </table>
    </div>
    @if(listaMedicaoCamada.length > 0){
    <div class="d-flex justify-content-center col-12">
        <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
            [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
            (paginaMudou)="onPageChange($event)"></app-paginator>
    </div>
    }
</app-container-component>