import { SnackBarService } from './../../../../utils/snack-bar.service';
import { PlanoDeAcaoService } from './../../../../services/qualidade/plano-de-acao/planoDeAcao.service';
import { Component, Input, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PlanoDeRiscoService } from '../../../../services/acoes/plano-de-risco/plano-de-risco.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-plano-de-risco-listar',
  standalone: true,
  imports: [ContainerComponentComponent, PaginatorComponent, CommonModule, ReactiveFormsModule, FormsModule, MatSlideToggleModule, MatTooltipModule],
  templateUrl: './plano-de-risco-listar.component.html',
  styleUrl: './plano-de-risco-listar.component.scss'
})
export class PlanoDeRiscoListarComponent implements OnInit {

  valorPesquisado = "";
  paginaAtual: number = 0;
  totalElementos: number = 0;
  itensPorPagina: number[] = [6, 12, 24, 48]
  totalPorPagina: number = this.itensPorPagina[0];
  listaPlanoDeRisco: any[] = [];
  titulo = "PLANO DE RISCO";
  nomeFormulario = "Planos de risco cadastrados";
  statusSelecionado: string = "Ativo";
  @Input() comTopo: boolean = true;

  constructor(
    private router: Router,
    private planoDeRiscoService: PlanoDeRiscoService,
    private snackBarService: SnackBarService,
    private loginService:LoginService
  ) {

  }
  ngOnInit(): void {
    this.buscarTodosPlanoDeAcao();
  }
  
  public buscarTodosPlanoDeAcao() {
    this.planoDeRiscoService.buscarTodosPlanoDeAcao(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res: any) => {
        this.listaPlanoDeRisco = res.content;
        this.totalElementos = res.page.totalElements;
        console.log(res);
      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar consultar plano de risco");
      }, complete: () => {

      }
    });
  }

  public enterPress(event: KeyboardEvent){
      if(event.key === 'Enter'){
        this.pesquisar();
      }
  }

  public pesquisar() {
    if(this.valorPesquisado === ''){
      this.buscarTodosPlanoDeAcao();
      return;
    }
    this.planoDeRiscoService.consultaPorIdentificacao(this.valorPesquisado).subscribe({
      next:(res: any)=>{
        this.listaPlanoDeRisco = res;
      }
    });
  }

  public visualizarAcoesPlano(id: number){
    this.router.navigate([`acoes/plano-de-risco-acoes/${id}`]);
  }

  public editarPlanoDeRisco(id: number){
    this.router.navigate([`acoes/plano-de-risco-registrar/${id}`]);
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.buscarTodosPlanoDeAcao();
  }

  
  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_ACAO_EDIT']);
  }

  public temPermissaoExcluir() {
    return this.loginService.temPermissao(['ROLE_ACAO_ADMIN']);
  }

}
