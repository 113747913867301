<app-container-component [header]="false" [subtituloPage]="'DETERMINAÇÃO LIQUÍDA'">
    <form class="form-group col-12" [formGroup]="formularioDeterminacaoProcessoLiquida">
        <div class="row sessao-produto delay-1">
            <!-- <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Característica:</label>
                <input type="text" formControlName="nome" class="form-control form-control-sm">
            </div> -->
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Quantidade de travessa:</label>
                <input type="number" formControlName="qtdePecasTravessa" (input)="calcularQuantidadePorRack()"
                    class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasTravessa')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Quantidade de quadro:</label>
                <input type="number" formControlName="qtdeTravessaQuadro" (input)="calcularQuantidadePorRack()"
                    class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdeTravessaQuadro')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Quantidade peças por quadro:</label>
                <input type="number" formControlName="qtdePecasQuadro" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasQuadro')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Padão de pintura:</label>
                <input type="text" formControlName="padraoPintura" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Volume tinta:</label>
                <input type="text" formControlName="volumeTinta" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Volume catalisador:</label>
                <input type="number" formControlName="volumeCatalisador" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('volumeCatalisador')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Volume solvente:</label>
                <input type="number" formControlName="volumeSolvente" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('volumeSolvente')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Pressão geral:</label>
                <input type="number" formControlName="pressaoArGeral" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('pressaoArGeral')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Pressão ar tambor:</label>
                <input type="number" formControlName="pressaoArTambor" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('pressaoArTambor')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="number" class="form-label">Viscosidade mistura:</label>
                <input type="number" formControlName="viscosidadeMistura" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('viscosidadeMistura')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo flash off:</label>
                <input type="number" formControlName="tempoFlashOff" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoFlashOff')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Temperatura cura:</label>
                <input type="number" formControlName="temperatuaCura" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('temperatuaCura')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Amostra inspeção:</label>
                <input type="number" formControlName="amostraInpecao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('amostraInpecao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo de cura:</label>
                <input type="number" formControlName="tempoCura" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoCura')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo setup equipamento:</label>
                <input type="number" formControlName="tempoSetupEquipamento" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoSetupEquipamento')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo setup dispositivo:</label>
                <input type="number" formControlName="tempoSetupDispositivo" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoSetupDispositivo')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo montagem</label>
                <input type="number" formControlName="tempoMontagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoMontagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo preparação:</label>
                <input type="number" formControlName="tempoPreparacao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoPreparacao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo aplicação:</label>
                <input type="number" formControlName="tempoAplicacao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoAplicacao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo de resfriamento:</label>
                <input type="number" formControlName="tempoResfriamento" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoResfriamento')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo desmontagem:</label>
                <input type="number" formControlName="tempoDesmontagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoDesmontagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo inspeção:</label>
                <input type="number" formControlName="tempoInspecao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoInspecao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo embalagem:</label>
                <input type="number" formControlName="tempoEmbalagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoEmbalagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">leadTime:</label>
                <input type="number" formControlName="leadTime" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('leadTime')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Qtde peças por hora linha:</label>
                <input type="number" formControlName="qtdePecasHoraLinha" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasHoraLinha')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Camada minima:</label>
                <input type="number" formControlName="camadaMinima" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('camadaMinima')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Camada maxima:</label>
                <input type="number" formControlName="camadaMaxima" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('camadaMaxima')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-12 col-xxl-12">
                <label for="text" class="form-label">EPI:</label>
                <input type="text" formControlName="epi" class="form-control form-control-sm">
            </div>
            <div class="col-12 mb-3">
                <label for="text" class="form-label"><span>Descrição:</span></label>
                <textarea formControlName="observacao" class="form-control form-control-sm dynamic-textarea"
                    (input)="autoGrow($event)"></textarea>
            </div>
            <div class="row justify-content-start gap-2">

                @if(idProcesso && formularioDeterminacaoProcessoLiquida.controls['id'].value && temPermissao()){
                <app-botao label="Salvar" class="col-12 col-lg-2" icon="icons8-disquete.png" status="btn-salvar"
                    (click)="salvarProcesso()"></app-botao>
                <app-botao label="Cancelar" class="col-12 col-lg-2" icon="icons8-x.png" status="btn-cancelar"
                    (click)="limparFormulario()"></app-botao>
                }@else {
                @if(temPermissao()){
                <app-botao label="Editar" class="col-12 col-lg-2" icon="icons8-lápis.png" status="btn-salvar"
                    (click)="editarProcesso()"></app-botao>
                }
                }
            </div>
        </div>
    </form>
</app-container-component>