<div class="sessao-lista-dispositivos">
  <div class="row mt-3 justify-content-evenly p-3 p-sm-3 p-md-3 p-lg-4">
    @for(dispositivo of dispositivos ;track dispositivo.id;let i = $index){
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 overflow-hidden">
      <div class="card text-center shadow-sm" [ngStyle]="{'animation-delay': calDelay(i)}">
        <!-- Cabeçalho do card -->
        <div class="card-header">
          <h6 class="card-title mb-0">{{ dispositivo.nome }}</h6>
        </div>
        <!-- Corpo do card -->
        <div class="card-body">
          <div class="swiper mySwiper-{{dispositivo.id}}">
            <div class="swiper-wrapper">
              @if(dispositivo.imagens.length > 0){
              @for (imagem of dispositivo.imagens; track imagem.id) {
              <div class="swiper-slide">
                <figure class="image-container">
                  <a [href]="imagem.path" target="_blank" rel="noopener noreferrer">
                    <img [src]="imagem.path" alt="" loading="lazy">
                  </a>
                </figure>
              </div>
              }
              }@else {
              <div class="swiper-slide">
                <figure class="image-container">
                  <img src="assets/Produto-sem-imagem.png" alt="SEM IMAGEM">
                </figure>
              </div>
              }
            </div>
            <div class="swiper-pagination d-flex justify-content-center my"></div>
          </div>
          <!-- Informações do dispositivo -->
          <div class="mt-3 conteudo-texto">
            <p class="card-text mb-1">{{ dispositivo.observacao }}</p>
          </div>
          <div>
            @if(dispositivo.processo){
            <p class="card-text mb-1"><strong>PROCESSO:</strong> {{ dispositivo.processo }}</p>
            }
            @if(dispositivo.processoAplicavel){
            <p class="card-text mb-1"><strong>PROCESSO:</strong> {{ dispositivo.processoAplicavel }}</p>
            }
            <hr>
          </div>
        </div>
        <!-- Rodapé do card -->
        <div class="card-footer bg-transparent border-top-0">
          <small class="text-muted">Quantidade disponível: <strong>{{ dispositivo.qtdeDisponivel }}</strong></small>
          <!-- Ícone para editar dispositivo -->
          @if(temPermissao()){
          <button type="button" class="btn btn-link text-decoration-none text-dark float-end"
            (click)="editarDispositivo(dispositivo)">
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
          }
        </div>
      </div>
    </div>
    }
  </div>
  @if(exibirPaginacao){
  <div class="d-flex justify-content-center col-12">
    <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
      [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
      (paginaMudou)="onPageChange($event)"></app-paginator>
  </div>
  }
</div>