import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from '../../../componentes/container-component/container-component.component';
import { FormsModule } from '@angular/forms';
import { Usuario } from '../../../model/usuario/usuario';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { PermissoesService } from '../../../services/usuario/permissoes.service';
import { SnackBarService } from '../../../utils/snack-bar.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RedefinirSenhaComponent } from '../redefinir-senha/redefinir-senha.component';

@Component({
  selector: 'app-usuario-cadastro',
  standalone: true,
  imports: [
    CommonModule,
    ContainerComponentComponent,
    FormsModule,
    MatDialogModule
  ],
  templateUrl: './usuario-cadastro.component.html',
  styleUrl: './usuario-cadastro.component.scss'
})
export class UsuarioCadastroComponent implements OnInit {

  tituloPagina: string = 'EDIÇÃO E CADASTRO DE USUÁRIO';
  subTitulo: string = 'Dados do usuário';
  usuario: Usuario = new Usuario();
  permissoesSistema: any[] = [];
  permissoesUsuario: Set<string> = new Set();

  constructor(
    private routerActive: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService,
    private permissoesService: PermissoesService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog
    
  ) {

  }

  ngOnInit(): void {

    this.routerActive.params.subscribe((res: any) => {
      this.consultarPermissoesSistema();
      if (res.id != 0) {
        this.consultaUsuarioPorId(res.id);
      }
    });
  }

  public redefinirSenha() {
    this.dialog.open(RedefinirSenhaComponent, {
      data: this.usuario,
      position: {top: '20px'},
      maxWidth: '700px', // Configurações opcionais como largura do dialog
      width: '500px',
      disableClose: true // Impede que o usuário feche o dialog clicando fora
    });
  }
  

  private consultarPermissoesSistema() {
    this.permissoesService.consultaPermissoesSistema().subscribe({
      next: (res) => {
        this.permissoesSistema = [];
        this.permissoesSistema = res;
      }
    });
  }

  cadastrarUsuario() {
    this.usuarioService.criarUsuario(this.usuario).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar('Usuário cadastrado com sucesso!', 'my-snack-bar-sucess');
        this.router.navigate(['/administrador/usuario-consulta']);
      },
      error: (error) => {
        this.snackBarService.tratarErro(error, 'Falha ao tentar cadastrar usuário');
      }
    });
  }

  alterarUsuario() {
    this.usuarioService.alterarPermissoes(this.usuario).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar('Usuário alterado com sucesso!', 'my-snack-bar-sucess');
      },
      error: (error) => {
        this.snackBarService.tratarErro(error, 'Falha ao tentar alterar usuário');
      }
    });
  }

  private consultaUsuarioPorId(id: number) {
    this.usuarioService.consultaUsuarioPorId(id).subscribe({
      next: (res) => {
        this.usuario = res;
      },
      complete: () => {
        this.ativarPermissoesDoUsuario();
      }
    });
  }

  private ativarPermissoesDoUsuario() {
    this.usuario.permissoes.forEach((perm: any) => {
      this.permissoesUsuario.add(perm.authority);
    });

    // Verifica se o usuário possui a permissão ROLE_ADMIN
    if (this.hasPermission('ROLE_ADMIN')) {
      this.setAllPermissionsTrue();
    }
  }

  hasPermission(authority: string): boolean {
    return this.permissoesUsuario.has(authority);
  }

  setAllPermissionsTrue() {
    this.permissoesSistema.forEach(item => {
      item.permissoes.forEach((p: any) => {
        this.permissoesUsuario.add(p.autority); // Adiciona a permissão ao usuário
        this.addPermissaoUsuario(p); // Adiciona a permissão ao objeto usuario
      });
    });
  }

  onCheckboxChange(event: any, permission: any) {
    const isGlobalAdmin = this.hasPermission('ROLE_ADMIN'); // Verifica se o usuário é ADMIN global

    if (event.target.checked) {
      // Adicionar permissão ao usuário se marcada
      this.permissoesUsuario.add(permission.autority);
      this.addPermissaoUsuario(permission);

      // Se a permissão for um ADMIN do módulo, marque todas as permissões desse módulo
      if (permission.autority.includes('_ADMIN')) {
        const moduloPermissoes = this.permissoesSistema.find((modulo: any) => modulo.modulo === permission.modulo);

        if (moduloPermissoes && Array.isArray(moduloPermissoes.permissoes)) {
          moduloPermissoes.permissoes.forEach((p: any) => {
            this.permissoesUsuario.add(p.autority); // Adiciona a permissão ao conjunto do usuário
            this.addPermissaoUsuario(p); // Adiciona a permissão ao usuário

            const checkbox: any = document.getElementById(p.autority);
            if (checkbox) {
              checkbox.checked = true; // Marca a permissão
            }
          });
        }
      }
    } else {
      // Remover permissão do usuário se desmarcada
      this.permissoesUsuario.delete(permission.autority);
      this.removePermissaoUsuario(permission.autority);

      // Se a permissão desmarcada for um ADMIN do módulo, desmarque todas as permissões desse módulo
      if (permission.autority.includes('_ADMIN')) {
        const moduloPermissoes = this.permissoesSistema.find((modulo: any) => modulo.modulo === permission.modulo);

        if (moduloPermissoes && Array.isArray(moduloPermissoes.permissoes)) {
          moduloPermissoes.permissoes.forEach((p: any) => {
            this.permissoesUsuario.delete(p.autority); // Remove a permissão do conjunto do usuário
            this.removePermissaoUsuario(p.autority); // Remove a permissão do usuário

            const checkbox: any = document.getElementById(p.autority);
            if (checkbox) {
              checkbox.checked = false; // Desmarca a permissão
            }
          });
        }
      }
    }
  }

  setAllPermissionsFalse() {
    this.permissoesUsuario.clear(); // Limpa todas as permissões do usuário
    this.usuario.permissoes = []; // Limpa as permissões do objeto usuario
  }

  addPermissaoUsuario(permission: any) {
    // Verifica se a permissão já não está no array e adiciona
    const exists = this.usuario.permissoes.some((perm: any) => perm.authority === permission.autority);
    if (!exists) {
      this.usuario.permissoes.push({
        id: permission.id,
        authority: permission.autority,
        modulo: permission.modulo,
        descricao: permission.descricao
      });
    }
  }

  removePermissaoUsuario(authority: string) {
    // Remove a permissão do array do usuário
    this.usuario.permissoes = this.usuario.permissoes.filter((perm: any) => perm.authority !== authority);
  }

}
