import { SnackBarService } from './../../../../utils/snack-bar.service';
import { ChangeDetectorRef, Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { NotificacoesSistemaService } from '../../../../services/infra/notificacoes-sistema.service';
import { NotificacaoSistemaConsulta } from '../../../../model/infra/notificacoes/notificacao-consulta';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { slideUpToBottom } from '../../../../animations/animations';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-consulta',
  standalone: true,
  imports: [
    CommonModule, FormsModule,
    PaginatorComponent
  ],
  animations: [
    slideUpToBottom
  ],
  templateUrl: './consulta.component.html',
  styleUrl: './consulta.component.scss'
})
export class ConsultaComponent implements OnInit, OnDestroy {

  notificacaoService = inject(NotificacoesSistemaService);
  dialog = inject(MatDialog);
  cdr = inject(ChangeDetectorRef);
  snackBarService = inject(SnackBarService);
  notificacoes: NotificacaoSistemaConsulta[] = [];
  mensagem: NotificacaoSistemaConsulta;
  selectedStatus: string = 'Todas';
  animationState: string = 'recuando';
  public isMobile: boolean = false;
  private listaDeNotificacaoSelecionada: NotificacaoSistemaConsulta[] = [];
  notificacoesPaginadas: NotificacaoSistemaConsulta[] = [];
  private intervalo: any;
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [8, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  scroll = document.querySelector(".container-mensagem") as HTMLElement;
  constructor(

  ) {
    this.mensagem = new NotificacaoSistemaConsulta();
  }

  ngOnInit(): void {
    this.consultarNotificacoes();
    this.checkScreenSize();
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth <= 1000;
    this.animationState = this.isMobile ? 'enteringFromLeft' : '';
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalo);
  }

  selectStatus(status: string): void {
    this.selectedStatus = status;
    this.notificacoes = [];
    this.limpar();
    if (status === 'Todas') {
      this.consultarNotificacoes();
    } else {
      this.consultarNotificacaoPorStatus('Não lida');
    }
  }




  public consultarNotificacaoPorStatus(status: string) {
    this.notificacaoService.consultaQtdeNotificacoesPorStatus(this.selectedStatus, this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.notificacoes = [];
        this.notificacoes = res.content;
        this.totalTamanho = res.page.totalElements;
        this.paginaAtual = res.page.number
      }
    });
  }

  public consultarNotificacoes() {
    this.notificacaoService.consultarNotificacoesDoUsuario(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.notificacoes = [];
        this.notificacoes = res.content;
        this.totalTamanho = res.page.totalElements;
        this.paginaAtual = res.page.number
      }, complete: () => {

      }
    });
  }

  public visualizarMensagem(notificacao: NotificacaoSistemaConsulta, event: any) {
    if (this.mensagem?.id !== notificacao.id) {
      // Define o estado de recuo ou entrada dependendo do dispositivo
      const animationOut = this.isMobile ? 'enteringFromLeft' : 'recuando';
      this.animationState = animationOut;
      notificacao.status = 'Lida'
      setTimeout(() => {
        this.mensagem = notificacao;
        this.animationState = this.isMobile ? 'leftToRight' : 'descendo';
      }, 250); // Tempo da animação de saída
      this.marcarMensagemComoLida(notificacao.id);
      this.enivarConformacaoDeLeitura(notificacao.id);
    }
  }

  private marcarMensagemComoLida(idNotificacao: number) {
    this.notificacaoService.mudarStatusParaLido(idNotificacao).subscribe({
      next: (res) => {
      }, error: (error) => {
        this.snackBarService.openSnackBar('Falha ao tentar alterar status da mensagem!', 'my-snack-bar-erro')
      }, complete: () => {

      }
    });
  }

  private enivarConformacaoDeLeitura(idNotificacao: number) {
    this.notificacaoService.confirmacaoDeLeitura(idNotificacao).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackBarService.openSnackBar('Falha ao tentar enviar confirmacao de leitura!', 'my-snack-bar-erro')
      }, complete: () => {

      }
    });
  }

  public excluirNotificacao(idNotificacao: number, assunto: string) {
    let dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: assunto, texto: 'Deseja realmente excluir esta mensagem ?' },
      width: "100%",
      maxWidth: "800px",
      maxHeight: "650px",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.deletarNotificacao(idNotificacao);
        this.snackBarService.openSnackBar('Mensagem removida com sucesso!', 'my-snack-bar-sucess');
        this.consultarNotificacoes();
      }
    });
  }

  private deletarNotificacao(idNotificacao: number) {
    this.notificacaoService.excluirNotificacao(idNotificacao).subscribe({
      next: (res) => {
      }, error: (error) => {
        this.snackBarService.openSnackBar('Falha ao tentar excluir mensagem!', 'my-snack-bar-erro')
      }, complete: () => {

      }
    });
  }

  limpar() {
    this.mensagem = new NotificacaoSistemaConsulta();
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultarNotificacoes();
  }

  public mudarPosicao() {

  }
}
