import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PadraoMontagemService {

  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }

  public salvarPadraoMontagem(formdata: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-padrao-montagem`, formdata);
  }
  public editarDescricaoPadraoMontagem(id:number,descricao:string): Observable<any> {
    return this.http.put<any>(`${this.url}/eng-padrao-montagem/${id}/${descricao}`,null);
  }

  public consultarPadraoMontagem(cdProduto:number,cdProcesso:number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-padrao-montagem/${cdProduto}/${cdProcesso}`);
  }

  public consultaImagemPorNome(nomeArquivo:string): Observable<Blob> {
    return this.http.get(`${this.url}/eng-padrao-montagem/download/${nomeArquivo}`,{responseType:'blob'});
  }

  public deletarPadraoMontagem(id:number): Observable<any> {
    return this.http.delete<any>(`${this.url}/eng-padrao-montagem/${id}`);
  }

  public salvarNovoPadraoImagem(novaSequencia:any):Observable<any>{
    return this.http.post<any>(`${this.url}/eng-padrao-montagem/sequencia`,novaSequencia)
  }



}
