import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuditoriaInternaService } from '../../../../services/acoes/auditoria-interna/auditoria-interna.service';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { AuditoriaInternaConsultaDto } from '../../../../model/acoes/auditoria-Interna/AuditoriaInternaConsultaDto';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { AuditoriaInternaModalComponent } from '../auditoria-interna-modal/auditoria-interna-modal.component';

@Component({
  selector: 'app-auditoria-interna-gerenciar',
  standalone: true,
  imports: [ContainerComponentComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './auditoria-interna-gerenciar.component.html',
  styleUrl: './auditoria-interna-gerenciar.component.scss'
})
export class AuditoriaInternaGerenciarComponent implements OnInit {

  auditoriaForm!: FormGroup;
  idAuditoria: number = 0;
  mostrarBolas: boolean = false;
  mostrarFormularioAcoes: boolean = false;

  private formBuilder = inject(FormBuilder);
  private formValidacaoService = inject(FormValidacaoService);
  private auditoriaInternaService = inject(AuditoriaInternaService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private activatedRoute = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  private snackBarService = inject(SnackBarService);
  auditoria: AuditoriaInternaConsultaDto = new AuditoriaInternaConsultaDto();



  ngOnInit(): void {
    this.verificarRota();
  }

  public registrarAuditoria() {

  }


  public verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idAuditoria = res.id;
        this.consultaAponetamentos();
      }
    });
  }

  public iniciarFormulario() {
    this.auditoriaForm = this.formBuilder.group({
      processo: [],
      auditado: [],
      obs: [],
      campoExtra: []
    })
  }


  public consultaAponetamentos() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.auditoriaInternaService.consultarAuditoriaPorId(this.idAuditoria).subscribe({
      next: (res) => {
        this.auditoria = res;
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public editarAuditoria(relatorio: any) {

  }
  public excluirAuditoria(relatorio: any) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: relatorio.norma, texto: "Deseja apagar este relátorio" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.deletar(relatorio);
        };
      }
    });
  }

  private deletar(relatorio: any) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.auditoriaInternaService.deletarRelatorioAuditoriaInterna(relatorio.id).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar deletar ação")
      }, complete: () => {
        this.consultaAponetamentos();
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.openSnackBar("Ação deletada com sucesso", "my-snack-bar-sucess");
      }
    });
  }

  public openClose() {
    this.mostrarBolas = !this.mostrarBolas;
  }

  public mostrarFormsAcoes(relatorio?: any) {
    const dialogRef = this.dialog.open(AuditoriaInternaModalComponent, {
      data: { relatorio: relatorio,auditoria: this.auditoria},
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.verificarRota();
        };
      }
    });
  }


}
