import { Routes } from '@angular/router';
import { authGuard } from './auth/auth-guard.guard';
import { verificarPermissoesGuard } from './auth/verificar-permissoes.guard';
import { routesEngenharia } from './routes/app.engenharia.routes';
import { routesDispositivos } from './routes/app-dispositivo.routes';
import { routesQualidade } from './routes/app.qualidade.routes';
import { routesProducao } from './routes/app.producao.routes';
import { routesIndicadores } from './routes/app.indicadores.routes';
import { MenuIndicadoresComponent } from './paginas/indicadores/menu-indicadores/menu-indicadores.component';
import { MenuComponent } from './paginas/engenharia/menu-eng/menu/menu.component';
import { LoginComponent } from './paginas/login/login.component';
import { HomeComponent } from './paginas/home/home.component';
import { SatisfacaoClienteResponderComponent } from './paginas/controles/satisfacao-cliente/satisfacao-cliente-responder/satisfacao-cliente-responder.component';
import { GestaoAVistaComponent } from './paginas/qualidade/gestao-a-vista/gestao-a-vista.component';
import { MenuQualidadeComponent } from './paginas/qualidade/menu-qualidade/menu-qualidade.component';
import { MenuControlesComponent } from './paginas/controles/menu-controles/menu-controles.component';
import { BookProducaoVisualizarComponent } from './paginas/producao/book-producao-visualizar/book-producao-visualizar.component';
import { UsuarioComponent } from './paginas/administrador/usuario-consulta/usuario.component';
import { InspecaoHomeComponent } from './paginas/inspecao-equipamentos/inspecao-home/inspecao-home.component';
import { routesInspecaoEquipamentos } from './routes/app.inspecao-equipamento.routes';
import { RecuperarSenhaComponent } from './paginas/recuperar-senha/recuperar-senha.component';
import { MenuAcoesComponent } from './paginas/acoes/menu-acoes/menu-acoes.component';
import { routesAcoes } from './routes/app.acoes.routes';
import { MenuManutencaoComponent } from './paginas/manutencao/menu-manutencao/menu-manutencao.component';
import { PcpMenuComponent } from './paginas/pcp/pcp-menu/pcp-menu.component';
import { routesPcp } from './routes/app.pcp.routes';
import { routesControle } from './routes/app.controle.routes';
import { rotaManutencao } from './routes/app-menutencao.routes';
import { routesAdministrador } from './routes/app.admin.routes';
import { MenuDispositivoComponent } from './paginas/dispositivos/menu-dispositivo/menu-dispositivo.component';
import { MenuInspecaoComponent } from './paginas/inspecao-equipamentos/menu-inspecao/menu-inspecao.component';
import { UsuarioConfigComponent } from './paginas/usuario/usuario-config/usuario-config.component';
import { routesNotificacoesSistema } from './routes/app.notificacoes.routes';
import { ConsultaComponent } from './paginas/infra/notificacoes-sistema/consulta/consulta.component';
import { authGuardLogin } from './auth/auth-guard-login.guard';
import { MenuAdministracaoComponent } from './paginas/administrador/menu-administracao/menu-administracao.component';

export const routes: Routes = [
    { path: "", redirectTo: "home", pathMatch: 'full' },
    { path: "home", component: HomeComponent, canActivate: [authGuard] },
    { path: "login", component: LoginComponent, canActivate: [authGuardLogin] },
    { path: "login/:mail", component: LoginComponent },
    {
        path: "teste", loadComponent: () => import("./paginas/teste/teste.component").then(t => t.TesteComponent), children: [
            { path: "teste-home", loadComponent: () => import("./paginas/teste/componente-exibicao-teste/componente-exibicao-teste.component").then(a => a.ComponenteExibicaoTesteComponent) },
        ]
    },
    { path: "responder-satisfacao-cliente/:id/:token", component: SatisfacaoClienteResponderComponent },

    //ROTAS RESPONSAVEIS POR ENGENHARIA
    {
        path: "engenharia", component: MenuComponent, children: [
            ...routesEngenharia
        ],
        canActivate: [authGuard, verificarPermissoesGuard], data: {
            permissao: ['ROLE_ENG_VIS']
        }
    },
    // //ROTAS RESPONSAVEIS POR DISPOSITIVOS
    {
        path: "dispositivo", component: MenuDispositivoComponent, children: [
            ...routesDispositivos
        ], canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_DISP_VIS'] }
    },
    { path: "gestao-a-vista", component: GestaoAVistaComponent },
    // //ROTAS RESPONSAVEIS POR QUALIDADE
    {
        path: "qualidade", component: MenuQualidadeComponent, children: [
            ...routesQualidade
        ], canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_QUA_VIS'] }
    },
    // //ROTAS RESPONSAVEIS POR CONTROLES
    {
        path: "controles", component: MenuControlesComponent, canActivate: [authGuard, verificarPermissoesGuard],
        children: [
            ...routesControle
        ]
        , data: { permissao: ['ROLE_CONT_VIS'] }
    },
    //PRODUÇÃO
    {
        path: "producao/book", component: BookProducaoVisualizarComponent, children: [
            ...routesProducao
        ], canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    // //ROTA ADMINISTRADOR
    {
        path: "administrador", component: MenuAdministracaoComponent,
        children: [...routesAdministrador],
        canActivate: [authGuard, verificarPermissoesGuard],
        data: { permissao: ['ROLE_QUA_VIS'] }
    },

    // //ROTA PCP
    {
        path: "pcp", component: PcpMenuComponent, children: [
            ...routesPcp
        ], canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    //ROTA INDICADORES
    {
        path: "indicadores", component: MenuIndicadoresComponent, children: [
            ...routesIndicadores
        ], canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    // //ROTA INSPECAO
    {
        path: "inspecao", component: MenuInspecaoComponent, children: [
            ...routesInspecaoEquipamentos
        ], canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    // //ROTA PUBLICA PARA RECUPERAÇÃO DE SENHA
    {
        path: "recuperar-senha/:email/:token", component: RecuperarSenhaComponent,
    },
    // //ACÕES
    {
        path: "acoes", component: MenuAcoesComponent, children: [
            ...routesAcoes
        ], canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    // //Manuntenção
    {
        path: "manutencao", component: MenuManutencaoComponent, children: [
            ...rotaManutencao
        ], canActivate: [authGuard, verificarPermissoesGuard], data: { permissao: ['ROLE_PROD_VIS'] }
    },
    {
        path: "usuario-config", component: UsuarioConfigComponent
    },
    //Notificações do sistema
    {
        path: "notificacoes-sistema", component: ConsultaComponent, children: [...routesNotificacoesSistema], canActivate: [authGuard]
    },

];

