@if(listaDePadraoDeIsencao.length > 0){
<app-container-component [header]="false" subtituloPage="Imagens cadastradas">
    @if(carregando){
    <app-loading></app-loading>
    }
    <div class="col-12 p-0">
        <div #swiperProduto class="swiper mySwiper p-0">
            <div class="swiper-wrapper mb-2">
                @for(imagem of listaDePadraoDeIsencao;track $index){
                <div class="swiper-slide d-flex flex-column gap-2">
                    <div>
                        <img class="col-12  col-md-6 col-lg-3" [src]="imagem.path" alt=""
                            [ngStyle]="!imagem.descricao ? {'margin-bottom': '40px'}: {}">
                    </div>
                    <div class="sessao-descricao text-start" *ngIf="imagem.descricao"
                        [ngStyle]="imagem.descricao ? {'margin-bottom': '40px'}: {} ">
                        @if(editando && this.imagemSelecionada == imagem.id){
                        <i class="fa-solid fa-circle-xmark mb-1" matTooltipPosition="after" matTooltipHideDelay="200"
                            matTooltip="Fechar" (click)="cancelarEdicao()"></i>
                        <div class="form-floating">
                            <textarea class="form-control" id="floatingTextarea" (focus)="autoAjuste($event)"
                                (mousemove)="autoAjuste($event)"
                                [(ngModel)]="descricaoImagem">{{imagem?.descricao}}</textarea>
                            <label for="floatingTextarea">Descrição</label>
                        </div>
                        <i class="fa-solid fa-floppy-disk text-end mt-1" matTooltipPosition="after"
                            matTooltipHideDelay="200" matTooltip="Atualizar" (click)="concluirEdicaoDeDescricao()"></i>
                        }@else {
                        <span><strong>Descrição</strong>: {{imagem?.descricao}}</span>
                        @if(temPermissao() || temPermissaoExcluir() ){
                        <i class="fa-solid fa-pen-to-square" matTooltipPosition="after" matTooltipHideDelay="200"
                            matTooltip="Edita" (click)="editarDescricaoImagem(imagem.id,imagem.descricao)"></i>
                        }
                        }
                    </div>
                </div>
                }
            </div>
            <div class="swiper-pagination d-flex justify-content-center my"></div>
        </div>
        @if(temPermissao() || temPermissaoExcluir() ){
        <div class="col-12 d-flex justify-content-center mb-3 mt-3">
            <button class="btn align-items-center "
                (click)="ativarVisualizarSequencia = !ativarVisualizarSequencia">Visualizar sequência de Padrão de
                montagem </button>
        </div>
        @if(ativarVisualizarSequencia){
        <div class="col-12">
            <div cdkDropList cdkDropListOrientation="mixed" class="example-list" (cdkDropListDropped)="drop($event)">
                @for (item of listaDePadraoDeIsencao; track item) {
                <div class="example-box" cdkDrag>
                    <span class="sessao-sequencia mb-1">Sequencia <strong> {{item.sequencia}} </strong> <i
                            class="fa-solid fa-trash-can"
                            (click)="deletarPadraoDeIsencao(item.nomeArquivo,item.id)"></i></span>
                    <img mat-card-image class="imagemCard" [src]="item.path" alt="">

                </div>
                }

            </div>
            <div class="d-flex justify-content-center align-items-center mt-3">
                <button class="btn color-btn" (click)="salvarNovaSequencia()">Definir sequência</button>
            </div>
        </div>
        }
        }
    </div>
</app-container-component>
}@else {
<app-container-component [header]="false" subtituloPage="Imagens cadastradas">
    <div class="text-center">
        <span>Não há padrão de isenção cadastrado</span>
    </div>
</app-container-component>
}
@if(temPermissao()){
<app-container-component [header]="false" subtituloPage="Cadastrar Imagens">
    @if(carregando){
    <app-loading></app-loading>
    }
    <form [formGroup]="contactFormPadraoIsencao" class="d-flex flex-column justify-content-center align-items-center">
        <div formArrayName="contactsPadraoIsencao" class="row col-12 p-md-3 p-0 espaco">
            @if(contactsPadraoIsencao.controls.length > 0){
            <div *ngFor="let contact of contactsPadraoIsencao.controls; let i = index" class="row col-12 p-0">
                <div [formGroupName]="i" class="col-12 card-box">
                    <div class="col-12">
                        <label for="text" class="form-label">Descrição de padrão de isenção:</label>
                        <textarea formControlName="descricao"
                            class="form-control form-control-sm dynamic-textarea"></textarea>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="mb-3 col-12">
                            <div class="col-12">
                                @if(listaDocumentoSelecionadoRederizadosIsencao[i]){
                                <div
                                    class="col-12 mt-3 text-center d-flex flex-column justify-content-center align-items-center">
                                    <img class="imagem-pdf"
                                        src="{{this.listaDocumentoSelecionadoRederizadosIsencao[i].preview}}" alt="">
                                    <label
                                        class="file-name mt-2">{{listaDocumentoSelecionadoRederizadosIsencao[i].name}}</label>
                                </div>
                                }@else {
                                <label for="arquivoAlteracaoPadraoIsencao-{{i}}" class="form-label botao-input">

                                    Imagem +
                                    <input type="file" id="arquivoAlteracaoPadraoIsencao-{{i}}" class="d-none"
                                        (input)="pegarPadraoIsencao($event)">

                                </label>
                                }
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn close-button" (click)="removeContact(i)"><i
                            class="fa-regular fa-circle-xmark"></i></button>
                </div>
            </div>
            }@else {
            <div class="d-flex align-items-center justify-content-center gap-1">
                Criar formulario :
                <app-botao label="Documentos" class="col-2" icon="icons8-lápis.png" status="btn-salvar"
                    (click)="addContact()"></app-botao>
            </div>
            }
        </div>
        <div class="row gap-1 gap-md-0  mt-1 col-12">
            @if(contactsPadraoIsencao.controls.length > 0 ){
            <app-botao label="Documentos" class="col-12 col-sm-3 col-md-4 col-lg-4" icon="icons8-mais.png"
                status="btn-salvar" (click)="addContact()"></app-botao>
            }
            <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-4" icon="icons8-disquete.png"
                status="btn-salvar" (click)="onSubmit()"></app-botao>
            <app-botao label="Cancelar" class="col-12 col-sm-3 col-md-4 col-lg-4" icon="icons8-x-branco.png"
                status="btn-cancelar" (click)="limparFormulario()"></app-botao>
        </div>
    </form>
</app-container-component>
}