import { MenuInspecaoComponent } from './../paginas/inspecao-equipamentos/menu-inspecao/menu-inspecao.component';
import { Routes } from "@angular/router";


export const routesInspecaoEquipamentos: Routes = [

    { path: "home", loadComponent: () => import("../paginas/inspecao-equipamentos/inspecao-home/inspecao-home.component").then(h => h.InspecaoHomeComponent) },
    //ADERENCIA
    { path: "aderencia", loadComponent: () => import("../paginas/inspecao-equipamentos/aderencia/inspecao-aderencia/inspecao-aderencia.component").then(a => a.InspecaoAderenciaComponent) },
    { path: "aderencia/:valorBusca", loadComponent: () => import("../paginas/inspecao-equipamentos/aderencia/inspecao-aderencia/inspecao-aderencia.component").then(a => a.InspecaoAderenciaComponent) },
    { path: "aderencia-registrar", loadComponent: () => import("../paginas/inspecao-equipamentos/aderencia/inspecao-aderencia-registrar/inspecao-aderencia-registrar.component").then(a => a.InspecaoAderenciaRegistrarComponent) },
    { path: "aderencia-registrar/:id", loadComponent: () => import("../paginas/inspecao-equipamentos/aderencia/inspecao-aderencia-registrar/inspecao-aderencia-registrar.component").then(a => a.InspecaoAderenciaRegistrarComponent) },
    //DUREZA LAPIS
    { path: "dureza-lapis", loadComponent: () => import("../paginas/inspecao-equipamentos/dureza-lapis/inspecao-dureza-lapis/inspecao-dureza-lapis.component").then(a => a.InspecaoDurezaLapisComponent) },
    { path: "dureza-lapis/:valorBusca", loadComponent: () => import("../paginas/inspecao-equipamentos/dureza-lapis/inspecao-dureza-lapis/inspecao-dureza-lapis.component").then(a => a.InspecaoDurezaLapisComponent) },
    { path: "dureza-lapis-registrar", loadComponent: () => import("../paginas/inspecao-equipamentos/dureza-lapis/inspecao-dureza-lapis-registrar/inspecao-dureza-lapis-registrar.component").then(d => d.InspecaoDurezaLapisRegistrarComponent) },
    { path: "dureza-lapis-registrar/:id", loadComponent: () => import("../paginas/inspecao-equipamentos/dureza-lapis/inspecao-dureza-lapis-registrar/inspecao-dureza-lapis-registrar.component").then(d => d.InspecaoDurezaLapisRegistrarComponent) },
    //Medicao camada
    { path: "medicao-camada", loadComponent: () => import("../paginas/inspecao-equipamentos/medicao-camada/inspecao-medicao-camada/inspecao-medicao-camada.component").then(m => m.InspecaoMedicaoCamadaComponent) },
    { path: "medicao-camada/:valorBusca", loadComponent: () => import("../paginas/inspecao-equipamentos/medicao-camada/inspecao-medicao-camada/inspecao-medicao-camada.component").then(m => m.InspecaoMedicaoCamadaComponent) },
    { path: "medicao-camada-registrar", loadComponent: () => import("../paginas/inspecao-equipamentos/medicao-camada/inspecao-medicao-camada-registrar/inspecao-medicao-camada-registrar.component").then(m => m.InspecaoMedicaoCamadaRegistrarComponent) },
    { path: "medicao-camada-registrar/:id", loadComponent: () => import("../paginas/inspecao-equipamentos/medicao-camada/inspecao-medicao-camada-registrar/inspecao-medicao-camada-registrar.component").then(m => m.InspecaoMedicaoCamadaRegistrarComponent) },
    //Luximetro
    { path: "luximetro", loadComponent: () => import("../paginas/inspecao-equipamentos/luximetro/inspecao-luximetro/inspecao-luximetro.component").then(l => l.InspecaoLuximetroComponent) },
    { path: "luximetro/:valorBusca", loadComponent: () => import("../paginas/inspecao-equipamentos/luximetro/inspecao-luximetro/inspecao-luximetro.component").then(l => l.InspecaoLuximetroComponent) },
    { path: "luximetro-registrar", loadComponent: () => import("../paginas/inspecao-equipamentos/luximetro/inspecao-luximetro-registrar/inspecao-luximetro-registrar.component").then(l => l.InspecaoLuximetroRegistrarComponent) },
    { path: "luximetro-registrar/:id", loadComponent: () => import("../paginas/inspecao-equipamentos/luximetro/inspecao-luximetro-registrar/inspecao-luximetro-registrar.component").then(l => l.InspecaoLuximetroRegistrarComponent) }
]