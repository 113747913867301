import { InformacaoDocumentadaService } from './../../../../services/acoes/informacao-documentada/informacao-documentada.service';
import { Component, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";

@Component({
  selector: 'app-informacao-documentada-home',
  standalone: true,
  imports: [ContainerComponentComponent],
  templateUrl: './informacao-documentada-home.component.html',
  styleUrl: './informacao-documentada-home.component.scss'
})
export class InformacaoDocumentadaHomeComponent implements OnInit {
  quantidadeInformacaoDocumentadaCriada: number = 0;
  listaInformacaoDocumentadaPendentes: any[] = [];
  listaInformacaoDocumentadaAprovadas: any[] = [];
  listaInformacaoDocumentadaReprovadas: any[] = [];

  constructor(
    private informacaoDocumentadaService: InformacaoDocumentadaService
  ) {

  }
  ngOnInit(): void {
    this.consultaInformacaoDocumentadaAprovadas();
    this.consultaInformacaoDocumentadaPendentes();
    this.consultaInformacaoDocumentadaReprovadas();
    this.consultaInformacaoDocumentadaCriadas();
  }

  public consultaInformacaoDocumentadaPendentes() {
    this.informacaoDocumentadaService.consultaInformacaoPorStatus("PENDENTE").subscribe({
      next: (res) => {
        this.listaInformacaoDocumentadaPendentes = res;
      }, error: (error) => {
        this.listaInformacaoDocumentadaPendentes = [];
      }, complete: () => {

      }
    })
  }
  public consultaInformacaoDocumentadaCriadas() {
    this.informacaoDocumentadaService.consultaInformacoesCadastradas().subscribe({
      next: (res) => {
        this.quantidadeInformacaoDocumentadaCriada = res;
      }, error: (error) => {

      }, complete: () => {

      }
    })
  }

  public consultaInformacaoDocumentadaAprovadas() {
    this.informacaoDocumentadaService.consultaInformacaoPorStatus("APROVADO").subscribe({
      next: (res) => {
        this.listaInformacaoDocumentadaAprovadas = res;
      }, error: (error) => {
        this.listaInformacaoDocumentadaAprovadas = [];
      }, complete: () => {

      }
    })
  }
  public consultaInformacaoDocumentadaReprovadas() {
    this.informacaoDocumentadaService.consultaInformacaoPorStatus("REPROVADO").subscribe({
      next: (res) => {
        this.listaInformacaoDocumentadaReprovadas = res;
      }, error: (error) => {
        this.listaInformacaoDocumentadaReprovadas = [];
      }, complete: () => {

      }
    })
  }

}
