<app-container-component tituloPage="INFORMAÇÃO DOCUMENTADA" subtituloPage="Informações cadastradas">
  <app-informacao-documentada-home></app-informacao-documentada-home>
  <div class="col-12 d-flex flex-column justify-content-end align-items-end">
    <div class="col-12 col-md-6 col-lg-6 col-xl-4 mt-2 me-2">
      <div class="input-group mb-3">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="valorPesquisado"
          placeholder="Identificação" (keypress)="onKeyEnterPress($event)">
        <button class="btn btn-sm" type="button"><i class="fa-solid fa-magnifying-glass"
            (click)="consultaPorIdentificacao()"></i></button>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-striped align-middle">
      <thead class="table-secondary">
        <tr>
          <th scope="col">Identificação</th>
          <th scope="col">Cadastro</th>
          <th scope="col">Responsável</th>
          <th scope="col" class="text-center">Origem</th>
          <th scope="col" class="text-center">Situação</th>
          <th scope="col" class="text-center">Versão</th>
          <th scope="col" class="text-center">Arquivo</th>
          <th scope="col" class="text-center">Alterações</th>
        </tr>
      </thead>
      <tbody>
        @if(listaDeInformacaoDocumentada.length > 0) {
        @for (identificacao of listaDeInformacaoDocumentada; track $index) {
        <tr title="Clique para visualizar">
          <td data-label="Identificação">{{ identificacao.identificacao }}</td>
          <td data-label="Data Criação">{{ identificacao.dataCadastro | date: 'dd/MM/yy' }}</td>
          <td data-label="Criado por">{{ identificacao.usuarioCadastro }}</td>
          <td data-label="Origem" class="text-center">{{ identificacao.classificacao }}</td>
          <td data-label="Situação" class="text-center">
            <span class="badge" [ngClass]="{
                      'bg-success': identificacao.status === StatusInfoDoc.APROVADO,
                      'bg-warning': identificacao.status === StatusInfoDoc.PENDENTE,
                      'bg-secondary': identificacao.status === StatusInfoDoc.OBSOLETO,
                      'reprovado': identificacao.status === StatusInfoDoc.REPROVADO
                  }">
              {{ identificacao.status }}
            </span>
          </td>
          <td data-label="Versão" class="text-center">{{ identificacao.versao }}</td>
          <td data-label="Arquivo" class="text-center">
            <a [href]="identificacao.path" target="_blank">
              <img class="icon" src="assets/icons/48x48/icons8-pdf.png" alt="arquivo">
            </a>
          </td>
          <td data-label="Histórico" class="text-center">
            @if(identificacao.versao > 1){
            <button class="btn btn-outline-primary btn-sm"
              (click)="verHistorioDeInformacaoDocumentada(identificacao.id)">
              <i class="fa-solid fa-clock"></i> Histórico
            </button>
            }
          </td>
        </tr>
        }
        }
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center col-12">
    <app-paginator [quantidadesPorPagina]="itensPorPagina" [paginaAtual]="paginaAtual" [totalPorPagina]="totalPorPagina"
      [totalElementos]="totalElementos" (paginaMudou)="onPageChange($event)"></app-paginator>
  </div>
</app-container-component>