<div class="d-flex justify-content-between header align-items-center">
    <div class="ms-2">
        OS N° - {{os.id}}
    </div>
    <div class="close" (click)="close()">
        X
    </div>
</div>
<div class="p-3">
    <div class="separador mt-2 mb-2 mt-3">
        <strong>DADOS DA OS</strong>
    </div>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class=" p-2">
                <div>EQUIPAMENTO:</div>
                <div class="detalhes">{{os.equipamento.descricao}}</div>
                <div>TIPO: </div>
                <div class="detalhes">{{os.tipo}}</div>
                <div>LOCAL:</div>
                <div class="detalhes">{{os.equipamento.local}}</div>
            </div>
        </div>

        <div class="col-12 col-lg-6">
            <div class=" p-2">
                <div>ABERTURA:</div>
                <div class="detalhes">{{os.equipamento.dataCadastro | date: 'dd/MM/yyyy'}}</div>
                <div>SITUAÇÃO: </div>
                <div class="detalhes">{{os.equipamento.status}}</div>
            </div>
        </div>
    </div>

    <div class=" row">
        @if(os.status === 'ABERTA'){
        <div class="col-12 mt-2">
            <label for="" class="form-label">Solicitação:</label>
            <textarea class="form-control form-control-sm" rows="3" [(ngModel)]="os.solicitacao"></textarea>
        </div>
        <div class="col-12 col-lg-6 mt-2">
            <label for="" class="form-label">Prioridade:</label>
            <select class="form-select form-select-sm" [(ngModel)]="os.prioridade">
                <option value="" selected></option>
                <option value="Alta">Alta</option>
                <option value="Normal">Normal</option>
                <option value="Baixa">Baixa</option>
                <option value="Sem prioridade">Sem prioridade</option>
            </select>
        </div>
        <div class="col-12 col-lg-6 mt-2">
            <label for="" class="form-label">Parada de produção:</label>
            <select class="form-select form-select-sm" [(ngModel)]="os.stopProd">
                <option value="" selected></option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
            </select>
        </div>
        }@else {
        <div class="col-12 mt-2">
            <label for="" class="form-label">Solicitação:</label>
            <div class="detalhes">
                <P>{{os.solicitacao}}</P>
            </div>
        </div>
        <div class="col-12 col-lg-4 mt-2">
            <label for="" class="form-label">Prioridade:</label>
            <div class="detalhes">
                {{os.prioridade}}
            </div>
        </div>
        <div class="col-12 col-lg-4 mt-2">
            <label for="" class="form-label">Parada de produção:</label>
            <div class="detalhes">
                {{os.stopProd}}
            </div>
        </div>
        <div class="col-12 col-lg-4 mt-2">
            <label for="" class="form-label">Aprovador:</label>
            <div class="detalhes">
                {{os.aprovador}}
            </div>
        </div>
        }
    </div>

    @if(os.status === 'ABERTA'){
    <button class="btn-sm btn mt-3" (click)="alterar()">Alterar</button>
    }
    @if(os.status === 'REPROVADA'){
    <div class="col-12 mt-2">
        <label for="" class="form-label bold text-danger">Motivo reprova:</label>
        <div class="detalhes">
            <P>{{os.justificativa}}</P>
        </div>
    </div>
    }@else{
    <div class="separador mt-2 mb-2 mt-3">
        <strong>APONTAMENTOS REALIZADOS</strong>
    </div>
    <div class="table mt-3">
        <table class="table table-sm">
            <thead class="table-secondary">
                <tr>
                    <th>DATA</th>
                    <th>APONTAMENTO</th>
                    <th>RESPONSÁVEL</th>
                    <th>TIPO</th>
                    <th class="text-center">TEMPO (Min)</th>
                </tr>
            </thead>
            <tbody>
                @for (apon of apontamentosPaginado; track $index) {
                <tr>
                    <td>{{apon.data | date : 'dd/MM/yyyy'}}</td>
                    <td class="text-wrap">
                        {{apon.atividadeRealizada}}
                    </td>
                    <td>{{apon.responsavel}}</td>
                    <td>{{apon.tipoApontamento}}</td>
                    <td class="text-center">{{apon.tempoGasto}}</td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    <app-paginator-sistemico [itens]="apontamentos" [itemsPorPagina]="itensPorPagina"
        (itensPaginados)="pegarMudanca($event)"></app-paginator-sistemico>
    }
</div>