import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ManualClienteCadastro } from '../../../../model/controles/manuais-clientes/manual-cliente-cadastro';
import { ManuaisClientesService } from '../../../../services/controles/manuais-clientes/manuais-clientes.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-manual-cliente-cadastro',
  standalone: true,
  imports: [
    ContainerComponentComponent,
    FormsModule,
    ReactiveFormsModule,
    MensagemErrorsComponent,
    CommonModule
],
  templateUrl: './manual-cliente-cadastro.component.html',
  styleUrl: './manual-cliente-cadastro.component.scss'
})
export class ManualClienteCadastroComponent implements OnInit{

  formulario!:FormGroup;
  formBuilder = inject(FormBuilder);
  manualService = inject(ManuaisClientesService);
  snackBarService = inject(SnackBarService);
  formValidacaoService = inject(FormValidacaoService);
  manualCadastro: ManualClienteCadastro;

  constructor(){
    this.manualCadastro = new ManualClienteCadastro();
  }

  ngOnInit(): void {
      this.iniciarFormulario();
  }

  public iniciarFormulario(){
    this.formulario = this.formBuilder.group({
      cliente:[null, Validators.required],
      descricao:[null, Validators.required],
      versao:[null, Validators.required],
      obs:[null],
      status:['Pendente']
    });
  }

  public cadastrarManual(){
   if(this.formulario.valid){
    this.manualCadastro = this.formulario.value;
    this.manualService.cadastrarManual(this.manualCadastro).subscribe({
      next:(res)=>{
        this.snackBarService.openSnackBar('Manual do cliente cadastrado com sucesso!', 'my-snack-bar-sucess');
        this.iniciarFormulario();
      },
      error:(e)=>{
        this.snackBarService.tratarErro(e, "Falha ao tentar cadastrar manual do cliente!");
      }
    });
   }else{
    this.formulario.markAllAsTouched();
   }
  }

  //Metodos utilizados para validação de formulário
    public obterValidacao(nome: string): FormControl {
      return this.formValidacaoService.obterValidacao(this.formulario, nome);
    }
    
    public tocado(nome: string): boolean {
      return this.formValidacaoService.tocado(this.formulario, nome);
    }
  
    public validar(nome: string): boolean {
      return this.formValidacaoService.validar(this.formulario, nome);
    }
  
    public sujoInvalido(nome: string): boolean {
      return this.formValidacaoService.sujoInvalido(this.formulario, nome);
    }

}
