import { Routes } from "@angular/router";
import { MsaRegistrarComponent } from "../../paginas/engenharia/msa/msa-registrar/msa-registrar.component";

export const routesEngenhariaEdit: Routes = [
    {
        path: "msa-registrar", component: MsaRegistrarComponent,
    },
    {
        path: "msa-registrar/:id", component: MsaRegistrarComponent,
    },
]