import { Component } from '@angular/core';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-home-indicadores',
  standalone: true,
  imports: [CommonModule,ContainerComponentComponent],
  templateUrl: './home-indicadores.component.html',
  styleUrl: './home-indicadores.component.scss'
})
export class HomeIndicadoresComponent {
  titulo = "MÓDULO DE INDICADORES";
  subTitulo = "Bem vindo a sessão de indicadores";
}
