<app-container-component [header]="desativarHeader" tituloPage="Consulta de normas de pintura"
    subtituloPage="Registro de Norma">
    <div class="col-12 d-flex flex-column justify-content-end align-items-end">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mt-2 me-2">
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="valorPesquisado"
                    (keypress)="this.onKeyPress($event)"
                    placeholder="Montadora , Norma , Descrição, Responsável, Data, Atualizado,Versão">
                <button class="btn btn-sm" type="button"><i class="fa-solid fa-magnifying-glass"
                        (click)="this.consultaPorValorPesquisado($event)"></i></button>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-hover pointer table-striped align-middle">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">Montadora</th>
                    <th scope="col">Norma</th>
                    <th scope="col">Descrição</th>
                    <th scope="col" class="text-center">Responsável</th>
                    <th scope="col" class="text-center">Data atualização</th>
                    <th scope="col" class="text-center">Atualizado por</th>
                    <th scope="col" class="text-center">Versão</th>
                </tr>
            </thead>
            <tbody>
                @if(listaDeNormasPintura.length > 0) {
                @for (normaPintura of listaDeNormasPintura; track $index) {
                <tr class="table-row" matTooltipPosition="above" matTooltipHideDelay="1" [matTooltip]="temPermissao() ? 'Vizualizar norma' : ''"
                    (click)="temPermissao() ? editarNormaPintura(normaPintura.id) : ''">
                    <td data-label="Montadora">
                        <a>
                            {{ normaPintura.montadora }}
                        </a>
                    </td>
                    <td data-label="Norma">
                        <a>
                            {{ normaPintura.norma }}
                        </a>
                    </td>
                    <td data-label="Descrição">
                        <a>
                            {{ normaPintura.descricao }}
                        </a>
                    </td>
                    <td data-label="Responsável" class="text-center">
                        {{ normaPintura.usuarioCadastro }}
                    </td>

                    <td data-label="Data atualização" class="text-center">
                        <a>
                            {{ normaPintura.dataAlteracao | date: 'dd/MM/yy' }}
                        </a>
                    </td>
                    <td data-label="Atualizado por" class="text-center">
                        <a>
                            {{ normaPintura.usuarioAlteracao }}
                        </a>
                    </td>
                    <td data-label="Versão" class="text-center">
                        <a>
                            {{ normaPintura.versao }}
                        </a>
                    </td>
                </tr>
                }
                }
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center col-12">
        <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
            [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
            (paginaMudou)="onPageChange($event)"></app-paginator>
    </div>
</app-container-component>