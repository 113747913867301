import { Component, inject, OnInit } from '@angular/core';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { RouterLink } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import PartesInteressadasConsultaDto from '../../../../model/acoes/partes-interessadas/ParteInteressadaConsultaDto';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormsModule } from '@angular/forms';
import { PartesInteressadasService } from '../../../../services/acoes/partes-interessadas/partes-interessadas.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-partes-interessadas',
  standalone: true,
  imports: [CommonModule,PaginatorComponent, RouterLink, ContainerComponentComponent,FormsModule],
  templateUrl: './partes-interessadas.component.html',
  styleUrl: './partes-interessadas.component.scss'
})
export class PartesInteressadasComponent implements OnInit {


  valorPesquisado:string = "";
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  listaDeMapeamento: PartesInteressadasConsultaDto[] = [];

  private partesInteressadaService = inject(PartesInteressadasService);


  ngOnInit(): void {
    this.buscarMapeamento();
  }

  public buscarMapeamento(event?:any) {
    this.partesInteressadaService.consultaPaginada(this.paginaAtual,this.totalPorPagina).subscribe({
      next:(res)=>{
        this.listaDeMapeamento = res.content;
        this.processarElementosPagina(res.page);
      }
    });
  }

  private processarElementosPagina(page: any){
    this.paginaAtual = page.number;
    this.totalTamanho = page.totalElements;
  }


  public visualizarMapeamento(idMapeamento: number) {

  }


  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.buscarMapeamento(event); // Chama o método buscar se a tecla Enter for pressionada
    }
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.buscarMapeamento();
  }
}
