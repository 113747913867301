export class AutoridadePesquisa {
    id: number | undefined;
    idPesquisa: number | undefined;
    nome: string | undefined;
    empresa: string | undefined;
    email: string | undefined;
    token: string | undefined;
    dataCadastro: string | undefined;
    dataResposta: string | undefined;
    respondia: boolean | undefined;
    statusEmail: string | undefined;
}
