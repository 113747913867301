import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { PartesInteressadasService } from './../../../../services/acoes/partes-interessadas/partes-interessadas.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Component, inject, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { BotaoComponent } from "../../../../componentes/botao/botao.component";
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import PartesInteressadasConsultaDto from '../../../../model/acoes/partes-interessadas/ParteInteressadaConsultaDto';
import AcoesPartesInteressadas from '../../../../model/acoes/partes-interessadas/AcoesPartesInteressadas';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-partes-interessada-registrar-acoes',
  standalone: true,
  imports: [CommonModule, MensagemErrorsComponent, ContainerComponentComponent, BotaoComponent, ReactiveFormsModule],
  templateUrl: './partes-interessada-registrar-acoes.component.html',
  styleUrl: './partes-interessada-registrar-acoes.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartesInteressadaRegistrarAcoesComponent implements OnInit, OnChanges, AfterViewInit {


  formularioCadastroAcoes!: FormGroup;
  idParteInteressada: number = 0;
  @Input() mapeamentoRecebido!: PartesInteressadasConsultaDto;
  @Input() acaoParteInteressada!: AcoesPartesInteressadas;
  @Input() emitirMovimento: boolean = false;
  @Output() emitirNovaConsulta: EventEmitter<boolean> = new EventEmitter;
  @ViewChild("formularioEditarAcoes") formularioEditarAcoes!: ElementRef;
  private formValidacaoService = inject(FormValidacaoService);
  private formBuilder = inject(FormBuilder);
  private activatedRoute = inject(ActivatedRoute);
  private partesInteressadasService = inject(PartesInteressadasService);
  snackBarService = inject(SnackBarService);
  emisorEventoGeralService = inject(EmisorEventoGeralService);


  ngOnInit(): void {
    this.verificarRota();
    this.verificarEditar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.verificarRota();
    if (changes['acaoParteInteressada'] && changes['acaoParteInteressada'].currentValue && this.formularioCadastroAcoes) {
      this.formularioCadastroAcoes.patchValue(this.acaoParteInteressada);
    }
  }

  ngAfterViewInit(): void {
    this.moverParaFormulario();
  }

  public verificarEditar() {
    this.partesInteressadasService.emitirEdicao.subscribe(res => {
      if (res.id) {
        this.moverParaFormulario();
      }
    });
  }


  public cadastrarAcoesParteInteressada() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.formularioCadastroAcoes.removeControl("id");
    this.partesInteressadasService.cadastrarAcoesPartesInteressadas(this.idParteInteressada, this.formularioCadastroAcoes.value).subscribe({
      next: (res) => {

      }, error: (error) => {
        console.log(error)
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar salvar ação");
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.emitirNovaConsulta.emit(true);
        this.iniciarFormulario();
        this.snackBarService.openSnackBar("Ação criada com sucesso", "my-snack-bar-sucess");
      }
    });
  }
  public editarAcoesParteInteressada() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.partesInteressadasService.editarAcoesPartesInteressadas(this.idParteInteressada, this.formularioCadastroAcoes.value).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar salvar ação");
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.emitirNovaConsulta.emit(true);
        this.formularioCadastroAcoes.reset();
        this.snackBarService.openSnackBar("Ação criada com sucesso", "my-snack-bar-sucess");
      }
    })
  }

  public verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idParteInteressada = res.id;
      }
    });
  }

  public iniciarFormulario() {
    this.formularioCadastroAcoes = this.formBuilder.group({
      id: [],
      parteInteressada: [],
      descricao: [],
      requisito: [],
      monitoramento: []
    })
  }

  public limparFormulario() {
    this.formularioCadastroAcoes.reset();
    this.acaoParteInteressada = new AcoesPartesInteressadas();
  }


  public moverParaFormulario() {
    if (this.formularioEditarAcoes) {
      this.formularioEditarAcoes.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formularioCadastroAcoes, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formularioCadastroAcoes, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formularioCadastroAcoes, nome);
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }
}
