import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { Situacao } from '../../../../model/engenharia/situacao';
import { SituacaoService } from '../../../../services/engenharia/situacao/situacao.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { MensagemErrorsComponent } from '../../../../componentes/mensagem-errors/mensagem-errors.component';
import { ModalComponent } from '../../../../componentes/modal/modal.component';

@Component({
  selector: 'app-situacao-modal',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,BotaoComponent,MensagemErrorsComponent],
  templateUrl: './situacao-modal.component.html',
  styleUrl: './situacao-modal.component.scss'
})
export class SituacaoModalComponent implements OnInit {
  
  formularioSituacao!: FormGroup;
  situacao: Situacao[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private formValidacaoService: FormValidacaoService,
    private situacaoService: SituacaoService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog
  ) {

  }
  ngOnInit(): void {
    this.iniciarFormulario();
    this.buscarTodos();
  }


  public iniciarFormulario() {
    this.formularioSituacao = this.formBuilder.group({
      id: [],
      descricao: ['', Validators.required],
    });
  }



  public criarTipoPlanoAcao() {
    this.situacaoService.salvarSituacao(this.formularioSituacao.value).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Tipo de plano de ação salvo com sucesso", "my-snack-bar-sucess");
      }, error: (error) => {
        this.snackBarService.openSnackBar("Tipo de plano de ação salvo com sucesso", "my-snack-bar-sucess");
      }, complete: () => {
        this.limparFormulario();
        this.buscarTodos();
      }
    });
  }

  public editarTipoPlanoAcao() {
    this.situacaoService.editarAcaoSituacao(this.formularioSituacao.value).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Tipo de plano de ação salvo com sucesso", "my-snack-bar-sucess");
      }, error: (error) => {
        this.snackBarService.openSnackBar("Tipo de plano de ação salvo com sucesso", "my-snack-bar-sucess");
      }, complete: () => {
        this.buscarTodos();
      }
    });
  }

  public voltar() {

  }

  public limparFormulario() {
    this.formularioSituacao.reset()
  }

  public buscarPorId(item: Situacao) {
    this.formularioSituacao.patchValue(item);
  }

  public buscarTodos() {
    this.situacaoService.consultarTodasSituacao().subscribe({
      next: (res) => {
        this.situacao = res;
      }, error: (error) => {
        this.snackBarService.openSnackBar("Tipo de plano de ação com erro ao buscar", "my-snack-bar-erro");
      }, complete: () => {

      }
    });
  }

  public deletar(id: number) {
    this.situacaoService.deletarSituacao(id).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Tipo de plano de ação salvo com sucesso", "my-snack-bar-sucess");
      }, error: (error) => {
        console.log(error)
        this.snackBarService.openSnackBar("Erro ao tenta excluir", "my-snack-bar-erro");
      }, complete: () => {
        this.buscarTodos();
      }
    });
  }

  public deletarTipoPlanoAcao(descricao: string, id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: descricao, textoConteudo: "Tipo de plano de ação" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.deletar(id);
        };
      }
    });
  }

  public tocado(nome: string) {
    return this.formValidacaoService.tocado(this.formularioSituacao, nome)
  }
  public validar(nome: string) {
    return this.formValidacaoService.validar(this.formularioSituacao, nome)
  }
  public obterValidacao(nome: string) {
    return this.formValidacaoService.obterValidacao(this.formularioSituacao, nome)
  }

  verificarEstado(valorDigitado: string) {
    if (valorDigitado === '') {
      this.formularioSituacao.reset();
    }
  }

  public closeModal(){
    this.dialog.closeAll()
  }
}
