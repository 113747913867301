<app-container-component [header]="false" [desativarSubTitulo]="false" [iconeVolta]="false">
 <div class="row mb-4">
  <div class="col-12">
    <div class="text-center img">
      <img src="assets/logo-croma.png" alt="">
    </div>
  </div>
  <div class="col-12 text-center mt-3">
    <p>Seja bem vindo a pesquisa de satifação do cliente!</p>
  </div>
 </div> 

 
  @if(this.autoridadePesquisa.respondia){
    <div class="card-response">
      <div class="card-header">
        <h2>Esta pesquisa já foi respondida!</h2>
      </div>
      <div class="card-body">
        <p>Obrigado por participar da nossa pesquisa, seus comentários são muito importantes para nós!</p>
      </div>
    </div>
  }@else if(indisponivel){
    <div class="card-response">
      <div class="card-header">
        <h2>Pesquisa indisponível!</h2>
      </div>
      <div class="card-body">
        <p>Esta pesquisa não está mais disponível para resposta.</p>
      </div>
    </div>
  }@else {
    <form [formGroup]="formularioDePerguntas">
      <div class="row">
        <div class="mb-3  col-4">
          <label for="text" class="col-form-label-sm">Nome:</label>
          <input type="text" formControlName="nome" class="form-control form-control-sm">
          <app-mensagem-errors [control]="obterValidacao('nome')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-4">
          <label for="text" class="col-form-label-sm">Empresa:</label>
          <input class="form-control form-control-sm" formControlName="empresa">
          <app-mensagem-errors [control]="obterValidacao('empresa')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-4">
          <label for="text" class="col-form-label-sm">Área:</label>
          <input class="form-control form-control-sm" formControlName="area">
          <app-mensagem-errors [control]="obterValidacao('area')"></app-mensagem-errors>
        </div>
      </div>
      <div class="row  justify-content-center">
        @if(satisfacaoCliente.perguntas && satisfacaoCliente.perguntas.length > 0){
          <button class="btn btn-sm col-12 col-md-4 col-lg-2" (click)="abrirFormularioResponder()">
            {{ responder ? 'Cancelar' : 'Iniciar' }}
          </button>
        }@else {
        <div class="text-center">
          <span>Não há perguntas cadastradas</span>
        </div>
        }
        @if(responder){
        <mat-stepper class="example-stepper" orientation="vertical" #stepper>
          @for (pergunta of satisfacaoCliente.perguntas; track pergunta.id; let i = $index) {
          <mat-step [stepControl]="formularioDePerguntas.get('resposta-' + i)!" [label]="pergunta.descPergunta">
            <mat-form-field class="col-11">
              <mat-label>Escolha uma opção</mat-label>
              <mat-select [formControlName]="'resposta-'+i" required>
                <mat-option value="Ótimo">Ótimo</mat-option>
                <mat-option value="Bom">Bom</mat-option>
                <mat-option value="Regular">Regular</mat-option>
                <mat-option value="Ruim">Ruim</mat-option>
                <mat-option value="Péssimo">Péssimo</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-hint
              *ngIf="!formularioDePerguntas.controls['resposta-' + i].valid && formularioDePerguntas.controls['resposta-' + i].touched">
              <i class="fa-solid fa-circle-xmark"></i>
            </mat-hint>
            <mat-hint *ngIf="formularioDePerguntas.controls['resposta-' + i].valid">
              <i class="fa-solid fa-circle-check"></i>
            </mat-hint>
            <div class="mb-3 mt-3">
              <label for="form-label">Deixe suas observações:</label>
              <textarea class="form-control form-control-sm" formControlName="observacao-{{i}}"></textarea>
            </div>
            <div class="row gap-1 gap-sm-3">
              <button mat-button matStepperPrevious class="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-1"> <i
                  class="fa-solid fa-arrow-left"></i> Anterior</button>
              <button mat-button matStepperNext class="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-1">Proxima <i
                  class="fa-solid fa-arrow-right"></i></button>
            </div>
          </mat-step>
          }
          <mat-step>
            <ng-template matStepLabel>Finalizado</ng-template>
            <div class="row gap-1 gap-sm-3 ms-1">
              <button mat-button matStepperPrevious class="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-1"> <i
                  class="fa-solid fa-arrow-left"></i> Anterior</button>
              <button mat-button matStepperNext class="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-1"
                (click)="enviarQuestionario()">Enviar
                <i class="fa-solid fa-file-import"></i></button>
            </div>
            <p class="mt-3"><b>Você finalizou o questionario !!!</b></p>
          </mat-step>
          <ng-template matStepperIcon="chat">
            <mat-icon>forum</mat-icon>
          </ng-template>
        </mat-stepper>
        }
      </div>
    </form>
  }
</app-container-component>