<app-container-component tituloPage="ANÁLISE CRÍTICAS" subtituloPage="Análise críticas cadastradas">
    <div class="col-12 d-flex flex-column justify-content-end align-items-end">
        <div class="col-12 col-md-6 col-lg-6 col-xl-4 m-0 mt-md-2 me-md-2">
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="valorPesquisado"
                    (keypress)="this.onKeyPress($event)" placeholder="Análise Crítica,Identificação">
                <button class="btn btn-sm" type="button"><i class="fa-solid fa-magnifying-glass"
                        (click)="this.consultaPaginada($event)"></i></button>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-hover pointer table-striped align-middle">
            <thead class="table-secondary">
                <tr>
                    <th scope="col">AC</th>
                    <th scope="col">Identificação</th>
                    <th scope="col">Data</th>
                    <th scope="col" class="text-center">Consultar</th>
                </tr>
            </thead>
            <tbody>
                @if(listaAnaliseCritica.length > 0) {
                @for (analiseCritica of listaAnaliseCritica; track $index) {
                <tr title="Clique para editar" class="table-row ">
                    <td data-label="Mapeamento">
                        <a [routerLink]="['/acoes/analise-critica-registrar', analiseCritica.id]">
                            {{ analiseCritica.id }}
                        </a>
                    </td>
                    <td data-label="analiseCritica">
                        <a [routerLink]="['/acoes/analise-critica-registrar', analiseCritica.id]">
                            {{ analiseCritica.identificacao }}
                        </a>
                    </td>
                    <td data-label="Atualização">
                        <a [routerLink]="['/acoes/analise-critica-registrar', analiseCritica.id]">
                            {{ analiseCritica.dataRealizacao | date: "dd/MM/yyyy" }}
                        </a>
                    </td>
                    <td data-label="Visualizar" class="text-center" title="Clique para visualizar"
                        (click)="visualizarAnaliseCritica(analiseCritica.id)">
                        <a [routerLink]="['/acoes/analise-critica-visualizar', analiseCritica.id]">
                            <i class="fa-solid fa-eye"></i>
                        </a>
                    </td>
                </tr>
                }
                }
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center col-12">
        <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
            [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
            (paginaMudou)="onPageChange($event)"></app-paginator>
    </div>
</app-container-component>