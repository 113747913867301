import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { AutoridadePesquisaService } from './../../../../services/controles/satisfacao-cliente/autoridadePesquisa.service';
import { SatisfacaoClienteService } from './../../../../services/controles/satisfacao-cliente/satisfacao-cliente.service';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { SatisfacaoCliente } from '../../../../model/controles/satisfacao-cliente/satisfacao-cliente';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { RegistroPaginado } from '../../../../interface/registroPaginado';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-satisfacao-cliente-lista',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, PaginatorComponent, FormsModule, MatSlideToggleModule],
  templateUrl: './satisfacao-cliente-lista.component.html',
  styleUrl: './satisfacao-cliente-lista.component.scss'
})
export class SatisfacaoClienteListaComponent implements OnInit {
  titulo = "LISTA SATISFAÇÃO CLIENTE";
  nomeFormulario = "Pesquisas de satisfação";
  listaDeSatisfacaoCliente: SatisfacaoCliente[] = [];
  statusSelecionado: string = "Ativo";
  @Input() comTopo: boolean = true;
  checked = false;
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [6, 12, 18, 24, 30, 36]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  private loginService = inject(LoginService);


  constructor(
    private satisfacaoClienteService: SatisfacaoClienteService,
    private autoridadePesquisaService: AutoridadePesquisaService,
    private router: Router,
    private snackBarService: SnackBarService,
    private emisorEventoGeralService:EmisorEventoGeralService,
    private dialog: MatDialog,
  ) {

  }
  ngOnInit(): void {
    this.consultarTodasSatisfacaoCliente();
  }

  public consultarTodasSatisfacaoCliente() {
    this.satisfacaoClienteService.consultaPesquisasPaginada(this.paginaAtual, this.totalPorPagina,this.statusSelecionado).subscribe({
      next: (res: RegistroPaginado) => {
        this.listaDeSatisfacaoCliente = res.content;
        this.totalTamanho = res.page.totalElements;
      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar consultar pesquisas ")
      }, complete: () => {
        this.paginaAtual = 0;
      }
    });
  }

  public pesquisarPorStatus() {
    this.consultarTodasSatisfacaoCliente()
  }

  public confirmarTrocaDeStatus(pesquisa: any, novoStatus: boolean) {
    // Armazena o status atual
    const statusAnterior = pesquisa.status;
  
    // Define o texto de acordo com a ação
    const statusTexto = novoStatus ? 'Ativar' : 'Inativar';
  
    // Abre o modal de confirmação
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: pesquisa.nomePesquisa, texto: `Deseja ${statusTexto} esta pesquisa?` },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
  
    // Ação após o fechamento do modal
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          // Se o usuário confirmar, altera o status
          pesquisa.status = novoStatus ? 'Ativo' : 'Inativo';
  
          // Chama o método para salvar o novo status no banco de dados
          this.trocarStatus(pesquisa);
        } else {
          // Se o usuário cancelar, reverte a alteração
          pesquisa.status = statusAnterior; // Restaurar o status anterior
        }
      }
    });
  }
  
  
  

  public trocarStatus(pesquisa: SatisfacaoCliente) {
    this.satisfacaoClienteService.atualizarPesquisa(pesquisa).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar consultar pesquisas ");
      }, complete: () => {
        this.snackBarService.openSnackBar("Status alterado da pesquisa", "my-snack-bar-sucess");
        this.consultarTodasSatisfacaoCliente();
      }
    });
  }

  public enviarPesquisa(idPesquisa:number){
    this.emisorEventoGeralService.carregando.emit(true);
    this.autoridadePesquisaService.enviarEmailsParticipacao(idPesquisa).subscribe({
      next:(res)=>{
        this.snackBarService.openSnackBar("Pesquisa enviada com sucesso!","my-snack-bar-sucess");
      },error:(error)=>{
        this.snackBarService.tratarErro(error,"Erro ao tentar enviar pesquisa");
        this.emisorEventoGeralService.carregando.emit(false);
      },complete:()=>{
        this.emisorEventoGeralService.carregando.emit(false);
      }
    })
  }

  public editarSatisfacaoCliente(idSatisfacao: number) {
    this.router.navigate([`controles/cadastrar-satisfacao-cliente/${idSatisfacao}`]);
  }

  public criarAutoridade(idSatisfacao: number) {
    this.router.navigate([`controles/autoridade-pesquisa/${idSatisfacao}`]);
  }


  public criarPerguntaSatisfacaoCliente(idSatisfacao: number) {
    this.router.navigate([`controles/editar-satisfacao-cliente/${idSatisfacao}`]);
  }

  public verResultados(idSatisfacao: number) {
    this.router.navigate([`controles/resultados-satisfacao-cliente/${idSatisfacao}`]);
  }

  public mudancaNaPagina(event: any) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultarTodasSatisfacaoCliente();
  }

  public temPermissao(){
    return this.loginService.temPermissao(['ROLE_CONT_EDIT']);
  }

  public temPermissaoExcluir(){
    return this.loginService.temPermissao(['ROLE_CONT_ADMIN']);
  }

}
