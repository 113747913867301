import AcoesPlanejamentoComunicacao from "./AcoesPlanejamentoComunicacao"

export class PlanejamentoComunicacao {
    id: number  = 0;
    descricao: string | undefined;
    versao: string | undefined;
    status: string | undefined;
    idUsuarioCadastro: number | undefined;
    usuarioCadastro: string | undefined;
    dataCadastro: string | undefined;
    idUsuarioAlteracao: string | undefined;
    usuarioAlteracao: string | undefined;
    dataAlteracao: string | undefined;
    itens: AcoesPlanejamentoComunicacao[] = [];
}
