import { EngCriteriosDeAutoInspecaoService } from './../../../../services/engenharia/processo/eng-criterios-de-auto-inspecao.service';
import { ChangeDetectorRef, Component, ElementRef, SimpleChanges, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { Produto } from '../../../../model/engenharia/produto-model';
import { BookService } from '../../../../services/producao/book.service';
import { CommonModule } from '@angular/common';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";

@Component({
  selector: 'app-criterio-de-inspecao',
  standalone: true,
  imports: [CommonModule, SwiperComponentComponent],
  templateUrl: './criterio-de-inspecao.component.html',
  styleUrl: './criterio-de-inspecao.component.scss'
})
export class CriterioDeInspecaoComponent {

  criterioAutoInspecao: any = [];
  criterioAutoInspecao2: any = [];
  imagemNaoCadastrada1 = false;
  imagemNaoCadastrada2 = false;
  produto: Produto = new Produto();
  processoDoProduto: string[] = [];
  dados: any;
  tabAtiva: string = '';
  @ViewChild("swiperCriterioAutoInspecao") swiperCriterioAutoInspecao!: ElementRef;
  @ViewChild("swiperCriterioAutoInspecao2") swiperCriterioAutoInspecao2!: ElementRef;
  swiper!: Swiper;

  constructor(
    private engCriteriosDeAutoInspecaoService: EngCriteriosDeAutoInspecaoService,
    private bookService: BookService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }

  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.produto = new Produto();
        this.criterioAutoInspecao = [];
        this.criterioAutoInspecao2 = [];
        this.tabAtiva = '';
        this.produto = res;
        if (this.produto.nomeProduto) {
          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          this.consultarCriterioAutoInspecaoProcesso1(this.dados.numeroProcesso1);
        }
      },
      error: (e: any) => {

      }
    });
  }

  consultarCriterioAutoInspecaoProcesso1(processo: number) {
    this.imagemNaoCadastrada1 = false;
    this.criterioAutoInspecao = [];
    this.engCriteriosDeAutoInspecaoService.consultarCriterioAutoInspecao(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.criterioAutoInspecao = res;
      }, error: (e) => {
        this.imagemNaoCadastrada1 = true;
        this.consultarCriterioAutoInspecaoProcesso2(this.dados.numeroProcesso2);
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada();
        this.consultarCriterioAutoInspecaoProcesso2(this.dados.numeroProcesso2);
      },
    });
  }

  consultarCriterioAutoInspecaoProcesso2(processo: number) {
    this.imagemNaoCadastrada2 = false;
    this.criterioAutoInspecao2 = [];
    this.engCriteriosDeAutoInspecaoService.consultarCriterioAutoInspecao(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.criterioAutoInspecao2 = res;
      }, error: (e) => {
        this.imagemNaoCadastrada2 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2();

      },
    });
  }

  consultarCriterioAutoInspecaoProcesso1Click(processo: number) {
    this.imagemNaoCadastrada1 = false;
    this.criterioAutoInspecao = [];
    this.engCriteriosDeAutoInspecaoService.consultarCriterioAutoInspecao(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.criterioAutoInspecao = res;
      }, error: (e) => {
        this.imagemNaoCadastrada1 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada();
      },
    });
  }

  consultarCriterioAutoInspecaoProcesso2Click(processo: number) {
    this.imagemNaoCadastrada2 = false;
    this.criterioAutoInspecao2 = [];
    this.engCriteriosDeAutoInspecaoService.consultarCriterioAutoInspecao(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.criterioAutoInspecao2 = res;
      }, error: (e) => {
        this.imagemNaoCadastrada2 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2();

      },
    });
  }

  private definirTabAtiva() {
    if (this.criterioAutoInspecao) {
      this.tabAtiva = 'processo1';
      return;
    } if (this.criterioAutoInspecao2) {
      this.tabAtiva = 'processo2';
    }
  }

  private iniciarImagemCarregada() {
    this.iniciarImagemCarregadaParaArray(this.criterioAutoInspecao);
  }

  private iniciarImagemCarregada2() {
    this.iniciarImagemCarregadaParaArray(this.criterioAutoInspecao2);
  }

  private iniciarImagemCarregadaParaArray(array: any[]) {
    array.forEach((e: any) => {
      if (!e.imagemCarregada) {
        e.imagemCarregada = false;
      }
    });
  }




}
