import { SnackBarService } from './../../../../utils/snack-bar.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ContainerComponentComponent } from '../../../../componentes/container-component/container-component.component';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { Router } from '@angular/router';
import { IndicadoresIqfFornecedoresService } from '../../../../services/indicadores/indicadoresIqfFornecedores.service';
import moment from 'moment';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-indicadores-iqf-fornecedores-list',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, PaginatorComponent],
  templateUrl: './indicadores-iqf-fornecedores-list.component.html',
  styleUrl: './indicadores-iqf-fornecedores-list.component.scss'
})
export class IndicadoresIqfFornecedoresListComponent implements OnInit {
  titulo: string = "IQF DE FORNECEDORES";
  nomeFormulario: string = "IQF DE FORNECEDOR CADASTRADOS";
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100];
  totalPorPagina: number = this.quantidadesPorPagina[0];
  listaDeIndicadores: any[] = [];
  anoAtual = moment().format('yyyy');
  token: any;

  constructor(
    private router: Router,
    private indicadoresIqfFornecedoresService: IndicadoresIqfFornecedoresService,
    private snackBarService:SnackBarService,
    private loginService: LoginService
  ) {

  }

  ngOnInit(): void {
    this.decodifica();
  }

  private decodifica(){
    this.token = this.loginService.decodificarToken();
    this.consultaIndicadores();
  }

  public consultaIndicadores() {
    this.indicadoresIqfFornecedoresService.consultaIndicadoresIqfFornecedoresPorAno(this.paginaAtual, this.totalPorPagina, this.anoAtual).subscribe({
      next: (res:any) => {
        this.listaDeIndicadores = res.content;
        this.totalTamanho = res.page.totalElements
      }, error: (error) => {
        this.snackBarService.tratarErro(error,"Erro ao tentar consultar indicadores");
      }, complete: () => {

      }
    });
  }

  public editarIndicador(id: number) {
    this.router.navigate([`/indicadores/fornecedores-cadastro/${id}`]);
  }

  public gerenciarIndicador(id: number) {
    this.router.navigate([`/indicadores/indicador-fornecedores-resultado/${id}/${this.anoAtual}`]);
  }

  public mudancaNaPagina(evento: any) {
    this.paginaAtual = evento.pageIndex;
    this.totalPorPagina = evento.pageSize;
    this.consultaIndicadores();
  }

}
