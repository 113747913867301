export class EngDeterminacaoKtl {
  nome!: string;
  cdProduto!: number;
  qtdePecasGancheira!: number;
  qtdeGancheirasRack!: number;
  qtdePecasRack!: number;
  receita!: string;
  parametroEstufa!: string;
  amostraInspecao!: string;
  tempoSetupDispositivo!: number;
  tempoMontagem!: number;
  tempoIsencaoAnterior!: number;
  tempoMaquina!: number;
  tempoResfriamento!: number;
  tempoDesmontagem!: number;
  tempoInspecao!: number;
  tempoIsencaoPosterior!: number;
  tempoEmbalagem!: number;
  leadTime!: number;
  qtdePecasHoraMaquina!: number;
  camadaMinima!: number;
  camadaMaxima!: number;
  epi!: string;
  observacao!: string;
  dataCadastro!: string;
  usuarioCadastro!: string;
  usuarioAlteracao!: string;
  dataAlteracao!: string;
  cdProcesso!: number;
}
