import { Routes } from "@angular/router";
import { ControlesHomeComponent } from "../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-home/controles-home.component";



export const routesControle: Routes = [
    { path: "home", component: ControlesHomeComponent },
    { path: "cadastrar-medicao-monitoramento", loadComponent: () => import("../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-cadastro/controles-cadastro.component").then(c => c.ControlesCadastroComponent) },
    { path: "cadastrar-medicao-monitoramento/:id", loadComponent: () => import("../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-cadastro/controles-cadastro.component").then(c => c.ControlesCadastroComponent) },
    { path: "lista-medicao-monitoramento", loadComponent: () => import("../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-lista/controles-medicao-monitoramento-lista.component").then(c => c.ControlesMedicaoMonitoramentoListaComponent) },
    { path: "resultado-medicao-monitoramento/:id", loadComponent: () => import("../paginas/controles/medicao-monitoramento/controles-medicao-monitoramento-resultado/controles-medicao-monitoramento-resultado.component").then(c => c.ControlesMedicaoMonitoramentoResultadoComponent) },

    { path: "home-satisfacao-cliente", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-home/satisfacao-cliente-home.component").then(s => s.SatisfacaoClienteHomeComponent) },
    { path: "cadastrar-satisfacao-cliente", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-cadastrar/satisfacao-cliente-cadastrar.component").then(s => s.SatisfacaoClienteCadastrarComponent) },
    { path: "cadastrar-satisfacao-cliente/:id", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-cadastrar/satisfacao-cliente-cadastrar.component").then(s => s.SatisfacaoClienteCadastrarComponent) },
    { path: "lista-satisfacao-cliente", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-lista/satisfacao-cliente-lista.component").then(s => s.SatisfacaoClienteListaComponent) },
    { path: "editar-satisfacao-cliente/:id", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-editar/satisfacao-cliente-editar.component").then(s => s.SatisfacaoClienteEditarComponent) },
    { path: "resultados-satisfacao-cliente/:id", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-resultado/satisfacao-cliente-resultado.component").then(s => s.SatisfacaoClienteResultadoComponent) },
    { path: "autoridade-pesquisa/:id", loadComponent: () => import("../paginas/controles/satisfacao-cliente/satisfacao-cliente-autoridade/satisfacao-cliente-autoridade.component").then(s => s.SatisfacaoClienteAutoridadeComponent) },
];
