import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import moment from 'moment';
import { Router } from '@angular/router';
import { IndicadoresIqfClienteService } from '../../../../services/indicadores/indicadoresIqfCliente.service';
import { IndicadorIqfClientes } from '../../../../model/indicadores/indicadoresIqfClientes/indicadorIqfClientes';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-indicadores-iqf-clientes-list',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, PaginatorComponent],
  templateUrl: './indicadores-iqf-clientes-list.component.html',
  styleUrl: './indicadores-iqf-clientes-list.component.scss'
})
export class IndicadoresIqfClientesListComponent {
  listaDeIndicadores:IndicadorIqfClientes[]=[];
  titulo = "<i class='fa-solid fa-chart-simple'></i> IQF DE CLIENTES";
  nomeFormulario = "IQF DE CLIENTES CADASTRADOS";
  anoAtual:string = moment().format("yyyy");
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  token: any;

  constructor(
    private indicadoresIqfClientes:IndicadoresIqfClienteService,
    private router:Router,
    private loginService: LoginService,
  ){

  }

  ngOnInit(): void {
      this.decodificarToken();
  }

  private decodificarToken(){
    this.token = this.loginService.decodificarToken();
    this.consultaIndicadores();
  }

  public consultaIndicadores(){
    this.indicadoresIqfClientes.consultaIndicadorIqfClientesPorAno(this.paginaAtual,this.totalPorPagina,this.anoAtual).subscribe({
      next:(res:any)=>{
        this.listaDeIndicadores = res.content;
        this.totalTamanho = res.page.totalElements
      },error:(error)=>{
        
      },complete:()=>{

      }
    });
  }

  public gerenciarIndicador(idIndicador:number){
    this.router.navigate([`indicadores/indicador-clientes-resultado/${idIndicador}/${this.anoAtual}`]);
  }

  public editarIndicador(idIndicador:number){
    this.router.navigate([`indicadores/clientes-cadastro/${idIndicador}`])
  }

  public mudancaNaPagina(evento:any){
    this.paginaAtual = evento.pageIndex;
    this.totalPorPagina = evento.pageSize;
    this.consultaIndicadores();
  }

}
