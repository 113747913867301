import { Component, OnInit } from '@angular/core';
import { ProdutoDetalheService } from '../../../../services/engenharia/produto/produto-detalhe.service';
import { Produto } from '../../../../model/engenharia/produto-model';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import Swiper from 'swiper';
import { CommonModule } from '@angular/common';
import { BookService } from '../../../../services/producao/book.service';

@Component({
  selector: 'app-dados-produto',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dados-produto.component.html',
  styleUrl: './dados-produto.component.scss'
})
export class DadosProdutoComponent implements OnInit {

  cdProduto: any;
  produto: Produto;

  constructor(
    private produtoService: ProdutoDetalheService,
    private snackbarService: SnackBarService,
  ) {
    this.produto = new Produto();
  }

  ngOnInit(): void {
     this.receberDadosDoProduto();
  }

  receberDadosDoProduto(){
    BookService.emissorProduto.subscribe({
      next:(res: any)=>{
        this.produto = res;
      },
      error:(e: any)=>{
        this.produto = new Produto();
      }
    });
  }

}
