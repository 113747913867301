<div class="sessaoHome">
    <div class="row">
        <div
            class=" col-12 p-sm-3 p-md-3 p-lg-2 p-xxl-3 justify-content-center align-items-center gap-1 gap-sm-2 gap-md-1 gap-xxl-3">
            <div class="col-12  d-flex justify-content-center align-items-center mb-3">
                <div id="croma" class="card  col-12 col-lg-12 col-xl-12 p-md-2 p-lg-3 p-xl-4">
                    <span class="text-conteudo">
                        SISTEMA DE GESTÃO CROMA
                        <br>
                        <span class="text-sub">

                            <p> Sistema de Gestão da Qualidade CROMA é uma abordagem inovadora e abrangente paragarantir
                                a
                                excelência operacional e a satisfação do cliente em todos os processos da organização.
                            </p>

                            <p>
                                Baseado em princípios consagrados de gestão da qualidade, o CROMA incorpora as melhores
                                práticas e metodologias
                                para a melhoria contínua, conformidade normativa e eficiência operacional...</p>
                        </span>
                        <i class="fa-solid fa-briefcase"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="row p-2 gap-2 justify-content-around">
            @if(this.isPermitido('ROLE_ENG_VIS') || isPermitido('ROLE_ADMIN')){
            <a routerLink="/engenharia/produto-detalhe" id="engenharia"
                class="card  col-12 col-sm-12 col-md-5 col-lg-3 col-xl-3 col-xxl-3">
                <span class="text-conteudo">
                    DESENVOLVIMENTO
                    <i class="fa-solid fa-gears"></i>
                </span>
            </a>
            }

            @if(isPermitido('ROLE_QUA_VIS') || isPermitido('ROLE_ADMIN')){
            <a routerLink="/qualidade/home" id="qualidade"
                class="card col-12  col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3">
                <span class="text-conteudo">
                    QUALIDADE
                    <i class="fa-solid fa-ranking-star"></i>
                </span>
            </a>
            }

            @if (isPermitido('ROLE_PCP_VIS') || isPermitido('ROLE_ADMIN')) {
            <a routerLink="/pcp/recebimento" id="pcp"
                class="card col-12  col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3">
                <span class="text-conteudo">
                    PCP
                    <i class="fa-solid fa-truck-fast"></i>
                </span>
            </a>
            }
            @if (isPermitido('ROLE_GEST_VIS') || isPermitido('ROLE_ADMIN')){
            <a routerLink="/gestao/gestao-melhoria" id="gestao"
                class="card col-12  col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3">
                <span class="text-conteudo">
                    GESTÃO
                    <i class="fa-brands fa-get-pocket"></i>
                </span>
            </a>
            }
            @if(isPermitido('ROLE_IND_VIS') || isPermitido('ROLE_ADMIN') ){
            <a routerLink="/indicadores/home" class="card col-12   col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                id="indicadores">
                <span class="text-conteudo">
                    INDICADORES
                    <i class="fa-solid fa-chart-line"></i>
                </span>
            </a>
            }

            @if(isPermitido('ROLE_CONT_VIS') || isPermitido('ROLE_ADMIN')){
            <a routerLink="/controles/home" class="card col-12   col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                id="controle">
                <span class="text-conteudo">
                    CONTROLES
                    <i class="fa-solid fa-diagram-project"></i>
                </span>
            </a>
            }

            @if(isPermitido("ROLE_ACAO_VIS") || isPermitido('ROLE_ADMIN')){
            <a routerLink="/acoes/informacao-documentada"
                class="card col-12   col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3" id="acao">
                <span class="text-conteudo">
                    AÇÕES
                    <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                </span>
            </a>
            }

            @if(isPermitido('ROLE_DISP_VIS') || isPermitido('ROLE_ADMIN')){
            <a routerLink="/dispositivo/dispositivo-lista"
                class="card col-12   col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3" id="dispositivo">
                <span class="text-conteudo">
                    DISPOSITIVOS
                    <i class="fa-solid fa-microchip"></i>
                </span>
            </a>
            }

            @if(isPermitido('ROLE_INSP_VIS') || isPermitido('ROLE_ADMIN')){
            <a routerLink="/inspecao/home" class="card col-12   col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                id="inspecao">
                <span class="text-conteudo">
                    INSPEÇÃO
                    <i class="fa-solid fa-magnifying-glass"></i>
                </span>
            </a>
            }

            @if(isPermitido('ROLE_MANUT_VIS') || isPermitido('ROLE_ADMIN')){
            <a routerLink="/manutencao/manutencao" class="card col-12   col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                id="manutencao">
                <span class="text-conteudo">
                    MANUTENÇÃO
                    <i class="fa-solid fa-screwdriver-wrench"></i>
                </span>
            </a>
            }

            @if(isPermitido('ROLE_PROD_VIS') || isPermitido('ROLE_ADMIN')){
            <a routerLink="/producao/book" class="card col-12   col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                id="instrucao">
                <span class="text-conteudo">
                    PRODUÇÃO BOOK
                    <i class="fa-brands fa-product-hunt"></i>
                </span>
            </a>
            }

            @if(isPermitido('ROLE_ADMIN')){
            <a routerLink="/administrador/usuario-cadastrados" class="card col-12   col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                id="admin">
                <span class="text-conteudo">
                    ADMINISTRADOR
                    <i class="fa-solid fa-laptop-code"></i>
                </span>
            </a>
            }
            <!-- 
            <div id="rh" class="card col-12  col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3" (click)="aviso()">
                <span class="text-conteudo">
                    RH
                    <i class="fa-solid fa-users-gear"></i>
                </span>
            </div> -->

            <!-- <div id="almoxarifado" class="card col-12  col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                (click)="aviso()">
                <span class="text-conteudo">
                    ALMOXARIFADO
                    <i class="fa-solid fa-boxes-stacked"></i>
                </span>
            </div> -->
            <!-- <div id="lab" class="card col-12  col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3" (click)="aviso()">
                <span class="text-conteudo">
                    LABORATÓRIO
                    <i class="fa-solid fa-flask-vial"></i>
                </span>
            </div> -->


            <!-- <div id="manutencao" class="card col-12  col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                (click)="aviso()">
                <span class="text-conteudo">
                    INSPEÇÃO
                    <i class="fa-solid fa-clipboard-check"></i>
                </span>
            </div> -->
            <!-- <div id="instrucao" class="card col-12  col-sm-12 col-md-5  col-lg-3 col-xl-3 col-xxl-3"
                (click)="aviso()">
                <span class="text-conteudo">
                    Instruções
                    <i class="fa-solid fa-list"></i>
                </span>
            </div> -->

        </div>
    </div>
</div>