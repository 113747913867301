
import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';
import { inject } from '@angular/core';

export const authGuardLogin: CanActivateFn = (route, state) => {
    const loginService = inject(LoginService);
    const router = inject(Router);

    if (loginService.estaLogado()) {
        if (loginService.consultarLocalstorageTokenSgq() == null) {
            // router.navigate(['/login']);
            return true;
        } else {
            router.navigate(['/home'])
            return true
        }
    }
    return true;
};