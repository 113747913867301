<app-container-component [header]="false" subtituloPage="Inserir itens no planejamento - {{planejamentoComunicacao.id}}">
    <form class="form-group row d-flex justify-content-start formulario-produto col-12"
        [formGroup]="formularioCadastroAcoes" #formularioEditarAcoes>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">O que será comunicado:</label>
            <textarea class="form-control form-control-sm" formControlName="oqueComunica"
            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
            [ngClass]="{'valid-icon': validar('oqueComunica'), 'invalid-icon': tocado('oqueComunica')}"></textarea>
            <app-mensagem-errors [control]="obterValidacao('oqueComunica')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Quando será comunicado:</label>
            <textarea class="form-control form-control-sm" formControlName="quandoComunica"
            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
            [ngClass]="{'valid-icon': validar('quandoComunica'), 'invalid-icon': tocado('quandoComunica')}"></textarea>
            <app-mensagem-errors [control]="obterValidacao('quandoComunica')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Público alvo:</label>
            <textarea class="form-control form-control-sm" formControlName="publicoAlvo"
            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
            [ngClass]="{'valid-icon': validar('publicoAlvo'), 'invalid-icon': tocado('publicoAlvo')}"></textarea>
            <app-mensagem-errors [control]="obterValidacao('publicoAlvo')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Canal de comunicação:</label>
            <textarea class="form-control form-control-sm" formControlName="canalComunicacao"
            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
            [ngClass]="{'valid-icon': validar('canalComunicacao'), 'invalid-icon': tocado('canalComunicacao')}"></textarea>
            <app-mensagem-errors [control]="obterValidacao('canalComunicacao')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Responsável pela comunicação:</label>
            <textarea class="form-control form-control-sm" formControlName="respComunicacao"
            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
            [ngClass]="{'valid-icon': validar('respComunicacao'), 'invalid-icon': tocado('respComunicacao')}"></textarea>
            <app-mensagem-errors [control]="obterValidacao('respComunicacao')"></app-mensagem-errors>
        </div>
        <div class="row justify-content-start gap-2 ms-2 mt-3 mb-3">
            @if(this.acaoPlanejamentoComunicacao && this.acaoPlanejamentoComunicacao.id!){
                <app-botao label="Editar Ação" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="editarAcoesParteInteressada()"></app-botao>
                <app-botao label="Limpar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-x-branco.png"
                status="btn-cancelar" (click)="limparFormulario()"></app-botao>
            }@else {
                <app-botao label="Adicionar Ação" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="cadastrarAcoesParteInteressada()"></app-botao>
            }
        </div>
    </form>
</app-container-component>