import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { AuditoriaInternaService } from '../../../../services/acoes/auditoria-interna/auditoria-interna.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auditoria-interna-lista',
  standalone: true,
  imports: [ContainerComponentComponent],
  templateUrl: './auditoria-interna-lista.component.html',
  styleUrl: './auditoria-interna-lista.component.scss'
})
export class AuditoriaInternaListaComponent implements OnInit {

  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  listaDeAuditorias: any[] = []
  private auditoriaInternaService = inject(AuditoriaInternaService);
  private router = inject(Router);


  ngOnInit(): void {
    this.consultaAuditoria();
  }

  public consultaAuditoria() {
    this.auditoriaInternaService.consultarAuditoriaInternaPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.listaDeAuditorias = res.content;
        this.processarElementosPagina(res.page)
      }, error: (error) => {

      }, complete: () => {

      }
    })
  }

  private processarElementosPagina(page: any) {
    this.paginaAtual = page.number;
    this.totalTamanho = page.totalElements;
  }

  public editarAuditoria(idAuditoria: number) {
    this.router.navigate([`/acoes/auditoria-interna-registrar/${idAuditoria}`])
  }
  public verApontamentos(idAuditoria: number) {
    this.router.navigate([`/acoes/auditoria-interna-apontamentos/${idAuditoria}`])

  }
  public gerenciarAuditoria(idAuditoria: number) {
    this.router.navigate([`/acoes/auditoria-interna-gerenciar/${idAuditoria}`])
  }
  public exportarAuditoria(auditoria: any) {

  }

}
