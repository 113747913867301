<form [formGroup]="formulario">
    <div class="row">
        <div class="col-12">
            <div class="separador mt-3 mb-3">
                <strong>Cadastrar permissões</strong>
            </div>
        </div>
        <div class="col-12 col-lg-3">
            <label for="" class="form-label">Permissão:</label>
            <input type="text" class="form-control form-control-sm" formControlName="authority" [ngClass]="{'is-invalid':tocado('authority'),'is-valid':validar('authority'), 'errorAnimacao is-invalid': sujoInvalido('authority'), }">
            <app-mensagem-errors [control]="obterValidacao('authority')"></app-mensagem-errors>
        </div>
        <div class="col-12 col-lg-6">
            <label for="" class="form-label">Descrição:</label>
            <input type="text" class="form-control form-control-sm" formControlName="descricao" [ngClass]="{'is-invalid':tocado('descricao'),'is-valid':validar('descricao'), 'errorAnimacao is-invalid': sujoInvalido('descricao'), }">
            <app-mensagem-errors [control]="obterValidacao('descricao')"></app-mensagem-errors>
        </div>
        <div class="col-12 col-lg-3">
            <label for="" class="form-label">Módulo:</label>
            <input type="text" class="form-control form-control-sm" formControlName="modulo" [ngClass]="{'is-invalid':tocado('modulo'),'is-valid':validar('modulo'), 'errorAnimacao is-invalid': sujoInvalido('modulo'), }">
            <app-mensagem-errors [control]="obterValidacao('modulo')"></app-mensagem-errors>
        </div>
        <div class="col-12 mt-3">
            <button class="btn btn-sm" (click)="cadastrarPermissao()">Cadastrar</button>
        </div>
    </div>
</form>

<div class="col-12">
    <div class="separador mt-5 mb-3">
        <strong>Permissoes cadastradas</strong>
    </div>
</div>

<div class="row d-flex flex-row-reverse">
    <div class="col-12 col-lg-4 ">
        <div class="input-group mb-3">
            <input type="text" class="form-control form-control-sm" (keypress)="keyEnterPress($event)" placeholder="Digite o módulo" [(ngModel)]="modulo">
            <button class="btn btn-sm btn-outline-secondary" type="button" id="button-addon2" (click)="consultarPermissoesPorModulo()">
                <i class="fa-solid fa-magnifying-glass"></i>
            </button>
        </div>
    </div>
</div>

@for (m of permissoes; track $index) {
<div class="linha mt-2 mb-5">
    <div class="row">
        <div class="col-12">
            <label for="">Módulo:</label>
            <div class="informacao">{{m.modulo}}</div>
        </div>
        <div class="col-12 mt-3">
            <div class="table">
                <table class="table table-sm">
                    <thead class="table-secondary">
                        <tr>
                            <th>Permissão</th>
                            <th>Descrição</th>
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (p of m.permissoes; track $index) {
                        <tr>
                            <td>{{p.autority}}</td>
                            <td>{{p.descricao}}</td>
                            <td>
                                <i class="fa-solid fa-pen-to-square text-success me-3" (click)="alterarPermissao(p.id)"></i>
                                <i class="fa-solid fa-trash text-danger"></i>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
}
<app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
      [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
      (paginaMudou)="onPageChange($event)"></app-paginator>