<app-container-component [header]="false" subtituloPage="Gerenciar mapeamento - {{mapeamentoRecebido.id}}">
    <form class="form-group row d-flex justify-content-start formulario-produto col-12"
        [formGroup]="formularioCadastroAcoes" #formularioEditarAcoes>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Parte Interessada:</label>
            <input type="text" formControlName="parteInteressada" class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('parteInteressada')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Descrição:</label>
            <input type="text" formControlName="descricao" class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('descricao')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Expectativas / Requisitos:</label>
            <input type="text" formControlName="requisito" class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('requisito')"></app-mensagem-errors>
        </div>
        <div class="mb-3 col-12 col-lg-6">
            <label class="form-label">Monitoramento:</label>
            <input type="text" formControlName="monitoramento" class="form-control form-control-sm">
            <app-mensagem-errors [control]="obterValidacao('monitoramento')"></app-mensagem-errors>
        </div>
        <div class="row justify-content-start gap-2 ms-2 mt-3 mb-3">
            @if(this.acaoParteInteressada && this.acaoParteInteressada.id!){
                <app-botao label="Editar Ação" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="editarAcoesParteInteressada()"></app-botao>
                <app-botao label="Limpar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-cross.png"
                status="btn-cancelar" (click)="limparFormulario()"></app-botao>
            }@else {
                <app-botao label="Adicionar Ação" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="cadastrarAcoesParteInteressada()"></app-botao>
            }
        </div>
    </form>
</app-container-component>