import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from '../../../componentes/container-component/container-component.component';
import { LoginService } from '../../../services/login/login.service';
import { Usuario } from '../../../model/usuario/usuario';
import { RedefinirSenhaComponent } from '../redefinir-senha/redefinir-senha.component';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-usuario-config',
  standalone: true,
  imports: [
    CommonModule, 
    ContainerComponentComponent, 
    RedefinirSenhaComponent,
    FormsModule
  ],
  templateUrl: './usuario-config.component.html',
  styleUrl: './usuario-config.component.scss'
})
export class UsuarioConfigComponent implements OnInit {

  usuario = new Usuario();
  controleEdicao:boolean = true;
  loginService = inject(LoginService);
  menuFixo:boolean = false;

  constructor(){

  }

  ngOnInit(): void {
    this.consultarDadosUsuario();
    this.menuFixo = JSON.parse(localStorage.getItem("menuFixado")! || 'false');
  }

  public consultarDadosUsuario(){
    this.usuario = this.loginService.decodificarToken();
  }


  public salvarStatuMenu(event:any){
   if(event.target!.checked){
    localStorage.setItem("menuFixado", "true");
   }else{
    localStorage.setItem("menuFixado", "false");
   }
  }



}
