import { Component, OnInit } from '@angular/core';
import { Produto } from '../../../../model/engenharia/produto-model';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, catchError, tap, finalize } from 'rxjs/operators';
import { BookService } from '../../../../services/producao/book.service';
import { DeterminacaoProcessoFosfatoService } from '../../../../services/engenharia/processo/eng-determinacao-processo-fosfato.service';
import { DeterminacaoProcessoKtlService } from '../../../../services/engenharia/processo/eng-determinacao-processo-ktl.service';
import { DeterminacaoProcessoPoService } from '../../../../services/engenharia/processo/eng-determinacao-processo-po.service';
import { DeterminacaoProcessoLiquidaService } from '../../../../services/engenharia/processo/eng-determinacao-processo-liquida.service';
import { CommonModule } from '@angular/common';
import { DASH } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-determinacao-processo',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './determinacao-processo.component.html',
  styleUrl: './determinacao-processo.component.scss'
})
export class DeterminacaoProcessoComponent implements OnInit {
  determinacaoProcesso1: any = [];
  determinacaoProcesso2: any = [];
  imagemNaoCadastrada = false;
  produto: Produto = new Produto();
  dados: any = {};
  tabAtiva: string = '';

  constructor(
    private determinadaFosfatoService: DeterminacaoProcessoFosfatoService,
    private determinacaoKtlService: DeterminacaoProcessoKtlService,
    private determinacaoPoService: DeterminacaoProcessoPoService,
    private determinacaoLiquidaService: DeterminacaoProcessoLiquidaService,
    private bookService: BookService,
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }

  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.produto = res;
        this.determinacaoProcesso1 = undefined;
        this.determinacaoProcesso2 = undefined;
        if (this.produto.nomeProduto) {
          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          forkJoin({
            s1: this.consultarDeterminacao(this.dados.processo1),
            s2: this.consultarDeterminacao(this.dados.processo2)
          }).subscribe(({s1, s2})=>{
            if(s1 || s2){
              this.determinacaoProcesso1 = s1[0];
              this.determinacaoProcesso2 = s2[0];
              this.definirTabAtiva();
            }
          });  
        }
      }
    });
  }

  realizarNovaConsulta( processo : string){
    this.determinacaoProcesso1 = undefined;
    this.determinacaoProcesso2 = undefined;
    if (this.produto.nomeProduto) {
      this.dados = this.bookService.verificarProcesso(this.produto.processo);
      forkJoin({
        s1: this.consultarDeterminacao(this.dados.processo1),
        s2: this.consultarDeterminacao(this.dados.processo2)
      }).subscribe(({s1, s2})=>{
        if(s1 || s2){
          this.determinacaoProcesso1 = s1[0];
          this.determinacaoProcesso2 = s2[0];
          this.definirTabAtiva();
        }
      });  
    }
  }

 private consultarDeterminacao(processo: string) {
    this.imagemNaoCadastrada = false;
    switch (processo.toUpperCase()) {
      case 'FOSFATO':
        return this.consultarDeterminacaoProcessoFosfato();
      case 'KTL':
        return this.consultarDeterminacaoProcessoKtl();
      case 'PÓ':
      case 'PO':
        return this.consultarDeterminacaoProcessoPo();
      case 'LIQUIDA':
      case 'LÍQUIDA':
        return this.consultarDeterminacaoProcessoLiquida();
      default:
        return of(null);  // Retorna um Observable nulo para processos não encontrados
    }
  }

  private consultarDeterminacaoProcessoFosfato(): Observable<any>{
    return this.determinadaFosfatoService.consultarDeterminacaoProcessoPorCdProduto(this.produto.cdProduto).pipe(
      tap((res: any) => {
         res[0];
      })
    );
  }

  private consultarDeterminacaoProcessoKtl(): Observable<any> {
    return this.determinacaoKtlService.consultarDeterminacaoProcessoPorCdProduto(this.produto.cdProduto).pipe(
      tap((res: any) => {
        res[0];
      })
    );
  }

  private consultarDeterminacaoProcessoPo(): Observable<any> {
    return this.determinacaoPoService.consultarDeterminacaoProcessoPorCdProduto(this.produto.cdProduto).pipe(
      tap((res: any) => {
        res[0];
      })
    );
  }

  private consultarDeterminacaoProcessoLiquida(): Observable<any> {
   return this.determinacaoLiquidaService.consultarDeterminacaoProcessoPorCdProduto(this.produto.cdProduto).pipe(
      tap((res: any)=>{
        res[0];
      })
    );
  }

  definirTabAtiva() {
    if (this.determinacaoProcesso1) {
      this.tabAtiva = 'processo1';
      return;
    }
    if (this.determinacaoProcesso2) {
      this.tabAtiva = 'processo2';
    }
  
  }

}
