import { DeterminacaoProcessoLiquidaService } from './../../../../../services/engenharia/processo/eng-determinacao-processo-liquida.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Processo } from '../../../../../enum/Processo';
import { BotaoComponent } from '../../../../../componentes/botao/botao.component';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from '../../../../../utils/snack-bar.service';
import { FormValidacaoService } from '../../../../../utils/formValidacao.service';
import { ValidatorsService } from '../../../../../utils/validators.service';
import { LoginService } from '../../../../../services/login/login.service';
import { Usuario } from '../../../../../model/usuario/usuario';
import moment from 'moment';
import { MensagemErrorsComponent } from "../../../../../componentes/mensagem-errors/mensagem-errors.component";
import { ContainerComponentComponent } from "../../../../../componentes/container-component/container-component.component";
import { EmisorEventoGeralService } from '../../../../../utils/emisorEventoGeral.service';

@Component({
  selector: 'app-eng-determinacao-processo-liquida',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, BotaoComponent, MensagemErrorsComponent, ContainerComponentComponent],
  templateUrl: './eng-determinacao-processo-liquida.component.html',
  styleUrl: './eng-determinacao-processo-liquida.component.scss'
})
export class EngDeterminacaoProcessoLiquidaComponent implements OnInit,OnChanges {

  formularioDeterminacaoProcessoLiquida!: FormGroup;
  processo = Processo;
  cdProduto: any;
  usuario: Usuario = new Usuario();
  @Input() formularioLiquida: any;
  @Output() processoSalvo: EventEmitter<boolean> = new EventEmitter<boolean>();
  idProcesso: any;
  constructor(
    private formBuilder: FormBuilder,
    private determinacaoProcessoLiquidaService: DeterminacaoProcessoLiquidaService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private formValidacoesServices: FormValidacaoService,
    private validatorsService: ValidatorsService,
    private loginService: LoginService,
    private emisorEventoGeralService:EmisorEventoGeralService
  ) {

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.cdProduto = res.cdProduto;
        this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
        this.iniciarFormulario();
        this.formularioDeterminacaoProcessoLiquida.patchValue(this.formularioLiquida);
      }
    });
  }

    ngOnChanges(changes: SimpleChanges): void {
      this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
      if (changes['dadosFormulario'] && !changes['dadosFormulario'].firstChange) {
        this.formularioDeterminacaoProcessoLiquida.patchValue(changes['dadosFormulario'].currentValue);
      }
    }


  public iniciarFormulario() {
    this.formularioDeterminacaoProcessoLiquida = this.formBuilder.group({
      id: [],
      cdProcesso: [this.processo.LIQUIDA],
      cdProduto: [this.cdProduto],
      nome: [],
      qtdePecasTravessa: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdeTravessaQuadro: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdePecasQuadro: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      padraoPintura: [],
      volumeTinta: [],
      volumeCatalisador: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      volumeSolvente: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      pressaoArGeral: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      pressaoArTambor: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      viscosidadeMistura: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoFlashOff: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      temperatuaCura: [],
      amostraInpecao: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoCura: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoSetupEquipamento: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoSetupDispositivo: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoMontagem: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoPreparacao: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoAplicacao: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoResfriamento: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoDesmontagem: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoInspecao: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoEmbalagem: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      leadTime: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdePecasHoraLinha: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      camadaMinima: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      camadaMaxima: ['', [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      epi: ["Óculos, sapatos de segurança, luvas e protetor auricular"],
      observacoes: [],
      observacao: [],
      dataCadastro: [moment().format("yyyy-MM-DD")],
      dataAlteracao: [moment().format("yyyy-MM-DD")],
      usuarioCadastro: [this.usuario.username],
      usuarioAleracao: [this.usuario.username]
    });
  };


  public pegarDadosUsuario() {
    this.usuario = this.loginService.decodificarToken();
  }
  public salvarProcesso() {
    if (this.formularioDeterminacaoProcessoLiquida.valid) {
      this.emisorEventoGeralService.carregando.emit(true);
      this.determinacaoProcessoLiquidaService.salvarDeterminacaoProcesso(this.formularioDeterminacaoProcessoLiquida.value).subscribe({
        next: (res) => {
          this.snackBarService.openSnackBar("Derminação do processo LIQUIDA criada", "my-snack-bar-sucess");
        }, error: () => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.openSnackBar("Erro ao tentar criar Determinação LIQUIDA", "my-snack-bar-erro");
        }, complete: () => {
          this.processoSalvo.emit(true);
          this.emisorEventoGeralService.carregando.emit(false);
        },
      })
    } else {
      this.snackBarService.openSnackBar("Há campos inválidos no formulario", "my-snack-bar-erro");
      this.formularioDeterminacaoProcessoLiquida.markAllAsTouched()
    }

  }
  public editarProcesso() {
    if (this.formularioDeterminacaoProcessoLiquida.valid) {
      this.emisorEventoGeralService.carregando.emit(true);
      this.determinacaoProcessoLiquidaService.editarDeterminacaoProcesso(this.formularioDeterminacaoProcessoLiquida.value).subscribe({
        next: (res) => {
          this.snackBarService.openSnackBar("Derminação do processo LIQUIDA criada", "my-snack-bar-sucess");
        }, error: () => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.openSnackBar("Erro ao tentar criar Determinação LIQUIDA", "my-snack-bar-erro");
        }, complete: () => {
          this.processoSalvo.emit(true);
          this.emisorEventoGeralService.carregando.emit(false);
        },
      });
    } else {
      this.snackBarService.openSnackBar("Há campos inválidos no formulario", "my-snack-bar-erro");
      this.formularioDeterminacaoProcessoLiquida.markAllAsTouched()
    }
  }


  public calcularQuantidadePorRack() {
    this.formularioDeterminacaoProcessoLiquida.get('qtdePecasQuadro')?.reset();
    let valor = this.formValidacoesServices.calcularQuantidade(this.formularioDeterminacaoProcessoLiquida.get('qtdePecasTravessa')?.value, this.formularioDeterminacaoProcessoLiquida.get('qtdeTravessaQuadro')?.value)
    // Converta os valores para inteiros, atribuindo 0 se não for um número válido
    this.formularioDeterminacaoProcessoLiquida.get('qtdePecasQuadro')?.setValue(valor);
  }


  public obterValidacao(nome: string) {
    return this.formValidacoesServices.obterValidacao(this.formularioDeterminacaoProcessoLiquida, nome)
  }

  public limparFormulario() {
    this.formularioDeterminacaoProcessoLiquida.reset();
    this.iniciarFormulario();
  }

  public autoGrow(event: any) {
    this.formValidacoesServices.autoAjuste(event)
  }

  public temPermissao(){
    return this.loginService.temPermissao(['ROLE_ENG_EDIT']);
  }

  public temPermissaoExcluir(){
    return this.loginService.temPermissao(['ROLE_ENG_ADMIN']);
  }

}
