<app-container-component [tituloPage]="'DISPOSITIVO DE ISENÇÃO'" [subtituloPage]="'Dispositivos cadastrados'">

    <div class="col-12 d-flex flex-column justify-content-end align-items-end">
        <div class="col-12 col-lg-4 p-2">
            <div class="input-group">
                <input type="text" class="form-control form-control-sm" placeholder="Consultar por nome"
                    [(ngModel)]="nomeDispositivo" (keypress)="buscar($event)">
                <button class="btn btn-sm " type="button" (click)="consultaDispositivoPorNome()"><i
                        class="fa-solid fa-magnifying-glass"></i></button>
            </div>
        </div>
    </div>

    <app-listar-dispositivos [dispositivos]="dispositivosIsencao" [caminho]="urlTipo" [paginaAtual]="paginaAtual"
        [totalPorPagina]="totalPorPagina" [totalTamanho]="totalTamanho" [quantidadesPorPagina]="quantidadesPorPagina"
        (paginaMudou)="onPageChange($event)" (excluscao)="exclusao($event)" [exibirPaginacao]="exibirPaginacao">
    </app-listar-dispositivos>

    @if(naoEncontrado){
        <div class="p-3">
            <div class=" alert alert-danger" role="alert">
                Nenhum dispositivo encontrado!
             </div>
        </div>
    }

    @if(this.loginService.temPermissao(['ROLE_DISP_ADMIN']) || this.loginService.temPermissao(['ROLE_DISP_EDIT'])){
    <div class="botao-container">
        <div class="botao" (click)="openClose()">
            @if(!mostrarBolas){
            <i class="fa-solid fa-plus"></i>
            }@else {
            <i class="fa-solid fa-minus"></i>
            }
        </div>
        <div class="bola-adicionar" [class.show]="mostrarBolas" routerLink="/dispositivo/dispositivo">
            <span>Adicionar Dispositivo Pintura</span>
        </div>
        <div class="bola-adicionar2" [class.show]="mostrarBolas" routerLink="/dispositivo/dispositivo-isencao">
            <span>Adicionar Dispositivo isenção</span>
        </div>
    </div>
    }
</app-container-component>