<section *ngIf="!autorizacao" class="error-page">
    <div class="container">
        <div class="row justify-content-center align-items-center min-vh-100 text-center">
            <div class="col-md-6">
                <div class="card shadow p-4">
                    <div class="text-center mb-4">
                        <h2 class="error-title">Acesso Negado!</h2>
                        <p class="text-muted">Você não está autorizado a realizar esta operação!</p>
                    </div>
                    <button class="btn btn-primary" (click)="voltar()">Voltar</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="password-reset" *ngIf="autorizacao">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-5">
                <div class="card shadow p-4">
                    <div class="text-center mb-4">
                        <img src="assets/croma.png" alt="Logo da Empresa" class="logo mb-3">
                        <h2 class="welcome-text">Bem-vindo!</h2>
                        <strong class="mb-2">{{email}}</strong>
                        <p class="text-muted mt-2">Por favor, redefina sua senha abaixo.</p>
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Nova Senha</label>
                        <input type="password" class="form-control"
                            [ngClass]="{'form-control-erro' : erro , 'form-control-sucesso' : sucesso}" id="password"
                            placeholder="Digite sua nova senha" required [(ngModel)]="senha">
                    </div>
                    <div class="mb-3">
                        <label for="confirm-password" class="form-label">Confirme a Nova Senha</label>
                        <input type="password" class="form-control"
                            [ngClass]="{'form-control-erro' : erro , 'form-control-sucesso' : sucesso}"
                            id="confirm-password" placeholder="Confirme sua nova senha" required
                            [(ngModel)]="confirmaSenha">
                        <div class="invalid-feedback" [ngClass]="{'d-block': erro === true}">
                            {{mensagem}}
                        </div>

                        <div class="valid-feedback text-center" [ngClass]="{'d-block': sucesso === true}">
                            Senha alterada com sucesso!
                        </div>

                        <div [ngClass]="{'d-block': sucesso, 'd-none': !sucesso}"
                            class="d-flex justify-content-center align-items-center mt-5">
                            <div class="spinner-border text-success me-3" role="status"></div>
                            <span>Redirecionando, aguarde!</span>
                        </div>
                    </div>

                    <div class="d-grid">
                        <button type="submit" class="btn btn-primary btn-block" (click)="redefinirSenha()">Redefinir
                            Senha</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>