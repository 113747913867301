import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { PlanejamentoComunicacao } from '../../../../model/acoes/planejamento-comunicacao/PlanejamentoComunicacao';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { PlanejamentoComunicacaoService } from '../../../../services/acoes/planejamento-comunicacao/planejamento-comunicacao.service';

@Component({
  selector: 'app-planejamento-comunicacao-listar',
  standalone: true,
  imports: [ContainerComponentComponent, PaginatorComponent, RouterLink, CommonModule, FormsModule],
  templateUrl: './planejamento-comunicacao-listar.component.html',
  styleUrl: './planejamento-comunicacao-listar.component.scss'
})
export class PlanejamentoComunicacaoListarComponent implements OnInit {

  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  valorPesquisado: string = "";
  listaDePlanejamentoComunicacao: PlanejamentoComunicacao[] = [];

  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private planejamentoComunicacaoService = inject(PlanejamentoComunicacaoService);


  ngOnInit(): void {
    this.buscarPlanejamento();
  }

  public buscarPlanejamento(event?: any) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.planejamentoComunicacaoService.consultaPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.listaDePlanejamentoComunicacao = res.content;
        console.log(res.content)
        this.processarElementosPagina(res.page);
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  private processarElementosPagina(page: any) {
    this.paginaAtual = page.number;
    this.totalTamanho = page.totalElements;
  }



  public visualizarPlanejamentoComunicacao(idPlanejamento: number) {

  }
  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.buscarPlanejamento(event); // Chama o método buscar se a tecla Enter for pressionada
    }
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.buscarPlanejamento();
  }
}
