import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Certificado } from '../../model/certificado/certificado';

@Injectable({
  providedIn: 'root'
})
export class CertificadoService {

  private url = `${environment.url}/certificado`;
  constructor(
    private http: HttpClient
  ) {

  }

  public salvarCertificado(formData:FormData,key:string):Observable<Certificado>{
    return this.http.post<Certificado>(`${this.url}/upload-file/${key}`,formData)
  }

  public consultarCertificado():Observable<Certificado>{
    return this.http.get<Certificado>(`${this.url}`)
  }

}
