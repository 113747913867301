export class EngProcessoUpdateDto {
    id?: number;
    nomeArquivo?: string;
    cdProcesso!: string;
    cdProduto!: number;
    descricao!: string;
    path?: string;
    dataCadastro?: string;
    sequencia!: number;


    constructor(
        id: number = 0,
        nomeArquivo: string = '',
        cdProcesso: string = '',
        cdProduto: number = 0,
        descricao: string = '',
        path: string = '',
        dataCadastro: string = '',
        sequencia: number = 0
    ) {
        this.id = id;
        this.nomeArquivo = nomeArquivo;
        this.cdProcesso = cdProcesso;
        this.cdProduto = cdProduto;
        this.descricao = descricao;
        this.path = path;
        this.dataCadastro = dataCadastro;
        this.sequencia = sequencia;
    }
}