import { ChangeDetectorRef, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PadraoMontagemService } from '../../../../services/engenharia/processo/padrao-montagem.service';
import { Produto } from '../../../../model/engenharia/produto-model';
import { BookService } from '../../../../services/producao/book.service';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { CommonModule } from '@angular/common';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";

@Component({
  selector: 'app-padrao-montagem',
  standalone: true,
  imports: [
    CommonModule,
    SwiperComponentComponent
  ],
  templateUrl: './padrao-montagem.component.html',
  styleUrl: './padrao-montagem.component.scss'
})
export class PadraoMontagemComponent implements OnInit {

  produto: Produto = new Produto();
  dados: any;
  tabAtiva: string = '';
  imagemNaoCadastrada1 = false;
  imagemNaoCadastrada2 = false;
  padraoMontagemProcesso1: any[] = [];
  padraoMontagemProcesso2: any[] = [];
  @ViewChild("swiperPadraoMontagemProcesso1") swiperPadraoMontagemProcesso1!: ElementRef;
  @ViewChild("swiperPadraoMontagemProcesso2") swiperPadraoMontagemProcesso2!: ElementRef;
  swiper!: Swiper;

  constructor(
    private padraoMontagemService: PadraoMontagemService,
    private bookService: BookService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }

  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.produto = new Produto();
        this.padraoMontagemProcesso1 = [];
        this.padraoMontagemProcesso2 = [];
        this.tabAtiva = '';
        this.produto = res;
        if (this.produto.nomeProduto) {
          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          this.consultarPadraoMontagemProcesso1Inicial(this.dados.numeroProcesso1);

        }
      },
      error: (e: any) => {

      }
    });
  }


  consultarPadraoMontagemProcesso1Inicial(processo: number) {
    this.imagemNaoCadastrada1 = false;
    this.padraoMontagemProcesso1 = [];

    this.padraoMontagemService.consultarPadraoMontagem(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoMontagemProcesso1 = res;
        console.log(res)
      }, error: (error) => {
        this.imagemNaoCadastrada1 = true;
        console.error('Erro ao consultar padrão de montagem Processo 1', error);
        this.consultarPadraoMontagemProcesso2Inicial(this.dados.numeroProcesso2);
        this.iniciarImagemCarregada();
      }, complete: () => {
        this.consultarPadraoMontagemProcesso2Inicial(this.dados.numeroProcesso2);
        this.iniciarImagemCarregada();
      }
    });
  }

  consultarPadraoMontagemProcesso2Inicial(processo: number) {
    this.imagemNaoCadastrada2 = false;
    this.padraoMontagemProcesso2 = [];

    this.padraoMontagemService.consultarPadraoMontagem(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoMontagemProcesso2 = res;
        console.log(res)
      }, error: (error) => {
        this.imagemNaoCadastrada2 = true;
        this.definirTabAtiva();
        this.iniciarImagemCarregada2();
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2();
      }
    });
  }


  public consultarPadraoMontagemProcesso1Click(processo: number) {
    this.imagemNaoCadastrada1 = false;
    this.padraoMontagemProcesso1 = [];
    this.padraoMontagemService.consultarPadraoMontagem(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoMontagemProcesso1 = res;
      }, error: (error) => {
        this.imagemNaoCadastrada1 = true;
        console.error('Erro ao consultar padrão de montagem Processo 1 (Click)', error);
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada();
      }
    });
  }


  consultarPadraoMontagemProcesso2Click(processo: number) {
    this.imagemNaoCadastrada2 = false;
    this.padraoMontagemProcesso2 = [];
    this.padraoMontagemService.consultarPadraoMontagem(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoMontagemProcesso2 = res;
      }, error: (error) => {
        this.imagemNaoCadastrada2 = true;
        console.error('Erro ao consultar padrão de montagem Processo 2 (Click)', error);
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada();
      }
    });
  }

  private definirTabAtiva() {
    if (this.padraoMontagemProcesso1.length > 0) {
      this.tabAtiva = 'processo1';
    } else if (this.padraoMontagemProcesso2.length > 0) {
      this.tabAtiva = 'processo2';
    }
  }

  private iniciarImagemCarregada() {
    this.iniciarImagemCarregadaParaArray(this.padraoMontagemProcesso1);
  }

  private iniciarImagemCarregada2() {
    this.iniciarImagemCarregadaParaArray(this.padraoMontagemProcesso2);
  }

  private iniciarImagemCarregadaParaArray(array: any[]) {
    array.forEach((e: any) => {
      if (!e.imagemCarregada) {
        e.imagemCarregada = false;
      }
    });
  }
}
