<app-container-component tituloPage="Análise crítica" [desativarSubTitulo]="false" [removerElevacao]="true">
    <form class="form-group row d-flex justify-content-start formulario-produto col-12"
        [formGroup]="formularioCadastro">
        <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start" dynamicHeight #tabGroup
            [(selectedIndex)]="indiceMesAtual" (selectedTabChange)="checkTab($event.tab.textLabel)" [class.tab-exclusao]="isExclusaoAnaliseCritica">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="me-1">Registro de Análise Crítica da Direção</span>
                    @if(!this.formularioCadastro.valid && this.formularioCadastro.touched){
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    }@else{
                    <!-- <i class="fa-solid fa-circle-check"></i> -->
                    }
                </ng-template>
                <app-container-component [header]="false" [desativarSubTitulo]="false">
                    <div class="mb-3 col-12 col-lg-12">
                        <label class="form-label">Identificação:</label>
                        <div class="position-relative">
                            <input type="text" formControlName="identificacao" class="form-control form-control-sm"
                                [ngClass]="{'errorAnimacao':tocado('identificacao'),'validoAnimacao':validar('identificacao')}">
                            <span class="input-icon"
                                [ngClass]="{'valid-icon': validar('identificacao'), 'invalid-icon': tocado('identificacao')}">
                                <i class="fas fa-check-circle" *ngIf="validar('identificacao')"></i>
                                <i class="fas fa-exclamation-circle" *ngIf="tocado('identificacao')"></i>
                            </span>
                        </div>
                        <app-mensagem-errors [control]="obterValidacao('identificacao')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Participantes:</label>
                        <div class="position-relative">
                            <textarea class="form-control form-control-sm" formControlName="participantes"
                                (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                                [ngClass]="{'errorAnimacao':tocado('participantes'),'validoAnimacao':validar('participantes')}"></textarea>
                            <span class="input-icon"
                                [ngClass]="{'valid-icon': validar('participantes'), 'invalid-icon': tocado('participantes')}">
                                <i class="fas fa-check-circle" *ngIf="validar('participantes')"></i>
                                <i class="fas fa-exclamation-circle" *ngIf="tocado('participantes')"></i>
                            </span>
                        </div>
                        <app-mensagem-errors [control]="obterValidacao('participantes')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Pauta geral:</label>
                        <div class="position-relative">
                            <textarea class="form-control form-control-sm" formControlName="pautaGeral"
                                (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                                [ngClass]="{'errorAnimacao':tocado('pautaGeral'),'validoAnimacao':validar('pautaGeral')}"></textarea>
                            <span class="input-icon"
                                [ngClass]="{'valid-icon': validar('participantes'), 'invalid-icon': tocado('participantes')}">
                                <i class="fas fa-check-circle" *ngIf="validar('participantes')"></i>
                                <i class="fas fa-exclamation-circle" *ngIf="tocado('participantes')"></i>
                            </span>
                        </div>
                        <app-mensagem-errors [control]="obterValidacao('pautaGeral')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                        <label for="text" class="form-label">Data do desenho:</label>
                        <input type="date" class="form-control form-control-sm"
                            [ngClass]="{'errorAnimacao':tocado('pautaGeral'),'validoAnimacao':validar('pautaGeral')}"
                            formControlName="dataRealizacao">
                    </div>
                    @if(this.idAnaliseCritica){
                    <div class="row">
                        <app-botao label="Editar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-lápis.png"
                            status="btn-salvar" (click)="editarAnaliseCritica()"></app-botao>
                    </div>
                    }@else{
                    <div class="row">
                        <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                            status="btn-salvar" (click)="cadastrarAnaliseCritica()"></app-botao>
                    </div>
                    }
                </app-container-component>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="me-1">Entradas</span>
                </ng-template>
                <app-container-component [header]="false" [desativarSubTitulo]="false">
                    <div class="mb-3 col-12">
                        <label class="form-label">Situação de ações provenientes de análises críticas anteriores pela
                            direção:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_a"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_a'),'validoAnimacao':validar('ent_932_a')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_a')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Mudanças em questões externas e internas que sejam pertinentes para o
                            sistema de
                            gestão da qualidade</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_b"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_b'),'validoAnimacao':validar('ent_932_b')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_b')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Satisfação do cliente e retroalimentação de partes interessadas
                            pertinentes:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_c_1"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_c_1'),'validoAnimacao':validar('ent_932_c_1')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_c_1')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Extensão na qual os objetivos da qualidade tenham sido
                            alcançados:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_c_2"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_c_2'),'validoAnimacao':validar('ent_932_c_2')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_c_2')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Desempenho de processo e conformidade de produtos e serviços:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_c_3"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_c_3'),'validoAnimacao':validar('ent_932_c_3')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_c_3')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Não conformidades e ações corretivas:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_c_4"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_c_4'),'validoAnimacao':validar('ent_932_c_4')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_c_4')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Resultados de monitoramento e medição:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_c_5"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_c_5'),'validoAnimacao':validar('ent_932_c_5')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_c_5')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Resultados de auditoria:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_c_6"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_c_6'),'validoAnimacao':validar('ent_932_c_6')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_c_6')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Desempenho de provedores externos:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_c_7"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_c_7'),'validoAnimacao':validar('ent_932_c_7')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_c_7')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Adequação de recursos:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_d"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_d'),'validoAnimacao':validar('ent_932_d')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_d')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Eficácia de ações tomadas para abordar riscos e oportunidades:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_e"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_e'),'validoAnimacao':validar('ent_932_e')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_e')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Oportunidades para melhoria:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_932_f"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_932_f'),'validoAnimacao':validar('ent_932_f')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_932_f')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Custo de má qualidade (custo de não conformidades internas e
                            externas):</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_a"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_a'),'validoAnimacao':validar('ent_9321_a')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_a')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Medições da eficácia do processo:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_b"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_b'),'validoAnimacao':validar('ent_9321_b')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_b')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Medições da eficiência do processo para processos de realização do
                            produto:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_c"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_c'),'validoAnimacao':validar('ent_9321_c')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_c')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Conformidade do produto:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_d"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_d'),'validoAnimacao':validar('ent_9321_d')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_d')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Avaliações da viabilidade de manufatura feitas para mudanças de
                            operações
                            existentes e para novas instalações ou novos produtos:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_r"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_r'),'validoAnimacao':validar('ent_9321_r')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_r')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Satisfação do cliente:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_f"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_f'),'validoAnimacao':validar('ent_9321_f')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_f')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Análise crítica do desempenho em relação aos objetivos de
                            manutenção:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_g"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_g'),'validoAnimacao':validar('ent_9321_g')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_g')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Desempenho da garantia:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_h"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_h'),'validoAnimacao':validar('ent_9321_h')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_h')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Análise crítica dos indicadores do cliente:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_i"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_i'),'validoAnimacao':validar('ent_9321_i')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_i')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Identificação de falhas de campo potenciais identificadas através da
                            análise de
                            risco:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_ak"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_ak'),'validoAnimacao':validar('ent_9321_ak')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_ak')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Falhas de campo reais e seu impacto sobre a segurança ou o
                            ambiente:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_k"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_k'),'validoAnimacao':validar('ent_9321_k')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_k')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Resultados resumidos das medições em estágios especificados durante o
                            projeto e
                            desenvolvimento:</label>
                        <textarea class="form-control form-control-sm" formControlName="ent_9321_l"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('ent_9321_l'),'validoAnimacao':validar('ent_9321_l')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('ent_9321_l')"></app-mensagem-errors>
                    </div>
                    @if(this.idAnaliseCritica){
                    <div class="row">
                        <app-botao label="Editar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-lápis.png"
                            status="btn-salvar" (click)="editarAnaliseCritica()"></app-botao>
                    </div>

                    }

                </app-container-component>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="me-1">Saídas</span>
                </ng-template>
                <app-container-component [header]="false" [desativarSubTitulo]="false">
                    <div class="mb-3 col-12">
                        <label class="form-label">Oportunidades para melhoria:</label>
                        <textarea class="form-control form-control-sm" formControlName="sai_933_a"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('sai_933_a'),'validoAnimacao':validar('sai_933_a')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('sai_933_a')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Necessidade de mudanças no sistema de gestão da qualidade:</label>
                        <textarea class="form-control form-control-sm" formControlName="sai_933_b"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('sai_933_b'),'validoAnimacao':validar('sai_933_b')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('sai_933_b')"></app-mensagem-errors>
                    </div>
                    <div class="mb-3 col-12">
                        <label class="form-label">Necessidade de recursos necessários:</label>
                        <textarea class="form-control form-control-sm" formControlName="sai_933_c"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('sai_933_c'),'validoAnimacao':validar('sai_933_c')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('sai_933_c')"></app-mensagem-errors>
                    </div>
                    @if(this.idAnaliseCritica){
                    <div class="row">
                        <app-botao label="Editar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-lápis.png"
                            status="btn-salvar" (click)="editarAnaliseCritica()"></app-botao>
                    </div>
                    }
                </app-container-component>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="me-1">Observações Gerais</span>
                </ng-template>
                <app-container-component [header]="false" [desativarSubTitulo]="false">
                    <div class="mb-3 col-12">
                        <label class="form-label">Observações:</label>
                        <textarea class="form-control form-control-sm" formControlName="obs"
                            (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                            [ngClass]="{'errorAnimacao':tocado('obs'),'validoAnimacao':validar('obs')}"></textarea>
                        <app-mensagem-errors [control]="obterValidacao('obs')"></app-mensagem-errors>
                    </div>
                </app-container-component>
                <div class="row justify-content-start gap-2 ms-2 mt-3 mb-3">
                    @if(this.idAnaliseCritica){
                    <div class="row">
                        <app-botao label="Editar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-lápis.png"
                            status="btn-salvar" (click)="editarAnaliseCritica()"></app-botao>
                    </div>
                    }@else{
                    <div class="row">
                        <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                            status="btn-salvar" (click)="cadastrarAnaliseCritica()"></app-botao>
                    </div>
                    }
                </div>
            </mat-tab>
            @if(this.formularioCadastro.controls['id'].value){
                <mat-tab  label="Exclusão da análise crítica" [ngClass]="{'exclusao-analise-critica': isExclusaoAnaliseCritica}">
                    <app-container-component [header]="false" [desativarSubTitulo]="false">
                        <div class="row justify-content-center">
                            <button class="btn-delete col-2" (click)="deletarAnaliseCritica(this.formularioCadastro.value)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6m5 4v6m4-6v6M10 2l1-1h2l1 1">
                                    </path>
                                </svg>
                                Excluir Análise crítica
                            </button>
                        </div>
                    </app-container-component>
                </mat-tab>
            }
        </mat-tab-group>
    </form>
</app-container-component>