import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import AcoesPartesInteressadas from '../../../model/acoes/partes-interessadas/AcoesPartesInteressadas';

@Injectable({
  providedIn: 'root'
})
export class PartesInteressadasService {

  private urlApi = environment.url + "/acoes/partes-interessadas"
  emitirEdicao: EventEmitter<AcoesPartesInteressadas> = new EventEmitter<AcoesPartesInteressadas>();

  constructor(
    private http: HttpClient
  ) { }

  public cadastrarMapeamento(mapeamento:any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}`,mapeamento);
  }
  public editarMapeamento(mapeamento:any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}`,mapeamento);
  }
  

  public cadastrarAcoesPartesInteressadas(idMapeamento:number,acao:AcoesPartesInteressadas): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/itens/${idMapeamento}`,acao);
  }
  public editarAcoesPartesInteressadas(idMapeamento:number,acao:AcoesPartesInteressadas): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/itens/${idMapeamento}`,acao);
  }
  public deletarAcoesPartesInteressadas(idMapeamento:number): Observable<any> {
    return this.http.delete<any>(`${this.urlApi}/itens/${idMapeamento}`);
  }
  public consultarPorId(idMapeamento:any): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${idMapeamento}`);
  }

  public consultaPaginada(paginaAtaul: number, quantidadePorPagina: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${paginaAtaul}/${quantidadePorPagina}`);
  }

}
