import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContainerComponentComponent } from "../../container-component/container-component.component";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-view-jodit',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent],
  templateUrl: './modal-view-jodit.component.html',
  styleUrl: './modal-view-jodit.component.scss'
})
export class ModalViewJoditComponent implements OnInit {


  conteudoSeguro: SafeHtml = ""

  constructor(
    private dialogRef: MatDialogRef<ModalViewJoditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = "",
    private sanitizer: DomSanitizer
  ) {

  }




  ngOnInit(): void {
    this.iniciarValores();
  }

  public iniciarValores() {
    if (this.data) {
      this.conteudoSeguro = this.sanitizer.bypassSecurityTrustHtml(this.data.changingThisBreaksApplicationSecurity);
    } else {
      this.conteudoSeguro = "Monte seu Conteúdo";
    }
  }






}
