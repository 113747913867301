
import { ChangeDetectorRef, Component, EventEmitter, inject, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
// import { RotaDispositivosService } from '../../../services/rotas-services/rota-dispositivos.service';
// import { DispositivoService } from '../../../services/dispositivos/dispositivo.service';
import { Dispositivo } from '../../../model/dispositivos/dispostivo';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { LoginService } from '../../../services/login/login.service';
import { SnackBarService } from '../../../utils/snack-bar.service';

@Component({
  selector: 'app-menu-dispositivo',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink, RouterLinkActive, MatSidenavModule, RouterOutlet],
  templateUrl: './menu-dispositivo.component.html',
  styleUrl: './menu-dispositivo.component.scss'
})
export class MenuDispositivoComponent implements OnInit {

  listaRotasDispositivo: any[] = [];
  dispositivo: Dispositivo = new Dispositivo();
  open: boolean = true;
  mostrarMenu = false;
  menuFixo = true;
  menusVisiveis: { [key: string]: boolean } = {};
  @ViewChild('drawer') drawer!: MatDrawer;
  isMobile = window.innerWidth < 1000;
  cdr = inject(ChangeDetectorRef);
  snackBarService = inject(SnackBarService)
  loginService = inject(LoginService);
  
  constructor(
    // private rotaDispositivoService: RotaDispositivosService,
  ) {

  }






  ngAfterViewInit(): void {
    this.restaurarEstadoMenu();
    this.verificarSeEhMobile();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.restaurarEstadoMenu();
    // this.listaRotasDispositivo = this.rotaDispositivoService.getMenuItensDispostivos();
    
  }


  private restaurarEstadoMenu() {
    this.menuFixo = JSON.parse(sessionStorage.getItem("menuFixado")! || 'false');
    if (this.drawer) {
      if (this.menuFixo) {
        this.drawer.open();
        this.open = false;
      }
    }

  }

  public verificarSeEhMobile() {
    this.isMobile = window.innerWidth <= 1000;
    if (this.isMobile) {
      sessionStorage.setItem("menuFixado", "false");
      this.open = true;
      this.menuFixo = false;
    }
  }


 public mudarIcone() {
    if (!this.menuFixo) {
      this.open = !this.open
    }
  }

  abrirMenu(menu: string) {
    // Alterna o estado do menu clicado
    // if (this.menusVisiveis[menu]) {
    //   this.menusVisiveis[menu] = false;
    // } else {
    //   // Fecha todos os menus
    //   Object.keys(this.menusVisiveis).forEach(key => {
    //     this.menusVisiveis[key] = false;
    //   });
    //   // Abre o menu clicado
    //   this.menusVisiveis[menu] = true;
    // }
    this.menusVisiveis[menu] = !this.menusVisiveis[menu];
  }

  fixarMenu(): void {
    this.menuFixo = !this.menuFixo; // Alterna o estado do menu fixo
    sessionStorage.setItem("menuFixado", this.menuFixo.toString());
  }

  clicarMenu(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    }
  }

  clicarFechar(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    } else {
      this.snackBarService.openSnackBar("Para recolher o menu você precisa clicar no icone desfixar menu", "my-snack-bar-warning")
    }
  }

  
  public getMenuItensDispostivos(){
    return  [
      // { label: 'DISPOSITIVOS', link: ['dispositivo'] },
      { label: 'DISPOSITIVOS DE PINTURA', link: ['dispositivo-lista'] },
      // { label: 'DISPOSITIVOS ISENCAO', link: ['dispositivo-isencao'] },
      { label: 'DISPOSITIVOS ISENÇÃO', link: ['dispositivo-isencao-lista'] },
    ];
  }





}
