<mat-drawer-container class="example-container h-100">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <div class="row mb-1 mt-1 ms-1">
            <div class="d-none d-lg-flex col-1 p-0">
                @if(menuFixo){
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Desfixar menu"></i></div>
                }@else {
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack-slash" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Fixar menu"></i></div>
                }
            </div>
            <div class="col-10  col-md-11 d-flex justify-content-start justify-content-lg-center align-items-center p-0">
                <a class="logo d-flex justify-content-center align-items-center gap-2"> <i class="fa-solid fa-hammer"></i>  Manutenção</a>
            </div>
            <div class="col-2  z-1 d-flex align-items-center  justify-content-end" (click)="clicarMenu(drawer)"
                (click)="mudarIcone()">
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div class="menu-content">
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['manutencao']}" (click)="abrirMenu('manutencao')">
                    <span class="font-titulo"><i class="fa-solid fa-hammer"></i> <span class="ms-2">Manutenção</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['manutencao']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['manutencao']}">
                    @for (link of getMenuManutencao(); track $index) {
                    <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                        (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                        <a class="font-subMenu">{{ link.label }}</a>
                    </li>
                    }
                </ul>
            </ul>

        </div>
    </mat-drawer>
    <div class="example-sidenav-content flex-column justify-content-start align-items-start ">
        <a class="btn-menu" (click)="clicarFechar(drawer)" (click)="mudarIcone()">
            <i *ngIf="!open" class="fa-solid fa-angles-left"></i>
            <i *ngIf="open" class="fa-solid fa-angles-right"></i>
        </a>
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>