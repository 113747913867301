<app-container-component tituloPage="Lista de Auditorias" subtituloPage="Auditorias cadastradas">
    <div class="actions-container">
        @if(listaDeAuditorias.length > 0){
        @for(auditoria of listaDeAuditorias; track $index){
        <div class="auditoria-card">
            <div class="auditoria-body" (click)="editarAuditoria(auditoria.id)" title="clique para editar a auditoria">
                <div class="auditoria-image">
                    <i class="fas fa-clipboard-check fa-3x auditoria-icon"></i>
                </div>
                <div class="auditoria-info">
                    <div class="auditoria-header">
                        <span class="auditoria-number">#{{auditoria.id}}</span>
                        <strong>{{auditoria.identificauditoria}}</strong>
                    </div>
                    <div class="auditoria-content">
                        <p><strong>Norma Auditada:</strong> {{auditoria.normaAuditada}}</p>
                        <p><strong>Data:</strong> {{auditoria.dataCadastro}}</p>
                        <p><strong>Auditor Líder:</strong> {{auditoria.auditorLider}}</p>
                    </div>
                </div>
            </div>
            <div class="auditoria-footer">
                <button class="btn apontamentos" (click)="verApontamentos(auditoria.id)">
                    <i class="fas fa-list"></i> Apontamentos
                </button>
                <button class="btn manage" (click)="gerenciarAuditoria(auditoria.id)">
                    <i class="fas fa-cogs"></i> Gerenciar
                </button>
                <button class="btn export" (click)="exportarAuditoria(auditoria)">
                    <i class="fas fa-file-export"></i> Exportar
                </button>
            </div>
        </div>
        }
        }@else {
        <span class="no-actions">Não há auditorias cadastradas</span>
        }
    </div>

</app-container-component>