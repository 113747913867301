<mat-drawer-container class="example-container h-100">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <div class="row mb-1 mt-1 ms-1">
            <div class="d-none d-lg-flex col-1 p-0">
                @if(menuFixo){
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Desfixar menu"></i></div>
                }@else {
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack-slash" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Fixar menu"></i></div>
                }
            </div>
            <div
                class="col-10  col-md-11 d-flex justify-content-start justify-content-lg-center align-items-center p-0">
                <a class="logo d-flex justify-content-center align-items-center gap-2"> <i
                        class="fa-solid fa-layer-group"></i> AÇÕES</a>
            </div>
            <div class="col-2  z-1 d-flex align-items-center  justify-content-end" (click)="clicarMenu(drawer)"
                (click)="mudarIcone()">
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div class="menu-content">
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['informacaoDocumentada']}"
                    (click)="abrirMenu('informacaoDocumentada')">
                    <span class="font-titulo"><i class="fa-solid fa-chart-simple"></i><span class="ms-2">Informação
                            documentada</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['informacaoDocumentada']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['informacaoDocumentada']}">
                    @for (link of getMenuItensInformacaoDocumentada(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }

                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['planoDeRisco']}" (click)="abrirMenu('planoDeRisco')">
                    <span class="font-titulo"><i class="fa-solid fa-bars-progress"></i> <span class="ms-2">Plano de
                            risco</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['planoDeRisco']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['planoDeRisco']}">
                    @for (link of getMenuItensPlanoDeRisco(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['partesInteressadas']}" (click)="abrirMenu('partesInteressadas')">
                    <span class="font-titulo"><i class="fa-brands fa-pinterest-p"></i> <span class="ms-2">Partes interessadas</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['partesInteressadas']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['partesInteressadas']}">
                    @for (link of getMenuItensPartesInteressadas(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['planejamentoComunicacao']}" (click)="abrirMenu('planejamentoComunicacao')">
                    <span class="font-titulo"><i class="fa-brands fa-pinterest-p"></i> <span class="ms-2">Planejamento de Comunicação</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['planejamentoComunicacao']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['planejamentoComunicacao']}">
                    @for (link of getMenuItensPlanejamentoComunicacao(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['auditoriaInterna']}" (click)="abrirMenu('auditoriaInterna')">
                    <span class="font-titulo"><i class="fa-solid fa-list-check"></i><span class="ms-2">Auditoria interna</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['auditoriaInterna']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['auditoriaInterna']}">
                    @for (link of getMenuItensAuditoriaInterna(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['AnaliseCritica']}" (click)="abrirMenu('AnaliseCritica')">
                    <span class="font-titulo"><i class="fa-solid fa-magnifying-glass-chart"></i> <span class="ms-2">Análise Crítica</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['AnaliseCritica']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['AnaliseCritica']}">
                    @for (link of getMenuItensAnaliseCritica(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" routerLinkActive="active-solo"
                            (click)="clicarMenu(drawer)" (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
        </div>
    </mat-drawer>
    <div class="example-sidenav-content flex-column justify-content-start align-items-start ">
        <a class="btn-menu" (click)="clicarFechar(drawer)" (click)="mudarIcone()">
            <i *ngIf="!open" class="fa-solid fa-angles-left"></i>
            <i *ngIf="open" class="fa-solid fa-angles-right"></i>
        </a>
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>