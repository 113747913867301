@if(listaDeCriteriosDeAutoInspecao.length > 0){
<app-container-component [header]="false" subtituloPage="Imagens cadastradas">
    <div class="col-12 p-0">
        <div #swiperProduto class="swiper mySwiper p-0">
            <div class="swiper-wrapper mb-2">
                @for(imagem of listaDeCriteriosDeAutoInspecao;track $index){
                    <div class="swiper-slide d-flex flex-column gap-2">
                        <img class="col-12  col-md-6 col-lg-3" [src]="imagem.path" alt="">
                        <div class="sessao-descricao text-start" *ngIf="imagem.descricao">
                            @if(editando && this.imagemSelecionada == imagem.id){
                            <i class="fa-solid fa-circle-xmark mb-1" matTooltipPosition="after" matTooltipHideDelay="200"
                                matTooltip="Fechar" (click)="cancelarEdicao()"></i>
                            <div class="form-floating">
                                <textarea class="form-control" id="floatingTextarea" (focus)="autoAjuste($event)"
                                    (mousemove)="autoAjuste($event)"
                                    [(ngModel)]="descricaoImagem">{{imagem?.descricao}}</textarea>
                                <label for="floatingTextarea">Descrição</label>
                            </div>
                            <i class="fa-solid fa-floppy-disk text-end mt-1" matTooltipPosition="after"
                                matTooltipHideDelay="200" matTooltip="Atualizar" (click)="concluirEdicaoDeDescricao()"></i>
                            }@else {
                            <span><strong>Descrição</strong>: {{imagem?.descricao}}</span>
                            <i class="fa-solid fa-pen-to-square" matTooltipPosition="after" matTooltipHideDelay="200"
                                matTooltip="Edita" (click)="editarDescricaoImagem(imagem.id,imagem.descricao)"></i>
                            }
                        </div>
                    </div>
                }
            </div>
            <div class="swiper-pagination d-flex justify-content-center my"></div>
        </div>
        <div class="col-12 d-flex justify-content-center mb-3 mt-3">
            <button class="btn align-items-center "
                (click)="ativarVisualizarSequencia = !ativarVisualizarSequencia">Visualizar sequência de auto
                inspeção</button>
        </div>
        @if(ativarVisualizarSequencia){
        <div class="col-12">
            <div cdkDropList cdkDropListOrientation="mixed" class="example-list" (cdkDropListDropped)="drop($event)">
                @for (item of listaDeCriteriosDeAutoInspecao; track item) {
                <div class="example-box" cdkDrag>
                    <span class="sessao-sequencia mb-1">Sequencia <strong> {{item.sequencia}} </strong> <i
                            class="fa-solid fa-trash-can"
                            (click)="deletarAutoInspecao(item.nomeArquivo,item.id)"></i></span>
                    <img mat-card-image class="imagemCard" [src]="item.path" alt="">

                </div>
                }

            </div>
            <div class="d-flex justify-content-center align-items-center mt-3">
                <button class="btn color-btn" (click)="salvarNovaSequencia()">Definir sequência</button>
            </div>
        </div>
        }
    </div>
</app-container-component>
}
<app-container-component [header]="false" subtituloPage="Cadastrar">
    <form [formGroup]="contactFormCriterio"  class="d-flex flex-column justify-content-center align-items-center">
        <div formArrayName="contactsCriterio" class="row col-12 p-md-3 p-0 espaco">
            @if(contactsCriterio.controls.length > 0){
                <div *ngFor="let contact of contactsCriterio.controls; let i = index" class="row col-12 ">
                    <div [formGroupName]="i" class="col-12 card-box">
                        <div class="col-12">
                            <label for="text" class="form-label">Descrição de Autoinspeção:</label>
                            <textarea formControlName="descricao" class="form-control form-control-sm dynamic-textarea"></textarea>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="mb-3 col-12">
                                <div class="col-12">
                                    @if(listaDocumentoSelecionadoAutoInspecaoRederizados[i]){
                                    <div
                                        class="col-12 mt-3 text-center d-flex flex-column justify-content-center align-items-center">
                                        <img class="imagem-pdf"
                                            src="{{this.listaDocumentoSelecionadoAutoInspecaoRederizados[i].preview}}" alt="">
                                        <label
                                            class="file-name mt-2">{{listaDocumentoSelecionadoAutoInspecaoRederizados[i].name}}</label>
                                    </div>
                                    }@else {
                                    <label for="arquivoAlteracao-{{i}}" class="form-label botao-input">
                                        Imagem +
                                        <input type="file" id="arquivoAlteracao-{{i}}" class="d-none"
                                            (input)="pegarImagemCriterio($event)">

                                    </label>
                                    }
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn close-button" (click)="removeContact(i)"><i
                                class="fa-regular fa-circle-xmark"></i></button>
                    </div>
                </div>
                }@else {
                <div class="d-flex align-items-center justify-content-center gap-1">
                    Criar formulario :
                    <app-botao label="Documentos" class="col-2" icon="icons8-lápis.png" status="btn-salvar"
                        (click)="addContact()"></app-botao>
                </div>
                }
            </div>
            <div class="row gap-1 gap-md-0  mt-1 col-12">
                @if(contactsCriterio.controls.length > 0 ){
                <app-botao label="Documentos" class="col-12 col-sm-3 col-md-4 col-lg-4" icon="icons8-mais.png"
                    status="btn-salvar" (click)="addContact()"></app-botao>
                }
                <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-4" icon="icons8-disquete.png"
                    status="btn-salvar" (click)="onSubmit()"></app-botao>
                <app-botao label="Cancelar" class="col-12 col-sm-3 col-md-4 col-lg-4" icon="icons8-x.png"
                    status="btn-cancelar" (click)="limparFormulario()"></app-botao>
            </div>
    </form>
</app-container-component>