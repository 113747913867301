import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../../utils/snack-bar.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  @ViewChild('myDiv') myDiv!: ElementRef;
  @ViewChild('myDiv2') myDiv2!: ElementRef;

  @ViewChild('inputSenha') campoSenha!: ElementRef<HTMLInputElement>;
  email: any;
  emailRecuperacao: any;
  senha: any;
  token: any;
  teste1 = "teste";
  mensagem: boolean = false;
  icone = "fa-solid fa-lock";
  private activeRouter = inject(ActivatedRoute);
  private loginService = inject(LoginService);
  private router = inject(Router);
  private snackBarService = inject(SnackBarService);

  constructor() {

  }

  ngOnInit(): void {
    this.verificarRota();
  }

  public verificarRota() {
    this.activeRouter.params.subscribe((res: any) => {
      if (res.mail) {
        this.email = res.mail;
      }
    });
  }

  public login() {
    this.loginService.fazerLogin(this.email, this.senha).subscribe({
      next: (res) => {
        this.token = res.token;
      }, complete: () => {
        this.salvarLocalStorage();
        this.limparFormulario();
      },
      error: (e) => {
        this.snackBarService.openSnackBar("Usuário ou senha inválido!", 'my-snack-bar-erro');
      }
    });
  }

  public abrirEsqueceuSenha() {
    this.myDiv.nativeElement.classList.toggle("active");
  }

  public salvarLocalStorage() {
    this.loginService.salvarLocalstorageTokenSgq(this.token);
    this.router.navigate(["/home"]);
  }

  public enviarRecuperacaoDeSenha() {
    this.loginService.recuperarSenha(this.emailRecuperacao).subscribe({
      next: (res) => {
        this.mensagem = true;
      }, complete: () => {
        this.limparFormulario();
      }
    })
  }

  public verSenha() {
    this.campoSenha.nativeElement.type = this.campoSenha.nativeElement.type == "password" ? "text" : "password";
    this.icone = this.campoSenha.nativeElement.type == "password" ? "fa-solid fa-eye" : "fa-solid fa-eye-slash";
  }

  public verificarValor() {
    this.icone = this.campoSenha.nativeElement.value != "" ? 'fa-solid fa-eye' : 'fa-solid fa-lock';
  }


  public limparFormulario() {
    this.email = "";
    this.senha = "";
    this.emailRecuperacao = "";
  }
}
