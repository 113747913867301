import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { IndicadoresTratamentoService } from './../../../../services/indicadores/indicadoresTratamento.service';
import { AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { ChartData, ChartItem } from 'chart.js';
import { IndicadoresGerenciaisService } from '../../../../services/indicadores/indicadores-gerenciais.service';
import { IndicadorGerenciais } from '../../../../model/indicadores/indicadoresGerenciais/IndicadorGerenciais';
import moment from 'moment';
import Chart from 'chart.js/auto';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IndicadorGerenciaisResult } from '../../../../model/indicadores/indicadoresGerenciais/IndicadorGerenciaisResult';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { LoginService } from '../../../../services/login/login.service';
@Component({
  selector: 'app-indicadores-gerenciais-home',
  standalone: true,
  imports: [CommonModule, FormsModule, ContainerComponentComponent, PaginatorComponent],
  templateUrl: './indicadores-gerenciais-home.component.html',
  styleUrl: './indicadores-gerenciais-home.component.scss'
})
export class IndicadoresGerenciaisHomeComponent implements OnInit,AfterViewInit,OnDestroy {

  anoAtual = moment().format('yyyy');
  anoPassado = moment().subtract(1, 'year').format('yyyy');
  anoSelecionado: string = moment().format('yyyy');

  paginaAtual: number = 0;
  totalTamanho: number = 0;
  resultadoMeses: any[] = [];
  metaMeses: any[] = [];
  resultadoMesesAnoPassado: any[] = [];
  metaMesesAnoPassado: any[] = [];
  titulo: string = `<i class='fa-solid fa-chart-simple'></i> Indicadores gerenciais ${this.anoAtual}`
  listaResultado = [{ meta: 0, resultado: 0 }]
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  listaDeIndicadores: IndicadorGerenciais[] = [];

  setores: any[] = [];
  anos: any[] = [];
  token: any;

  setorSelecionado: string = "Todos";
  private chartInstances: { [key: string]: Chart } = {};

  private emisorEventoGeralService =  inject(EmisorEventoGeralService);

  constructor(
    private indicadoresGerenciaisService: IndicadoresGerenciaisService,
    private router: Router,
    private snackBarService: SnackBarService,
    private indicadoresTratamentoService: IndicadoresTratamentoService,
    private cdr: ChangeDetectorRef,
    private loginService: LoginService
  ) {

  }
  ngOnDestroy(): void {
    this.destruirGraficos();
  }
  ngAfterViewInit(): void {
    this.consultarTodosOsGraficos();
  }

  

  ngOnInit(): void {
    this.decoderToken();
    this.criarListaDeAnos();
    this.consultaSetores();
  }

  decoderToken(){
    this.token = this.loginService.decodificarToken();
  }

  public gerenciarIndicador(idIndicador: number) {
    this.router.navigate([`indicadores/indicador-gerenciais-resultado/${idIndicador}/${this.anoSelecionado}`])
  }

  public editarIndicador(idIndicador: number) {
    this.router.navigate([`indicadores/gerenciais-cadastro/${idIndicador}`])
  }

  public consultarTodosOsGraficos() {
    this.listaDeIndicadores = [];
    this.emisorEventoGeralService.carregando.emit(true);
    this.indicadoresGerenciaisService.consultaIndicadorGerenciaisPorAno(this.paginaAtual, this.totalPorPagina, this.anoSelecionado).subscribe({
      next: (res: any) => {
        this.listaDeIndicadores = res.content;
        this.totalTamanho = res.page.totalElements;
        this.anoAtual = this.anoSelecionado;
        this.anoPassado = moment(this.anoSelecionado, 'YYYY').subtract(1, 'year').format('YYYY');
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.transformaTodosValoresEmNumero();
        this.cdr.detectChanges();
        this.montarGraficos();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public receberMudanca(event: any) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultarTodosOsGraficos();
  }

  public montarGraficos() {
    this.listaDeIndicadores.forEach((ind, index) => {
      const { id, tipo, indicador, resultados } = ind;
      if (resultados && resultados.length > 0) {
        const chartElement = document.getElementById(`myChart${id}`);
        if (chartElement) {
          this.tratarCorGrafico(tipo, resultados[resultados.length - 1]);
          this.tratarCorGraficoAnoAnterior(tipo, resultados[resultados.length - 2]);
          this.chartInstances[id] = new Chart(chartElement as ChartItem, {
            type: 'bar',
            data: this.getChartData(indicador),
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  usePointStyle: true,
                  callbacks: {
                    labelPointStyle: function (context) {
                      return {
                        pointStyle: 'triangle',
                        rotation: 0
                      };
                    }
                  }
                }
              },
              responsive: true, // Garante que o gráfico seja responsivo
              maintainAspectRatio: false, // Permite que o gráfico preencha todo o contêiner
            },

          });
        }
      }
    });
  }

  private tratarCorGrafico(tipoGrafico: string, resultados: any) {
    this.anoPassado = moment(this.anoSelecionado).subtract(1).format("yyyy");
    this.metaMeses = this.indicadoresTratamentoService.getMetaMeses(resultados);
    if (this.metaMeses) {
      this.resultadoMeses = this.indicadoresTratamentoService.getResultadoMeses(resultados, tipoGrafico, this.metaMeses,this.anoPassado);
    }
  }
  private tratarCorGraficoAnoAnterior(tipoGrafico: string, resultados: any) {
    this.anoPassado = moment(this.anoSelecionado).subtract(1).format("yyyy");
    this.metaMesesAnoPassado = this.indicadoresTratamentoService.getMetaMeses(resultados);
    if (this.metaMesesAnoPassado) {
      this.resultadoMesesAnoPassado = this.indicadoresTratamentoService.getResultadoMeses(resultados, tipoGrafico, this.metaMesesAnoPassado,this.anoPassado);
    }
  }

  private getChartData(label: string): ChartData<'bar' | 'line'> {
    return {
      labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
      datasets: [
        {
          type: 'line',
          label: 'Meta',
          borderColor: "blue",
          backgroundColor: "#c6c6c6",
          data: this.metaMeses.map(m => m.mes),
        },
        {
          label: `Resultado ${this.anoAtual}`,
          backgroundColor: this.resultadoMeses.map(r => r.cor),
          data: this.resultadoMeses.map(r => r.mes),
          borderWidth: 1
        },
        {
          type: 'line',
          fill: true,
          pointRadius: 5,
          label: `Resultado ${this.anoPassado}`,
          backgroundColor: this.resultadoMesesAnoPassado.map(r => r.cor),
          data: this.resultadoMesesAnoPassado.map(r => r.mes),
          borderWidth: 1
        },
      ],
    };
  }


  public criarListaDeAnos() {
    this.anos = this.indicadoresTratamentoService.criarListaDeAnos();
  }


  public consultarPorSetorEAno() {
    if (this.validarConsulta()) {
      this.indicadoresGerenciaisService.consultaIndicadorGerenciaisPorSetorEAno(this.setorSelecionado, this.anoSelecionado).subscribe({
        next: (res) => {
          this.listaDeIndicadores = res;
        }, error: (error) => {

        }, complete: () => {
          this.transformaTodosValoresEmNumero();
          this.cdr.detectChanges();
          this.montarGraficos()
        }
      });
    }
  }


  public consultaSetores() {
    this.indicadoresGerenciaisService.consultaSetores().subscribe({
      next: (res) => {
        this.setores = res;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public verificarSeGraficoEstaNaMeta(resultados: IndicadorGerenciais[]) {
    resultados.forEach(i => {
      const ultimoAnoIndex = i.resultados.length - 1;
      const resultadoAno = i.resultados[ultimoAnoIndex];
      if (i.resultados.length === 1) {
        // const anoAnterior = moment(this.anoSelecionado).subtract(1, 'year').format("yyyy");
        const novoResultado: IndicadorGerenciaisResult = {
          id: 0,
          ano: this.anoPassado,
          obsAno: "",
          media: 0,
          metaJan: 0, resultJan: 0,
          metaFev: 0, resultFev: 0,
          metaMar: 0, resultMar: 0,
          metaAbr: 0, resultAbr: 0,
          metaMai: 0, resultMai: 0,
          metaJun: 0, resultJun: 0,
          metaJul: 0, resultJul: 0,
          metaAgo: 0, resultAgo: 0,
          metaSet: 0, resultSet: 0,
          metaOut: 0, resultOut: 0,
          metaNov: 0, resultNov: 0,
          metaDez: 0, resultDez: 0,
          metaAno: 0, resultAno: 0,
          // Adicione outros campos conforme necessário
          usuario: "",
          dataUpdate: "",
          compJan1: 0,
          compJan2: 0,
          compFev1: 0,
          compFev2: 0,
          compMar1: 0,
          compMar2: 0,
          compAbr1: 0,
          compAbr2: 0,
          compMai1: 0,
          compMai2: 0,
          compJun1: 0,
          compJun2: 0,
          compJul1: 0,
          compJul2: 0,
          compAgo1: 0,
          compAgo2: 0,
          compSet1: 0,
          compSet2: 0,
          compOut1: 0,
          compOut2: 0,
          compNov1: 0,
          compNov2: 0,
          compDez1: 0,
          compDez2: 0,
          obsJan: '',
          obsFev: '',
          obsMar: '',
          obsAbr: '',
          obsMai: '',
          obsJun: '',
          obsJul: '',
          obsAgo: '',
          obsSet: '',
          obsOut: '',
          obsNov: '',
          obsDez: ''
        };
        i.resultados.unshift(novoResultado);
      }

      let soma = 0;
      let contadorMeses = 0;

      // Mapeia os resultados mensais
      const meses = [
        'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
        'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
      ];

      meses.forEach(mes => {
        const metaMes = resultadoAno[`meta${mes}` as keyof IndicadorGerenciaisResult];
        const resultMes = resultadoAno[`result${mes}` as keyof IndicadorGerenciaisResult];
        if (resultMes !== null && resultMes !== 0) {
          soma += resultMes as number;
          contadorMeses++;
        }
      });
      // Calcular a média
      resultadoAno.media = contadorMeses > 0 ? soma / contadorMeses : 0;
    });
  }

  private destruirGraficos() {
    Object.keys(this.chartInstances).forEach((key) => {
      this.chartInstances[key].destroy();
      delete this.chartInstances[key]; // Remove a referência do gráfico destruído
    });
  }


  private transformaTodosValoresEmNumero() {
    this.listaDeIndicadores.forEach(e => {
      e.resultados = e.resultados.map(i => this.indicadoresTratamentoService.converterParaNumeros(i, [
        'resultJan', 'resultFev', 'resultMar', 'resultAbr', 'resultMai',
        'resultJun', 'resultJul', 'resultAgo', 'resultSet', 'resultOut',
        'resultNov', 'resultDez', 'resultAno', 'metaJan', 'metaFev',
        'metaMar', 'metaAbr', 'metaMai', 'metaJun', 'metaJul', 'metaAgo',
        'metaSet', 'metaOut', 'metaNov', 'metaDez', 'metaAno']))
    });
    this.verificarSeGraficoEstaNaMeta(this.listaDeIndicadores);
  }

  private validarConsulta() {
    if (this.setorSelecionado && this.anoSelecionado) {
      if (this.setorSelecionado === "Todos") {
        this.setorSelecionado = "";
        this.consultarTodosOsGraficos();
      } else if (this.anoSelecionado !== this.anoAtual) {
        this.anoAtual = this.anoSelecionado;
        this.anoPassado = moment(this.anoSelecionado, 'YYYY').subtract(1, 'year').format('YYYY');
      }
      this.listaDeIndicadores = [];
      this.destruirGraficos();
      return true;
    } else {
      this.snackBarService.openSnackBar("Selecione setor e ano para fazer a consulta", "my-snack-bar-warning");
      return false;
    }
  }
}