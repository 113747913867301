import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ListarDispositivosComponent } from '../listar-dispositivos/listar-dispositivos.component';
import { DispositivoService } from '../../../services/dispositivos/dispositivo.service';
import { Dispositivo } from '../../../model/dispositivos/dispostivo';
import { PageEvent } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";
import { LoginService } from '../../../services/login/login.service';

@Component({
  selector: 'app-dispositivos-lista',
  standalone: true,
  imports: [CommonModule, ListarDispositivosComponent, RouterLink, FormsModule, ContainerComponentComponent],
  templateUrl: './dispositivos-lista.component.html',
  styleUrl: './dispositivos-lista.component.scss'
})
export class DispositivosListaComponent implements OnInit {

  mostrarBolas = false;
  dispositivos: Dispositivo[] = [];
  urlTipo = "eng-dispositivo";
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [8, 16, 24, 32, 56]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  nomeDispositivo: string = '';
  naoEncontradado: boolean = false;
  paginacao: boolean = true;
  loginService = inject(LoginService);
  constructor(
    private dispositivoService: DispositivoService,
  ) {

  }

  ngOnInit(): void {
    this.consultarDispositivos();
  }

  public consultarDispositivos() {
    this.dispositivoService.consultarTodasPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res: any) => {
        this.dispositivos = res.content;
        this.totalTamanho = res.page.totalElements;
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultarDispositivos();
  }

  public consultaDispositivoPorNome() {
    this.naoEncontradado = false;
    this.paginacao = true;
    if (this.nomeDispositivo === '') {
      this.consultarDispositivos();
    } else {
      this.dispositivoService.consultarDispositivoPorNome(this.nomeDispositivo).subscribe({
        next: (res) => {
          this.dispositivos = res;
          if (this.dispositivos.length == 0) {
            this.naoEncontradado = true;
          } else {
            this.paginacao = false;
          }
        },
        error: (e) => {
          console.log(e);
        }
      });
    }
  }

  public buscar(event?: any) {
    if (!event || event.key === "Enter") {
      this.consultaDispositivoPorNome();
    }
  }

  public openClose() {
    this.mostrarBolas = !this.mostrarBolas;
  }


  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_DISP_EDIT'])
  }





}
