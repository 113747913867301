import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { EngDeterminacaoFosfato } from '../../../model/engenharia/EngDeterminacaoFosfato';


@Injectable({
  providedIn: 'root'
})
export class DeterminacaoProcessoFosfatoService {

  private url = `${environment.url}/engenharia/eng-determinacao-fosfato`;
  constructor(
    private http: HttpClient
  ) { 
    
  }


  public salvarDeterminacaoProcesso(engDeterminacaoProcesso: EngDeterminacaoFosfato): Observable<EngDeterminacaoFosfato> {
    return this.http.post<EngDeterminacaoFosfato>(`${this.url}`, engDeterminacaoProcesso);
  }
  public editarDeterminacaoProcesso(engDeterminacaoProcesso: EngDeterminacaoFosfato): Observable<EngDeterminacaoFosfato> {
    return this.http.put<EngDeterminacaoFosfato>(`${this.url}`, engDeterminacaoProcesso);
  }

  public consultarDeterminacaoProcessoPorCdProduto(cdProduto:number): Observable<EngDeterminacaoFosfato> {
    return this.http.get<EngDeterminacaoFosfato>(`${this.url}/${cdProduto}`);
  }

  public deletarDeterminacaoProcesso(id:number): Observable<EngDeterminacaoFosfato> {
    return this.http.delete<EngDeterminacaoFosfato>(`${this.url}/${id}`);
  }

}
