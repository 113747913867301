import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { SnackBarService } from './../../../../utils/snack-bar.service';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { BotaoComponent } from "../../../../componentes/botao/botao.component";
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { AnaliseCritica } from '../../../../model/acoes/analise-critica/AnaliseCritica';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import moment from 'moment';
import { AnaliseCriticaService } from '../../../../services/acoes/analise-critica/analise-critica.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../../componentes/modal/modal.component';

@Component({
  selector: 'app-analise-critica-registrar',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, MensagemErrorsComponent, ReactiveFormsModule, BotaoComponent, MatTabsModule, FormsModule],
  templateUrl: './analise-critica-registrar.component.html',
  styleUrl: './analise-critica-registrar.component.scss'
})
export class AnaliseCriticaRegistrarComponent implements OnInit {

  formularioCadastro!: FormGroup;
  analiseCritica!: AnaliseCritica;
  idAnaliseCritica: number = 0;
  formValidacaoService = inject(FormValidacaoService);
  formBuilder = inject(FormBuilder);
  private analiseCriticaService = inject(AnaliseCriticaService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private snackBarService = inject(SnackBarService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  indiceMesAtual = 0;
  dataAtual = moment().format("YYYY-MM-DD");



  ngOnInit(): void {
    this.verificarRota();

  }

  public verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idAnaliseCritica = res.id;
        if(this.idAnaliseCritica){
          this.consultaPorId();
        }
      }
    })
  }
  public iniciarFormulario() {
    this.formularioCadastro = this.formBuilder.group({
      id: [],
      identificacao: [null, [Validators.required, Validators.nullValidator]],
      participantes: [null, [Validators.required, Validators.nullValidator]],
      pautaGeral: [null, [Validators.required, Validators.nullValidator]],
      dataRealizacao: [this.dataAtual],
      ent_932_a: [],
      ent_932_b: [],
      ent_932_c_1: [],
      ent_932_c_2: [],
      ent_932_c_3: [],
      ent_932_c_4: [],
      ent_932_c_5: [],
      ent_932_c_6: [],
      ent_932_c_7: [],
      ent_932_d: [],
      ent_932_e: [],
      ent_932_f: [],
      ent_9321_a: [],
      ent_9321_b: [],
      ent_9321_c: [],
      ent_9321_d: [],
      ent_9321_r: [],
      ent_9321_f: [],
      ent_9321_g: [],
      ent_9321_h: [],
      ent_9321_i: [],
      ent_9321_ak: [],
      ent_9321_k: [],
      ent_9321_l: [],
      sai_933_a: [],
      sai_933_b: [],
      sai_933_c: [],
      obs: [],
    });
  }


  public cadastrarAnaliseCritica() {
    if (this.formularioCadastro.valid) {
      this.emisorEventoGeralService.carregando.emit(true);
      this.formularioCadastro.removeControl("id");
      this.analiseCriticaService.cadastrarAnaliseCritica(this.formularioCadastro.value).subscribe({
        next: (res) => {
          this.idAnaliseCritica = res.id
        }, error: (error) => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.tratarErro(error, "Erro ao tentar salvar análise crítica");
        }, complete: () => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.router.navigate([`/acoes/analise-critica-registrar/${this.idAnaliseCritica}`]);
          this.snackBarService.openSnackBar("Análise crítica criada com sucesso", "my-snack-bar-sucess");
        }
      });
    } else {
      this.formularioCadastro.markAllAsTouched();
      this.indiceMesAtual = 0;
      this.snackBarService.openSnackBar("Preencha os dados dos campos obrigatórios", "my-snack-bar-warning");
    }
  }


  public consultaPorId() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.analiseCriticaService.consultarAnaliseCriticaPorId(this.idAnaliseCritica).subscribe({
      next: (res) => {
        this.formularioCadastro.patchValue(res);
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar consultar análise crítica" + this.idAnaliseCritica);
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.openSnackBar("Análise crítica consultada com sucesso", "my-snack-bar-sucess");
      }
    });
  }

  public editarAnaliseCritica() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.analiseCriticaService.editarAnaliseCritica(this.formularioCadastro.value).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar editar análise crítica");
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.consultaPorId();
        this.snackBarService.openSnackBar("Análise crítica editada com sucesso", "my-snack-bar-sucess");
      }
    });
  }

  public deletarAnaliseCritica(analise:AnaliseCritica){
    const dialogRef = this.dialog.open(ModalComponent,{
      data: { nomeArquivo: analise.identificacao, texto: "Deseja apagar essa análise crítica" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.deletar(analise.id);
        };
      }
    });
  }

  public deletar(idAnalise:number){
    this.emisorEventoGeralService.carregando.emit(true);
    this.analiseCriticaService.deletarAnaliseCritica(idAnalise).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar deletada análise crítica");
      }, complete: () => {
        this.router.navigate(["acoes/analise-critica"])
        this.snackBarService.openSnackBar("Análise crítica deletada com sucesso", "my-snack-bar-sucess");
      }
    });
  }

  isExclusaoAnaliseCritica: boolean = false;

  checkTab(tabName: any) {
    this.isExclusaoAnaliseCritica = tabName === 'Exclusão da análise crítica';
    console.log(this.isExclusaoAnaliseCritica)
  }


  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formularioCadastro, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formularioCadastro, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formularioCadastro, nome);
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }
}
