<app-container-component tituloPage="PLANEJAMENTO DE COMUNICAÇÃO"
    [subtituloPage]="this.planejamento.id ? 'Editar planejamento':'Novo planejamento'">
    <form class="form-group row d-flex justify-content-start formulario-produto col-12"
        [formGroup]="formularioCadastro">
        <div class="mb-3 col-12 col-lg-12">
            <label class="form-label">Nome do planejamento:</label>
            <div class="position-relative">
                <input type="text" formControlName="descricao" class="form-control form-control-sm"
                    [ngClass]="{'errorAnimacao':tocado('descricao'),'validoAnimacao':validar('descricao')}">
                <span class="input-icon"
                    [ngClass]="{'valid-icon': validar('descricao'), 'invalid-icon': tocado('descricao')}">
                    <i class="fas fa-check-circle" *ngIf="validar('descricao')"></i>
                    <i class="fas fa-exclamation-circle" *ngIf="tocado('descricao')"></i>
                </span>
            </div>
            <app-mensagem-errors [control]="obterValidacao('descricao')"></app-mensagem-errors>
        </div>

        @if(this.planejamento.id){
        <div class="row">
            <app-botao label="Editar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-lápis.png"
                status="btn-salvar" (click)="editarPlanejamentoComunicacao()"></app-botao>
        </div>
        }@else{
        <div class="row">
            <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="cadastrarPlanejamentoComunicacao()"></app-botao>
        </div> }
    </form>
</app-container-component>
@if(this.planejamento){
<app-planejamento-comunicacao-acoes [planejamentoComunicacaoRecebido]="this.planejamento" [ativarHeader]="false"
    (emitirEdicao)="emitirEdicao($event)"></app-planejamento-comunicacao-acoes>

@if(mostrarFormularioAcoes){
<app-planejamento-comunicacao-registrar-acoes
    [planejamentoComunicacao]="this.planejamento"
    [acaoPlanejamentoComunicacao]="this.acoesParteInteressada"
    (emitirNovaConsulta)="receberNovaConsulta($event)"></app-planejamento-comunicacao-registrar-acoes>
}

<div class="botao-container">
    <div class="botao" (click)="openClose()" (click)="mostrarFormsAcoes()">
        @if(!mostrarBolas){
        <i class="fa-solid fa-plus" ></i>
        }@else {
        <i class="fa-solid fa-minus"></i>
        }
    </div>
    <!-- <div class="bola-adicionar" [class.show]="mostrarBolas" >
        <span> {{mostrarFormularioAcoes ? "Fechar": "Adicionar Novas Ações"}} </span>
    </div> -->
</div>
}