import { Routes } from "@angular/router";

export const routesIndicadoresEdit: Routes = [

    //PERFORMANCE
    { path: "performance-cadastro", loadComponent: () => import("../../paginas/indicadores/indicadores-performance/indicadores-performance-cadastro/indicadores-performance-cadastro.component").then(i => i.IndicadoresPerformanceCadastroComponent) },
    { path: "performance-cadastro/:id", loadComponent: () => import("../../paginas/indicadores/indicadores-performance/indicadores-performance-cadastro/indicadores-performance-cadastro.component").then(i => i.IndicadoresPerformanceCadastroComponent) },
    { path: "indicador-performance-resultado/:id/:ano", loadComponent: () => import("../../paginas/indicadores/indicadores-performance/indicadores-performance-resultado/indicadores-performance-resultado.component").then(i => i.IndicadoresPerformanceResultadoComponent) },

    //GERENCIAIS
    { path: "gerenciais-cadastro", loadComponent: () => import("../../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-cadastrar/indicadores-gerenciais-cadastrar.component").then(i => i.IndicadoresGerenciaisCadastrarComponent) },
    { path: "gerenciais-cadastro/:id", loadComponent: () => import("../../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-cadastrar/indicadores-gerenciais-cadastrar.component").then(i => i.IndicadoresGerenciaisCadastrarComponent) },
    { path: "indicador-gerenciais-resultado/:id/:ano", loadComponent: () => import("../../paginas/indicadores/indicadores-gerenciais/indicadores-gerenciais-resultado/indicadores-gerenciais-resultado.component").then(i => i.IndicadoresGerenciaisResultadoComponent) },

    //MONITORAMENTO
    { path: "monitoramento-cadastro", loadComponent: () => import("../../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-cadastrar/indicadores-monitoramento-cadastrar.component").then(i => i.IndicadoresMonitoramentoCadastrarComponent) },
    { path: "monitoramento-cadastro/:id", loadComponent: () => import("../../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-cadastrar/indicadores-monitoramento-cadastrar.component").then(i => i.IndicadoresMonitoramentoCadastrarComponent) },
    { path: "indicador-monitoramento-resultado/:id/:ano", loadComponent: () => import("../../paginas/indicadores/indicadores-monitoramento/indicadores-monitoramento-result/indicadores-monitoramento-result.component").then(i => i.IndicadoresMonitoramentoResultComponent) },

    //IQF CLIENTES
    { path: "clientes-cadastro", loadComponent: () => import("../../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-cadastrar/indicadores-iqf-clientes-cadastrar.component").then(i => i.IndicadoresIqfClienteCadastrarComponent) },
    { path: "clientes-cadastro/:id", loadComponent: () => import("../../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-cadastrar/indicadores-iqf-clientes-cadastrar.component").then(i => i.IndicadoresIqfClienteCadastrarComponent) },
    { path: "indicador-clientes-resultado/:id/:ano", loadComponent: () => import("../../paginas/indicadores/indicadores-iqf-clientes/indicadores-iqf-clientes-result/indicadores-iqf-clientes-result.component").then(i => i.IndicadoresIqfClienteResultComponent) },


    //IQF FORNECEDORES
    { path: "fornecedores-cadastro", loadComponent: () => import("../../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-cadastro/indicadores-iqf-fornecedores.component").then(i => i.IndicadoresIqfFornecedoresComponent) },
    { path: "fornecedores-cadastro/:id", loadComponent: () => import("../../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-cadastro/indicadores-iqf-fornecedores.component").then(i => i.IndicadoresIqfFornecedoresComponent) },
    { path: "indicador-fornecedores-resultado/:id/:ano", loadComponent: () => import("../../paginas/indicadores/indicadores-iqf-fornecedores/indicadores-iqf-fornecedores-result/indicadores-iqf-fornecedores-result.component").then(i => i.IndicadoresIqfFornecedoresResultComponent) },

    //OBJ QUALIDADE
    { path: "obj-qualidade-cadastro", loadComponent: () => import("../../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-cadastrar/indicador-obj-qualidade-cadastrar.component").then(i => i.IndicadorObjQualidadeCadastrarComponent) },
    { path: "obj-qualidade-cadastro/:id", loadComponent: () => import("../../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-cadastrar/indicador-obj-qualidade-cadastrar.component").then(i => i.IndicadorObjQualidadeCadastrarComponent) },
    { path: "indicador-obj-qualidade-resultado/:id", loadComponent: () => import("../../paginas/indicadores/indicadores-obj-qualidade/indicador-obj-qualidade-resultado/indicador-obj-qualidade-resultado.component").then(i => i.IndicadorObjQualidadeResultadoComponent) },

]


