import { SnackBarService } from './../utils/snack-bar.service';
import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';

export const verificarPermissoesGuard: CanActivateFn = (route, state) => {
  const permissaoDaRota = route.data['permissao'];
  const loginService  = inject(LoginService);
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const snackBarService = inject(SnackBarService);

  
  if(loginService.temPermissao(permissaoDaRota)){
    LoginService.naoAutorizado.emit(false);
    return true;
  }else{
    // snackBarService.openSnackBar("Usuario sem permissão","my-snack-bar-erro");
    LoginService.naoAutorizado.emit(true);
    return false;
  } 

};
