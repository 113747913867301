import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink, Router, NavigationEnd } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NotificacoesComponentComponent } from '../../componentes/notificacoes-component/notificacoes-component.component';
import { EmisorEventoGeralService } from '../../utils/emisorEventoGeral.service';
import { InformacaoDocumentadaService } from '../../services/acoes/informacao-documentada/informacao-documentada.service';
import { StatusInfoDoc } from '../../enum/status-informacao-documentada';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  url: any;
  quantidadeTotalDeNotificacao: number = 0;
  quantidadeDeInformacaoDocumentadaPendente: number = 0;
  tipo = environment.tipo;
  linkServe = `${environment.url}/home`;

  private notificacoesInterval: any = null; // Variável para controlar o intervalo
  private subscription: Subscription | null = null;

  constructor(
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private informacaoDocumentadaService: InformacaoDocumentadaService,
  ) {

  }

  ngOnInit(): void {
    this.consultarNotificacoes();
    this.receberQuantidadeDeNotificacao();
    if (!this.notificacoesInterval) {
      this.startNotificacoesAutoUpdate();
    }
    console.log(this.linkServe)
  }

  openBottomSheet(): void {
    this.bottomSheet.open(NotificacoesComponentComponent);
  }

  public receberQuantidadeDeNotificacao() {
    EmisorEventoGeralService.quantidadeNotificacao.subscribe(res => {
      this.quantidadeDeInformacaoDocumentadaPendente = res;
    });
  }

  public consultarNotificacoes() {
    this.subscription = this.informacaoDocumentadaService.consultaAprovacaoPendentesDoUsuario(StatusInfoDoc.PENDENTE).subscribe({
      next: (res) => {
        this.quantidadeDeInformacaoDocumentadaPendente = res;
        if (this.quantidadeDeInformacaoDocumentadaPendente == 0) {
          this.stopNotificacoesAutoUpdate();
        }
      }, error: (error) => {

      }, complete: () => {
        this.totalDeNotificacoes();
      }
    });
  }

  public logOffSistema() {
    sessionStorage.clear();
    window.location.reload();
  }

  public totalDeNotificacoes() {
    EmisorEventoGeralService.quantidadeNotificacao.emit(this.quantidadeDeInformacaoDocumentadaPendente);
  }

  private startNotificacoesAutoUpdate(): void {
    // Verifica se o intervalo já está rodando para evitar duplicações
    this.notificacoesInterval = setInterval(() => {
      this.consultarNotificacoes();
    }, 60000); // Atualiza a cada 60 segundos
  }

  public stopNotificacoesAutoUpdate(): void {
    if (this.notificacoesInterval) {
      clearInterval(this.notificacoesInterval);
      this.notificacoesInterval = null;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
