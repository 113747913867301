@if(!formularioProduto.controls['id'].value){
<app-container-component tituloPage="CADASTRO DE PRODUTO" subtituloPage="Consultar do Supersmart">
    <div class="row">
        <div class="form-check form-switch  col-12 ms-3 mb-3">
            <input id="consultaSupersmart" class="form-check-input" type="checkbox" [(ngModel)]="consultaSupersmart">
            <label class="form-check-label" for="consultaSupersmart">Habilitar consulta</label>
        </div>
        @if(consultaSupersmart){
        <div class="mb-3  col-12 col-lg-4">
            <div class="input-group mb-3">
                <input type="number" class="form-control form-control-sm"
                    (keyup)="this.consultarPorId(codigoSupersmart,$event)" placeholder="Digite o código interno"
                    [(ngModel)]="codigoSupersmart">
                <a (click)="this.consultarPorIdClick(codigoSupersmart)"
                    class="btn btn-light btn-sm d-flex align-items-center " type="button" id="button-addon2">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </a>
            </div>
        </div>
        <strong class="aviso">Consulta realizada apenas pelo código interno do produto!</strong>
        }
    </div>
</app-container-component>
}

<form class="form-group  row" [formGroup]="formularioProduto" (keydown.enter)="onEnter($event)">
    <app-container-component [header]="!consultaSupersmart && formularioProduto.controls['id'].value"
        tituloPage="DADOS DO PRODUTO" subtituloPage="Detalhes do produto">
        <div class="row">
            @if(formularioProduto.controls['situacao'].value == 'Inativa'){
                <div class="col-12 text-center mb-2">
                    <strong class="text-danger bold">PRODUTO INATIVO</strong>
                </div>
            }
            <!-- <div class="d-flex justify-content-center col-12 mb-3">
                <a [href]="listaDeImagens[1].path" target="_blank" class="d-flex justify-content-center">
                    <img class="col-6 col-lg-4" [src]="listaDeImagens[1].path" alt="">
                </a>
            </div> -->
            <div #swiperContainer class="swiper mySwiper mb-3">
                <div class="swiper-wrapper">
                    @for(imagem of listaDeImagens;track imagem.id){
                    <div class="swiper-slide position-relative">
                        <div class="d-flex justify-content-center col-12 ">
                            <a [href]="imagem.path" target="_blank" class="d-flex justify-content-center">
                                <img class="col-6 col-lg-4" [src]="imagem.path" alt="">
                            </a>
                        </div>
                    </div>
                    }
                </div>
                <div class="swiper-pagination mt-5 d-flex justify-content-center"></div>
            </div>

            @if(!formularioProduto.get('id')!.value){
            <div class="col-12 d-flex justify-content-center">
                <img class="col-6 col-lg-3 " [src]="urlImagem" alt="">
            </div>
            @if(formularioProduto.get('id')!.value){
            <div class="ps-5 pe-5">
                <div class="alert alert-primary" role="alert">
                    Produto do book
                </div>
            </div>
            }
            }
            @if(mostrarValorSupersmart){
            <div class="ps-5 pe-5 mt-2">
                <div class="alert alert-primary" role="alert">
                    Produto consultado do supersmart não esta cadastrado no book
                </div>
            </div>
            }

            <div class="mb-3  col-12 col-md-6 col-lg-6 col-xxl-4">
                <label for="text" class="form-label">Nome da Peça - PN:</label>
                <input type="text" formControlName="nomeProduto" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('nomeProduto')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-4">
                <label for="text" class="form-label">Tratamento / Beneficiamento:</label>
                <input type="text" formControlName="nomeBeneficiamento" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('nomeBeneficiamento')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-4">
                <label for="text" class="form-label">Cliente:</label>
                <input type="text" formControlName="nomeCliente" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('nomeCliente')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Representante do Cliente:</label>
                <input type="text" formControlName="representante" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('representante')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Função do Representante:</label>
                <input type="text" formControlName="cargoRepresentante" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('cargoRepresentante')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Aplicação principal da peça:</label>
                <input type="text" formControlName="aplicacaoPrincipal" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('aplicacaoPrincipal')"></app-mensagem-errors>
            </div>

            <div class="mb-3 col-12  col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Norma de Pintura:</label>
                <input type="text" formControlName="norma" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12  col-sm-4 col-md-4 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Peso:</label>
                <div class="position-relative">
                    <input type="number" formControlName="peso"
                        [ngClass]="{'errorAnimacao':tocado('peso'),'validoAnimacao':validar('peso')}"
                        class="form-control form-control-sm ">
                    <span class="input-icon"
                        [ngClass]="{'valid-icon': validar('peso'), 'invalid-icon': tocado('peso')}">
                        <i class="fas fa-check-circle" *ngIf="validar('peso')"></i>
                        <i class="fas fa-exclamation-circle" *ngIf="tocado('peso')"></i>
                    </span>
                </div>
                <app-mensagem-errors [control]="obterValidacao('peso')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-4 col-md-4 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Área:</label>
                <div class="position-relative">
                    <input type="number" formControlName="area"
                        [ngClass]="{'errorAnimacao':tocado('area'),'validoAnimacao':validar('area')}"
                        class="form-control form-control-sm ">
                    <span class="input-icon"
                        [ngClass]="{'valid-icon': validar('area'), 'invalid-icon': tocado('area')}">
                        <i class="fas fa-check-circle" *ngIf="validar('area')"></i>
                        <i class="fas fa-exclamation-circle" *ngIf="tocado('area')"></i>
                    </span>
                </div>
                <app-mensagem-errors [control]="obterValidacao('area')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-4 col-md-4 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Espessura:</label>
                <div class="position-relative">
                    <input type="number" formControlName="espessura"
                        [ngClass]="{'errorAnimacao':tocado('espessura'),'validoAnimacao':validar('espessura')}"
                        class="form-control form-control-sm ">
                    <span class="input-icon"
                        [ngClass]="{'valid-icon': validar('espessura'), 'invalid-icon': tocado('espessura')}">
                        <i class="fas fa-check-circle" *ngIf="validar('espessura')"></i>
                        <i class="fas fa-exclamation-circle" *ngIf="tocado('espessura')"></i>
                    </span>
                </div>
                <app-mensagem-errors [control]="obterValidacao('espessura')"></app-mensagem-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Processo:</label>
                <div class="d-flex align-items-start">
                    <app-select-component [listaConteudo]="processos"
                        [valorSelecionado]="formularioProduto.get('processo')!.value"
                        (conteudoRetorno)="receberConteudoProcesso($event)">
                    </app-select-component>
                    <!-- @if(temPermissao()){
                    <a class="btn btn-sm btn-light" (click)="gerenciarProcesso()"
                        style="height: 31px; display: flex; align-items: center; justify-content: center;">
                        <i class="fa-solid fa-plus"></i>
                    </a>
                    } -->

                </div>
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-3 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">IMDS:</label>
                <input type="text" formControlName="imds" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-2 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">% Sucata:</label>
                <input type="text" formControlName="percentualSucata" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Material:</label>
                <input type="text" formControlName="material" class="form-control form-control-sm">
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Família de Material:</label>
                <div class="input-group mb-3">
                    <select class="form-select form-select-sm" formControlName="familiaMateriais">
                        <option value="" disabled
                            [selected]="formularioProduto.controls['familiaMateriais'].value === '' ||
                            formularioProduto.controls['familiaMateriais'].value === null">
                            Selecione um material</option>
                        @for( material of substratos;track material.id){
                        <option [selected]="formularioProduto.controls['familiaMateriais'].value === material">
                            {{material.descricao}}</option>
                        }
                    </select>
                    @if(temPermissao()){
                    <a class="btn btn-sm btn-light" (click)="gerenciarSubstrato()"><i class="fa-solid fa-plus"></i></a>
                    }
                </div>
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Prazo de Entrega:</label>
                <input type="text" class="form-control form-control-sm" formControlName="prazoEntrega">
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Previsão de Demanda:</label>
                <input type="text" class="form-control form-control-sm" formControlName="previsaoDemanda">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Montadora:</label>
                <input type="text" class="form-control form-control-sm" formControlName="montadora">
            </div>
            <!-- <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
            <label for="text" class="form-label">Situação:</label>
            <input class="form-control form-control-sm" type="text" formControlName="situacao">
        </div> -->
            <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Situação:</label>
                <div class="input-group mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                    <select class="form-select form-select-sm" required
                        [ngClass]="{'errorAnimacao': tocado('situacao'), 'validoAnimacao': validar('situacao')}"
                        formControlName="situacao">
                        <option value="" disabled selected>Selecione uma situação</option>
                        @for (situacao of situacoes; track $index) {
                        @if(formularioProduto.get('situacao')!.value != situacao.descricao){
                        <option>{{situacao.descricao}}</option>
                        }
                        @if(formularioProduto.get('situacao')!.value == situacao.descricao){
                        <option selected>{{situacao.descricao}}</option>
                        }
                        }
                    </select>
                    @if(temPermissao()){
                    <a class="btn btn-sm btn-light" (click)="gerenciarSituacao()"><i class="fa-solid fa-plus"></i></a>
                    }
                </div>
            </div>
            @if(temPermissaoAdmin() && this.cdProduto != undefined){
                <div class="col-12">
                    <button class="btn btn-sm btn-salvar p-1" (click)="clonarProduto()">Clonar</button>
                </div>
            }
        </div>
    </app-container-component>
    <app-container-component [header]="false" subtituloPage="Produto status">
        <div class="ps-4 row">
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="corte-laser" class="form-check-input" type="checkbox" formControlName="corteLaser">
                <label class="form-check-label" for="corte-laser">Corte Laser</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="chapa" class="form-check-input" type="checkbox" formControlName="chapaPreta">
                <label class="form-check-label" for="chapa">Chapa Preta</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="jateamento" class="form-check-input" type="checkbox" formControlName="jateamento">
                <label class="form-check-label" for="jateamento">Jateamento</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="zincado" class="form-check-input" type="checkbox" formControlName="zincado">
                <label class="form-check-label" for="zincado">Galvanizado/Zincado</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="decapIterna" class="form-check-input" type="checkbox" formControlName="decapIterna">
                <label class="form-check-label" for="decapIterna">Dec. na Linha</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="decapExterna" class="form-check-input" type="checkbox" formControlName="decapExterna">
                <label class="form-check-label" for="decapExterna">Dec. Fora da Linha</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="itemSeguranca" class="form-check-input" type="checkbox" formControlName="itemSeguranca">
                <label class="form-check-label" for="itemSeguranca">Item de Segurança</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="itemAparencia" class="form-check-input" type="checkbox" formControlName="itemAparencia">
                <label class="form-check-label" for="itemAparencia">Item de Aparência</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="relatAprovAparencia" class="form-check-input" type="checkbox"
                    formControlName="relatAprovAparencia">
                <label class="form-check-label" for="relatAprovAparencia">Necessário RAA</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="retemAmostraPadrao" class="form-check-input" type="checkbox"
                    formControlName="retemAmostraPadrao">
                <label class="form-check-label" for="retemAmostraPadrao">Retém Amostra
                    Padrão</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="substanciaPerigosa" class="form-check-input" type="checkbox"
                    formControlName="substanciaPerigosa">
                <label class="form-check-label" for="substanciaPerigosa">Substância Perigosa</label>
            </div>
            <div class="form-check form-switch  col-12 col-md-6 col-lg-6 col-xxl-3">
                <input id="pecaPolimera" class="form-check-input" type="checkbox" formControlName="pecaPolimera">
                <label class="form-check-label" for="pecaPolimera">Peça Polimérica</label>
            </div>
        </div>
    </app-container-component>
    <app-container-component [header]="false" subtituloPage="Produto observações">
        <div class="row">
            <div class="mb-3 col-12">
                <label class="form-label">Condição de Armazenagem Pré-processamento:</label>
                <textarea class="form-control form-control-sm" formControlName="condiArmazenagem"></textarea>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Tipo de Desenvolvimento:</label>
                <div class="input-group mb-3">
                    <select class="form-select form-select-sm" formControlName="tipoDesenvolvimento">
                        <option [selected]="formularioProduto.controls['tipoDesenvolvimento'].value === '' || formularioProduto.controls['tipoDesenvolvimento'].value === null">Selecione uma tipo desenvolvimento</option>
                        @for( tipo of tipoDesenvolvimento;track tipo.id){
                        <option [selected]="formularioProduto.controls['tipoDesenvolvimento'].value === tipo">{{tipo.descricao}}</option>
                        }
                    </select>
                    @if(temPermissao()){
                    <a class="btn btn-sm btn-light" (click)="gerenciarTipoDesenvolvimento()"><i
                            class="fa-solid fa-plus"></i></a>
                    }
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Status do Desenvolvimento:</label>
                <div class="input-group mb-3">
                    <select class="form-select form-select-sm" formControlName="statusDesenvolvimento">
                        <option [selected]="formularioProduto.controls['statusDesenvolvimento'].value === '' || formularioProduto.controls['statusDesenvolvimento'].value === null">Selecione o status do Desenvolvimento</option>
                        @for( status of statusDesenvolvimento;track status.id){
                        <option [selected]="formularioProduto.controls['statusDesenvolvimento'].value === status">{{status.descricao}}</option>
                        }
                    </select>
                    @if(temPermissao()){
                    <a class="btn btn-sm btn-light" (click)="gerenciarStatusDesenvolvimento() "><i
                            class="fa-solid fa-plus"></i></a>
                    }
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Status do book:</label>
                <div class="input-group mb-3">
                    <select class="form-select form-select-sm" formControlName="statusBook">
                        <option [selected]="formularioProduto.controls['statusBook'].value === '' || formularioProduto.controls['statusBook'].value === null">Selecione o status do book:</option>
                        @for( statusBook of statusBook;track statusBook.id){
                        <option [selected]="formularioProduto.controls['statusBook'].value === statusBook">{{statusBook.descricao}}</option>
                        }
                    </select>
                    @if(temPermissao()){
                    <a class="btn btn-sm btn-light" (click)="gerenciarStatusBook() "><i
                            class="fa-solid fa-plus"></i></a>
                    }
                </div>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Lote Significativo:</label>
                <input type="text" class="form-control form-control-sm" formControlName="loteSignificativo">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                <label class="form-label">Início Desenvolvimento:</label>
                <input type="date" class="form-control form-control-sm" formControlName="inicioDesenvolvimento"
                    [ngClass]="{'errorAnimacao':tocado('inicioDesenvolvimento'),'validoAnimacao':validar('inicioDesenvolvimento')}">
                <app-mensagem-errors [control]="obterValidacao('inicioDesenvolvimento')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                <label class="form-label">Final Desenvolvimento:</label>
                <input type="date" class="form-control form-control-sm" formControlName="fimDesenvolvimento">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Código do desenho:</label>
                <input type="text" class="form-control form-control-sm" formControlName="codigoDesenho">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Nome da peça no desenho:</label>
                <input type="text" class="form-control form-control-sm" formControlName="nomePecaDesenho">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Revisão do desenho:</label>
                <input type="text" class="form-control form-control-sm" formControlName="revisaoDesenho">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label">Data do desenho:</label>
                <input type="date" class="form-control form-control-sm" formControlName="dataDesenho">
            </div>
            <div class="mb-3 col-12">
                <label class="form-label">Observações:</label>
                <textarea class="form-control form-control-sm" formControlName="observacao"
                    (loadstart)="autoAjuste($event)" (input)="autoAjuste($event)"
                    [ngClass]="{'errorAnimacao':verificarQuantidadeCaracteres('observacao') == false}"></textarea>
                <app-mensagem-errors [control]="obterValidacao('observacao')"></app-mensagem-errors>
            </div>
        </div>
        <div class="row">
            @if(temPermissao()){
            <div class="col-12 d-flex justify-content-around">
                @if(this.formularioProduto.get('id')!.value){
                <button class="btn btn-sm btn-save me-lg-3 col-5" (click)="this.editarProduto()">Editar</button>
                }@else {
                <button class="btn btn-sm btn-save me-lg-3 col-5" (click)="this.salvarProduto()">Salvar</button>
                }
                <button class="btn btn-sm btn-danger col-5" (click)="limparFormulario()">Limpar</button>
            </div>
            }
        </div>
    </app-container-component>
</form>