<div class="d-flex justify-content-between header align-items-center">
    <div class="ms-2">
        PRODUTOS CADASTRADOS
    </div>
    <div class="close" (click)="closeModal()">
        X
    </div>
</div>

<section class="p-2">
    <div class="mt-1 mb-2">
        <div class="input-group" [ngClass]="nomeProduto ? 'mb-1' :'mb-3'">
            <input type="text" class="form-control form-control-sm" placeholder="Produto, Cliente, Desenho" [(ngModel)]="nomeProduto"
                (change)="onChange()" (keypress)="onKeyPress($event)">
            <button class="btn btn-sm btn-outline-secondary" type="button" id="button-addon2" (click)="consultarProdutos()">
                <i class="fa-solid fa-magnifying-glass"></i>
            </button>
        </div>
    </div>
    @if(exibeAlerta){
    <div class="alert alert-danger" role="alert">
        Produto não encontrado!
    </div>
    }@else{
    <div class="table">
        <table class="table table-sm table-hover table-striped">
            <thead class="table-secondary">
                <tr>
                    <th class="text-center">FOTO</th>
                    <th>DETALHES</th>
                </tr>
            </thead>
            <tbody>
                @for (produto of produtos; track $index) {
                <tr class="c-pointer" (click)="selecionarProduto(produto)">
                    <td class="text-center">
                        @if(this.urlTemp === '/producao/book'){
                        <a [href]="url +'/'+ produto.cdProduto" rel="noopener noreferrer">
                            <div *ngIf="!produto.imagemCarregada" class="d-flex justify-content-center">
                                <app-loadCardComponent></app-loadCardComponent>
                            </div>
                            <img [src]="produto.imagem" (load)="loadingImagem(produto)"
                                [ngClass]="!produto.imagemCarregada ? 'imagem-oculta' : ''" alt="Produto">
                        </a>
                        }@else{
                        <a [href]="url+'/'+ produto.cdProduto" rel="noopener noreferrer">
                            <div *ngIf="!produto.imagemCarregada" class="d-flex justify-content-center">
                                <app-loadCardComponent></app-loadCardComponent>
                            </div>
                            <img [src]="produto.imagem" (load)="loadingImagem(produto)"
                                [ngClass]="!produto.imagemCarregada ? 'imagem-oculta' : ''" alt="Produto">
                        </a>
                        }

                    </td>
                    <td class="align-middle">
                        @if(this.urlTemp === '/producao/book'){
                        <a [href]="url +'/'+ produto.cdProduto" rel="noopener noreferrer">
                            <div class="d-flex">
                                <div class="me-4">
                                    <div>
                                        <strong>CÓDIGO: </strong>
                                    </div>
                                    <div>
                                        <strong>DESCRIÇÃO: </strong>
                                    </div>
                                    <div>
                                        <strong>CLIENTE: </strong>
                                    </div>
                                </div>
                                <div>
                                    <div>{{produto.cdProduto}}</div>
                                    <div>{{produto.nomeProduto}}</div>
                                    <div>{{produto.nomeCliente}}</div>
                                </div>
                            </div>
                        </a>
                        }@else {
                        <a [href]="url+'/'+produto.cdProduto" rel="noopener noreferrer">
                            <div class="d-flex">
                                <div class="me-4">
                                    <div>
                                        <strong>CÓDIGO: </strong>
                                    </div>
                                    <div>
                                        <strong>DESCRIÇÃO: </strong>
                                    </div>
                                    <div>
                                        <strong>CLIENTE: </strong>
                                    </div>
                                </div>
                                <div>
                                    <div>{{produto.cdProduto}}</div>
                                    <div>{{produto.nomeProduto}}</div>
                                    <div>{{produto.nomeCliente}}</div>
                                </div>
                            </div>
                        </a>
                        }
                    </td>

                </tr>
                }
            </tbody>
        </table>
    </div>
    <app-paginator (paginaMudou)="mudancaNaPagina($event)" [paginaAtual]="paginaAtual"
        [quantidadesPorPagina]="quantidadesPorPagina" [totalElementos]="totalTamanho" [totalPorPagina]="totalPorPagina">
    </app-paginator>
    }
</section>