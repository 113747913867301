import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { StatusBook } from '../../../model/statusBook';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusBookService {

  private url  = environment.url+'/engenharia';

  constructor(
    private http:HttpClient
  ) { 

  }

  public consultarStatusBook():Observable<StatusBook[]>{
    return this.http.get<StatusBook[]>(`${this.url}/status-books`)
  }
  
  public criarStatusBook(statusBook:StatusBook):Observable<StatusBook>{
    return this.http.post<StatusBook>(`${this.url}/status-books`,statusBook)
  }

  public buscarPorId(id:number):Observable<StatusBook>{
    return this.http.get<StatusBook>(`${this.url}/status-books/${id}`)
  }
  public deletarStatusBook(id:number):Observable<StatusBook>{
    return this.http.delete<StatusBook>(`${this.url}/status-books/${id}`)
  }
}
