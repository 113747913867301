<app-container-component tituloPage="Notificações do sistema" subtituloPage="Notificações">

    <div class="row justify-content-center">
        <div class="col-12">
            <span>
                <strong>Digite a mensagem para enviar</strong>
            </span>
        </div>
        <div class="col-12">
            <div class="assunto">
                <div class="header-notificaca">
                    <h4 class="mt-2">{{mensagem.assunto ? mensagem.assunto : 'Assunto'}}</h4>
                    <label><strong>Remetente: </strong>TODOS</label>
                </div>
                <div class="body-notificacao">
                    <div class="msg mt-2">
                        <!-- <p [innerHTML]="mensagem.mensagem ? formatarMensagem(mensagem.mensagem) : 'Mensagem'"></p> -->
                    </div>
                    <div class="mt-5">
                        <a href="" target="_blank">Clique aqui para visualizar</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-12 mt-2">
            <div class="alert alert-primary " role="alert">
                Conteúdo da notificação
            </div>
            <div class="col-6 mb-2">
                <input type="text" class="form-control form-control-sm" placeholder="Titulo"
                    [(ngModel)]="mensagem.assunto">
            </div>
            <div class="col-6 mb-2">
                <input type="text" class="form-control form-control-sm" placeholder="Link para encaminhar os usuários"
                    [(ngModel)]="mensagem.url">
            </div>
            <div class="col-12 ">
                <div class="mb-2 d-flex gap-2">
                    <!-- <button class="btn btn-sm" (click)="aplicarFormatacao('strong')"><strong>N</strong></button>
                    <button class="btn btn-sm" (click)="aplicarFormatacao('em')"><strong>I</strong></button>
                    <button class="btn btn-sm"><strong (click)="aplicarFormatacao('h2')">Pular linha</strong></button> -->
                </div>
                <textarea type="text" class="form-control form-control-sm" placeholder="Mensagem" #textArea
                    [(ngModel)]="mensagem.mensagem"> </textarea>
            </div>
        </div>
    </div>
</app-container-component>