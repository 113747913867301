<div class="notify-content">
    <div class="row ajuste-posicao">
        <div class="col-12 col-lg-4 limitador-tamanho">
            <div class="status-msg d-flex justify-content-between">
                <div class="nao_lida text-center" [ngClass]="{ 'selected-lida': selectedStatus === 'Todas' }"
                    (click)="selectStatus('Todas')" id="status-todas">
                    <label>Todas <i class=" ms-2 fa-solid fa-envelope-open"></i></label>
                </div>
                <div class="lida text-center" [ngClass]="{ 'selected-nao-lida': selectedStatus === 'Não lida' }"
                    (click)="selectStatus('Não lida')" id="status-nao-lida">
                    <label>Não lida <i class=" ms-2 fa-solid fa-envelope"></i></label>
                </div>
            </div>
            <div class="container-mensagem" id="container-mensagem">
                @for (notificacao of notificacoes; track $index) {
                <div class="row notificacao" (click)="visualizarMensagem(notificacao,$event)"
                    [ngClass]="notificacao.id == this.mensagem.id ? 'selected' : ''">
                    <div class="col-10 ">
                        <div class="text-truncate" [ngClass]="{ 'bold': notificacao.status === 'Não lida' }">
                            {{notificacao.remetente?.nome}}</div>
                        <div class="text-truncate">{{notificacao.assunto}}</div>
                        <div class="data">{{notificacao.dataCadastro | date : 'dd/MM/YYYY - HH:mm'}}</div>
                    </div>
                    <div class="col-2 icone-lixeira  d-flex flex-column justify-content-between align-items-center">
                        @if(notificacao.status?.toLowerCase() == 'não lida'){
                        <i class="fa-solid fa-envelope"></i>
                        }@else{
                        <i class="fa-solid fa-envelope-open"></i>
                        <i class="fa-solid fa-trash-can text-danger"
                            (click)="excluirNotificacao(notificacao.id,notificacao.assunto!)"></i>
                        }
                    </div>
                </div>
                @if(isMobile && notificacao.id == this.mensagem.id){
                    <div class="col-12 col-lg-8 d-flex  justify-content-center mb-2">
                        <div class="assunto " [@slideUpToBottom]="animationState">
                            @if(mensagem.assunto ){
                            <div class="header-notificaca">
                                <h5 class="mt-2">{{mensagem.assunto}}</h5>
                                <hr>
                                <label><strong>De: </strong>{{mensagem.remetente?.nome}} - {{mensagem.remetente?.email}}></label>
                            </div>
                            <div class="body-notificacao">
                                <div class="msg mt-2">
                                    <p [innerHTML]="mensagem.mensagem"></p>
                                </div>
                                <div class="mt-5">
                                    @if(mensagem.url){
                                    <a href="{{mensagem.url}}" target="_blank">Clique aqui para visualizar</a>
                                    }
                                </div>
                            </div>
                            }@else {
                            <span class="p-2 text-center">Selecione alguma notificação para ler</span>
                            }
                        </div>
                    </div>
                }
                }
         
            </div>
        </div>
        @if(!isMobile){
            <div class="col-12 col-lg-8 d-flex  justify-content-center ">
                <div class="assunto " [@slideUpToBottom]="animationState">
                    @if(mensagem.assunto ){
                    <div class="header-notificaca">
                        <h5 class="mt-2">{{mensagem.assunto}}</h5>
                        <hr>
                        <label><strong>De: </strong>{{mensagem.remetente?.nome}} - {{mensagem.remetente?.email}}></label>
                    </div>
                    <div class="body-notificacao">
                        <div class="msg mt-2">
                            <p [innerHTML]="mensagem.mensagem"></p>
                        </div>
                        <div class="mt-5">
                            @if(mensagem.url){
                            <a href="{{mensagem.url}}" target="_blank">Clique aqui para visualizar</a>
                            }
                        </div>
                    </div>
                    }@else {
                    <span class="p-2 text-center">Selecione alguma notificação para ler</span>
                    }
                </div>
            </div>
        }
    </div>
    <div class="position-pagination">
        <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual"
            [totalPorPagina]="totalPorPagina" [totalElementos]="totalTamanho"
            (paginaMudou)="onPageChange($event)"></app-paginator>
    </div>
</div>