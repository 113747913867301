<app-container-component [header]="false" subtituloPage="DETERMINAÇÃO FOSFATO">
    <form class="form-group  col-12" [formGroup]="formularioDeterminacaoProcessoFosfato">
        <div class="row sessao-produto delay-1">
            <!-- <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Característica:</label>
                <input type="text" formControlName="nome" class="form-control form-control-sm">
            </div> -->
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Quantidade de peças na gancheira:</label>
                <input type="number" formControlName="qtdePecasGancheira" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasGancheira')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Parâmetros da estufa:</label>
                <input type="text" formControlName="parametrosEstufa" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Amostra para inspeção:</label>
                <input type="number" formControlName="amostraInspecao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('amostraInspecao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de set-up:</label>
                <input type="number" formControlName="tempoSetup" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoSetup')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de montagem:</label>
                <input type="number" formControlName="tempoMontagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoMontagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de isenção (anterior):</label>
                <input type="number" formControlName="tempoIsencaoAnterior" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoIsencaoAnterior')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de máquina:</label>
                <input type="number" formControlName="tempoDeMaquina" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoDeMaquina')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de resfriamento:</label>
                <input type="number" formControlName="tempoResfriamento" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoResfriamento')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de desmontagem:</label>
                <input type="number" formControlName="tempoDesmontagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoDesmontagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de inspeção:</label>
                <input type="number" formControlName="tempoInspecao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoInspecao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de isenção (posterior):</label>
                <input type="number" formControlName="tempoIsencaoPosterior" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoIsencaoPosterior')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Tempo de Maquina:</label>
                <input type="number" formControlName="tempoDeMaquina" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoDeMaquina')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-2">
                <label for="text" class="form-label">Tempo de embalagem:</label>
                <input type="number" formControlName="tempoEmbalagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoEmbalagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Quantidade de peças por hora da máquina:</label>
                <input type="number" formControlName="qtdePecasHora" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasHora')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-lg-12 col-xxl-12">
                <label for="text" class="form-label">Equipamentos de Proteção:</label>
                <input type="text" formControlName="equipamentoDeProtecao" class="form-control form-control-sm">
            </div>
            <div class="col-12 mb-3">
                <label for="text" class="form-label"><span>Descrição:</span></label>
                <textarea formControlName="observacao" class="form-control form-control-sm dynamic-textarea"
                    (input)="autoGrow($event)"></textarea>
            </div>
            <div class="row col-12">
                @if(idProcesso && formularioDeterminacaoProcessoFosfato.controls['id'].value){
                <app-botao label="Editar" class="col-2 col-lg-4" icon="icons8-lápis.png" status="btn-salvar"
                    (click)="editarProcesso()"></app-botao>
                }@else {
                <app-botao label="Salvar" class="col-2 col-lg-4" icon="icons8-disquete.png" status="btn-salvar"
                    (click)="salvarProcesso()"></app-botao>
                <app-botao label="Cancelar" class="col-2 col-lg-4" icon="icons8-x.png" status="btn-cancelar"
                    (click)="limparFormulario()"></app-botao>
                }


            </div>
        </div>
    </form>
</app-container-component>