import { Component, Inject, Input, OnInit } from '@angular/core';
import { Usuario } from '../../../model/usuario/usuario';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { SnackBarService } from '../../../utils/snack-bar.service';

@Component({
  selector: 'app-redefinir-senha',
  standalone: true,
  imports: [
    MatDialogModule,
    CommonModule,
    FormsModule
  ],
  templateUrl: './redefinir-senha.component.html',
  styleUrl: './redefinir-senha.component.scss'
})
export class RedefinirSenhaComponent implements OnInit{
 
  senha: string = '';
  confirmaSenha = '';
  erro: boolean = false;
  sucesso: boolean = false;
  mensagem: string = '';
  usuario: Usuario = new Usuario();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RedefinirSenhaComponent>,
    private usuarioService: UsuarioService,
    private snackbarService: SnackBarService,
  ){
    
  }

  ngOnInit(): void {
   this.usuario = this.data;   
  }

  redefinirSenha(){
    if(this.senha.trim() === '' || this.confirmaSenha.trim() === ''){
      this.mensagem = 'As senhas não podem ser vazias!'
      this.sucesso = false;
      this.erro = true;
    }else if(this.senha.length < 6){
      this.mensagem = 'A senha precisa ter 6 ou mais caracteres!'
      this.sucesso = false;
      this.erro = true;
    }else if(this.senha !== this.confirmaSenha){
      this.mensagem = 'As senhas não são iguais!'
      this.sucesso = false;
      this.erro = true;
    }else if(this.senha === this.confirmaSenha){
      this.alterarSenha();
      this.erro = false;
      this.sucesso = true;
    }
  }

  private alterarSenha(){
    this.usuarioService.redefinirSenha(this.usuario.id, this.senha).subscribe({
      next:(res)=>{
        this.snackbarService.openSnackBar('Senho do usuário redefinida com sucesso!', 'my-snack-bar-sucess');
      },
      error:(e)=>{
        console.log(e);
      },
      complete:()=>{
        this.dialogRef.close();
      }
    });
  }

  public fechar(){
    this.dialogRef.close();
  }

}
