import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Msa } from '../../../model/engenharia/msa';
import { RegistroPaginado } from '../../../interface/registroPaginado';

@Injectable({
  providedIn: 'root'
})
export class MsaService {

  private url = environment.url + '/engenharia/msa';
  constructor(
    private http: HttpClient
  ) {

  }

  public salvarMsa(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.url}`, formData);
  }
  public alterarMsa(formData: FormData): Observable<any> {
    return this.http.put<any>(`${this.url}`, formData);
  }
  public alterarImagemTwayAnova(id: number, formData: FormData): Observable<any> {
    return this.http.put<any>(`${this.url}/img-tway-anova/${id}`, formData);
  }
  public alterarImagemEsTolerancia(id: number, formData: FormData): Observable<any> {
    return this.http.put<any>(`${this.url}/img-est-tolerancia/${id}`, formData);
  }
  public alterarImagemGageLb(id: number, formData: FormData): Observable<any> {
    return this.http.put<any>(`${this.url}/img-gage-lb/${id}`, formData);
  }
  public alterarImagemMpj(id: number, formData: FormData): Observable<any> {
    return this.http.put<any>(`${this.url}/arquivo-mpj/${id}`, formData);
  }

  public consultaPaginadaMsa(paginaInicial: number, maximoPorPagina: number): Observable<RegistroPaginado> {
    return this.http.get<RegistroPaginado>(`${this.url}/${paginaInicial}/${maximoPorPagina}`);
  }
  public consultaPorId(id: number): Observable<Msa> {
    return this.http.get<Msa>(`${this.url}/${id}`);
  }

  public deletarMsa(id: number): Observable<Msa> {
    return this.http.delete<Msa>(`${this.url}/${id}`);
  }
  public deletarImagemTwayAnova(id: number): Observable<Msa> {
    return this.http.delete<Msa>(`${this.url}/img-tway-anova/${id}`);
  }
  public deletarImagemEsTolerancia(id: number): Observable<Msa> {
    return this.http.delete<Msa>(`${this.url}/img-est-tolerancia/${id}`);
  }
  public deletarImagemGageLb(id: number): Observable<Msa> {
    return this.http.delete<Msa>(`${this.url}/img-gage-lb/${id}`);
  }
  public deletarImagemMpj(id: number): Observable<Msa> {
    return this.http.delete<Msa>(`${this.url}/arquivo-mpj/${id}`);
  }


}
