import { ActivatedRoute } from '@angular/router';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuditoriaInternaService } from '../../../../services/acoes/auditoria-interna/auditoria-interna.service';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { AuditoriaInternaConsultaDto } from '../../../../model/acoes/auditoria-Interna/AuditoriaInternaConsultaDto';
import { PaginatorSistemicoComponent } from "../../../../componentes/paginator-sistemico/paginator-sistemico/paginator-sistemico.component";
import { Apontamento } from '../../../../model/acoes/auditoria-Interna/Apontamento';

@Component({
  selector: 'app-auditoria-interna-apontamentos',
  standalone: true,
  imports: [ContainerComponentComponent, ReactiveFormsModule, CommonModule, PaginatorSistemicoComponent],
  templateUrl: './auditoria-interna-apontamentos.component.html',
  styleUrl: './auditoria-interna-apontamentos.component.scss'
})
export class AuditoriaInternaApontamentosComponent implements OnInit {

  apontamentoForm!: FormGroup;
  idAuditoria: number = 0;
  novoApontamento: any;
  itensPaginados: Apontamento[] = [];
  auditoria: AuditoriaInternaConsultaDto = new AuditoriaInternaConsultaDto();

  private formBuilder = inject(FormBuilder);
  private formValidacaoService = inject(FormValidacaoService);
  private auditoriaInternaService = inject(AuditoriaInternaService);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private activatedRoute = inject(ActivatedRoute);



  ngOnInit(): void {
    this.verificarRota();
  }

  public verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idAuditoria = res.id;
        this.consultaAponetamentos();
      }
    });
  }

  public iniciarFormulario() {
    this.apontamentoForm = this.formBuilder.group({
      apontamento: [],
      requisitoNormativo: [],
      evidenciaObjetiva: []
    })
  }

  public salvarApontamento() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.auditoriaInternaService.cadastrarApontamentoAuditoria(this.apontamentoForm.value, this.idAuditoria).subscribe({
      next: (res) => {

      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.consultaAponetamentos();
      }
    });
  }

  public consultaAponetamentos() {
    this.emisorEventoGeralService.carregando.emit(true);
    this.auditoriaInternaService.consultarAuditoriaPorId(this.idAuditoria).subscribe({
      next: (res) => {
        this.auditoria = res;
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
      }, complete: () => {
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }

  public receberItensPaginados(itensPaginadosRecebidos: Apontamento[]) {
    this.itensPaginados = itensPaginadosRecebidos
  }




  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.apontamentoForm, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.apontamentoForm, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.apontamentoForm, nome);
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }
}
