import { IndicadoresObjQualidadeService } from './../../../../services/indicadores/indicadoresObjQualidade.service';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { IndicadoresObjQualidade } from '../../../../model/indicadores/indicadoresObjQualidade/IndicadoresObjQualidade';
import moment from 'moment';
import { Router } from '@angular/router';
import { LoginService } from '../../../../services/login/login.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';

@Component({
  selector: 'app-indicador-obj-qualidade-list',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent],
  templateUrl: './indicador-obj-qualidade-list.component.html',
  styleUrl: './indicador-obj-qualidade-list.component.scss'
})
export class IndicadorObjQualidadeListComponent implements OnInit {
  titulo = "LISTA INDICADORES OBJETIVO DA QUALIDADE";
  nomeFormulario = "INDICADORES";
  listaDeIndicadores: IndicadoresObjQualidade[] = []

  paginaAtual: number = 1;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  anoAtual = moment().format("yyyy");
  private loginService = inject(LoginService);
  private router = inject(Router);
  private indicadoresObjQualidadeService = inject(IndicadoresObjQualidadeService);
  private snackbarService = inject(SnackBarService);
  token: any = '';

  constructor(
    
  ) {

  }

  ngOnInit(): void {
    this.consultaIndicadores();
  }

  public consultaIndicadores() {
    this.indicadoresObjQualidadeService.consultaTodosIndicadoresObjQualidade().subscribe({
      next: (res) => {
        this.listaDeIndicadores = res;
      }, error: (error) => {
        this.snackbarService.tratarErro(error, 'Falha ao consultar indicadores!');
      }, complete: () => {

      }
    });
  }

  public temPermissao(permissao: string):boolean{
    return this.loginService.temPermissao([permissao]);
  } 

  public editarIndicador(id: number) {
    this.router.navigate([`indicadores/obj-qualidade-cadastro/${id}`])
  }

  public gerenciarIndicador(id: number) {
    this.router.navigate([`indicadores/indicador-obj-qualidade-resultado/${id}`])
  }

  public mudancaNaPagina(evento: any) {
    this.paginaAtual = evento.pageIndex;
    this.totalPorPagina = evento.pageSize;
    this.consultaIndicadores();
  }

}
