import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadCardComponent',
  standalone:true,
  imports:[CommonModule],
  templateUrl: './loadCardComponent.component.html',
  styleUrls: ['./loadCardComponent.component.scss']
})
export class LoadCardComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
