<app-container-component [tituloPage]="titulo" [desativarSubTitulo]="false" alinharAocentro="align-items-center">
    <app-container-component [header]="false" subtituloPage="Pesquisar por setor e ano">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label m-0">Setores:</label>
                <div class="input-group mb-3">
                    <select class="form-select form-select-sm" [(ngModel)]="setorSelecionado">
                        <option value="" disabled selected>Selecione um setor</option>
                        <option>Todos</option>
                        @if(setores.length > 0){
                        <!-- @for (setor of setores; track $index) {
                        <option>{{setor.setor}}</option>
                        } -->
                        }
                    </select>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                <label for="text" class="form-label m-0">Ano</label>
                <div class="input-group mb-3">
                    <select class="form-select form-select-sm" [(ngModel)]="anoSelecionado">
                        <option value="" disabled selected>Selecione um anos</option>
                        @if(anos.length > 0){
                        @for (ano of anos; track $index) {
                        <option>{{ano}}</option>
                        }
                        }
                    </select>
                    <a class="btn btn-sm btn-light" (click)="consultarPorSetorEAno()">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </a>
                </div>
            </div>
        </div>
    </app-container-component>
    @if(listaDeIndicadores.length > 0){
    <div class="row">
        @for (item of this.listaDeIndicadores; track $index) {
        <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-5">
            <div class="mb-3 titulo-card">
                <h6>
                    <p class="p-0 m-0">{{item.indicador}}</p>
                </h6>
            </div>
            <div class="col-12  d-flex gap-2 justify-content-end">
                @if(token.nome === item.responsavel || token.permissoes.includes('ROLE_ADMIN') ||
                token.permissoes.includes('ROLE_IND_ADMIN')){
                <i class="fa-solid fa-pencil" (click)="editarIndicador(item.id)">
                    <span>Editar</span>
                </i>
                <i class="fa-solid fa-gear" (click)="gerenciarIndicador(item.id)">
                    <span>Gerenciar</span>
                </i>
                }
            </div>
            <div class="d-flex align-items-center justify-content-between col-12">
                <div class="col-2 col-lg-2 d-flex flex-column justify-content-center align-items-center">
                    @if(item.tipo == "Negativo"){
                    <i class="fa-solid fa-circle-arrow-down"></i>
                    <p class="text">{{item.tipo}}</p>
                    }@else {
                    <i class="fa-solid fa-circle-arrow-up"></i>
                    <p class="text">{{item.tipo}}</p>
                    }
                    @if(item.tipo == "Positivo"){
                    @if(item.resultados[item.resultados.length - 1].media >= item.resultados[item.resultados.length
                    -
                    1].metaAno){
                    <img class="icon" src="assets/icons/64x64/analise.png" alt="">
                    }@else {
                    <img class="icon" src="assets/icons/64x64/negativo.png" alt="">
                    }
                    }@if(item.tipo == "Negativo"){
                    @if(item.resultados[item.resultados.length - 1].media <= item.resultados[item.resultados.length -
                        1].metaAno){ <img class="icon" src="assets/icons/64x64/analise.png" alt="">
                        }@else {
                        <img class="icon" src="assets/icons/64x64/negativo.png" alt="">
                        }
                        }
                </div>
                <div class="col-10">
                    <div class="grafico">
                        <div class="descricaoChart">
                            <div class="d-flex align-items-center gap-2">
                                <div class="chart-description-a"></div><b>Meta {{this.anoAtual}}.</b>
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <div class="chart-description-g"></div><b>Resultado {{this.anoAtual}}.</b>
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <div class="chart-description-ac"></div><b>Resultado {{this.anoPassado}}.</b>
                            </div>
                        </div>
                        <div class="chart">
                            <canvas [id]="'myChart' + item.id"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>

    <app-paginator (paginaMudou)="receberMudanca($event)" [paginaAtual]="paginaAtual"
        [quantidadesPorPagina]="quantidadesPorPagina" [totalElementos]="totalTamanho" [totalPorPagina]="totalPorPagina">
    </app-paginator>
    }@else {
    <div class="text-center">
        Não há graficos cadastrados neste ano {{anoAtual}}
    </div>
    }
</app-container-component>