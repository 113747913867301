import { Routes } from "@angular/router";
import { GestaoDeMelhoriasListaComponent } from "../../paginas/gestao/gestao-de-melhorias/gestao-de-melhorias-lista/gestao-de-melhorias-lista.component";
import { GestaoMudancaListarComponent } from "../../paginas/gestao/gestao-de-mudancas/gestao-mudanca-listar/gestao-mudanca-listar.component";
import { GestaoDeFornecedoresListaComponent } from "../../paginas/gestao/gestao-de-fornecedores/gestao-de-fornecedores-lista/gestao-de-fornecedores-lista.component";


export const routesGestaoView: Routes = [
    { path: "gestao-melhoria", component: GestaoDeMelhoriasListaComponent },
    { path: "gestao-melhoria-view/:id", loadComponent: () => import('../../paginas/gestao/gestao-de-melhorias/gestao-de-melhorias-visualizar/gestao-de-melhorias-visualizar.component').then(g => g.GestaoDeMelhoriasVisualizarComponent) },

    { path: "gestao-mudanca", component: GestaoMudancaListarComponent },
    { path: "gestao-mudanca-itens/:id", loadComponent: () => import('../../paginas/gestao/gestao-de-mudancas/gestao-mudanca-itens/gestao-mudanca-itens.component').then(g => g.GestaoMudancaItensComponent) },

    { path: "gestao-fornecedor", component: GestaoDeFornecedoresListaComponent },
    { path: "gestao-fornecedor-view/:id", loadComponent: () => import('../../paginas/gestao/gestao-de-fornecedores/gestao-de-fornecedores-view/gestao-de-fornecedores-view.component').then(g => g.GestaoDeFornecedoresViewComponent) },

]