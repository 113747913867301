import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ListarDispositivosComponent } from '../listar-dispositivos/listar-dispositivos.component';
import { DispositivoIsencao } from '../../../model/dispositivos/dipositivoIsencao';
import { DispositivoIsencaoService } from '../../../services/dispositivos/dispositivo-isencao.service';
import { PageEvent } from '@angular/material/paginator';
import { BotaoComponent } from "../../../componentes/botao/botao.component";
import { RouterLink } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { Usuario } from '../../../model/usuario/usuario';
import { FormsModule } from '@angular/forms';
import { SnackBarService } from '../../../utils/snack-bar.service';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";
import { BoundElementProperty } from '@angular/compiler';

@Component({
  selector: 'app-dispositivos-isencao-lista',
  standalone: true,
  imports: [CommonModule, ListarDispositivosComponent, RouterLink, FormsModule, ContainerComponentComponent],
  templateUrl: './dispositivos-isencao-lista.component.html',
  styleUrl: './dispositivos-isencao-lista.component.scss'
})
export class DispositivosIsencaoListaComponent implements OnInit {

  mostrarBolas = false;
  dispositivosIsencao: DispositivoIsencao[] = [];
  listaImagens: any[] = []
  urlTipo = "dispositivo-isencao";
  idDispositivoIsencao: number = 0;
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [8, 16, 24, 32, 56]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  renderImagemDispositivos: any[] = [];
  loginService = inject(LoginService);
  usuario = new Usuario();
  nomeDispositivo: string = '';
  dispositivoIsencaoService = inject(DispositivoIsencaoService);
  snackBarService = inject(SnackBarService);
  exibirPaginacao: boolean = true;
  naoEncontrado: boolean = false;

  constructor() {
  
  }

  ngOnInit(): void {
    this.consultarDispositivos();
  }

  public consultarDispositivos() {
    this.dispositivoIsencaoService.consultarTodasDispositivoIsencao(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res: any) => {
        this.dispositivosIsencao = res.content;
        this.totalTamanho = res.page.totalElements
      }, error: (error) => {
        this.snackBarService.tratarErro(error, 'Falha ao consultar dispositivos!');
      }
    });
  }

  public preparaParaExibirImagens() {
    this.renderImagemDispositivos = [];
    let listaDeImagens: any[] = []
    this.dispositivosIsencao.forEach((e: any) => {
      listaDeImagens.push(e.imagens);
    });
  }

  public consultaDispositivoPorNome(){
   if(this.nomeDispositivo == ''){
    this.consultarDispositivos();
   }else{
    this.dispositivoIsencaoService.consultarDispositivoPorNome(this.nomeDispositivo).subscribe({
      next:(res)=>{
        this.dispositivosIsencao = res;
        this.exibirPaginacao = false;
      },
      error:(e)=>{
        this.snackBarService.tratarErro(e, 'Falha ao consultar dispositivo por nome');
      }
    });
   }
  }

  public deletarDispositivoIsencao() {
    this.dispositivoIsencaoService.deletarDispositivoIsencao(this.idDispositivoIsencao).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar('Dispositivo de insenção removido com sucesso!', 'my-snack-bar-sucess');
      }, error: (error) => {
        this.snackBarService.tratarErro(error, 'Falha ao deletar dispositivo!');
      }, complete: () => {
        this.consultarDispositivos();
      }
    })
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultarDispositivos()
  }

  public exclusao(dados: any) {
    this.idDispositivoIsencao = dados.id
    this.deletarDispositivoIsencao();
  }

  public openClose(){
    this.mostrarBolas = !this.mostrarBolas;
  }

  public buscar(event?: any) {
    if (!event || event.key === "Enter") {
     this.consultaDispositivoPorNome();
    }
  }
}
