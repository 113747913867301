import { ChangeDetectorRef, Component, inject, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { LoginService } from '../../../services/login/login.service';
import { SnackBarService } from '../../../utils/snack-bar.service';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-menu-manutencao',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, RouterOutlet, MatSidenavModule],
  templateUrl: './menu-manutencao.component.html',
  styleUrl: './menu-manutencao.component.scss'
})
export class MenuManutencaoComponent {


  open: boolean = true;
  mostrarMenu = false;
  menuFixo = true;
  menusVisiveis: { [key: string]: boolean } = {};
  @ViewChild('drawer') drawer!: MatDrawer;
  isMobile = window.innerWidth < 1000;
  cdr = inject(ChangeDetectorRef);
  snackBarService = inject(SnackBarService)
  loginService = inject(LoginService);

  constructor() {

  }

  ngAfterViewInit(): void {
    this.restaurarEstadoMenu();
    this.verificarSeEhMobile();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.restaurarEstadoMenu();
  }

  private restaurarEstadoMenu() {
    this.menuFixo = JSON.parse(localStorage.getItem("menuFixado")! || 'false');
    if (this.drawer) {
      if (this.menuFixo) {
        this.drawer.open();
        this.open = false;
      }
    }
  }

  public verificarSeEhMobile() {
    this.isMobile = window.innerWidth <= 1000;
    if (this.isMobile) {
      localStorage.setItem("menuFixado", "false");
      this.open = true;
      this.menuFixo = false;
    }
  }
  
  public getMenuManutencao() {
    if (this.loginService.temPermissao(['ROLE_MANUT_ADMIN'])) {
      return [
        { label: 'Consultar OS', link: ['manutencao'] },
        { label: 'Equipamentos', link: ['equipamento'] },
        { label: 'Painel gerencial', link: ['painel-gerencial'] },
      ];
    } else {
      return [
        { label: 'Consultar OS', link: ['manutencao'] },
      ];
    }
  }

  
  public mudarIcone() {
    if (!this.menuFixo) {
      this.open = !this.open
    }
  }

  abrirMenu(menu: string) {
    // Alterna o estado do menu clicado
    if (this.menusVisiveis[menu]) {
      this.menusVisiveis[menu] = false;
    } else {
      // Fecha todos os menus
      Object.keys(this.menusVisiveis).forEach(key => {
        this.menusVisiveis[key] = false;
      });
      // Abre o menu clicado
      this.menusVisiveis[menu] = true;
    }
    // this.menusVisiveis[menu] = !this.menusVisiveis[menu];
  }

  fixarMenu(): void {
    this.menuFixo = !this.menuFixo; // Alterna o estado do menu fixo
    localStorage.setItem("menuFixado", this.menuFixo.toString());
  }

  clicarMenu(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    }
  }

  clicarFechar(drawer: any): void {
    if (!this.menuFixo) {
      drawer.toggle(); // Só executa o toggle se o menu não estiver fixado
    } else {
      this.snackBarService.openSnackBar("Para recolher o menu você precisa clicar no icone desfixar menu", "my-snack-bar-warning")
    }
  }
}
