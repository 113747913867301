import { Injectable } from '@angular/core';
import { StatusDesenvolvimento } from '../../../model/statusDensevolvimento';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StatusDesenvolvimentoService {
  
  private url  = environment.url+'/engenharia';

  constructor(
    private http:HttpClient
  ) { 

  }

  public consultarStatusDesenvolvimento():Observable<StatusDesenvolvimento[]>{
    return this.http.get<StatusDesenvolvimento[]>(`${this.url}/status-desenvolvimentos`)
  }
  
  public criarStatusDesenvolvimento(statusDesenvolvimento:StatusDesenvolvimento):Observable<StatusDesenvolvimento>{
    return this.http.post<StatusDesenvolvimento>(`${this.url}/status-desenvolvimentos`,statusDesenvolvimento)
  }

  public buscarPorId(id:number):Observable<StatusDesenvolvimento>{
    return this.http.get<StatusDesenvolvimento>(`${this.url}/status-desenvolvimentos/${id}`)
  }

  public deletarStatusDesenvolvimento(id:number):Observable<StatusDesenvolvimento>{
    return this.http.delete<StatusDesenvolvimento>(`${this.url}/status-desenvolvimentos/${id}`)
  }
}
