import { IndicadoresObjQualidadeService } from './../../../../services/indicadores/indicadoresObjQualidade.service';
import { AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import moment from 'moment';
import { IndicadoresObjQualidade } from '../../../../model/indicadores/indicadoresObjQualidade/IndicadoresObjQualidade';
import { ChartData, ChartItem } from 'chart.js';
import { Chart } from 'chart.js/auto';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormsModule } from '@angular/forms';
import { IndicadoresTratamentoService } from '../../../../services/indicadores/indicadoresTratamento.service';
import { IndicadoresObjQualidadeResultado } from '../../../../model/indicadores/indicadoresObjQualidade/indicadoresObjQualidadeResultado';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-indicador-obj-qualidade-home',
  standalone: true,
  imports: [CommonModule, PaginatorComponent, ContainerComponentComponent, FormsModule],
  templateUrl: './indicador-obj-qualidade-home.component.html',
  styleUrl: './indicador-obj-qualidade-home.component.scss'
})
export class IndicadorObjQualidadeHomeComponent implements OnInit, AfterViewInit,OnDestroy {
  anoAtual = moment().format('yyyy');
  anoPassado = moment().subtract(1, 'year').format('yyyy');
  anoSelecionado: string = moment().format('yyyy');

  anosLista: any[] = [];
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  resultadoAnos: any[] = [];
  metaAno: any[] = [];
  titulo: string = `<i class='fa-solid fa-chart-simple'></i> Indicadores objetivos da qualidade ${this.anoAtual}`
  listaResultado = [{ meta: 0, resultado: 0 }]
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100]
  totalPorPagina: number = this.quantidadesPorPagina[0];
  listaDeIndicadores: IndicadoresObjQualidade[] = [];
  private loginService = inject(LoginService);

  setores: any[] = [];
  anos: any[] = [];

  setorSelecionado: string = "Todos";
  private chartInstances: { [key: string]: Chart } = {};

  constructor(
    private indicadoresObjQualidadeService: IndicadoresObjQualidadeService,
    private cdr: ChangeDetectorRef,
    private indicadoresTratamentoService: IndicadoresTratamentoService,
    private router: Router
  ) {

  }

  ngOnDestroy(): void {
    this.destruirGraficos();
  }
  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.criarListaDeAnos();
    this.consultaObetivosDaQualidade();
  }

  public temPermissao(permissao:string[]):boolean{
    return this.loginService.temPermissao(permissao);
  } 

  public consultaObetivosDaQualidade() {
    this.indicadoresObjQualidadeService.consultaTodosIndicadoresObjQualidade().subscribe({
      next: (res) => {
        this.listaDeIndicadores = res;
        console.log(res)
      }, error: (error) => {

      }, complete: () => {
        this.cdr.detectChanges();
        this.montarGraficos();
      }
    });
  }



  public montarGraficos() {
    this.listaDeIndicadores.forEach((ind, index) => {
      const { id, tipo, objetivo, resultados } = ind;
      if (resultados && resultados.length > 0) {
        const chartElement = document.getElementById(`myChart${id}`);
        if (chartElement) {
          this.tratarCorGrafico(tipo, resultados);
          this.chartInstances[id] = new Chart(chartElement as ChartItem, {
            type: 'bar',
            data: this.getChartData(objetivo),
            options: {
              scales: {
                y: {
                  type:'linear',
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  usePointStyle: true,
                  callbacks: {
                    labelPointStyle: function (context) {
                      return {
                        pointStyle: 'triangle',
                        rotation: 0
                      };
                    }
                  }
                }
              },
              responsive: true, // Garante que o gráfico seja responsivo
              maintainAspectRatio: false, // Permite que o gráfico preencha todo o contêiner
            },

          });
        }
      }
    });
  }


  private getChartData(label: string): ChartData<'bar' | 'line'> {
    return {
      labels: this.anosLista,
      datasets: [
        {
          type: 'line',
          label: 'Meta',
          borderColor: "blue",
          backgroundColor: "#c6c6c6",
          data: this.metaAno.map(m => m),
        },
        {
          label: `${label}`,
          backgroundColor: this.resultadoAnos.map(r => r.cor),
          data: this.resultadoAnos.map(r => r.mes),
          borderWidth: 1
        },
      ],
    };
  }


  private tratarCorGrafico(tipoGrafico: string, resultados: any[]) {
    this.anosLista = [];
    this.resultadoAnos = [];
    this.metaAno = [];
    resultados.forEach((result: IndicadoresObjQualidadeResultado) => {
      this.metaAno.push(result.meta);
      this.anosLista.push(result.ano);
      if (tipoGrafico === "Positivo") {
        result.resultado > result.meta ? this.resultadoAnos.push({ mes: result.resultado, cor: 'green' }) : this.resultadoAnos.push({ mes: result.resultado, cor: 'red' });
      } else {
        result.resultado <= result.meta ? this.resultadoAnos.push({ mes: result.resultado, cor: 'green' }) : this.resultadoAnos.push({ mes: result.resultado, cor: 'red' });
      }
    });

  }


  private destruirGraficos() {
    Object.keys(this.chartInstances).forEach((key) => {
      this.chartInstances[key].destroy();
      delete this.chartInstances[key]; // Remove a referência do gráfico destruído
    });
  }


  public receberMudanca(event: any) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultaObetivosDaQualidade();
  }

  public criarListaDeAnos() {
    this.anos = this.indicadoresTratamentoService.criarListaDeAnos();
  }
  public editarIndicador(id: number) {
    this.router.navigate([`/indicadores/obj-qualidade-cadastro/${id}`])
  }
  public gerenciarIndicador(id: number) {
    this.router.navigate([`/indicadores/indicador-obj-qualidade-resultado/${id}`])
  }
}
