import { Routes } from "@angular/router";
import { authGuard } from "../auth/auth-guard.guard";
import { verificarPermissoesGuard } from "../auth/verificar-permissoes.guard";

export const routesAcoes: Routes = [

    { path: "informacao-documentada", loadComponent: () => import('../paginas/acoes/informacao-documentada/informacao-documentada-lista/informacao-documentada-lista.component').then(i => i.InformacaoDocumentadaListaComponent) },
    { path: "informacao-documentada-gerenciar", loadComponent: () => import('../paginas/acoes/informacao-documentada/informacao-documentada-gerenciar/informacao-documentada-gerenciar.component').then(i => i.InformacaoDocumentadaGerenciarComponent) },
    { path: "informacao-documentada-gerenciar-update/:id", loadComponent: () => import('../paginas/acoes/informacao-documentada/informacao-documentada-gerenciar-informacao-documentada/informacao-documentada-gerenciar-informacao-documentada.component').then(i => i.InformacaoDocumentadaGerenciarInformacaoDocumentadaComponent) },
    { path: "informacao-documentada-aprovacao/:valor", loadComponent: () => import('../paginas/acoes/informacao-documentada/informacao-documentada-aprovacao/informacao-documentada-aprovacao.component').then(a => a.InformacaoDocumentadaAprovacaoComponent) },
    { path: "informacao-documentada-historico/:id", loadComponent: () => import('../paginas/acoes/informacao-documentada/informacao-documentada-historico/informacao-documentada-historico.component').then(h => h.InformacaoDocumentadaHistoricoComponent) },
    { path: "informacao-documentada-registrar", loadComponent: () => import('../paginas/acoes/informacao-documentada/informacao-documentada-registrar/informacao-documentada-registrar.component').then(r => r.InformacaoDocumentadaRegistrarComponent) },

    { path: "plano-de-risco", loadComponent: () => import('../paginas/acoes/plano-de-risco/plano-de-risco-listar/plano-de-risco-listar.component').then(i => i.PlanoDeRiscoListarComponent) },
    { path: "plano-de-risco-acoes/:id", loadComponent: () => import('../paginas/acoes/plano-de-risco/plano-de-risco-acoes-lista/plano-de-risco-acoes-lista.component').then(i => i.PlanoDeRiscoAcoesListaComponent) },
    { path: "plano-de-risco-gerenciar/:idPlano", loadComponent: () => import('../paginas/acoes/plano-de-risco/plano-de-risco-editar/plano-de-risco-editar.component').then(i => i.PlanoDeRiscoEditarComponent) },
    { path: "plano-de-risco-gerenciar/:idPlano/:idAcao", loadComponent: () => import('../paginas/acoes/plano-de-risco/plano-de-risco-editar/plano-de-risco-editar.component').then(i => i.PlanoDeRiscoEditarComponent) },
    { path: "plano-de-risco-historico/:id", loadComponent: () => import('../paginas/acoes/plano-de-risco/plano-de-risco-historico/plano-de-risco-historico.component').then(h => h.PlanoDeRiscoHistoricoComponent) },
    { path: "plano-de-risco-registrar", loadComponent: () => import('../paginas/acoes/plano-de-risco/plano-de-risco-registrar/plano-de-risco-registrar.component').then(r => r.PlanoDeRiscoRegistrarComponent) },
    { path: "plano-de-risco-registrar/:id", loadComponent: () => import('../paginas/acoes/plano-de-risco/plano-de-risco-registrar/plano-de-risco-registrar.component').then(r => r.PlanoDeRiscoRegistrarComponent) },
]
