import { Component, OnInit } from '@angular/core';
import { EquipamentoService } from '../../../../../services/acoes/manutencao/equipamento.service';
import { Equipamento } from '../../../../../model/acoes/equipamento/manutencao-equipamento';
import { ManutencaoOsCadastroDTO } from '../../../../../model/acoes/ordem-servico/ordem-servico-cadastro';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SnackBarService } from '../../../../../utils/snack-bar.service';
import { OrdemServicoService } from '../../../../../services/acoes/manutencao/ordem-servico.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectComponentComponent } from "../../../../../componentes/select-component/select-component.component";

@Component({
  selector: 'app-ordem-servico-cadastro',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SelectComponentComponent
],
  templateUrl: './ordem-servico-cadastro.component.html',
  styleUrl: './ordem-servico-cadastro.component.scss'
})
export class OrdemServicoCadastroComponent implements OnInit {


  equipamentos: Equipamento[] = [];
  osCadastro: ManutencaoOsCadastroDTO;

  constructor(
    private equipamentoService: EquipamentoService,
    private snackbarService: SnackBarService,
    private ordemServicoService: OrdemServicoService,
    private matdialog: MatDialog
  ) {
    this.osCadastro = new ManutencaoOsCadastroDTO();
  }

  ngOnInit(): void {
    this.consultaEquipamentos();
  }

  public consultaEquipamentos() {
    this.equipamentoService.consultaTodosEquipamentos().subscribe({
      next: (res) => {
        this.equipamentos = res;
      },
      error:(e)=>{
        console.log(e);
      }
    });
  }

  public receberConteudoSelecionado(valorSelecionado:any){
    this.osCadastro.idEquipamento = valorSelecionado.id;
  }

  public cadastrar() {
    if (this.validar()) {
      this.ordemServicoService.cadastrarOrdemDeServico(this.osCadastro).subscribe({
        next: (res) => {
          OrdemServicoService.emitirCadastro.emit(true);
          this.snackbarService.openSnackBar('Ordem de serviço cadastrada com sucesso!','my-snack-bar-sucess' )
        },
        error:(e)=>{
          this.snackbarService.tratarErro(e, 'Não foi possível cadastrar a OS!');
        },
        complete:()=>{
          this.osCadastro = new ManutencaoOsCadastroDTO();
        }
        
      });
    } else {
      this.snackbarService.openSnackBar('Formulario inválido!', 'my-snack-bar-erro');
    }
  }

  public validar(): boolean {
    if (this.osCadastro.idEquipamento === undefined || this.osCadastro.prioridade === undefined || this.osCadastro.prioridade === undefined || this.osCadastro.solicitacao === undefined) {
      return false;
    }
    return true;
  }

}
