<app-container-component tituloPage="Certificado" [subtituloPage]="'Certificado e-cnpj A1'">

    <div class="separador mt-3 mb-3">
        <strong>Certificado cadastrado</strong>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="table">
                <table class="table tabl-sm table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Empresa</th>
                            <th>CNPJ</th>
                            <th>Certificado</th>
                            <th>Data expiração</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{certificado.empresa}}</td>
                            <td>{{certificado.cnpj | cnpj}}</td>
                            <td class="align-middle">{{certificado.nome}}</td>
                            <td class="align-middle">{{certificado.dataExpiracao | date: 'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="separador mt-3 mb-3">
        <strong>Cadastrar certificado</strong>
    </div>

    <section>
        <div class="row">
            <div class="col-12 col-lg-6">
                <label for="nome_certificado" class="form-label">Senha</label>
                <input id="nome_certificado" class="form-control" type="password" [(ngModel)]="senha">
            </div>
            <div class="col-12 col-lg-6">
                <label for="nome_certificado" class="form-label">Arquivo</label>
                <input id="nome_certificado" #fileInput class="form-control" type="file"
                    (change)="onFileChange($event)">
            </div>
            <div class="col-12 col-lg-4 mt-3">
                <button class="btn btn-sm" (click)="cadastrarCertificado(fileInput)">Salvar</button>
            </div>
        </div>
    </section>
</app-container-component>