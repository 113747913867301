import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { GestaoMudancaService } from '../../../../services/gestao/gestao-mudanca/gestao-mudanca.service';
import GestaoMudanca from '../../../../model/gestao/gestao-mudanca/GestaoMudanca';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestao-mudanca-listar',
  standalone: true,
  imports: [CommonModule,ContainerComponentComponent, PaginatorComponent],
  templateUrl: './gestao-mudanca-listar.component.html',
  styleUrl: './gestao-mudanca-listar.component.scss'
})
export class GestaoMudancaListarComponent implements OnInit {

  gestaoMudancaService = inject(GestaoMudancaService);
  router = inject(Router);
  listaDeGestaoDeMudanca: GestaoMudanca[] = [];
  paginaAtual = 0;
  quantidadePorPagina: any[] = [10, 20, 40, 80];
  totalPorPagina: number = this.quantidadePorPagina[0];
  tamanhoTotal: number = 0


  ngOnInit(): void {
    this.consultaPaginadaGestaoMudanca();
  }


  private consultaPaginadaGestaoMudanca() {
    this.gestaoMudancaService.consultarGestaoDeMudancaPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res) => {
        this.listaDeGestaoDeMudanca = res.content;
        this.processarElementosPagina(res.page)
      }, error: (error) => {

      }, complete: () => {

      }
    })
  }


  private processarElementosPagina(page: any) {
    this.paginaAtual = page.number;
    this.tamanhoTotal = page.totalElements;
  }



  mudancaDePagina(evento: PageEvent) {
    this.paginaAtual = evento.pageIndex;
    this.totalPorPagina = evento.pageSize;
    this.consultaPaginadaGestaoMudanca();
  }

  public criarItens(id:number){
    this.router.navigate([`gestao/gestao-mudanca-itens/${id}`])
  }




}
