import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Produto } from '../../../../model/engenharia/produto-model';
import { EngPadraoEmbalagemService } from '../../../../services/engenharia/processo/eng-padrao-embalagem.service';
import { BookService } from '../../../../services/producao/book.service';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { CommonModule } from '@angular/common';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";

@Component({
  selector: 'app-padrao-embalagem',
  standalone: true,
  imports: [
    CommonModule,
    SwiperComponentComponent
  ],
  templateUrl: './padrao-embalagem.component.html',
  styleUrl: './padrao-embalagem.component.scss'
})
export class PadraoEmbalagemComponent implements OnInit {
  padraoEmbalagemProcesso1: any = undefined;
  padraoEmbalagemProcesso2: any = undefined;
  imagemNaoCadastrada1 = false;
  imagemNaoCadastrada2 = false;
  produto: Produto = new Produto();
  dados: any = {};
  tabAtiva: string = '';
  @ViewChild("swiperPadraoMontagemEmbalagem1") swiperPadraoMontagemEmbalagem1!: ElementRef;
  @ViewChild("swiperPadraoMontagemEmbalagem2") swiperPadraoMontagemEmbalagem2!: ElementRef;
  swiper?: Swiper

  constructor(
    private padraoEmbalagemService: EngPadraoEmbalagemService,
    private bookService: BookService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }

  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.padraoEmbalagemProcesso1 = undefined;
        this.padraoEmbalagemProcesso2 = undefined;
        this.produto = res;
        this.tabAtiva = '';
        if (this.produto.nomeProduto) {
          this.dados = this.bookService.verificarProcesso(this.produto.processo);
          this.consultarPadraoEmbalagemProcesso1Inicial(this.dados.numeroProcesso1);
        }
      },
    });
  }

  consultarPadraoEmbalagemProcesso1Inicial(processo: any) {
    this.imagemNaoCadastrada1 = false;
    this.padraoEmbalagemProcesso1 = undefined;
    this.padraoEmbalagemService.consultarPadraoEmbalagem(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoEmbalagemProcesso1 = res;
      }, error: (e) => {
        this.imagemNaoCadastrada1 = true;
        this.consultarPadraoEmbalagemProcesso2Inicial(this.dados.numeroProcesso2);
      },
      complete: () => {
        this.consultarPadraoEmbalagemProcesso2Inicial(this.dados.numeroProcesso2);
        this.definirTabAtiva();
        this.iniciarImagemCarregada()
      },

    });
  }


  consultarPadraoEmbalagemProcesso2Inicial(processo: any) {
    this.imagemNaoCadastrada2 = false;
    this.padraoEmbalagemProcesso2 = undefined;
    this.padraoEmbalagemService.consultarPadraoEmbalagem(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoEmbalagemProcesso2 = res;
      }, error: (e) => {
        this.imagemNaoCadastrada2 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2()

      },
    });
  }

  consultarPadraoEmbalagemProcesso1Click(processo: any) {
    this.imagemNaoCadastrada1 = false;
    this.padraoEmbalagemProcesso1 = undefined;
    this.padraoEmbalagemService.consultarPadraoEmbalagem(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoEmbalagemProcesso1 = res;
      }, error: (e) => {
        this.imagemNaoCadastrada1 = true;
      },
      complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada()
      },

    });
  }
  consultarPadraoEmbalagemProcesso2Click(processo: any) {
    this.imagemNaoCadastrada2 = false;
    this.padraoEmbalagemProcesso2 = undefined;
    this.padraoEmbalagemService.consultarPadraoEmbalagem(this.produto.cdProduto, processo).subscribe({
      next: (res) => {
        this.padraoEmbalagemProcesso2 = res;
      }, error: (e) => {
        this.imagemNaoCadastrada2 = true;
      }, complete: () => {
        this.definirTabAtiva();
        this.iniciarImagemCarregada2()
      },
    });
  }

  private iniciarImagemCarregada() {
    this.iniciarImagemCarregadaParaArray(this.padraoEmbalagemProcesso1);
  }

  private iniciarImagemCarregada2() {
    this.iniciarImagemCarregadaParaArray(this.padraoEmbalagemProcesso2);
  }

  private iniciarImagemCarregadaParaArray(array: any[]) {
    if (array.length > 0) {
      array.forEach((e: any) => {
        if (!e.imagemCarregada) {
          e.imagemCarregada = false;
        }
      });
    }

  }

  private definirTabAtiva() {
    if (this.padraoEmbalagemProcesso1) {
      this.tabAtiva = 'processo1';
      return;
    } if (this.padraoEmbalagemProcesso2) {
      this.tabAtiva = 'processo2';
    }
  }

}
