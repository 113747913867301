import { Component, inject, OnInit } from '@angular/core';
import { ContainerComponentComponent } from '../../../../componentes/container-component/container-component.component';
import { ManuaisClientesService } from '../../../../services/controles/manuais-clientes/manuais-clientes.service';
import { ManualClienteConsulta } from '../../../../model/controles/manuais-clientes/manual-cliente-consulta';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { Router } from '@angular/router';
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-manual-cliente-consulta',
  standalone: true,
  imports: [
    ContainerComponentComponent,
    CommonModule,
    PaginatorComponent
],
  templateUrl: './manual-cliente-consulta.component.html',
  styleUrl: './manual-cliente-consulta.component.scss'
})
export class ManualClienteConsultaComponent implements OnInit {
  
  manualService = inject(ManuaisClientesService);
  manuais: ManualClienteConsulta[] = [];
  pagina: number = 0;
  itensPorPgina = [10,20,30,40]; 
  totalElementos: number = 0;
  totalItens: number = 0;
  router = inject(Router);
  private loginService = inject(LoginService);

  ngOnInit(): void {
      this.totalItens = this.itensPorPgina[0];
      this.consultaManuais();
  }

  public consultaManuais(){
    this.manualService.consultarManuais(this.pagina, this.totalItens).subscribe({
      next:(res)=>{
        this.manuais = res.content;
        this.totalElementos = res.page.totalElements;
      },
      error:(e)=>{
        console.log(e);   
      }
    });
  }

  public onPageChange(event: any) {
    this.pagina = event.pageIndex;
    this.totalItens = event.pageSize;
    this.consultaManuais();
  }

  public gerenciarManual(id: any){
    this.router.navigate([`/controles/manuais-cliente-gerenciar/${id}`]);
  }

  public editarManual(id: any){
    this.router.navigate([`/controles/manuais-cliente-edicao/${id}`]);
  }

  public temPermissao(){
    return this.loginService.temPermissao(['ROLE_CONT_EDIT']);
  }

  public temPermissaoExcluir(){
    return this.loginService.temPermissao(['ROLE_CONT_ADMIN']);
  }

}
