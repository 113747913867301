import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { ChartData, ChartItem } from 'chart.js';
import Chart from 'chart.js/auto';
import moment from 'moment';
import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { IndicadorDePerformance } from '../../../model/indicadores/indicadoresDePerformance/indicadorDePerformance';
import { IndicadorDePerformanceResult } from '../../../model/indicadores/indicadoresDePerformance/indicadorDePerformanceResult';
import { IndicadoresGerenciaisService } from '../../../services/indicadores/indicadores-gerenciais.service';
import { IndicadoresPerformanceService } from '../../../services/indicadores/indicadores-performance.service';
import { IndicadoresTratamentoService } from '../../../services/indicadores/indicadoresTratamento.service';
import { EmisorEventoGeralService } from '../../../utils/emisorEventoGeral.service';
import { RouterLink } from '@angular/router';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-gestap-a-vista',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './gestao-a-vista.component.html',
  styleUrl: './gestao-a-vista.component.scss'
})
export class GestaoAVistaComponent {

  listaDeGraficosRh: Array<any> = [];
  listaDeGraficos: Array<any> = [];
  listaDeGraficosQualidade: Array<any> = [];
  setorSelecionado: string = "Qualidade";
  anoAtual = moment().format("yyyy");
  anoPassado = moment(this.anoAtual).subtract(1).format("yyyy");
  private chartInstances: { [key: string]: Chart } = {};
  resultadoMeses: any[] = [];
  metaMeses: any[] = [];
  resultadoMesesAnoPassado: any[] = [];
  metaMesesAnoPassado: any[] = [];
  @ViewChild('swiperContainer') swiperProduto!: ElementRef;
  swiper?: Swiper;
  tempoSlide: number = 10000;

  private cdr = inject(ChangeDetectorRef);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  private indicadorGerencia = inject(IndicadoresGerenciaisService)

  constructor(
    private indicadoresPerformanceService: IndicadoresPerformanceService,
    private indicadoresTratamentoService: IndicadoresTratamentoService,
  ) {

  }

  ngOnInit(): void {
    this.emisorEventoGeralService.controleHeader.emit(false);
    this.consultaGraficosQualidade();
  }

  ngOnDestroy(): void {
    this.destruirGraficos(); // Limpa as instâncias de gráficos ao desmontar o componente
  }

  public consultaGraficosQualidade() {
    this.indicadoresPerformanceService.consultaIndicadorDePerformancePorSetorEAno(this.setorSelecionado, this.anoAtual).subscribe({
      next: (res) => {
        this.listaDeGraficosQualidade = res;
        console.log(res)
      }, error: (error) => {

      }, complete: () => {
        this.consultaGraficosRh();
      }
    });
  }

  public consultaGraficosRh() {
    this.indicadorGerencia.consultaIndicadorGerenciaisPorSetorEAno("RH", this.anoAtual).subscribe({
      next: (res) => {
        this.listaDeGraficosRh = res;
      }, error: (error) => {

      }, complete: () => {
        this.transformaTodosValoresEmNumero();
        this.montarGraficos();
        this.montarImagens();
      }
    });
  }

  public consultaGraficosQualidadePorSegundo() {
    this.indicadoresPerformanceService.consultaIndicadorDePerformancePorSetorEAno(this.setorSelecionado, this.anoAtual).subscribe({
      next: (res) => {
        this.listaDeGraficosQualidade = res;
      }, error: (error) => {

      }, complete: () => {
        this.consultaGraficosRhPorSegundo();
      }
    });
  }

  public consultaGraficosRhPorSegundo() {
    this.indicadorGerencia.consultaIndicadorGerenciaisPorSetorEAno("RH", this.anoAtual).subscribe({
      next: (res) => {
        this.listaDeGraficosRh = res;
      }, error: (error) => {

      }, complete: () => {
        this.transformaTodosValoresEmNumero();
      }
    });
  }

  private transformaTodosValoresEmNumero() {
    this.listaDeGraficos = [...this.listaDeGraficosRh, ...this.listaDeGraficosQualidade];
    this.listaDeGraficos.forEach(e => {
      e.resultados = e.resultados.map((i: any) => this.indicadoresTratamentoService.converterParaNumeros(i, [
        'resultJan', 'resultFev', 'resultMar', 'resultAbr', 'resultMai',
        'resultJun', 'resultJul', 'resultAgo', 'resultSet', 'resultOut',
        'resultNov', 'resultDez', 'resultAno', 'metaJan', 'metaFev',
        'metaMar', 'metaAbr', 'metaMai', 'metaJun', 'metaJul', 'metaAgo',
        'metaSet', 'metaOut', 'metaNov', 'metaDez', 'metaAno']))
    });
    this.verificarSeGraficoEstaNaMeta(this.listaDeGraficos);
  }

  public verificarSeGraficoEstaNaMeta(resultados: IndicadorDePerformance[]) {
    resultados.forEach(i => {
      const ultimoAnoIndex = i.resultados.length - 1;
      const resultadoAno = i.resultados[ultimoAnoIndex];
      if (i.resultados.length === 1) {
        const anoAnterior = moment(this.anoAtual).subtract(1, 'year').format("yyyy");
        const novoResultado: IndicadorDePerformanceResult = {
          id: 0,
          ano: anoAnterior,
          obsAno: "",
          media: 0,
          metaJan: 0, resultJan: 0,
          metaFev: 0, resultFev: 0,
          metaMar: 0, resultMar: 0,
          metaAbr: 0, resultAbr: 0,
          metaMai: 0, resultMai: 0,
          metaJun: 0, resultJun: 0,
          metaJul: 0, resultJul: 0,
          metaAgo: 0, resultAgo: 0,
          metaSet: 0, resultSet: 0,
          metaOut: 0, resultOut: 0,
          metaNov: 0, resultNov: 0,
          metaDez: 0, resultDez: 0,
          metaAno: 0, resultAno: 0,
          // Adicione outros campos conforme necessário
          usuario: "",
          dataUpdate: "",
          compJan1: 0,
          compJan2: 0,
          compFev1: 0,
          compFev2: 0,
          compMar1: 0,
          compMar2: 0,
          compAbr1: 0,
          compAbr2: 0,
          compMai1: 0,
          compMai2: 0,
          compJun1: 0,
          compJun2: 0,
          compJul1: 0,
          compJul2: 0,
          compAgo1: 0,
          compAgo2: 0,
          compSet1: 0,
          compSet2: 0,
          compOut1: 0,
          compOut2: 0,
          compNov1: 0,
          compNov2: 0,
          compDez1: 0,
          compDez2: 0,
          obsJan: '',
          obsFev: '',
          obsMar: '',
          obsAbr: '',
          obsMai: '',
          obsJun: '',
          obsJul: '',
          obsAgo: '',
          obsSet: '',
          obsOut: '',
          obsNov: '',
          obsDez: '',
        };
        i.resultados.unshift(novoResultado);
      }
      let soma = 0;
      let contadorMeses = 0;
      // Mapeia os resultados mensais
      const meses = [
        'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
        'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
      ];
      meses.forEach(mes => {
        const metaMes = resultadoAno[`meta${mes}` as keyof IndicadorDePerformanceResult];
        const resultMes = resultadoAno[`result${mes}` as keyof IndicadorDePerformanceResult];
        if (resultMes !== null && resultMes !== 0) {
          soma += resultMes as number;
          contadorMeses++;
        }
      });
      // Calcular a média
      resultadoAno.media = contadorMeses > 0 ? soma / contadorMeses : 0;
    });
  }

  public montarGraficos() {
    this.cdr.detectChanges();
    this.listaDeGraficos.forEach((ind, index) => {
      const { id, tipo, indicador, resultados, unidade } = ind;
      if (resultados && resultados.length > 0) {
        const chartElement = document.getElementById(`myChart${id}`) as HTMLCanvasElement;

        if (chartElement && !this.chartInstances[id]) {
          this.tratarCorGrafico(tipo, resultados[resultados.length - 1]);
          this.tratarCorGraficoAnoAnterior(tipo, resultados[resultados.length - 2]);
          this.chartInstances[id] = new Chart(chartElement as ChartItem, {
            type: 'bar',
            data: this.getChartData(indicador, unidade),

            options: {
              scales: {
                y: {
                  beginAtZero: true,
                },
                
              },
              plugins: {
                legend: {
                  display:false,
                  fullSize: true,
                  labels: {
                    color: 'black',
                    font: {
                      size: 18,
                      weight: 'bold',
                    },
                    usePointStyle: true, // Utiliza estilo de ponto para as legendas (mais simples)
                  },
                },
                tooltip: {
                  usePointStyle: true,
                }
              },
              responsive: true, // Garante que o gráfico seja responsivo
              maintainAspectRatio: false,
            },
            plugins: [ChartDataLabels]
          });
        }
      }
    });
  }


  private tratarCorGrafico(tipoGrafico: string, resultados: any) {
    this.metaMeses = this.indicadoresTratamentoService.getMetaMeses(resultados);
    if (this.metaMeses) {
      this.resultadoMeses = this.indicadoresTratamentoService.getResultadoMeses(resultados, tipoGrafico, this.metaMeses, this.anoPassado);
    }
  }

  private tratarCorGraficoAnoAnterior(tipoGrafico: string, resultados: any) {
    this.metaMesesAnoPassado = this.indicadoresTratamentoService.getMetaMeses(resultados);
    if (this.metaMesesAnoPassado) {
      this.resultadoMesesAnoPassado = this.indicadoresTratamentoService.getResultadoMeses(resultados, tipoGrafico, this.metaMesesAnoPassado, this.anoPassado);
    }
  }

  private getChartData(label: string, unidade: string): ChartData<'bar' | 'line'> {
    return {
      labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ',`MEDIA DE ${this.anoAtual} ATÉ O MOMENTO`],
      datasets: [
        {
          type: 'line',
          label: 'Meta',
          borderColor: "blue",
          backgroundColor: "blue",
          data: this.metaMeses.map(m => m.mes),
          datalabels: {
            display: false,
          }
        },
        {
          label: `${label} - ${this.anoAtual}`,
          backgroundColor: this.resultadoMeses.map(r => r.cor),
          data: this.resultadoMeses.map(r => r.mes),
          // borderWidth: 1,
          datalabels: {
            anchor: 'end',
            align: 'end',
            color: '#300244',
            rotation: 0,
            font: {
              weight: 'bold',
              size: 24
            },
            formatter: function (value) {
              if (unidade == "%") {
                return value ? `${value}%` : '';
              }else {
                return value ? `${value}` : '';
              }
            }
          }
        },
        {
          type: 'line',
          fill: true,
          pointRadius: 5,
          label: `${label} - ${this.anoPassado}`,
          backgroundColor: this.resultadoMesesAnoPassado.map(r => r.cor),
          data: this.resultadoMesesAnoPassado.map(r => r.mes),
          borderWidth: 1,
          datalabels: {
            display: false,
          }
        },
      ],
    };
  }

  public voltar() {
    this.emisorEventoGeralService.controleHeader.emit(true);
  }

  public montarImagens() {
    this.cdr.detectChanges();
    const progressCircle = document.querySelector(".autoplay-progress svg")! as HTMLElement;
    const progressContent = document.querySelector(".autoplay-progress span")! as HTMLElement;
    const options: SwiperOptions = {
      autoplay: {
        delay: this.tempoSlide
      }, on: {
        autoplayTimeLeft(s, time, progress: number) {
          // Convertendo o número para string
          progressCircle.style.setProperty("--progress", (1 - progress).toString());
          // progressContent.textContent = `${Math.ceil(time / 50000)}s`;
        }, slideChange: () => {
          this.consultarEatualizarGrafico();
        },
      },
      effect: "fade",
      modules: [Autoplay, EffectFade],
    };
    this.swiper = new Swiper(this.swiperProduto.nativeElement, options);
  }


  public consultarEatualizarGrafico() {
    this.consultaGraficosQualidadePorSegundo();
    this.listaDeGraficos.forEach(grafico => {
      const chartInstance = this.chartInstances[grafico.id];
      this.tratarCorGrafico(grafico.tipo, grafico.resultados[grafico.resultados.length - 1]);
      this.tratarCorGraficoAnoAnterior(grafico.tipo, grafico.resultados[grafico.resultados.length - 2]);
      if (chartInstance) {
        chartInstance.data.datasets[0].data = this.getChartData(grafico.indicador, grafico.unidade).datasets[0].data;
        chartInstance.data.datasets[0].backgroundColor = this.getChartData(grafico.indicador, grafico.unidade).datasets[0].backgroundColor;
        chartInstance.data.datasets[1].data = this.getChartData(grafico.indicador, grafico.unidade).datasets[1].data;
        chartInstance.data.datasets[1].backgroundColor = this.getChartData(grafico.indicador, grafico.unidade).datasets[1].backgroundColor;
        chartInstance.data.datasets[2].data = this.getChartData(grafico.indicador, grafico.unidade).datasets[2].data;
        chartInstance.data.datasets[2].backgroundColor = this.getChartData(grafico.indicador, grafico.unidade).datasets[2].backgroundColor;
        chartInstance.update();
      }
    });
  }

  private destruirGraficos() {
    Object.keys(this.chartInstances).forEach((key) => {
      if (this.chartInstances[key]) {
        this.chartInstances[key].destroy();
        delete this.chartInstances[key];
      }
    });
  }
}

