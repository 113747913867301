import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { DispositivoIsencaoService } from '../../../../services/dispositivos/dispositivo-isencao.service';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { DispositivoIsencao } from '../../../../model/dispositivos/dipositivoIsencao';
import { EngDispositivoIsencaoPinturaService } from '../../../../services/engenharia/processo/eng-dispositivo-isencao-pintura.service';
import { environment } from '../../../../../environments/environment';
import { FormsModule } from '@angular/forms';
import Swiper from 'swiper';
import { Controller, Pagination } from 'swiper/modules';
import { EngDispositivoIsencaoPintura } from '../../../../model/engenharia/engDispositivoIsencaoPintura';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectComponentComponent } from '../../../../componentes/select-component/select-component.component';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { SwiperOptions } from 'swiper/types/swiper-options';
import { LoadingComponent } from "../../../../componentes/loading/loading.component";
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-eng-dispositivo-isencao',
  standalone: true,
  imports: [CommonModule, FormsModule, BotaoComponent, SelectComponentComponent, ContainerComponentComponent, LoadingComponent],
  templateUrl: './eng-dispositivo-isencao.component.html',
  styleUrl: './eng-dispositivo-isencao.component.scss',
})
export class EngDispositivoIsencaoComponent implements OnInit, OnChanges {

  dispositivoIsencao: EngDispositivoIsencaoPintura = new EngDispositivoIsencaoPintura();
  listaDispositivoIsencaoPintura: DispositivoIsencao[] = [];
  dispositivoDescricao: any;;
  listaDeDispostivosIsencao: EngDispositivoIsencaoPintura[] = [];
  dispositivoConsultadoParaVisualizacao: DispositivoIsencao = new DispositivoIsencao();
  dispositivoExibir: boolean = false;
  idProcesso: number = 0;
  cdProduto: number = 0
  produto: any = {};
  mensagemErro = "";
  urlImagem = environment.url;
  @Input() fazerConsulta: boolean = false;
  @ViewChild('swiperDispositivo', { static: false }) swiperDispositivo!: ElementRef;
  swiper!: Swiper;
  carregando: boolean = false;
  private loginService = inject(LoginService);


  constructor(
    private dispositivoIsencaoService: DispositivoIsencaoService,
    private engDispositivioPinturaService: EngDispositivoIsencaoPinturaService,
    private activeRouter: ActivatedRoute,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private emisorEventoGeralService: EmisorEventoGeralService,
  ) {

  }

  ngOnInit(): void {
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = parseInt(params.cdProduto);
      this.produto = { cdProduto: this.cdProduto }
      this.idProcesso = parseInt(this.getProcessoFromSession());
    });
  }




  ngOnChanges(changes: SimpleChanges) {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    if (changes['fazerConsulta'] && changes['fazerConsulta'].currentValue) {
      this.limparDados();
      this.consultarDispositivosIsencao();
      this.consultarDispositivosIsencaoPinturaPorIdDeterminacao();

    }
  }


  public limparDados() {
    this.listaDeDispostivosIsencao = [];
    this.listaDispositivoIsencaoPintura = [];

  }

  public limparConsulta() {
    this.dispositivoConsultadoParaVisualizacao = new DispositivoIsencao();
    this.dispositivoExibir = false;
    this.consultarDispositivosIsencaoPinturaPorIdDeterminacao();
  }

  private getProcessoFromSession(): string {
    return JSON.parse(sessionStorage.getItem('processo') ?? '""');
  }





  public salvarDispositivoDeIsencaoPintura() {
    if (this.dispositivoDescricao != null) {
      this.emisorEventoGeralService.carregando.emit(true);
      this.engDispositivioPinturaService.salvarEngDispositivoIsencaoPintura(this.montarObjeto()).subscribe({
        next: (res) => {
        }, error: (error) => {
          this.emisorEventoGeralService.carregando.emit(false);
          this.snackBarService.tratarErro(error, "Erro ao tentar salvar um dispositivo de isenção na peça")
        }, complete: () => {
          this.consultarDispositivosIsencaoPinturaPorIdDeterminacao();
          this.emisorEventoGeralService.carregando.emit(false);
        }
      });
    }
  }

  public consultarDispositivosIsencaoPinturaPorIdDeterminacao() {
    this.listaDeDispostivosIsencao = [];
    this.engDispositivioPinturaService.consultarEngDispositivoIsencaoPinturaPorIdProcesso(this.cdProduto, this.idProcesso).subscribe({
      next: (res) => {
        this.listaDeDispostivosIsencao = res;
      }, error: (error) => {
        this.mensagemErro = "Não há dispositivos de isenção nesta determinacao";
      }, complete: () => {

      }
    });
  }

  public consultarDispositivoPorId(id: number) {
    this.dispositivoConsultadoParaVisualizacao = new DispositivoIsencao();
    this.dispositivoExibir = true;
    this.dispositivoIsencaoService.consultarPorId(id).subscribe({
      next: (res) => {
        this.dispositivoConsultadoParaVisualizacao = res;
      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Dispositivo de isenção não encontrado")
      }, complete: () => {
        this.dispositivoConsultadoParaVisualizacao.imagens.length > 0 ? this.montarImagens() : []
      }
    });
  }

  public consultarDispositivosIsencao() {
    this.dispositivoIsencaoService.consultarTodasDispositivoIsencaoSemPaginacao().subscribe({
      next: (res: any) => {
        this.listaDispositivoIsencaoPintura = res

      }, error: (error) => {

      }, complete: () => {

      }
    });
  }


  public montarImagens() {
    this.cdr.detectChanges();
    const options: SwiperOptions = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff; background:#09638E" class="' + className + '">' + (index + 1) + '</span>';
        }
      },
      allowTouchMove: true, // Garante suporte ao touch
      observer: true,       // Observa alterações no Swiper
      observeParents: true, // Observa mudanças nos elementos pais
      threshold: 30,
      modules: [Pagination, Controller],
    };
    this.swiper = new Swiper(this.swiperDispositivo.nativeElement, options);
  }

  public montarObjeto() {
    this.dispositivoIsencao.cdProcesso = this.idProcesso;
    this.dispositivoIsencao.cdProduto = this.cdProduto;
    this.dispositivoIsencao.cdDispositivoIsencao = this.dispositivoDescricao.id;
    this.dispositivoIsencao.id = this.dispositivoIsencao.id;
    return this.dispositivoIsencao;
  }



  public deletarDispositivoPintura(nomeArquivo: string, id: number) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: nomeArquivo, textoConteudo: "dispositivo de isenção" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.deletar(id);
        };
      }
    });
  }

  private deletar(id: number) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.engDispositivioPinturaService.deletarEngDispositivoIsencaoPintura(id).subscribe({
      next: (res: any) => {
        this.snackBarService.openSnackBar(res.mensagem, "my-snack-bar-sucess")
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.openSnackBar("Erro ao tenta excluir", "my-snack-bar-erro")
      }, complete: () => {
        this.dispositivoConsultadoParaVisualizacao.imagens = [];
        this.consultarDispositivosIsencaoPinturaPorIdDeterminacao();
        this.emisorEventoGeralService.carregando.emit(false);
      }
    });
  }


  public receberValor(valor: any) {
    this.dispositivoDescricao = valor;
  }


  public temPermissao() {
    return this.loginService.temPermissao(['ROLE_ENG_EDIT']);
  }

  public temPermissaoExcluir() {
    return this.loginService.temPermissao(['ROLE_ENG_ADMIN']);
  }



}
