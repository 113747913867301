<app-container-component tituloPage="Gerenciar Auditoria" subtituloPage="Registrar relatorio Auditoria"
  [removerElevacao]="true" [iconeVolta]="false" [header]="false">
  <div class="mb-3">
    <label class="form-label text-muted"><strong>Auditor</strong></label>
    <input type="text" class="form-control bg-light border-0 rounded-3 readonly-field" [value]="auditoria.auditorLider"
      readonly>
  </div>
  <!-- <div class="mb-3">
    <label class="form-label text-muted">Data</label>
    <input type="date" class="form-control bg-light border-0 rounded-3" [value]="data" readonly>
  </div> -->
  <div class="mb-3">
    <label class="form-label text-muted"><strong>Norma</strong></label>
    <input type="text" class="form-control bg-light border-0 rounded-3 readonly-field" [value]="auditoria.normaAuditada"
      readonly>
  </div>

  <!-- Campos editáveis do formulário -->
  <form [formGroup]="auditoriaRelatorioForm">
    <div class="mb-3">
      <label class="form-label text-dark fw-semibold">Processo</label>
      <input type="text" class="form-control" formControlName="processo"
      [ngClass]="{'errorAnimacao':tocado('processo'),'validoAnimacao':validar('processo')}">
    </div>
    <div class="mb-3">
      <label class="form-label text-dark fw-semibold">Auditados</label>
      <input type="text" class="form-control" formControlName="auditado"
      [ngClass]="{'errorAnimacao':tocado('auditado'),'validoAnimacao':validar('auditado')}">
    </div>
    <div class="mb-3">
      <label class="form-label text-dark fw-semibold">Observação</label>
      <textarea class="form-control" formControlName="obs" rows="3"
      [ngClass]="{'errorAnimacao':tocado('obs'),'validoAnimacao':validar('obs')}"></textarea>
    </div>
    <div class="row">
      @if(this.auditoriaRelatorioForm.controls['id'].value){
      <app-botao label="Editar" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" icon="icons8-lápis.png" status="btn-salvar"
        (click)="editarRelatorio()"></app-botao>
      }@else {
      <app-botao label="Salvar" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" icon="icons8-disquete.png" status="btn-salvar"
        (click)="cadastrarRelatorio()"></app-botao>
      }
    </div>
  </form>

</app-container-component>