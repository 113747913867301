import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BookService } from '../../../../services/producao/book.service';
import { Produto } from '../../../../model/engenharia/produto-model';
import { ProdutoDetalheImagemService } from '../../../../services/engenharia/produto/produto-detalhe-imagem.service';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { CommonModule } from '@angular/common';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperComponentComponent } from "../swiperComponent/swiperComponent.component";

@Component({
  selector: 'app-imagens-produto',
  standalone: true,
  imports: [CommonModule, SwiperComponentComponent],
  templateUrl: './imagens-produto.component.html',
  styleUrl: './imagens-produto.component.scss'
})
export class ImagensProdutoComponent implements OnInit {

  imagensProduto: any[] = [];
  produto: Produto = new Produto();
  @ViewChild("swiperImagensProduto") swiperImagensProduto!: ElementRef;
  swiper!: Swiper;

  constructor(
    private imagemService: ProdutoDetalheImagemService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.receberDadosDoProduto();
  }

  receberDadosDoProduto() {
    BookService.emissorProduto.subscribe({
      next: (res: any) => {
        this.produto = res;
        this.consultarImagemDoProduto();
      },
      error: (e: any) => {
        this.produto = new Produto();
      }
    });
  }

  public consultarImagemDoProduto() {
    this.imagensProduto = [];
    this.imagemService.consultarTodasImagemPorIdProduto(this.produto.cdProduto).subscribe({
      next: (res) => {
        this.imagensProduto = res;
      }, error: (error) => {

      }, complete: () => {
        this.iniciarImagemCarregada();

      }
    });
  }


  public iniciarImagemCarregada() {
    this.imagensProduto.forEach(i => {
      i.imagemCarregada = false;
    });
  }
}
