import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TipoDesenvolvimento } from '../../../model/TipoDesenvolvimento';

@Injectable({
  providedIn: 'root'
})
export class TipoDesenvolvimentoService {

  private url  = environment.url+'/engenharia';

  constructor(
    private http:HttpClient
  ) { 

  }

  public consultarTiposDensolvimento():Observable<TipoDesenvolvimento[]>{
    return this.http.get<TipoDesenvolvimento[]>(`${this.url}/tipos-desenvolvimentos`)
  }
  
  public criarTipoDesenvolvimento(tipoDesenvolvimento:TipoDesenvolvimento):Observable<TipoDesenvolvimento>{
    return this.http.post<TipoDesenvolvimento>(`${this.url}/tipos-desenvolvimentos`,tipoDesenvolvimento)
  }

  public buscarPorId(id:number):Observable<TipoDesenvolvimento>{
    return this.http.get<TipoDesenvolvimento>(`${this.url}/tipos-desenvolvimentos/${id}`)
  }
  public deletarTipoDesenvolvimento(id:number):Observable<TipoDesenvolvimento>{
    return this.http.delete<TipoDesenvolvimento>(`${this.url}/tipos-desenvolvimentos/${id}`)
  }
}
