<div class="separador mt-2 mb-2" #dispositivoPintura>
    <strong>DISPOSITIVO DE PINTURA</strong>
</div>
@defer (on viewport(dispositivoPintura)) {
@if(tabAtiva != ''){

<ul class="nav nav-tabs" id="tabDispPintura" role="tablist">
    @if (dados.processo1 != '') {
    <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="{'active': tabAtiva === 'processo1'}" id="dispPintura1-tab" data-bs-toggle="tab"
            href="#dispPintura1" (click)="this.consultarDispositivoDePinturaProcesso1Click(dados.numeroProcesso1)"
            role="tab" aria-controls="processo1" aria-selected="true">{{dados.processo1}}</a>
    </li>
    }
    @if (dados.processo2 != '') {
    <li class="nav-item" role="presentation">
        <a class="nav-link" [ngClass]="{'active': tabAtiva === 'processo2'}" id="dispPintura2-tab" data-bs-toggle="tab"
            href="#dispPintura2" role="tab" (click)="consultarDispositivoDePinturaProcesso2Click(dados.numeroProcesso2)"
            aria-controls="processo2" aria-selected="false">{{dados.processo2}}</a>
    </li>
    }
</ul>
<div class="tab-content mt-3" id="myTabContent">
    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo1'}" id="dispPintura1" role="tabpanel"
        aria-labelledby="dispPintura1-tab">
        <div class="position-img">
            <div class="col-12 ">
                @if(imagens1 == undefined){
                <div class="card-load-box">
                    <div class="card_load_extreme_descripion"></div>
                </div>
                }@else if (imagemNaoCadastrada1) { <div class="col-12 text-center text-danger">
                    <h3>Imagem não cadastrada!</h3>
                </div>
                }
                <app-swiperComponent [listaDeDados]="imagens1"></app-swiperComponent>
            </div>
        </div>
    </div>

    <div class="tab-pane fade" [ngClass]="{'show active': tabAtiva === 'processo2'}" id="dispPintura2" role="tabpanel"
        aria-labelledby="dispPintura2-tab">
        <div class="position-img">
            <div class="col-12 ">
                @if(imagens2 == undefined){
                <div class="card-load-box">
                    <div class="card_load_extreme_descripion"></div>
                </div>
                }@else if (imagemNaoCadastrada2){ <div class="col-12 text-center text-danger">
                    <h3>Imagem não cadastrada!</h3>
                </div>
                }
                <app-swiperComponent [listaDeDados]="imagens2"></app-swiperComponent>
            </div>
        </div>
    </div>
</div>
}@else {
<div class="text-center text-danger mt-3">
    <p class="alert alert-danger">Dispositivo de pintura não cadastrado!</p>
</div>
}
}@loading {
<div class="card-load-box">
    <div class="card_load_extreme_descripion"></div>
</div>
}