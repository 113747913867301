<app-container-component tituloPage="AÇÕES" subtituloPage="Ações visualizar" [header]="ativarHeader" >
    <div class="row gap-2 gap-lg-3 justify-content-around">
        @if(mapeamentoRecebido && mapeamentoRecebido.itens && mapeamentoRecebido.itens.length > 0){
        @for(acao of mapeamentoRecebido.itens;track $index){
        <div class="card p-3">
            <div class="card-body text-center">
                <small><strong>Parte interessada</strong></small>
                <h6 class="card-title">{{acao.parteInteressada}}</h6>
                <div class="divider"></div>
                <p class="card-text"><strong>Descrição:</strong>{{acao.descricao}}</p>
                <div class="divider"></div>
                <p class="card-text"><strong>Requisito / Expectativa:</strong> {{acao.requisito}}</p>
                <div class="divider"></div>
                <p class="card-text"><strong>Monitoramento:</strong> {{acao.monitoramento}}</p>
                @if(!ativarHeader){
                    <div class="btn-container">
                        @if(temPermissao()){
                        <button class="btn-icon edit" (click)="editarParteInteressadaSelecionada(acao)"><i
                                class="fas fa-edit"></i></button>
                        @if(temPermissaoExcluir()){
                        <button class="btn-icon delete" (click)="deletarParteInteressadaSelecionada(acao)"><i
                                class="fas fa-trash"></i></button>
                        }
                        }
    
                    </div>
                }
            </div>
        </div>
        }
        }@else {
            <span>Não há  ações cadastradas</span>
        }
    </div>
</app-container-component>