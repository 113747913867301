import { InspecaoAderenciaComponent } from '../../paginas/inspecao-equipamentos/aderencia/inspecao-aderencia/inspecao-aderencia.component';
import { Routes } from "@angular/router";
import { InspecaoDurezaLapisComponent } from '../../paginas/inspecao-equipamentos/dureza-lapis/inspecao-dureza-lapis/inspecao-dureza-lapis.component';
import { InspecaoMedicaoCamadaComponent } from '../../paginas/inspecao-equipamentos/medicao-camada/inspecao-medicao-camada/inspecao-medicao-camada.component';
import { InspecaoLuximetroComponent } from '../../paginas/inspecao-equipamentos/luximetro/inspecao-luximetro/inspecao-luximetro.component';


export const routesInspecaoEquipamentos: Routes = [

    { path: "home", loadComponent: () => import("../../paginas/inspecao-equipamentos/inspecao-home/inspecao-home.component").then(h => h.InspecaoHomeComponent) },
    //ADERENCIA
    { path: "aderencia", component: InspecaoAderenciaComponent },
    { path: "aderencia/:valorBusca", component: InspecaoAderenciaComponent },
    { path: "aderencia/view/:id", loadComponent: () => import("../../paginas/inspecao-equipamentos/aderencia/inspecao-aderencia-view/inspecao-aderencia-view.component").then(a => a.InspecaoAderenciaViewComponent) },
    //DUREZA LAPIS
    { path: "dureza-lapis", component: InspecaoDurezaLapisComponent },
    { path: "dureza-lapis/:valorBusca", component: InspecaoDurezaLapisComponent },
    { path: "dureza-lapis/view/:id", loadComponent: () => import("../../paginas/inspecao-equipamentos/dureza-lapis/dureza-lapis-view/dureza-lapis-view.component").then(a => a.DurezaLapisViewComponent) },
    //Medicao camada
    { path: "medicao-camada", component: InspecaoMedicaoCamadaComponent },
    { path: "medicao-camada/:valorBusca", component: InspecaoMedicaoCamadaComponent },
    { path: "medicao-camada/view/:id", loadComponent: () => import("../../paginas/inspecao-equipamentos/medicao-camada/medicao-camada-view/medicao-camada-view.component").then(m => m.MedicaoCamadaViewComponent) },
    //Luximetro
    { path: "luximetro", component: InspecaoLuximetroComponent},
    { path: "luximetro/:valorBusca", component: InspecaoLuximetroComponent},
    { path: "luximetro/view/:id", loadComponent: () => import("../../paginas/inspecao-equipamentos/luximetro/luximetro-view/luximetro-view.component").then(l => l.LuximetroViewComponent) },
]