
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DispositivoService } from '../../../../services/dispositivos/dispositivo.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Dispositivo } from '../../../../model/dispositivos/dispostivo';
import { EngDispositivoPinturaService } from '../../../../services/engenharia/processo/eng-dispositivo-pintura.service';
import { EngDispositivoPintura } from '../../../../model/engenharia/engDispositivoPintura';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from '../../../../utils/snack-bar.service';
// import { RotaProcessosService } from '../../../../services/rotas-services/rota-processos.service';
import { environment } from '../../../../../environments/environment';
import Swiper from 'swiper';
import { Controller, Pagination } from 'swiper/modules';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { SelectComponentComponent } from '../../../../componentes/select-component/select-component.component';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { LoadingComponent } from "../../../../componentes/loading/loading.component";

@Component({
  selector: 'app-eng-dispositivo-pintura',
  standalone: true,
  imports: [CommonModule, FormsModule, BotaoComponent, SelectComponentComponent, ContainerComponentComponent, LoadingComponent],
  templateUrl: './eng-dispositivo-pintura.component.html',
  styleUrl: './eng-dispositivo-pintura.component.scss'
})
export class EngDispositivoPinturaComponent implements OnInit,OnChanges {

  dispositivo: EngDispositivoPintura = new EngDispositivoPintura();
  listaDispositivoPintura: EngDispositivoPintura[] = [];
  dispositivoDescricao: Dispositivo = new Dispositivo();
  dispositivoConsultadoVisualizacao: Dispositivo = new Dispositivo();
  dispositivoExibir: Dispositivo = new Dispositivo();
  listaDeDispostivos: Dispositivo[] = [];
  idProcesso: number = 0;
  cdProduto: number = 0
  produto: any = {};
  @Input() fazerConsulta: boolean = false;
  urlImagem = environment.url;
  carregando:boolean = false;

  constructor(
    private dispositivoService: DispositivoService,
    private engDispositivioPinturaService: EngDispositivoPinturaService,
    private activeRouter: ActivatedRoute,
    private snackBarService: SnackBarService,
    public   dialog: MatDialog,
    private cdr:ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.activeRouter.params.subscribe((params: any) => {
      this.cdProduto = parseInt(params.cdProduto) || 1408;
      this.produto = { cdProduto: this.cdProduto }
      // RotaProcessosService.emitirProcesso.emit(this.produto);
      this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
      if(this.idProcesso){
        this.limparDados();
        this.consultarDispositivos();
        this.consultarDispositivosPinturaPorIdProcesso();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString())
    if (changes['fazerConsulta'] && changes['fazerConsulta'].currentValue) {
      this.limparDados();
      this.consultarDispositivos();
      this.consultarDispositivosPinturaPorIdProcesso();
      
    }
  }

  public consultarDispositivos() {
    this.dispositivoService.consultarTodasPaginada(0, 9999999).subscribe({
      next: (res: any) => {
        this.listaDeDispostivos = res.content
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public limparDados(){
    this.listaDeDispostivos = [];
    this.listaDispositivoPintura = [];
  }



  public limparConsulta(){
    this.dispositivoExibir =  new Dispositivo();
    this.consultarDispositivosPinturaPorIdProcesso();
    
  }

  public receberValor(valor:any){
    this.dispositivoDescricao = valor;
  }

  public consultarDispositivosPinturaPorIdProcesso() {
    this.listaDispositivoPintura = [];
    this.engDispositivioPinturaService.consultarEngDispositivoPinturaPorIdProcesso(this.cdProduto, this.idProcesso).subscribe({
      next: (res) => {
        this.listaDispositivoPintura = res;
      }, error: (error) => {
        console.log(error)
      }, complete: () => {

      }
    });
  }

  public consultarDispositivosPintura(id:any){
    this.dispositivoExibir = new Dispositivo();;
      this.dispositivoService.consultarPorCdDispostivo(id).subscribe({
        next:(res)=>{
          this.dispositivoExibir = res;
        }
      })
  }


  public salvarDispositivoDePintura() {
    this.carregando = true;
    this.engDispositivioPinturaService.salvarEngDispositivoPintura(this.montarObjeto()).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Dispositivo salvo com sucesso na determinacao " + this.idProcesso, "my-snack-bar-sucess")
      }, error: (error) => {
        this.carregando = false;
        this.snackBarService.tratarErro(error,"Erro ao tentar salvar dispositivo neste processo")
      }, complete: () => {
        this.consultarDispositivosPinturaPorIdProcesso()
        this.carregando = false;
      }
    });
  }


  public montarObjeto(): EngDispositivoPintura {
    this.dispositivo.cdProcesso = this.idProcesso;
    this.dispositivo.cdProduto = this.cdProduto;
    this.dispositivo.cdDispositivo = this.dispositivoDescricao.id;
    return this.dispositivo;
  }


  public deletarDispositivoPintura(nomeArquivo:string,id: number) {
        const dialogRef = this.dialog.open(ModalComponent, {
      data: {nomeArquivo:nomeArquivo,textoConteudo:"dispositivo de pintura"},
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next:(res)=>{
        if (res) {
        this.deletar(id);
        };
      }
    });
  }

  private deletar(id: number){
    this.carregando = true;
    this.engDispositivioPinturaService.deletarEngDispositivoPintura(id).subscribe({
      next: (res: any) => {
        this.snackBarService.openSnackBar(res.mensagem, "my-snack-bar-sucess")
      }, error: (error) => {
        this.carregando = false;
        this.snackBarService.tratarErro(error,"Erro ao tentar excluir dispositivo deste processo")
      }, complete: () => {
        this.consultarDispositivosPinturaPorIdProcesso();
        this.carregando = false;
      }
    });
  }

  public montarImagens() {
    this.cdr.detectChanges()
    const swiper = new Swiper(".mySwiper", {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span style="width:18px;height:18px; display:flex;justify-content:center;align-items: center;color: #fff;" class="' + className + '">' + (index + 1) + "</span>";
        },
      },
      allowTouchMove: true, // Garante suporte ao touch
      observer: true,       // Observa alterações no Swiper
      observeParents: true, // Observa mudanças nos elementos pais
      threshold: 30,
      modules: [Pagination, Controller]
    });
  }


}
