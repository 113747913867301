import { Component, ElementRef, inject, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Jodit } from 'jodit';
import { ModalViewJoditComponent } from '../modal-view-jodit/modal-view-jodit.component';

@Component({
  selector: 'app-formulario-editor-jodit',
  standalone: true,
  imports: [],
  templateUrl: './formulario-editor-jodit.component.html',
  styleUrl: './formulario-editor-jodit.component.scss'
})
export class FormularioEditorJoditComponent {
  @ViewChild("editor", { static: false }) editorRef: ElementRef | undefined;
  @Output() conteudoHtml:EventEmitter <{}> = new EventEmitter<{}>();
  @Input() toolBarJoodit:boolean = true;
  private editorInstance: Jodit | undefined;
  private sanitizer = inject(DomSanitizer);
  private dialog = inject(MatDialog);
  editorContent: SafeHtml = '';

  ngOnInit(): void {
    this.iniciarFormulario();
  }

  ngAfterViewInit(): void {
    this.iniciarFormulario();
  }



  public iniciarFormulario() {
    if (this.editorRef) {
      this.editorInstance = Jodit.make(this.editorRef.nativeElement, {
        toolbarAdaptive: false,
        buttons: [
          // Primeira linha de botões
          "bold", "italic", "underline", "strikethrough", "align","indent","outdent","brush",

          // Quebra de linha
          "\n",

          // Segunda linha de botões
          "font", "fontsize", "paragraph", "|", "ul", "ol", "superscript", "subscript", "|", "image", "table", "link"
        ],
        commandToHotkeys: {
          bold: 'ctrl + b',
          underline: 'ctrl + u',
          italic: 'ctrl + i',
          strikethrough: 'ctrl+s',
        },
        uploader: {
          "insertImageAsBase64URI": true,
        },
        history: {
          enable: true,
        }, cleanHTML: {
          denyTags: {
            script: 'true',
          }
        },
        toolbarInline: this.toolBarJoodit,
        language: "pt_br",
        enter: "p",
        toolbarSticky: false,
        showCharsCounter: false,
        toolbarInlineForSelection: true,
        showWordsCounter: false,
        showXPathInStatusbar: false
      });

    }
  }


    public salvar(): { files: File[], conteudo: string } {
      const editorContent = this.editorInstance!.value; // Pega o conteúdo do editor
      const div = document.createElement('div');
      div.innerHTML = editorContent;
  
      const images = div.querySelectorAll('img'); // Seleciona todas as imagens
      const files: File[] = [];
  
      images.forEach((img: HTMLImageElement) => {
        if (img.src.startsWith('data:image')) { // Verifica se é Base64
          const file = this.base64ToFile(img.src);
          files.push(file);
          img.src = file.name; // Substitui a imagem Base64 pelo nome do arquivo temporário
        }
      });
      this.conteudoHtml.emit({ files, conteudo: div.innerHTML })
      return { files, conteudo: div.innerHTML };
    }
  
    base64ToFile(base64String: string): File {
      const arr = base64String.split(',');
      const mimeMatch = arr[0].match(/:(.*?);/);
      const mime = mimeMatch ? mimeMatch[1] : 'image/png';
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
  
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
  
    
      return new File([u8arr], `image_${Date.now()}.png`, { type: mime });
    }
  
  
  
    public abrirPreview() {

      this.dialog.open(ModalViewJoditComponent, {
        width: "80%",
        height: "80%",
        data: this.editorContent  ? this.editorContent : '',
      });
    }
  
    public obterValor(): string {
      if (this.editorInstance) {
        const valorEditor = this.editorInstance.value;
        this.editorContent = this.sanitizer.bypassSecurityTrustHtml(valorEditor);
      }
      return '';
    }

}
