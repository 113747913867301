<app-container-component [tituloPage]="tituloPagina" [subtituloPage]="subTitulo">
    <section class="row p-2">

        <div class="col-12 col-lg-6">
            <label for="" class="form-label mt-2">Nome:</label>
            <input type="text" class="form-control" [(ngModel)]="usuario.nome">
           
        </div>
        <div class="col-12 col-lg-6">
            <label for="" class="form-label mt-2">Email:</label>
            <input type="text" class="form-control" [(ngModel)]="usuario.username">
        </div>
        <div class="col-12 col-lg-4">
            <label for="" class="form-label mt-2">Matricula:</label>
            <input type="text" class="form-control" [(ngModel)]="usuario.matricula">
        </div>
        <div class="col-12 col-lg-4">
            <label for="" class="form-label mt-2">Situação conta:</label>
            <select class="form-select" aria-label="Default select example" [(ngModel)]="usuario.contaAtiva">
                <option value="true">ATIVA</option>
                <option value="false">BLOQUEADA</option>
            </select>
        </div>
        <div class="col-12 col-lg-4">
            <label for="" class="form-label mt-2">Tipo:</label>
            <select class="form-select" aria-label="Default select example" [(ngModel)]="usuario.tipo">
                <option value="PARTICIPANTE">PARTICIPANTE</option>
                <option value="EXPLORADOR">EXPLORADOR</option>
            </select>
        </div>

        <div class="col-12">
            <div class="separador mt-3 mb-3">
                <strong>Permissões do usuário</strong>
            </div>

                <div class="row">
                    <div *ngFor="let item of permissoesSistema; let i = index" class="col-12 col-lg-3 mt-2">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">{{item.modulo}}</h5>
                                <div *ngFor="let p of item.permissoes; let j = index">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" [checked]="hasPermission(p.autority)"  [value]="p" [id]="p.autority" (change)="onCheckboxChange($event, p)">
                                        <label class="form-check-label" [for]="p.autority">
                                            {{ p.autority.includes('_ADMIN') ? 'ADMINISTRADOR' : (p.autority.includes('_EDIT') ? 'EDITAR' : 'VISUALIZAR') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            @if(usuario.id !== 0 && usuario.id !== undefined ){
                <button class="btn mt-2" (click)="alterarUsuario()">Salvar</button>
                <button class="btn mt-2 ms-3" (click)="redefinirSenha()">Alterar senha</button>
            }@else {
                <button class="btn mt-2" (click)="cadastrarUsuario()">Salvar</button>
            }
            
        </div>
    </section>

</app-container-component>
