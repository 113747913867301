@if(documentoEDesenhos.length > 0){
<app-container-component [header]="false" subtituloPage="Documentos cadastrados">
    <div class="p-3">
        <table class="table  table-striped table-hover table-responsive">
            <thead>
                <tr>
                    <th>Estudo</th>
                    <th>Análise</th>
                    <th>Resultado</th>
                    <th class="text-center">Arquivo</th>
                    <th class="text-center">Editar</th>
                    <th class="text-center">Remover</th>
                </tr>
            </thead>
            <tbody>
                @for (arquivo of documentoEDesenhos; track arquivo.id) {
                <tr>
                    <td>{{arquivo.estudo}}</td>
                    <td>{{arquivo.analise}}</td>
                    <td>{{arquivo.resultado}}</td>
                    <td class="text-center"><a [href]="arquivo.path" target="_blank">{{arquivo.nomeArquivo}}</a>
                    </td>
                    <td class="text-center"><i (click)="editarDocumentoOuDesenho(arquivo)"
                            class="fa-solid fa-pencil"></i></td>
                    <td class="text-center"><i (click)="deletarDocumentoOuDesenho(arquivo.nomeArquivo,arquivo.id)"
                            class="fa-solid fa-trash"></i></td>

                </tr>
                }
            </tbody>
        </table>
    </div>
</app-container-component>
}
@if(!controle){
<app-container-component [header]="false" subtituloPage="Cadastrar documento">
    <form [formGroup]="contactFormAnaliseCapabilidade"
        class="d-flex flex-column justify-content-center align-items-center">
        <div formArrayName="contactsAnaliseCapabilidade" class="row col-12 p-0 p-lg-3 espaco">
            @if(contactsAnaliseCapabilidade.controls.length > 0){
            <div *ngFor="let contact of contactsAnaliseCapabilidade.controls; let i = index" class="row col-12 ">
                <div [formGroupName]="i" class="col-12 card-box">
                    <div class="col-12">
                        <label for="text" class="form-label">Estudo:</label>
                        <input type="text" formControlName="estudo" class="form-control form-control-sm">
                    </div>
                    <div class="col-12">
                        <label for="text" class="form-label">Análise:</label>
                        <input type="text" formControlName="analise" class="form-control form-control-sm">
                    </div>
                    <div class="col-12">
                        <label for="text" class="form-label">Resultado:</label>
                        <input type="text" formControlName="resultado" class="form-control form-control-sm">
                    </div>
                    <div class="col-12 mt-3">
                        <div class="mb-3 col-12">
                            <div class="col-12">
                                @if(listaDocumentoSelecionado[i]){
                                <div
                                    class="col-12 mt-3 text-center d-flex flex-column justify-content-center align-items-center">
                                    <img class="imagem-pdf" src="assets/pdf.png" alt="">
                                    <label class="file-name mt-2">{{listaDocumentoSelecionado[i].name}}</label>
                                </div>
                                }@else {
                                <label for="arquivoAnaliseCapacidade-{{dados}}" class="form-label botao-input">
                                    <div>
                                        Documento +
                                        <input type="file" id="arquivoAnaliseCapacidade-{{dados}}" class="d-none"
                                            (change)="pegarDocumentoEDesenho($event)">
                                    </div>
                                </label>
                                }
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn close-button" (click)="removeContact(i)"><i
                            class="fa-regular fa-circle-xmark"></i></button>
                </div>
            </div>
            }@else {
            <div class="text-center">
                <app-botao label="Criar formulario" class="col-12 col-sm-3 col-md-3 col-lg-3" icon="icons8-mais.png"
                    status="btn-salvar" (click)="addContact()"></app-botao>
            </div>
            }
        </div>
        <div class="row gap-1 mt-1 col-12">
            <!-- @if(contactsAnaliseCapabilidade.controls.length > 0 ){
                <app-botao label="Documentos" class="col-12 col-sm-3 col-md-3 col-lg-3" icon="icons8-mais.png"
                    status="btn-salvar" (click)="addContact()"></app-botao>
                } -->
            <app-botao label="Salvar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-disquete.png"
                status="btn-salvar" (click)="onSubmit()"></app-botao>
            <app-botao label="Cancelar" class="col-12 col-sm-3 col-md-4 col-lg-3" icon="icons8-x.png"
                status="btn-cancelar" (click)="limparFormulario()"></app-botao>

        </div>
    </form>
</app-container-component>
}

@if(controle){
<app-container-component [header]="false" subtituloPage="Editar documento">
    <form [formGroup]="formularioEditar">
        <div class="row col-12 p-3 espaco">
            <div class="col-12 card-box">
                <div class="col-12">
                    <label for="text" class="form-label">Estudo:</label>
                    <input type="text" formControlName="estudo" class="form-control form-control-sm">
                </div>
                <div class="col-12">
                    <label for="text" class="form-label">Análise:</label>
                    <input type="text" formControlName="analise" class="form-control form-control-sm">
                </div>
                <div class="col-12">
                    <label for="text" class="form-label">Resultado:</label>
                    <input type="text" formControlName="resultado" class="form-control form-control-sm">
                </div>
                <button type="button" class="btn close-button"><i class="fa-regular fa-circle-xmark"
                        (click)="cancelarEdicao()"></i></button>
            </div>
            <div class="col-3">
                <button class="btn btn-sm btn-primary col-12" (click)="editarDesenho()">Editar</button>
            </div>
            <div class="col-3">
                <button class="btn btn-sm btn-primary col-12" (click)="cancelarEdicao()">Cancelar</button>
            </div>
        </div>
    </form>
</app-container-component>
}