import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngPadraoEmbalagemService {

  
  private url = environment.url+'/engenharia';
  constructor(
    private http: HttpClient
  ) { 
    
  }
  public salvarPadraoEmbalagem(formdata: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-padrao-embalagem`, formdata);
  }
  public editarDescricaoPadraoEmbalagem(id:number,descricao:string): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-padrao-embalagem/${id}/${descricao}`, null);
  }

  public consultarPadraoEmbalagem(cdProduto:number,idProcesso:number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-padrao-embalagem/${cdProduto}/${idProcesso}`);
  }


  public consultaImagemPorNome(nomeArquivo:string): Observable<Blob> {
    return this.http.get(`${this.url}/eng-padrao-embalagem/download/${nomeArquivo}`,{responseType:'blob'});
  }

  public deletarPadraoEmbalagem(id:number): Observable<any> {
    return this.http.delete<any>(`${this.url}/eng-padrao-embalagem/${id}`);
  }

  public salvarNovoPadraoEmbalagem(novaSequencia:any):Observable<any>{
    return this.http.post<any>(`${this.url}/eng-padrao-embalagem/sequencia`,novaSequencia)
  }
}
