<app-container-component [header]="false" subtituloPage="DETERMINAÇÃO PÓ">
    <form class="form-group  col-12" [formGroup]="formularioDeterminacaoProcessoPo">
        <div class="row sessao-produto delay-1">
            <!-- <div class="mb-3 col-12 col-md-6 col-lg-6 col-xxl-3">
                <label for="text" class="form-label">Característica:</label>
                <input type="text" formControlName="nome" class="form-control form-control-sm">
            </div> -->
            <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Equipamento 1:</label>
                <input type="text" formControlName="equipament1" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Equipamento 2:</label>
                <input type="text" formControlName="equipament2" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Equipamento 3:</label>
                <input type="text" formControlName="equipament3" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Qtd de peças na travessa:</label>
                <input type="number" formControlName="qtdePecasTravessa" (input)="calcularQuantidadePorRack()"
                    class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasTravessa')"></app-mensagem-errors>
            </div>
            <div class="mb-3  col-12   col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Qtd de travessas por quadro:</label>
                <input type="number" formControlName="qtdeTravessasQuadro" (input)="calcularQuantidadePorRack()"
                    class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasTravessa')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Qtd de peças por quadro:</label>
                <input type="number" formControlName="qtdePecasQuadro" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasQuadro')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Velocidade máxima da monovia:</label>
                <input type="number" formControlName="velocMonoviaMax" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('velocMonoviaMax')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Velocidade mínima da monovia:</label>
                <input type="number" formControlName="velocMonoviaMin" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('velocMonoviaMin')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Distância do reciprocador:</label>
                <input type="number" formControlName="distanciaReciprocador" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('distanciaReciprocador')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Velocidade do reciprocador:</label>
                <input type="number" formControlName="velocReciprocador" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('velocReciprocador')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="number" class="form-label">Vazão do reciprocador:</label>
                <input type="number" formControlName="vazaoReciprocador" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('vazaoReciprocador')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Altura máxima do reciprocador:</label>
                <input type="number" formControlName="alturaReciprocadorMax" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('alturaReciprocadorMax')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Altura mínima do reciprocador:</label>
                <input type="number" formControlName="alturaReciprocadorMin" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('alturaReciprocadorMin')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tensão:</label>
                <input type="number" formControlName="tensao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tensao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Corrente:</label>
                <input type="number" formControlName="corrente" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('corrente')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="number" class="form-label">Vazão da pistola retrátil:</label>
                <input type="number" formControlName="vazaoPistolaRet" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('vazaoPistolaRet')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">Quantidade de passadas da pistola retrátil:</label>
                <input type="number" formControlName="qtdePassadaPistolaRet" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePassadaPistolaRet')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">Formato de pintura:</label>
                <input type="text" formControlName="formatoPintura" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <label for="text" class="form-label">Parâmetro de cura:</label>
                <input type="text" formControlName="parametroCura" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12  col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <label for="text" class="form-label">Amostra para inspeção:</label>
                <input type="text" formControlName="amostraInspecao" class="form-control form-control-sm">
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de setup do equipamento:</label>
                <input type="number" formControlName="tempoSetupEquipamento" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoSetupEquipamento')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de setup do dispositivo:</label>
                <input type="number" formControlName="tempoSetupDispositivo" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoSetupDispositivo')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de montagem:</label>
                <input type="number" formControlName="tempoMontagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoMontagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de isenção (anterior):</label>
                <input type="number" formControlName="tempoIsencaoAnterior" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoIsencaoAnterior')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de máquina:</label>
                <input type="number" formControlName="tempoMaquina" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoMaquina')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de resfriamento:</label>
                <input type="number" formControlName="tempoResfriamento" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoResfriamento')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de desmontagem:</label>
                <input type="number" formControlName="tempoDesmontagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoDesmontagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de inspeção:</label>
                <input type="number" formControlName="tempoInspecao" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoInspecao')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de isenção (posterior):</label>
                <input type="number" formControlName="tempoIsencaoPosterior" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoIsencaoPosterior')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Tempo de embalagem:</label>
                <input type="number" formControlName="tempoEmbalagem" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('tempoEmbalagem')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">LeadTime:</label>
                <input type="number" formControlName="leadTime" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('leadTime')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <label for="text" class="form-label">Quantidade de peças por hora da máquina:</label>
                <input type="number" formControlName="qtdePecasHoraMaquina" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('qtdePecasHoraMaquina')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="text" class="form-label">Camada Mínima:</label>
                <input type="number" formControlName="camadaMinima" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('camadaMinima')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="text" class="form-label">Camada Máxima:</label>
                <input type="number" formControlName="camadaMaxima" class="form-control form-control-sm">
                <app-mensagem-errors [control]="obterValidacao('camadaMaxima')"></app-mensagem-errors>
            </div>
            <div class="mb-3 col-12  col-sm-12 col-md-12 col-lg-12 col-xxl-12">
                <label for="text" class="form-label">EPI:</label>
                <input type="text" formControlName="epi" class="form-control form-control-sm">
            </div>
            <div class="col-12 mb-3">
                <label for="text" class="form-label"><span>Descrição:</span></label>
                <textarea formControlName="observacao" class="form-control form-control-sm dynamic-textarea"
                    (input)="autoGrow($event)"></textarea>
            </div>
            <div class="row justify-content-start gap-2">
                @if(idProcesso && formularioDeterminacaoProcessoPo.controls['id'].value){
                <app-botao label="Editar" class="col-12 col-lg-2" icon="icons8-lápis.png" status="btn-salvar"
                    (click)="editarProcesso()"></app-botao>
                }@else {
                <app-botao label="Salvar" class="col-12 col-lg-2" icon="icons8-disquete.png" status="btn-salvar"
                    (click)="salvarProcesso()"></app-botao>
                <app-botao label="Cancelar" class="col-12 col-lg-2" icon="icons8-x.png" status="btn-cancelar"
                    (click)="limparFormulario()"></app-botao>
                }
            </div>
        </div>
    </form>
</app-container-component>