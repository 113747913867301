import { ActivatedRoute } from '@angular/router';
import { DeterminacaoProcessoFosfatoService } from './../../../../../services/engenharia/processo/eng-determinacao-processo-fosfato.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Processo } from '../../../../../enum/Processo';
import { BotaoComponent } from '../../../../../componentes/botao/botao.component';
import { SnackBarService } from '../../../../../utils/snack-bar.service';
import { FormValidacaoService } from '../../../../../utils/formValidacao.service';
import { ValidatorsService } from '../../../../../utils/validators.service';
import { Usuario } from '../../../../../model/usuario/usuario';
import { LoginService } from '../../../../../services/login/login.service';
import moment from 'moment';
import { MensagemErrorsComponent } from "../../../../../componentes/mensagem-errors/mensagem-errors.component";
import { ContainerComponentComponent } from "../../../../../componentes/container-component/container-component.component";

@Component({
  selector: 'app-eng-determinacao-processo-fosfato',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, BotaoComponent, MensagemErrorsComponent, ContainerComponentComponent],
  templateUrl: './eng-determinacao-processo-fosfato.component.html',
  styleUrl: './eng-determinacao-processo-fosfato.component.scss'
})
export class EngDeterminacaoProcessoFosfatoComponent implements OnInit, OnChanges {

  formularioDeterminacaoProcessoFosfato!: FormGroup;
  processo = Processo;
  cdProduto: any;
  idProcesso: any;
  usuario: Usuario = new Usuario();
  @Input() formularioFosfato: any = "";
  @Output() processoSalvo: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private determinacaoProcessoFosfatoService: DeterminacaoProcessoFosfatoService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private formValidacoesServices: FormValidacaoService,
    private validatorsService: ValidatorsService,
    private loginService: LoginService,
  ) {

  }


  ngOnInit(): void {
    this.pegarDadosUsuario();
    this.iniciarFormulario();
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
    this.formularioDeterminacaoProcessoFosfato.patchValue(this.formularioFosfato);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.idProcesso = JSON.parse(sessionStorage.getItem('processo')!.toString());
    if (changes['dadosFormulario'] && !changes['dadosFormulario'].firstChange) {
      this.formularioDeterminacaoProcessoFosfato.patchValue(changes['dadosFormulario'].currentValue);
    }
  }

  public iniciarFormulario() {
    this.formularioDeterminacaoProcessoFosfato = this.formBuilder.group({
      id: [],
      cdProcesso: [this.processo.FOSFATO],
      nome: [],
      qtdePecasGancheira: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      qtdeGancheirasRack: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      parametrosEstufa: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      amostraInspecao: [],
      qtdePecasHora: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoSetup: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoMontagem: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoIsencaoAnterior: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoIsencaoPosterior: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoDeMaquina: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoResfriamento: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoDesmontagem: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoInspecao: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      tempoEmbalagem: [null, [Validators.min(0), this.validatorsService.somenteNumeroPositivo()]],
      equipamentoDeProtecao: ["Óculos, sapatos de segurança, luvas e protetor auricular"],
      observacoes: [],
      observacao: [],
      cdProduto: [],
      usuarioCadastro: [this.usuario.username],
      usuarioAlteracao: [this.usuario.username],
      dataCadastro: [moment().format("yyyy-MM-DD")],
      dataAlteracao: [moment().format("yyyy-MM-DD")]
    });

  };


  public pegarDadosUsuario() {
    this.usuario = this.loginService.decodificarToken();
  }
  public salvarProcesso() {
    if (this.formularioDeterminacaoProcessoFosfato.valid) {
      this.determinacaoProcessoFosfatoService.salvarDeterminacaoProcesso(this.formularioDeterminacaoProcessoFosfato.value).subscribe({
        next: (res) => {
          this.snackBarService.openSnackBar("Derminação do processo FOSFATO criada", "my-snack-bar-sucess");
        }, error: (error) => {
          this.snackBarService.openSnackBar("Derminação do processo FOSFATO criada", "my-snack-bar-erro");
        }, complete: () => {
          this.processoSalvo.emit(true);
        },
      });
    } else {
      this.snackBarService.openSnackBar("Há campos inválidos no formulario", "my-snack-bar-erro");
      this.formularioDeterminacaoProcessoFosfato.markAllAsTouched()
    }

  }
  public editarProcesso() {
    if (this.formularioDeterminacaoProcessoFosfato.valid) {
      this.determinacaoProcessoFosfatoService.editarDeterminacaoProcesso(this.formularioDeterminacaoProcessoFosfato.value).subscribe({
        next: (res) => {
          this.snackBarService.openSnackBar("Derminação do processo FOSFATO editada", "my-snack-bar-sucess");
        }, error: (error) => {
          console.log(error)
          this.snackBarService.openSnackBar("Erro ao tentar editar derminação do processo FOSFATO ", "my-snack-bar-erro");
        }, complete: () => {
          this.processoSalvo.emit(true);
        },
      });
    } else {
      this.snackBarService.openSnackBar("Há campos inválidos no formulario", "my-snack-bar-erro");
      this.formularioDeterminacaoProcessoFosfato.markAllAsTouched()
    }
  }

  public calcularQuantidadePorRack() {
    this.formularioDeterminacaoProcessoFosfato.get('qtdePecasRack')?.reset();
    let valor = this.formValidacoesServices.calcularQuantidade(this.formularioDeterminacaoProcessoFosfato.get('qtdePecasGancheira')?.value, this.formularioDeterminacaoProcessoFosfato.get('qtdeGancheirasRack')?.value)
    // Converta os valores para inteiros, atribuindo 0 se não for um número válido
    this.formularioDeterminacaoProcessoFosfato.get('qtdePecasRack')?.setValue(valor);
  }



  public obterValidacao(nome: string) {
    return this.formValidacoesServices.obterValidacao(this.formularioDeterminacaoProcessoFosfato, nome)
  }

  public limparFormulario() {
    this.formularioDeterminacaoProcessoFosfato.reset();
    this.iniciarFormulario();
  }


  public autoGrow(event: any) {
    this.formValidacoesServices.autoAjuste(event)
  }





}
