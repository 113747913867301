import { Routes } from "@angular/router";
import { authGuard } from "../auth/auth-guard.guard";
import { verificarPermissoesGuard } from "../auth/verificar-permissoes.guard";
import { PcpRecebimentoConsultaComponent } from "../paginas/pcp/recebimento/pcp-recebimento-consulta/pcp-recebimento-consulta.component";

export const routesPcp: Routes = [
    { path: "recebimento-registrar", loadComponent: () => import("../paginas/pcp/recebimento/pcp-recebimento-cadastra/pcp-recebimento-cadastra.component").then(r => r.PcpRecebimentoCadastraComponent) },
    { path: "recebimento-registrar/:id", loadComponent: () => import("../paginas/pcp/recebimento/pcp-recebimento-cadastra/pcp-recebimento-cadastra.component").then(r => r.PcpRecebimentoCadastraComponent) },
    { path: "recebimento", component: PcpRecebimentoConsultaComponent },
    { path: "recebimento/:valor", loadComponent: () => import("../paginas/pcp/recebimento/pcp-recebimento-consulta/pcp-recebimento-consulta.component").then(r => r.PcpRecebimentoConsultaComponent) },
]
