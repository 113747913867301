<table class=" table table-sm table-striped">
    <tbody>
        <tr>
            <td><strong>Qtd de peças na travessa:</strong></td>
            <td>{{processoDoPo!.qtdePecasTravessa }}</td>
        </tr>
        <tr>
            <td><strong>Qtd de travessas por quadro:</strong></td>
            <td>{{processoDoPo!.qtdeTravessasQuadro }}</td>
        </tr>
        <tr>
            <td><strong>Qtd de peças por quadro:</strong></td>
            <td>{{processoDoPo!.qtdePecasQuadro}}</td>
        </tr>
        <tr>
            <td><strong>Velocidade máxima da monovia:</strong></td>
            <td>{{processoDoPo!.velocMonoviaMax }}</td>
        </tr>
        <tr>
            <td><strong>Velocidade mínima da monovia:</strong></td>
            <td>{{processoDoPo!.velocMonoviaMin }}</td>
        </tr>
        <tr>
            <td><strong>Distância do reciprocador:</strong></td>
            <td>{{processoDoPo!.distanciaReciprocador }}</td>
        </tr>
        <tr>
            <td><strong>Velocidade do reciprocador:</strong></td>
            <td>{{processoDoPo!.velocReciprocador }}</td>
        </tr>
        <tr>
            <td><strong>Vazão do reciprocador:</strong></td>
            <td>{{processoDoPo!.vazaoReciprocador }}</td>
        </tr>
        <tr>
            <td><strong>Altura máxima do reciprocador:</strong></td>
            <td>{{processoDoPo!.alturaReciprocadorMax }}</td>
        </tr>
        <tr>
            <td><strong>Altura mínima do reciprocador:</strong></td>
            <td>{{processoDoPo!.alturaReciprocadorMin }}</td>
        </tr>
    </tbody>
</table>