<app-container-component [tituloPage]="'Cadastrar manual'" [subtituloPage]="'Manuais cadastrados'">
    <form [formGroup]="formulario">
        <div class="row p-2">
            <div class="col-12 col-lg-4">
                <label for="" class="form-label">Cliente:</label>
                <input type="text" class="form-control form-control-sm" formControlName="cliente" [ngClass]="{'is-invalid':tocado('cliente'),'is-valid':validar('cliente'), 'errorAnimacao is-invalid': sujoInvalido('cliente'), }">
                <app-mensagem-errors [control]="obterValidacao('cliente')"></app-mensagem-errors>
            </div>
            <div class="col-12 col-lg-4">
                <label for="" class="form-label" >Descrição:</label>
                <input type="text" class="form-control form-control-sm" formControlName="descricao" [ngClass]="{'is-invalid':tocado('descricao'),'is-valid':validar('descricao'), 'errorAnimacao is-invalid': sujoInvalido('descricao'), }">
                <app-mensagem-errors [control]="obterValidacao('descricao')"></app-mensagem-errors>
            </div>
            <div class="col-12 col-lg-4">
                <label for="" class="form-label">Versão:</label>
                <input type="text" class="form-control form-control-sm" formControlName="versao" [ngClass]="{'is-invalid':tocado('versao'),'is-valid':validar('versao'), 'errorAnimacao is-invalid': sujoInvalido('versao'), }">
                <app-mensagem-errors [control]="obterValidacao('versao')"></app-mensagem-errors>
            </div>
            <div class="col-12 mt-2">
                <label for="" class="form-label">Observação:</label>
                <textarea class="form-control form-control-sm" rows="5" name="obs" id="obs" formControlName="obs" ></textarea>
            </div>
            <div class="col-12 mt-3">
                <button class="btn btn-sm btn-bg me-2" (click)="cadastrarManual()">Salvar</button>
                <button class="btn btn-sm btn-danger" (click)="iniciarFormulario()">Limpar</button>
            </div>
        </div>
    </form>
</app-container-component>