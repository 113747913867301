<app-container-component [tituloPage]="titulo" [subtituloPage]="nomeFormulario">
    <table class="table table-sm table-striped">
        <thead class="table-sencondary">
            <tr>
                <th>Indicador</th>
                <th>Processo</th>
                <th>Tipo</th>
                <th>Responsável</th>
                <th>Status</th>
                <th class="text-center">Editar</th>
                <th class="text-center">Gerenciar</th>
            </tr>
        </thead>
        <tbody>
            @for (indicador of listaDeIndicadores; track $index) {
            <tr>
                <td>{{indicador.indicador}}</td>
                <td>{{indicador.processo}}</td>
                <td>{{indicador.tipo}}</td>
                <td>{{indicador.responsavel}}</td>
                <td>{{indicador.status}}</td>
                @if(token.nome === indicador.responsavel || token.permissoes.includes('ROLE_ADMIN') || token.permissoes.includes('ROLE_IND_ADMIN')){
                <td (click)="editarIndicador(indicador.id)" class="text-center"><i class="fa-solid fa-pencil"></i></td>
                <td (click)="gerenciarIndicador(indicador.id)" class="text-center"><i class="fa-solid fa-gear"></i></td>
                }@else {
                <td class="text-center text-danger"><i class="fa-solid fa-ban"></i></td>
                <td class="text-center text-danger"><i class="fa-solid fa-ban"></i></td>
                }
            </tr>
            }
        </tbody>
    </table>
    <app-paginator (paginaMudou)="mudancaNaPagina($event)" [paginaAtual]="paginaAtual"
        [quantidadesPorPagina]="quantidadesPorPagina" [totalElementos]="totalTamanho" [totalPorPagina]="totalPorPagina">
    </app-paginator>
</app-container-component>