import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import AcoesPlanejamentoComunicacao from '../../../model/acoes/planejamento-comunicacao/AcoesPlanejamentoComunicacao';

@Injectable({
  providedIn: 'root'
})
export class PlanejamentoComunicacaoService {

  private urlApi = environment.url + "/acoes/planejamento-comunicacao"
  emitirEdicao: EventEmitter<AcoesPlanejamentoComunicacao> = new EventEmitter<AcoesPlanejamentoComunicacao>();

  constructor(
    private http: HttpClient
  ) { }

  public cadastrarPlanejamentoComunicacao(planejamentoComunicacao:any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}`,planejamentoComunicacao);
  }
  public editarPlanejamentoComunicacao(planejamentoComunicacao:any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}`,planejamentoComunicacao);
  }

  public cadastrarAcoesPlanejamentoComunicacao(idPlanejamentoComunicacao:number,acao:AcoesPlanejamentoComunicacao): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/itens/${idPlanejamentoComunicacao}`,acao);
  }

  public teste(): Observable<any> {
    return this.http.get<any>("http://home.task-master.tech/testederotapublica");
  }

  public editarAcoesPlanejamentoComunicacao(idPlanejamentoComunicacao:number,acao:AcoesPlanejamentoComunicacao): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/itens/${idPlanejamentoComunicacao}`,acao);
  }

  public deletarAcoesPlanejamentoComunicacao(acao:any,idPlanejamentoComunicacao:number): Observable<any> {
    return this.http.delete<any>(`${this.urlApi}/itens/${idPlanejamentoComunicacao}`,acao);
  }
  
  public consultarPorId(idPlanejamentoComunicacao:any): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${idPlanejamentoComunicacao}`);
  }

  public consultaPaginada(paginaAtaul: number, quantidadePorPagina: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${paginaAtaul}/${quantidadePorPagina}`);
  }

}
