import { MedicaoMonitoramentoService } from './../../../../services/controles/medicao-monitoramento/medicao-monitoramento.service';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { PaginatorComponent } from "../../../../componentes/paginator/paginator.component";
import { MedicaoMonitoramento } from '../../../../model/controles/medicao-monitoramento/medicao-monitoramento';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginService } from '../../../../services/login/login.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';

@Component({
  selector: 'app-controles-medicao-monitoramento-lista',
  standalone: true,
  imports: [ContainerComponentComponent, PaginatorComponent, CommonModule],
  templateUrl: './controles-medicao-monitoramento-lista.component.html',
  styleUrls: ['./controles-medicao-monitoramento-lista.component.scss'] // Corrigido para styleUrls
})
export class ControlesMedicaoMonitoramentoListaComponent implements OnInit {
  titulo = "LISTA MEDIÇÃO E MONITORAMENTO";
  nomeFormulario = "Medição e monitoramento";
  listaDeMedicaoMonitoramento: MedicaoMonitoramento[] = [];
  @Input() comTopo: boolean = true;

  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [10, 20, 30, 40, 100];
  totalPorPagina: number = this.quantidadesPorPagina[0];
  private loginService = inject(LoginService);
  private snackbarService = inject(SnackBarService);

  constructor(
    private medicaoMonitoramentoService: MedicaoMonitoramentoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.consultarTodasMedicaoMonitoramento();
  }

  public consultarTodasMedicaoMonitoramento() {
    this.medicaoMonitoramentoService.consultaPaginadaMedicaoMonitoramento(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res: any) => {
        this.listaDeMedicaoMonitoramento = res.content;
        this.totalTamanho = res.page.totalElements;
      },
      error: (error) => {
        this.snackbarService.tratarErro(error, 'Falha ao consultar registros de medição e monitoramento!');
      }
    });
  }

  public editarMedicaoMonitoramento(id: number) {
    this.router.navigate([`/controles/cadastrar-medicao-monitoramento/${id}`]);
  }

  public gerenciarMedicaoMonitoramento(id: number) {
    this.router.navigate([`/controles/resultado-medicao-monitoramento/${id}`]);
  }

  public mudancaNaPagina(evento: any) {
    this.paginaAtual = evento.pageIndex; // Incrementado por 1 para alinhar com a paginação de backend
    this.totalPorPagina = evento.pageSize;
    this.consultarTodasMedicaoMonitoramento();
  }


  public temPermissao(){
    return this.loginService.temPermissao(['ROLE_CONT_EDIT']);
  }

  public temPermissaoExcluir(){
    return this.loginService.temPermissao(['ROLE_CONT_ADMIN']);
  }


}
