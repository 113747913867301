import { Component, OnInit } from '@angular/core';
import { CertificadoService } from '../../../services/certificado/certificado.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Certificado } from '../../../model/certificado/certificado';
import { SnackBarService } from '../../../utils/snack-bar.service';
import { CnpjPipe } from '../../../pipe/cnpj.pipe';
import { AzureTokenService } from '../../../services/azure-token/azure-token.service';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";

@Component({
  selector: 'app-certificado',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CnpjPipe,
    ContainerComponentComponent
],
  templateUrl: './certificado.component.html',
  styleUrl: './certificado.component.scss'
})
export class CertificadoComponent  implements OnInit{
  formData = new FormData();
  senha: string = '';
  file!: File;
  certificado : Certificado =  new Certificado();
  constructor(
    private certificadoService: CertificadoService,
    private snackBarService: SnackBarService,
    private azureTokenService: AzureTokenService
  ){
    
  }

  ngOnInit(): void {
      this.consultaCertificado();
      this.consultarToken();
  }

  public consultaCertificado(){
    this.certificadoService.consultarCertificado().subscribe({
      next:(res)=>{
        this.certificado = res;
      },
      error:(error)=>{
        this.snackBarService.tratarErro(error, "Falha ao tentar consultar certificado!");
      }
    });
  }

  public consultarToken(){
    this.azureTokenService.consultarTokens().subscribe({
      next:(res)=>{
        console.log(res);
      }
    });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.file = input.files[0]; // Armazenar o arquivo selecionado
    }
  }

  public cadastrarCertificado(fileInput: HTMLInputElement){
    this.formData.append('file', this.file);
    this.certificadoService.salvarCertificado(this.formData, this.senha).subscribe({
        next: (res) => {
            this.senha = '';
            this.file = new File([], ""); // Limpa a variável file
            fileInput.value = ''; // Limpa o input de arquivo
            this.snackBarService.openSnackBar('Certificado cadastrado com sucesso!', 'my-snack-bar-sucess');
        },
        error: (error) => {
           this.snackBarService.tratarErro(error, "Falha ao tentar cadastrar certificado!");
        },
        complete:()=>{
          this.consultaCertificado();
        }
    });
  }

}
