import { EmisorEventoGeralService } from './../../../../utils/emisorEventoGeral.service';
import { ChangeDetectorRef, Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { BotaoComponent } from "../../../../componentes/botao/botao.component";
import { FormValidacaoService } from '../../../../utils/formValidacao.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { PartesInteressadasService } from '../../../../services/acoes/partes-interessadas/partes-interessadas.service';
import PartesInteressadasConsultaDto from '../../../../model/acoes/partes-interessadas/ParteInteressadaConsultaDto';
import { PartesInteressadaRegistrarAcoesComponent } from "../partes-interessada-registrar-acoes/partes-interessada-registrar-acoes.component";
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import AcoesPartesInteressadas from '../../../../model/acoes/partes-interessadas/AcoesPartesInteressadas';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PartesInteressadaListarComponent } from "../partes-interessada-visualizar/partes-interessada-listar.component";

@Component({
  selector: 'app-partes-interessada-registrar',
  standalone: true,
  imports: [CommonModule, ContainerComponentComponent, ReactiveFormsModule, MensagemErrorsComponent, BotaoComponent, PartesInteressadaRegistrarAcoesComponent, PartesInteressadaListarComponent],
  templateUrl: './partes-interessada-registrar.component.html',
  styleUrl: './partes-interessada-registrar.component.scss'
})
export class PartesInteressadaRegistrarComponent implements OnInit {

  formularioCadastro!: FormGroup;
  mostrarBolas: boolean = false;
  mostrarFormularioAcoes: boolean = false;
  mapeamento!: PartesInteressadasConsultaDto;
  idMapeamento: number = 0;
  acoesParteInteressada!: AcoesPartesInteressadas;
  movimentar: boolean = false;
  private formValidacaoService = inject(FormValidacaoService);
  private formBuilder = inject(FormBuilder);
  private activatedRoute = inject(ActivatedRoute);
  private partesInteressadaService = inject(PartesInteressadasService);
  private dialog = inject(MatDialog);
  private emisorEventoGeralService = inject(EmisorEventoGeralService);
  snackBarService = inject(SnackBarService);
  router = inject(Router);
  cdr = inject(ChangeDetectorRef);


  ngOnInit(): void {
    this.verificarRota();
  }

  public verificarRota() {
    this.iniciarFormulario();
    this.activatedRoute.params.subscribe({
      next: (res: any) => {
        this.idMapeamento = res.id;
        if (this.idMapeamento != 0) {
          this.consultaPorId();
        }
      }
    })
  }

  public cadastrarParteInteressada() {
    this.partesInteressadaService.cadastrarMapeamento(this.formularioCadastro.value).subscribe({
      next: (res) => {
        this.mapeamento = res;
      }, error: (error) => {

      }, complete: () => {
        this.router.navigate([`/acoes/partes-interessadas-registrar/${this.mapeamento.id}`])
      }
    });
  }
  public editarParteInteressada() {
    this.partesInteressadaService.editarMapeamento(this.formularioCadastro.value).subscribe({
      next: (res) => {
        this.mapeamento = res;
      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar editar mapeamento")
      }, complete: () => {
        this.snackBarService.openSnackBar("Mapeamento Editado com sucesso", "my-snack-bar-sucess");
      }
    });
  }

  public deletarParteInteressadaSelecionada(acao: AcoesPartesInteressadas) {
    const dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: acao.parteInteressada, texto: "Deseja apagar essa parte interessada" },
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => {
        if (res) {
          this.deletar(acao.id);
        };
      }
    });

  }
  private deletar(id: number) {
    this.emisorEventoGeralService.carregando.emit(true);
    this.partesInteressadaService.deletarAcoesPartesInteressadas(id).subscribe({
      next: (res) => {
        this.mapeamento = res;
      }, error: (error) => {
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.tratarErro(error, "Erro ao tentar deletar ação")
      }, complete: () => {
        this.consultaPorId();
        this.emisorEventoGeralService.carregando.emit(false);
        this.snackBarService.openSnackBar("Ação deletada com sucesso", "my-snack-bar-sucess");
      }
    });
  }


  public consultaPorId() {
    this.partesInteressadaService.consultarPorId(this.idMapeamento).subscribe({
      next: (res) => {
        this.mapeamento = res;
        this.formularioCadastro.patchValue(this.mapeamento);
      }, error: (error) => {

      }, complete: () => {

      }
    });
  }

  public receberNovaConsulta(event: any) {
    if (event) {
      this.consultaPorId();
      this.acoesParteInteressada = new AcoesPartesInteressadas();
    }
  }
  public emitirEdicao(event: any) {
    if (event) {
      if (this.mostrarFormularioAcoes) {
        this.acoesParteInteressada = event;
      } else {
        this.acoesParteInteressada = event;
        this.mostrarFormularioAcoes = !this.mostrarFormularioAcoes
      }
    }
  }

  public iniciarFormulario() {
    this.formularioCadastro = this.formBuilder.group({
      id: [],
      mapeamento: [],
    });
  }

  public openClose() {
    this.mostrarBolas = !this.mostrarBolas;
  }

  public mostrarFormsAcoes() {
    this.mostrarFormularioAcoes = !this.mostrarFormularioAcoes
    if (!this.mostrarFormularioAcoes) {
      this.acoesParteInteressada = new AcoesPartesInteressadas();
    }
    this.openClose();
  }



  public editarParteInteressadaSelecionada(acaoSelecionada: AcoesPartesInteressadas) {
    if (this.mostrarFormularioAcoes) {
      this.acoesParteInteressada = acaoSelecionada;
    } else {
      this.acoesParteInteressada = acaoSelecionada;
      this.mostrarFormularioAcoes = !this.mostrarFormularioAcoes
    }
  }


  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formularioCadastro, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formularioCadastro, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formularioCadastro, nome);
  }

  public autoAjuste(evento: Event) {
    return this.formValidacaoService.autoAjuste(evento);
  }
}
