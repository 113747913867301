import { FormValidacaoService } from './../../../../utils/formValidacao.service';
import { ActivatedRoute } from '@angular/router';
import { SatisfacaoClienteService } from './../../../../services/controles/satisfacao-cliente/satisfacao-cliente.service';
import { Component, inject } from '@angular/core';
import { ContainerComponentComponent } from "../../../../componentes/container-component/container-component.component";
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperIcon, MatStepperIntl, MatStepperModule, StepperOrientation } from '@angular/material/stepper';
import { map, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SatisfacaoCliente } from '../../../../model/controles/satisfacao-cliente/satisfacao-cliente';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatIcon } from '@angular/material/icon';
import { MensagemErrorsComponent } from "../../../../componentes/mensagem-errors/mensagem-errors.component";
import { AutoridadePesquisaService } from '../../../../services/controles/satisfacao-cliente/autoridadePesquisa.service';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { AutoridadePesquisa } from '../../../../model/controles/satisfacao-cliente/AutoridadePesquisa';
import moment from 'moment';
import { SatisfacaoClienteRespostas } from '../../../../interface/controles/satisfacao-clientes/satisfacaoClienteRespostas';


@Component({
  selector: 'app-satisfacao-cliente-responder',
  standalone: true,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true, displayDefaultIndicatorType: false },
    },
  ],
  imports: [
    CommonModule,
    ContainerComponentComponent,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    AsyncPipe,
    MatOption,
    MatSelect,
    MatIcon,
    MatStepperIcon,
    MensagemErrorsComponent
  ],
  templateUrl: './satisfacao-cliente-responder.component.html',
  styleUrl: './satisfacao-cliente-responder.component.scss'
})
export class SatisfacaoClienteResponderComponent {
  private formBuilder = inject(FormBuilder);
  private _matStepperIntl = inject(MatStepperIntl);
  private activatedRoute = inject(ActivatedRoute);
  private satisfacaoClienteService = inject(SatisfacaoClienteService);
  private formValidacaoService = inject(FormValidacaoService);
  private snackBarService = inject(SnackBarService);
  private autoridadePesquisaService = inject(AutoridadePesquisaService);

  satisfacaoCliente: SatisfacaoCliente = new SatisfacaoCliente();
  autoridadePesquisa: AutoridadePesquisa = new AutoridadePesquisa();
  idPesquisa: number = 0;
  token: string = '';
  formularioDePerguntas: FormGroup = new FormGroup({});
  responder: boolean = false;
  indisponivel: boolean =false;

  constructor() {
    this.verificarRota();
    this.iniciarFormularioPerguntas();
  }

  public consultaPorId() {
    this.indisponivel = false;
    this.satisfacaoClienteService.consultaPorIdPesquisa(this.idPesquisa).subscribe({
      next: (res) => {
        this.satisfacaoCliente = res;
      }, error: (error) => {
        this.indisponivel = true;
      }, complete: () => {
        this.iniciarFormulario();
      }
    });
  }

  public verificarRota() {
    this.activatedRoute.params.subscribe((res: any) => {
      this.idPesquisa = res.id;
      this.token = res.token;
      if (this.idPesquisa) {
        this.consultaPorId();
        this.consultaPorToken();
      }
    });
  }

  public enviarQuestionario() {
    const respostas: SatisfacaoClienteRespostas[] = this.tratarRespostas();
    this.satisfacaoClienteService.cadastrarRespostas(respostas, this.idPesquisa, this.token).subscribe({
      next: (res) => {
        this.snackBarService.openSnackBar("Pequisa de respondida com sucesso!!!", "my-snack-bar-sucess");
      }, error: (error) => {
        this.snackBarService.tratarErro(error, "Erro ao tentar enviar");
      }, complete: () => {
        this.consultaPorToken();
      }
    });
  }

  public consultaPorToken() {
    this.autoridadePesquisaService.consultaAutorizacoesPorToken(this.token).subscribe({
      next: (res) => {
        this.autoridadePesquisa = res;
      }, error: (error) => {
        console.log('Teste', error)
      }, complete: () => {
        this.formularioDePerguntas.patchValue(this.autoridadePesquisa);
      }
    });
  }

  public tratarRespostas() {
    return this.satisfacaoCliente.perguntas.map((pergunta, i) => {
      return {
        nomeRemetente: this.formularioDePerguntas.controls['nome'].value, // Se for um dado fixo ou obtido dinamicamente
        empresaRemetente: this.formularioDePerguntas.controls['empresa'].value, // Ajuste conforme necessário
        areaRemetente: this.formularioDePerguntas.controls['area'].value, // Ajuste conforme necessário
        resposta: this.formularioDePerguntas.get(`resposta-${i}`)?.value,
        observacao: this.formularioDePerguntas.get(`observacao-${i}`)?.value, // Observação opcional
        dataResposta: moment().format('yyyy-MM-DD'), // Data atual no formato YYYY-MM-DD
        idPergunta: pergunta.id
      };
    });
  }

  public iniciarFormulario() {
    this.satisfacaoCliente.perguntas.forEach((_, i) => {
      this.formularioDePerguntas.addControl(`resposta-${i}`, this.formBuilder.control('', Validators.required));
      this.formularioDePerguntas.addControl(`observacao-${i}`, this.formBuilder.control(''));  // Adiciona observação, se necessário
    });
  }

  public iniciarFormularioPerguntas() {
    this.formularioDePerguntas = this.formBuilder.group({
      nome: ['', Validators.required],
      empresa: ['', Validators.required],
      area: ['', Validators.required],
    });
  }


  public obterValidacao(nome: string) {
    return this.formValidacaoService.obterValidacao(this.formularioDePerguntas, nome)
  }

  public abrirFormularioResponder() {
    this.responder = !this.responder;
  }



}
