import { Injectable } from '@angular/core';
import { Situacao } from '../../../model/engenharia/situacao';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SituacaoService {


  private url = environment.url+'/engenharia';

  constructor(
    private http: HttpClient
  ) { 
  
  }


public salvarSituacao(tipoPlanoAcao: Situacao): Observable<Situacao> {
  return this.http.post<Situacao>(`${this.url}/situacao-produto`, tipoPlanoAcao);
}

public editarAcaoSituacao(tipoPlanoAcao:Situacao): Observable<Situacao> {
  return this.http.put<Situacao>(`${this.url}/situacao-produto`, tipoPlanoAcao);
}
public deletarSituacao(id:number): Observable<any> {
  return this.http.delete<any>(`${this.url}/situacao-produto/${id}`);
}

public consultarTodasSituacao(): Observable<Situacao[]> {
  return this.http.get<Situacao[]>(`${this.url}/situacao-produto`);
}


}
