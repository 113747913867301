import { Component, inject, OnInit } from '@angular/core';
import { PermissoesService } from '../../../services/usuario/permissoes.service';
import { PageEvent } from '@angular/material/paginator';
import { PaginatorComponent } from "../../../componentes/paginator/paginator.component";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidacaoService } from '../../../utils/formValidacao.service';
import { MensagemErrorsComponent } from "../../../componentes/mensagem-errors/mensagem-errors.component";
import { SnackBarService } from '../../../utils/snack-bar.service';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";

@Component({
  selector: 'app-permissoes',
  standalone: true,
  imports: [
    PaginatorComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MensagemErrorsComponent,
    ContainerComponentComponent
],
  templateUrl: './permissoes.component.html',
  styleUrl: './permissoes.component.scss'
})
export class PermissoesComponent implements OnInit {
  paginaAtual: number = 0;
  totalTamanho: number = 0;
  quantidadesPorPagina: any[] = [6, 16, 24, 32, 56];
  totalPorPagina: number = this.quantidadesPorPagina[0];
  permissoesService = inject(PermissoesService);
  permissoes: any[] = [];
  modulo: string = '';
  private router = inject(Router);
  formulario!: FormGroup;
  formBuilder = inject(FormBuilder);
  formValidacaoService = inject(FormValidacaoService);
  private snackBarService= inject(SnackBarService);

  ngOnInit(): void {
    this.iniciarFormulario();
    this.consultarPermissoesDoSistema();
  }

  private consultarPermissoesDoSistema() {
    this.permissoesService.consultaPermissoesPaginada(this.paginaAtual, this.totalPorPagina).subscribe({
      next: (res: any) => {
        this.totalTamanho = res.page.totalElements;
        this.permissoes = res.content;
      }
    });
  }

  private iniciarFormulario() {
    this.formulario = this.formBuilder.group({
      authority: [null, Validators.required],
      descricao: [null, Validators.required],
      modulo: [null, Validators.required]
    });
  }

  public consultarPermissoesPorModulo() {
    if (this.modulo === '') {
      this.consultarPermissoesDoSistema();
    } else {
      this.permissoesService.consultaPermissoesPorModulo(this.modulo.toUpperCase()).subscribe({
        next: (res: any) => {
          this.permissoes = res;
        }
      });
    }
  }

  public cadastrarPermissao() {
    if(this.formulario.valid){
      this.permissoesService.cadastrarPermissao(this.formulario.value).subscribe({
        next:(res)=>{
          this.snackBarService.openSnackBar("Permissão cadastrada com sucesso!", "my-snack-bar-sucess");
        },
        error:(e)=>{
          this.snackBarService.tratarErro(e, "Falha ao tentar salvar permissão!");
        },
        complete:()=>{
          this.iniciarFormulario();
        }
      });
    }else{
      this.formulario.markAllAsTouched();
    }
  }

  public alterarPermissao(id: number) {
    this.router.navigate([`permissao-alteracao/${id}`]);
  }

  public keyEnterPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.consultarPermissoesPorModulo();
    }
  }

  public onPageChange(event: PageEvent) {
    this.paginaAtual = event.pageIndex;
    this.totalPorPagina = event.pageSize;
    this.consultarPermissoesDoSistema();
  }

  //Metodos utilizados para validação de formulário
  public obterValidacao(nome: string): FormControl {
    return this.formValidacaoService.obterValidacao(this.formulario, nome);
  }

  public tocado(nome: string): boolean {
    return this.formValidacaoService.tocado(this.formulario, nome);
  }

  public validar(nome: string): boolean {
    return this.formValidacaoService.validar(this.formulario, nome);
  }

  public sujoInvalido(nome: string): boolean {
    return this.formValidacaoService.sujoInvalido(this.formulario, nome);
  }

}
