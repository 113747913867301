<mat-drawer-container class="example-container h-100">
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <div class="row mb-1 mt-1 ms-1">
            <div class="d-none d-lg-flex col-1 p-0">
                @if(menuFixo){
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Desfixar menu"></i></div>
                }@else {
                <div class="text-start c-pointer"><i class="fa-solid fa-thumbtack-slash" (click)="fixarMenu()"
                        matTooltipPosition="below" matTooltipHideDelay="500" matTooltip="Fixar menu"></i></div>
                }
            </div>
            <div
                class="col-10  col-md-11 d-flex justify-content-start justify-content-lg-center align-items-center p-0">
                <a class="logo d-flex justify-content-center align-items-center gap-2"> <i
                        class="fa-regular fa-clipboard"></i> CONTROLE</a>
            </div>
            <div class="col-2  z-1 d-flex align-items-center  justify-content-end" (click)="clicarMenu(drawer)"
                (click)="mudarIcone()">
                <i class="fa-solid fa-xmark"></i>
            </div>
        </div>
        <div class="menu-content">
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['medicaoMonitoramento']}"
                    (click)="abrirMenu('medicaoMonitoramento')">
                    <span class="font-titulo"><i class="fa-solid fa-chart-simple"></i><span class="ms-2">Medição e
                            monitoramento</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['medicaoMonitoramento']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['medicaoMonitoramento']}">
                    @for (link of getMenuItensMedicaoEMonitoramento(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" [routerLink]="link.link" (click)="clicarMenu(drawer)" (click)="mudarIcone()"
                            routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }

                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['satisfacaoCliente']}" (click)="abrirMenu('satisfacaoCliente')">
                    <span class="font-titulo"><i class="fa-solid fa-bars-progress"></i> <span class="ms-2">Satisfação do cliente</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['satisfacaoCliente']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['satisfacaoCliente']}">
                    @for (link of getMenuItensSatisfacaoCliente(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" routerLinkActive="active-solo" (click)="clicarMenu(drawer)"
                            (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['normaPintura']}" (click)="abrirMenu('normaPintura')">
                    <span class="font-titulo"><i class="fa-solid fa-bars-progress"></i> <span class="ms-2">Norma Pintura</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['normaPintura']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['normaPintura']}">
                    @for (link of getMenuItensNormaPintura(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" routerLinkActive="active-solo" (click)="clicarMenu(drawer)"
                            (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
            <ul class="item-menu">
                <div class="d-flex justify-content-between sessao-header-menu"
                    [ngClass]="{'active':menusVisiveis['normaCliente']}" (click)="abrirMenu('normaCliente')">
                    <span class="font-titulo"><i class="fa-solid fa-book"></i><span class="ms-2">Manuais de clientes</span></span>
                    <span><i class="fa-solid fa-chevron-down"
                            [ngClass]="{'rotate': menusVisiveis['normaCliente']}"></i></span>
                </div>
                <ul class="sub-menu-item" [ngClass]="{'show': menusVisiveis['normaCliente']}">
                    @for (link of getMenuItensManuaisCliente(); track $index) {
                    <a [routerLink]="link.link">
                        <li class="item" routerLinkActive="active-solo" (click)="clicarMenu(drawer)"
                            (click)="mudarIcone()" routerLinkActive="active-solo">
                            <a class="font-subMenu">{{ link.label }}</a>
                        </li>
                    </a>
                    }
                </ul>
            </ul>
        </div>
    </mat-drawer>
    <div class="example-sidenav-content flex-column justify-content-start align-items-start ">
        <a class="btn-menu" (click)="clicarFechar(drawer)" (click)="mudarIcone()">
            <i *ngIf="!open" class="fa-solid fa-angles-left"></i>
            <i *ngIf="open" class="fa-solid fa-angles-right"></i>
        </a>
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>