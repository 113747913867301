
import { Component, Input } from '@angular/core';
import { EngDeterminacaoPo } from '../../../../../../model/engenharia/EngDeterminacaoPo';

@Component({
  selector: 'app-form-maquina-1',
  standalone: true,
  imports: [],
  templateUrl: './form-maquina-1.component.html',
  styleUrl: './form-maquina-1.component.scss'
})
export class FormMaquina1Component {
  @Input() processoDoPo: EngDeterminacaoPo = new EngDeterminacaoPo;;

}
