import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContainerComponentComponent } from "../../../componentes/container-component/container-component.component";
import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';

@Component({
  selector: 'app-home-qualidade',
  standalone: true,
  imports: [ContainerComponentComponent],
  templateUrl: './home-qualidade.component.html',
  styleUrl: './home-qualidade.component.scss'
})
export class HomeQualidadeComponent implements OnInit {
  titulo:string = "Modulo Qualidade";
  subTitulo:string = "Bem-vindo ao modulo de qualidade";

  constructor(
    private cdr:ChangeDetectorRef
  ){
    
  }
  ngOnInit(): void {
    this.cdr.detectChanges();
    this.montarCarrousel();
  }
  public montarCarrousel() {
    var swiper = new Swiper(".mySwiper", {
      autoplay: {
        delay: 7000,
        pauseOnMouseEnter:true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span style="width:18px;height:18px;margin-top:10px; display:flex;justify-content:center;align-items: center;color: #fff; background:#09638E" class="' + className + '">' + (index + 1) + "</span>";
        },
      },
      modules: [Autoplay, Pagination]
    });
  }
}
